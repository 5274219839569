{
  "wysiwyg.viewer.components.MediaBox": {
    "componentType": "wysiwyg.viewer.components.MediaBox",
    "components": [],
    "style": "mb1",
    "type": "Container",
    "layout": {
      "x": 0,
      "y": 0,
      "width": 600,
      "height": 400
    },
    "props": {
      "type": "MediaBoxProperties",
      "allowOverflowContent": true
    },
    "design": {
      "type": "MediaContainerDesignData",
      "background": {
        "type": "BackgroundMedia",
        "color": "#FFF",
        "alignType": "center",
        "fittingType": "fill",
        "scrollType": "none",
        "colorOverlay": "",
        "colorOverlayOpacity": 0
      },
      "cssStyle": {},
      "themeMappings": {}
    }
  },
  "wysiwyg.viewer.components.SiteButton": {
    "type": "Component",
    "skin": "wysiwyg.viewer.skins.button.BasicButton",
    "layout": {
      "width": 200,
      "height": 50,
      "x": 0,
      "y": 0
    },
    "componentType": "wysiwyg.viewer.components.SiteButton",
    "data": {
      "type": "LinkableButton",
      "label": "Button"
    },
    "props": {
      "type": "ButtonProperties"
    },
    "style": {
      "type": "ComponentStyle",
      "style": {
        "properties": {
          "alpha-bg": "1",
          "alpha-bgh": "1",
          "alpha-brd": "1",
          "alpha-brdh": "0",
          "alpha-txt": "1",
          "alpha-txth": "1",
          "bg": "color_11",
          "bgh": "color_12",
          "boxShadowToggleOn-shd": "false",
          "brd": "color_14",
          "brdh": "color_12",
          "brw": "0",
          "fnt": "font_8",
          "rd": "0px",
          "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
          "txt": "color_15",
          "txtd": "color_11",
          "txth": "#000000"
        },
        "propertiesSource": {
          "alpha-bg": "value",
          "alpha-bgh": "value",
          "alpha-brd": "value",
          "alpha-brdh": "value",
          "alpha-txt": "value",
          "alpha-txth": "value",
          "bg": "theme",
          "bgh": "theme",
          "boxShadowToggleOn-shd": "value",
          "brd": "theme",
          "brdh": "theme",
          "brw": "value",
          "fnt": "theme",
          "rd": "value",
          "shd": "value",
          "txt": "theme",
          "txtd": "theme",
          "txth": "value"
        },
        "groups": {}
      },
      "componentClassName": "wysiwyg.viewer.components.SiteButton",
      "styleType": "custom",
      "skin": "wysiwyg.viewer.skins.button.BasicButton"
    }
  },
  "wysiwyg.viewer.components.StateBox": {
    "componentType": "wysiwyg.viewer.components.StateBox",
    "layout": {
      "x": 0,
      "y": 0,
      "fixedPosition": false,
      "width": 600,
      "height": 400,
      "scale": 1,
      "rotationInDegrees": 0
    },
    "components": [
      {
        "layout": {
          "x": 0,
          "y": 0,
          "fixedPosition": false,
          "width": 600,
          "height": 400,
          "scale": 1,
          "rotationInDegrees": 0
        },
        "components": [],
        "componentType": "wysiwyg.viewer.components.StateBoxState",
        "type": "Container",
        "design": {
          "type": "MediaContainerDesignData",
          "background": {
            "type": "BackgroundMedia",
            "color": "{color_11}",
            "colorOpacity": 1,
            "alignType": "center",
            "fittingType": "fill",
            "scrollType": "none",
            "colorOverlay": "",
            "colorOverlayOpacity": 0,
            "showOverlayForMediaType": "WixVideo",
            "mediaRef": null,
            "imageOverlay": null,
            "filterEffect": null
          },
          "charas": "design-k4ck4vur",
          "dataChangeBehaviors": []
        },
        "style": "stateBoxState1",
        "connections": {
          "type": "ConnectionList",
          "items": [
            {
              "type": "ConnectionItem",
              "role": "State1",
              "isPrimary": true,
              "controllerId": "dataItem-placeholder"
            }
          ]
        }
      },
      {
        "layout": {
          "x": 0,
          "y": 0,
          "fixedPosition": false,
          "width": 600,
          "height": 400,
          "scale": 1,
          "rotationInDegrees": 0
        },
        "components": [],
        "componentType": "wysiwyg.viewer.components.StateBoxState",
        "type": "Container",
        "design": {
          "type": "MediaContainerDesignData",
          "background": {
            "type": "BackgroundMedia",
            "color": "{color_11}",
            "colorOpacity": 1,
            "alignType": "center",
            "fittingType": "fill",
            "scrollType": "none",
            "colorOverlay": "",
            "colorOverlayOpacity": 0,
            "showOverlayForMediaType": "WixVideo",
            "mediaRef": null,
            "imageOverlay": null,
            "filterEffect": null
          },
          "charas": "design-k4ck4vur",
          "dataChangeBehaviors": []
        },
        "style": "stateBoxState1",
        "connections": {
          "type": "ConnectionList",
          "items": [
            {
              "type": "ConnectionItem",
              "role": "State2",
              "isPrimary": true,
              "controllerId": "dataItem-placeholder"
            }
          ]
        }
      }
    ],
    "type": "Container",
    "skin": "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
    "props": {
      "type": "StateBoxProperties",
      "transition": "NoTransition",
      "transDuration": 0
    },
    "style": {
      "type": "ComponentStyle",
      "style": {
        "properties": {
          "alpha-brd": "0.11",
          "boxShadowToggleOn-shd": "false",
          "brd": "color_15",
          "brw": "0",
          "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
        },
        "propertiesSource": {
          "alpha-brd": "value",
          "boxShadowToggleOn-shd": "value",
          "brd": "theme",
          "brw": "value",
          "shd": "value"
        },
        "groups": {}
      },
      "componentClassName": "wysiwyg.viewer.components.StateBox",
      "styleType": "custom",
      "skin": "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin"
    },
    "activeModes": {}
  },
  "wysiwyg.viewer.components.Repeater": {
    "type": "RepeaterContainer",
    "components": [
      {
        "type": "Container",
        "components": [
          {
            "type": "Component",
            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
            "layout": {
              "width": 130,
              "height": 31,
              "x": 29,
              "y": 43,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.WRichText",
            "data": {
              "type": "RepeatedData",
              "original": {
                "type": "StyledText",
                "text": "<h2 class=\"font_2\"><span class=\"color_15\">Title</span></h2>",
                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                "linkList": []
              },
              "overrides": {
                "item1": {
                  "type": "StyledText",
                  "text": "<h2 class=\"font_2\"><span class=\"color_15\">Title</span></h2>",
                  "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                  "linkList": []
                },
                "item-k5uwo6us": {
                  "type": "StyledText",
                  "text": "<h2 class=\"font_2\"><span class=\"color_15\">Title</span></h2>",
                  "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                  "linkList": []
                },
                "item-k5uwo7eq": {
                  "type": "StyledText",
                  "text": "<h2 class=\"font_2\"><span class=\"color_15\">Title</span></h2>",
                  "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                  "linkList": []
                }
              }
            },
            "props": {
              "type": "WRichTextProperties",
              "brightness": 1,
              "packed": true
            },
            "mobileStructure": {
              "layout": {
                "width": 262,
                "height": 37,
                "x": 29,
                "y": 28,
                "scale": 1.3214285714285714,
                "rotationInDegrees": 0,
                "fixedPosition": false
              },
              "props": {
                "type": "WRichTextProperties",
                "brightness": 1,
                "packed": true
              }
            }
          },
          {
            "type": "Component",
            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
            "layout": {
              "width": 130,
              "height": 190,
              "x": 29,
              "y": 93,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.WRichText",
            "data": {
              "type": "RepeatedData",
              "original": {
                "type": "StyledText",
                "text": "<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\"><span class=\"color_15\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\">Avenir Light is a clean and stylish font favored by designers.</span></span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\"><span class=\"color_15\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\">It&#39;s easy on the eyes and a great go to font for titles, paragraphs &amp; more.</span></span></span></span></p>",
                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                "linkList": []
              },
              "overrides": {
                "item1": {
                  "type": "StyledText",
                  "text": "<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\"><span class=\"color_15\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\">Avenir Light is a clean and stylish font favored by designers.</span></span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\"><span class=\"color_15\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\">It&#39;s easy on the eyes and a great go to font for titles, paragraphs &amp; more.</span></span></span></span></p>",
                  "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                  "linkList": []
                },
                "item-k5uwo6us": {
                  "type": "StyledText",
                  "text": "<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\"><span class=\"color_15\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\">Avenir Light is a clean and stylish font favored by designers.</span></span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\"><span class=\"color_15\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\">It&#39;s easy on the eyes and a great go to font for titles, paragraphs &amp; more.</span></span></span></span></p>",
                  "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                  "linkList": []
                },
                "item-k5uwo7eq": {
                  "type": "StyledText",
                  "text": "<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\"><span class=\"color_15\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\">Avenir Light is a clean and stylish font favored by designers. </span></span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.3em;\"><span class=\"color_15\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\">It&#39;s easy on the eyes and a great go to font for titles, paragraphs &amp; more.</span></span></span></span></p>",
                  "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                  "linkList": []
                }
              }
            },
            "props": {
              "type": "WRichTextProperties",
              "brightness": 1,
              "packed": true
            },
            "style": "txtNew",
            "mobileStructure": {
              "layout": {
                "width": 262,
                "height": 95,
                "x": 29,
                "y": 78,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
              },
              "props": {
                "type": "WRichTextProperties",
                "brightness": 1,
                "packed": true
              }
            }
          },
          {
            "type": "Component",
            "skin": "wysiwyg.viewer.skins.button.BasicButton",
            "layout": {
              "width": 110,
              "height": 40,
              "x": 29,
              "y": 317,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.SiteButton",
            "data": {
              "type": "RepeatedData",
              "original": {
                "type": "LinkableButton",
                "label": "Button"
              },
              "overrides": {
                "item1": {
                  "type": "LinkableButton",
                  "label": "Button"
                },
                "item-k5uwo6us": {
                  "type": "LinkableButton",
                  "label": "Button"
                },
                "item-k5uwo7eq": {
                  "type": "LinkableButton",
                  "label": "Button"
                }
              }
            },
            "props": {
              "type": "ButtonProperties",
              "align": "center",
              "margin": 0
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "properties": {
                  "alpha-bg": "0",
                  "alpha-bgd": "1",
                  "alpha-bgh": "0.6",
                  "alpha-brd": "1",
                  "alpha-brdd": "1",
                  "alpha-brdh": "1",
                  "bg": "color_17",
                  "bgd": "#CCCCCC",
                  "bgh": "color_12",
                  "boxShadowToggleOn-shd": "false",
                  "brd": "color_15",
                  "brdd": "#CCCCCC",
                  "brdh": "color_15",
                  "brw": "1",
                  "fnt": "font_8",
                  "rd": "0px 0px 0px 0px",
                  "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                  "txt": "color_15",
                  "txtd": "#FFFFFF",
                  "txth": "#000000"
                },
                "propertiesSource": {
                  "alpha-bg": "value",
                  "alpha-bgd": "value",
                  "alpha-bgh": "value",
                  "alpha-brd": "value",
                  "alpha-brdd": "value",
                  "alpha-brdh": "value",
                  "bg": "theme",
                  "bgd": "value",
                  "bgh": "theme",
                  "boxShadowToggleOn-shd": "value",
                  "brd": "theme",
                  "brdd": "value",
                  "brdh": "theme",
                  "brw": "value",
                  "fnt": "theme",
                  "rd": "value",
                  "shd": "value",
                  "txt": "theme",
                  "txtd": "value",
                  "txth": "value"
                },
                "groups": {}
              },
              "componentClassName": "wysiwyg.viewer.components.SiteButton",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wysiwyg.viewer.skins.button.BasicButton"
            }
          }
        ],
        "layout": {
          "width": 200,
          "height": 400,
          "x": 0,
          "y": 0,
          "scale": 1,
          "rotationInDegrees": 0,
          "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.MediaContainer",
        "design": {
          "type": "RepeatedData",
          "original": {
            "type": "MediaContainerDesignData",
            "background": {
              "type": "BackgroundMedia",
              "color": "{color_11}",
              "colorOpacity": 1,
              "alignType": "center",
              "fittingType": "fill",
              "scrollType": "none",
              "colorOverlay": "",
              "colorOverlayOpacity": 0,
              "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
              "cssBorder": {
                "width": {
                  "top": {
                    "value": 2,
                    "unit": "px"
                  },
                  "right": {
                    "value": 2,
                    "unit": "px"
                  },
                  "bottom": {
                    "value": 2,
                    "unit": "px"
                  },
                  "left": {
                    "value": 2,
                    "unit": "px"
                  }
                },
                "style": {
                  "top": "solid",
                  "right": "solid",
                  "left": "solid",
                  "bottom": "solid"
                },
                "color": {
                  "top": {
                    "red": 232,
                    "green": 230,
                    "blue": 230,
                    "alpha": 1
                  },
                  "right": {
                    "red": 232,
                    "green": 230,
                    "blue": 230,
                    "alpha": 1
                  },
                  "left": {
                    "red": 232,
                    "green": 230,
                    "blue": 230,
                    "alpha": 1
                  },
                  "bottom": {
                    "red": 232,
                    "green": 230,
                    "blue": 230,
                    "alpha": 1
                  }
                }
              },
              "cssBorderRadius": {
                "topLeft": {
                  "value": 0,
                  "unit": "px"
                },
                "topRight": {
                  "value": 0,
                  "unit": "px"
                },
                "bottomLeft": {
                  "value": 0,
                  "unit": "px"
                },
                "bottomRight": {
                  "value": 0,
                  "unit": "px"
                }
              }
            },
            "themeMappings": {
              "cssBorder.color.bottom": "color_12",
              "cssBorder.color.left": "color_12",
              "cssBorder.color.right": "color_12",
              "cssBorder.color.top": "color_12"
            },
            "charas": "design-k5v25ztj",
            "dataChangeBehaviors": []
          },
          "overrides": {
            "item1": {
              "type": "MediaContainerDesignData",
              "background": {
                "type": "BackgroundMedia",
                "color": "{color_11}",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {
                "cssBorder": {
                  "width": {
                    "top": {
                      "value": 2,
                      "unit": "px"
                    },
                    "right": {
                      "value": 2,
                      "unit": "px"
                    },
                    "bottom": {
                      "value": 2,
                      "unit": "px"
                    },
                    "left": {
                      "value": 2,
                      "unit": "px"
                    }
                  },
                  "style": {
                    "top": "solid",
                    "right": "solid",
                    "left": "solid",
                    "bottom": "solid"
                  },
                  "color": {
                    "top": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "right": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "left": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "bottom": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    }
                  }
                },
                "cssBorderRadius": {
                  "topLeft": {
                    "value": 0,
                    "unit": "px"
                  },
                  "topRight": {
                    "value": 0,
                    "unit": "px"
                  },
                  "bottomLeft": {
                    "value": 0,
                    "unit": "px"
                  },
                  "bottomRight": {
                    "value": 0,
                    "unit": "px"
                  }
                }
              },
              "themeMappings": {
                "cssBorder.color.bottom": "color_12",
                "cssBorder.color.left": "color_12",
                "cssBorder.color.right": "color_12",
                "cssBorder.color.top": "color_12"
              },
              "charas": "design-k5v25ztj",
              "dataChangeBehaviors": []
            },
            "item-k5uwo6us": {
              "type": "MediaContainerDesignData",
              "background": {
                "type": "BackgroundMedia",
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                  "scale": 1
                }
              },
              "cssStyle": {
                "cssBorder": {
                  "width": {
                    "top": {
                      "value": 2,
                      "unit": "px"
                    },
                    "right": {
                      "value": 2,
                      "unit": "px"
                    },
                    "bottom": {
                      "value": 2,
                      "unit": "px"
                    },
                    "left": {
                      "value": 2,
                      "unit": "px"
                    }
                  },
                  "style": {
                    "top": "solid",
                    "right": "solid",
                    "left": "solid",
                    "bottom": "solid"
                  },
                  "color": {
                    "top": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "right": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "left": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "bottom": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    }
                  }
                },
                "cssBorderRadius": {
                  "topLeft": {
                    "value": 0,
                    "unit": "px"
                  },
                  "topRight": {
                    "value": 0,
                    "unit": "px"
                  },
                  "bottomLeft": {
                    "value": 0,
                    "unit": "px"
                  },
                  "bottomRight": {
                    "value": 0,
                    "unit": "px"
                  }
                }
              },
              "themeMappings": {
                "cssBorder.color.bottom": "color_12",
                "cssBorder.color.left": "color_12",
                "cssBorder.color.right": "color_12",
                "cssBorder.color.top": "color_12"
              },
              "charas": "design-k5v0wjxo",
              "dataChangeBehaviors": []
            },
            "item-k5uwo7eq": {
              "type": "MediaContainerDesignData",
              "background": {
                "type": "BackgroundMedia",
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                  "scale": 1
                }
              },
              "cssStyle": {
                "cssBorder": {
                  "width": {
                    "top": {
                      "value": 2,
                      "unit": "px"
                    },
                    "right": {
                      "value": 2,
                      "unit": "px"
                    },
                    "bottom": {
                      "value": 2,
                      "unit": "px"
                    },
                    "left": {
                      "value": 2,
                      "unit": "px"
                    }
                  },
                  "style": {
                    "top": "solid",
                    "right": "solid",
                    "left": "solid",
                    "bottom": "solid"
                  },
                  "color": {
                    "top": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "right": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "left": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    },
                    "bottom": {
                      "red": 232,
                      "green": 230,
                      "blue": 230,
                      "alpha": 1
                    }
                  }
                },
                "cssBorderRadius": {
                  "topLeft": {
                    "value": 0,
                    "unit": "px"
                  },
                  "topRight": {
                    "value": 0,
                    "unit": "px"
                  },
                  "bottomLeft": {
                    "value": 0,
                    "unit": "px"
                  },
                  "bottomRight": {
                    "value": 0,
                    "unit": "px"
                  }
                }
              },
              "themeMappings": {
                "cssBorder.color.bottom": "color_12",
                "cssBorder.color.left": "color_12",
                "cssBorder.color.right": "color_12",
                "cssBorder.color.top": "color_12"
              },
              "charas": "design-k5v0wjxp",
              "dataChangeBehaviors": []
            }
          }
        },
        "style": {
          "type": "ComponentStyle",
          "style": {
            "properties": {},
            "propertiesSource": {},
            "groups": {}
          },
          "componentClassName": "wysiwyg.viewer.components.MediaContainer",
          "pageId": "",
          "compId": "",
          "styleType": "custom",
          "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        }
      }
    ],
    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
    "layout": {
      "width": 600,
      "height": 400,
      "x": 0,
      "y": 0,
      "scale": 1,
      "rotationInDegrees": 0,
      "fixedPosition": false
    },
    "componentType": "wysiwyg.viewer.components.Repeater",
    "data": {
      "type": "Repeater",
      "items": [
        "item1",
        "item-k5uwo6us",
        "item-k5uwo7eq"
      ]
    },
    "props": {
      "type": "CardsLayoutProperties",
      "alignment": "left",
      "gap": {
        "vertical": 0,
        "horizontal": 0
      }
    },
    "style": {
      "type": "ComponentStyle",
      "style": {
        "properties": {
          "alpha-bg": "0",
          "alpha-brd": "0",
          "bg": "61,155,233,1",
          "boxShadowToggleOn-shd": "false",
          "brd": "#324158",
          "brw": "0px",
          "rd": "0px",
          "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
        },
        "propertiesSource": {
          "alpha-bg": "value",
          "alpha-brd": "value",
          "bg": "value",
          "brd": "value",
          "brw": "value",
          "rd": "value",
          "shd": "value"
        },
        "groups": {}
      },
      "componentClassName": "mobile.core.components.Container",
      "pageId": "",
      "compId": "",
      "styleType": "custom",
      "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
    }
  },
  "wixui.StylableButton": {
    "type": "Component",
    "skin": "wixui.skins.Skinless",
    "layout": {
      "width": 200,
      "height": 50,
      "x": 0,
      "y": 0,
      "scale": 1,
      "rotationInDegrees": 0,
      "fixedPosition": false
    },
    "componentType": "wixui.StylableButton",
    "data": {
      "type": "StylableButton",
      "metaData": {
        "isPreset": false,
        "schemaVersion": "1.0",
        "isHidden": false,
        "pageId": "hn9lf"
      },
      "label": "Button",
      "svgId": "8a02b846b862a7b6973bdeff1bf009d3.svg"
    },
    "style": {
      "type": "ComponentStyle",
      "metaData": {
        "isPreset": false,
        "schemaVersion": "1.0",
        "isHidden": false,
        "pageId": "hn9lf"
      },
      "style": {
        "properties": {
          "$st-css": ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease;background: value(site_1_1)}.root:hover{background: #FFFFFF;border: 1px solid #000000}.root:hover::label{color: #000000}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;margin: 0px 4px 0px 0px;letter-spacing: 0em;font-family: lato-light,lato,sans-serif,HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;color: value(site_1_5);font-size: 15px}.root::icon{transition: inherit;margin: 0px 0px 0px 4px;width: 14px;height: 14px;fill: rgb(65,65,65)}.root:hover::icon{fill: #000000}"
        },
        "propertiesSource": {
          "$st-css": "value"
        }
      },
      "componentClassName": "wixui.StylableButton",
      "pageId": "",
      "compId": "",
      "styleType": "custom",
      "skin": "wixui.skins.Skinless"
    },
    "mobileStructure": {
      "layout": {
        "width": 200,
        "height": 50,
        "x": 40,
        "y": 10,
        "scale": 1,
        "rotationInDegrees": 0,
        "fixedPosition": false
      },
      "metaData": {
        "originalCompId": "comp-k7c218e9",
        "author": "duplicate"
      }
    },
    "props": {
      "type": "StylableButtonProperties"
    },
    "activeModes": {}
  }
}
