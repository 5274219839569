import _ from 'lodash';
import { TOGGLE_ADVANCED_A11Y_ENABLED } from './a11yActionTypes';

export const a11yInitialState = {
  isAdvancedA11yEnabled: false,
};

const a11yReducer = (a11yState = a11yInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case TOGGLE_ADVANCED_A11Y_ENABLED:
      return _.defaults(
        {
          isAdvancedA11yEnabled: !a11yState.isAdvancedA11yEnabled,
        },
        a11yState,
      );
    default:
      return a11yState;
  }
};

export default a11yReducer;
