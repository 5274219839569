import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export default {
  isShownOnAllPagesComponent(
    editorAPI: EditorAPI,
    componentsPointer: CompRef | CompRef[],
  ) {
    const components = Array.isArray(componentsPointer)
      ? componentsPointer
      : [componentsPointer];

    return components.some((component) => {
      return (
        editorAPI.components.isShowOnAllPages(component) &&
        !editorAPI.utils.isSameRef(
          component,
          editorAPI.dsRead.siteSegments.getFooter(),
        )
      );
    });
  },
};
