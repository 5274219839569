// @ts-nocheck
import _ from 'lodash';
import * as updateDataPlugins from './updateDataPlugins/updateDataPlugins';

function fix(data) {
  if (updateDataPlugins[data.type]) {
    updateDataPlugins[data.type](data);
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(data, function (field) {
    if (_.isPlainObject(field) && field.type) {
      fix(field);
    }
  });
  return data;
}

export { fix as fixData };
