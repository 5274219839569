const ASCEND_PRODUCT_ID = '73988963-5f5f-4f61-b6a1-fd004df31b00';

const ASCEND_FETCH_STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
} as const;

export default {
  ASCEND_PRODUCT_ID,
  ASCEND_FETCH_STATUS,
};
