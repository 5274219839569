import _ from 'lodash';
import * as reselect from 'reselect';
import * as mobile from '../mobile/mobile';

import type { EditorState } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { GuidesState } from './rulersReducer';

const mobileSelectors = mobile.selectors;
const { createSelector } = reselect;

const getHistoryState = (state: EditorState) =>
  state.rulers.history.stack[state.rulers.history.currentIndex];

const getGuidesState = (state: EditorState) => state.rulers.guidesState;

const getCurrentViewModeGuides = createSelector(
  getGuidesState,
  mobileSelectors.isMobileEditor,
  (guidesState, isMobileEditor) =>
    isMobileEditor ? guidesState.mobile : guidesState.desktop,
);

const getHorizontalGuides = createSelector(
  getCurrentViewModeGuides,
  (currentViewModeGuides) => currentViewModeGuides.horizontalGuides,
);

const getVerticalGuides = createSelector(
  getCurrentViewModeGuides,
  (currentViewModeGuides) => currentViewModeGuides.verticalGuides,
);

const getVerticalGuideRealPosition = (
  editorAPI: EditorAPI,
  guidePosition: number,
) =>
  guidePosition +
  Math.round(editorAPI.siteSegments.getPagesContainerAbsLayout().x);

const getSelectedGuideDataById = (
  guidesState: GuidesState,
  selectedGuideId: string,
) => {
  if (!selectedGuideId) {
    return null;
  }
  let guidePath;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  if (_.some(guidesState.mobile.verticalGuides, ['guideId', selectedGuideId])) {
    guidePath = 'mobile.verticalGuides';
  } else if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(guidesState.mobile.horizontalGuides, ['guideId', selectedGuideId])
  ) {
    guidePath = 'mobile.horizontalGuides';
  } else if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(guidesState.desktop.verticalGuides, ['guideId', selectedGuideId])
  ) {
    guidePath = 'desktop.verticalGuides';
  } else if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(guidesState.desktop.horizontalGuides, ['guideId', selectedGuideId])
  ) {
    guidePath = 'desktop.horizontalGuides';
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const { guidePosition } = _.find(_.get(guidesState, guidePath), {
    guideId: selectedGuideId,
  });

  return {
    guideId: selectedGuideId,
    guidePosition,
    guidePath,
  };
};

const getSelectedGuideId = (state: EditorState) => state.rulers.selectedGuide;

const getSelectedGuideData = createSelector(
  getSelectedGuideId,
  getGuidesState,
  (selectedGuideId, guidesState) =>
    getSelectedGuideDataById(guidesState, selectedGuideId),
);

const isRulersEnabled = (state: EditorState) => state.viewTools.rulersEnabled;

const canUndo = (state: EditorState) => state.rulers.history.currentIndex > 0;

const canRedo = (state: EditorState) =>
  state.rulers.history.stack.length > state.rulers.history.currentIndex + 1;

const isHovered = (state: EditorState) => state.rulers.isHovered;

export {
  getSelectedGuideId,
  getHistoryState,
  getGuidesState,
  getCurrentViewModeGuides,
  getHorizontalGuides,
  getVerticalGuides,
  getVerticalGuideRealPosition,
  getSelectedGuideData,
  isRulersEnabled,
  isHovered,
  canUndo,
  canRedo,
};
