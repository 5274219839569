import * as stateManagement from '@/stateManagement';
import * as helpIds from '@/helpIds';

import type { EditorAPI } from '@/editorAPI';
import type { CompLayout } from 'types/documentServices';

export function shouldShowNotificationFreezeWarning(
  componentType: string,
  layout: CompLayout,
) {
  return (
    !layout.fixedPosition &&
    layout.height > 200 &&
    (componentType === 'SITE_HEADER' || componentType === 'SITE_FOOTER')
  );
}

export function showNotificationFreezeWarning(
  editorAPI: EditorAPI,
  componentType?: string,
) {
  const [helpId, tanslationKey] =
    componentType === 'SITE_HEADER'
      ? [
          helpIds.NOTIFICATIONS.FROZEN_HEADER,
          'Notifications_Frozen_Header_Text',
        ]
      : [
          helpIds.NOTIFICATIONS.FROZEN_FOOTER,
          'Notifications_Frozen_Footer_Text',
        ];
  const onNotificationLinkClick = () => {
    editorAPI.panelManager.openHelpCenter(helpId);
  };

  editorAPI.store.dispatch(
    stateManagement.notifications.actions.showUserActionNotification({
      message: tanslationKey,
      title: tanslationKey,
      type: 'warning',
      link: {
        caption: 'Notifications_Learn_More_Link',
        onNotificationLinkClick,
      },
    }),
  );
}
