// @ts-nocheck
import _ from 'lodash';
import * as boxSlideShowSelectors from '../boxSlideShow/boxSlideShowSelectors';
import type { DSRead } from 'types/documentServices';

const getWidgetApiTestProperties = (state) =>
  state.applicationStudio.widgetApiTestProperties;
const getCurrBuildVersion = (state) =>
  state.applicationStudio.packageAndPublish.currBuildVersion;
const getCurrTaskDisplayName = (state) =>
  state.applicationStudio.packageAndPublish.currTask;
const isFirstAppBuild = (state) =>
  state.applicationStudio.packageAndPublish.isFirstAppBuild;
const isPackageInProgress = (state) =>
  state.applicationStudio.packageAndPublish.isPackaging;
const getNumOfTasksDone = (state) =>
  state.applicationStudio.packageAndPublish.numOfTasksDone;
const getPackageErrorMessage = (state) =>
  state.applicationStudio.packageAndPublish.errorMessage;
const getPackageStep = (state) =>
  state.applicationStudio.packageAndPublish.packageStep;
const getTotalNumOfPackageTasks = (state) =>
  state.applicationStudio.packageAndPublish.totalNumOfPackageTasks;
const getAppLink = (state) => state.applicationStudio.appLink.link;
const isCreatingAppLink = (state) => state.applicationStudio.appLink.creating;
const isCreateAppLinkFail = (state) => state.applicationStudio.appLink.fail;
const isRevokingAppLink = (state) => state.applicationStudio.appLink.revoking;
const isRevokeAppLinkFail = (state) =>
  state.applicationStudio.appLink.failRevoke;
const getAppDefId = (state) => state.applicationStudio.appInfo.appDefId;
const getAppName = (state) => state.applicationStudio.appInfo.appName;
const getAppVersion = (state) =>
  state.applicationStudio.packageAndPublish.appVersion;
const getNamespace = (state) => state.applicationStudio.appInfo.namespace;
const getWidgetDesignPanelSplitterHeight = (state) =>
  state.applicationStudio.widgetDesignPanelSplitterHeight;
const getWidgetsData = (state) =>
  state.applicationStudio.devCenterSettings.widgetsData;
const getSiteHeaderUrl = (state) =>
  state.applicationStudio.devCenterSettings.siteHeaderUrl;
const getControllerWidgetMap = (state) =>
  state.applicationStudio.controllerWidgetMap;
const getBlocksVersion = (state) =>
  state.applicationStudio.packageAndPublish.blocksVersion;

const getWidgetDisplayName = (state, dsRead, compRef) => {
  const pointer = getControllerWidgetMap(state)[compRef.id];
  if (pointer) {
    return dsRead.appStudio.widgets.name.get(pointer);
  }
};
const getAppWidgetByWidgetPointer = (dsRead, widgetPointer) => {
  const rootCompId = dsRead.appStudio.widgets.getRootCompId(widgetPointer);
  const pageRef = dsRead.components.get.byId(rootCompId);
  return dsRead.appStudio.widgets.getRootWidgetByPage(pageRef);
};

const isWixBlocksCodePackageEnvironment = (dsRead: DSRead) => {
  const getAppType = dsRead.appStudio?.getAppType;
  return getAppType ? getAppType() === 'CODE_PACKAGE' : false;
};

const getWidgetPrimaryContainer = (dsRead, container) => {
  const type = dsRead.components.getType(container);
  let primaryContainer;
  switch (type) {
    case 'wysiwyg.viewer.components.StateBox':
      primaryContainer = boxSlideShowSelectors.findCurrentSlide(
        dsRead,
        container,
      );
      break;
    case 'wysiwyg.viewer.components.Repeater':
      primaryContainer = _.head(
        dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
          container,
        ),
      );
      break;
    default:
      primaryContainer = container;
  }
  if (!_.isEqual(primaryContainer, container)) {
    return getWidgetPrimaryContainer(dsRead, primaryContainer);
  }
  return container;
};

function isWidgetContainer(dsRead, widgetRoot) {
  const firstChild = _.head(
    dsRead.deprecatedOldBadPerformanceApis.components.getChildren(widgetRoot),
  );
  return firstChild ? dsRead.components.is.container(firstChild) : true;
}

const isWidgetRootContainer = (dsRead, widgetPointer) => {
  const appWidgetRef = getAppWidgetByWidgetPointer(dsRead, widgetPointer);
  return isWidgetContainer(dsRead, appWidgetRef);
};

const isStrictColorPicker = (state) =>
  state.applicationStudio.isStrictColorPicker;

export {
  getBlocksVersion,
  getWidgetApiTestProperties,
  getWidgetPrimaryContainer,
  getAppWidgetByWidgetPointer,
  getCurrBuildVersion,
  getCurrTaskDisplayName,
  isFirstAppBuild,
  isPackageInProgress,
  getNumOfTasksDone,
  getPackageErrorMessage,
  getPackageStep,
  getTotalNumOfPackageTasks,
  getAppLink,
  getWidgetDesignPanelSplitterHeight,
  isWidgetRootContainer,
  isWidgetContainer,
  isCreatingAppLink,
  isCreateAppLinkFail,
  isRevokingAppLink,
  isRevokeAppLinkFail,
  getAppName,
  getAppVersion,
  getNamespace,
  getAppDefId,
  getControllerWidgetMap,
  getWidgetDisplayName,
  getWidgetsData,
  getSiteHeaderUrl,
  isStrictColorPicker,
  isWixBlocksCodePackageEnvironment,
};
