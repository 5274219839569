import * as draggableSlotsActionTypes from './draggableSlotsActionTypes';
import type { Box } from 'types/core';

const {
  REGISTER_DRAG_COMPONENT,
  UPDATE_DRAG_COMPONENT_POSITION,
  UNREGISTER_DRAG_COMPONENT,
} = draggableSlotsActionTypes;

export type DraggableSlotId =
  | 'INTERACTION_MODE_BAR'
  | 'LIGHTBOX_MODE_BAR'
  | 'MULTILINGUAL_MODE_BAR';

export const DRAGGABLE_SLOTS: {
  [slotId in DraggableSlotId]: DraggableSlotId;
} = {
  INTERACTION_MODE_BAR: 'INTERACTION_MODE_BAR',
  LIGHTBOX_MODE_BAR: 'LIGHTBOX_MODE_BAR',
  MULTILINGUAL_MODE_BAR: 'MULTILINGUAL_MODE_BAR',
};

export interface DraggableSlotsState {
  [slotId: string]: Box | null;
}

export const draggableSlotsInitialState: DraggableSlotsState = {
  [DRAGGABLE_SLOTS.INTERACTION_MODE_BAR]: null,
  [DRAGGABLE_SLOTS.LIGHTBOX_MODE_BAR]: null,
  [DRAGGABLE_SLOTS.MULTILINGUAL_MODE_BAR]: null,
};

const reducer = (
  draggableSlotsState: DraggableSlotsState = draggableSlotsInitialState,
  action: AnyFixMe,
): DraggableSlotsState => {
  switch (action.type) {
    case REGISTER_DRAG_COMPONENT:
      return {
        ...draggableSlotsState,
        [action.slotId]: action.initialPosition,
      };
    case UPDATE_DRAG_COMPONENT_POSITION:
      return { ...draggableSlotsState, [action.slotId]: action.newPosition };
    case UNREGISTER_DRAG_COMPONENT: {
      return {
        ...draggableSlotsState,
        [action.slotId]: null,
      };
    }
    default:
      return draggableSlotsState;
  }
};

export default reducer;
