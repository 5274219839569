// @ts-nocheck
import _ from 'lodash';

function updateEffectPropertyOnMobile(properties, compRef, dsRead, dsActions) {
  const effect = _.pick(properties, 'filterEffect');
  if (_.isEmpty(effect)) {
    return;
  }
  const mobileMode = dsRead.viewMode.VIEW_MODES.MOBILE;
  const pageId = dsRead.components.getPage(compRef).id;
  const mobileComp = dsRead.components.get.byId(compRef.id, pageId, mobileMode);

  if (
    mobileComp &&
    dsRead.components.is.exist(mobileComp) &&
    dsRead.components.properties.mobile.isForked(mobileComp)
  ) {
    dsActions.components.properties.update(mobileComp, effect);
  }
}

export default function wPhotoPropertiesPlugin(
  properties,
  compRef,
  dsRead,
  dsActions,
  isMobileEditor,
) {
  if (!isMobileEditor) {
    updateEffectPropertyOnMobile(properties, compRef, dsRead, dsActions);
  }
  return properties;
}
