import { stageLoaderActionTypes as actionTypes } from './stageLoaderActionTypes';

const startStageLoading = () => ({
  type: actionTypes.START_STAGE_LOADING,
});

const endStageLoading = () => ({
  type: actionTypes.END_STAGE_LOADING,
});

export { startStageLoading, endStageLoading };
