import * as reselect from 'reselect';
import type { createSelector as _createSelector } from 'reselect';
import type { StateMapperArgs } from 'types/redux';

const selectorValSymb = Symbol('selector');

export type Selector<I, O> = (input: I) => O;

export function isSelector(val: any): val is Selector<unknown, unknown> {
  return typeof val === 'function' && Boolean(val[selectorValSymb]);
}

function isEqual(a: AnyFixMe, b: AnyFixMe) {
  return a === b;
}

const markSel = <T>(fn: T): T =>
  //@ts-expect-error
  function (...args) {
    //@ts-expect-error
    const sel = fn.apply(undefined, args);
    sel[selectorValSymb] = true;
    let dependencies = args.slice(0, -1);
    dependencies = Array.isArray(dependencies[0])
      ? dependencies[0]
      : dependencies;
    sel.dependencies = dependencies;
    sel.dependsOnOwnProps = false;
    return sel;
  };

export const createSelector: typeof _createSelector = markSel(
  reselect.createSelectorCreator(reselect.defaultMemoize, isEqual),
);

interface CreateStructuredSelector {
  <T>(
    selectors: { [K in keyof T]: Selector<StateMapperArgs, T[K]> },
    selectorCreator?: typeof createSelector,
  ): Selector<StateMapperArgs, T>;
}

export const createStructuredSelector: CreateStructuredSelector = (selectors) =>
  reselect.createStructuredSelector(selectors, createSelector);
