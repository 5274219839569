// @ts-nocheck
import actionTypes from './helpPanelActionTypes';

const { OPEN_HELP_CENTER, CLOSE_HELP_CENTER } = actionTypes;

const reducer = (helpProps = null, action) => {
  switch (action.type) {
    case OPEN_HELP_CENTER:
      return action.helpPanelProps;
    case CLOSE_HELP_CENTER:
      return null;
    default:
      return helpProps;
  }
};

export default reducer;
