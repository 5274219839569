import _ from 'lodash';
import selectionActionTypes from './selectionActionTypes';
import type { CompRef } from 'types/documentServices';
import type { Point } from 'types/core';

export interface SelectionState {
  selectedComponents: CompRef[];
  prevSelectedComponents: CompRef[];
  appContainer: CompRef | null;
  focusedContainer: CompRef;
  ancestorOfSelectedWithInteraction: CompRef | null;
  focusBoxTabsDef: unknown;
  compRestrictions: any;
  lastSelectionClickPos: Point | null;
  lastClickType: 'left' | 'right';
  compDisplayName: string;
}

const {
  SET_FOCUSED_CONTAINER,
  SET_LAST_SELECTION_CLICK_POS,
  SELECT_COMPONENTS,
  ADD_TO_SELECTION,
  REMOVE_FROM_SELECTION,
  CLEAR_SELECTED_COMPONENTS,
  SET_SELECTED_COMPS_RESTRICTIONS,
  SET_SELECTED_COMPS_DISPLAY_NAME,
  SET_APP_CONTAINER,
  SET_FOCUSED_TABS_DEF,
  SET_ANCESTOR_OF_SELECTED_WITH_INTERACTION,
  SET_LAST_CLICK_TYPE,
  SET_PREV_SELECTED_COMPS,
} = selectionActionTypes;

export const selectionInitialState: SelectionState = {
  selectedComponents: [],
  prevSelectedComponents: [],
  compRestrictions: {},
  compDisplayName: '',
  lastClickType: null,
  lastSelectionClickPos: null,
  focusedContainer: null,
  focusBoxTabsDef: null,
  appContainer: null,
  ancestorOfSelectedWithInteraction: null,
};

const reducer = (selection = selectionInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case SELECT_COMPONENTS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (_.isArray(action.compRefs)) {
        return _.defaults(
          {
            selectedComponents: action.compRefs,
          },
          selection,
        );
      }
      console.error('selected comps must be an array');
      return selection;
    case SET_PREV_SELECTED_COMPS:
      return _.defaults(
        {
          prevSelectedComponents: action.compRefs,
        },
        selection,
      );
    case ADD_TO_SELECTION:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (_.isArray(action.compRefs)) {
        return _.defaults(
          {
            selectedComponents: selection.selectedComponents.concat(
              action.compRefs,
            ),
          },
          selection,
        );
      }
      console.error('selected comps must be an array');
      return selection;
    case REMOVE_FROM_SELECTION:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (_.isArray(action.compRefs)) {
        return _.defaults(
          {
            selectedComponents: _.reject(
              selection.selectedComponents,
              (selectedComp) =>
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line you-dont-need-lodash-underscore/find
                _.find(action.compRefs, (rejectedComp) =>
                  _.isEqual(rejectedComp, selectedComp),
                ),
            ),
          },
          selection,
        );
      }
      console.error('selected comps must be an array');
      return selection;
    case CLEAR_SELECTED_COMPONENTS:
      return _.defaults(
        {
          selectedComponents: [],
        },
        selection,
      );
    case SET_SELECTED_COMPS_RESTRICTIONS:
      return _.defaults(
        {
          compRestrictions: action.restrictions,
        },
        selection,
      );
    case SET_SELECTED_COMPS_DISPLAY_NAME:
      return _.defaults(
        {
          compDisplayName: action.compDisplayName,
        },
        selection,
      );
    case SET_LAST_SELECTION_CLICK_POS:
      return _.defaults(
        {
          lastSelectionClickPos: action.pos,
        },
        selection,
      );
    case SET_FOCUSED_CONTAINER:
      return _.defaults(
        {
          focusedContainer: action.focusedContainer || null,
        },
        selection,
      );
    case SET_APP_CONTAINER:
      return _.defaults(
        {
          appContainer: action.appContainer || null,
        },
        selection,
      );
    case SET_FOCUSED_TABS_DEF:
      return _.defaults(
        {
          focusBoxTabsDef: action.tabsDef,
        },
        selection,
      );
    case SET_ANCESTOR_OF_SELECTED_WITH_INTERACTION:
      return _.defaults(
        {
          ancestorOfSelectedWithInteraction:
            action.ancestorOfSelectedWithInteraction,
        },
        selection,
      );
    case SET_LAST_CLICK_TYPE:
      return _.defaults(
        {
          lastClickType: action.clickType,
        },
        selection,
      );
    default:
      return selection;
  }
};

export default reducer;
