import type { IntroFunnelIntents } from './introFunnelTypes';

export function getAppIntents(state: AnyFixMe): IntroFunnelIntents[] {
  return state?.introFunnel?.introFunnelIntents ?? [];
}

export function hasAppIntent(state: AnyFixMe): boolean {
  const introFunnelAppIntents = getAppIntents(state);
  return introFunnelAppIntents.length > 0;
}
