export default {
  COLLAGE_GALLERY: 'COLLAGE_GALLERY',
  FREESTYLE_GALLERY: 'FREESTYLE_GALLERY',
  MATRIX_GALLERY: 'MATRIX_GALLERY',
  HONEYCOMB_GALLERY: 'HONEYCOMB_GALLERY',
  PAGINATED_GRID_GALLERY: 'PAGINATED_GRID_GALLERY',
  SLIDESHOW_STRIP_GALLERY: 'SLIDESHOW_STRIP_GALLERY',
  SHOWCASE_STRIP_GALLERY: 'SHOWCASE_STRIP_GALLERY',
  IMPRESS_GALLERY: 'IMPRESS_GALLERY',
  ACCORDION_GALLERY: 'ACCORDION_GALLERY',
  MASONRY_GALLERY: 'MASONRY_GALLERY',
  SLIDER_GALLERY: 'SLIDER_GALLERY',
  SLIDESHOW_GALLERY: 'SLIDESHOW_GALLERY',
  THUMBNAIL_GALLERY: 'THUMBNAIL_GALLERY',
  CAROUSEL_3D_GALLERY: 'CAROUSEL_3D_GALLERY',
  SLIDESHOW_3D_GALLERY: 'SLIDESHOW_3D_GALLERY',
  BOX_SLIDE_SHOW: 'BOX_SLIDE_SHOW',
  POPUP_CONTAINER: 'POPUP_CONTAINER',
  POPUP_OVERLAY: 'POPUP_OVERLAY',
  CLOSE_BUTTON: 'CLOSE_BUTTON',
  SITE_BUTTON: 'SITE_BUTTON',
  PAGINATION: 'PAGINATION',
  RATINGS_DISPLAY: 'RATINGS_DISPLAY',
  RATINGS_INPUT: 'RATINGS_INPUT',
  STATE_BOX: 'STATE_BOX',
};
