const isPanelOpen = (state: AnyFixMe) => state.editorSearch.isPanelOpen;
const getPanelOpenOrigin = (state: AnyFixMe) => state.editorSearch.openOrigin;

const isEnabled = (state: AnyFixMe) => state.editorSearch.enabled;

export const editoSearchSelectors = {
  isPanelOpen,
  getPanelOpenOrigin,
  isEnabled,
};
