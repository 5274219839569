const SAFETY_CHECK_REST_URL = '/_serverless/wix-education-editor/safetyCheck';

import * as topBar from '../topBar/topBar';
import * as leftBar from '../leftBar/leftBar';
import * as externalLinks from '../externalLinks/externalLinks';
import { editorSearch } from '../editorSearch/editorSearch';
import { safeTopBarOverrides } from './overrides/safeTopBarOverrides';
import { externalOverrides } from './overrides/externalLinksOverrides';
import { leftBarOverrides } from './overrides/leftBarOverrides';
import { schoolModeActionTypes } from './schoolModeActionTypes';
import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';
import type { EditorState } from '@/stateManagement';
import type { Dispatch } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import { isEnabled } from './schoolModeSelectors';
import _ from 'lodash';

// const IS_EDUCATION_REST_URL = '/_serverless/wix-education/isEducationUser';

const safetyCheck = async () => {
  try {
    const safetyCheckResponse = await fetch(SAFETY_CHECK_REST_URL);
    const safetyCheckJson = await safetyCheckResponse.json();
    if (safetyCheckJson.isStudent) {
      window.open(safetyCheckJson.educationDashboardUrl, '_self');
    }
  } catch (e) {
    console.warn('schoolActions error: ', e);
  }
};

const schoolSafeCheck = () => () => {
  if (experiment.isOpen('se_ForceSchoolMode')) {
    return;
  }
  safetyCheck();
};

const enableSchoolMode = () => ({
  type: schoolModeActionTypes.ENABLE_SCHOOL_MODE,
});

const notifySiteWasSave =
  () =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    if (!isEnabled(getState())) {
      return;
    }
    const SBS_DEV_APP_DEF_ID = 'efe26092-a75a-4b84-bbc6-20bdc880bcee';
    const SBS_APP_DEF_ID = '03ba136e-332a-47c8-b896-4fda680c89c7';
    const PROJECT_APP_DEF_ID = 'd52bbb6a-7b7e-4879-94d1-01e72ac42ebb';

    const getAppId = (appDefId: any) =>
      _.get(
        editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId),
        'applicationId',
      );

    const eventType = 'siteWasSaved';
    const eventPayload = {};

    const notifyApp = (appDefId: any) => {
      const appId = getAppId(appDefId);

      if (!appId) {
        return;
      }

      editorAPI.dsActions.platform.notifyApplication(appId, {
        eventType,
        eventPayload,
      });
    };

    notifyApp(SBS_DEV_APP_DEF_ID);
    notifyApp(SBS_APP_DEF_ID);
    notifyApp(PROJECT_APP_DEF_ID);
  };

const activateMode = (
  dispatch: Dispatch,
  getState: () => EditorState,
  editorAPI: EditorAPI,
) => {
  // TODO check what discoveribiliy was - may not be needed anymore since it seems to be gone
  // dispatch(topBar.actions.setDiscoveryVisibilty(false));

  //Top Bar
  dispatch(topBar.actions.overrideItems(safeTopBarOverrides(editorAPI)));
  // //Left Bar
  dispatch(leftBar.actions.overrideItems(leftBarOverrides));
  // remove mobile quick action bar from left panel
  dispatch(stateManagement.mobile.actions.qab.disableMobileQab());
  // External Link - terms of use and privacy
  dispatch(externalLinks.actions.overrideExternalLinks(externalOverrides));
  // remove editor search from top bar and addPanel
  dispatch(editorSearch.actions.disable());

  dispatch(enableSchoolMode());
};

const activateModeIfNeeded =
  () =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    if (experiment.isOpen('se_ForceSchoolMode')) {
      activateMode(dispatch, getState, editorAPI);
    }
    // } else {
    //   fetch(IS_EDUCATION_REST_URL)
    //     .then((response) => response.json())
    //     .then((isStudent) => {
    //       if (isStudent) {
    //         activateMode(dispatch, getState, editorAPI);
    //       }
    //     });
    // }
  };
export { schoolSafeCheck, activateModeIfNeeded, notifySiteWasSave };
