import coreUtilsLib from 'coreUtilsLib';

const getAttributesString = (attributes: any[]): string =>
  attributes.reduce(
    (result, attrObj) => `${result} ${attrObj.name}="${attrObj.escaped}" `,
    '',
  );

export const insertTextInHtml = (html: AnyFixMe, text: AnyFixMe) => {
  const tagStack: AnyFixMe = [];
  let stop = false;

  coreUtilsLib.htmlParser(html, {
    start(tagName: AnyFixMe, attributes: AnyFixMe, selfClosing: AnyFixMe) {
      if (!stop && !selfClosing) {
        tagStack.push({
          tagName,
          attributes,
        });
      }

      return {
        tagName,
        attributes,
        selfClosing,
      };
    },
    end(tag: AnyFixMe) {
      if (!stop) {
        //if we are here than tag is empty
        tagStack.pop();
      }

      return tag;
    },
    chars(text: AnyFixMe) {
      stop = true;

      return text;
    },
  });

  let openTags = '';
  let closingTags = '';

  for (const { tagName, attributes } of tagStack) {
    openTags += attributes
      ? `<${tagName} ${getAttributesString(attributes)}>`
      : `<${tagName}>`;
    closingTags = `</${tagName}>${closingTags}`;
  }

  const textWithLineBreaks = text.replace(/\n/g, '<br />');

  return openTags + textWithLineBreaks + closingTags;
};
