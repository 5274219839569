import _ from 'lodash';
import { applicationStudioInitialState } from '../applicationStudioReducer';
import actionTypes from '../applicationStudioActionTypes';

const packageAndPublishReducer = (
  packageAndPublish = applicationStudioInitialState.packageAndPublish,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_CURR_PACKAGE_TASK:
      return _.defaults({ currTask: action.displayName }, packageAndPublish);
    case actionTypes.SET_APP_VERSION:
      return _.defaults({ appVersion: action.appVersion }, packageAndPublish);
    case actionTypes.SET_PACKAGE_ERROR:
      return _.defaults(
        { errorMessage: action.errorMessage },
        packageAndPublish,
      );
    case actionTypes.SET_NUM_OF_TASKS_DONE:
      return _.defaults(
        { numOfTasksDone: action.numOfTasksDone },
        packageAndPublish,
      );
    case actionTypes.SET_TOTAL_NUM_OF_PACKAGE_TASKS:
      return _.defaults(
        { totalNumOfPackageTasks: action.totalNumOfPackageTasks },
        packageAndPublish,
      );
    case actionTypes.SET_PACKAGE_STEP:
      return _.defaults({ packageStep: action.packageStep }, packageAndPublish);
    case actionTypes.SET_IS_FIRST_APP_BUILD:
      return _.defaults(
        { isFirstAppBuild: action.isFirstAppBuild },
        packageAndPublish,
      );
    case actionTypes.SET_CURR_BUILD_VERSION:
      return _.defaults(
        { currBuildVersion: action.currBuildVersion },
        packageAndPublish,
      );
    case actionTypes.SET_IS_PACKAGING:
      return _.defaults(
        { isPackaging: action.isPackageInProgress },
        packageAndPublish,
      );
    case actionTypes.SET_BLOCKS_VERSION:
      return _.defaults(
        {
          blocksVersion: action.blocksVersion,
        },
        packageAndPublish,
      );
    default:
      return packageAndPublish;
  }
};

export default packageAndPublishReducer;
