import * as actionTypes from './helpHomeActionTypes';

export interface HelpHomeState {
  isPotentialPartner: boolean | null;
}

export const helpHomeInitialState: HelpHomeState = {
  isPotentialPartner: null,
};

const reducer = (
  state: HelpHomeState = helpHomeInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_PARTNER_STATUS:
      return { ...state, isPotentialPartner: action.payload };
    default:
      return state;
  }
};

export default reducer;
