import virtualNumbersConstants from './constants';
import type { VirtualNumbersState } from './types';

const isAccountActive = ({
  virtualNumbers,
}: {
  virtualNumbers: VirtualNumbersState;
}): boolean =>
  virtualNumbers.status ===
  virtualNumbersConstants.VIRTUAL_NUMBERS_ACCOUNT_STATUS.ACTIVE;

const isFetching = ({
  virtualNumbers,
}: {
  virtualNumbers: VirtualNumbersState;
}) =>
  virtualNumbers.fetchStatus ===
  virtualNumbersConstants.VIRTUAL_NUMBERS_FETCH_STATUS.LOADING;

const fetchSucceed = ({
  virtualNumbers,
}: {
  virtualNumbers: VirtualNumbersState;
}) =>
  virtualNumbers.fetchStatus ===
  virtualNumbersConstants.VIRTUAL_NUMBERS_FETCH_STATUS.SUCCESS;

const getBusinessNumber = ({
  virtualNumbers,
}: {
  virtualNumbers: VirtualNumbersState;
}) => virtualNumbers.businessNumber;

const getVirtualNumbersAccountId = ({
  virtualNumbers,
}: {
  virtualNumbers: VirtualNumbersState;
}) => virtualNumbers.virtualNumbersAccountId;

export default {
  isAccountActive,
  isFetching,
  fetchSucceed,
  getBusinessNumber,
  getVirtualNumbersAccountId,
};
