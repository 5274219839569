import _ from 'lodash';
import { combineReducers } from 'redux';

import actionTypes, {
  type SilentInstallAction,
  type SilentInstallUsedOnSiteAction,
} from './platformActionTypes';
import addElementsReducer from './addElements/addElementsReducer';

export interface VersionsMap {
  [appDefId: string]: string;
}

export interface PlatformState {
  silentInstall: {
    isRunning: boolean;
    usedOnSite: boolean;
  };
  availableApps: AnyFixMe;
  freshAppsData: AnyFixMe[];
  latestVersions: VersionsMap;
  appInstallationInProcess: boolean;
  importPanel: {
    isLoading: boolean;
    isLoadingAppsError: boolean;
  };
  addElementsPanel: {
    masterStructure: null;
  };
  packagesView: {
    isRootExpanded: boolean;
    isImportingApp: boolean;
  };
  loadBiEvents: {
    alreadyBeenSet: boolean;
    hasStartLoadingPlatformBeenReported: boolean;
    hasStartLoadingEditorScriptsBeenReported: boolean;
    hasEditorScriptsLoadedReported: boolean;
    hasAllAppManifestsLoadedReported: boolean;
    loadTimeAppsManifestLoadedIndicator: AnyFixMe;
  };
}

export const platformInitialState: PlatformState = {
  silentInstall: {
    isRunning: false,
    usedOnSite: false,
  },
  availableApps: null,
  freshAppsData: [],
  latestVersions: {},
  appInstallationInProcess: false,
  importPanel: {
    isLoading: false,
    isLoadingAppsError: false,
  },
  addElementsPanel: {
    masterStructure: null,
  },
  packagesView: {
    isRootExpanded: true,
    isImportingApp: false,
  },
  loadBiEvents: {
    alreadyBeenSet: false,
    hasStartLoadingPlatformBeenReported: false,
    hasStartLoadingEditorScriptsBeenReported: false,
    hasEditorScriptsLoadedReported: false,
    hasAllAppManifestsLoadedReported: false,
    loadTimeAppsManifestLoadedIndicator: {},
  },
};

const availableAppsReducer = (
  availableApps = platformInitialState.availableApps,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_AVAILABLE_APPS:
      return action.apps;
    default:
      return availableApps;
  }
};

const latestVersionsReducer = (
  latestVersions = platformInitialState.latestVersions,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_LATEST_VERSIONS:
      return action.latestVersions;
    default:
      return latestVersions;
  }
};

const appInstallationInProcessReducer = (
  installationInProgress = platformInitialState.appInstallationInProcess,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_IS_APP_INSTALLATION_IN_PROGRESS:
      return action.installationInProgress;
    default:
      return installationInProgress;
  }
};

const freshAppsDataReducer = (
  freshAppsData = platformInitialState.freshAppsData,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_FRESH_APPS_DATA:
      return action.appsData || freshAppsData;
    default:
      return freshAppsData;
  }
};

const importPanelReducer = (
  importPanel = platformInitialState.importPanel,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_IS_LOADING:
      return _.defaults({ isLoading: action.isLoading }, importPanel);
    case actionTypes.SET_IS_LOADING_APPS_ERROR:
      return _.defaults(
        { isLoadingAppsError: action.isLoadingAppsError },
        importPanel,
      );
    default:
      return importPanel;
  }
};

const packagesViewReducer = (
  packagesView = platformInitialState.packagesView,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.TOGGLE_PACKAGES_VIEW_ROOT:
      return _.defaults(
        { isRootExpanded: !packagesView.isRootExpanded },
        packagesView,
      );
    case actionTypes.SET_IS_IMPORTING_APP:
      return _.defaults(
        { isImportingApp: action.isImportingApp },
        packagesView,
      );
    default:
      return packagesView;
  }
};

const loadBiEventsReducer = (
  loadBiEvents = platformInitialState.loadBiEvents,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_START_LOADING_EDITOR_SCRIPTS_REPORTED:
      return _.defaults(
        {
          hasStartLoadingEditorScriptsBeenReported: true,
        },
        loadBiEvents,
      );
    case actionTypes.SET_START_LOADING_PLATFORM_REPORTED:
      return _.defaults(
        {
          hasStartLoadingPlatformBeenReported: true,
        },
        loadBiEvents,
      );
    case actionTypes.SET_APPS_TO_MEASURE_LOADING_TIME:
      if (!loadBiEvents.alreadyBeenSet) {
        return _.defaults(
          {
            alreadyBeenSet: true,
            loadTimeAppsManifestLoadedIndicator: _.mapValues(
              _.keyBy(action.apps, (app) => app.appDefinitionId),
              () => false,
            ),
          },
          loadBiEvents,
        );
      }

      return loadBiEvents;
    case actionTypes.SET_APP_MANIFEST_HAS_BEEN_LOADED:
      if (
        _.hasIn(
          loadBiEvents.loadTimeAppsManifestLoadedIndicator,
          action.app.appDefinitionId,
        )
      ) {
        return _.defaults(
          {
            loadTimeAppsManifestLoadedIndicator: _.defaults(
              {
                [action.app.appDefinitionId]: true,
              },
              loadBiEvents.loadTimeAppsManifestLoadedIndicator,
            ),
          },
          loadBiEvents,
        );
      }

      return loadBiEvents;
    case actionTypes.SET_ALL_APP_MANIFESTS_LOADED_REPORTED:
      return _.defaults(
        {
          hasAllAppManifestsLoadedReported: true,
        },
        loadBiEvents,
      );
    case actionTypes.SET_EDITOR_SCRIPTS_LOADED_REPORTED:
      return _.defaults(
        {
          hasEditorScriptsLoadedReported: true,
        },
        loadBiEvents,
      );
    default:
      return loadBiEvents;
  }
};

const silentInstallReducer = (
  silentInstall = platformInitialState.silentInstall,
  action: SilentInstallAction | SilentInstallUsedOnSiteAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SILENT_INSTALL_FLAG:
      return { ...silentInstall, isRunning: action.isRunning };
    case actionTypes.SET_USED_SILENT_INSTALL_ON_SITE:
      return { ...silentInstall, usedOnSite: action.usedOnSite };
    default:
      return silentInstall;
  }
};

export default combineReducers({
  silentInstall: silentInstallReducer,
  latestVersions: latestVersionsReducer,
  availableApps: availableAppsReducer,
  importPanel: importPanelReducer,
  freshAppsData: freshAppsDataReducer,
  packagesView: packagesViewReducer,
  appInstallationInProcess: appInstallationInProcessReducer,
  loadBiEvents: loadBiEventsReducer,
  addElementsPanel: addElementsReducer,
});
