// @ts-nocheck
import constants from '@/constants';
import * as util from '@/util';
import * as siteDisplayNameActionTypes from './siteDisplayNameActionTypes';
const url = `${constants.MULTILINGUAL.sitePropertyServiceUrl}?fields.paths=siteDisplayName`;

const setSiteDisplayName = (siteDisplayName) => ({
  type: siteDisplayNameActionTypes.SET_SITE_DISPLAY_NAME,
  siteDisplayName,
});

const fetchSiteDisplayName =
  () =>
  (dispatch, getState, { dsRead }) => {
    const metaSiteInstance = dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance;
    return util.http
      .fetchJson(url, {
        contentType: 'application/json',
        credentials: 'same-origin',
        headers: new Headers({ Authorization: metaSiteInstance }),
        method: 'GET',
      })
      .then((data) => {
        dispatch(setSiteDisplayName(data.properties.siteDisplayName));
      });
  };

export { fetchSiteDisplayName, setSiteDisplayName };
