import type { AfterPlugin } from 'types/editorPlugins';
import experiments from 'experiment';
import { updateAllChildrenSourceData } from '../../componentSourceFeatureUtils';

const fillTextContentAfterPlugin: AfterPlugin = (
  editorAPI,
  { compRef, contentSource },
) => {
  if (experiments.isOpen('se_siteCompletionSourceData') && contentSource)
    updateAllChildrenSourceData(editorAPI, {
      parentRef: compRef,
      source: contentSource,
      changedOverride: false,
    });
};

export default fillTextContentAfterPlugin;
