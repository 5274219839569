import { getMemberPrivacySettings as getMemberPrivacySettingsAmbassador } from '@wix/ambassador-members-v1-member-privacy-settings/http';
import { Candidates as PublicMemberCandidates } from '@wix/ambassador-members-v1-member-privacy-settings/types';
import { HttpClient } from '@wix/http-client';

const getMemberPrivacySettings = async (instance: string) => {
  const httpClient = new HttpClient({ getAppToken: () => instance });
  const { data } = await httpClient.request(
    getMemberPrivacySettingsAmbassador({}),
  );

  return data.memberPrivacySettings;
};

const canHavePublicMembers = async (instance: string) => {
  const memberPrivacySettings = await getMemberPrivacySettings(instance);
  const canAnyoneBePublicMembers =
    memberPrivacySettings?.publicMemberCandidates ===
    PublicMemberCandidates.ANYONE;

  return canAnyoneBePublicMembers;
};

export const memberPrivacySettingsFacade = {
  canHavePublicMembers,
};
