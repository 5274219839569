const showOnlySavedEvents = (state: AnyFixMe) =>
  state.developerToolBar.overrides.showOnlySavedEvents;
const isReadOnlyEvents = (state: AnyFixMe) =>
  state.developerToolBar.overrides.isReadOnlyEvents;
const disableLinkToCode = (state: AnyFixMe) =>
  state.developerToolBar.overrides.disableLinkToCode;
const shouldShowEvents = (state: AnyFixMe) =>
  state.developerToolBar.overrides.shouldShowEvents;

export {
  showOnlySavedEvents,
  isReadOnlyEvents,
  disableLinkToCode,
  shouldShowEvents,
};
