import stretchToScreenConstraints from './layoutConstraints/stretchToScreenConstraints';
import mobileBoundariesConstraints from './layoutConstraints/mobileBoundariesConstraints';
import desktopBoundariesConstraints from './layoutConstraints/desktopBoundariesConstraints';
import repeaterElementsBoundariesConstraints from './layoutConstraints/repeaterElementsBoundariesConstraints';

export default [
  //should be plain objects, not modules, it's cloned!
  stretchToScreenConstraints,
  mobileBoundariesConstraints,
  desktopBoundariesConstraints,
  repeaterElementsBoundariesConstraints,
];
