export interface AutoTranslationPayload {
  languageCode?: string;
  referrer?: string;
  origin?: string;
  reportWords?: boolean;
}

export enum MultilingualPages {
  MANAGE_LANGUAGES = 'manage-languages',
  TRANSLATION_MANAGER = 'translation-manager',
  SETTINGS = 'settings',
}
