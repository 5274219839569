const SET_INNER_ROUTES = 'set-inner-routes';
const RESET_INNER_ROUTES = 'reset-inner-routes';
const SET_ROUTER_DATA = 'set-router-data';
const RESET_ROUTER_DATA = 'reset-router-data';
const SET_SELECTED_INNER_ROUTE = 'set-selected-inner-route';
const SET_CURRENT_ROUTE_INFO = 'set-current-route-info';
const RESET_SELECTED_INNER_ROUTE = 'reset-selected-inner-route';
const SET_INNER_ROUTES_FETCH_PROCESS = 'set-inner-routes-fetch-process';
const SET_PREVENT_FETCH = 'set-prevent-fetch';
const RESET_INNER_ROUTES_FETCH_PROCESS = 'reset-inner-routes-fetch-process';
const SET_INNER_ROUTES_COUNT = 'set-inner-routes-count';
const CLEAR_INNER_ROUTES_COUNT = 'clear-inner-routes-count';
const SET_CACHED_INNER_ROUTES = 'set-cached-inner-routes';

export {
  SET_INNER_ROUTES,
  RESET_INNER_ROUTES,
  SET_ROUTER_DATA,
  RESET_ROUTER_DATA,
  SET_SELECTED_INNER_ROUTE,
  RESET_SELECTED_INNER_ROUTE,
  SET_INNER_ROUTES_FETCH_PROCESS,
  SET_PREVENT_FETCH,
  RESET_INNER_ROUTES_FETCH_PROCESS,
  SET_CURRENT_ROUTE_INFO,
  SET_INNER_ROUTES_COUNT,
  CLEAR_INNER_ROUTES_COUNT,
  SET_CACHED_INNER_ROUTES,
};
