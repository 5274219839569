import _ from 'lodash';
import type { Dock, Docking } from 'types/documentServices';

const isNumber = _.isNumber;

export const dock = {
  px: (value: number): Dock => ({ px: value }),
};

export function ensureDockingIsValid({
  vCenter,
  hCenter,
  left,
  right,
  top,
  bottom,
}: Docking) {
  if (isNumber(vCenter?.px) && (isNumber(top?.px) || isNumber(bottom?.px))) {
    throw new Error(
      'Cannot set both `vCenter` AND (`top` OR `bottom`) in the same `docking`',
    );
  }

  if (isNumber(hCenter?.px) && (isNumber(left?.px) || isNumber(right?.px))) {
    throw new Error(
      'Cannot set both `hCenter` AND (`left` OR `right`) in the same `docking`',
    );
  }
}
