export default {
  COLLAGE_GALLERY: 'a7c1f2f1-2149-4382-8fbb-f1f727f26195',
  FREESTYLE_GALLERY: '730ba999-fe8c-4882-9cd1-2e3626cfc272',
  MATRIX_GALLERY: '22d45b53-4e3d-4417-afe9-6d07de61f93c',
  HONEYCOMB_GALLERY: '302cb2b8-867b-418d-b9b5-c25fcbb2c2aa',
  PAGINATED_GRID_GALLERY: '576360a4-9d36-4e5e-8875-f33e9ac338ed',
  SLIDESHOW_STRIP_GALLERY: '898d735c-8948-4cea-b896-1b5f57e83c12',
  SHOWCASE_STRIP_GALLERY: '6472e363-0c5f-4855-9f74-85045b96c05b',
  IMPRESS_GALLERY: 'e8557fee-1116-469e-a81f-e23c32e5b1d3',
  ACCORDION_GALLERY: '9ff9e0a0-1ce3-4eb7-8b80-034ca67ac614',
  MASONRY_GALLERY: '45caac16-90f9-4486-9821-37816fa1cbc4',
  SLIDER_GALLERY: 'd7fb3d4b-befa-4f98-9bed-9c957c589926',
  SLIDESHOW_GALLERY: '312ab3b4-6083-4c13-bb5a-0160ca3962aa',
  THUMBNAIL_GALLERY: '8e884490-b583-4531-86da-0329fb66084f',
  CAROUSEL_3D_GALLERY: 'fda1e4a2-407d-45a7-9e3e-7fb6e6bb0228',
  SLIDESHOW_3D_GALLERY: '8d260d6a-6a1c-48de-9ed7-cb54e7b3bcd1',
  BOX_SLIDE_SHOW: '8f2a6cb0-d223-4259-a59e-538a1a9d7b6e',
  POPUP_CONTAINER: 'cf5cd721-0638-4131-b6ca-5e11626b070c',
  POPUP_OVERLAY: '338c91ed-6670-4783-93e5-3439a92bf786',
  HOVER_BOX: '7a5225d1-ebce-4ae7-bf6e-3d74757881ea',
  MEDIA_PLAYER: '6d608b6d-8587-4ca3-93c6-595e3162eb34',
  MEDIA_PLAYER_ALPHA: '9d46ba6e-c799-4eca-94e1-5a2a784f11dc',
  PAGINATION: 'c228c4fd-c39f-4e05-88f1-ef1b788ee8b3',
};
