// @ts-nocheck
import _ from 'lodash';

const getIsMobileWizardEnabled = (state) => state.mobile.mobileWizard.enabled;
const getMobileWizardOpeningState = (state) =>
  state.mobile.mobileWizard.openingState;

// MOBILE WIZARD ITSELF
const getCurrentStep = (state) => state.mobile.mobileWizard.currentStep;
const getCurrentStepSelectedValue = (state) => {
  const currentStep = getCurrentStep(state);
  const currentStepSelectedValueGetter =
    selectedValueGetterById[currentStep] || _.noop;
  return currentStepSelectedValueGetter(state);
};

const getStepsToShow = (state) => state.mobile.mobileWizard.stepsToShow;
const getUserSelections = (state) => state.mobile.mobileWizard.userSelections;
const getLastSaved = (state, stepName) =>
  state.mobile.mobileWizard.lastSaved[stepName];

// TINY MENU
const getTinyMenuAvailableSkins = (state) =>
  state.mobile.mobileWizard.tinyMenu.availableSkins;
const getTinyMenuSelectedSkin = (state) => getUserSelections(state).tinyMenu;

// HEADER
const getSelectedHeaderType = (state) => getUserSelections(state).header;
const getAnimationProgress = (state) =>
  state.mobile.mobileWizard.header.animationInProgress;

// QAB
const getQabAvailablePresets = (state) =>
  state.mobile.mobileWizard.qab.availablePresets;
const getSelectedQab = (state) => getUserSelections(state).qab;

// BACK TO TOP
const getSelectedBackToTop = (state) => getUserSelections(state).backToTop;

const selectedValueGetterById = {
  TINY_MENU: getTinyMenuSelectedSkin,
  SITE_HEADER: getSelectedHeaderType,
  QUICK_ACTION_BAR: getSelectedQab,
  BACK_TO_TOP_BUTTON: getSelectedBackToTop,
};

const tinyMenu = {
  getSelectedSkin: getTinyMenuSelectedSkin,
  getAvailableSkins: getTinyMenuAvailableSkins,
};

const header = {
  getSelectedHeaderType,
  getAnimationProgress,
};

const qab = {
  getAvailablePresets: getQabAvailablePresets,
  getSelectedQab,
};

const backToTop = {
  getSelectedBackToTop,
};

export {
  getIsMobileWizardEnabled,
  getMobileWizardOpeningState,
  getCurrentStep,
  getCurrentStepSelectedValue,
  getStepsToShow,
  getUserSelections,
  getLastSaved,
  tinyMenu,
  header,
  qab,
  backToTop,
};
