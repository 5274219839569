import * as actionTypes from './pagesActionTypes';
import type { IMenuItemId } from '@/baseUI';
import type { CompRef } from 'types/documentServices';
import type { ThunkAction } from 'types/redux';

const setFocusedPage =
  (focusedPage: CompRef): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const focusedPageTitle = editorAPI.pages.getPageTitle(focusedPage.id);
    dispatch({ type: actionTypes.SET_FOCUSED_PAGE, focusedPage });
    dispatch({ type: actionTypes.SET_FOCUSED_PAGE_TITLE, focusedPageTitle });
  };

const setIsEditorLoadedWithMoreThanMaxStaticPages = (value: boolean) => ({
  type: actionTypes.EDITOR_LOADED_WITH_MORE_THAN_MAX_STATIC_PAGES,
  value,
});

const setEditItemId = (value: IMenuItemId | null) => ({
  type: actionTypes.SET_EDITING_MENU_ITEM,
  value,
});

export {
  setFocusedPage,
  setIsEditorLoadedWithMoreThanMaxStaticPages,
  setEditItemId,
};
