// @ts-nocheck
import _ from 'lodash';

function updateFlipPropertyOnMobile(properties, compRef, dsRead, dsActions) {
  const flip = _.pick(properties, 'flip');
  if (_.isEmpty(flip) || !dsActions.components.properties.mobile) {
    return;
  }
  const mobileMode = dsRead.viewMode.VIEW_MODES.MOBILE;
  const pageId = dsRead.components.getPage(compRef).id;
  const mobileComp = dsRead.components.get.byId(compRef.id, pageId, mobileMode);
  const hasMobileComp = mobileComp && dsRead.components.is.exist(mobileComp);
  const isForked =
    hasMobileComp && dsRead.components.properties.mobile.isForked(mobileComp);
  if (hasMobileComp && !isForked) {
    dsActions.components.properties.mobile.fork(mobileComp);
  }
}

export default function vectorImagePropertiesPlugin(
  properties,
  compRef,
  dsRead,
  dsActions,
  isMobileEditor,
) {
  if (!isMobileEditor) {
    updateFlipPropertyOnMobile(properties, compRef, dsRead, dsActions);
  }
  return properties;
}
