// @ts-nocheck
import _ from 'lodash';
import type { CompRef } from 'types/documentServices';
import * as selectionSelectors from '../selection/selectionSelectors';

const { getSelectedCompsRefs } = selectionSelectors;

const updateFixedPosition =
  (compRef: CompRef, isFixedPosition: boolean) =>
  (dispatch, getState, { editorAPI }) => {
    editorAPI.components.layout.updateRelativeToStructure(compRef, {
      fixedPosition: isFixedPosition,
    });
  };

const setPreviewState =
  (compId, previewState) =>
  (dispatch, getState, { dsActions }) => {
    dsActions.documentMode.setComponentPreviewState(compId, previewState);
  };

const updateComponentProperties =
  (compRef, properties) =>
  (dispatch, getState, { dsActions: { components } }) =>
    components.properties.update(compRef, properties);
const updateComponentLayout =
  (compRef, properties) =>
  (dispatch, getState, { dsActions: { components } }) =>
    components.layout.update(compRef, properties);
const updateComponentsStyle =
  (compRef, properties) =>
  (dispatch, getState, { dsActions: { components } }) =>
    components.style.update(compRef, properties);

const filterComponentsByProperty = (components, propKey, propertiesGetter) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  _.filter(components, (component) => {
    const compProperties = propertiesGetter(component);
    return _.get(compProperties, propKey);
  });

const ignoreComponentsHiddenProperty =
  (components, propertiesItem?) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const propertiesGetter = propertiesItem
      ? _.constant(propertiesItem)
      : dsRead.components.properties.get;
    const hiddenComps = filterComponentsByProperty(
      components,
      'isHidden',
      propertiesGetter,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const hiddenCompIds = _.map(hiddenComps, 'id');
    dsActions.documentMode.ignoreComponentsHiddenProperty(hiddenCompIds);
  };

const ignoreComponentsCollapsedProperty =
  (components, propertiesItem?) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const propertiesGetter = propertiesItem
      ? _.constant(propertiesItem)
      : dsRead.components.properties.get;
    const collapsedComps = filterComponentsByProperty(
      components,
      'isCollapsed',
      propertiesGetter,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const collapsedCompsIds = _.map(collapsedComps, 'id');
    dsActions.documentMode.ignoreComponentsCollapsedProperty(collapsedCompsIds);
  };

const onSelectedCompPropertiesChange =
  (component, propertiesItem) => (dispatch, getState) => {
    const selectedComponents = getSelectedCompsRefs(getState());

    dispatch(
      ignoreComponentsHiddenProperty(selectedComponents, propertiesItem),
    );
    dispatch(
      ignoreComponentsCollapsedProperty(selectedComponents, propertiesItem),
    );
  };

const onAppManifestLoaded =
  () =>
  (dispatch, getState, { editorAPI, dsRead }) => {
    const selectedComponents = getSelectedCompsRefs(getState());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const renderedSelectedComps = _.filter(
      selectedComponents,
      dsRead.components.is.rendered,
    );
    if (
      !_.isEmpty(renderedSelectedComps) &&
      selectedComponents.length !== renderedSelectedComps.length
    ) {
      return editorAPI.selection.selectComponentByCompRef(
        renderedSelectedComps,
      );
    }
  };

export {
  updateFixedPosition,
  setPreviewState,
  updateComponentProperties,
  updateComponentLayout,
  updateComponentsStyle,
  ignoreComponentsHiddenProperty,
  ignoreComponentsCollapsedProperty,
  onSelectedCompPropertiesChange,
  onAppManifestLoaded,
};
