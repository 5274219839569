import type { EditorState } from '@/stateManagement';

const isPerformingMouseMoveAction = (state: EditorState) =>
  state.mouseActions.performingMouseMoveAction;
const isUsingWindowMouseEvents = (state: EditorState) =>
  state.mouseActions.usingWindowMouseEvents;
const isDragInProgress = (state: EditorState) =>
  state.mouseActions.dragInProgress;
const isDraggingComponent = (state: EditorState) => {
  return (
    state.selection.selectedComponents.length > 0 &&
    (state.mouseActions.dragInProgress ||
      state.mouseActions.performingMouseMoveAction)
  );
};
const isMouseOverStage = (state: EditorState) =>
  state.mouseActions.isMouseOverStage;

export {
  isPerformingMouseMoveAction,
  isUsingWindowMouseEvents,
  isDragInProgress,
  isDraggingComponent,
  isMouseOverStage,
};
