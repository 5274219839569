/* eslint-disable prettier/prettier */
import * as santaCoreUtils from '@wix/santa-core-utils';
import {
  ensureLayoutSizeIsPx,
  hasFixedItemLayout,
  isFullHeightByLayouts,
  isFullWidthByLayouts,
  layoutSize,
} from '@/layoutUtils';
import { DOCKING_DIRECTIONS_LIST } from './constants';
import {
  ensureDockingDirectionIsValid,
  isDockingDirectionAllowed,
} from './ensureDockingDirectionIsValid';
import type { FixedItemLayout, SingleLayoutData } from 'types/documentServices';
import type {
  PinDockingDirection,
  PinDockingOuterOffsets,
  PinDockingOptions,
} from '../types';

const alignmentsByDirection = new Map<
  PinDockingDirection,
  Pick<FixedItemLayout, 'justifySelf' | 'alignSelf'>
>([
  ['TOP_LEFT', { justifySelf: 'start', alignSelf: 'start' }],
  ['TOP', { justifySelf: 'center', alignSelf: 'start' }],
  ['TOP_RIGHT', { justifySelf: 'end', alignSelf: 'start' }],

  ['LEFT', { justifySelf: 'start', alignSelf: 'center' }],
  ['MIDDLE', { justifySelf: 'center', alignSelf: 'center' }],
  ['RIGHT', { justifySelf: 'end', alignSelf: 'center' }],

  ['BOTTOM_LEFT', { justifySelf: 'start', alignSelf: 'end' }],
  ['BOTTOM', { justifySelf: 'center', alignSelf: 'end' }],
  ['BOTTOM_RIGHT', { justifySelf: 'end', alignSelf: 'end' }],
]);

function getRotationOffsets({ componentLayout }: SingleLayoutData) {
  if (!componentLayout.rotationInDegrees) {
    return {
      verticalOffset: 0,
      horizontalOffset: 0,
    };
  }

  if (
    componentLayout.width.type !== 'px' ||
    componentLayout.height.type !== 'px'
  ) {
    throw new Error(
      `Component layout with and height should be in 'px'.` +
        `\nReceived` +
        `\n - width: ${JSON.stringify(componentLayout.width)}` +
        `\n - height: ${JSON.stringify(componentLayout.height)}`,
    );
  }

  const width = componentLayout.width.value;
  const height = componentLayout.height.value;
  const rotationInDegrees = componentLayout.rotationInDegrees;
  const layout = {
    x: 0,
    y: 0,
    width,
    height,
    rotationInDegrees,
  };

  const boundingWidth = santaCoreUtils.boundingLayout.getBoundingWidth(layout);
  const boundingHeight =
    santaCoreUtils.boundingLayout.getBoundingHeight(layout);

  // NOTE: based on the next code:
  //
  return {
    verticalOffset: (boundingHeight - height) / 2,
    horizontalOffset: (boundingWidth - width) / 2,
  };
}

function createFixedItemLayoutMargins(
  layouts: SingleLayoutData,
  {
    dockingDirection,
    dockingOffsets,
  }: {
    dockingDirection: PinDockingDirection;
    dockingOffsets: Partial<PinDockingOuterOffsets>;
  },
): FixedItemLayout['margins'] {
  const rotationOffsets = getRotationOffsets(layouts);

  // TODO: left & right were removed from "MeshItemLayout" - https://github.com/wix-private/document-management/pull/24507
  // @ts-expect-error
  const { left, right } = isFullWidthByLayouts(layouts)
    ? layouts.itemLayout.margins ?? layouts.itemLayout
    : ((): FixedItemLayout['margins'] => {
        const { justifySelf } = alignmentsByDirection.get(dockingDirection);
        const { horizontalOffset } = dockingOffsets;

        switch (justifySelf) {
          case 'start':
            return {
              left: layoutSize.px(
                horizontalOffset + rotationOffsets.horizontalOffset,
              ),
            };
          case 'center':
            return {
              left: layoutSize.px(horizontalOffset),
            };
          case 'end':
            return {
              right: layoutSize.px(
                horizontalOffset + rotationOffsets.horizontalOffset,
              ),
            };
        }
      })();

  const { top, bottom } = isFullHeightByLayouts(layouts)
    ? layouts.itemLayout.margins
    : ((): FixedItemLayout['margins'] => {
        const { alignSelf } = alignmentsByDirection.get(dockingDirection);
        const { verticalOffset } = dockingOffsets;

        switch (alignSelf) {
          case 'start':
            return {
              top: layoutSize.px(verticalOffset + rotationOffsets.verticalOffset),
            };
          case 'center':
            return {
              top: layoutSize.px(verticalOffset),
            };
          case 'end':
            return {
              bottom: layoutSize.px(verticalOffset + rotationOffsets.verticalOffset),
            };
        }
      })();

  return {
    left,
    right,
    top,
    bottom,
  };
}

export function getFixedLayoutItemDockingDirection(layouts: SingleLayoutData) {
  if (!hasFixedItemLayout(layouts)) {
    return undefined;
  }

  const isFullWidth = isFullWidthByLayouts(layouts);
  const isFullHeight = isFullHeightByLayouts(layouts);
  const itemLayout = layouts.itemLayout;

  return DOCKING_DIRECTIONS_LIST.find((dockingDirection) => {
    const directionAllignment = alignmentsByDirection.get(dockingDirection);

    if (
      !isDockingDirectionAllowed(dockingDirection, {
        isFullWidth,
        isFullHeight,
      })
    ) {
      return false;
    }

    if (isFullWidth) {
      return directionAllignment.alignSelf === itemLayout.alignSelf;
    }

    if (isFullHeight) {
      return directionAllignment.justifySelf === itemLayout.justifySelf;
    }

    return (
      directionAllignment.justifySelf === itemLayout.justifySelf &&
      directionAllignment.alignSelf === itemLayout.alignSelf
    );
  });
}

export function getFixedLayoutItemDockingOuterOffsets(
  layouts: SingleLayoutData,
): PinDockingOuterOffsets {
  if (!hasFixedItemLayout(layouts)) {
    return undefined;
  }

  const { justifySelf, alignSelf, margins } = layouts.itemLayout;
  const rotationOffsets = getRotationOffsets(layouts);

  const horizontalOffset = (() => {
    switch (justifySelf) {
      case 'start':
        ensureLayoutSizeIsPx(margins.left);
        return margins.left?.value - rotationOffsets.horizontalOffset;
      case 'center':
        ensureLayoutSizeIsPx(margins.left);
        return margins.left?.value;
      case 'end':
        ensureLayoutSizeIsPx(margins.right);
        return margins.right?.value - rotationOffsets.horizontalOffset;
    }
  })();

  const verticalOffset = (() => {
    switch (alignSelf) {
      case 'start':
        ensureLayoutSizeIsPx(margins.top);
        return margins.top?.value - rotationOffsets.verticalOffset;
      case 'center':
        ensureLayoutSizeIsPx(margins.top);
        return margins.top?.value;
      case 'end':
        ensureLayoutSizeIsPx(margins.bottom);
        return margins.bottom?.value - rotationOffsets.verticalOffset;
    }
  })();

  return {
    horizontalOffset,
    verticalOffset,
  };
}

export function createFixedLayoutItemByPinDockingDirection(
  layouts: SingleLayoutData,
  { dockingDirection, dockingOffsets }: PinDockingOptions,
): FixedItemLayout {
  ensureDockingDirectionIsValid(dockingDirection, {
    isFullWidth: isFullWidthByLayouts(layouts),
    isFullHeight: isFullHeightByLayouts(layouts),
  });

  const { justifySelf, alignSelf } =
    alignmentsByDirection.get(dockingDirection);

  const margins: FixedItemLayout['margins'] = createFixedItemLayoutMargins(
    layouts,
    {
      dockingDirection,
      dockingOffsets,
    },
  );

  // @ts-expect-error - TODO: ds should make `id` optional https://wix.slack.com/archives/C04EX7N2BCP/p1697547680621689
  const fixedItemLayout: FixedItemLayout = {
    type: 'FixedItemLayout',
    justifySelf,
    alignSelf,
    margins,
  };

  return fixedItemLayout as FixedItemLayout;
}
