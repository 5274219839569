import siteCreationActionTypes from './siteCreationActionTypes';

const setSessionInitializedWithWizard = () => ({
  type: siteCreationActionTypes.SET_SESSION_INITIALIZED_WITH_WIZARD,
});

const hideWizard = () => ({
  type: siteCreationActionTypes.HIDE_WIZARD,
});

const setSiteCreationUiFinished = () => ({
  type: siteCreationActionTypes.SET_SITE_CREATION_FINISHED,
});

const setSiteCreationFullyDone = () => ({
  type: siteCreationActionTypes.SET_SITE_CREATION_FULLY_DONE,
});

export {
  hideWizard,
  setSiteCreationFullyDone,
  setSessionInitializedWithWizard,
  setSiteCreationUiFinished,
};
