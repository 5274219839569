// @ts-nocheck
import _ from 'lodash';

export default _.mapKeys([
  'MULTILINGUAL_LOAD_START',
  'MULTILINGUAL_LOAD_STOP',
  'LANGUAGE_CHANGE_START',
  'LANGUAGE_CHANGE_SUCCESS',
  'LOAD_SUPPORTED_LANGUAGES',
  'LOAD_SUPPORTED_LANGUAGES_SUCCESS',
  'LOAD_SUPPORTED_LANGUAGES_ERROR',
  'GET_SITE_TRANSLATABLES_PROPERTIES',
  'GET_SITE_TRANSLATABLES_PROPERTIES_SUCCESS',
  'GET_SITE_TRANSLATABLES_PROPERTIES_ERROR',
  'SET_IS_TRANSLATING',
  'SET_IS_PRELOADING',
  'SET_IS_AUTO_TRANSLATED',
  'GET_MACHINE_TRANSLATION_LIMIT',
  'GET_MACHINE_TRANSLATION_LIMIT_SUCCESS',
  'GET_MACHINE_TRANSLATION_LIMIT_ERROR',
  'SET_QUICK_EDIT_AUTO_OPENED',
]);
