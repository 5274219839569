export default {
  MANAGE_MENU: 'ee41fc70-0e66-4423-b72f-d09ecb75ad7c',
  MANAGE_MENU_MOBILE: '8cd59533-091d-4b6c-943e-15b2a9ae58bc',
  MANAGE_MENU_MEMBERS: '3f6a0b00-18a0-47f6-851e-deddccbf025d',
  MANAGE_MENU_MEMBERS_LOGIN: '04f780fc-ad8f-4ac7-a9a0-37564bfd7aaa',
  CUSTOM_MENUS: '42e2bf0a-835a-4288-bff2-d5868713313d',
  MANAGE_ALL_MENUS: '32f8d419-de6a-4c87-9735-c56f48b589b9',
  MAIN_MENU: '022714e6-b5b3-439a-83b8-42e98910a8ba',
  CREATE_DROPDOWN: '577a2b68-26a3-4412-8f57-30e2e85205bf',
  CREATE_LINK: '721dd94d-464c-430c-abba-2f4128285505',
  SHOW_MENU_ITEMS_FROM: 'b50c9131-f903-4964-8ffd-edc28819c0c4',
  MOBILE_CREATE_MENU: 'c1f462dd-dad0-455f-afbe-743ce8faa46c',
  EDIT_MENU_SET_NOTIFICATION: 'ed3fbae9-c8f6-4460-8482-4773b248fd5a',
  RENAME_PAGE_IN_MENU_NOTIFICATION: 'f661a109-d86a-4fc3-a4e4-48ae239c9680',
};
