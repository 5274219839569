// @ts-nocheck
const getWelcomeScreenConfig = (state) => state.welcomeScreen?.config ?? null;
const isWelcomeScreenDirty = (state) => state.welcomeScreen?.isDirty;
const getWelcomeScreenOverride = (state) =>
  state.welcomeScreen?.overrides ?? { panels: [] };

export {
  getWelcomeScreenConfig,
  isWelcomeScreenDirty,
  getWelcomeScreenOverride,
};
