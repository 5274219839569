export default {
  DEFAULT_CUSTOMIZE_DESIGN_HELP_ID: '4bb86dd5-b23f-46fc-b7fb-728e59916358',
  'wysiwyg.viewer.components.FiveGridLine':
    'ef3e7ddb-d1d2-4e29-871f-e0d381849fcd',
  'mobile.core.components.Page': 'ecd4f1a6-002b-4f62-9a5c-6cc0acdd20cf',
  'wysiwyg.viewer.components.HeaderContainer':
    'b3eae97f-6a69-45eb-8c10-33ff31a01f7a',
  'wysiwyg.viewer.components.VectorImage':
    'f734acb6-ea3d-49e4-817d-2e78fd72dd93',
  'core.components.Container': '93ae49e0-f214-4a80-abf3-d0912cfabeb2',
  'mobile.core.components.Container': '93ae49e0-f214-4a80-abf3-d0912cfabeb2',
  'wysiwyg.viewer.components.SiteButton':
    '5631dde3-1c0d-40c2-b81b-47099760ef4f',
  'wysiwyg.viewer.components.WPhoto': '286f6301-8ee9-4bbf-a367-8ebfe82f2571',
  'wysiwyg.viewer.components.svgshape.SvgShape':
    '5305f8c2-96bd-421e-9218-7ef6426e97db',
  'tpa.viewer.components.Masonry': '53a2d61c-19ae-4136-837a-fa10b87ca6c0',
  'tpa.viewer.components.Collage': 'afb9e850-2cb3-45ea-a41e-d5d58bc711bc',
  'wysiwyg.viewer.components.MatrixGallery':
    'ca5d7b5a-80fd-43ec-b10f-9d773f7cb4e0',
  'wysiwyg.viewer.components.PaginatedGridGallery':
    'c5fa6333-2e6c-4e10-85e9-89c9308c2dfc',
  'tpa.viewer.components.Honeycomb': '5001f606-69a6-4dff-97bc-8d286755ab31',
  'tpa.viewer.components.StripSlideshow':
    'b3218295-e9b4-42f6-a066-0bab96a3a71c',
  'tpa.viewer.components.Thumbnails': 'e5fa394f-144c-4255-a794-496f24a8f46e',
  'wysiwyg.viewer.components.SliderGallery':
    '21b6e06f-e36e-4ec9-b16b-4462e2a60053',
  'wysiwyg.viewer.components.SlideShowGallery':
    'd9760958-8460-4a62-bb4b-dca8344b7a59',
  'tpa.viewer.components.Accordion': '3ed71c4d-f13c-4bc4-a45d-b87b31a8f44f',
  'tpa.viewer.components.Impress': '0308fcd7-31d5-4825-bf87-be2790b9b6fc',
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel':
    '29041406-3daa-4d14-aae8-97dd0c45abe8',
  'wysiwyg.viewer.components.tpapps.TPA3DGallery':
    '846c2383-8ca9-4651-aac8-33d01bd8fcad',
  'tpa.viewer.components.Freestyle': '13a1a280-c4e0-4058-9928-3125fe448df6',
  'wysiwyg.viewer.components.BoxSlideShow':
    '5a0501e9-d806-4a1e-ae95-f3415bb6b297',
  'wysiwyg.viewer.components.StripContainerSlideShow':
    '287e66ea-bfd6-4a92-8b35-beec4cf8e220',
  'wysiwyg.viewer.components.StateBox': '5a0501e9-d806-4a1e-ae95-f3415bb6b297',
  'wysiwyg.viewer.components.StateStrip':
    '287e66ea-bfd6-4a92-8b35-beec4cf8e220',
  'wysiwyg.viewer.components.inputs.RadioButton':
    '39ff8847-8c60-458e-a14a-ab4428740a34',
  'wysiwyg.viewer.components.HoverBox': '93ae49e0-f214-4a80-abf3-d0912cfabeb2',
  'wysiwyg.viewer.components.Grid': '4bb86dd5-b23f-46fc-b7fb-728e59916358',
  'wysiwyg.viewer.components.MediaPlayer':
    '5a018d46-e38e-4a3c-a032-21f3809db1db',
  'wysiwyg.viewer.components.QuickActionBar':
    'da7bc6ad-58b3-41c9-b5f2-2b558b874a5c',
  'wysiwyg.viewer.components.MediaOverlayControls':
    'c6a7db14-52dd-475e-b8c3-b78ae1667232',
  'wysiwyg.viewer.components.MediaContainer':
    '779ed2b1-8105-4afc-a698-24c5f8d9b29d',
  'wysiwyg.viewer.components.MenuContainer':
    'eba824ab-a937-4c7d-a55e-a9584dddb2f7',
  'wysiwyg.viewer.components.MenuToggle':
    'cb845195-9471-401a-aad7-f41081126e70',
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu':
    'c7c57774-1b52-45e2-acab-49d89c303b25',
  'wysiwyg.viewer.components.ExpandableMenu':
    'e9f7fd7a-c0df-4b3e-b2f9-79aad0410e37',
  'wysiwyg.viewer.components.LanguageSelector':
    '30ad7372-4f2f-42d4-b02d-0c78b585b5f7',
  'wixui.Pagination': 'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wixui.RatingsDisplay': 'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wixui.RichTextBox': 'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wixui.Tags': '88e06916-1b67-4311-858d-0564f4686793',
  'wixui.ProgressBar': '3f0d472a-c729-4896-9b62-213993a8b9d3',
  'wixui.RatingsInput': 'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wixui.SearchBox': 'da510b19-68fd-48ef-a098-9b4c0d25ac7c',
  'wixui.SignatureInput': '508ea2cb-7ea0-4017-b259-8d0d46af2801',
  'wixui.MusicPlayer': '5a4cc5ca-cfd4-4ad7-90da-8f65327e3973',
  'wixui.SelectionTagsList': 'd046f464-f5f0-44fa-9d23-d2cfa295faf8',
  'wysiwyg.viewer.components.inputs.TextInput':
    'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wysiwyg.viewer.components.inputs.TextAreaInput':
    'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wysiwyg.viewer.components.inputs.RadioGroup':
    'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wysiwyg.viewer.components.inputs.CheckboxGroup':
    'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wysiwyg.viewer.components.inputs.ComboBoxInput':
    'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wysiwyg.viewer.components.inputs.DatePicker':
    'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wysiwyg.viewer.components.inputs.FileUploader':
    'bffefe1d-bb4a-4296-a803-a6d905e59495',
};
