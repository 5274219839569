import _ from 'lodash';
import * as reselect from 'reselect';
import type { EditorState } from '../store/editorState';
import * as hiddenItemsSelectors from './hiddenItems/hiddenItemsSelectors';
import * as interactionsHiddenItemsSelectors from './interactionsHiddenItems/interactionsHiddenItemsSelectors';
import * as mobileWizardSelectors from './mobileWizard/mobileWizardSelectors';
import * as mobileQabSelectors from './qab/mobileQabSelectors';
import * as mobileKeyboardSelectors from './mobileKeyboard/mobileKeyboardSelectors';
import * as introsSelectors from './intros/introsSelectors';
import * as mobileSessionSelectors from './mobileSession/mobileSessionSelectors';
import type { DSRead, CompRef } from 'types/documentServices';
import type { MobileFramePosition } from 'types/core';

type DeviceType = 'mobile' | 'desktop';

const isMobileEditor = (state: EditorState): boolean => state.isMobileEditor;

const isDesktopEditor = _.negate(isMobileEditor);

const getDeviceType: (state: EditorState) => DeviceType =
  reselect.createSelector(
    isMobileEditor,
    (mobileEditor): DeviceType => (mobileEditor ? 'mobile' : 'desktop'),
  );

const getMobileFramePosition = (state: EditorState): MobileFramePosition =>
  state.mobileFramePosition;

const isComponentVisibleInMobileMode = (
  dsRead: DSRead,
  compRef: CompRef,
): boolean => {
  const { variants } = dsRead;
  if (!variants) {
    return true;
  }

  const siteMobileVariant = [dsRead.variants.mobile.get()];

  if (!siteMobileVariant || _.isEmpty(siteMobileVariant)) {
    return true;
  }
  const compVariantPointer = dsRead.components.variants.getPointer(
    compRef,
    siteMobileVariant,
  );
  const compVariantTransformations =
    dsRead.components.transformations.get(compVariantPointer);
  // Must check that it's really false, and not just undefined.
  return compVariantTransformations?.hidden !== true;
};

export {
  isMobileEditor,
  isDesktopEditor,
  getDeviceType,
  getMobileFramePosition,
  isComponentVisibleInMobileMode,
  hiddenItemsSelectors as hiddenItems,
  interactionsHiddenItemsSelectors as interactionsHiddenItems,
  mobileWizardSelectors as mobileWizard,
  mobileQabSelectors as qab,
  mobileKeyboardSelectors as mobileKeyboard,
  introsSelectors as intros,
  mobileSessionSelectors,
};
