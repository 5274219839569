import * as actionTypes from './previewActionTypes';

export const previewModeInitialState = false;

const reducer = (previewModeState: AnyFixMe, action: AnyFixMe) => {
  switch (action.type) {
    case actionTypes.SET_PREVIEW_MODE:
      return action.modeOn;
    default:
      return previewModeState;
  }
};

export default reducer;
