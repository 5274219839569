import * as actions from './biActions';
import reportBiEventOnUiChange from './reportBiEventOnUiChange';
import withBiLogger from './withBiLogger';

const hoc = {
  reportBiEventOnUiChange,
  withBiLogger,
};

export { actions, hoc };
