import _ from 'lodash';

import type { EditorAPI } from '@/editorAPI';
import type {
  CompRef,
  CompLayout,
  CompStructure,
  Rect,
  Point,
} from 'types/documentServices';
import type { Box } from 'types/core';
import type { Viewport } from '@/stateManagement';

export interface LayoutLimits {
  x: number;
  y: number;
  width: number;
  height: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
}

const LAYOUT_UPDATE_KEYS = [
  'x',
  'y',
  'width',
  'height',
  'rotationInDegrees',
  'fixedPosition',
];

function addXYOffsetsToLayout(
  compPosition: Point,
  compPositionOffsets: Partial<Point>,
): Partial<Point> {
  const compPositionUpdated = {
    ...compPosition,
    x: compPosition.x + (compPositionOffsets?.x ?? 0),
    y: compPosition.y + (compPositionOffsets?.y ?? 0),
  };
  return _.pickBy(compPositionUpdated, _.isFinite);
}

export class LayoutUpdateCtx {
  initialAbsLayout: CompLayout;
  initialRelativeLayout: CompLayout;
  constructor(editorAPI: EditorAPI, compRef: CompRef) {
    this.initialAbsLayout = editorAPI.components.layout.get(compRef);
    this.initialRelativeLayout =
      editorAPI.components.layout.getRelativeToScreen(compRef);
  }
}

function calcRelativeToParentLayoutFromLayoutsDiff(
  editorAPI: EditorAPI,
  component: CompRef,
  newLayout: AnyFixMe,
  currentLayout: AnyFixMe,
  layoutUpdateCtx?: LayoutUpdateCtx,
) {
  const currentPosition =
    layoutUpdateCtx?.initialAbsLayout ??
    editorAPI.components.layout.get(component);
  const diffBetweenNewLayoutAndCurrentLayout = getLayoutsXYDiff(
    newLayout,
    currentLayout,
  );
  const newLayoutRelativeToParent = addXYOffsetsToLayout(
    currentPosition,
    diffBetweenNewLayoutAndCurrentLayout,
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const result = _.assign(
    {},
    newLayoutRelativeToParent,
    _.omit(newLayout, ['x', 'y']),
  );
  // we update only explicitly changed values, newLayout is partial<layout>
  return _.pick(result, Object.keys(newLayout));
}

function isLayoutIntersectsRect(layout: AnyFixMe, rectLayout: AnyFixMe) {
  const isPartiallySelected = !(
    rectLayout.x + rectLayout.width < layout.x ||
    rectLayout.x > layout.x + layout.width ||
    rectLayout.y + rectLayout.height < layout.y ||
    rectLayout.y > layout.y + layout.height
  );

  const isContained =
    layout.x <= rectLayout.x &&
    layout.x + layout.width >= rectLayout.x + rectLayout.width &&
    layout.y <= rectLayout.y &&
    layout.y + layout.height >= rectLayout.y + rectLayout.height;
  return isPartiallySelected && !isContained;
}

function getLayoutsXYDiff(
  layout1: Partial<Point>,
  layout2: Partial<Point>,
): Point {
  const diff = {
    x: layout1.x - layout2.x,
    y: layout1.y - layout2.y,
  };

  return _.pickBy(diff, _.isFinite) as any;
}

function getChildContainerLayoutRatio(
  childLayout: AnyFixMe,
  containerLayout: AnyFixMe,
) {
  const ratios = {
    x: childLayout.x / containerLayout.width,
    y: childLayout.y / containerLayout.height,
    width: childLayout.width / containerLayout.width,
    height: childLayout.height / containerLayout.height,
  };

  return _.pickBy(ratios, _.isFinite);
}

function calcLayoutFromLayoutAndRatio(
  layout: Partial<Rect>,
  ratio: Partial<Rect>,
): Rect {
  const calculatedLayout = {
    x: layout.width * ratio.x,
    y: layout.height * ratio.y,
    width: layout.width * ratio.width,
    height: layout.height * ratio.height,
  };

  return _.pickBy(calculatedLayout, _.isFinite) as any;
}

function getLayoutRelativeToScreenFromRelativeToParent(
  editorAPI: EditorAPI,
  component: AnyFixMe,
  layout: Partial<Rect>,
) {
  const currentLayout = editorAPI.components.layout.get_position(component);
  const layoutsXYDiff = getLayoutsXYDiff(currentLayout, layout);

  const currentLayoutRelativeToScreen =
    editorAPI.components.layout.getRelativeToScreen(component);
  const layoutRelativeToScreen = addXYOffsetsToLayout(
    currentLayoutRelativeToScreen,
    layoutsXYDiff,
  );

  return layoutRelativeToScreen;
}

function getLayoutRelativeToParentFromRelativeToScreen(
  editorAPI: EditorAPI,
  component: CompRef,
  layoutRelativeToScreen: AnyFixMe,
  layoutUpdateCtx?: LayoutUpdateCtx,
) {
  const currentLayoutRelativeToScreen =
    layoutUpdateCtx?.initialRelativeLayout ??
    editorAPI.components.layout.getRelativeToScreen(component);
  const layoutRelativeToParent = calcRelativeToParentLayoutFromLayoutsDiff(
    editorAPI,
    component,
    layoutRelativeToScreen,
    currentLayoutRelativeToScreen,
    layoutUpdateCtx,
  );
  return layoutRelativeToParent;
}

function getLayoutRelativeToParentFromRelativeToStructure(
  editorAPI: EditorAPI,
  component: CompRef,
  layoutRelativeToStructure: AnyFixMe,
) {
  const currentLayoutRelativeToStructure =
    editorAPI.components.layout.getRelativeToStructure(component);
  const layoutRelativeToParent = calcRelativeToParentLayoutFromLayoutsDiff(
    editorAPI,
    component,
    layoutRelativeToStructure,
    currentLayoutRelativeToStructure,
  );

  return layoutRelativeToParent;
}

function getLayoutRelativeToScreenFromRelativeToStructure(
  editorAPI: EditorAPI,
  component: CompRef | CompRef[],
  layoutRelativeToStructure: AnyFixMe,
) {
  const currentLayoutRelativeToStructure =
    editorAPI.components.layout.getRelativeToStructure(component);
  const currentLayoutRelativeToScreen =
    editorAPI.components.layout.getRelativeToScreen(component);
  const layoutsDiff = getLayoutsXYDiff(
    layoutRelativeToStructure,
    currentLayoutRelativeToStructure,
  );

  return _(currentLayoutRelativeToScreen)
    .thru((layout) => addXYOffsetsToLayout(layout, layoutsDiff))
    .assign(_.pick(layoutRelativeToStructure, ['width', 'height']))
    .pick(LAYOUT_UPDATE_KEYS)
    .value();
}

const xyMapper = _.curry(function mapCoordinate(
  config: AnyFixMe,
  containerLayout: AnyFixMe,
  itemLayout: AnyFixMe,
  offset: AnyFixMe,
) {
  const x0 = itemLayout[config.sourceProperty];
  const x1 = containerLayout[config.sourceProperty];
  const ax = config.ratio * (x1 - x0);
  const dx = config.offsetRatio * offset;

  return [config.targetProperty, Math.floor(ax + dx)];
});

const ALIGN = {
  center: xyMapper({
    ratio: 0.5,
    offsetRatio: 1,
    sourceProperty: 'width',
    targetProperty: 'x',
  }),
  right: xyMapper({
    ratio: 1,
    offsetRatio: -1,
    sourceProperty: 'width',
    targetProperty: 'x',
  }),
  middle: xyMapper({
    ratio: 0.5,
    offsetRatio: 1,
    sourceProperty: 'height',
    targetProperty: 'y',
  }),
  bottom: xyMapper({
    ratio: 1,
    offsetRatio: -1,
    sourceProperty: 'height',
    targetProperty: 'y',
  }),
};

function convertAnyPositionToLeftTopPosition(
  itemLayout: AnyFixMe,
  containerLayout: AnyFixMe,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/to-pairs
  return _(itemLayout)
    .toPairs()
    .map(function (pair) {
      const key = pair[0];
      const value = pair[1];

      if (ALIGN.hasOwnProperty(key)) {
        return ALIGN[key as keyof typeof ALIGN](
          containerLayout,
          itemLayout,
          value,
        );
      }

      return pair;
    })
    .fromPairs()
    .value();
}

/**
 *
 * @param {{x: {number}, y: {number}, width: {number}, height: {number}, [minWidth]: {number}, [minHeight]: {number}, [maxWidth]: {number}, [maxHeight]: {number}} layoutWithLimits
 * @param {number} imageAspectRatio
 * @param {Object} [dockData]
 * @param {boolean} [shouldCenter]
 * @returns {{x: {number}, y: {number}, width: {number}, height: {number}}}
 */
function getLayoutWithAspectRatio(
  layoutWithLimits: LayoutLimits,
  imageAspectRatio: number,
  dockData: AnyFixMe,
  shouldCenter?: boolean,
) {
  let { x, y, width, height } = layoutWithLimits;

  const {
    minWidth = 0,
    minHeight = 0,
    maxWidth = Number.MAX_SAFE_INTEGER,
    maxHeight = Number.MAX_SAFE_INTEGER,
  } = layoutWithLimits;
  const compAspectRatio = width / height;

  if (compAspectRatio > imageAspectRatio) {
    width = height * imageAspectRatio;
  } else {
    height = width / imageAspectRatio;
  }

  if (width < minWidth || width > maxWidth) {
    width = width < minWidth ? minWidth : maxWidth;
    height = width / imageAspectRatio;
  }

  if (height < minHeight || height > maxHeight) {
    height = height < minHeight ? minHeight : maxHeight;
    width = height * imageAspectRatio;
  }

  if (dockData) {
    x = dockData.right ? Math.round(x + (layoutWithLimits.width - width)) : x;
    y = dockData.bottom
      ? Math.round(y + (layoutWithLimits.height - height))
      : y;
  }

  if (shouldCenter) {
    // Center
    if (!dockData || (!dockData.right && !dockData.left)) {
      x += (layoutWithLimits.width - width) / 2;
    }
    if (!dockData || (!dockData.bottom && !dockData.top)) {
      y += (layoutWithLimits.height - height) / 2;
    }
  }

  return { x, y, width, height };
}

function getImageOrCropAspectRatio(compData: AnyFixMe) {
  if (_.isEmpty(compData.crop)) {
    return compData.width / compData.height;
  }
  return compData.crop.width / compData.crop.height;
}

function getLayoutRelativeToPrimaryContainer(
  editorAPI: EditorAPI,
  comps: AnyFixMe,
): CompLayout {
  const shouldUseLayoutRelativeToScreen =
    editorAPI.components.layout.isHorizontallyStretchedToScreen(comps);

  const primaryContainer = editorAPI.pages.getPrimaryContainer();

  const shouldNormalizeToPrimaryContainer =
    !editorAPI.utils.isPage(primaryContainer);

  const primaryContainerLayout = shouldUseLayoutRelativeToScreen
    ? editorAPI.components.layout.getRelativeToScreen(primaryContainer)
    : editorAPI.components.layout.getRelativeToStructure(primaryContainer);

  const layoutRelativeToStructure =
    editorAPI.components.layout.getRelativeToStructure(comps);

  return shouldNormalizeToPrimaryContainer
    ? _.defaultsDeep(
        {
          x: layoutRelativeToStructure.x - primaryContainerLayout.x,
          y: layoutRelativeToStructure.y - primaryContainerLayout.y,
          bounding: {
            x:
              layoutRelativeToStructure.bounding.x -
              primaryContainerLayout.bounding.x,
            y:
              layoutRelativeToStructure.bounding.y -
              primaryContainerLayout.bounding.y,
          },
        },
        layoutRelativeToStructure,
      )
    : layoutRelativeToStructure;
}

function getContainerLayoutToFitContent(
  containerLayout: Rect,
  compLayout: Rect,
) {
  return {
    width: Math.max(containerLayout.width, compLayout.width),
    x:
      containerLayout.x -
      Math.max(0, compLayout.width - containerLayout.width) / 2,
    height: Math.max(containerLayout.height, compLayout.height),
  };
}

function getCompPastePositionToFitContainer(
  containerLayout: AnyFixMe,
  compLayout: AnyFixMe,
  stageHeight: AnyFixMe,
  scrollTop: AnyFixMe,
) {
  const isContainerExceedingStage = stageHeight < containerLayout.height;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign(
    { x: Math.max(0, (containerLayout.width - compLayout.width) / 2) },
    isContainerExceedingStage
      ? {
          y: Math.max(
            0,
            scrollTop -
              containerLayout.y +
              (stageHeight - compLayout.height) / 2,
          ),
        }
      : { y: Math.max(0, (containerLayout.height - compLayout.height) / 2) },
  );
}

const INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET = 20;

function isCompRelativelyInsideRepeatedItemWithInteractionRestrictions(
  compLayoutRelativeToContainer: CompLayout,
  repeaterItemLayout: CompLayout,
): boolean {
  return (
    compLayoutRelativeToContainer.x + compLayoutRelativeToContainer.width >
      INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET &&
    compLayoutRelativeToContainer.y + compLayoutRelativeToContainer.height >
      INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET &&
    repeaterItemLayout.width - compLayoutRelativeToContainer.x >
      INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET &&
    repeaterItemLayout.height - compLayoutRelativeToContainer.y >
      INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET
  );
}

function isCompInsideRepeatedItemWithInteractionRestrictions(
  compLayoutRelativeToStructure: CompLayout,
  repeaterItemLayoutRelativeToStructure: CompLayout,
): boolean {
  return (
    compLayoutRelativeToStructure.x + compLayoutRelativeToStructure.width >
      repeaterItemLayoutRelativeToStructure.x +
        INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET &&
    compLayoutRelativeToStructure.y + compLayoutRelativeToStructure.height >
      repeaterItemLayoutRelativeToStructure.y +
        INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET &&
    compLayoutRelativeToStructure.x <
      repeaterItemLayoutRelativeToStructure.x +
        repeaterItemLayoutRelativeToStructure.width -
        INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET &&
    compLayoutRelativeToStructure.y <
      repeaterItemLayoutRelativeToStructure.y +
        repeaterItemLayoutRelativeToStructure.height -
        INTERACTION_ALLOWED_REPEATER_ITEM_CONTAINING_OFFSET
  );
}

function canMoveByInteractionsLayoutConstraints(
  editorAPI: EditorAPI,
  selected: CompRef | CompRef[],
  compLayout: CompLayout,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  const compRef = _.isArray(selected) ? selected[0] : selected;
  if (!editorAPI.components.is.descendantOfRepeaterItem(compRef)) {
    return true;
  }
  const repeaterItem = editorAPI.components.getAncestorRepeaterItem(compRef);
  const repeaterItemClientRect =
    editorAPI.components.layout.measure.getBoundingClientRect(repeaterItem);
  const repeaterItemMeasure = {
    y: repeaterItemClientRect.absoluteTop,
    x: repeaterItemClientRect.absoluteLeft,
    width: repeaterItemClientRect.width,
    height: repeaterItemClientRect.height,
  };
  return isCompInsideRepeatedItemWithInteractionRestrictions(
    compLayout,
    repeaterItemMeasure,
  );
}

function doBoxesOverlap(box1: Box, box2: Box): boolean {
  return !(
    box1.x >= box2.x + box2.width || //box1 to the right of box2
    box2.x >= box1.x + box1.width || //box2 to the right of box1
    box1.y >= box2.y + box2.height || //box1 beneath box2
    box2.y >= box1.y + box1.height
  ); //box2 beneath box1
}

function doPointOverlapBox(box: Box, point: Point): boolean {
  return (
    box.x <= point.x &&
    box.x + box.width >= point.x &&
    box.y <= point.y &&
    box.y + box.height >= point.y
  );
}

// returns relativeToParentLayout, aka layout.get
const getPositionInsideParentCloseToViewportCenter = (
  editorAPI: EditorAPI,
  viewPort: Viewport,
  containerRef: CompRef,
  compDef: CompStructure,
): { x: number; y: number } => {
  const componentLayout = compDef.layout;
  const x = viewPort.x + (componentLayout.width + viewPort.width) / 2;
  const { y: containerY, height: containerHeight } =
    editorAPI.components.layout.getRelativeToScreenConsideringScroll(
      containerRef,
    );
  const y =
    (viewPort.stageLayout.height - componentLayout.height) / 2 - containerY;

  const minY = 0;
  const maxY = containerHeight - componentLayout.height;

  return { x, y: _.clamp(y, minY, maxY) };
};

const getScaledRect = (layout: Rect, siteScale: number) => {
  return {
    x: layout.x * siteScale,
    y: layout.y * siteScale,
    width: layout.width * siteScale,
    height: layout.height * siteScale,
  };
};

const getBoxesOverlapArea = (boxLayout1: Rect, boxLayout2: Rect) => {
  const intersectionXStart = Math.max(boxLayout1.x, boxLayout2.x);
  const intersectionXEnd = Math.min(
    boxLayout1.x + boxLayout1.width,
    boxLayout2.x + boxLayout2.width,
  );
  const intersectionYStart = Math.max(boxLayout1.y, boxLayout2.y);
  const intersectionYEnd = Math.min(
    boxLayout1.y + boxLayout1.height,
    boxLayout2.y + boxLayout2.height,
  );

  const horizontalOverlap = Math.max(0, intersectionXEnd - intersectionXStart);
  const verticalOverlap = Math.max(0, intersectionYEnd - intersectionYStart);

  return horizontalOverlap * verticalOverlap;
};

export {
  addXYOffsetsToLayout,
  getScaledRect,
  getLayoutsXYDiff,
  getChildContainerLayoutRatio,
  isCompInsideRepeatedItemWithInteractionRestrictions,
  canMoveByInteractionsLayoutConstraints,
  isCompRelativelyInsideRepeatedItemWithInteractionRestrictions,
  calcLayoutFromLayoutAndRatio,
  getLayoutRelativeToScreenFromRelativeToParent,
  getLayoutRelativeToParentFromRelativeToScreen,
  getLayoutRelativeToParentFromRelativeToStructure,
  getLayoutRelativeToScreenFromRelativeToStructure,
  getLayoutRelativeToPrimaryContainer,
  convertAnyPositionToLeftTopPosition,
  getLayoutWithAspectRatio,
  getImageOrCropAspectRatio,
  isLayoutIntersectsRect,
  getContainerLayoutToFitContent,
  getCompPastePositionToFitContainer,
  doBoxesOverlap,
  doPointOverlapBox,
  getPositionInsideParentCloseToViewportCenter,
  getBoxesOverlapArea,
};
