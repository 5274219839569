import type {
  AddAppData,
  AppInstallOrigin,
} from '@wix/editor-platform-host-integration-apis';
import type { AppData } from 'types/documentServices';

export interface InstallAppOptions {
  shouldOpenFakeProgressBar?: boolean;
  shouldHandleCallbacks?: boolean;
  origin?: AppInstallOrigin;
  appVersion?: string;
  skipActiveApps?: boolean;
  callback?: (data: AnyFixMe) => void;
  payload?: { updateType: string };
}

export interface ProvisionErrorData {
  error: Error;
  errName: string;
  appDefinitionId: string;
}

export interface InstallAppCallbacks {
  onError: (provisionErrorData: ProvisionErrorData) => void;
  onAppInstallationComplete?: (data: AddAppData | AppData) => void;
}

export interface UpdateAppCallbacks {
  singleAppCallback: (appDefinitionId: string, appData: AppData) => void;
  onError: (e: Error, errName: string, appDefinitionId: string) => void;
}
