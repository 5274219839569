// @ts-nocheck
import * as actionTypes from './googleMapActionTypes';

const setGoogleMapsLocationsPanelState = (panel, locationIndex) => ({
  type: actionTypes.SET_SINGLE_PANEL_STATE,
  panel,
  locationIndex,
});

const setLocationIndex = (locationIndex) => ({
  type: actionTypes.SET_LOCATION_INDEX,
  locationIndex,
});

const setOldLocationsForSession = (compId, locations) => ({
  type: actionTypes.SET_OLD_LOCATIONS_FOR_SESSION,
  compId,
  locations,
});

export {
  setGoogleMapsLocationsPanelState,
  setLocationIndex,
  setOldLocationsForSession,
};
