import type { Hooks } from '@/apilib';
import type { CompRef, Point } from 'types/documentServices';

export type LayoutMoveByParams =
  | {
      deltaX: number;
      deltaY?: number;
    }
  | {
      deltaX?: number;
      deltaY: number;
    }
  | {
      deltaX: number;
      deltaY: number;
    };

export type LayoutMoveToParams = Point;

export type LayoutMoveByInterceptor = Hooks.InterceptorHookInterceptor<{
  compRefs: CompRef[];
  params: LayoutMoveByParams;
}>;

export interface LayoutMoveHooks {
  moveByInterceptor: Hooks.InterceptorHook<{
    compRefs: CompRef[];
    params: LayoutMoveByParams;
  }>;
}
