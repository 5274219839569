import constants from '@/constants';
import devModeActionTypes from './devModeActionTypes';

export const devModeInitialState = {
  type: constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE,
  data: undefined as AnyFixMe,
};

const { RESET_CONTEXT } = devModeActionTypes;

const reducer = (devModeContext = devModeInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case RESET_CONTEXT:
      return devModeInitialState;
    default:
      return devModeContext;
  }
};

export default reducer;
