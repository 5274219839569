// @ts-nocheck
import tpaTypeUtils from './tpaTypeUtils';

const NEW_BLOG_APP_DEF_ID = '14bcded7-0066-7c35-14d7-466cb3f09103';

function getAppDefId(editorAPI, pageData) {
  if (tpaTypeUtils.isTPA(pageData)) {
    const tpaPageData = editorAPI.dsRead.tpa.section.getPageData(
      pageData.id,
    ).appData;
    return tpaPageData?.appDefinitionId;
  }
}

const blogTypeUtils = {
  isBlog,
  isBlogPage,
  isBlogSpecialPage,
  isBlogSectionPage,
};

function isBlogPage(editorAPI, pageData) {
  return getAppDefId(editorAPI, pageData) === NEW_BLOG_APP_DEF_ID;
}

function isBlogSpecialPage(pageData) {
  return pageData.appPageId === '7326bfbb-4b10-4a8e-84c1-73f776051e10';
}

function isBlogSectionPage(editorAPI, pageData) {
  return (
    blogTypeUtils.isBlog(editorAPI, pageData) &&
    !blogTypeUtils.isBlogSpecialPage(pageData)
  );
}

function isBlog(editorAPI, pageData) {
  if (pageData.appInnerID) {
    return (
      editorAPI.dsRead.wixapps.classics.getPackageName(pageData.appInnerID) ===
      'blog'
    );
  }
  return false;
}

export default blogTypeUtils;
