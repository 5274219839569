import {
  ensureItemLayoutIsMeshItemLayout,
  ensureLayoutSizeIsPx,
  layoutSize,
} from '@/layoutUtils';
import type { EditorAPI } from '@/editorAPI';
import type { HistoryAddOptions } from '@/history';
import type { CompRef, MeshItemLayout } from 'types/documentServices';
import type { HistoryApi } from '../../createHistoryApi';
import type { LayoutMeshApi } from '../createLayoutMeshApi';

function ensureItemLayoutMarginTopIsPx(
  itemLayout: Partial<MeshItemLayout>,
): asserts itemLayout is { margins: { top: { type: 'px'; value: number } } } {
  ensureLayoutSizeIsPx(itemLayout.margins.top);
}

export function moveByAndPushTransition(
  {
    editorAPI,
    historyApi,
    layoutMeshCoreApi,
  }: {
    editorAPI: EditorAPI;
    historyApi: HistoryApi;
    layoutMeshCoreApi: LayoutMeshApi['__core'];
  },
  compRefRaw: CompRef,
) {
  const compRef = layoutMeshCoreApi.resolveCompRefWithVariant(compRefRaw);
  const compRectInitial = layoutMeshCoreApi.measureRect(compRef);
  const itemLayoutInitial = layoutMeshCoreApi.get(compRef).itemLayout;

  ensureItemLayoutIsMeshItemLayout(itemLayoutInitial);
  ensureItemLayoutMarginTopIsPx(itemLayoutInitial);

  let deltaYLatest = 0;
  const update = ({ deltaY }: { deltaY: number }) => {
    if (deltaY === deltaYLatest) {
      return;
    }

    layoutMeshCoreApi.update(compRef, {
      itemLayout: {
        ...itemLayoutInitial,
        margins: {
          ...itemLayoutInitial.margins,
          top: layoutSize.px(itemLayoutInitial.margins.top.value + deltaY),
        },
      },
    });

    deltaYLatest = deltaY;
  };

  const end = async (options: HistoryAddOptions & {} = {}) => {
    await editorAPI.transactions.run(async () => {
      const compContainerRef = editorAPI.components.getContainer(compRef);
      const compRectsMap = new Map([
        [
          compRef.id,
          {
            ...compRectInitial,
            y: compRectInitial.y + deltaYLatest,
          },
        ],
      ]);

      layoutMeshCoreApi.updateContainerGrid(compContainerRef, { compRectsMap });
    });

    historyApi.add('component - move and push', options);
  };

  return {
    update,
    end,
  };
}
