export default {
  LOBBY: '190d1fe2-0c9a-4012-a875-903a88014e38',
  THEME: '4d7dd491-e163-4df5-ba5b-f17237f7dde1',
  BASE_COLORS: '287a0974-019a-4fa6-a4df-1be8f1e22cdc',
  ACCENT_COLORS: 'fc046501-3690-46e7-92a3-09252cc95dca',
  COLOR: 'dd66bbbd-1dd8-434e-9338-395091aa80d6',
  COLOR_NEW_PALETTE: '7123d6a3-07a8-4824-897a-6d267fcd7dc2',
  TEXT: '57c6d996-a6d1-44f6-a1f8-112eb366a9ea',
  TRANSITION: 'dfc95824-954a-4d20-aad2-7971d3fbdb98',
  ACCESSIBILITY: '0b74cb8d-4534-413a-a7e2-f776b539f053',
  ADVANCED_SETTINGS: '828fd8be-ad0a-477d-8c6c-f8fd85bbd493',
};
