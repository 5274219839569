import _ from 'lodash';
import actionTypes from './secondaryLeftAreaActionTypes';
import type { Reducer } from 'types/redux';

const { SET_WIDTH, SET_CONTENT_MODULE } = actionTypes;

interface SecondaryLeftAreaState {
  contentModule: AnyFixMe;
  width: string;
}

export const secondaryLeftAreaInitialState: SecondaryLeftAreaState = {
  contentModule: undefined,
  width: undefined,
};

const reducer: Reducer<SecondaryLeftAreaState> = (
  secondaryLeftArea = secondaryLeftAreaInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case SET_WIDTH:
      const width = action?.newWidth ?? secondaryLeftArea.width;
      return _.defaultsDeep({}, { width }, secondaryLeftArea);
    case SET_CONTENT_MODULE:
      const contentModule =
        action?.moduleName ?? secondaryLeftArea.contentModule;
      return _.defaultsDeep({}, { contentModule }, secondaryLeftArea);
    default:
      return secondaryLeftArea;
  }
};

export default reducer;
