// @ts-nocheck
import constants from '@/constants';
import * as util from '@/util';
import { recompose } from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as domMeasurements from '../domMeasurements/domMeasurements';
import * as panelsActions from './panelsActions';

const { setPanelType, closePanelByName } = panelsActions;
const { getStageLayout, getEditorContentLayout } = domMeasurements.selectors;

export interface PanelFrameProps {
  isSingleInstance?: boolean;
}

export interface ProvidedPanelFrameProps {
  blockOuterScroll: React.ReactEventHandler;
  openHelpCenter: (helpId: string) => void;
  close: (origin: string) => void;
}

function createPanelFrame(Component, frameType) {
  // eslint-disable-next-line react/prefer-es6-class
  const PanelFrame = createReactClass({
    displayName: recompose.wrapDisplayName(Component, 'PanelFrame'),
    propTypes: {
      selectedComponentType: PropTypes.string,
      onHelpClicked: PropTypes.func,
      panelName: PropTypes.string.isRequired,
      helpId: PropTypes.string,
      isSingleInstance: PropTypes.bool,
      closeWithUserIntent: PropTypes.bool,
      isHidden: PropTypes.bool,
      setPanelType: PropTypes.func.isRequired,
      closePanelByName: PropTypes.func.isRequired,
      onPanelClosed: PropTypes.func,
      openHelpCenter: PropTypes.func,
      disableBlockingScroll: PropTypes.bool,
      stageLayout: PropTypes.object.isRequired,
    },
    UNSAFE_componentWillMount() {
      this.props.setPanelType(
        this.props.panelName,
        frameType,
        this.props.isSingleInstance,
        this.props.closeWithUserIntent,
        this.props.isHidden,
      );
    },
    blockOuterScroll(e) {
      const contentNode = e.currentTarget;
      const totalHeight = e.currentTarget.scrollHeight;
      const maxScroll = totalHeight - e.currentTarget.offsetHeight;
      const delta = e.deltaY % 3 ? e.deltaY : e.deltaY * 10;
      if (contentNode.scrollTop + delta <= 0) {
        contentNode.scrollTop = 0;
        e.preventDefault();
      } else if (contentNode.scrollTop + delta >= maxScroll) {
        contentNode.scrollTop = maxScroll;
        e.preventDefault();
      }
      e.stopPropagation();
    },
    close(origin: string) {
      this.props.closePanelByName(this.props.panelName, origin);
      this.props.onPanelClosed?.();
    },
    openHelpCenter(biHelpParams) {
      const helpId = this.props.helpId || this.props.helpLink;
      if (helpId) {
        const mergedBiParams = {
          learn_more: false,
          origin: constants.BI.HELP.ORIGIN.PANEL,
          panel_name: this.props.panelName,
          component: this.props.selectedComponentType,
        };
        if (!this.props.selectedComponentType) {
          _.merge(mergedBiParams, _.pick(biHelpParams, 'component'));
        }
        this.props.openHelpCenter(helpId, null, mergedBiParams);
      }
      if (this.props.onHelpClicked) {
        this.props.onHelpClicked();
      }
    },

    render() {
      return React.createElement(
        Component,
        Object.assign({}, this.props, {
          blockOuterScroll: this.props.disableBlockingScroll
            ? _.noop
            : this.blockOuterScroll,
          openHelpCenter: this.openHelpCenter,
          close: this.close,
        }),
      );
    },
  });

  PanelFrame.defaultProps = {
    isSingleInstance: false,
  };

  return PanelFrame;
}

const mapStateToProps = ({ state, editorAPI }) => ({
  editorContentLayout: getEditorContentLayout(state),
  stageLayout: getStageLayout(state),
  openHelpCenter: editorAPI.panelManager.openHelpCenter,
});

const mapDispatchToProps = {
  setPanelType,
  closePanelByName,
};

export default (frameType) => (Component) =>
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(createPanelFrame(Component, frameType));
