import { SET_BLOG_MIGRATION_STATUS } from './blogActionTypes';

export const blogInitialState = {
  migrationStatus: null as AnyFixMe,
};

const reducer = (state = blogInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case SET_BLOG_MIGRATION_STATUS:
      return { ...state, migrationStatus: action.status };
    default:
      return state;
  }
};

export default reducer;
