// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import constants from '@/constants';

import type { EditorAPI } from '@/editorAPI';
import type { CompStructure } from 'types/documentServices';

const CONTROLLER_COMP_TYPE = 'platform.components.AppController';

function isSameConnection(serializedConnectionItem, compConnection) {
  return (
    _.isEqual(
      compConnection.controllerRef,
      serializedConnectionItem.controllerRef,
    ) && _.isEqual(compConnection.role, serializedConnectionItem.role)
  );
}

function rejectAlreadyExistingConnections(
  editorAPI,
  compId,
  compSerializedConnections,
) {
  const compRef = editorAPI.components.get.byId(
    compId,
    editorAPI.dsRead.pages.getFocusedPageId(),
  );
  if (!compRef) {
    return [];
  }
  const compConnections =
    editorAPI.dsRead.platform.controllers.connections.get(compRef);
  return _.reject(
    compSerializedConnections,
    function (serializedConnectionItem) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      return _.find(
        compConnections,
        _.partial(isSameConnection, serializedConnectionItem),
      );
    },
  );
}

function removeIdIfAlreadyExist(editorAPI, serializedData) {
  const controllerRef =
    serializedData.id && editorAPI.components.get.byId(serializedData.id);
  if (controllerRef && editorAPI.components.is.exist(controllerRef)) {
    return _.omit(serializedData, 'id');
  }
  return serializedData;
}

function removeConnectedComponentsIfAlreadyExist(editorAPI, serializedData) {
  const controllerSerializedData = removeIdIfAlreadyExist(
    editorAPI,
    _.cloneDeep(serializedData),
  );
  const connectedComponents =
    editorAPI.dsRead.platform.serialization.getConnectedComponents(
      controllerSerializedData,
    );
  const newConnectedConnections = _.transform(
    connectedComponents,
    function (acc, compSerializedConnections, compId) {
      acc[compId] = rejectAlreadyExistingConnections(
        editorAPI,
        compId,
        compSerializedConnections,
      );
    },
    {},
  );
  return editorAPI.dsActions.platform.serialization.setConnectedComponents(
    controllerSerializedData,
    newConnectedConnections,
  );
}

function removeSerializedDataId(editorAPI, serializedData) {
  return serializedData.componentType === CONTROLLER_COMP_TYPE
    ? serializedData
    : _.omit(serializedData, 'id');
}

function adjustMobileTextScalingRecursive(
  editorAPI: EditorAPI,
  compStructure: CompStructure,
): void {
  if (compStructure.componentType === constants.COMP_TYPES.TEXT) {
    const textScale = compStructure.layout?.scale;
    if (editorAPI.isDesktopEditor() || (textScale && textScale === 1)) {
      return;
    }
    // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
    const themeFonts = editorAPI.theme.fonts.getAll();
    try {
      const previewFrame = document.getElementById(
        'preview',
      ) as HTMLIFrameElement;
      const previewDocument = previewFrame.contentWindow.document;
      const textComponent = previewDocument.getElementById(
        compStructure.originCompId,
      );
      compStructure.data.text =
        util.textUtils.decreaseTextFontSizeByRenderedText(
          textComponent,
          themeFonts,
          compStructure.data.text,
        );
    } catch (e) {
      compStructure.data.text = util.textUtils.decreaseTextFontSizeByScale(
        themeFonts,
        compStructure.data.text,
        compStructure.layout.scale,
      );
    }
    compStructure.layout.scale = 1;
  } else if (compStructure.components?.length) {
    compStructure.components.forEach((comp) =>
      adjustMobileTextScalingRecursive(editorAPI, comp),
    );
  }
}

function adjustMobileTextScaling(
  editorAPI: EditorAPI,
  compStructure: CompStructure,
): CompStructure {
  adjustMobileTextScalingRecursive(editorAPI, compStructure);
  return compStructure;
}

function stretchAppWidgetBottomOnConnectedComponentPaste(
  editorAPI,
  serializedData,
  appWidgetAncestor,
  compParent,
) {
  if (appWidgetAncestor) {
    const appWidgetLayoutRelativeToScreen =
      editorAPI.dsRead.components.layout.getRelativeToScreen(appWidgetAncestor);
    const parentLayoutRelativeToScreen =
      editorAPI.dsRead.components.layout.getRelativeToScreen(compParent);
    const distanceBetweenAppWidgetAndParent =
      parentLayoutRelativeToScreen.y - appWidgetLayoutRelativeToScreen.y;
    const componentBottomYRelativeToAppWidget =
      serializedData.layout.height +
      serializedData.layout.y +
      distanceBetweenAppWidgetAndParent;
    if (
      componentBottomYRelativeToAppWidget >
      appWidgetLayoutRelativeToScreen.height
    ) {
      editorAPI.components.layout.update(appWidgetAncestor, {
        height: componentBottomYRelativeToAppWidget,
      });
    }
  }
}

export {
  adjustMobileTextScaling,
  removeConnectedComponentsIfAlreadyExist,
  removeSerializedDataId,
  stretchAppWidgetBottomOnConnectedComponentPaste,
};
