import _ from 'lodash';
import addAudioPluginUtil from './commonUtils/addAudioPluginUtil';
import type { BeforePlugin } from 'types/editorPlugins';

function getAudioPlayerStructure(compDef: AnyFixMe, item: AnyFixMe) {
  return _.merge({}, compDef, {
    data: {
      uri: item.fileName,
      originalFileName: item.title,
    },
  });
}

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
) =>
  addAudioPluginUtil.handleAudioAdd(
    editorAPI,
    originalAddComponentFunc,
    containerRef,
    compDef,
    optionalId,
    onCompAddCallback,
    getAudioPlayerStructure,
  );

export default plugin;
