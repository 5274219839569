import type { DesktopSuperApps } from '@/constants';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import * as bi from '../bi/bi';
import * as panels from '../panels/panels';
import * as userPreferences from '../userPreferences/userPreferences';
import * as actionTypes from './leftBarActionTypes';
import * as selectors from './leftBarSelectors';
import * as util from '@/util';
import type { EditorState } from '@/stateManagement';
import type { Dispatch, StateMapperArgs } from 'types/redux';

import type {
  LeftBarItem,
  LeftBarDesktopItemKey,
  LeftBarMobileItemKey,
} from './leftBarData';

const panelsActions = panels.actions;

const highlightMenu = () => ({ type: actionTypes.HIGHLIGHT_MENU });
const unhighlightMenu = () => ({ type: actionTypes.UNHIGHLIGHT_MENU });

const collapseDesktopMenu = () => ({ type: actionTypes.COLLAPSE_DESKTOP_MENU }); // only for se_newWorkspace old
const collapseMobileMenu = () => ({ type: actionTypes.COLLAPSE_MOBILE_MENU }); // only for se_newWorkspace old
const expandButton = (panelName: string) => ({
  type: actionTypes.EXPAND_BUTTON,
  panelName,
});
const collapseButtonAction = (panelName: string) => ({
  type: actionTypes.COLLAPSE_BUTTON,
  panelName,
});
const updateButtonsBoundingRect = (boundingRect: AnyFixMe) => ({
  type: actionTypes.UPDATE_BUTTONS_BOUNDING_RECT,
  boundingRect,
});

const setNotificationsCount = (panelName: string, count: number) => ({
  type: actionTypes.SET_NOTIFICATION_COUNT,
  panelName,
  count,
});
const increaseNotificationsCount = (panelName: string, count: number) => ({
  type: actionTypes.INCREASE_NOTIFICATION_COUNT,
  panelName,
  count,
});
const decreaseNotificationsCount = (panelName: string, count: number) => ({
  type: actionTypes.DECREASE_NOTIFICATION_COUNT,
  panelName,
  count,
});
const removePanelNotifications = (panelName: string) => ({
  type: actionTypes.REMOVE_PANEL_NOTIFICATIONS,
  panelName,
});

const updateDesktopSuperAppButton = (buttonParams: AnyFixMe) => ({
  type: actionTypes.UPDATE_DESKTOP_SUPERAPP_BUTTON,
  buttonParams,
});
const updateMobileSuperAppButton = (buttonParams: AnyFixMe) => ({
  type: actionTypes.UPDATE_MOBILE_SUPERAPP_BUTTON,
  buttonParams,
});
const updateDidDismissMobileHiddenItemsTooltip = (didDismiss: boolean) => ({
  type: actionTypes.UPDATE_DID_DISMISS_MOBILE_HIDDEN_ITEMS_TOOLTIP,
  payload: {
    didDismiss,
  },
});
const updateIsMobileHiddenItemsTooltipEnabled = (isEnabled: boolean) => ({
  type: actionTypes.UPDATE_IS_MOBILE_HIDDEN_ITEMS_TOOLTIP_ENABLED,
  payload: {
    isEnabled,
  },
});

const removeSuperAppButton = (panelName: AnyFixMe) => ({
  type: actionTypes.REMOVE_SUPERAPP_BUTTON,
  panelName,
});

const overrideItems = (overriddenButtons: AnyFixMe) => ({
  type: actionTypes.OVERRIDE_LEFT_BAR,
  overriddenButtons,
});

const sendBIEvent = bi.actions.event;

const collapseMenu = () => (dispatch: Dispatch, getState: AnyFixMe) => {
  const isMobile = selectors.isMobileEditor(getState());

  if (isMobile) {
    dispatch(collapseMobileMenu());
  } else {
    dispatch(collapseDesktopMenu());
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LeftBarPatches<T extends LeftBarDesktopItemKey | LeftBarMobileItemKey> =
  Partial<Record<LeftBarDesktopItemKey, Partial<LeftBarItem>>>;

const patchDesktopLeftBarItems = (
  items: LeftBarPatches<LeftBarDesktopItemKey>,
) => ({
  type: actionTypes.PATCH_DESKTOP_LEFT_BAR_ITEMS,
  items,
});
const patchMobileLeftBarItems = (
  items: LeftBarPatches<LeftBarMobileItemKey>,
) => ({
  type: actionTypes.PATCH_MOBILE_LEFT_BAR_ITEMS,
  items,
});

const collapseButton = (panelName: string) => (dispatch: Dispatch) => {
  dispatch(collapseButtonAction(panelName));
  dispatch(
    userPreferences.actions.setSiteUserPreferences(
      panelName + constants.LEFT_BAR.WAS_BUTTON_CLICKED_KEY,
      true,
    ),
  );
};

const openAddPanel = () => (dispatch: Dispatch) => {
  dispatch(collapseMenu());

  const panelName = util.addPanel.isNewPanelEnabled()
    ? constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME
    : constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME;

  if (util.workspace.isNewWorkspaceEnabled()) {
    dispatch(panelsActions.openLeftPanel(panelName));
  } else {
    dispatch(panelsActions.updateOrOpenPanel(panelName));
  }
};

const sendBIButtonsCount =
  (isPopUpMode: boolean) =>
  (dispatch: Dispatch, getState: AnyFixMe, { dsRead }: AnyFixMe) => {
    const leftBarMenuItems = selectors.getMenuItems(getState(), isPopUpMode);
    const siteId = dsRead && dsRead.generalInfo.getSiteId();

    if (siteId) {
      const panelsName = leftBarMenuItems.map(({ panelName }) => panelName);
      const biParams = {
        count: leftBarMenuItems.length,
        site_id: siteId,
        blog_bool: panelsName.includes(constants.SUPER_APPS.BLOG_PANEL_NAME),
        social_blog_bool: panelsName.includes(
          constants.SUPER_APPS.NEW_BLOG_PANEL_NAME,
        ),
        booking_bool: panelsName.includes(
          constants.SUPER_APPS.BOOKINGS_PANEL_NAME,
        ),
        ecomm_bool: panelsName.includes(
          constants.SUPER_APPS.WIX_STORE_PANEL_NAME,
        ),
        ascend_bool: panelsName.includes(
          constants.SUPER_APPS.ASCEND_PANEL_NAME,
        ),
        wix_data_bool: panelsName.includes(
          constants.SUPER_APPS.WIX_DATA_PANEL_NAME,
        ),
        rest_bool: false, // TODO : add panel name once added to editor
      };

      dispatch(
        sendBIEvent(coreBi.events.leftBar.LEFT_BAR_NUMBER_OF_BUTTONS, biParams),
      );
    }
  };

const sendBIButtonClick = (category: string) => (dispatch: Dispatch) => {
  dispatch(
    sendBIEvent(coreBi.events.leftBar.LEFT_BAR_BUTTON_CLICK, {
      category,
    }),
  );
};

const sendBIButtonHover =
  (element_name: string) =>
  (
    dispatch: Dispatch,
    _: () => EditorState,
    { editorAPI }: StateMapperArgs,
  ) => {
    const editorMode = editorAPI.isMobileEditor() ? 'mobile' : 'desktop';
    dispatch(
      sendBIEvent(coreBi.events.leftBar.LEFT_BAR_BUTTON_HOVER, {
        editorMode,
        element_name,
      }),
    );
  };

const getLeftBarButtonOrder = (panelName: string) =>
  constants.LEFT_BAR.SUPER_APPS_ORDER.DESKTOP.indexOf(
    panelName as DesktopSuperApps,
  );

const getLeftBarButtonParams = (
  panelName: string,
  panelProps: Record<string, AnyFixMe>,
  label: string,
  className: string,
  symbolName: string,
  isButtonOpen: boolean,
  key?: string,
) => ({
  panelName,
  panelProps,
  label,
  className,
  symbolName,
  order: getLeftBarButtonOrder(panelName),
  isButtonOpen,
  key,
});

const addSuperAppsButton =
  (
    panelName: AnyFixMe,
    panelProps: AnyFixMe,
    label: AnyFixMe,
    className: AnyFixMe,
    symbolName: AnyFixMe,
    addToMobile: AnyFixMe,
    collapseOnClick: AnyFixMe,
    isButtonOpen: AnyFixMe,
    key?: string,
  ) =>
  (dispatch: Dispatch, getState: AnyFixMe) => {
    const buttonParams = getLeftBarButtonParams(
      panelName,
      panelProps,
      label,
      className,
      symbolName,
      isButtonOpen,
      key,
    );

    dispatch(updateDesktopSuperAppButton(buttonParams));
    if (addToMobile) {
      dispatch(updateMobileSuperAppButton(buttonParams));
    }

    const isAlreadyClicked = userPreferences.selectors.getSiteUserPreferences(
      panelName + constants.LEFT_BAR.WAS_BUTTON_CLICKED_KEY,
    )(getState());
    if (collapseOnClick && !isAlreadyClicked) {
      dispatch(expandButton(panelName));
    }
  };

const removeSuperAppsButton = (panelName: string) => (dispatch: Dispatch) => {
  dispatch(leftBarActions.removeSuperAppButton(panelName));
  dispatch(removePanelNotifications(panelName));
  dispatch(panelsActions.closePanelByNameAction(panelName));
};

const leftBarActions = {
  collapseDesktopMenu,
  collapseMobileMenu,
  expandButton,
  updateButtonsBoundingRect,
  setNotificationsCount,
  increaseNotificationsCount,
  decreaseNotificationsCount,
  removePanelNotifications,
  updateDesktopSuperAppButton,
  updateMobileSuperAppButton,
  removeSuperAppButton,
  overrideItems,
  patchDesktopLeftBarItems,
  patchMobileLeftBarItems,
  collapseMenu,
  collapseButton,
  highlightMenu,
  unhighlightMenu,
  openAddPanel,
  sendBIButtonsCount,
  sendBIButtonClick,
  sendBIButtonHover,
  addSuperAppsButton,
  removeSuperAppsButton,
  updateDidDismissMobileHiddenItemsTooltip,
  updateIsMobileHiddenItemsTooltipEnabled,
};

export default leftBarActions;
