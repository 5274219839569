// @ts-nocheck
import _ from 'lodash';

const getAppIdsToRefreshAfterDashboardClose = (state) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys, you-dont-need-lodash-underscore/map
  _.map(_.keys(state.tpa.appsIdsToRefreshOnDashboardClose), (appId) =>
    parseInt(appId, 10),
  );

const getShouldRefreshGeneralInfo = (state) =>
  state.tpa.shouldRefreshGeneralInfo;

const getAppsToTrackForUpgrade = (state) => state.tpa.trackedAppsForUpgrade;

const getTpaSettingsPanelExtraProps = (state) =>
  state.tpa.settingsPanelExtraProps;

export {
  getAppIdsToRefreshAfterDashboardClose,
  getTpaSettingsPanelExtraProps,
  getAppsToTrackForUpgrade,
  getShouldRefreshGeneralInfo,
};
