import _ from 'lodash';
import * as actionTypes from './rulersActionTypes';
import type { Reducer } from 'types/redux';

const {
  RESET_HISTORY,
  UNDO,
  REDO,
  UPDATE_HISTORY,
  UPDATE_STATE,
  SET_RULERS_STATE,
} = actionTypes;

interface Guide {
  guideId: string;
  guidePosition: number;
}

export interface GuidesState {
  desktop: {
    verticalGuides: Guide[];
    horizontalGuides: Guide[];
  };
  mobile: {
    verticalGuides: Guide[];
    horizontalGuides: Guide[];
  };
}

export interface RulersState {
  guidesState: GuidesState;
  selectedGuide: string;
  isHovered: boolean;
  isDragging: boolean;
  history: {
    stack: GuidesState[];
    currentIndex: number;
  };
}

export const rulersInitialState: RulersState = {
  guidesState: {
    desktop: {
      verticalGuides: [],
      horizontalGuides: [],
    },
    mobile: {
      verticalGuides: [],
      horizontalGuides: [],
    },
  },
  selectedGuide: undefined,
  isHovered: undefined,
  isDragging: null,
  history: {
    stack: [],
    currentIndex: 0,
  },
};

const reducer: Reducer<RulersState> = (
  rulers = rulersInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case SET_RULERS_STATE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, rulers, action.rulersState);

    case RESET_HISTORY:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, rulers, {
        history: {
          stack: [rulers.guidesState],
          currentIndex: 0,
        },
      });

    case UNDO:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, rulers, {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        history: _.assign({}, rulers.history, {
          currentIndex: rulers.history.currentIndex - 1,
        }),
      });

    case REDO:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, rulers, {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        history: _.assign({}, rulers.history, {
          currentIndex: rulers.history.currentIndex + 1,
        }),
      });

    case UPDATE_HISTORY:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, rulers, {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        history: _.assign({}, rulers.history, {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/concat
          stack: _.concat(
            _.take(rulers.history.stack, rulers.history.currentIndex + 1),
            rulers.guidesState,
          ),
          currentIndex: rulers.history.currentIndex + 1,
        }),
      });

    case UPDATE_STATE:
      return action.state;

    default:
      return rulers;
  }
};

export default reducer;
