// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';

const getFontObject = (fonts) => {
  fonts = _.reject(fonts, function (font) {
    return !font.fileInput;
  });
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(fonts, function (font) {
    return util.fontsManagerUtils.getUploadedFontData(font);
  });
};

const buildUploadedItems = (queue) =>
  _.transform(
    queue,
    function (uploadedItems, queueItem) {
      if (
        queueItem.status ===
        util.fontsManagerUtils.constants.FONT_STATUS.SUCCEEDED
      ) {
        uploadedItems[queueItem.id] = { finished: true };
      }
    },
    {},
  );

const buildQueueItemForStore = (data) => {
  const queueItem = _.pick(data, [
    'canceled',
    'cssFontFamily',
    'displayName',
    'downloadUrl',
    'error_code',
    'fileUrl',
    'fontFace',
    'fontFamily',
    'id',
    'isAborted',
    'name',
    'removed',
    'status',
    'stillUploading',
  ]);

  queueItem.source = _.cloneDeep(data.source);
  queueItem.fileInput = _.cloneDeep(data.fileInput);
  queueItem.fileOutput = _.cloneDeep(data.fileOutput);
  queueItem.serverResponse = _.cloneDeep(data.serverResponse);

  return queueItem;
};

const getInitialQueueWithData = (queue) =>
  _.flow([getNewQueueData, getUploadedFontsData])(queue);

const getNewQueueData = (queue) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(queue, function (item) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(item, { name: item.source.name });
  });

const getUploadedFontsData = (queue) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(queue, function (queueItem) {
    if (
      queueItem.status ===
      util.fontsManagerUtils.constants.FONT_STATUS.SUCCEEDED
    ) {
      const fontObj = queueItem?.serverResponse?.data;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(
        {},
        queueItem,
        util.fontsManagerUtils.getUploadedFontData(fontObj),
      );
    }

    return queueItem;
  });

export {
  getFontObject,
  buildUploadedItems,
  buildQueueItemForStore,
  getInitialQueueWithData,
  getNewQueueData,
};
