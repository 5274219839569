const RESET_HISTORY = 'rulers/reset-history';
const UNDO = 'rulers/undo';
const REDO = 'rulers/redo';
const UPDATE_HISTORY = 'rulers/update-history';
const UPDATE_STATE = 'rulers/update-state';
const SET_RULERS_STATE = 'rulers/set-rulers-state';

export {
  RESET_HISTORY,
  UNDO,
  REDO,
  UPDATE_HISTORY,
  UPDATE_STATE,
  SET_RULERS_STATE,
};
