import _ from 'lodash';
import { combineReducers } from 'redux';
import { mobileInitialState } from '../initialState';
import * as actionTypes from './mobileWizardActionTypes';
import mobileWizardTinyMenuReducer from './mobileWizardTinyMenuReducer';
import mobileWizardHeaderReducer from './mobileWizardHeaderReducer';
import mobileWizardQabReducer from './mobileWizardQabReducer';

const currentStepReducer = (
  currentStep = mobileInitialState.mobileWizard.currentStep,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_STEP:
      return action.currentStep;
    default:
      return currentStep;
  }
};

const enabledReducer = (
  enabled = mobileInitialState.mobileWizard.enabled,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_MOBILE_WIZARD_IS_ENABLED:
      return action.enabled;
    default:
      return enabled;
  }
};

const openingStateReducer = (
  openingState = mobileInitialState.mobileWizard.openingState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_MOBILE_WIZARD_OPENING_STATE:
      return action.openingState;
    default:
      return openingState;
  }
};

const stepsToShowReducer = (
  stepsToShow = mobileInitialState.mobileWizard.stepsToShow,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_STEPS_TO_SHOW:
      return action.stepsToShow;
    default:
      return stepsToShow;
  }
};

const userSelectionsReducer = (
  userSelections = mobileInitialState.mobileWizard.userSelections,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_USER_SELECTIONS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userSelections, {
        [action.stepName]: action.stepValue,
      });

    default:
      return userSelections;
  }
};

const lastSavedReducer = (
  lastSaved = mobileInitialState.mobileWizard.lastSaved,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_LAST_SAVED:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, lastSaved, { [action.stepName]: action.value });
    default:
      return lastSaved;
  }
};

export default combineReducers({
  enabled: enabledReducer,
  openingState: openingStateReducer,
  currentStep: currentStepReducer,
  stepsToShow: stepsToShowReducer,
  userSelections: userSelectionsReducer,
  lastSaved: lastSavedReducer,
  tinyMenu: mobileWizardTinyMenuReducer,
  header: mobileWizardHeaderReducer,
  qab: mobileWizardQabReducer,
});
