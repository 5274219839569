import type { RelatedApp } from '@wix/add-panel-component';

export enum RelatedAppsActionTypes {
  SetRelatedApps = '@related-apps/set-related-apps-action',
  StartRelatedAppsLoading = '@related-apps/start-related-apps-loading',
}

export interface RelatedAppsState {
  isLoading: boolean;
  value: RelatedApp[];
}
