export default {
  SOUND_CLOUD: '09e6dafa-963c-4f67-8967-0a077fc1a0cf',
  SPOTIFY_PLAYER: '6b54fe98-9576-426a-a8bc-b6ecd97c3e46',
  SPOTIFY_FOLLOW: '74ce7388-15d7-468e-984f-4d15a2257005',
  ITUNES_BUTTON: 'efc503e3-f989-4a9c-aa90-ae6115aba215',
  VIDEO: '6c2f22ee-09e8-4306-978a-4c0f34c0f25a',
  VIDEO_PLAYER: '550ae2f1-0c89-48cd-a8fa-ad85eff3372c',
  WPHOTO: '17285747-d275-441c-afb5-82597d5b3a76',
  CLIPART: '4e0894d0-352c-42b0-91e7-7d50a4dc6e60',
  WRICHTEXT: '4c4a4b4b-c9fc-48e7-b357-a00b8910eab1',
  WRICHTEXT_MOBILE: '79267696-2d13-44bd-bea0-d2ed6cef5228',
  WRICHTEXT_MOBILE_OPTIMIZE_TEXT: '270f26ed-3bab-4089-a00c-b3f182078531',
  BG_IMAGE_STRIP: '6f489af9-7f85-45b7-bf19-91693703247a',
  SITE_BUTTON: '5f4c4450-3536-4012-8cb8-4efdad505884',
  IMAGE_BUTTON: '58b325b8-7cff-41e2-91c6-521ddccb487f',
  VECTOR_SHAPE: 'bfd06685-7fe8-48b0-8f7b-e7d0989c0b59',
  VECTOR_COLOR: 'bfd06685-7fe8-48b0-8f7b-e7d0989c0b59',
  VECTOR_UGC: 'bfd06685-7fe8-48b0-8f7b-e7d0989c0b59',
  TEXT_INPUT: 'e7b449fb-1d92-430e-9ce3-56e0aaaf6bee',
  TEXT_AREA: 'a95936d4-7f1b-4068-bf7e-b4d15ef777c8',
  RICH_TEXT_BOX: '80aa6188-7cc9-4710-92cb-c3847d0f23e6',
  AUDIO_PLAYER: '8b7de177-8f58-4710-9b86-7fc97ef4959f',
  SINGLE_AUDIO_PLAYER: 'fc80b3f8-5a8e-4b36-bdfa-050a567a4a93',
  FACEBOOK_LIKE: 'bdbac375-052b-4307-9a44-75e187bddb3d',
  FACEBOOK_SHARE: 'd727fa92-1cc1-4582-be45-068f39bb895e',
  FACEBOOK_COMMENTS: 'be9bbdf7-47c0-4325-9f3b-50f76a14da1f',
  FACEBOOK_LIKE_BOX: '3f70b75a-c946-4989-9c4c-898a21b636ca',
  TWITTER_TWEET: '529b564d-2713-4db8-8910-f2223d235bd9',
  TWITTER_FOLLOW: '95bb48fd-c7b5-49f5-a69e-02895f61b034',
  PINTEREST_PIN_IT: 'd7327a6c-cfdb-4caf-8d1d-2fe4b71e2efb',
  PINTEREST_FOLLOW: 'da6df43a-6cf7-43a5-969a-59ed840b3565',
  PINTEREST_SHARE: '87fb2262-a588-460c-9dac-5e053c9207be',
  VK_SHARE: '11e021d9-ddbf-41b6-8c4f-1b8df16f3831',
  YOUTUBE_SUBSCRIBE: '04d07284-56c6-43e1-8a1f-e5e384dafe4e',
  CONTACT_FORM: '018ebceb-3b59-488d-9e24-ef41a50e8e64',
  DYNAMIC_CONTACT_FORM: '018ebceb-3b59-488d-9e24-ef41a50e8e64',
  GOOGLE_MAP: '14f5284e-63a1-4630-afe9-f3c9e1cbdcdb',
  NEW_GOOGLE_MAP: '44aa3c1b-e2c3-4f52-8125-3dfa7d2aa8ff',
  SKYPE_CALL: '9d6e6179-8d3d-4a68-9098-70750e06f112',
  LIST: 'cca9fad1-6f1c-47a2-af58-9c0d9a9f9cfd',
  HTML_EXTERNAL: 'ab3896f5-f2df-46a3-ad2f-0daec282dbb5',
  HTML_EMBEDDED: '2c56cce3-4d9f-40b2-b6a5-08138cf3958d',
  FLASH: '8c987dc3-486b-48e1-9ecf-96c38c2752bc',
  LOGIN_SOCIAL_BAR: 'a35903f3-e512-4a15-af55-4f49c6c704ec',
  PAYPAL: '28ed040f-2828-47f5-8601-f3db7bd9a720',
  DOCUMENT: '8cd3757a-cc68-4c0c-a9dd-72342bd6bf56',
  ANCHOR: '85ee5cfd-8237-4eca-806c-b8711388e101',
  VERTICAL_ANCHORS_MENU: '56cff04d-3b77-4213-a79d-0b9ec5ed39b1',
  VERTICAL_ANCHORS_MENU_SECTIONS: 'c4a2ac2c-4bbf-42c3-8b89-cc5147c34f48',
  VERTICAL_ANCHORS_MENU_AUTO_COLOR: '40e31c0c-966b-4a51-90e5-ccc586038d16',
  HEADER: 'f31637ad-03f8-4676-821e-0483eccdcaa9',
  HEADER_SCROLL: '0b9aa52e-c479-42cb-926a-eb4f122edf81',
  HEADER_SCROLL_MOBILE: 'f3a3b37c-d211-4424-a181-511d0d572266',
  FOOTER: '2cea8027-a5ce-46a6-947e-266aa972bd01',
  FLICKR: 'fc84fbfc-f60a-45db-bb83-05fa14d7e49e',
  POPUP_CONTAINER: '2c385c77-0aeb-4768-89e4-ee1b13617af6',
  POPUP_OVERLAY: 'be516d21-ee47-4606-8d0b-313c7a1e6d22',
  BOX_SLIDE_SHOW: '908b9660-647a-4542-8e5f-6ee17e52c30b',
  IMAGE_OPTIMIZATION: 'f6a9138a-2c1b-4c89-881d-ed2f5eb3d5c9',
  TINY_MENU: '9db5f55e-2e14-4915-8cb6-9279d6637963',
  POPUP_CLOSE_TEXT_BUTTON: 'aa6c9a19-13d0-41f9-a2a7-c76a50205565',
  COLLAGE_GALLERY: '87353a1f-494a-426b-8b97-5dfa9e57243f',
  FREESTYLE_GALLERY: 'b10d812b-dda1-454b-a4b8-82bd2a097b89',
  MATRIX_GALLERY: 'bdb3f0f6-5d56-458e-ace1-71cee98472ac',
  HONEYCOMB_GALLERY: '65adb8c6-df41-4c08-b3e2-2836765317a4',
  PAGINATED_GRID_GALLERY: 'b66b2120-b1d8-445f-abc8-ca68d56f28d4',
  SLIDESHOW_STRIP_GALLERY: '4f4d2bd9-811e-4a4b-a040-be9d1476f621',
  SHOWCASE_STRIP_GALLERY: 'f57e9d37-e041-433f-8574-dd4363d54c2b',
  IMPRESS_GALLERY: 'a47df7ce-176e-43ca-85ad-486c6f3a86f4',
  ACCORDION_GALLERY: '327e525a-672d-4baf-a665-f4bebc55a635',
  MASONRY_GALLERY: '63d9ee4e-dcca-45bf-8417-0df906ead4d6',
  SLIDER_GALLERY: 'b296e2a8-e082-4621-bc05-1cc45385cde6',
  SLIDESHOW_GALLERY: '58b019a4-746e-4e72-9ec6-a41ce70f9a2d',
  THUMBNAIL_GALLERY: '24e86e44-560d-4894-90b7-2d58e8f2eee0',
  CAROUSEL_3D_GALLERY: 'e0f040e7-b1d1-4400-88e1-32f7ef8f6793',
  SLIDESHOW_3D_GALLERY: '9460a294-1dc8-4b86-8914-f07fadefa658',
  QUICK_ACTION_BAR: '1d4e9c27-bb0e-4834-a068-f5cf82847e5a',
  MANAGE_COLUMNS: 'b9ddf47c-e6b9-4441-bc1c-8cabede49c72',
  MANAGE_COLUMNS_MOBILE: '99d5c560-8df6-4188-9551-9283d82d939a',
  COMBO_BOX_INPUT: '1512e7fa-c30b-4209-b84b-8098a1aecc9a',
  DATE_PICKER: '54425412-0fb9-422e-8a02-60b44c04b082',
  RADIO_BUTTON: '8343f20c-f236-41fd-a5d5-b964e21cd579',
  HOVER_BOX: 'e98d2677-9601-4028-9b26-b19aa4b23aa6',
  HOVER_BOX_APPLY_TO_HOVER: 'b42a0b3d-b7e6-4aff-b678-97a90ebbbbb7',
  HOVER_BOX_APPLY_TO_REGULAR: '10d40a08-4565-4c3b-b866-dcbf87730d0c',
  COMBO_BOX_INPUT_MANAGE_ITEMS: 'edd6df77-c8b6-4fc9-9167-f090d9208cad',
  GRID: 'c1226dfc-b21c-4add-910f-2956a7cb47b0',
  GRID_COLUMNS: 'd25e64d4-07fa-4045-bf26-f6612c74e2eb',
  MEDIA_PLAYER: 'ca5860c3-ec89-4f45-a5f4-d4c27553206f',
  MEDIA_PLAYER_ALPHA: '2ccbae83-0763-433b-91fb-2bfa4b0fc591',
  MEDIA_CONTROLS: 'e5569a88-a533-498c-8f42-44365277f8f1',
  CHECKBOX: 'fc2049e4-c3f8-431f-aeb8-7248c5e60a7f',
  FILE_UPLOADER: 'b62a76a9-8fad-4858-944b-810bfd7a8990',
  PAGINATION: '4ff84052-7bd0-4418-bd33-dfbb78bf9940',
  RATINGS_DISPLAY: '212ee303-94cd-4d44-9df5-7a8df9bc96cb',
  STATE_BOX: '1e617266-9abb-483a-a384-ed2803211b70',
  SECTIONS: '581758cb-68b0-4312-a513-ca800113db41',
};
