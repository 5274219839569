import _ from 'lodash';
import constants from './constants';

export default function getKeyFromAppId(
  appId: string | undefined,
  key: string,
) {
  const prefix = `${_.defaultTo(appId, constants.EDITOR_APP_ID)}_`;
  return key.startsWith(prefix) ? key : `${prefix}${key}`;
}
