import { translate } from '@/i18n';

export default {
  ITUNES_BUTTON: 'd21769d8-2b87-4453-95ba-e746f1b391f3',
  SOUND_CLOUD: '2359089f-6976-48b3-bc0f-801c689f9ba3',
  PINTEREST_SHARE: 'b3b9b76f-fbaa-4230-8db6-179eaa764e58',
  PINTEREST_FOLLOW: '607a1ccc-33b3-4c76-ac3c-deaa7546285c',
  MEMBER_AREA_ADDED: '25cc5b41-56d8-4be1-95e1-b3df76cdbce6',
  SOSP_ATTACH_PANEL: '95f36e0d-651e-480b-8d35-1db2016843a5',
  SOSP_DETACH_PANEL: 'e3bb822e-e92e-4194-9b46-509f2c680158',
  YOUTUBE_SUBSCRIBE: '5286d377-4c51-4269-a336-fdfc3a1b6300',
  FACEBOOK_LIKE_BOX: 'a9e851eb-a0b9-4cc2-96bd-f7b17684ec2b',
  ANCHOR: '4932c49c-7282-48f0-8fa2-42d929981c40',
  PIN_TO_SCREEN_ON_MOBILE: translate(
    'PinToScreen_ControlPanel_Mobile_Notification_Learn_More_Link',
  ),
  QUICK_ACTION_BAR_DYNAMIC_ACTION: 'ab5bbd4e-5760-4ee6-95c0-dbdda4203477',
  REPEATER_MANAGE_ITEMS: 'd9143735-9bc7-4280-8754-7dc73c7cc586',
  BROWSER_DEPRECATED: translate(
    'Notification_Box_Browser_Not_Supported_Link_ID',
  ),
  WIX_FORMS_IN_CONTACT_FORM: '3f079ae2-312b-4196-99f8-73e2c142cb39',
  INPUT_ELEMENTS_MISSING_LABEL: '0929a4d2-1fbd-45b8-a064-4e6ceb6a20df',
  LINK_PANEL: {
    LINK_TO_SECTION_LINK: 'e62cde1d-e355-4cf7-b795-9adfcb07b9be',
  },
};
