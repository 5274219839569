import constants from '@/constants';
import * as util from '@/util';
import type { PanelDescriptor } from '../panelsReducer';
import { registerPanelPropsTransformer } from './panelPropsTransformers';

const shouldPanelBeTreatedAsNewAddPanel = (name: string): boolean => {
  return (
    name === constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME ||
    (name === constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME &&
      util.addPanel.isNewPanelEnabled())
  );
};

export const registerNewAddPanelPropsTransformers = () => {
  registerPanelPropsTransformer((descriptor): PanelDescriptor => {
    if (shouldPanelBeTreatedAsNewAddPanel(descriptor.name)) {
      return {
        ...descriptor,
        name: constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME,
      };
    }

    return descriptor;
  });
};
