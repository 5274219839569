import { editorStateSel } from './rootSelectors';
import { createSelector } from './selector';

import { selectors as pinModeSelectors } from '../pinMode/pinMode';

export const siteScaleSel = createSelector(
  editorStateSel,
  (state) => state.siteScale,
);

export const zoomModeSel = createSelector(
  siteScaleSel,
  (siteScale) => siteScale !== 1,
);

export const isPinModeSel = createSelector(
  editorStateSel,
  pinModeSelectors.isOpen,
);
