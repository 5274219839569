import type { BeforePlugin } from 'types/editorPlugins';

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
) => {
  // On drag - ignore drop position.
  compDef.layout.x = 0;
};

export default plugin;
