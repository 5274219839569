import * as panels from '../panels/panels';

const panelsActions = panels.actions;

interface OpenWixBlocksOptions {
  source: string;
}

const openWixBlocks =
  (options: OpenWixBlocksOptions) => (dispatch: AnyFixMe) => {
    dispatch(
      panelsActions.updateOrOpenPanel(
        'wixBlocksConsumer.panels.WixBlocksPanel',
        {
          source: options.source,
        },
      ),
    );
  };

export { openWixBlocks };
