// @ts-nocheck
import _ from 'lodash';

const getCurrentColumnIndex = (dsRead, columnRef) => {
  const columnsContainer =
    dsRead.deprecatedOldBadPerformanceApis.components.getContainer(columnRef);
  const columns =
    dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
      columnsContainer,
    );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  return _.findIndex(columns, (columnPointer) =>
    _.isEqual(columnPointer, columnRef),
  );
};

export { getCurrentColumnIndex };
