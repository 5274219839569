import actionTypes, {
  type IntroFunnelActionTypes,
} from './introFunnelActionTypes';
import _ from 'lodash';

export const introFunnelInitialState: AnyFixMe = {
  introFunnelIntents: [],
};

const reducer = (
  state = introFunnelInitialState,
  action: IntroFunnelActionTypes,
) => {
  switch (action.type) {
    case actionTypes.ADD_INTRO_FUNNEL_INTENT:
      const introFunnelIntents = state.introFunnelIntents.concat([
        action.appIntent,
      ]);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, state, { introFunnelIntents });
    default:
      return state;
  }
};

export default reducer;
