// @ts-nocheck
import _ from 'lodash';

const getComponentStore = (state, { comp }) =>
  comp ? state.componentsStore[comp.id] : null;
const getComponentStoreData = (state, { comp, key }) =>
  comp ? state?.componentsStore?.[comp.id]?.[key] ?? null : null;
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/map
const getComponentsList = (state) => _.map(state.componentsStore, 'compRef');

export { getComponentStore, getComponentStoreData, getComponentsList };
