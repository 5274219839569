// @ts-nocheck
import * as actionTypes from './addPanelActionTypes';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

import * as notificationsActions from '../notifications/notificationsActions';
const overrideItems = (overridden) => ({
  type: actionTypes.OVERRIDE_ADD_PANEL,
  overridden,
});

const UNSUPPORTED_CONTAINABLE_COMPONENTS_MESSAGE_MAP = {
  [constants.COMP_TYPES.REPEATER]: {
    message:
      'Notifications_Error_AppBuilder_MultistateRepeaterRoot_UnsupportedElement',
    title:
      'Notifications_Error_AppBuilder_MultistateRepeaterRoot_UnsupportedElement',
    type: constants.NOTIFICATIONS.TYPES.INFO,
  },
};

function getUnsupportedElementAddNotificationContent(
  editorAPI: EditorAPI,
  componentStructure: CompStructure,
  containerToAddTo: CompRef,
) {
  if (
    editorAPI.isMobileEditor() &&
    !editorAPI.components.is.allowedMobileOnlyByType(
      componentStructure.componentType,
    )
  ) {
    return {
      message: 'Notifications_Mobile_ElementNotSupported_Text',
      title: 'Notifications_Mobile_ElementNotSupported_Text',
      type: constants.NOTIFICATIONS.TYPES.INFO,
      link: {
        caption: 'Notifications_Learn_More_Link',
        onNotificationLinkClick: () => {
          editorAPI.panelManager.openHelpCenter(
            helpIds.MOBILE_ONLY.CANNOT_ADD_COMP_AS_MOBILE_ONLY,
          );
        },
      },
    };
  }
  const containerToAddToType = editorAPI.components.getType(containerToAddTo);
  return (
    UNSUPPORTED_CONTAINABLE_COMPONENTS_MESSAGE_MAP[containerToAddToType] ||
    UNSUPPORTED_CONTAINABLE_COMPONENTS_MESSAGE_MAP[
      constants.COMP_TYPES.REPEATER
    ]
  );
}

const showCannotAddComponentNotification =
  (componentStructure: CompStructure, containerToAddTo: CompRef) =>
  (dispatch, getState, { editorAPI }) => {
    dispatch(
      notificationsActions.showUserActionNotification(
        getUnsupportedElementAddNotificationContent(
          editorAPI,
          componentStructure,
          containerToAddTo,
        ),
      ),
    );
  };

export { overrideItems, showCannotAddComponentNotification };
