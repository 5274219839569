'use strict'
const acornParser = require('acorn')
const acornJSXParserExtension = require('acorn-jsx')

const JSDocParser = require('doctrine')
const _ = require('lodash')

const PUBLIC_API_ANNOTATIONS = {
    FUNCTION: 'function',
    PARAMETER: 'param',
    DESCRIPTION: 'description',
    RETURNS: 'returns'
}

const DECLARATION = {
    VARIABLE: 'VariableDeclaration',
    FUNCTION: 'FunctionDeclaration',
    EXPORTED: 'ExportNamedDeclaration'
}

const isArrowFunction = token => token.declaration.type === DECLARATION.VARIABLE && token.declaration.declarations[0].init.type === 'ArrowFunctionExpression'
const isFunction = token => token.declaration.type === DECLARATION.FUNCTION
const hasFunctionAnnotation = parsedJSDoc => _.some(parsedJSDoc.tags, {title: PUBLIC_API_ANNOTATIONS.FUNCTION})
const isBlockComment = token => _.last(token.leadingComments).type === 'Block'

const validateAppStudioFunctionDefinition = (token, parsedJSDoc) => {
    if (!isFunction(token) && !isArrowFunction(token)) {
        return false
    }

    if (!hasFunctionAnnotation(parsedJSDoc)) {
        return false
    }

    return isBlockComment(token)
}

const getParametersDefinition = parsedJSDoc => _(parsedJSDoc.tags)
    .filter({title: PUBLIC_API_ANNOTATIONS.PARAMETER})
    .map(param => ({
        name: param.name === 'null-null' ? undefined : param.name,
        description: param.description
    }))
    .value()

const getFunctionName = exportedDeclaration => _.get(exportedDeclaration, 'declaration.id.name') || _.get(exportedDeclaration, 'declaration.declarations[0].id.name')
const getFunctionDefinition = parsedJSDoc => _.chain(parsedJSDoc.tags)
    .find({title: PUBLIC_API_ANNOTATIONS.DESCRIPTION})
    .get(PUBLIC_API_ANNOTATIONS.DESCRIPTION)
    .value()
const getReturnsDescription = parsedJSDoc => {
    const returnsDef = _.find(parsedJSDoc.tags, {title: PUBLIC_API_ANNOTATIONS.RETURNS})
    if (!returnsDef) {
        return undefined
    }
    return returnsDef.description
}

function convertToAppStudioFunctionsDefinition(exportedDeclaration) {
    const options = {
        unwrap: true,
        tags: [..._.values(PUBLIC_API_ANNOTATIONS)],
        recoverable: true,
        sloppy: false,
        lineNumbers: false,
        range: false
    }

    const parsedJSDoc = JSDocParser.parse(_.last(exportedDeclaration.leadingComments).value, options)

    if (!validateAppStudioFunctionDefinition(exportedDeclaration, parsedJSDoc)) {
        return undefined
    }

    return {
        name: getFunctionName(exportedDeclaration),
        description: getFunctionDefinition(parsedJSDoc),
        params: getParametersDefinition(parsedJSDoc),
        returnsDescription: getReturnsDescription(parsedJSDoc)
    }
}

const removeSpreadOperator = code => code.replace(/\.\.\./g, '')
const consolidateRepeatingNewLines = code => code.replace(/\n\n+/g, '\n')

function getDeclarationLeadingComment(declaration, allComments) {
    const declarationFirstLine = declaration.loc.start.line
    const declarationLeadingLine = declarationFirstLine - 1
    const maybeDeclarationLeadingComment = allComments.find(comment => comment.loc.end.line === declarationLeadingLine)

    return maybeDeclarationLeadingComment
}

function addLeadingCommentToDeclaration(declaration, allComments) {
    const maybeDeclarationLeadingComment = getDeclarationLeadingComment(declaration, allComments)
    return maybeDeclarationLeadingComment ? {...declaration, leadingComments: [maybeDeclarationLeadingComment]} : declaration
}

function parseModule(code) {
    const normalizedCode = consolidateRepeatingNewLines(removeSpreadOperator(code || ''))    
    const allComments = []
    const config = {
        // When passing an array, acorn will populate it with all comments it finds while parsing
        onComment: allComments,
        sourceType: 'module',
        locations: true,
        ecmaVersion: '2020'
    }

    const parsedCode = acornParser.Parser.extend(acornJSXParserExtension()).parse(normalizedCode, config)

    const declarations = parsedCode.body
    return declarations.map(declaration => addLeadingCommentToDeclaration(declaration, allComments))
}

function simpleParse(code) {
    return acornParser.parse(code, {sourceType: 'module', ecmaVersion: '2020'})
}

function parse(code) {
    const parsedCode = parseModule(code)

    return _(parsedCode)
        .filter(declaration => declaration.type === DECLARATION.EXPORTED && declaration.leadingComments)
        .map(exportedDeclaration => convertToAppStudioFunctionsDefinition(exportedDeclaration))
        .compact()
        .keyBy('name')
        .value()
}

function isValidSyntax(code) {
    try {
        simpleParse(code)        
        return true
    } catch (e) {
        return false
    }
}

module.exports = {
    parse,
    isValidSyntax
}
