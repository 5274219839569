import { BasePublicApi } from '@/apilib';

import type { EditorRestrictionsScope } from './editorRestrictionsEntryPoint';

export type ValidateFunction = () => boolean;
export type UxResourceName = string;
export type UIResourceBehaviorType = 'visible' | 'interactive';
export type UIResourceRestriction =
  `${UxResourceName}.${UIResourceBehaviorType}`;

const store: Map<UIResourceRestriction, (boolean | ValidateFunction)[]> =
  new Map();

export const resetStore = () => store.clear();

/**
 * Checks if a ux action or component is visible or interactive, ie not restricted.
 * If restriction name not registered, returns true, because we are restricting functionality
 * that was permitted
 * @param restrictionName - string literal of the restriction name `${name}.visible`
 */
export const allowed = <TUIResourceRestriction extends UIResourceRestriction>(
  restrictionName: TUIResourceRestriction,
): boolean => {
  if (!restrictionName) {
    throw new TypeError('Parameter "restrictionName" not provided');
  }
  const validations = store.get(restrictionName) || [true];
  return validations.every((validation) => {
    return typeof validation === 'function' ? validation() : validation ?? true;
  });
};

const verifyIsNotEmpty = <TUIResourceRestriction>(
  restrictionNames: TUIResourceRestriction[] | TUIResourceRestriction,
) => {
  const isArray = Array.isArray(restrictionNames);
  const emptyRestrictions = isArray
    ? !restrictionNames.length
    : !restrictionNames;

  if (emptyRestrictions) {
    throw new Error('Parameter "restrictions" not provided');
  }
};

const verifyValidationExists = (validation: (() => boolean) | boolean) => {
  if (typeof validation !== 'function' && typeof validation !== 'boolean') {
    throw new TypeError('Parameter "validation" is not a function or boolean');
  }
};

const setRestriction = (
  restrictionName: UIResourceRestriction,
  validation: boolean | ValidateFunction,
) => {
  const existingValidation = store.get(restrictionName) || [];
  store.set(restrictionName, existingValidation.concat(validation));
};

const set = <TUIResourceRestriction extends UIResourceRestriction>(
  scope: EditorRestrictionsScope,
  restrictionNames: TUIResourceRestriction | TUIResourceRestriction[],
  validation: boolean | ValidateFunction,
) => {
  verifyIsNotEmpty(restrictionNames);
  verifyValidationExists(validation);

  const isArray = Array.isArray(restrictionNames);
  if (isArray) {
    restrictionNames.forEach((restrictionName) => {
      setRestriction(restrictionName, validation);
    });
  } else {
    setRestriction(restrictionNames, validation);
  }
  scope.restrictionsVersionStore.restrictionsVersionChanged();
};

export class EditorRestrictionsApi extends BasePublicApi<EditorRestrictionsScope> {
  allowed = allowed;
  set = this.bindScope(set);
  getRestrictionsVersion =
    this.scope.restrictionsVersionStore.getRestrictionsVersion;
}
