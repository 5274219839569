import { createSelector } from 'reselect';

import type { EditorState } from '../store/editorState';

const selectAttachCandidateState = (state: EditorState) =>
  state.attachCandidate;

export const selectAttachCandidateComponent = createSelector(
  selectAttachCandidateState,
  (attachCandidate) => attachCandidate.comp,
);
export const selectAttachCandidateUseAnimation = createSelector(
  selectAttachCandidateState,
  (attachCandidate) => attachCandidate.useAnimation,
);
export const isDraggingToSameParentComponent = createSelector(
  selectAttachCandidateState,
  (attachCandidate) => !attachCandidate.comp,
);

export const attachCandidateSelectors = {
  selectAttachCandidateComponent,
  selectAttachCandidateUseAnimation,
  isDraggingToSameParentComponent,
};
