import type { Rect } from 'types/documentServices';

export type LayoutY = Pick<Rect, 'y' | 'height'>;
interface GetLayoutOptions<T> {
  getLayout?: (b: T) => LayoutY;
}

const isNumber = (value: unknown): value is number => typeof value === 'number';

const topY = (layout: LayoutY) => layout.y;
const bottomY = (layout: LayoutY) => layout.y + layout.height;
const middleY = (layout: LayoutY) => layout.y + layout.height / 2;

const numberOrBottomY = (y: LayoutY | number) => (isNumber(y) ? y : bottomY(y));
const numberOrTopY = (y: LayoutY | number) => (isNumber(y) ? y : topY(y));
const numberOrMiddleY = (y: LayoutY | number) => (isNumber(y) ? y : middleY(y));

const isAAboveB = (a: LayoutY, b: LayoutY | number) =>
  bottomY(a) <= numberOrTopY(b);
const isABelowB = (a: LayoutY, b: LayoutY | number) =>
  topY(a) >= numberOrBottomY(b);
const isAWithinB = (a: LayoutY, b: LayoutY) =>
  topY(a) > topY(b) && bottomY(a) < bottomY(b);

interface CalcDistanceOptions {
  byMiddleY?: boolean;
}

const distanceTo_ = (
  a: LayoutY,
  b: LayoutY | number,
  options?: CalcDistanceOptions,
) => {
  if (options?.byMiddleY) {
    return middleY(a) - numberOrMiddleY(b);
  }

  if (isAAboveB(a, b)) {
    return bottomY(a) - numberOrTopY(b);
  }

  if (isABelowB(a, b)) {
    return topY(a) - numberOrBottomY(b);
  }

  return middleY(a) - numberOrMiddleY(b);
};

const distanceTo = (
  a: LayoutY,
  b: LayoutY | number,
  options?: CalcDistanceOptions,
) => Math.abs(distanceTo_(a, b, options));

const getArrayLayoutY = (arr: LayoutY[]): LayoutY => {
  const y = Math.min(...arr.map((item) => topY(item)));
  const height = Math.max(...arr.map((item) => bottomY(item))) - y;

  return {
    y,
    height,
  };
};

function getAboveBelowOverlap<TLayoutY>(
  a: LayoutY,
  bArray: TLayoutY[],
  options?: GetLayoutOptions<TLayoutY>,
) {
  const above: TLayoutY[] = [];
  const below: TLayoutY[] = [];
  const overlap: TLayoutY[] = [];

  bArray.forEach((b) => {
    const bLayout =
      typeof options?.getLayout === 'function'
        ? options.getLayout(b)
        : (b as unknown as LayoutY);

    if (!bLayout) {
      return;
    }

    if ($layoutY(bLayout).isAbove(a)) {
      above.push(b);
      return;
    }

    if ($layoutY(bLayout).isBelow(a)) {
      below.push(b);
      return;
    }

    overlap.push(b);
  });

  return {
    above,
    overlap,
    below,
  };
}

export function getClosest<TLayoutY>(
  a: LayoutY,
  bArray: TLayoutY[],
  {
    getLayout,
    ...distanceOptions
  }: GetLayoutOptions<TLayoutY> & CalcDistanceOptions,
): TLayoutY | null {
  const { above, below, overlap } = getAboveBelowOverlap(a, bArray, {
    getLayout,
  });

  const distances = (overlap.length ? overlap : above.concat(below))
    .map((b) => {
      const bLayout =
        typeof getLayout === 'function'
          ? getLayout(b)
          : (b as unknown as LayoutY);
      return {
        item: b,
        distance: bLayout && $layoutY(a).distanceTo(bLayout, distanceOptions),
      };
    })
    .filter((item) => typeof item.distance === 'number')
    .sort((item1, item2) => item1.distance - item2.distance);

  return distances.length > 0 ? distances[0].item : null;
}

export function $layoutY(aOrArrayOfA: LayoutY | LayoutY[]) {
  const a = Array.isArray(aOrArrayOfA)
    ? getArrayLayoutY(aOrArrayOfA)
    : aOrArrayOfA;

  return {
    get topY() {
      return topY(a);
    },
    get bottomY() {
      return bottomY(a);
    },
    get middleY() {
      return middleY(a);
    },
    isAbove: (b: LayoutY | number) => isAAboveB(a, b),
    isBelow: (b: LayoutY | number) => isABelowB(a, b),
    isWithin: (b: LayoutY) => isAWithinB(a, b),
    distanceTo: (b: LayoutY | number, options?: CalcDistanceOptions) =>
      distanceTo(a, b, options),
    getAboveBelowOverlap<TLayoutY>(
      bArray: TLayoutY[],
      options?: GetLayoutOptions<TLayoutY>,
    ) {
      return getAboveBelowOverlap<TLayoutY>(a, bArray, options);
    },
    getClosest<TLayoutY>(
      bArray: TLayoutY[],
      options?: GetLayoutOptions<TLayoutY> & CalcDistanceOptions,
    ) {
      return getClosest<TLayoutY>(a, bArray, options);
    },
  };
}

type LayoutYLayoutY = LayoutY;
type LayoutYGetLayoutOptions<TWithLayout> = GetLayoutOptions<TWithLayout>;
type LayoutYCalcDistanceOptions = CalcDistanceOptions;

export namespace LayoutY {
  export type LayoutY = LayoutYLayoutY;
  export type GetLayoutOptions<TWithLayout> =
    LayoutYGetLayoutOptions<TWithLayout>;
  export type CalcDistanceOptions = LayoutYCalcDistanceOptions;
}
