// @ts-nocheck
import * as actionTypes from './preloadingsActionsTypes';

const setComponentPreloading = (component) => ({
  type: actionTypes.SET_PRELOADING_COMPONENT,
  component,
});

const clearPreloadingComponent = (component) => ({
  type: actionTypes.CLEAR_PRELOADING_COMPONENT,
  component,
});

export { setComponentPreloading, clearPreloadingComponent };
