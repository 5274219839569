import * as actionTypes from './siteDisplayNameActionTypes';

const { SET_SITE_DISPLAY_NAME } = actionTypes;

export const siteDisplayNameInitialState: string | null = null;

const reducer = (
  siteDisplayName = siteDisplayNameInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case SET_SITE_DISPLAY_NAME:
      return action.siteDisplayName;

    default:
      return siteDisplayName;
  }
};

export default reducer;
