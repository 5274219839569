import {
  DOCKING_DIRECTIONS,
  DOCKING_DIRECTIONS_FULL_HEIGHT,
  DOCKING_DIRECTIONS_FULL_WIDTH,
} from './constants';
import type { PinDockingDirection } from '../types';

export function isDockingDirectionAllowed(
  dockingDirection: PinDockingDirection,
  {
    isFullWidth,
    isFullHeight,
  }: {
    isFullWidth?: boolean;
    isFullHeight?: boolean;
  } = {},
) {
  if (isFullWidth) {
    return DOCKING_DIRECTIONS_FULL_WIDTH.has(dockingDirection);
  }

  if (isFullHeight) {
    return DOCKING_DIRECTIONS_FULL_HEIGHT.has(dockingDirection);
  }

  return DOCKING_DIRECTIONS.has(dockingDirection);
}

export function ensureDockingDirectionIsValid(
  dockingDirection: PinDockingDirection,
  {
    isFullWidth,
    isFullHeight,
  }: {
    isFullWidth?: boolean;
    isFullHeight?: boolean;
  } = {},
): asserts dockingDirection is PinDockingDirection {
  if (!DOCKING_DIRECTIONS.has(dockingDirection)) {
    throw new Error(`Unknown docking direction: ${dockingDirection}`);
  }

  if (
    !isDockingDirectionAllowed(dockingDirection, { isFullWidth, isFullHeight })
  ) {
    throw new Error(
      `Docking direction ${dockingDirection} is not allowed for:\n${JSON.stringify(
        {
          isFullWidth,
          isFullHeight,
        },
      )}`,
    );
  }
}
