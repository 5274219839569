import _ from 'lodash';
import { applicationStudioInitialState } from '../applicationStudioReducer';
import actionTypes from '../applicationStudioActionTypes';

const devCenterSettingsReducer = (
  devCenterSettings = applicationStudioInitialState.devCenterSettings,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_WIDGETS_DATA:
      return _.defaults({ widgetsData: action.widgetsData }, devCenterSettings);
    case actionTypes.SET_SITE_HEADER_URL:
      return _.defaults(
        { siteHeaderUrl: action.siteHeaderUrl },
        devCenterSettings,
      );
    default:
      return devCenterSettings;
  }
};

export default devCenterSettingsReducer;
