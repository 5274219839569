import _ from 'lodash';
import virtualNumbersActionTypes from './virtualNumbersActionTypes';
import type { VirtualNumbersState, VirtualNumbersAction } from './types';

const { SET_VIRTUAL_NUMBERS_DATA, SET_VIRTUAL_NUMBERS_FETCH_STATUS } =
  virtualNumbersActionTypes;

export const virtualNumbersInitialState: VirtualNumbersState = {
  fetchStatus: null,
  virtualNumbersAccountId: null,
  businessNumber: null,
  status: null,
};

const reducer: (
  state: VirtualNumbersState,
  action: VirtualNumbersAction,
) => VirtualNumbersState = (state = virtualNumbersInitialState, action) => {
  switch (action.type) {
    case SET_VIRTUAL_NUMBERS_DATA:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, state, {
        virtualNumbersAccountId: action.virtualNumbersAccountId,
        businessNumber: action.businessNumber,
        status: action.status,
      });
    case SET_VIRTUAL_NUMBERS_FETCH_STATUS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, state, {
        fetchStatus: action.fetchStatus,
      });
    default:
      return state;
  }
};

export default reducer;
