import _ from 'lodash';
import { combineReducers } from 'redux';
import { mobileInitialState } from '../initialState';
import * as actionTypes from './hiddenItemsActionTypes';
import * as interactionsActionTypes from '../interactionsHiddenItems/interactionsHiddenItemsActionTypes';

const isAddingHiddenItemInProgressReducer = (
  isAddingHiddenItemInProgress = mobileInitialState.hiddenItems
    .isAddingHiddenItemInProgress,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_IS_ADDING_COMPONENT_IN_PROGRESS:
      return action.isAddingHiddenItemInProgress;
    default:
      return isAddingHiddenItemInProgress;
  }
};

const hoveredComponentReducer = (
  hoveredComponent = mobileInitialState.hiddenItems.hoveredComponent,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_HIDDEN_ITEMS_HOVERED_COMPONENT:
      return action.hoveredComponent;
    default:
      return hoveredComponent;
  }
};

const masterPageHiddenComponentsReducer = (
  masterPageHiddenComponents = mobileInitialState.hiddenItems
    .masterPageHiddenComponents,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_HIDDEN_ITEMS_MASTER_PAGE_COMPS:
      return action.hiddenComponents;
    case actionTypes.REMOVE_HIDDEN_ITEM_FROM_MASTER_PAGE_LIST:
      return _.reject(masterPageHiddenComponents, { compId: action.compId });
    default:
      return masterPageHiddenComponents;
  }
};

const currentPageHiddenComponentsReducer = (
  currentPageHiddenComponents = mobileInitialState.hiddenItems
    .currentPageHiddenComponents,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_HIDDEN_ITEMS_CURRENT_PAGE_COMPS:
      return action.hiddenComponents;
    case actionTypes.REMOVE_HIDDEN_ITEM_FROM_CURRENT_PAGE_LIST:
      return _.reject(currentPageHiddenComponents, { compId: action.compId });
    default:
      return currentPageHiddenComponents;
  }
};

const isAddingInteractionsHiddenItemInProgressReducer = (
  isAddingInteractionsHiddenItemInProgress = mobileInitialState.hiddenItems
    .isAddingInteractionsHiddenItemInProgress,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case interactionsActionTypes.SET_IS_ADDING_COMPONENT_IN_PROGRESS:
      return action.isAddingHiddenItemInProgress;
    default:
      return isAddingInteractionsHiddenItemInProgress;
  }
};

const interactionsHiddenComponentsReducer = (
  interactionsHiddenComponents = mobileInitialState.hiddenItems
    .interactionsHiddenComponents,
  action: any,
) => {
  switch (action.type) {
    case interactionsActionTypes.SET_HIDDEN_ITEMS_INTERACTIONS_COMPS:
      return action.hiddenComponents;
    case interactionsActionTypes.REMOVE_HIDDEN_ITEM_FROM_INTERACTIONS_LIST:
      return _.reject(interactionsHiddenComponents, { compId: action.compId });
    default:
      return interactionsHiddenComponents;
  }
};

export default combineReducers({
  isAddingHiddenItemInProgress: isAddingHiddenItemInProgressReducer,
  hoveredComponent: hoveredComponentReducer,
  masterPageHiddenComponents: masterPageHiddenComponentsReducer,
  currentPageHiddenComponents: currentPageHiddenComponentsReducer,
  isAddingInteractionsHiddenItemInProgress:
    isAddingInteractionsHiddenItemInProgressReducer,
  interactionsHiddenComponents: interactionsHiddenComponentsReducer,
});
