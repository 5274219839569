import _ from 'lodash';
import { mobileInitialState } from '../initialState';
import * as actionTypes from './mobileWizardActionTypes';

const mobileWizardQabReducer = (
  qab = mobileInitialState.mobileWizard.qab,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_QAB_AVAILABLE_PRESETS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, qab, { availablePresets: action.availablePresets });
    default:
      return qab;
  }
};

export default mobileWizardQabReducer;
