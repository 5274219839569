import type { EditorState } from '@/stateManagement';

const getShouldShowWizard = (state: EditorState) =>
  state.siteCreation.shouldShowWizard;

const getIsSessionInitializedWithWizard = (state: EditorState) =>
  state.siteCreation.sessionInitializedWithWizard;

const isSiteCreationUiVisible = (state: EditorState) =>
  getShouldShowWizard(state);

const isSiteCreationWizardVisible = (state: EditorState) =>
  state.siteCreation.siteCreationUiVisible;

const isSiteCreationFullyDone = (state: EditorState) =>
  state.siteCreation.isFullyDone;

export {
  getShouldShowWizard,
  isSiteCreationUiVisible,
  isSiteCreationFullyDone,
  getIsSessionInitializedWithWizard,
  isSiteCreationWizardVisible,
};
