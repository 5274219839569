// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import { devCenterFacade } from '@/serverFacade';
const APPS_SERVICES_BASE_URL = 'https://dev.wix.com/_api/app-service/v1/';

const fetchDataByAppDefId = devCenterFacade.getAppSnapshot;

const findWidgetComponentPagePageId = (components, widgetPageId) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(components, {
    compType: 'STUDIO_WIDGET',
    compData: {
      studioWidgetComponentData: {
        studioWidgetId: widgetPageId,
      },
    },
  });

const findPlatformComponent = (components) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(components, {
    compType: 'PLATFORM',
  });

const getWidgetName = (dsRead, widgetPageId) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(dsRead.pages.getPagesData(), { id: widgetPageId }).title;

const fetchWidgetComponent = (appDefinitionId, widgetPageId) =>
  fetchDataByAppDefId(appDefinitionId).then((appData) =>
    findWidgetComponentPagePageId(appData.components, widgetPageId),
  );

const fetchPlatformComponent = (appDefinitionId) =>
  fetchDataByAppDefId(appDefinitionId).then((appData) =>
    findPlatformComponent(appData.components),
  );

const createWidgetComponent = (dsRead, appDefinitionId, widgetPageId) => {
  const devAppDefId = util.appStudioUtils.getAppDefIdIfExists();
  fetchWidgetComponent(devAppDefId, widgetPageId).then((widgetComponent) => {
    if (widgetComponent) {
      const compName = getWidgetName(dsRead, widgetPageId);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      const data = _.assign(_.pick(widgetComponent, ['compType', 'compData']), {
        compName,
      });
      return util.http.fetch(
        `${APPS_SERVICES_BASE_URL}apps/${appDefinitionId}/components`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(data),
        },
      );
    }
  });
};

const updateWidgetComponent = (
  dsRead,
  appDefinitionId,
  compId,
  widgetPageId,
) => {
  fetchWidgetComponent(
    util.appStudioUtils.getAppDefIdIfExists(),
    widgetPageId,
  ).then((updatedWidgetComponent) => {
    if (updatedWidgetComponent) {
      const compName = getWidgetName(dsRead, widgetPageId);
      return util.http.fetch(
        `${APPS_SERVICES_BASE_URL}apps/${appDefinitionId}/components/${compId}`,
        {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify(
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/assign
            _.assign(updatedWidgetComponent, {
              compName,
            }),
          ),
        },
      );
    }
  });
};

const updateDevCenterWidget = (dsRead, appDefinitionId, widgetPageId) =>
  fetchDataByAppDefId(appDefinitionId)
    .then((appData) => {
      const widgetComponent = findWidgetComponentPagePageId(
        appData.components,
        widgetPageId,
      );
      if (widgetComponent) {
        return updateWidgetComponent(
          dsRead,
          appDefinitionId,
          widgetComponent.compId,
          widgetPageId,
        );
      }

      return createWidgetComponent(dsRead, appDefinitionId, widgetPageId);
    })
    .catch(() => {
      throw new Error('fail_fetch_app');
    });

const updatePlatformComponent = (appDefinitionId, compId, baseUrls) => {
  fetchPlatformComponent(appDefinitionId).then((platformComponent) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      platformComponent.compData.platformComponentData.baseUrls,
      baseUrls,
    );
    return util.http.fetch(
      `${APPS_SERVICES_BASE_URL}apps/${appDefinitionId}/components/${compId}`,
      {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(platformComponent),
      },
    );
  });
};

const updateBaseUrls = (dsRead, appDefinitionId, baseUrls) =>
  fetchDataByAppDefId(appDefinitionId).then((appData) => {
    const platformComponent = findPlatformComponent(appData.components);
    if (platformComponent) {
      return updatePlatformComponent(
        appDefinitionId,
        platformComponent.compId,
        baseUrls,
      );
    }
    throw new Error('no_platform');
  });

const updateDevCenterApp =
  (appDefinitionId, baseUrls) =>
  (dispatch, getState, { dsRead }) => {
    const widgets = dsRead.appStudio.widgets.getAll();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const widgetPageIds = _.map(widgets, ({ pointer }) =>
      dsRead.appStudio.widgets.getRootCompId(pointer),
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const widgetPromises = _.map(widgetPageIds, (widgetPageId) =>
      updateDevCenterWidget(dsRead, appDefinitionId, widgetPageId),
    );
    const platformPromise = updateBaseUrls(dsRead, appDefinitionId, baseUrls);

    return Promise.all([...widgetPromises, platformPromise]);
  };

export default {
  updateDevCenterApp,
  fetchDataByAppDefId: (appDefinitionId) => () =>
    fetchDataByAppDefId(appDefinitionId),
};
