// @ts-nocheck
import _ from 'lodash';
import * as topBar from '../topBar/topBar';
import * as userPreferences from '../userPreferences/userPreferences';

const TOOLTIP_TIMEOUT = 10000;
const topBarActions = topBar.actions;
const hasSeenBackToAdiTooltipKey = 'hasSeenBackToAdiTooltip';

const showBackToAdiTooltip = () => (dispatch) => {
  dispatch(topBarActions.setAdiTooltipVisibility(true));
  _.delay(() => {
    dispatch(topBarActions.setAdiTooltipVisibility(false));
    dispatch(
      userPreferences.actions.setSiteUserPreferences(
        hasSeenBackToAdiTooltipKey,
        true,
      ),
    );
  }, TOOLTIP_TIMEOUT);
};

const closeBackToAdiTooltip = () => (dispatch) => {
  dispatch(topBarActions.setAdiTooltipVisibility(false));
  dispatch(
    userPreferences.actions.setSiteUserPreferences(
      hasSeenBackToAdiTooltipKey,
      true,
    ),
  );
};

export { showBackToAdiTooltip, closeBackToAdiTooltip };
