// @ts-nocheck
import * as mobileQabActionTypes from './mobileQabActionTypes';

const { SET_QAB_UPLOADED_BUTTON, DISABLE_MOBILE_QAB } = mobileQabActionTypes;

export default (qabState = {}, action) => {
  switch (action.type) {
    case SET_QAB_UPLOADED_BUTTON:
      return Object.assign({}, qabState, {
        [action.icon.compId]: action.icon.svgData,
      });
    case DISABLE_MOBILE_QAB:
      return Object.assign({}, qabState, {
        enabled: false,
      });
    default:
      return qabState;
  }
};
