import { isMeshLayoutEnabled } from '../layoutMeshApi';

export enum DefineLayoutType {
  Default = 'Default',
  Mesh2 = 'Mesh2',
}

export function createLayoutTypeDefine() {
  function define<TFn extends (...args: any[]) => any>(fnsMap: {
    [DefineLayoutType.Default]: TFn;
    [DefineLayoutType.Mesh2]?: TFn;
  }) {
    return (...args: Parameters<TFn>): ReturnType<TFn> => {
      if (fnsMap[DefineLayoutType.Mesh2] && isMeshLayoutEnabled()) {
        return fnsMap[DefineLayoutType.Mesh2](...args);
      }

      return fnsMap[DefineLayoutType.Default](...args);
    };
  }

  define.Type = DefineLayoutType;

  return { define };
}
