import type { EditorState } from '../store/editorState';

const isTextEditorOpen = (state: EditorState) => state.text.isTextEditorOpen;
const isLinkPanelOpen = (state: EditorState) => state.text.isLinkPanelOpen;
const shouldOpenSettingsPanel = (state: EditorState) =>
  state.text.shouldOpenSettingsPanel;
const isStyleOnly = (state: EditorState) => state.text.editStyleOnly;
const getSelectedComponent = (state: EditorState) =>
  state.text.selectedComponent;
const getSelectedColor = (state: EditorState) => state.text.selectedColor;
const getWidgetDesignSelectedComponents = (state: EditorState) =>
  state.text.widgetDesign?.selectedComponents;
const getWidgetDesignPanelProps = (state: EditorState) =>
  state.text.widgetDesign?.panelProps;
const isFontFamilyDropdownOpen = (state: EditorState) =>
  state.text.isFontFamilyDropdownOpen;

export {
  isTextEditorOpen,
  isStyleOnly,
  getSelectedComponent,
  getSelectedColor,
  getWidgetDesignSelectedComponents,
  getWidgetDesignPanelProps,
  isLinkPanelOpen,
  shouldOpenSettingsPanel,
  isFontFamilyDropdownOpen,
};
