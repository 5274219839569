import type { EditorAPI } from '@/editorAPI';
import type { HistoryAddOptions } from '@/history';

/**
 * TODO: merge this with `@/history` package
 */
export function createHistoryApi({ editorAPI }: { editorAPI: EditorAPI }) {
  function add(label: string, options?: HistoryAddOptions) {
    if (!options?.dontAddToUndoRedoStack) {
      editorAPI.history.add(label);
    }
  }

  function debouncedAdd(label: string, options?: HistoryAddOptions) {
    if (!options?.dontAddToUndoRedoStack) {
      editorAPI.history.debouncedAdd(label);
    }
  }

  return {
    add,
    debouncedAdd,
  };
}

export type HistoryApi = ReturnType<typeof createHistoryApi>;
export type { HistoryAddOptions };
