import { type PanelProps, StageBehaviour } from './types';

export const STAGE_BEHAVIOUR_PRESET: Record<
  string,
  PanelProps['stageBehaviour']
> = {
  DEFAULT: { open: StageBehaviour.None, close: StageBehaviour.None },
  EXIT: { open: StageBehaviour.Stretch, close: StageBehaviour.None },
  TOGGLE: { open: StageBehaviour.Shrink, close: StageBehaviour.Stretch },
};
