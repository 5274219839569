// @ts-nocheck
import * as actionTypes from './componentMetaDataActionTypes';

const addMetaDataOverride = (compType, key, value) => ({
  type: actionTypes.ADD_METADATA_OVERRIDE,
  compType,
  key,
  value,
});

export { addMetaDataOverride };
