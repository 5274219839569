import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { ComponentsApiKey } from '@/apis';
import { init } from './init';

export class Scope extends BaseEntryScope {
  componentsApi = this.useDependency(ComponentsApiKey);
}

export const TabsEntryPoint = createEntryPoint({
  name: 'Tabs',
  Scope,
  initialize(scope) {
    init(scope);
  },
});
