// @ts-nocheck
import actionTypes from '../applicationStudioActionTypes';
import * as util from '@/util';

const getDevCenterBaseURL = () =>
  `${util.serviceTopology.editorRootUrl}_api/app-service/`;

const fetchAppLinkFromDevCenter = (appDefinitionId) =>
  util.http
    .fetchJson(`${getDevCenterBaseURL()}v2/${appDefinitionId}/shareUrl`, {
      credentials: 'include',
    })
    .then((res) => {
      return res;
    });

const createAppLinkFromDevCenter = (appDefinitionId) =>
  util.http
    .fetchJson(`${getDevCenterBaseURL()}v1/${appDefinitionId}/shareUrl`, {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({
        'content-type': 'application/json; charset=utf-8',
      }),
      body: '',
    })
    .then((res) => {
      if (!res || !res.url) {
        throw Error('Fetching apps from dev center failed');
      }
      return res;
    });

const revokeAppLinkFromDevCenter = (appDefinitionId) =>
  util.http.fetchJson(
    `${getDevCenterBaseURL()}v1/${appDefinitionId}/shareUrl/revoke`,
    {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({
        'content-type': 'application/json; charset=utf-8',
      }),
      body: '',
    },
  );

const setIsRevokeLinkFail = (isRevokeFail) => ({
  type: actionTypes.SET_IS_REVOKE_LINK_FAIL,
  isRevokeFail,
});

const setIsRevoking = (isRevoking) => ({
  type: actionTypes.SET_IS_REVOKING_APP_LINK,
  isRevoking,
});

const setIsCreateLinkFail = (isCreateFail) => ({
  type: actionTypes.SET_IS_CREATE_LINK_FAIL,
  isCreateFail,
});

const setIsCreating = (isCreating) => ({
  type: actionTypes.SET_IS_CREATING_APP_LINK,
  isCreating,
});

const setAppLink = (link) => ({
  type: actionTypes.SET_APP_LINK,
  link,
});

const fetchAppLink = (appDefinitionId) => (dispatch) =>
  fetchAppLinkFromDevCenter(appDefinitionId)
    .then((res) => {
      dispatch(setAppLink(res.url));
    })
    .catch((e) => {
      if (e.status === 404) {
        dispatch(setAppLink(''));
      }
    });

const revokeAppLink = (appDefinitionId) => (dispatch) => {
  dispatch(setIsRevokeLinkFail(false));
  dispatch(setIsRevoking(true));
  return revokeAppLinkFromDevCenter(appDefinitionId).then(
    () => {
      dispatch(setAppLink(''));
      dispatch(setIsRevoking(false));
      return true;
    },
    () => {
      dispatch(setIsRevoking(false));
      dispatch(setIsRevokeLinkFail(true));
      return false;
    },
  );
};

const createAppLink = (appDefinitionId) => (dispatch) => {
  dispatch(setIsCreateLinkFail(false));
  dispatch(setIsCreating(true));
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.BLOCKS.CREATE_APP_LINK,
  );
  return createAppLinkFromDevCenter(appDefinitionId)
    .then((res) => {
      dispatch(setAppLink(res.url));
      dispatch(setIsCreating(false));
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.BLOCKS.CREATE_APP_LINK,
      );
    })
    .catch(() => {
      dispatch(setIsCreating(false));
      dispatch(setIsCreateLinkFail(true));
    });
};

export {
  fetchAppLink,
  createAppLink,
  setAppLink,
  setIsCreating,
  setIsCreateLinkFail,
  revokeAppLink,
  setIsRevokeLinkFail,
  setIsRevoking,
};
