import { layoutUtils } from '@/layoutUtils';
import { interactions } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { LayoutGetApi } from '../layoutGetApi';
import type { LayoutMoveByInterceptor } from './types';

export function createLayoutMoveInteractionApi({
  editorAPI,
  layoutGetApi,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
}) {
  function isInInteractionMode() {
    return interactions.selectors.isInInteractionMode(
      editorAPI.store.getState(),
    );
  }

  // TODO: move this logic to the interactions module
  const moveByInterceptor: LayoutMoveByInterceptor = (
    { compRefs, params },
    { cancel },
  ) => {
    if (!isInInteractionMode()) {
      return;
    }

    const currentLayout = layoutGetApi.getRelativeToStructure(compRefs);
    const { deltaX, deltaY } = params;

    const updates = {
      x:
        typeof deltaX === 'number' ? currentLayout.x + deltaX : currentLayout.x,
      y:
        typeof deltaY === 'number' ? currentLayout.y + deltaY : currentLayout.y,
    };

    if (
      !layoutUtils.canMoveByInteractionsLayoutConstraints(editorAPI, compRefs, {
        ...currentLayout,
        ...updates,
      })
    ) {
      cancel();
    }
  };

  return {
    moveByInterceptor,
  };
}
