import type { EditorAPI } from '@/editorAPI';
import type { AfterPlugin } from 'types/editorPlugins';
import { updateComponentSource } from '../../componentSourceFeatureUtils';
import experiments from 'experiment';

const wRichTextAfterPlugin: AfterPlugin = (
  editorAPI: EditorAPI,
  { compRef, contentSource },
): void => {
  if (!contentSource || !experiments.isOpen('se_siteCompletionSourceData'))
    return;

  updateComponentSource(editorAPI, { compRef, source: contentSource });
};

export default wRichTextAfterPlugin;
