import { getNotificationCount } from '@wix/discovery-client';
import constants from '@/constants';
import * as util from '@/util';

import * as actionTypes from './newReleasesActionTypes';

const setNotification = (hasNotification: AnyFixMe) => ({
  type: actionTypes.SET_NOTIFICATION,
  hasNotification,
});

const getNotification =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { dsRead }: AnyFixMe) => {
    const metaSiteInstance = dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    ).instance;
    const metaSiteId =
      dsRead?.generalInfo.getMetaSiteId() || util?.editorModel?.metaSiteId;
    const language = dsRead?.generalInfo.getLanguage();

    return getNotificationCount({
      metaSiteInstance,
      metaSiteId,
      language,
      origin: 'editor',
      subjectNames: constants.NEW_RELEASES_SUBJECT_NAMES,
    }).then((count: AnyFixMe) => {
      dispatch(setNotification(Boolean(count)));
    });
  };

export { getNotification, setNotification };
