// @ts-nocheck
import userProfileActionTypes from './userProfileActionTypes';

const { SET_USER_PROFILE } = userProfileActionTypes;

const setUserProfile = (userProfile) => ({
  type: SET_USER_PROFILE,
  userProfile,
});

export { setUserProfile };
