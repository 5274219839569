import { DragInProgressPath } from '@/apilib';
import _ from 'lodash';
import * as actionTypes from './mouseActionsActionTypes';

export interface MouseActionsState {
  usingWindowMouseEvents: boolean;
  performingMouseMoveAction: boolean;
  isMouseOverStage: boolean;

  [DragInProgressPath.property]?: boolean;
}

export const mouseActionsInitialState: MouseActionsState = {
  isMouseOverStage: false,
  performingMouseMoveAction: false,
  usingWindowMouseEvents: false,
};

const reducer = (state = mouseActionsInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case actionTypes.SET_USING_WINDOW_MOUSE_EVENTS:
      return _.defaults(
        {
          usingWindowMouseEvents: action.usingWindowMouseEvents,
        },
        state,
      );
    case actionTypes.SET_PERFORMING_MOUSE_ACTION:
      return _.defaults(
        {
          performingMouseMoveAction: action.performingMouseMoveAction,
        },
        state,
      );
    case actionTypes.SET_DRAG_IN_PROGRESS:
      return _.defaults(
        {
          [DragInProgressPath.property]: action.dragInProgress,
        },
        state,
      );
    case actionTypes.SET_IS_MOUSE_OVER_STAGE:
      return { ...state, isMouseOverStage: action.isMouseOverStage };
    default:
      return state;
  }
};

export default reducer;
