import type { BranchesState } from './types';
import { SET_BRANCHES } from './branchesActionTypes';
import type { Reducer } from 'types/redux';

export const branchesInitialState: BranchesState = {
  currentBranchName: undefined,
  branchesList: undefined,
  wasBranchesListFetched: false,
  hasBranches: undefined,
};

const reducer: Reducer<BranchesState> = (
  branches = branchesInitialState,
  action,
) => {
  switch (action.type) {
    case SET_BRANCHES:
      return Object.assign({}, branches, {
        branchesList: action.branchesList,
        wasBranchesListFetched: true,
        currentBranchName: action.currentBranchName,
        hasBranches: action.hasBranches,
      });
    default:
      return branches;
  }
};

export default reducer;
