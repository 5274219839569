import type { IntroFunnelIntents } from './introFunnelTypes';

const ADD_INTRO_FUNNEL_INTENT = 'addIntroFunnelIntent';

export interface AddIntroFunnelIntentAction {
  type: typeof ADD_INTRO_FUNNEL_INTENT;
  appIntent: IntroFunnelIntents;
}

export default {
  ADD_INTRO_FUNNEL_INTENT,
};

export type IntroFunnelActionTypes = AddIntroFunnelIntentAction;
