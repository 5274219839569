import type { ProgressStatus } from '@/constants';
import type { EditorState } from '../store/editorState';
import type { PreviewState } from './previewReducer';

const getPreviewMode = (state: EditorState) => state.previewMode;
const isPreviewReady = (state: EditorState) => state.previewReady;
const getPageIdBeforeEnteringPreview = (state: AnyFixMe) =>
  state.pageIdBeforeEnteringPreview;

const getPreviewState = (state: EditorState): PreviewState => state.preview;
const getUserToggledPreviewInCurrentSession = (state: EditorState): boolean =>
  state.preview.userToggledPreviewInCurrentSession;
const getPreviewPointerEventsOn = (state: EditorState): boolean =>
  getPreviewState(state).pointerEventsOn;
const getPreviewWidth = (state: EditorState) =>
  getPreviewState(state).previewWidth;
const getIsAnimatedPreviewResize = (state: EditorState) =>
  getPreviewState(state).isAnimatedPreviewResize;
const getPreviewType = (state: EditorState) =>
  getPreviewState(state).preveiwType;
const getPreviewingStatus = (state: EditorState): ProgressStatus =>
  state.previewingStatus;

export {
  isPreviewReady,
  getPreviewMode,
  getPageIdBeforeEnteringPreview,
  getPreviewPointerEventsOn,
  getUserToggledPreviewInCurrentSession,
  getPreviewingStatus,
  getPreviewWidth,
  getPreviewType,
  getIsAnimatedPreviewResize,
};
