/*eslint max-lines: [2, { "max": 1159, "skipComments": true, "skipBlankLines": true}]*/
import _ from 'lodash';
import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';
import {
  components,
  domMeasurements,
  selection,
  rulers,
  attachCandidate,
  type StageRect,
} from '@/stateManagement';
import {
  spotlightStageUtils,
  candidatesUtil,
  recommendedMobileHeaderHeightUtils,
  snapDataUtils,
  snapToUtils,
  type SnapCandidate,
  type RectangleDrawData,
  type AlignmentLine,
  type SnapData,
} from '@/util';
import { utils } from '@/core';
import type { CompLayout, CompRef, Point, Rect } from 'types/documentServices';

export interface ClosestLayoutDistance {
  x: number;
  y: number;
  compByX: CompRef;
  compByY: CompRef;
}

export interface SnapDirections {
  top: boolean;
  right: boolean;
  bottom: boolean;
  left: boolean;
  centerX: boolean;
  centerY: boolean;
}

type FilterPredicate = (layout: CompLayout | Point) => boolean;

interface HorizontalGridLineLayout {
  x: number;
  y: number;
  width: number;
}
interface VerticalGridLineLayout {
  x: number;
  y: number;
  height: number;
}

const FORBIDDEN_SNAP_TO_COMP_IDS = ['SITE_PAGES', 'PAGES_CONTAINER'];
let equalDistanceVerticalLines: AlignmentLine[],
  equalDistanceHorizontalLines: AlignmentLine[];

const compTypesToExcludeFromSimilarSizeSnapping = _.mapKeys([
  'platform.components.AppController',
  'mobile.core.components.Page',
  'core.components.Page',
  'wysiwyg.viewer.components.FooterContainer',
  'wysiwyg.viewer.components.HeaderContainer',
  'wysiwyg.viewer.components.WSiteStructure', // masterPage
]);

const TEXT_TYPE = 'wysiwyg.viewer.components.WRichText';

const shouldIgnoreCompLayoutForSnapSimilarSizes = (compType: string) =>
  !!compTypesToExcludeFromSimilarSizeSnapping[compType];

function isLayoutDynamic(
  editorAPI: EditorAPI,
  compRef: CompRef,
  page: boolean,
  footer: CompRef,
) {
  return (
    page ||
    _.isEqual(footer, compRef) ||
    editorAPI.components.isDescendantOfComp(compRef, footer)
  );
}

function isHeaderFooterOrPage(
  compRef: CompRef,
  page: boolean,
  header: CompRef,
  footer: CompRef,
) {
  return page || _.isEqual(header, compRef) || _.isEqual(footer, compRef);
}

function getSnapCandidateLayout(
  editorAPI: EditorAPI,
  compRef: CompRef,
  page?: boolean,
  header?: CompRef,
  footer?: CompRef,
  fixedAncestor?: CompRef,
) {
  const shouldIgnoreScreenWidthPlugins = isHeaderFooterOrPage(
    compRef,
    page,
    header,
    footer,
  );
  let boundingRelativeToScreen =
    editorAPI.components.layout.getRelativeToScreen(
      compRef,
      shouldIgnoreScreenWidthPlugins,
    ).bounding;
  if (fixedAncestor) {
    const scroll = editorAPI.scroll.get();
    boundingRelativeToScreen = {
      y: boundingRelativeToScreen.y + scroll.scrollTop,
      x: boundingRelativeToScreen.x + scroll.scrollLeft,
      width: 0,
      height: 0,
    };
  }
  return boundingRelativeToScreen;
}

function isPage(editorAPI: EditorAPI, comp: CompRef) {
  return comp && editorAPI.components.is.page(comp);
}

function createSnapCandidates(
  editorAPI: EditorAPI,
  validCompsToSnap: CompRef[],
  filterPredicate: FilterPredicate,
  fixedAncestor: CompRef,
): SnapCandidate[] {
  const header = editorAPI.dsRead.siteSegments.getHeader();
  const footer = editorAPI.dsRead.siteSegments.getFooter();

  return validCompsToSnap
    .map((comp) => {
      const page = isPage(editorAPI, comp);
      const compSnapCandidate: SnapCandidate = {
        layout: getSnapCandidateLayout(
          editorAPI,
          comp,
          page,
          header,
          footer,
          fixedAncestor,
        ),
        isDynamicLayout: isLayoutDynamic(editorAPI, comp, page, footer),
        comp,
      };
      return compSnapCandidate;
    })
    .concat(createFocusedContainerSnapCandidates(editorAPI))
    .concat(candidatesUtil.createContainerWidthSnapCandidates(editorAPI))
    .concat(candidatesUtil.createStageCenterSnapCandidates(editorAPI))
    .concat(
      candidatesUtil.createMarginIndicatorsSnapCandidates(
        editorAPI,
        validCompsToSnap,
      ),
    )
    .filter((candidate) => filterPredicate(candidate.layout))
    .concat(createRulersSnapCandidates(editorAPI));
}

function createFocusedContainerSnapCandidates(
  editorAPI: EditorAPI,
): SnapCandidate[] {
  const editorState = editorAPI.store.getState();
  const focusedContainer = selection.selectors.getFocusedContainer(editorState);

  if (
    !focusedContainer ||
    !editorAPI.components.is.showMarginsIndicator(focusedContainer)
  ) {
    return [];
  }

  const gridLines = utils.gridLinesUtil.getContainerGridLines(
    editorAPI,
    focusedContainer,
  );

  return gridLines.map(function (line) {
    return {
      layout: {
        x: line.x1 as number,
        y: line.y1 as number,
        height: (line.y2 as number) - (line.y1 as number),
        width: 0,
      },
      comp: focusedContainer,
      isDynamicLayout: false,
    };
  });
}

function createRulersSnapCandidates(editorAPI: EditorAPI): SnapCandidate[] {
  if (!rulers.selectors.isRulersEnabled(editorAPI.store.getState())) {
    return [];
  }
  let rulersLayouts = getHorizontalRulersLayouts(editorAPI);
  rulersLayouts = rulersLayouts.concat(getVerticalRulersLayouts(editorAPI));
  return rulersLayouts.map((rulerLayout) => {
    return {
      layout: rulerLayout,
      isDynamicLayout: false,
    };
  });
}

function canSnapToCompConsideringSpotlightStage(
  editorAPI: EditorAPI,
  comp: CompRef,
) {
  const focusedContainer = selection.selectors.getFocusedContainer(
    editorAPI.store.getState(),
  );
  return spotlightStageUtils.canSelectCompConsideringSpotlightStage(
    editorAPI,
    focusedContainer,
    comp,
  );
}

function isValidFixedPositionCompToSnapTo(
  editorAPI: EditorAPI,
  editedComp: CompRef[],
  compInPage: CompRef,
) {
  return (
    !editorAPI.components.layout.isRenderedInFixedPosition(compInPage) ||
    editorAPI.components.isDescendantOfComp(editedComp, compInPage)
  );
}

function isValidCompToSnap(
  editorAPI: EditorAPI,
  editedComp: CompRef[],
  compInPage: CompRef,
) {
  return (
    !editorAPI.selection.isComponentSelected(compInPage) &&
    editorAPI.components.is.canSnapTo(compInPage) &&
    !FORBIDDEN_SNAP_TO_COMP_IDS.includes(compInPage.id) &&
    editorAPI.components.isRenderedOnSite(compInPage) &&
    isValidFixedPositionCompToSnapTo(editorAPI, editedComp, compInPage) &&
    !editedComp?.some((possibleAncestorRef) =>
      editorAPI.components.isDescendantOfComp(compInPage, possibleAncestorRef),
    ) &&
    !editorAPI.dsRead.pages.isWidgetPage(compInPage.id) &&
    canSnapToCompConsideringSpotlightStage(editorAPI, compInPage)
  );
}

function getVisibleCandidates(
  editorAPI: EditorAPI,
  snapCandidates: SnapCandidate[],
  stageRect: StageRect,
) {
  const state = editorAPI.store.getState();
  const visibleInCurrentModeSnapCandidates = snapCandidates.filter(
    (snapCandidate) =>
      !snapCandidate.comp ||
      components.selectors.getIsComponentVisibleInCurrentMode(
        editorAPI.dsRead,
        snapCandidate.comp,
        state,
      ),
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _.filter(
    visibleInCurrentModeSnapCandidates,
    _.partial(candidatesUtil.isLayoutVisible, stageRect),
  );
}

function getDistanceFromGridLinesOnXAxis(
  editorAPI: EditorAPI,
  layout: CompLayout,
  snapDirections: SnapDirections,
) {
  const pagesContainerLayout =
    editorAPI.siteSegments.getPagesContainerAbsLayout();

  const distancesFromGridLines = [];

  if (snapDirections.left) {
    distancesFromGridLines.push(pagesContainerLayout.x - layout.x);
    distancesFromGridLines.push(
      pagesContainerLayout.x + pagesContainerLayout.width - layout.x,
    );
  }

  if (snapDirections.right) {
    distancesFromGridLines.push(
      pagesContainerLayout.x - (layout.x + layout.width),
    );
    distancesFromGridLines.push(
      pagesContainerLayout.x +
        pagesContainerLayout.width -
        (layout.x + layout.width),
    );
  }

  return distancesFromGridLines;
}

function calcMinDistanceOnXAxis(
  editorAPI: EditorAPI,
  layout: CompLayout,
  candidates: SnapCandidate[],
  snapDirections: SnapDirections,
) {
  const header = editorAPI.dsRead.siteSegments.getHeader();
  const footer = editorAPI.dsRead.siteSegments.getFooter();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const distancesFromCandidates = _.reduce(
    candidates,
    function (accum, candidate) {
      const distances = [];
      const candidateLayout = candidate.layout;
      const { comp } = candidate;
      const page = isPage(editorAPI, comp);
      if (!isHeaderFooterOrPage(comp, page, header, footer)) {
        if (snapDirections.left) {
          distances.push(candidateLayout.x - layout.x);
          distances.push(candidateLayout.x + candidateLayout.width - layout.x);
        }

        if (snapDirections.right) {
          distances.push(candidateLayout.x - (layout.x + layout.width));
          distances.push(
            candidateLayout.x +
              candidateLayout.width -
              (layout.x + layout.width),
          );
        }
      }

      if (snapDirections.centerX) {
        distances.push(
          (candidateLayout.x + (candidateLayout.x + candidateLayout.width)) /
            2 -
            (layout.x + (layout.x + layout.width)) / 2,
        );
      }

      return [...accum, ...distances.map((distance) => ({ distance, comp }))];
    },
    [],
  );

  const distancesFromGridLines =
    shouldIgnoreTouchingGridLinesConsideringSpotlightStage(editorAPI)
      ? []
      : getDistanceFromGridLinesOnXAxis(editorAPI, layout, snapDirections);

  return _.minBy(
    distancesFromCandidates.concat(
      distancesFromGridLines.map((distance) => ({ distance })),
    ),
    function ({ distance }) {
      return Math.abs(distance);
    },
  );
}

function getDistanceFromGridLinesOnYAxis(
  editorAPI: EditorAPI,
  layout: CompLayout,
  snapDirections: SnapDirections,
) {
  const pagesContainerLayout =
    editorAPI.siteSegments.getPagesContainerAbsLayout();

  const distancesFromGridLines = [];

  if (snapDirections.top) {
    distancesFromGridLines.push(pagesContainerLayout.y - layout.y);
    distancesFromGridLines.push(
      pagesContainerLayout.y + pagesContainerLayout.height - layout.y,
    );
  }

  if (snapDirections.bottom) {
    distancesFromGridLines.push(
      pagesContainerLayout.y - (layout.y + layout.height),
    );
    distancesFromGridLines.push(
      pagesContainerLayout.y +
        pagesContainerLayout.height -
        (layout.y + layout.height),
    );
  }

  return distancesFromGridLines;
}

function calcMinDistanceOnYAxis(
  editorAPI: EditorAPI,
  layout: CompLayout,
  candidates: SnapCandidate[],
  snapDirections: SnapDirections,
) {
  const header = editorAPI.dsRead.siteSegments.getHeader();
  const footer = editorAPI.dsRead.siteSegments.getFooter();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const distancesFromCandidates = _.reduce(
    candidates,
    function (accum, candidate) {
      const distances = [];
      const candidateLayout = candidate.layout;
      const { comp } = candidate;
      const page = isPage(editorAPI, comp);
      if (!isHeaderFooterOrPage(comp, page, header, footer)) {
        if (snapDirections.top) {
          distances.push(candidateLayout.y - layout.y);
          distances.push(candidateLayout.y + candidateLayout.height - layout.y);
        }

        if (snapDirections.bottom) {
          distances.push(candidateLayout.y - (layout.y + layout.height));
          distances.push(
            candidateLayout.y +
              candidateLayout.height -
              (layout.y + layout.height),
          );
        }
      }

      if (snapDirections.centerY) {
        distances.push(
          (candidateLayout.y + (candidateLayout.y + candidateLayout.height)) /
            2 -
            (layout.y + (layout.y + layout.height)) / 2,
        );
      }

      return [...accum, ...distances.map((distance) => ({ distance, comp }))];
    },
    [],
  );

  const distancesFromGridLines =
    shouldIgnoreTouchingGridLinesConsideringSpotlightStage(editorAPI)
      ? []
      : getDistanceFromGridLinesOnYAxis(editorAPI, layout, snapDirections);

  return _.minBy(
    distancesFromCandidates.concat(
      distancesFromGridLines.map((distance) => ({ distance })),
    ),
    function ({ distance }) {
      return Math.abs(distance);
    },
  );
}

function getTopLayout(layouts: (CompLayout | VerticalGridLineLayout)[]) {
  return _.minBy(layouts, 'y');
}

function getBottomLayout(layouts: (CompLayout | VerticalGridLineLayout)[]) {
  return _.maxBy(layouts, function (layout) {
    return layout.y + layout.height;
  });
}

function getLeftMostLayout(layouts: (CompLayout | HorizontalGridLineLayout)[]) {
  return _.minBy(layouts, 'x');
}

function getRightMostLayout(
  layouts: (CompLayout | HorizontalGridLineLayout)[],
) {
  return _.maxBy(layouts, function (layout) {
    return layout.x + layout.width;
  });
}

function getHorizontalCenterCandidates(
  centerYValue: number,
  candidatesPointersAndLayouts: SnapCandidate[],
) {
  return candidatesPointersAndLayouts.filter((candidatePointerAndLayout) => {
    const candidateLayout = candidatePointerAndLayout.layout;
    return (
      centerYValue ===
      (candidateLayout.y + (candidateLayout.y + candidateLayout.height)) / 2
    );
  });
}

function getVerticalCenterCandidates(
  centerXValue: number,
  candidatesPointersAndLayouts: SnapCandidate[],
) {
  return candidatesPointersAndLayouts.filter((candidatePointerAndLayout) => {
    const candidateLayout = candidatePointerAndLayout.layout;
    return (
      centerXValue ===
      (candidateLayout.x + (candidateLayout.x + candidateLayout.width)) / 2
    );
  });
}

function getHorizontalCandidates(
  editorAPI: EditorAPI,
  yValue: number,
  candidatesPointersAndLayouts: SnapCandidate[],
) {
  const header = editorAPI.dsRead.siteSegments.getHeader();
  const footer = editorAPI.dsRead.siteSegments.getFooter();

  return candidatesPointersAndLayouts.filter((candidatePointerAndLayout) => {
    const { comp } = candidatePointerAndLayout;
    const page = isPage(editorAPI, comp);
    const candidateLayout = candidatePointerAndLayout.layout;
    return (
      !isHeaderFooterOrPage(comp, page, header, footer) &&
      (yValue === candidateLayout.y ||
        yValue === candidateLayout.y + candidateLayout.height)
    );
  });
}

function getVerticalCandidates(
  editorAPI: EditorAPI,
  xValue: number,
  candidatesPointersAndLayouts: SnapCandidate[],
) {
  const header = editorAPI.dsRead.siteSegments.getHeader();
  const footer = editorAPI.dsRead.siteSegments.getFooter();

  return candidatesPointersAndLayouts.filter((candidatePointerAndLayout) => {
    const { comp } = candidatePointerAndLayout;
    const page = isPage(editorAPI, comp);
    const candidateLayout = candidatePointerAndLayout.layout;
    return (
      !isHeaderFooterOrPage(comp, page, header, footer) &&
      (xValue === candidateLayout.x ||
        xValue === candidateLayout.x + candidateLayout.width)
    );
  });
}

function getHorizontalAlignmentLine(
  yValue: number,
  lineRectanglesDrawData: RectangleDrawData[],
  horizontalGridLinesLayouts: HorizontalGridLineLayout[],
  allLayouts: CompLayout[],
): AlignmentLine[] {
  const horizontalLayouts = [...allLayouts, ...horizontalGridLinesLayouts];
  const leftMost = getLeftMostLayout(horizontalLayouts);
  const rightMost = getRightMostLayout(horizontalLayouts);

  return [
    {
      type: 'alignmentLine',
      line: [
        { x: leftMost.x, y: yValue },
        { x: rightMost.x + rightMost.width, y: yValue },
      ],
      rectanglesDrawData: lineRectanglesDrawData,
    },
  ];
}

function getVerticalAlignmentLine(
  xValue: number,
  lineRectanglesDrawData: RectangleDrawData[],
  verticalGridLinesLayouts: VerticalGridLineLayout[],
  allLayouts: CompLayout[],
): AlignmentLine[] {
  const verticalLayouts = [...allLayouts, ...verticalGridLinesLayouts];
  const topLayout = getTopLayout(verticalLayouts);
  const bottomLayout = getBottomLayout(verticalLayouts);

  return [
    {
      type: 'alignmentLine',
      line: [
        { x: xValue, y: topLayout.y },
        { x: xValue, y: bottomLayout.y + bottomLayout.height },
      ],
      rectanglesDrawData: lineRectanglesDrawData,
    },
  ];
}

function getValidComponentsToSnap(
  editorAPI: EditorAPI,
  editedComp: CompRef[],
  fixedContainer: CompRef,
) {
  if (fixedContainer) {
    return (
      // eslint-disable-next-line you-dont-need-lodash-underscore/concat
      _([fixedContainer])
        .concat(
          editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
            fixedContainer,
            true,
          ),
        )
        .reject(_.head(editedComp))
        .filter(isValidCompToSnap.bind(null, editorAPI, editedComp))
        .value()
    );
  }

  const focusedPageId = editorAPI.dsRead.pages.getFocusedPageId();

  const isEditedRepeatedComponents = editedComp?.every((compRef) =>
    editorAPI.components.is.repeatedComponent(compRef),
  );

  if (isEditedRepeatedComponents) {
    const ancestorRepeaterItem = editorAPI.components.getAncestorRepeaterItem(
      _.head(editedComp),
    );

    return editorAPI.components
      .getChildren(ancestorRepeaterItem, true)
      .concat(ancestorRepeaterItem)
      .filter(isValidCompToSnap.bind(null, editorAPI, editedComp));
  }

  const rootComponents = editorAPI.components.getRootComponents(focusedPageId);

  // get all components recursively until repeater
  return editorAPI.components
    .getChildrenRecursivelyWithResolvers(
      rootComponents,
      (compRef) =>
        editorAPI.components.getType(compRef) !== constants.COMP_TYPES.REPEATER,
    )
    .filter(isValidCompToSnap.bind(null, editorAPI, editedComp));
}

function updateLayoutForDynamicLayoutCandidates(
  editorAPI: EditorAPI,
  snapCandidates: SnapCandidate[],
) {
  snapCandidates.forEach((candidate) => {
    candidate.layout = candidate.isDynamicLayout
      ? getSnapCandidateLayout(editorAPI, candidate.comp)
      : candidate.layout;
  });
}

function SnapToHandler(this: any, editorAPI: EditorAPI, compRefs: CompRef[]) {
  if (editorAPI) {
    this.editorAPI = editorAPI;
    this.compRefs = compRefs;
    this.fixedAncestor =
      editorAPI.components.layout.getClosestAncestorRenderedInFixedPosition(
        compRefs,
      );
  }

  if (editorAPI && snapToUtils.isNewStageGuidesEnabled() && compRefs) {
    this.containerCompRef = editorAPI.components.getContainer(compRefs);
    this.containerCompLayout =
      editorAPI.components.layout.getRelativeToScreen_rect(
        this.containerCompRef,
      );
  }
}

function getVerticalLines(
  editorAPI: EditorAPI,
  xValue: number,
  compLayout: CompLayout,
  compsPointersAndLayouts: SnapCandidate[],
  verticalGridLinesLayouts: VerticalGridLineLayout[],
) {
  const alignmentCandidates = compsPointersAndLayouts.filter(
    (snapCandidate) =>
      !candidatesUtil.isContainerWidthCandidate(snapCandidate) &&
      !candidatesUtil.isMarginIndicatorCandidate(snapCandidate),
  );
  const candidatesLayouts = alignmentCandidates.map(
    (snapCandidate) => snapCandidate.layout,
  );
  const allLayouts = [compLayout].concat(candidatesLayouts);
  const lineRectanglesDrawData = snapDataUtils.getRectanglesDrawData(
    editorAPI,
    alignmentCandidates,
  );
  let alignmentLine = getVerticalAlignmentLine(
    xValue,
    lineRectanglesDrawData,
    verticalGridLinesLayouts,
    allLayouts,
  );

  if (snapToUtils.isNewStageGuidesEnabled()) {
    const widthPercentageCandidates = compsPointersAndLayouts.filter(
      candidatesUtil.isContainerWidthCandidate,
    );
    const widthPercentageLines = widthPercentageCandidates.map(
      snapDataUtils.getWidthPercentageLine,
    );
    alignmentLine = alignmentLine.concat(widthPercentageLines);
  }

  if (equalDistanceVerticalLines?.length > 0) {
    return equalDistanceVerticalLines.concat(alignmentLine);
  }

  return alignmentLine;
}

function getHorizontalLines(
  editorAPI: EditorAPI,
  yValue: number,
  compLayout: CompLayout,
  compsPointersAndLayouts: SnapCandidate[],
  horizontalGridLinesLayouts: HorizontalGridLineLayout[],
): AlignmentLine[] {
  const alignmentCandidates = compsPointersAndLayouts.filter(
    (snapCandidate) =>
      !candidatesUtil.isContainerWidthCandidate(snapCandidate) &&
      !candidatesUtil.isMarginIndicatorCandidate(snapCandidate),
  );
  const candidatesLayouts = alignmentCandidates.map(
    (snapCandidate) => snapCandidate.layout,
  );
  const allLayouts = [compLayout].concat(candidatesLayouts);
  const lineRectanglesDrawData = snapDataUtils.getRectanglesDrawData(
    editorAPI,
    compsPointersAndLayouts,
  );
  const alignmentLine = getHorizontalAlignmentLine(
    yValue,
    lineRectanglesDrawData,
    horizontalGridLinesLayouts,
    allLayouts,
  );

  if (equalDistanceHorizontalLines?.length > 0) {
    return equalDistanceHorizontalLines.concat(alignmentLine);
  }

  return alignmentLine;
}

function getLayout(
  editorAPI: EditorAPI,
  localLayout: CompLayout,
  fixedAncestor?: CompRef,
) {
  if (fixedAncestor) {
    return Object.assign({}, localLayout, {
      y: localLayout.y + editorAPI.scroll.get().scrollTop,
      x: localLayout.x + editorAPI.scroll.get().scrollLeft,
    });
  }
  return localLayout;
}

function getVisibleSnapCandidates(
  editorAPI: EditorAPI,
  snapCandidates: SnapCandidate[],
  fixedAncestor?: CompRef,
) {
  if (fixedAncestor) {
    return snapCandidates;
  }

  const state = editorAPI.store.getState();
  const stageRect = domMeasurements.selectors.getStageRect(state);
  return getVisibleCandidates(editorAPI, snapCandidates, stageRect);
}

SnapToHandler.prototype.getSnapCandidates = function (
  filterPredicate: FilterPredicate,
) {
  if (!_.has(this, 'snapCandidates')) {
    const validCompsToSnap = getValidComponentsToSnap(
      this.editorAPI,
      this.compRefs,
      this.fixedAncestor,
    );
    const safeFilterPredicate = _.isFunction(filterPredicate)
      ? filterPredicate
      : () => true;
    this.snapCandidates = createSnapCandidates(
      this.editorAPI,
      validCompsToSnap,
      safeFilterPredicate,
      this.fixedAncestor,
    );

    if (
      this.compRefs?.[0] &&
      recommendedMobileHeaderHeightUtils.isMobileHeader(this.compRefs[0])
    ) {
      this.snapCandidates.push(
        recommendedMobileHeaderHeightUtils.getSnapCandidate(
          this.editorAPI.ui.stage.getEditingAreaLayout,
          this.compRefs[0],
        ),
      );
    }
  }
  return this.snapCandidates;
};

/**
 * Returns closest layout distance to the given layout with according to the snap directions
 * @param localLayout
 * @param snapDirections
 * @returns {{x: Number, y: Number}}
 */
SnapToHandler.prototype.getClosestLayoutDistance = function (
  editorAPI: EditorAPI,
  localLayout: CompLayout,
  snapDirections: SnapDirections,
  filterPredicate: FilterPredicate,
): ClosestLayoutDistance {
  const snapCandidates = this.getSnapCandidates(filterPredicate);
  updateLayoutForDynamicLayoutCandidates(editorAPI, snapCandidates);
  const layout = getLayout(this.editorAPI, localLayout, this.fixedAncestor);
  const visibleCandidates = getVisibleSnapCandidates(
    this.editorAPI,
    this.getSnapCandidates(),
    this.fixedAncestor,
  );
  const x = calcMinDistanceOnXAxis(
    editorAPI,
    layout,
    visibleCandidates,
    snapDirections,
  );
  const y = calcMinDistanceOnYAxis(
    editorAPI,
    layout,
    visibleCandidates,
    snapDirections,
  );
  return {
    x: x?.distance,
    y: y?.distance,
    compByX: x?.comp,
    compByY: y?.comp,
  };
};

function getHorizontalGridLinesLayouts(
  editorAPI: EditorAPI,
): HorizontalGridLineLayout[] {
  const stageRight = domMeasurements.selectors.getStageRect(
    editorAPI.store.getState(),
  ).right;
  const pageGridLines = utils.gridLinesUtil.getPageGridLines(editorAPI, false);

  return pageGridLines
    .filter((gridLine) => gridLine.x2 === '100%')
    .map((line) => {
      return {
        x: line.x1 as number,
        y: line.y1 as number,
        width: stageRight,
      };
    });
}

function getVerticalGridLinesLayouts(
  editorAPI: EditorAPI,
): VerticalGridLineLayout[] {
  const stageBottom = domMeasurements.selectors.getStageRect(
    editorAPI.store.getState(),
  ).bottom;

  const pageGridLines = utils.gridLinesUtil.getPageGridLines(editorAPI, false);

  return pageGridLines
    .filter(({ y2 }) => y2 === '100%')
    .map((line) => {
      return {
        x: line.x1 as number,
        y: line.y1 as number,
        height: stageBottom,
      };
    });
}

function getTouchingHorizontalGridLines(editorAPI: EditorAPI, yValue: number) {
  const horizontalGridLinesLayouts = getHorizontalGridLinesLayouts(editorAPI);

  return horizontalGridLinesLayouts.filter(({ y }) => y === yValue);
}

function getTouchingVerticalGridLines(editorAPI: EditorAPI, xValue: number) {
  const verticalGridLinesLayouts = getVerticalGridLinesLayouts(editorAPI);

  return verticalGridLinesLayouts.filter(({ x }) => x === xValue);
}

function getHorizontalRulersLayouts(editorAPI: EditorAPI) {
  const editorState = editorAPI.store.getState();
  const horizontalGuides = rulers.selectors.getHorizontalGuides(editorState);

  const stageRect = domMeasurements.selectors.getStageRect(editorState);

  return horizontalGuides.map((guide) => {
    return {
      id: guide.guideId,
      x: stageRect.left,
      y: guide.guidePosition,
      height: 0,
      width: stageRect.right,
    };
  });
}

function getVerticalRulersLayouts(editorAPI: EditorAPI) {
  const editorState = editorAPI.store.getState();
  const verticalGuides = rulers.selectors.getVerticalGuides(editorState);

  const stageRect = domMeasurements.selectors.getStageRect(editorState);

  return verticalGuides.map((guide) => {
    const realGuidePos = rulers.selectors.getVerticalGuideRealPosition(
      editorAPI,
      guide.guidePosition,
    );
    return {
      id: guide.guideId,
      x: realGuidePos,
      y: stageRect.top,
      height: stageRect.bottom,
      width: 0,
    };
  });
}

const GUTTER = 5; // px
const SIDE_GUTTER = 3; // px

function getLinesForBottomOfLayout({ x, y, height, width }: Rect) {
  const bottom = y + height + GUTTER;
  const right = x + width;
  const l1 = snapDataUtils.getLine({ x, y: bottom }, { x: right, y: bottom });
  const l2 = snapDataUtils.getLine(
    { x, y: bottom - SIDE_GUTTER },
    { x, y: bottom + SIDE_GUTTER },
  );
  const l3 = snapDataUtils.getLine(
    { x: right, y: bottom - SIDE_GUTTER },
    { x: right, y: bottom + SIDE_GUTTER },
  );
  return [l1, l2, l3];
}

function getLinesForLeftOfLayout({ x, y, height /*, width*/ }: Rect) {
  const left = x - GUTTER;
  const bottom = y + height;
  const l1 = snapDataUtils.getLine({ x: left, y }, { x: left, y: bottom });
  const l2 = snapDataUtils.getLine(
    { x: left - SIDE_GUTTER, y },
    { x: left + SIDE_GUTTER, y },
  );
  const l3 = snapDataUtils.getLine(
    { x: left - SIDE_GUTTER, y: bottom },
    { x: left + SIDE_GUTTER, y: bottom },
  );
  return [l1, l2, l3];
}

function shouldIgnoreTouchingGridLinesConsideringSpotlightStage(
  editorAPI: EditorAPI,
) {
  return Boolean(editorAPI.spotlightStage.getContainer());
}

/**
 * Returns an array of lines between aligned layouts
 * @param localLayout
 * @param snapDirections
 * @param ignoreTouchingGridLines
 * @param equalDistanceData
 * @param SNAP_TYPE: 'RESIZE' || 'DRAG'
 * @param isAltKeyDown
 * @returns {{
 *  lines: Array.<Array.<{x: Number, y: Number}>>,
 *  rectangles: Array.<{x: Number, y: Number, width: Number, height: Number}>
 * }} snap lines
 */
SnapToHandler.prototype.getSnapData = function (
  localLayout: CompLayout,
  snapDirections: SnapDirections,
  ignoreTouchingGridLines: boolean,
  equalDistanceData: {
    verticalEqualDistanceLines: AlignmentLine[];
    horizontalEqualDistanceLines: AlignmentLine[];
  },
  SNAP_TYPE:
    | typeof constants.MOUSE_ACTION_TYPES.RESIZE
    | typeof constants.MOUSE_ACTION_TYPES.DRAG,
  isAltKeyDown: boolean,
) {
  let snapData: SnapData[] = [];
  const layout = getLayout(this.editorAPI, localLayout, this.fixedAncestor);
  const snapCandidates = this.getSnapCandidates();
  const visibleCandidates = getVisibleSnapCandidates(
    this.editorAPI,
    snapCandidates,
    this.fixedAncestor,
  );
  const shouldIgnoreGridLines =
    ignoreTouchingGridLines ||
    shouldIgnoreTouchingGridLinesConsideringSpotlightStage(this.editorAPI);

  let touchingHorizontalGridLines: HorizontalGridLineLayout[] = [];
  let touchingVerticalGridLines: VerticalGridLineLayout[] = [];

  if (equalDistanceData) {
    equalDistanceVerticalLines = equalDistanceData.verticalEqualDistanceLines;
    equalDistanceHorizontalLines =
      equalDistanceData.horizontalEqualDistanceLines;
  }

  const isResize = SNAP_TYPE === constants.MOUSE_ACTION_TYPES.RESIZE;
  const shouldSnapToSimilarWidth =
    isResize && (snapDirections.left || snapDirections.right);
  const shouldSnapToSimilarHeight =
    isResize && (snapDirections.top || snapDirections.bottom);
  let didSnapToSimilarSize = false; // if we're snapping to similar height/width, we shouldn't draw horizontal/vertical
  const shouldSnapToSimilarSize =
    shouldSnapToSimilarHeight || shouldSnapToSimilarWidth;

  if (shouldSnapToSimilarSize && !isAltKeyDown) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(visibleCandidates, (candidate) => {
      const compType =
        candidate.comp && this.editorAPI.components.getType(candidate.comp);
      if (!compType || shouldIgnoreCompLayoutForSnapSimilarSizes(compType)) {
        return;
      }
      const isSameWidth = candidate.layout.width === layout.width;
      if (isSameWidth && shouldSnapToSimilarWidth) {
        didSnapToSimilarSize = true;
        snapData = snapData.concat(
          getLinesForBottomOfLayout(candidate.layout),
          getLinesForBottomOfLayout(layout),
        );
      }
      const isSameHeight = candidate.layout.height === layout.height;
      if (isSameHeight && shouldSnapToSimilarHeight && compType !== TEXT_TYPE) {
        didSnapToSimilarSize = true;
        snapData = snapData.concat(
          getLinesForLeftOfLayout(candidate.layout),
          getLinesForLeftOfLayout(layout),
        );
      }
    });
  }

  if (snapToUtils.isNewStageGuidesEnabled()) {
    const shoudlRenderStickyBorders =
      this.containerCompRef &&
      this.editorAPI.components.is.fullWidth(this.containerCompRef) &&
      !this.editorAPI.sections.isSectionLike(this.compRefs[0]);

    if (shoudlRenderStickyBorders) {
      snapData = snapData.concat(
        snapDataUtils.getBorderSnapData(
          this.editorAPI,
          layout,
          this.containerCompLayout,
        ),
      );
    }

    const shouldShowMarginIndicators = this.compRefs;

    if (shouldShowMarginIndicators) {
      const candidate =
        attachCandidate.selectors.selectAttachCandidateComponent(
          this.editorAPI.store.getState(),
        );
      snapData = snapData.concat(
        snapDataUtils.getMarginsSnapIndicators(
          this.editorAPI,
          this.compRefs[0],
          candidate ?? this.containerCompRef,
        ),
      );
    }
  }

  if (!didSnapToSimilarSize) {
    const horizontalCenterCandidates =
      snapDirections.centerY &&
      getHorizontalCenterCandidates(
        layout.y + layout.height / 2,
        visibleCandidates,
      );
    if (horizontalCenterCandidates && horizontalCenterCandidates.length > 0) {
      snapData = snapData.concat(
        getHorizontalLines(
          this.editorAPI,
          layout.y + layout.height / 2,
          layout,
          horizontalCenterCandidates,
          touchingHorizontalGridLines,
        ),
      );
    } else {
      let horizontalCandidates = [];
      if (snapDirections.top) {
        horizontalCandidates = getHorizontalCandidates(
          this.editorAPI,
          layout.y,
          visibleCandidates,
        );
        touchingHorizontalGridLines = shouldIgnoreGridLines
          ? []
          : getTouchingHorizontalGridLines(this.editorAPI, layout.y);
        if (
          horizontalCandidates.length > 0 ||
          touchingHorizontalGridLines.length > 0
        ) {
          snapData = snapData.concat(
            getHorizontalLines(
              this.editorAPI,
              layout.y,
              layout,
              horizontalCandidates,
              touchingHorizontalGridLines,
            ),
          );
        }
      }

      if (snapDirections.bottom) {
        const compBottom = layout.y + layout.height;
        horizontalCandidates = getHorizontalCandidates(
          this.editorAPI,
          compBottom,
          visibleCandidates,
        );
        touchingHorizontalGridLines = shouldIgnoreGridLines
          ? []
          : getTouchingHorizontalGridLines(this.editorAPI, compBottom);
        if (
          horizontalCandidates.length > 0 ||
          touchingHorizontalGridLines.length > 0
        ) {
          snapData = snapData.concat(
            getHorizontalLines(
              this.editorAPI,
              compBottom,
              layout,
              horizontalCandidates,
              touchingHorizontalGridLines,
            ),
          );
        }
      }
    }

    const verticalCenterCandidates =
      snapDirections.centerX &&
      getVerticalCenterCandidates(
        layout.x + layout.width / 2,
        visibleCandidates,
      );
    if (verticalCenterCandidates && verticalCenterCandidates.length > 0) {
      snapData = snapData.concat(
        getVerticalLines(
          this.editorAPI,
          layout.x + layout.width / 2,
          layout,
          verticalCenterCandidates,
          touchingVerticalGridLines,
        ),
      );
    } else {
      let verticalCandidates = [];
      if (snapDirections.right) {
        const compRight = layout.x + layout.width;
        verticalCandidates = getVerticalCandidates(
          this.editorAPI,
          compRight,
          visibleCandidates,
        );
        touchingVerticalGridLines = shouldIgnoreGridLines
          ? []
          : getTouchingVerticalGridLines(this.editorAPI, compRight);
        if (
          verticalCandidates.length > 0 ||
          touchingVerticalGridLines.length > 0
        ) {
          snapData = snapData.concat(
            getVerticalLines(
              this.editorAPI,
              compRight,
              layout,
              verticalCandidates,
              touchingVerticalGridLines,
            ),
          );
        }
      }

      if (snapDirections.left) {
        verticalCandidates = getVerticalCandidates(
          this.editorAPI,
          layout.x,
          visibleCandidates,
        );
        touchingVerticalGridLines = shouldIgnoreGridLines
          ? []
          : getTouchingVerticalGridLines(this.editorAPI, layout.x);
        if (
          verticalCandidates.length > 0 ||
          touchingVerticalGridLines.length > 0
        ) {
          snapData = snapData.concat(
            getVerticalLines(
              this.editorAPI,
              layout.x,
              layout,
              verticalCandidates,
              touchingVerticalGridLines,
            ),
          );
        }
      }
    }
  }

  return snapData;
};

SnapToHandler.prototype.getSimilarSizeCandidates = function (
  editorAPI: EditorAPI,
  localLayout: CompLayout,
  snapDirections: SnapDirections,
) {
  const shouldSnapToSimilarHeight = snapDirections.top || snapDirections.bottom;
  const shouldSnapToSimilarWidth = snapDirections.left || snapDirections.right;

  if (!shouldSnapToSimilarHeight && !shouldSnapToSimilarWidth) {
    return {};
  }
  const layout = getLayout(this.editorAPI, localLayout, this.fixedAncestor);
  const snapCandidates = this.getSnapCandidates();
  const visibleCandidates = getVisibleSnapCandidates(
    this.editorAPI,
    snapCandidates,
    this.fixedAncestor,
  );
  let potentialSimilarHeightCandidate;
  let potentialSimilarWidthCandidate;
  let minimumHeightDifferenceFound = Number.MAX_SAFE_INTEGER;
  let minimumWidthDifferenceFound = Number.MAX_SAFE_INTEGER;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(visibleCandidates, (candidate) => {
    const compType =
      candidate.comp && editorAPI.components.getType(candidate.comp);
    if (
      !compType ||
      shouldIgnoreCompLayoutForSnapSimilarSizes(compType) ||
      candidatesUtil.isContainerWidthCandidate(candidate) ||
      candidatesUtil.isStageCenterCandidate(candidate)
    ) {
      return;
    }

    if (shouldSnapToSimilarHeight && compType !== TEXT_TYPE) {
      const heightDifference = Math.abs(
        candidate.layout.height - layout.height,
      );
      const isHeightSimilar = heightDifference < 5;
      if (isHeightSimilar && heightDifference < minimumHeightDifferenceFound) {
        minimumHeightDifferenceFound = heightDifference;
        potentialSimilarHeightCandidate = candidate;
      }
    }

    if (shouldSnapToSimilarWidth) {
      const widthDifference = Math.abs(candidate.layout.width - layout.width);
      const isWidthSimilar = widthDifference < 5;
      if (isWidthSimilar && widthDifference < minimumWidthDifferenceFound) {
        minimumWidthDifferenceFound = widthDifference;
        potentialSimilarWidthCandidate = candidate;
      }
    }
  });

  return {
    potentialSimilarHeightCandidate,
    potentialSimilarWidthCandidate,
  };
};

export default SnapToHandler as AnyFixMe;
