import { responsiveLayoutGet } from '../responsiveLayout';
import type { CompRef, DSRead, SingleLayoutData } from 'types/documentServices';

export function layoutGetRotationInDegreesByLayouts(layouts: SingleLayoutData) {
  if (!layouts?.componentLayout) {
    return undefined;
  }

  // NOTE: `rotationInDegrees` in `layouts.componentLayout` could be undefined
  // while in the old layout it was always defined (with default value as 0).
  return layouts.componentLayout.rotationInDegrees ?? 0;
}

export function layoutGetRotationInDegrees(dsRead: DSRead, compRef: CompRef) {
  const layouts = responsiveLayoutGet(dsRead, compRef);

  return layoutGetRotationInDegreesByLayouts(layouts);
}

export function layoutIsRotated(dsRead: DSRead, compRef: CompRef) {
  const rotationInDegrees = layoutGetRotationInDegrees(dsRead, compRef);

  return typeof rotationInDegrees === 'number' && rotationInDegrees !== 0;
}
