import * as util from '@/util';
import { translate } from '@/i18n';
import {
  SET_DOMAIN_SUGGESTIONS,
  TURN_OFF_DOMAIN_SUGGESTIONS,
  TURN_ON_DOMAIN_SUGGESTIONS,
} from './domainSuggestionsActionTypes';
import { ErrorReporter } from '@wix/editor-error-reporter';

import type { EditorState } from '..';
import type { EditorAPI } from '@/editorAPI';
import type { Dispatch, DispatchMapperArgs } from 'types/redux';

export const setDomainSuggestions = (domainSuggestions: string[]) => ({
  type: SET_DOMAIN_SUGGESTIONS,
  domainSuggestions,
});

const DEFAULT_SITE_NAME_STRINGS = [
  'website-',
  'my-site',
  'my_site',
  'my site',
  translate('Save_Publish_Site_Default_Name'),
];

const NON_ALLOWED_DOMAIN_SUGGESTIONS_STRINGS = [
  'start-from-scratch',
  'startfrom',
  'startthrough',
  ...DEFAULT_SITE_NAME_STRINGS,
];

function checkDomainSuggestion(
  domainSuggestion: DomainSuggestionsObject,
): boolean {
  return NON_ALLOWED_DOMAIN_SUGGESTIONS_STRINGS.every(
    (nonAllowedSuggestionPrefix) =>
      !domainSuggestion.domain.includes(nonAllowedSuggestionPrefix),
  );
}

export function buildSuggestDomainUrl(
  metaSiteId: string,
  businessName: string,
  numOfResults: number,
): string {
  businessName = businessName.replaceAll(' ', '-');
  return `${util.serviceTopology.editorRootUrl}_api/domain-registrar/v1/domain-search/suggestDomain?business_name=${businessName}&site_id=${metaSiteId}&tlds=com&max_results=${numOfResults}`;
}

export interface DomainSuggestionsData {
  suggestions: DomainSuggestionsObject[];
}

export interface DomainSuggestionsObject {
  domain: string;
}

export const fetchDomainSuggestions =
  (businessName: string) =>
  (
    dispatch: Dispatch,
    getState: EditorState,
    { editorAPI }: DispatchMapperArgs,
  ) => {
    if (!businessName || !editorAPI.dsRead) return;
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const suggestDomainUrl = buildSuggestDomainUrl(metaSiteId, businessName, 3);
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.SUCCESSFUL_DOMAIN_SUGGESTION_FETCH,
    );
    util.http
      .fetch(suggestDomainUrl)
      .then((res) => res.json())
      .then((domainSuggestionsData: DomainSuggestionsData) => {
        const domainSuggestions = domainSuggestionsData.suggestions
          .filter(checkDomainSuggestion)
          .map((suggestion) => suggestion.domain);
        if (domainSuggestions.length) {
          dispatch(setDomainSuggestions(domainSuggestions));
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.SUCCESSFUL_DOMAIN_SUGGESTION_FETCH,
          );
        }
      })
      .catch((e) => {
        ErrorReporter.captureException(e, {
          tags: {
            domainSuggestionFailure: true,
          },
          extra: {
            businessName,
          },
        });
      });
  };

export const siteDisplayNameContainsDefaultValue = (
  siteDisplayName: string,
): boolean => {
  return DEFAULT_SITE_NAME_STRINGS.some((siteNameDefaultString) => {
    const siteNameDefaultStringRegex = new RegExp(siteNameDefaultString, 'i');
    return siteNameDefaultStringRegex.test(siteDisplayName);
  });
};

export function getSiteBusinessNameForDomainSuggestion(
  editorAPI: EditorAPI,
  name?: string,
): string {
  const siteDisplayName = name || editorAPI.siteDisplayName.get();
  if (!siteDisplayNameContainsDefaultValue(siteDisplayName)) {
    return siteDisplayName;
  }
  const siteName = editorAPI.siteName.get();
  if (!siteDisplayNameContainsDefaultValue(siteName)) {
    return siteName;
  }
  return '';
}

export const hideDomainSuggestion = () => ({
  type: TURN_OFF_DOMAIN_SUGGESTIONS,
});

export const turnOnDomainSuggestion = () => ({
  type: TURN_ON_DOMAIN_SUGGESTIONS,
});
