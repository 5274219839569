import _ from 'lodash';
import { mobileInitialState } from '../initialState';
import * as actionTypes from './mobileWizardActionTypes';

const mobileWizardHeaderReducer = (
  header = mobileInitialState.mobileWizard.header,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_HEADER_ANIMATION_PROGRESS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, header, {
        animationInProgress: action.animationInProgress,
      });
    default:
      return header;
  }
};

export default mobileWizardHeaderReducer;
