export default {
  SOUND_CLOUD: 'b2e23cce-a9b2-44a9-98ba-55d3ed3a290f',
  SPOTIFY_PLAYER: '3e208a7b-646c-4f52-8424-4609cc2c63b3',
  STRIP_COLUMNS_CONTAINER: '18871bc3-5d53-4441-8fe1-92088dc37d5f',
  STRIP_COLUMNS_CONTAINER_SINGLE: '3f33c9eb-2eac-4bb9-b40c-e02a1d7846c4',
  COLUMN: 'bccd71cb-6abc-4742-90c1-99cd4681b22d',
  COLUMN_MOBILE: '509cb690-4898-4081-bd5d-05b0f80483fc',
  SPOTIFY_FOLLOW: 'b066de41-2839-4304-b4ec-a58667c0ef53',
  BG_IMAGE_STRIP: 'ad3d387e-74f3-43aa-b503-69baa8d524bd',
  VIDEO: 'bf83039a-a661-48c3-9c79-660cc975d000',
  VIDEO_PLAYER: '47daf186-9b3d-4cbb-a9fd-b8d3e2933c91',
  MEDIA_PLAYER: 'bf83039a-a661-48c3-9c79-660cc975d000',
  GOOGLE_PLUS: 'd015a6dd-ce7f-4b91-bfd8-a0289d5151af',
  TWITTER_TWEET: 'b066de41-2839-4304-b4ec-a58667c0ef53',
  TWITTER_FOLLOW: 'b066de41-2839-4304-b4ec-a58667c0ef53',
  VK_SHARE: 'b066de41-2839-4304-b4ec-a58667c0ef53',
  PINTEREST_PIN_IT: '4d7a0eea-4e23-46f6-bbde-61ec431e8276',
  GALLERIES: '87fc9152-1704-45c1-9fb2-e99cb8871c62',
  COLLAGE_GALLERY: '8628fc4b-a6ca-4aa8-9c0f-5343ad8b7d96',
  FREESTYLE_GALLERY: '1ac10cee-9166-421f-8ef7-2314e82938a5',
  MATRIX_GALLERY: '5a9b1461-1454-4cf2-ab17-318854157cd3',
  HONEYCOMB_GALLERY: '2d7b5c16-9537-4cc0-ae36-e600cae8b171',
  PAGINATED_GRID_GALLERY: '5d504b4d-e7dd-416b-8084-7662e17719ce',
  SLIDESHOW_STRIP_GALLERY: 'a8084eb2-9e0d-497c-84b9-009ce30d2618',
  SLIDESHOW_GALLERY: '6a0019f2-1dda-4a33-833c-f6c84b82ceb8',
  SHOWCASE_STRIP_GALLERY: '412963b0-1281-4463-ae19-9ac8ab98c64f',
  IMPRESS_GALLERY: '257a7085-57dd-4a23-9c74-6eecf43dbae7',
  ACCORDION_GALLERY: 'dc7a7471-4239-428b-bf6e-815dac7214c3',
  MASONRY_GALLERY: 'baf4ce6b-a390-43a0-9e4e-ed1cb8d0dcbe',
  SLIDER_GALLERY: '33012cc6-6b5f-42ab-862e-32691a07a7ac',
  THUMBNAIL_GALLERY: 'f4588045-3859-4526-9a0b-195bc1aa9ab7',
  FACEBOOK_LIKE: '3b2cee05-6f5f-49de-b7a1-348b57450734',
  FACEBOOK_LIKE_BOX: 'e7f2768b-8a69-464c-95f0-77da7c678583',
  YOUTUBE_SUBSCRIBE: 'b066de41-2839-4304-b4ec-a58667c0ef53',
  CONTACT_FORM: '076225c6-7f20-4dab-a4e1-96e1a496241d',
  GOOGLE_MAP: 'b066de41-2839-4304-b4ec-a58667c0ef53',
  SKYPE_CALL: '5f16df24-eeb9-423e-857c-142e7f5b16ca',
  HORIZONTAL_MENU: '472c2c26-a7ef-4381-a012-1ded43909b81',
  VERTICAL_MENU: '82742b26-810a-4c14-917b-9aca06197c69',
  EXPANDABLE_MENU: 'b3e7afe7-74c5-407a-9284-1ec84e5b3b55',
  VERTICAL_ANCHORS_MENU: 'a7275840-4642-4006-ac54-2ba0954bf2a7',
  PAYPAL: 'b066de41-2839-4304-b4ec-a58667c0ef53',
  LOGIN_SOCIAL_BAR: '08a3ceb8-8493-4d00-b71b-977386f0ceb2',
  QUICK_ACTION_BAR: '498fc566-4dd9-497c-8ea9-e5bfbc3aa938',
  FLICKR: '7333b534-d540-41ca-95c2-dbea7bf1dc14',
  POPUP_CONTAINER_GRID: '87ea81b1-93ff-48bd-ac1c-29ad63d3809b',
  POPUP_CONTAINER_TOP_BOTTOM: '75e2e7ab-cf0c-4db1-9457-f2dd543059dd',
  POPUP_CONTAINER_SIDE_BAR: '3dfcbbfd-2feb-4f03-b98f-f89439fcdabf',
  BOX_SLIDE_SHOW: '29ceac32-d1ac-4033-84d9-c4597a9396b0',
  TINY_MENU: '73514848-731c-4e15-8dc7-d908f2a70957',
  INLINE_POPUP: '3e3fbc9e-c390-4de6-b0be-0c23ec046a6e',
  CLOSE_BUTTON: '4f2f44f2-e0d6-464e-851a-1d11b05e73b8',
  COMBO_BOX_INPUT: '4ad7bb17-06b4-473d-bb27-528de0af1694',
  TEXT_INPUT: 'fc002f20-e3ef-47a9-8d7d-d4f1a9ad66e1',
  DATE_PICKER: '8acdb761-f81f-44b6-aa93-841515a2fe67',
  TEXT_AREA: '93844adb-06b3-4475-be27-86414c8f8601',
  RICH_TEXT_BOX: 'd7f7f130-e262-4d6d-a326-fb6391187438',
  GRID: 'd8ec1334-1076-4269-90ab-eec48eee709d',
  RADIO_GROUP: '76de0b46-6155-483d-a098-e3869fab9ece',
  CHECKBOX: 'bda38ce4-2741-4b00-89f1-4eef98ebf980',
  FILE_UPLOADER: '88fc69c5-9995-4d17-bb16-0fce97f0c9cf',
  FILE_UPLOADER_MOBILE: 'ef52b20c-0549-49dc-9e69-a4371b7fee11',
  REPEATER: '22929f30-65d4-45c3-a973-0a7197b868a3',
  REPEATER_MOBILE: '8d6b951b-fc51-4cc9-abc9-bcea2fe50fbd',
  LANGUAGE_SELECTOR: '74a80515-93ec-40f3-97c2-7a20fc72ab55',
  LANGUAGE_SELECTOR_MANAGE: '026526e0-1a81-4940-b3ac-2231f491106b',
  LANGUAGE_SELECTOR_MOBILE: '74a80515-93ec-40f3-97c2-7a20fc72ab55',
  TAGS: '00fa8ecd-3918-4c2a-acc6-014dba0518d5',
  PAGINATION: '11276ba4-2bcc-4f22-9547-7f42a754b778',
  RATINGS_DISPLAY: '0101005e-dd13-41f4-9097-daa9a008648e',
};
