const OPEN_PIN_MODE = 'openPinMode';
const CLOSE_PIN_MODE = 'closePinMode';
const SHOW_PIN_PREVIEW_RECT = 'showPinPreviewRect';
const HIDE_COMPONENT_PREVIEW_RECT = 'hideComponentPreviewRect';

export {
  OPEN_PIN_MODE,
  CLOSE_PIN_MODE,
  SHOW_PIN_PREVIEW_RECT,
  HIDE_COMPONENT_PREVIEW_RECT,
};
