const SET_FOCUSED_PAGE = 'set-focused-page';
const EDITOR_LOADED_WITH_MORE_THAN_MAX_STATIC_PAGES =
  'EDITOR_LOADED_WITH_MORE_THAN_MAX_STATIC_PAGES';
const SET_FOCUSED_PAGE_TITLE = 'set-focused-page-title';
const SET_EDITING_MENU_ITEM = 'set-editing-menu-item';

export {
  SET_FOCUSED_PAGE,
  SET_FOCUSED_PAGE_TITLE,
  EDITOR_LOADED_WITH_MORE_THAN_MAX_STATIC_PAGES,
  SET_EDITING_MENU_ITEM,
};
