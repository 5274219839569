import type { ComponentsApi } from '@/components';
import type { CompRef, DocumentServicesObject } from 'types/documentServices';

function getCurrentTabId(tabRef: CompRef, componentsApi: ComponentsApi): any;
function getCurrentTabId(tabRef: CompRef, dsRead: DocumentServicesObject): any;
function getCurrentTabId(tabRef: CompRef, api: any) {
  return (api.components || api).behaviors.getRuntimeState(tabRef)
    ?.currentTabId;
}

function getTabs(
  tabsRef: CompRef,
  dsRead: DocumentServicesObject,
): Array<{
  label: string;
  tabId: string;
}> {
  const singleTabRefs =
    dsRead.deprecatedOldBadPerformanceApis.components.getChildren(tabsRef);
  const singleTabDataItems = singleTabRefs.map((tabRef) =>
    dsRead.components.data.get(tabRef),
  );
  const tabIdToData: Record<string, any> = {};
  for (const data of singleTabDataItems) {
    tabIdToData[data.tabId] = data;
  }
  const { tabIds } = dsRead.components.data.get(tabsRef);
  return tabIds.map((tabId: string) => tabIdToData[tabId]);
}

function getCurrentTabComponent(
  compRef: CompRef,
  componentsApi: ComponentsApi,
) {
  const currentTabId = getCurrentTabId(compRef, componentsApi);
  const currentTab = componentsApi
    .getChildren(compRef)
    .find(
      (singleTab) => componentsApi.data.get(singleTab).tabId === currentTabId,
    );

  return currentTab;
}

export { getCurrentTabId, getTabs, getCurrentTabComponent };
