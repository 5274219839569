// @ts-nocheck
import _ from 'lodash';
import experiment from 'experiment';
import constants from '@/constants';
import * as sectionedPanelSelectors from '../sectionedPanel/sectionedPanelSelectors';
import * as sectionedPanelActions from '../sectionedPanel/sectionedPanelActions';
import * as panelsActions from '../panels/panelsActions';
import * as pages from '../pages/pages';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as util from '@/util';
import { translate } from '@/i18n';

const overrideItem = ({ KEY }, stateBase, overrideData) => ({
  [KEY]: Object.assign({}, stateBase?.[KEY] ?? {}, overrideData),
});
const hideItem = ({ KEY, IS_HIDDEN }, stateBase) =>
  overrideItem({ KEY }, stateBase, { [IS_HIDDEN]: true });
const setIsSection = ({ KEY, SHOULD_OPEN_AS_SECTION }, stateBase) =>
  overrideItem({ KEY }, stateBase, { [SHOULD_OPEN_AS_SECTION]: true });
const addPanelOverrideUtil = util.overrideUtils.addPanel;

const overriddenConstants = constants.OVERRIDDEN;
const appStudioConstants = constants.APP_STUDIO;
const { CATEGORIES_ID } = addPanelDataConsts;

const isVariationPage = (editorAPI, editorState) => {
  const currentPage = pages.selectors.getFocusedPage(editorState);
  return editorAPI.dsRead.appStudio.widgets.variations.isVariationPage(
    currentPage.id,
  );
};

const getOverriddenTopBarItems = (
  state,
  dispatch,
  editorAPI,
  isFullStagePanelOpen = false,
) => {
  const { MENU_ITEMS, QUICK_NAVIGATION } = overriddenConstants.TOP_BAR;
  const overridenBaseState = state.topBar.overridden;
  const { TOOLS_MENU_ITEMS, SITE_MENU_ITEMS, CODE_MENU_ITEMS, MENU_BAR_ITEMS } =
    constants.ROOT_COMPS.TOPBAR;

  //todo move to different location
  const { TOOLS } = constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS;

  const menuItems = Object.assign({}, overridenBaseState.menuItems, {
    [TOOLS]: setIsSection(
      MENU_ITEMS.TOOLS_PROPERTIES_PANEL,
      overridenBaseState.menuItems[TOOLS],
    ),
  });

  const quickNavigation = Object.assign(
    {},
    overridenBaseState[QUICK_NAVIGATION.KEY],
    { [QUICK_NAVIGATION.IS_HIDDEN]: true },
  );

  const getRightButtonsOverrides = () => [
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.UNDO,
      disabled: isFullStagePanelOpen,
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.REDO,
      disabled: isFullStagePanelOpen,
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
      dropPanelData: {
        title: 'TOPBAR_SAVE_TOOLTIP_TITLE',
        body: 'AppStudio_TopBar_Save_Tooltip_Text',
        linkText: null,
        linkAction: null,
      },
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.PREVIEW,
      tooltipData: {
        text: 'AppStudio_TopBar_Preview_Tooltip_Text',
      },
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.PUBLISH,
      label: translate('AppStudio_Package_Button'),
      dropPanelData: {
        title: 'AppStudio_Package_Button',
        body: 'AppStudio_TopBar_Package_Tooltip_Text',
        linkText: null,
        linkAction: null,
      },
      extraClassName: 'app-studio-package',
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.BACK_TO_EDITOR,
      label: translate('AppStudio_TopBar_Preview_BacktoBuilder'),
    },
  ];

  const getLeftButtonsOverrides = () => [
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.DESKTOP_MODE,
      tooltipData: {
        text: 'AppStudio_TopBar_Desktop_Tooltip_Text',
      },
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.MOBILE_MODE,
      tooltipData: {
        text: 'AppStudio_TopBar_Mobile_Tooltip_Text',
      },
    },
  ];

  const getMenuOverrides = () => [
    {
      key: MENU_BAR_ITEMS.SITE,
      title: 'AppStudio_TopBar_App',
    },
    {
      key: MENU_BAR_ITEMS.TOOLS,
      condition: () => !isFullStagePanelOpen,
    },
    {
      key: MENU_BAR_ITEMS.CODE,
      title: 'AppStudio_TopBar_Resources',
      getOverrides: () => ({
        [CODE_MENU_ITEMS.CODE_HEADER]: translate(
          'AppStudio_TopBar_Resources_Title',
        ),
        [CODE_MENU_ITEMS.CODE_SUB_HEADER]: translate(
          'AppStudio_TopBar_Resources_Subtitle',
        ),
        [CODE_MENU_ITEMS.CODE_ON_LIST_ITEMS]: [
          {
            item_name: 'Documentation',
            url: 'https://support.wix.com/en/article/wix-blocks-beta-index-of-help-center-articles',
            text: translate('AppStudio_TopBar_Resources_Link1'),
          },
          {
            item_name: 'Code',
            url: 'https://www.wix.com/velo/all-documentation',
            text: translate('AppStudio_TopBar_Resources_Link3'),
          },
        ],
        [CODE_MENU_ITEMS.CODE_SYMBOL]: 'appStudioWixCodeMenu',
      }),
      devToolsButtonEnabled: false,
    },
    { key: MENU_BAR_ITEMS.HELP },
  ];

  const siteMenuOverrides = [
    {
      key: SITE_MENU_ITEMS.SAVE,
      leftTitle: 'AppStudio_TopBar_App_Save_Label',
      rightTitle: 'AppStudio_TopBar_App_Save_Promotion_Title',
      description: 'AppStudio_TopBar_App_Save_Promotion_Text',
    },
    {
      key: SITE_MENU_ITEMS.PREVIEW,
      leftTitle: 'AppStudio_TopBar_App_Preview_Label',
      rightTitle: 'AppStudio_TopBar_App_Preview_Promotion_Title',
      description: 'AppStudio_TopBar_App_Preview_Promotion_Text',
    },
    {
      key: SITE_MENU_ITEMS.PUBLISH,
      leftTitle: 'AppStudio_TopBar_App_Build_Label',
      rightTitle: 'AppStudio_TopBar_App_Build_Promotion_Title',
      description: 'AppStudio_TopBar_App_Build_Promotion_Text',
      symbolName: 'appStudioTopBarBuild',
    },
    { key: 'separator', isSeparator: true, __isExtraItem: true },
    {
      key: 'OpenApp',
      automationId: 'list-item-open-app',
      leftTitle: 'AppStudio_TopBar_App_OpenApp_Label',
      rightTitle: 'AppStudio_TopBar_App_OpenApp_Promotion_Title',
      description: 'AppStudio_TopBar_App_OpenApp_Promotion_Text',
      symbolName: 'appStudioOpenApp',
      __isExtraItem: true,
      onClick() {
        dispatch(
          panelsActions.updateOrOpenPanel('tpa.panels.appImportPanel', {
            openAppVariant: true,
            helpId: '3439e7d1-5fbc-4270-adc6-e4a49826eb88',
            panelTitle: translate('AppStudio_OpenApp_Title'),
            noCreatedAppsText: translate('AppStudio_OpenApp_EmptyState_Text'),
          }),
        );
      },
    },
    {
      key: 'separator',
      isSeparator: true,
      __isExtraItem: true,
    },
    {
      key: 'namespace',
      automationId: 'list-item-namespace',
      leftTitle: 'AppStudio_TopBar_App_Namespace_Label',
      rightTitle: 'AppStudio_TopBar_App_Namespace_Promotion_Title',
      description: 'AppStudio_TopBar_App_Namespace_Promotion_Text',
      symbolName: 'appStudioTopBarNamespace',
      __isExtraItem: true,
      onClick() {
        dispatch(
          panelsActions.updateOrOpenPanel(
            'appStudio.panels.appSettingsPanel',
            {
              initialTab: appStudioConstants.SETTINGS_PANEL.OPTIONS.NAMESPACE,
            },
            true,
          ),
        );
      },
      condition: () => !!experiment.isOpen('se_appBuilderCollections'),
    },
    {
      key: 'AppLink',
      automationId: 'list-item-app-link',
      leftTitle: 'AppStudio_TopBar_App_AppLink_Label',
      rightTitle: 'AppStudio_TopBar_App_AppLink_Promotion_Title',
      description: 'AppStudio_TopBar_App_AppLink_Promotion_Text',
      symbolName: 'appStudioTopBarShareLink',
      __isExtraItem: true,
      onClick() {
        dispatch(
          panelsActions.updateOrOpenPanel(
            appStudioConstants.SETTINGS_PANEL.NAME,
            {
              initialTab: appStudioConstants.SETTINGS_PANEL.OPTIONS.APP_LINK,
            },
            true,
          ),
        );
      },
    },
  ];

  const helpMenuOverrides = [
    { key: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_CENTER },
    { key: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_KB_SHORTCUTS },
    { key: 'separator', isSeparator: true },
    { key: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_TERMS_OF_USE },
    { key: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_PRIVACY_POLICY },
  ];

  const customDropPanels = [
    {
      key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM
        .MOBILE_OPTIMIZE_LAYOUT_UNDO,
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.AUTO_SAVE,
      dropPanelData: {
        title: 'AppStudio_TopBar_AppSaved_Tooltip_Title',
        linkText: null,
        linkAction: null,
      },
    },
    { key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.BACK_TO_ADI },
    { key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.DISABLE_AUTO_SAVE },
    { key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.ENABLE_AUTO_SAVE },
  ];

  const getToolsMenuOverrides = (editorState) => {
    const { openDockedSections } = sectionedPanelSelectors;

    const isWidgetPublicAPISectionOpen = openDockedSections(editorState)?.some(
      (sectionName) =>
        sectionName === constants.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API,
    );

    const { WIDGET_PUBLIC_API } = constants.DOCKED_PANEL_SECTIONS;
    return [
      { key: TOOLS_MENU_ITEMS.TOOLBAR },
      { key: TOOLS_MENU_ITEMS.RULERS },
      { key: 'separator', isSeparator: true, __isExtraItem: true },
      { key: TOOLS_MENU_ITEMS.DEVELOPER_MODE_TITLE },
      {
        key: TOOLS_MENU_ITEMS.PROPERTIES_TOOLBAR,
        condition: () => !isVariationPage(editorAPI, editorState),
      },
      { key: TOOLS_MENU_ITEMS.HIDDEN_ELEMENTS },
      {
        key: TOOLS_MENU_ITEMS.WIDGET_PUBLIC_API,
        automationId: 'list-item-widget-public-api',
        isCheckbox: true,
        checkboxValue: isWidgetPublicAPISectionOpen,
        leftTitle: 'TOPBAR_VIEW_API',
        rightTitle: 'TOPBAR_VIEW_API',
        description: 'TOPBAR_VIEW_API_BODY',
        symbolName: 'appStudioTopBarWidgetAPI',
        condition: () => !isVariationPage(editorAPI, editorState),
        onClick() {
          dispatch(sectionedPanelActions.toggleSection(WIDGET_PUBLIC_API));
        },
        __isExtraItem: true,
      },
    ];
  };

  const overrideGetters = {
    getMenuOverrides,
    getSiteMenuOverrides: () =>
      _.reject(
        siteMenuOverrides,
        (item) => item.condition && !item.condition(),
      ),
    getToolsMenuOverrides: (editorState) =>
      _.reject(
        getToolsMenuOverrides(editorState),
        (item) => item.condition && !item.condition(),
      ),
    getHelpMenuOverrides: () => helpMenuOverrides,
    getRightButtonsOverrides,
    getLeftButtonsOverrides,
    getCustomDropPanelsOverrides: () => customDropPanels,
  };

  return Object.assign({}, overridenBaseState, {
    menuItems,
    quickNavigation,
    overrideGetters,
  });
};

const getOverriddenAddPanelItems = () => {
  const categoriesDefinition = [
    {
      categoryId: CATEGORIES_ID.TEXT,
      groups: [
        {
          sections: ['section_1', 'titlesSection', 'paragraphsSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.IMAGE,
      groups: [
        {
          sections: [
            'myUploadsSection',
            'imageCollectionsSection',
            'mySocialImagesSection',
          ],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.BUTTON,
      groups: [
        {
          sections: [
            'cashierButtons',
            'section_1',
            'section_2',
            'stylableButtonTheme',
            'stylableButtonSection',
            'textButtonsSection',
            'iconButtonsSection',
            'fileDownloadButtonsSection',
            'fileUploaderSection',
            'iTunesDownloadButtonSection',
          ],
        },
        {
          sections: ['paypalButtonsSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.BOX,
      groups: [
        {
          sections: [
            'section_1',
            'section_2',
            'containerBoxesSection',
            'stateBoxSection',
          ],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.DECORATIVE,
      groups: [
        {
          sections: [
            'myLinesSection',
            'themedLinesSection',
            'basicShapesSection',
            'arrowsSection',
            'horizontalLinesSection',
            'verticalLinesSection',
          ],
        },
        {
          sections: [
            'featuredSection',
            'iconsSection',
            'animalNatureSection',
            'logosBadgesSection',
            'charactersSection',
          ],
        },
        {
          sections: ['videoBoxSection', 'videoBoxTransparentSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.GALLERY,
      groups: [
        {
          sections: ['gridGalleriesSection', 'sliderGalleriesSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.MEDIA,
      groups: [
        {
          sections: ['socialVideoSection', 'videoBoxSection'],
        },
        {
          sections: [
            'musicThemeSection',
            'spotifyPlayerSection',
            'iTunesDownloadButtonSection',
          ],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.CONTACT,
      groups: [
        {
          sections: [],
        },
        {
          sections: ['googleMapsSquareSection', 'googleMapsGenericSection'],
        },
        {
          sections: ['lineSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.BOX_SLIDE_SHOW,
      groups: [
        {
          sections: ['boxSlideShow980Section', 'boxSlideShowSection'],
        },
        {
          sections: [],
        },
        {
          sections: ['stateBoxSection'],
        },
        {
          sections: ['progressBarSection', 'paginationSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.REPEATER,
      groups: [
        {
          sections: ['gridSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.SOCIAL,
      groups: [
        {
          sections: [
            'socialBarSection',
            'facebookSection',
            'twitterSection',
            'pinterestSection',
            'vkSection',
            'lineSection',
          ],
        },
        {
          sections: ['youtubeSocialSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.DEVELOPER,
      groups: [
        {
          sections: [
            'textInputSection',
            'textAreaSection',
            'richTextBoxSection',
          ],
        },
        {
          sections: [
            'radioGroupSection',
            'checkboxGroupSection',
            'checkboxSection',
            'selectionSection',
            'selectionTagsListSection',
            'datePickerSection',
            'timePickerSection',
            'sliderSection',
          ],
        },
        {
          sections: [
            'fileUploaderSection',
            'ratingsInputSection',
            'ratingsDisplaySection',
            'addressInputSection',
            'toggleSwitchSection',
            'captchaSection',
            'signatureInputSection',
          ],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.EMBEDS,
      groups: [
        {
          sections: [],
        },
        {
          sections: [],
        },
        {
          sections: ['spotifyPlayerSection', 'iTunesDownloadButtonSection'],
        },
      ],
    },
    {
      categoryId: CATEGORIES_ID.MENU,
      groups: [
        {
          sections: ['stylableMenuTheme'],
        },
      ],
    },
  ];
  const categories = _.thru(
    addPanelOverrideUtil.mapOverrideConfigToCategoriesGroupSchema(
      categoriesDefinition,
    ),
    addExtraSectionsByGroups,
  );

  return experiment.isOpen('se_appBuilderAllAddPanel') ? undefined : categories;
};

const addExtraSectionsByGroups = (categories) => {
  util.overrideUtils.addPanel.addExtraSectionByGroup(
    categories,
    CATEGORIES_ID.REPEATER,
    0,
    'oneItemRepeaterSection',
    0,
  );
  util.overrideUtils.addPanel.addExtraSectionByGroup(
    categories,
    CATEGORIES_ID.EMBEDS,
    0,
    'htmlFlashWithoutCustomElementSection',
    0,
  );
  return categories;
};

const getOverriddenLeftBarButtons = (isRootContainer = true) => ({
  desktop: isRootContainer
    ? [{ key: constants.ROOT_COMPS.LEFTBAR.ADD_ID }]
    : [],
  mobile: [],
});

const getVariationOverriddenLeftBarButtons = () => ({
  desktop: [],
  mobile: [],
});

const getOverriddenLeftTreeItems = () => {
  const { LEFT_TREE } = overriddenConstants;
  const LEFTBAR_ROOT_COMP = constants.ROOT_COMPS.LEFTTREE;

  return {
    overriddenItems: [
      {
        key: LEFT_TREE.APP_BUILDER_LEFT_TREE_HEADER.KEY,
        moduleName: 'appStudio.components.appBuilderLeftTreeHeader',
        __isExtraItem: true,
      },
      {
        key: LEFT_TREE.WIDGETS_VIEW.KEY,
        moduleName: 'appStudio.components.widgetsView',
        __isExtraItem: true,
      },
      {
        key: LEFTBAR_ROOT_COMP.COLLECTIONS,
        shouldShow: !!experiment.isOpen('se_appBuilderCollections'),
      },
    ],
  };
};

const getOverriddenDesignPanelSections = (state) => {
  const { ID, IS_HIDDEN } = overriddenConstants.DESIGN_PANEL.MORE_GALLERIES;

  return _.defaultsDeep(
    hideItem({ KEY: ID, IS_HIDDEN }),
    state.designPanel.overridden,
  );
};

const getOverriddenDomainPenelData = () => ({
  panelName: 'appStudio.panels.namePanel',
});

const getOverriddenSiteDefaultName = () => 'AppStudio_Publish_App_Default_Name';

const getOverriddenLinkPanelSections = () => [
  { key: constants.LINK_PANEL.noLinkTabLabel },
  { key: constants.LINK_PANEL.externalLinkTabLabel },
  { key: constants.LINK_PANEL.edgeAnchorLinksLabel },
  { key: constants.LINK_PANEL.docLinkTabLabel },
  { key: constants.LINK_PANEL.emailLinkTabLabel },
  { key: constants.LINK_PANEL.phoneLinkTabLabel },
  { key: constants.LINK_PANEL.whatsAppLinkTabLabel },
  { key: constants.LINK_PANEL.addressLinkTabLabel },
];

const getOverrideExternalLinks = () => {
  const { TERM_OF_USE, PRIVACY } = overriddenConstants.EXTERNAL_LINKS;
  return {
    [TERM_OF_USE.KEY]: { [TERM_OF_USE.LINK]: 'about/terms-of-use' },
    [PRIVACY.KEY]: { [PRIVACY.LINK]: 'about/privacy' },
  };
};

export {
  getOverriddenTopBarItems,
  getOverriddenAddPanelItems,
  getOverriddenLeftBarButtons,
  getVariationOverriddenLeftBarButtons,
  getOverriddenLeftTreeItems,
  getOverriddenDesignPanelSections,
  getOverriddenLinkPanelSections,
  getOverriddenDomainPenelData,
  getOverriddenSiteDefaultName,
  getOverrideExternalLinks,
};
