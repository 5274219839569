import * as actionTypes from './previewActionTypes';
import type { PreviewType } from './types';

const setPreviewModeFlag = (modeOn: boolean) => ({
  type: actionTypes.SET_PREVIEW_MODE,
  modeOn,
});

const setPreviewPointerEventsOn = (pointerEventsOn: boolean) => ({
  type: actionTypes.SET_PREVIEW_POINTER_EVENTS_ON,
  pointerEventsOn,
});

const setIsUserToggledPreviewInCurrentSession = () => ({
  type: actionTypes.SET_IS_USER_TOGGLED_PREVIEW_IN_CURRENT_SESSION,
});

const setPreviewMode = (modeOn: boolean) => (dispatch: AnyFixMe) => {
  dispatch(setPreviewModeFlag(modeOn));
  dispatch(setPreviewPointerEventsOn(modeOn));
};

const setPreviewWidth = (previewWidth: number) => ({
  type: actionTypes.SET_PREVIEW_WIDTH,
  previewWidth,
});

const setPreviewType = (preveiwType: PreviewType) => ({
  type: actionTypes.SET_PREVIEW_TYPE,
  preveiwType,
});

const enablePreviewResizeAnimation = () => ({
  type: actionTypes.ENABLE_PREVIEW_RESIZE_ANIMATION,
});

const disablePreviewResizeAnimation = () => ({
  type: actionTypes.DISABLE_PREVIEW_RESIZE_ANIMATION,
});

export {
  setPreviewMode,
  setPreviewPointerEventsOn,
  setIsUserToggledPreviewInCurrentSession,
  setPreviewWidth,
  setPreviewType,
  enablePreviewResizeAnimation,
  disablePreviewResizeAnimation,
};
