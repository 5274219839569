import { createDockingFromResponsiveLayouts } from '../../dockingMigrations';
import { responsiveLayoutGet } from '../responsiveLayout';
import * as dockingUtils from '../../dockingUtils';
import type { CompRef, DSRead, SingleLayoutData } from 'types/documentServices';

export function layoutGetDockedByLayouts(layouts: SingleLayoutData) {
  return createDockingFromResponsiveLayouts(layouts);
}

export function layoutGetDocked(dsRead: DSRead, compRef: CompRef) {
  const layouts = responsiveLayoutGet(dsRead, compRef);

  return layoutGetDockedByLayouts(layouts);
}

export function layoutIsDocked(dsRead: DSRead, compRef: CompRef) {
  return !!layoutGetDocked(dsRead, compRef);
}

export function layoutIsDockedToScreen(dsRead: DSRead, compRef: CompRef) {
  // source:
  // - https://github.com/wix-private/document-management/blob/b30cc2ea84908fdde8bbb2ceecbafc08992c9dfb/document-services-implementation/src/structure/structure.ts#L829
  // - https://github.com/wix-private/santa-core/blob/f1a5a0d8e67d5d606cd1983a47a56794e4f12238/santa-core-utils/src/coreUtils/core/layoutUtils.js#L77
  return dockingUtils.isDockToScreen(layoutGetDocked(dsRead, compRef));
}

export function layoutIsHorizontallyStretchedToScreen(
  dsRead: DSRead,
  compRef: CompRef,
) {
  return dockingUtils.isHorizontallyStretchedToScreen(
    layoutGetDocked(dsRead, compRef),
  );
}
