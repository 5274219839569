import { mobileInitialState } from '../initialState';
import type { SetDidVisitMobileEditorInCurrentSessionTrueAction } from './mobileSessionActions';
import { mobileSessionActionTypes } from './mobileSessionActionTypes';

type MobileSessionAction = SetDidVisitMobileEditorInCurrentSessionTrueAction;

export default (
  didVisitMobileEditorInCurrentSession = mobileInitialState.didVisitMobileEditorInCurrentSession,
  action: MobileSessionAction,
) => {
  switch (action.type) {
    case mobileSessionActionTypes.SET_DID_VISIT_MOBILE_EDITOR_IN_CURRENT_SESSION_TRUE:
      return true;

    default:
      return didVisitMobileEditorInCurrentSession;
  }
};
