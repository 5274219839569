import { hoc } from '@/util';
import * as biActions from './biActions';
import type { WithBiLoggerProps } from 'types/bi';
import type { MapDispatchToProps } from 'types/redux';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const mapDispatchToProps: MapDispatchToProps<WithBiLoggerProps, unknown> = (
  dispatch,
) => ({
  biLogger: {
    logEvent: (biEvent, biEventParams) =>
      dispatch(biActions.event(biEvent, biEventParams)),
    logError: (errorEvent, errorParams = {}) =>
      dispatch(biActions.error(errorEvent, errorParams)),
  },
});

const withBiLogger = connect<undefined, WithBiLoggerProps>(
  EDITOR_API,
  undefined,
  mapDispatchToProps,
);

export default withBiLogger;
