const ENTER_INTERACTION = 'enterInteraction';
const EXIT_INTERACTION = 'exitInteraction';
const CREATE_INTERACTION = 'createInteraction';
const UPDATE_INTERACTION_SELECTED_COMPONENT =
  'updateInteractionSelectedComponent';
const PLAY_INTERACTION = 'playInteraction';
const STOP_PLAYING_INTERACTION = 'stopPlayingInteraction';
const UPDATE_INTERACTION_TRANSITIONS = 'updateInteractionTransition';

export const interactionsActionTypes = {
  ENTER_INTERACTION,
  EXIT_INTERACTION,
  UPDATE_INTERACTION_SELECTED_COMPONENT,
  CREATE_INTERACTION,
  PLAY_INTERACTION,
  STOP_PLAYING_INTERACTION,
  UPDATE_INTERACTION_TRANSITIONS,
};
