import * as actionTypes from './savedComponentsActionTypes';
import type { Reducer } from 'types/redux';

interface SavedComponentsItem {
  id: string;
}

interface SavedComponentsState {
  items: SavedComponentsItem[];
  optimisticItems: SavedComponentsItem[];
}

export const savedComponentsInitialState: SavedComponentsState = {
  items: [],
  optimisticItems: [],
};

const reducer: Reducer<SavedComponentsState> = (
  savedComponents = savedComponentsInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_COLLECTION_ACTION:
      return Object.assign({}, savedComponents, {
        items: [...savedComponents.optimisticItems, ...action.items],
      });
    case actionTypes.ADD_OPTIMISTIC_ITEM_ACTION:
      return Object.assign({}, savedComponents, {
        items: [action.item, ...savedComponents.items],
        optimisticItems: [action.item],
      });
    case actionTypes.ADD_ITEM_ACTION:
      return Object.assign({}, savedComponents, {
        items: [action.item, ...savedComponents.items],
      });
    case actionTypes.UPDATE_ITEM_ACTION:
      return Object.assign({}, savedComponents, {
        items: savedComponents.items.map((item) => {
          if (item.id !== action.id) {
            return item;
          }

          return Object.assign({}, item, action.updatedProps);
        }),
      });
    case actionTypes.DELETE_ITEM_ACTION:
      return Object.assign({}, savedComponents, {
        items: savedComponents.items.filter((item) => item.id !== action.id),
      });
    case actionTypes.CLEAN_OPTIMISTIC_ITEM_ACTION:
      return Object.assign({}, savedComponents, {
        optimisticItems: savedComponents.optimisticItems.filter(
          (item) => item.id !== action.id,
        ),
      });
    default:
      return savedComponents;
  }
};

export default reducer;
