import { sections, array } from '@/util';
import constraintsUtil from './constraintsUtil';

import type { LayoutConstraint, LayoutContstraintBoundaries } from '../types';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import { FixedStageApiKey } from '@/apis';

const MAXIMUM_Y_COMP_ON_PAGE = 500000;
const MIN_ALLOWED_VISIBLE_ON_STAGE = 50;
const MAX_COMP_WIDTH_FOR_50_PERCENT = 50;

function isDirectChildOfMasterPage(editorAPI: EditorAPI, compPointer: CompRef) {
  const masterPage = editorAPI.siteSegments.getSiteStructure();

  return editorAPI.utils.isSameRef(
    editorAPI.components.getContainer(compPointer),
    masterPage,
  );
}

function getMaxY(
  editorAPI: EditorAPI,
  compPointer: CompRef,
): number | undefined {
  const footer = editorAPI.dsRead.siteSegments.getFooter();
  const footerLayout = editorAPI.components.layout.getRelativeToScreen(footer);

  const isLandingPage = editorAPI.isCurrentPageLandingPage();

  if (sections.isSectionsEnabled()) {
    const isFooter = compPointer.id === footer.id;

    const pageLayout = editorAPI.components.layout.getRelativeToScreen(
      editorAPI.pages.getCurrentPage(),
    );

    const isSection = editorAPI.sections.isSection(compPointer);

    if (isFooter || isSection) {
      return MAXIMUM_Y_COMP_ON_PAGE;
    }

    if (isLandingPage) {
      return pageLayout.height;
    }

    return footerLayout.y + footerLayout.height;
  }

  if (isLandingPage) {
    return undefined;
  }

  if (
    constraintsUtil.isShownOnAllPagesComponent(editorAPI, compPointer) &&
    !(
      editorAPI.siteSegments.isUsingSectionsLayout() &&
      isDirectChildOfMasterPage(editorAPI, compPointer)
    )
  ) {
    return footerLayout.y + footerLayout.height;
  }

  return MAXIMUM_Y_COMP_ON_PAGE;
}

const getLeftXForRightBoundary = (
  editorAPI: EditorAPI,
  compPointer: CompRef,
): number => {
  const { width } = editorAPI.components.layout.get_size(compPointer);
  const siteWidth = editorAPI.site.getWidth();

  if (width < MAX_COMP_WIDTH_FOR_50_PERCENT) {
    return siteWidth - width / 2;
  }

  return siteWidth - MIN_ALLOWED_VISIBLE_ON_STAGE;
};

const getRightXForLeftBoundary = (
  editorAPI: EditorAPI,
  compPointer: CompRef,
): number => {
  const { width } = editorAPI.components.layout.get_size(compPointer);

  if (width < MAX_COMP_WIDTH_FOR_50_PERCENT) {
    return width / 2;
  }

  return MIN_ALLOWED_VISIBLE_ON_STAGE;
};

function getBoundaries(editorAPI: EditorAPI, compPointer: CompRef | CompRef[]) {
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  const boundaries: LayoutContstraintBoundaries = {};

  boundaries.top = { compTopY: 0 };

  const compBottomY = getMaxY(editorAPI, array.asArray(compPointer)[0]);

  if (compBottomY !== undefined) {
    boundaries.bottom = {
      compBottomY,
    };
  }

  if (fixedStageAPI.isStageFixedInDesktop()) {
    const compLeftX = getLeftXForRightBoundary(
      editorAPI,
      array.asArray(compPointer)[0],
    );
    const compRightX = getRightXForLeftBoundary(
      editorAPI,
      array.asArray(compPointer)[0],
    );

    boundaries.right = {
      compLeftX,
    };
    boundaries.left = {
      compRightX,
    };
  }

  return boundaries;
}

const constraint: LayoutConstraint = {
  shouldConstrain(editorAPI) {
    return !editorAPI.isMobileEditor();
  },
  getBoundaries,
};

export default constraint;
