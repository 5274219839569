import { isPopupContainer } from '@/documentServices';
import { isFixedItemLayout } from '@/layoutUtils';
import { responsiveLayoutGet } from '../responsiveLayout';
import type { CompRef, DSRead } from 'types/documentServices';

export function layoutGetFixedPosition(dsRead: DSRead, compRef: CompRef) {
  if (isPopupContainer(dsRead, compRef)) {
    return false;
  }

  const layouts = responsiveLayoutGet(dsRead, compRef);
  if (!layouts?.itemLayout) {
    return undefined;
  }

  return isFixedItemLayout(layouts.itemLayout);
}
