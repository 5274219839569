const undo =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    if (!editorAPI.text.isEditingText()) {
      editorAPI.history.undo();
    } else {
      editorAPI.text.undo();
    }
  };

const redo =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    if (!editorAPI.text.isEditingText()) {
      editorAPI.history.redo();
    } else {
      editorAPI.text.redo();
    }
  };

/**
 * @param {string} label Name of action to keep in history
 * @param {any} params
 * @param {any} amendToSnapshotWithId
 */
const add =
  (label: string, params?: object, amendToSnapshotWithId?: string) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    editorAPI.history.add(label, params, amendToSnapshotWithId);
  };

export { undo, redo, add };
