// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';

// TODO: this functionality should move to editorAPI/editor/editorStage instead of querying the DOM directly

function getScrollableEditorElement() {
  return window.document.getElementById(
    constants.ROOT_COMPS.SELECTOR_ID.SCROLLABLE_EDITOR_STAGE,
  );
}

export default {
  /**
   * @returns {{scrollTop: number, scrollLeft: number, scrollHeight: number, scrollWidth: number}}
   */
  //use getScroll selector in domMeasurements instead
  getScroll() {
    const scrollableElement = getScrollableEditorElement();
    return _.pick(scrollableElement, [
      'scrollLeft',
      'scrollTop',
      'scrollHeight',
      'scrollWidth',
    ]);
  },

  /**
   * @param {Object} scroll - scroll position to set
   * @param {number} [scroll.scrollLeft] - left position.
   * @param {number} [scroll.scrollTop] - top position.
   */
  setScroll(scroll) {
    const scrollableElement = getScrollableEditorElement();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (!_.isUndefined(scroll.scrollTop)) {
      scrollableElement.scrollTop = scroll.scrollTop;
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (!_.isUndefined(scroll.scrollLeft)) {
      scrollableElement.scrollLeft = scroll.scrollLeft;
    }
  },
};
