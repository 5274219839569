import { arrayUtils, sectionsUtils } from '@/util';
import { isPopupContainer, isStripColumnContainer } from '@/documentServices';
import {
  getFullWidthFromLayouts as getFullWidthFromLayouts_Mesh,
  isFullWidthByLayouts as isFullWidthByLayouts_Mesh,
  recalculateFullWidthLayouts as recalculateFullWidthLayouts_Mesh,
  recalculateNotFullWidthLayoutsAfterFullWidth as recalculateNotFullWidthLayoutsAfterFullWidth_Mesh,
} from '@/layoutUtils';
import {
  isMeshLayoutEnabled,
  getDockingFromFullWidthValue,
  getStripPropertiesFromFullWidthValue,
  getFullWidthValueFromDocking,
  getFullWidthValueFromStripProperties,
  getFullWidthValueFromPopupContainerProperties,
} from '@/layoutOneDockMigration';
import { calulateXYInContainer_byXYInAnotherContainer } from '../layoutMeshApi/layoutMeshReparentApi/calculatePositionInContainer';

import type { EditorAPI } from '@/editorAPI';
import type { LayoutMeshApi } from '../layoutMeshApi';
import type { CompRef, Rect } from 'types/documentServices';
import type { LayoutFullWidthSiteMargin, LayoutFullWidthValue } from '../type';
import type { LayoutGetApi } from '../layoutGetApi';

export function createLayoutFullWidthApi({
  editorAPI,
  layoutGetApi,
  layoutMeshApi,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
  layoutMeshApi: LayoutMeshApi;
}) {
  // function isFullWidthByMetadata(compRef: CompRef) {
  //   return editorAPI.components.is.fullWidth(compRef);
  // }

  function getFullWidth_Docking(compRef: CompRef): LayoutFullWidthValue {
    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    const compDocking = editorAPI.dsRead.components.layout.getDock(compRef);
    return getFullWidthValueFromDocking(compDocking);
  }

  function getFullWidth_Strip(compRef: CompRef): LayoutFullWidthValue {
    const compProperties = editorAPI.components.properties.get(compRef);

    return getFullWidthValueFromStripProperties(compProperties);
  }

  function getFullWidth_Popup(compRef: CompRef): LayoutFullWidthValue {
    const compProperties = editorAPI.components.properties.get(compRef);

    return getFullWidthValueFromPopupContainerProperties(compProperties);
  }

  function getFullWidth_Mesh(compRef: CompRef): LayoutFullWidthValue {
    const layouts = layoutMeshApi.get(compRef);

    return getFullWidthFromLayouts_Mesh(layouts);
  }

  function getFullWidth(compRef: CompRef): LayoutFullWidthValue {
    // if (isFullWidthByMetadata(compRef)) {
    //   return {
    //     isFullWidth: true,
    //     siteMargin: 0,
    //   };
    // }

    if (isMeshLayoutEnabled()) {
      return getFullWidth_Mesh(compRef);
    }

    if (isStripColumnContainer(editorAPI.dsRead, compRef)) {
      return getFullWidth_Strip(compRef);
    }

    if (isPopupContainer(editorAPI.dsRead, compRef)) {
      return getFullWidth_Popup(compRef);
    }

    return getFullWidth_Docking(compRef);
  }

  function isFullWidth(compRefOrRefs: CompRef | CompRef[]) {
    return arrayUtils
      .asArray(compRefOrRefs)
      .every((compRef) => getFullWidth(compRef).isFullWidth);
  }

  /**
   * @deprecated use `isFullWidth` instead
   */
  function isHorizontallyStretchedToScreen(compRefOrRefs: CompRef | CompRef[]) {
    if (isMeshLayoutEnabled()) {
      return isFullWidth(compRefOrRefs);
    }

    return arrayUtils.asArray(compRefOrRefs).every((compRef: CompRef) =>
      // DS source https://github.com/wix-private/santa-core/blob/f1a5a0d8e67d5d606cd1983a47a56794e4f12238/santa-core-utils/src/coreUtils/core/dockUtils.js#L62-L68
      editorAPI.dsRead.components.layout.isHorizontallyStretchedToScreen(
        compRef,
      ),
    );
  }

  function reparentComponent_Mesh(compRef: CompRef, containerToRef: CompRef) {
    const containerFromRef = editorAPI.components.getContainer(compRef);
    const compXYInContainerFrom = layoutGetApi.get_position(compRef);
    const compSize = layoutGetApi.get_size(compRef);

    const [compRefUpdated] = arrayUtils.asArray(
      editorAPI.components.setContainer(compRef, containerToRef),
    );

    const compXYInContainerTo = calulateXYInContainer_byXYInAnotherContainer(
      editorAPI,
      {
        compXYInContainerFrom,
        containerFromRef,
        containerToRef,
      },
    );

    return {
      compRefUpdated,
      compRectUpdated: {
        ...compXYInContainerTo,
        ...compSize,
      },
    };
  }

  function reparentComponent(compRef: CompRef, containerRefTarget: CompRef) {
    if (isMeshLayoutEnabled()) {
      // NOTE: ⚠️ handle component position after reparenting (without mesh2 it's handled automatically)
      return reparentComponent_Mesh(compRef, containerRefTarget);
    }

    const [compRefUpdated] = arrayUtils.asArray(
      editorAPI.components.setContainer(compRef, containerRefTarget),
    );
    const compRectUpdated = layoutGetApi.get_rect(compRefUpdated);

    return {
      compRefUpdated,
      compRectUpdated,
    };
  }

  function isComponentInStretchableContainer(compRef: CompRef) {
    return editorAPI.components.is.potentialContainerForScreenWidthComp(
      editorAPI.components.getContainer(compRef),
    );
  }

  function getComponentStreatchableContainer(compRef: CompRef) {
    return sectionsUtils.isSectionsEnabled()
      ? editorAPI.sections.getClosestSectionLike(compRef)
      : editorAPI.pages.getFocusedPage();
  }

  interface StretchComponentOptions {
    siteMargin?: LayoutFullWidthSiteMargin;
  }

  function areSiteMarginsEqual(
    siteMarginA: LayoutFullWidthSiteMargin,
    siteMarginB: LayoutFullWidthSiteMargin,
  ) {
    if (siteMarginA === siteMarginB) {
      // same object reference or both are undefined
      return true;
    }

    if (!siteMarginA || !siteMarginB) {
      // one of them is undefined
      return false;
    }

    return (
      siteMarginA.value === siteMarginB.value &&
      siteMarginA.type === siteMarginB.type
    );
  }

  const SITE_MARGIN_DEFAULT: LayoutFullWidthSiteMargin = {
    value: 0,
    type: 'px',
  };

  function stretchComponent_Docking(
    compRef: CompRef,
    options?: StretchComponentOptions,
  ) {
    const fullWidthPrev = getFullWidth_Docking(compRef);
    const siteMargin =
      options?.siteMargin ?? fullWidthPrev.siteMargin ?? SITE_MARGIN_DEFAULT;

    if (
      fullWidthPrev.isFullWidth &&
      areSiteMarginsEqual(siteMargin, fullWidthPrev.siteMargin)
    ) {
      return;
    }

    if (!isComponentInStretchableContainer(compRef)) {
      ({ compRefUpdated: compRef } = reparentComponent(
        compRef,
        getComponentStreatchableContainer(compRef),
      ));
    }

    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    editorAPI.components.layout.setDock(
      compRef,
      getDockingFromFullWidthValue({
        isFullWidth: true,
        siteMargin,
      }),
    );

    if (!fullWidthPrev.isFullWidth) {
      editorAPI.history.add('stretchComponent');
    } else {
      editorAPI.history.debouncedAdd('stretchComponent - update siteMargin');
    }
  }

  function stretchComponent_Strip(
    compRef: CompRef,
    options?: StretchComponentOptions,
  ) {
    const fullWidthPrev = getFullWidth_Strip(compRef);
    const siteMargin =
      options?.siteMargin ?? fullWidthPrev.siteMargin ?? SITE_MARGIN_DEFAULT;

    if (
      fullWidthPrev.isFullWidth &&
      areSiteMarginsEqual(siteMargin, fullWidthPrev.siteMargin)
    ) {
      return;
    }

    editorAPI.components.properties.update(
      compRef,
      getStripPropertiesFromFullWidthValue({
        isFullWidth: true,
        siteMargin,
      }),
      {
        dontAddToUndoRedoStack: true,
      },
    );

    if (!fullWidthPrev.isFullWidth) {
      editorAPI.history.add('stretchComponent');
    } else {
      editorAPI.history.debouncedAdd('stretchComponent - update siteMargin');
    }
  }

  function recalculateFullWidthLayoutsAndUpdate_Mesh(
    compRef: CompRef,
    siteMargin: LayoutFullWidthSiteMargin,
  ) {
    const layouts = layoutMeshApi.get(compRef);

    layoutMeshApi.__updateMeshLayout(
      compRef,
      recalculateFullWidthLayouts_Mesh(layouts, siteMargin, {
        componentType: editorAPI.components.getType(compRef),
      }),
    );
  }

  async function stretchComponent_Mesh(
    compRef: CompRef,
    options?: StretchComponentOptions,
  ) {
    const layouts = layoutMeshApi.get(compRef);
    const fullWidthPrev = getFullWidthFromLayouts_Mesh(layouts);
    const siteMargin =
      options?.siteMargin ?? fullWidthPrev.siteMargin ?? SITE_MARGIN_DEFAULT;

    if (
      fullWidthPrev.isFullWidth &&
      areSiteMarginsEqual(siteMargin, fullWidthPrev.siteMargin)
    ) {
      return;
    }

    const markComponentAsFullWidth = (compRef: CompRef) =>
      recalculateFullWidthLayoutsAndUpdate_Mesh(compRef, siteMargin);

    const updateFullWidthSiteMargin = (compRef: CompRef) =>
      recalculateFullWidthLayoutsAndUpdate_Mesh(compRef, siteMargin);

    if (fullWidthPrev.isFullWidth) {
      updateFullWidthSiteMargin(compRef);
      editorAPI.history.debouncedAdd('stretchComponent - update siteMargin');
      return;
    }

    if (isComponentInStretchableContainer(compRef)) {
      markComponentAsFullWidth(compRef);
      editorAPI.history.add('stretchComponent');
      return;
    }

    await editorAPI.transactions.run(async () => {
      const containerRefCurrent = editorAPI.components.getContainer(compRef);
      const containerRefUpdated = getComponentStreatchableContainer(compRef);
      const { compRefUpdated, compRectUpdated } = reparentComponent(
        compRef,
        containerRefUpdated,
      );

      markComponentAsFullWidth(compRefUpdated);

      // Update containers grids after component is reparented and marked as full width
      layoutMeshApi.updateContainerGrid(containerRefCurrent);
      layoutMeshApi.updateContainerGrid(containerRefUpdated, {
        compRectsMap: new Map([[compRefUpdated.id, compRectUpdated]]),
      });
    });

    editorAPI.history.add('stretchComponent');
  }

  async function stretchComponent(
    compRefOrRefs: CompRef | CompRef[],
    options?: StretchComponentOptions,
  ) {
    const compRef = arrayUtils.asSingleThrowIfMultiple(compRefOrRefs);

    if (isMeshLayoutEnabled()) {
      return stretchComponent_Mesh(compRef, options);
    }

    if (isStripColumnContainer(editorAPI.dsRead, compRef)) {
      return stretchComponent_Strip(compRef, options);
    }

    return stretchComponent_Docking(compRef, options);
  }

  interface UnStretchComponentOptions {
    // containerRef, usually is a previous component container before stretch
    containerRef?: CompRef;
    compRect?: Partial<Rect>;
  }

  function unStretchComponent_Docking(
    compRef: CompRef,
    options?: UnStretchComponentOptions,
  ) {
    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    editorAPI.components.layout.unDock(compRef);

    editorAPI.dsActions.waitForChangesApplied(() => {
      editorAPI.components.layout.update(compRef, {
        ...options?.compRect,
        width: options?.compRect?.width ?? editorAPI.site.getWidth(),
      });

      const containerRefCurrent = editorAPI.components.getContainer(compRef);
      if (
        options?.containerRef &&
        !editorAPI.utils.isSameRef(options?.containerRef, containerRefCurrent)
      ) {
        reparentComponent(compRef, options?.containerRef);
      }
    });

    editorAPI.history.add('unStretchComponent');
  }

  function unStretchComponent_Strip(compRef: CompRef) {
    editorAPI.components.properties.update(
      compRef,
      getStripPropertiesFromFullWidthValue({
        isFullWidth: false,
      }),
      {
        dontAddToUndoRedoStack: true,
      },
    );

    editorAPI.history.add('unStretchComponent');
  }

  async function unStretchComponent_Mesh(
    compRef: CompRef,
    options?: UnStretchComponentOptions,
  ) {
    const layouts = layoutMeshApi.get(compRef);

    if (!isFullWidthByLayouts_Mesh(layouts)) {
      return;
    }

    const containerRefCurrent = editorAPI.components.getContainer(compRef);
    const containerRefUpdated = options?.containerRef ?? containerRefCurrent;

    await editorAPI.transactions.run(async () => {
      const compRectCurrent = layoutMeshApi.measureRect(compRef);
      let compRectUpdated = compRectCurrent;

      if (
        !editorAPI.utils.isSameRef(containerRefUpdated, containerRefCurrent)
      ) {
        ({ compRefUpdated: compRef, compRectUpdated } = reparentComponent(
          compRef,
          containerRefUpdated,
        ));
      }

      compRectUpdated = {
        ...compRectUpdated,
        ...options?.compRect,
        x: options?.compRect.x ?? 0,
        width: options?.compRect.width ?? editorAPI.site.getWidth(),
      };

      function markComponentAsNotFullWidth(
        compRef: CompRef,
        xAndWidth: Pick<Rect, 'x' | 'width'>,
      ) {
        const componentType = editorAPI.components.getType(compRef);

        layoutMeshApi.__updateMeshLayout(
          compRef,
          recalculateNotFullWidthLayoutsAfterFullWidth_Mesh(
            layouts,
            xAndWidth,
            { componentType },
          ),
        );
      }

      markComponentAsNotFullWidth(compRef, compRectUpdated);

      if (containerRefUpdated !== containerRefCurrent) {
        // Update containers grids after component is reparented and marked as NOT full width
        layoutMeshApi.updateContainerGrid(containerRefCurrent);
        layoutMeshApi.updateContainerGrid(containerRefUpdated, {
          // TODO: what if component reparented? Will compRect still work?
          compRectsMap: new Map([[compRef.id, compRectUpdated]]),
        });
      }
    });

    editorAPI.history.add('unStretchComponent');
  }

  async function unStretchComponent(
    compRefOrRefs: CompRef | CompRef[],
    options?: UnStretchComponentOptions,
  ) {
    const compRef = arrayUtils.asSingleThrowIfMultiple(compRefOrRefs);

    if (isMeshLayoutEnabled()) {
      return unStretchComponent_Mesh(compRef, options);
    }

    if (isStripColumnContainer(editorAPI.dsRead, compRef)) {
      return unStretchComponent_Strip(compRef);
    }

    return unStretchComponent_Docking(compRef, options);
  }

  return {
    getFullWidth,
    isFullWidth,
    isHorizontallyStretchedToScreen,
    stretchComponent,
    unStretchComponent,
  };
}

export type LayoutFullWidthApi = ReturnType<typeof createLayoutFullWidthApi>;
