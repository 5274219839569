import * as actionTypes from './layersPanelActionTypes';

const showLayersPanel = (origin: string = '') => ({
  type: actionTypes.SHOW_LAYERS_PANEL,
  origin,
});

const hideLayersPanel = () => ({
  type: actionTypes.HIDE_LAYERS_PANEL,
});

const setPreviewMode = (isPreview: boolean) => ({
  type: actionTypes.SET_PREVIEW_MODE,
  isPreview,
});

export { showLayersPanel, hideLayersPanel, setPreviewMode };
