// @ts-nocheck
import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import experiment from 'experiment';
import * as coreBi from '@/coreBi';
import actionTypes from '../applicationStudioActionTypes';
import * as selectors from '../applicationStudioSelectors';
import * as componentsSelectors from '../../components/componentsSelectors';
import * as panelsActions from '../../panels/panelsActions';
import * as bi from '../../bi/bi';
import appStudioCodeParser from '@wix/app-studio-code-parser';
import jsonSchemaUtils from '@wix/wix-json-schema-utils';
import * as util from '@/util';
import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';

const { parser } = appStudioCodeParser;
const { PACKAGE_STEPS, PACKAGE_ERRORS, BUILD_VERSION_TYPES, PACKAGE } =
  constants.APP_STUDIO;
const { isAppWidget } = componentsSelectors;

class PackageError extends Error {
  constructor(e, packageStep, fileName) {
    super(e, packageStep);
    this.originalError = e;
    this.packageStep = packageStep;

    if (packageStep === PACKAGE_ERRORS.PARSE_CODE_ERROR) {
      this.applicationError = e.applicationError ?? {
        code: PACKAGE_ERRORS.PARSE_CODE_ERROR,
        description: e?.message,
        fileName: e?.loc
          ? `${fileName}, line: ${e.loc.line}, col: ${e.loc.column}`
          : fileName,
      };
    }
  }
}

interface IBuildParams {
  isAsyncBuild: boolean;
  isAppContainsWidgets: boolean;
  releaseNotes?: string;
  publishRc?: boolean;
  onBuildSuccessCallback: () => void;
  onBuildErrorCallback?: (error: unknown) => void;
  packageImportName?: string;
  shouldHideProgressBar?: boolean;
}

const initialBuildPrams = {
  isAsyncBuild: false,
  isAppContainsWidgets: true,
  shouldHideProgressBar: false,
  onBuildSuccessCallback: () => {},
};

const saveAndPackage =
  (params?: IBuildParams = initialBuildPrams) =>
  (dispatch, getState, { editorAPI, dsRead }) =>
    new Promise((resolve, reject) => {
      if (!editorAPI.savePublish.canPackage()) {
        ErrorReporter.captureException(
          new Error('Save or Package is in progress'),
          {
            tags: { packagePublishTasks: true },
          },
        );
        reject();
        return;
      }

      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.APP_STUDIO_PACKAGE,
      );
      dispatch(setIsPackageInProgress(true));

      if (dsRead.generalInfo.isFirstSave() || dsRead.generalInfo.isDraft()) {
        const options = {
          overrideTitle: 'PUBLISH_BEFORE_PACKAGE_CHOOSE_DOMAIN_TITLE',
          overrideSubtitle: 'PUBLISH_BEFORE_PACKAGE_CHOOSE_DOMAIN_SUBTITLE',
          callback: async () => {
            await dispatch(packageApp(params));
            resolve();
          },
          origin: 'publish',
          isPublish: true,
          onError: (e) => {
            ErrorReporter.captureException(e, {
              tags: { firstSaveBeforeBuild: true, packagePublishTasks: true },
            });
            dispatch(setIsPackageInProgress(false));
            reject();
          },
          overrideOnCancel: () => {
            util.fedopsLogger.interactionEnded(
              util.fedopsLogger.INTERACTIONS.APP_STUDIO_PACKAGE,
            );
            dispatch(setIsPackageInProgress(false));
            reject();
          },
        };

        editorAPI.saveManager.save(options);
        return;
      }

      if (experiment.isOpen('se_refactorBlocksBuild')) {
        dispatch(packageApp(params)).then(resolve);
        return;
      }
      const onError = (e) => {
        dispatch(setIsPackageInProgress(false));

        if (e?.message === dsRead.errors.save.CONCURRENT_SAVE) {
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.APP_STUDIO_PACKAGE,
          );
        } else {
          ErrorReporter.captureException(e, {
            tags: { saveBeforeBuild: true, packagePublishTasks: true },
          });
        }
        reject();
      };
      editorAPI.saveManager.saveInBackground(
        async () => {
          await dispatch(packageApp(params));
          resolve();
        },
        onError,
        'top_bar_publish',
        {},
      );
    });

const packageApp =
  (params: IBuildParams = initialBuildPrams) =>
  async (dispatch) => {
    dispatch(setPackageStep(PACKAGE_STEPS.PACKAGE_TASKS));
    dispatch(
      panelsActions.updateOrOpenPanel('appStudio.panels.packagePanel', {
        shouldHideProgressBar: params.shouldHideProgressBar,
        tasksTitle: params.isAppContainsWidgets
          ? 'AppStudio_Publish_Popup_Loader_Title'
          : 'AppStudio_Packages_Progress_Bar_Header',
      }),
    );
    return dispatch(runPackageLogic(params));
  };

const runPackageTasks = (
  dispatch,
  params: IBuildParams = initialBuildPrams,
) => {
  const tasks = getTasks({
    isAppContainsWidgets: params.isAppContainsWidgets,
  });
  dispatch(setTotalNumOfPackageTasks(tasks.length));
  let chainedTasks = Promise.resolve();
  tasks.forEach((currTask) => {
    chainedTasks = chainedTasks
      .then(() => {
        dispatch(setCurrTaskDisplayName(currTask.displayName));
        return dispatch(
          currTask.performTask(currTask.taskWithParams ? params : undefined),
        );
      })
      .then(() => {
        return dispatch(incNumOfTasksDone());
      });
  });
  return chainedTasks;
};

const sendBi = (eventType, eventParams) => (dispatch, getState) => {
  dispatch(
    bi.actions.event(
      eventType,
      _.defaults({ app_id: selectors.getAppDefId(getState()) }, eventParams),
    ),
  );
};

const getNumberOfComps = (dsRead, rootCompId) => {
  const pageRef = dsRead.components.get.byId(rootCompId.replace('#', ''));
  const mediaBoxCompRef = _.head(
    dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
      dsRead.appStudio.widgets.getRootWidgetByPage(pageRef),
    ),
  );
  const mediaBoxChildren = _.without(
    dsRead.components.code.getComponentsInPage(mediaBoxCompRef),
    mediaBoxCompRef,
  );
  return mediaBoxChildren.length;
};

const getWidgetsInfo = (dsRead) => {
  const widgets = dsRead.appStudio.widgets.getAllSerialized() || [];

  return widgets.reduce((sum, widget) => {
    const widgetComps = getNumberOfComps(dsRead, widget.rootCompId);
    sum[widget.name] = widget.variations.reduce((variationsSum, variation) => {
      const variationComps = getNumberOfComps(dsRead, variation.rootCompId);
      variationsSum[variation.name] = {
        compsAdded: variationComps,
        compsRemoved: widgetComps - variationComps,
      };
      return variationsSum;
    }, {});
    return sum;
  }, {});
};

const runPackageLogic =
  (params?: IBuildParams = initialBuildPrams) =>
  (dispatch, getState, { dsRead }) => {
    dispatch(setNumOfTasksDone(0));
    dispatch(setCurrTaskDisplayName(null));
    const isFirstAppBuild = selectors.isFirstAppBuild(getState());

    const finalTaskDisplayName = params.isAppContainsWidgets
      ? 'AppStudio_Publish_Popup_Loader_Text5'
      : 'AppStudio_Packages_Progress_Bar_Final_Step';
    return runPackageTasks(dispatch, params)
      .then(() => {
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.APP_STUDIO_PACKAGE,
        );
        dispatch(
          sendBi(coreBi.events.AppBuilder.build, {
            value: JSON.stringify(getWidgetsInfo(dsRead)),
          }),
        );
        setFinalStep(
          {
            isFirstAppBuild,
            isAsyncBuild: params.isAsyncBuild,
            finalTaskDisplayName,
          },
          dispatch,
        );
      })
      .catch((e) => {
        ErrorReporter.captureException(e.originalError, {
          tags: { packageStep: e.packageStep, packagePublishTasks: true },
        });

        if (
          (params.shouldHideProgressBar ||
            e.packageStep === PACKAGE_ERRORS.BUILD_PACKAGE) &&
          params.onBuildErrorCallback
        ) {
          params.onBuildErrorCallback(e);
        } else {
          dispatch(setPackageError(e.packageStep));
        }
        dispatch(setIsPackageInProgress(false));
      });
  };

const setFinalStep = (
  { isFirstAppBuild, isAsyncBuild, finalTaskDisplayName },
  dispatch,
) => {
  let action = () => dispatch(setIsPackageInProgress(false));
  let timeOut = PACKAGE.DURATION_TO_SHOW_RESULT;
  if (isFirstAppBuild) {
    action = () => {
      if (!isAsyncBuild) {
        dispatch(setPackageStep(PACKAGE_STEPS.FIRST_PUBLISH_RESULT));
      }

      dispatch(setIsPackageInProgress(false));
    };
    timeOut = PACKAGE.DURATION_TO_SHOW_FIRST_RESULT;
  }
  dispatch(setCurrTaskDisplayName(finalTaskDisplayName));
  //gives time for the progress bar to finish
  window.setTimeout(action, timeOut);
};

const incNumOfTasksDone = () => (dispatch, getState) => {
  const numOfTasksDone = selectors.getNumOfTasksDone(getState());
  dispatch(setNumOfTasksDone(numOfTasksDone + 1));
};

const parsePagesCode =
  () =>
  (dispatch, getState, { dsActions, dsRead }) =>
    new Promise((resolve, reject) => {
      try {
        const pagesFolder = dsActions.wixCode.fileSystem.getRoots().pages;
        dsActions.wixCode.fileSystem
          .getChildren(pagesFolder)
          .then((pages) => {
            const widgetPages = getWidgetPages(pages, dsRead) || [];
            const filesContentPromises = widgetPages.map((file) =>
              dsActions.wixCode.fileSystem.readFile(file),
            );

            return Promise.all(filesContentPromises).then((filesContent) => {
              filesContent.forEach((code, index) => {
                try {
                  const functions = parseCode(code);
                  setWidgetFunctions(
                    widgetPages[index],
                    Object.values(functions),
                    dispatch,
                    dsRead,
                  );
                } catch (e) {
                  const widgetName = dsRead.appStudio.widgets.name.get(
                    getWidgetPointerByFSName(dsRead, widgetPages[index].name),
                  );
                  console.error(
                    `Error parsing the ${widgetName} widget code`,
                    e,
                  );

                  throw new PackageError(
                    e,
                    PACKAGE_ERRORS.PARSE_CODE_ERROR,
                    widgetName,
                  );
                }
              });
              return new Promise((res) => dsActions.waitForChangesApplied(res));
            });
          })
          .then(resolve)
          .catch((e) => {
            reject(new PackageError(e, PACKAGE_ERRORS.PARSE_CODE_ERROR));
          });
      } catch (e) {
        reject(new PackageError(e, PACKAGE_ERRORS.PARSE_CODE_ERROR));
      }
    });

const parseCode = (code) => {
  let functions;
  try {
    functions = parser.parse(code);
  } catch (e) {
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.APP_STUDIO_PACKAGE,
    );
    throw e;
  }
  return functions;
};

const getWidgetPages = (pages, dsRead) =>
  pages?.filter((page) => {
    const pageId = trimJSSuffix(page.name);
    return (
      dsRead.pages.isWidgetPage(pageId) ||
      dsRead.appStudio.isBlocksComponentPage({
        id: pageId,
        type: 'DESKTOP',
      })
    );
  });

const getWidgetPointerByFSName = (dsRead, name) => {
  const id = trimJSSuffix(name);
  return dsRead.appStudio.widgets.getByRootCompId(id);
};

const setWidgetFunctions = (page, functions, dispatch, dsRead) => {
  const widgetPointer = getWidgetPointerByFSName(dsRead, page.name);
  if (widgetPointer) {
    dispatch(setFunctions(widgetPointer, functions));
  }
};

const setFunctions =
  (pointer, functions) =>
  (dispatch, getState, { dsActions }) => {
    dsActions.appStudio.widgets.functions.set(pointer, functions);
  };

const trimJSSuffix = (str) => {
  if (str.endsWith('.js')) {
    return str.substring(0, str.length - 3);
  }
  return str;
};

const stepTwo = () => (dispatch) =>
  Promise.all([
    dispatch(updateWidgetsStructureVersion()),
    dispatch(preBuild()),
  ]);

const idleTask = () => () => new Promise((res) => setTimeout(res, 10));

const preSaveStep = () => (dispatch) =>
  Promise.all([dispatch(parsePagesCode()), dispatch(preBuild())]);

const getAllWidgetPages = (dsRead) => {
  const pageIds = dsRead.pages.getPageIdList() || [];
  return pageIds
    .filter(dsRead.pages.isWidgetPage)
    .map((widgetPageId) => dsRead.components.get.byId(widgetPageId));
};

const preBuild =
  () =>
  (dispatch, getState, { dsActions }) => {
    const appDefinitionId = selectors.getAppDefId(getState());
    return new Promise((resolve, reject) =>
      dsActions.appStudio.preBuild(appDefinitionId, {
        onSuccess: resolve,
        onError: (e) => reject(new PackageError(e, PACKAGE_ERRORS.PREBUILD)),
      }),
    );
  };

const updateWidgetsStructureVersion =
  () =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const newVersion = getNewVersion(getState);
    const widgetPages = getAllWidgetPages(dsRead);
    const widgetRefs = widgetPages
      .map((widgetPageRef) =>
        dsRead.appStudio.widgets.getRootWidgetByPage(widgetPageRef),
      )
      .filter((widgetRefCandidate) => isAppWidget(widgetRefCandidate, dsRead));

    widgetRefs.forEach((widgetRef) =>
      dsActions.appStudio.widgets.updateConfig(widgetRef, {
        structureVersion: newVersion,
      }),
    );
  };

const serializeWidgetAPI =
  ({ widgetApi }) =>
  (dispatch, getState, { dsRead }) => {
    const widgetAPIDescriptor = _(widgetApi)
      .pick(['functions', 'events'])
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      .mapValues((property) => _.map(property, 'name'))
      .value();
    const propertiesNames = _.flatMap(widgetApi.propertiesSchemas, (property) =>
      Object.keys(property.structure || {}),
    );
    widgetAPIDescriptor.defaultValues = serializeDefaultValues(
      widgetApi.propertiesSchemas,
      dsRead,
    );
    return _.defaults({ properties: propertiesNames }, widgetAPIDescriptor);
  };

const serializeDefaultValues = (propertiesSchemas, dsRead) => {
  const customDefinitions = Object.assign(
    {},
    ...(dsRead.appStudio.definitions.getAllSerialized() || []),
  );
  const resolver = jsonSchemaUtils.createResolver(
    jsonSchemaUtils.baseDefinitions,
    customDefinitions,
  );

  const resolvedSchema = propertiesSchemas?.map((propSchema) =>
    resolver.resolve(propSchema.structure),
  );
  const defaultValues = _(resolvedSchema)
    .thru((resolvedProperty) => Object.assign({}, ...(resolvedProperty || [])))
    .mapValues('default')
    .value();

  return defaultValues;
};

const save =
  () =>
  (dispatch, getState, { editorAPI }) =>
    new Promise((res, reject) => {
      const onError = (e) => {
        dispatch(
          panelsActions.closePanelByNameAction('appStudio.panels.packagePanel'),
        );
        reject(new PackageError(e, PACKAGE_ERRORS.SAVE));
      };
      editorAPI.saveManager.saveInBackground(
        res,
        onError,
        'top_bar_publish',
        {},
      );
    });

const getNewVersion = (getState) => {
  const currAppVersion = selectors.getAppVersion(getState());
  return currAppVersion ? incMinorVersion(currAppVersion) : '0.1.0';
};

const build =
  (params: IBuildParams = initialBuildPrams) =>
  (dispatch, getState, { editorAPI }: { editorAPI: EditorAPI }) => {
    const buildVersionType = selectors.getCurrBuildVersion(getState());
    return editorAPI.savePublish
      .build(buildVersionType, params)
      .then(() => {
        updateIsFirstAppBuild(dispatch, getState(), buildVersionType);
        const newVersion = getNewVersion(getState);
        dispatch(setAppVersion(newVersion));
      })
      .catch((e) => {
        throw new PackageError(e, PACKAGE_ERRORS.BUILD_PACKAGE);
      });
  };

const publish =
  (params: IBuildParams = initialBuildPrams) =>
  (dispatch, getState, { editorAPI }: { editorAPI: EditorAPI }) =>
    !params.publishRc
      ? editorAPI.savePublish.publish().catch((e) => {
          throw new PackageError(e, PACKAGE_ERRORS.PUBLISH);
        })
      : undefined;

const setWixDataToken =
  () =>
  (dispatch, getState, { dsActions }) =>
    new Promise((res, rej) => {
      const onError = (e) => {
        rej(new PackageError(e, PACKAGE_ERRORS.COLLECTIONS));
      };
      const appVersion = selectors.getAppVersion(getState());
      const appDefId = selectors.getAppDefId(getState());
      const appName = selectors.getAppName(getState());
      dsActions.appStudio.setWixDataToken(
        appVersion,
        appDefId,
        appName,
        res,
        onError,
      );
    });

const incMinorVersion = (version) => {
  const MINOR = 1;
  const TEST = 2;

  const versionsArr = version.split('.');
  versionsArr[MINOR] = Number(versionsArr[MINOR]) + 1;
  versionsArr[TEST] = 0;

  return versionsArr.join('.');
};

const updateIsFirstAppBuild = (dispatch, state, currBuildVersion) => {
  if (selectors.isFirstAppBuild(state)) {
    if (
      currBuildVersion === BUILD_VERSION_TYPES.MINOR ||
      currBuildVersion === BUILD_VERSION_TYPES.MAJOR
    ) {
      dispatch(setIsFirstAppBuild(false));
    }
  }
};

const NEW_TASKS = [
  {
    performTask: idleTask,
    displayName: 'AppStudio_Publish_Popup_Loader_Text',
  },
  {
    performTask: preSaveStep,
    displayName: 'AppStudio_Publish_Popup_Loader_Text2',
  },
  {
    performTask: save,
    displayName: 'AppStudio_Publish_Popup_Loader_Text3',
  },
  {
    performTask: idleTask,
    displayName: 'AppStudio_Publish_Popup_Loader_Text6',
  },
  {
    taskWithParams: true,
    performTask: build,
    displayName: 'AppStudio_Publish_Popup_Loader_Text4',
  },
];

const APP_WITH_WIDGET_TASKS = [
  {
    performTask: parsePagesCode,
    displayName: 'AppStudio_Publish_Popup_Loader_Text',
  },
  {
    performTask: stepTwo,
    displayName: 'AppStudio_Publish_Popup_Loader_Text2',
  },
  {
    performTask: save,
    displayName: 'AppStudio_Publish_Popup_Loader_Text3',
  },
  {
    taskWithParams: true,
    performTask: publish,
    displayName: 'AppStudio_Publish_Popup_Loader_Text6',
  },
  {
    taskWithParams: true,
    performTask: build,
    displayName: 'AppStudio_Publish_Popup_Loader_Text4',
  },
  {
    performTask: setWixDataToken,
    displayName: 'AppStudio_Publish_Popup_Loader_Text5',
    condition: () => !!experiment.isOpen('se_appBuilderCollections'),
  },
];

const CODE_PACKAGE_TASKS = [
  {
    performTask: parsePagesCode,
    displayName: 'AppStudio_Packages_Progress_Bar_Step1',
  },
  {
    performTask: save,
    displayName: 'AppStudio_Packages_Progress_Bar_Step2',
  },
  {
    taskWithParams: true,
    performTask: publish,
    displayName: 'AppStudio_Packages_Progress_Bar_Step3',
  },
  {
    taskWithParams: true,
    performTask: build,
    displayName: 'AppStudio_Packages_Progress_Bar_Step3',
  },
];

function getTasksByParams(params) {
  if (experiment.isOpen('se_refactorBlocksBuild')) {
    return NEW_TASKS;
  }
  return params.isAppContainsWidgets
    ? APP_WITH_WIDGET_TASKS
    : CODE_PACKAGE_TASKS;
}

const getTasks = (
  params?: { isAppContainsWidgets: boolean } = { isAppContainsWidgets: true },
) =>
  _.reject(
    getTasksByParams(params),
    (task) => task.condition && !task.condition(),
  );

const setNumOfTasksDone = (numOfTasksDone) => ({
  type: actionTypes.SET_NUM_OF_TASKS_DONE,
  numOfTasksDone,
});

const setTotalNumOfPackageTasks = (totalNumOfPackageTasks) => ({
  type: actionTypes.SET_TOTAL_NUM_OF_PACKAGE_TASKS,
  totalNumOfPackageTasks,
});

const setCurrTaskDisplayName = (displayName) => ({
  type: actionTypes.SET_CURR_PACKAGE_TASK,
  displayName,
});

const setPackageErrorMessage = (errorMessage) => ({
  type: actionTypes.SET_PACKAGE_ERROR,
  errorMessage,
});

const setPackageError = (errorMessage) => (dispatch) => {
  dispatch(setPackageErrorMessage(errorMessage));
  dispatch(setPackageStep(PACKAGE_STEPS.ERROR));
};

const setPackageStep = (packageStep) => ({
  type: actionTypes.SET_PACKAGE_STEP,
  packageStep,
});

const setAppVersion = (appVersion) => ({
  type: actionTypes.SET_APP_VERSION,
  appVersion,
});

const setIsFirstAppBuild = (isFirstAppBuild) => ({
  type: actionTypes.SET_IS_FIRST_APP_BUILD,
  isFirstAppBuild,
});

const setCurrBuildVersion = (currBuildVersion) => ({
  type: actionTypes.SET_CURR_BUILD_VERSION,
  currBuildVersion,
});

const setIsPackageInProgress = (isPackageInProgress) => ({
  type: actionTypes.SET_IS_PACKAGING,
  isPackageInProgress,
});

const setBlocksVersion = (blocksVersion: string) => ({
  type: actionTypes.SET_BLOCKS_VERSION,
  blocksVersion,
});

export {
  setBlocksVersion,
  saveAndPackage,
  setAppVersion,
  setIsFirstAppBuild,
  serializeWidgetAPI,
  getTasks,
  parsePagesCode,
  build,
  runPackageLogic,
  save,
  setCurrBuildVersion,
  setCurrTaskDisplayName,
  setIsPackageInProgress,
  setNumOfTasksDone,
  setPackageError,
  setPackageErrorMessage,
  setPackageStep,
  setTotalNumOfPackageTasks,
};
