// @ts-nocheck
import _ from 'lodash';

function getLayoutSymmetry(layout) {
  return {
    horizontal:
      isHorizontalCentered(layout) || isHorizontalDockToScreen(layout),
    vertical: isVerticalCentered(layout),
  };
}

function isVerticalCentered(componentLayout) {
  return Boolean(
    componentLayout.docked?.vCenter || componentLayout.symmetry?.vertical,
  );
}

function isHorizontalCentered(componentLayout) {
  return Boolean(
    componentLayout.docked?.hCenter || componentLayout.symmetry?.horizontal,
  );
}

function isHorizontalDockToScreen(layout) {
  const isDockToScreenFromLeft = _.chain(layout)
    .get('docked.left.vw')
    .isFinite()
    .value();
  const isDockToScreenFromRight = _.chain(layout)
    .get('docked.right.vw')
    .isFinite()
    .value();

  return isDockToScreenFromLeft && isDockToScreenFromRight;
}

export { getLayoutSymmetry };
