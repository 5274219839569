import * as actionTypes from './mouseActionsActionTypes';

const setUsingWindowMouseEvents = (usingWindowMouseEvents: boolean) => ({
  type: actionTypes.SET_USING_WINDOW_MOUSE_EVENTS,
  usingWindowMouseEvents,
});

const setPerformingMouseAction = (performingMouseMoveAction: boolean) => ({
  type: actionTypes.SET_PERFORMING_MOUSE_ACTION,
  performingMouseMoveAction,
});

const setDragInProgress = (dragInProgress: boolean) => ({
  type: actionTypes.SET_DRAG_IN_PROGRESS,
  dragInProgress,
});

const setIsMouseOverStage = (isMouseOverStage: boolean) => ({
  type: actionTypes.SET_IS_MOUSE_OVER_STAGE,
  isMouseOverStage,
});

export {
  setUsingWindowMouseEvents,
  setPerformingMouseAction,
  setDragInProgress,
  setIsMouseOverStage,
};
