import _ from 'lodash';
import * as actionTypes from './googleMapActionTypes';

export const googleMapInitialState = {
  panel: 'single',
  locationIndex: 0,
};

const googleMapReducer = (
  googleMapState = googleMapInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_SINGLE_PANEL_STATE:
      return _.defaultsDeep(
        {
          panel: action.panel,
          locationIndex: action.locationIndex,
        },
        googleMapState,
      );
    case actionTypes.SET_LOCATION_INDEX:
      return _.defaultsDeep(
        {
          locationIndex: action.locationIndex,
        },
        googleMapState,
      );
    case actionTypes.SET_OLD_LOCATIONS_FOR_SESSION:
      return _.defaultsDeep(
        {
          [action.compId]: action.locations,
        },
        googleMapState,
      );
    default:
      return googleMapState;
  }
};

export default googleMapReducer;
