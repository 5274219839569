import * as actionTypes from './gridLinesActionTypes';
import type { GridLinesType } from '@/constants';

const setGridLinesOverrides = (
  gridLinesType: GridLinesType,
  overrides: unknown,
) => ({
  type: actionTypes.SET_GRID_LINES_OVERRIDES,
  gridLinesType,
  overrides,
});

export { setGridLinesOverrides };
