import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { sections } from '@/util';
import { layoutUtils } from '@/layoutUtils';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef, CompStructure, Rect } from 'types/documentServices';

type CompRefOrCompRefArray = CompRef | CompRef[];

interface AttachUtil {
  getContainer(editorAPI: EditorAPI, compRef: CompRefOrCompRefArray): CompRef;
  isContainable(
    editorAPI: EditorAPI,
    compRef: CompStructure | CompRefOrCompRefArray,
    parentRef: CompRef,
  ): boolean;
  canReparent(editorAPI: EditorAPI, compRef: CompRefOrCompRefArray): boolean;
  getCompLayout(editorAPI: EditorAPI, compRef: CompRefOrCompRefArray): Rect;
  isShowOnAllPages(
    editorAPI: EditorAPI,
    compRef: CompRefOrCompRefArray,
  ): boolean;
  isShowOnSomePages(
    editorAPI: EditorAPI,
    compRef: CompRefOrCompRefArray,
  ): boolean;
  isFullWidth(
    editorAPI: EditorAPI,
    compRef: CompStructure | CompRefOrCompRefArray,
  ): boolean;
}

const attachUtils = {
  comp: {
    getContainer(editorAPI, compRef) {
      return editorAPI.components.getContainer(compRef);
    },
    isContainable(editorAPI, compRef, parentRef) {
      return (
        editorAPI.components.is.containable(compRef, parentRef) &&
        !editorAPI.components.is.group(parentRef)
      );
    },
    canReparent(editorAPI, compRef) {
      return editorAPI.components.is.canReparent(compRef);
    },
    getCompLayout(editorAPI, compRef) {
      return editorAPI.components.layout.getRelativeToScreen(compRef).bounding;
    },
    isShowOnAllPages(editorAPI, compRef) {
      return editorAPI.components.isShowOnAllPages(compRef);
    },
    isShowOnSomePages(editorAPI, compRef) {
      return editorAPI.components.isShowOnSomePages(compRef);
    },
    isFullWidth(editorAPI, compRef) {
      return editorAPI.components.is.fullWidth(compRef);
    },
  } as AttachUtil,
  structure: {
    getContainer() {
      return {} as any;
    },
    isContainable(editorAPI, compStructure, parentRef) {
      return (
        editorAPI.components.is.containableByStructure(
          compStructure,
          parentRef,
        ) && !editorAPI.components.is.group(parentRef)
      );
    },
    canReparent() {
      return true;
    },
    isShowOnAllPages() {
      return false;
    },
    isShowOnSomePages() {
      return false;
    },
    isFullWidth(editorAPI, compStructure) {
      return editorAPI.components.is.fullWidthByStructure(
        compStructure as AnyFixMe,
      );
    },
  } as Omit<AttachUtil, 'getCompLayout'>,
};

const FORBIDDEN_CONTAINERS_IDS_TO_ATTACH_TO = ['PAGES_CONTAINER', 'SITE_PAGES'];

function isDraggingFromSiteStructureToPage(
  editorAPI: EditorAPI,
  currentContainer: CompRef,
  potentialContainer: CompRef,
) {
  return (
    !_.isEmpty(currentContainer) &&
    editorAPI.utils.isSiteStructure(currentContainer) &&
    editorAPI.utils.isPage(potentialContainer)
  );
}

function isDraggingFromPageToSiteStructure(
  editorAPI: EditorAPI,
  currentContainer: CompRef,
  potentialContainer: CompRef,
) {
  return (
    !_.isEmpty(currentContainer) &&
    !editorAPI.components.isShowOnAllPages(currentContainer) &&
    editorAPI.utils.isSiteStructure(potentialContainer)
  );
}

function isDraggingFromPageToSiteFooter(
  editorAPI: EditorAPI,
  currentContainer: CompRef,
  potentialContainer: CompRef,
) {
  return (
    !_.isEmpty(currentContainer) &&
    !editorAPI.components.isShowOnAllPages(currentContainer) &&
    editorAPI.components.getType(potentialContainer) ===
      'wysiwyg.viewer.components.FooterContainer'
  );
}

function isCompletelyContained(
  editorAPI: EditorAPI,
  containerComp: CompRef,
  layout: Rect,
  containerLayout: Rect,
) {
  const isContainerFullWidth = editorAPI.components.is.fullWidth(containerComp);
  const containerIsHeader = editorAPI.utils.isSameRef(
    containerComp,
    editorAPI.dsRead.siteSegments.getHeader(),
  );

  return (
    (isContainerFullWidth || layout.x >= containerLayout.x) &&
    (isContainerFullWidth ||
      layout.x + layout.width <= containerLayout.x + containerLayout.width) &&
    (containerIsHeader || layout.y >= containerLayout.y) &&
    layout.y + layout.height <= containerLayout.y + containerLayout.height
  );
}

function isMostlyContained(layout: Rect, containerLayout: Rect): Boolean {
  if (!layoutUtils.doBoxesOverlap(layout, containerLayout)) {
    return false;
  }

  const square = layout.width * layout.height;

  const crossWidth =
    Math.min(
      layout.x + layout.width,
      containerLayout.x + containerLayout.width,
    ) - Math.max(layout.x, containerLayout.x);

  const crossHeight =
    Math.min(
      layout.y + layout.height,
      containerLayout.y + containerLayout.height,
    ) - Math.max(layout.y, containerLayout.y);

  const crossingSquare = crossWidth * crossHeight;

  return crossingSquare / square > 0.6;
}

function getCompLayout(editorAPI: EditorAPI, compRef: CompRef) {
  return editorAPI.components.layout.getRelativeToScreen(compRef);
}

function canAttachToSiteStructureByDrag(
  editorAPI: EditorAPI,
  compRef: CompRefOrCompRefArray,
) {
  const isMobile = editorAPI.isMobileEditor();

  if (isMobile) {
    return true;
  }

  if (sections.isSectionsEnabled()) {
    const isShownOnAllPages = editorAPI.components.isShowOnAllPages(compRef);
    const isShownOnSomePages = editorAPI.components.isShowOnSomePages(compRef);

    return isShownOnAllPages || isShownOnSomePages;
  }

  return false;
}

function removeFooterComponents(editorAPI: EditorAPI, components: CompRef[]) {
  const footerComp = editorAPI.dsRead.siteSegments.getFooter();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _.filter(components, function (component) {
    const isDescendantOfFooter = editorAPI.components.isDescendantOfComp(
      component,
      footerComp,
    );
    const isFooter = editorAPI.utils.isSameRef(component, footerComp);

    if (sections.isSectionsEnabled()) {
      return !isDescendantOfFooter;
    }

    return !isFooter && !isDescendantOfFooter;
  });
}

function isDirectChildOfMasterPage(
  editorAPI: EditorAPI,
  compRef: CompRefOrCompRefArray,
) {
  const masterPage = editorAPI.siteSegments.getSiteStructure();

  return editorAPI.utils.isSameRef(
    editorAPI.components.getContainer(compRef),
    masterPage,
  );
}

export const pickSOAPCandidates = (
  editorAPI: EditorAPI,
  attachCandidates: CompRef[],
  componentsToAttach: CompRef[],
): CompRef[] => {
  const pagesContainer = editorAPI.siteSegments.getPagesContainer();
  const masterPage = editorAPI.siteSegments.getSiteStructure();

  const isMobileOnlyComponent =
    editorAPI.dsRead.mobile?.mobileOnlyComponents?.isMobileOnlyComponent(
      componentsToAttach[0].id,
    );
  const isHeaderOrFooterChild =
    editorAPI.components.isDescendantOfComp(
      componentsToAttach,
      editorAPI.dsRead.siteSegments.getHeader(),
    ) ||
    editorAPI.components.isDescendantOfComp(
      componentsToAttach,
      editorAPI.dsRead.siteSegments.getFooter(),
    );

  const createIsCompOrItsChild = (possibleParent: CompRef) => (comp: CompRef) =>
    comp.id === possibleParent.id ||
    editorAPI.components.isDescendantOfComp(comp, possibleParent);

  const isMasterPageOrItsChild = createIsCompOrItsChild(masterPage);
  const isPagesContainerOrItsChild = createIsCompOrItsChild(pagesContainer);

  return attachCandidates.filter((attachCandidate) => {
    if (isPagesContainerOrItsChild(attachCandidate)) {
      return false;
    }

    // when dragging component out for header/child, you should be allowed to attach to an any available component
    if (isHeaderOrFooterChild) {
      return true;
    }

    if (editorAPI.isMobileEditor() && !isMobileOnlyComponent) {
      return false;
    }

    return isMasterPageOrItsChild(attachCandidate);
  });
};

function isMenuContainerFocused(editorAPI: EditorAPI) {
  const focusedContainer =
    stateManagement.selection.selectors.getFocusedContainer(
      editorAPI.store.getState(),
    );
  return (
    editorAPI.components.getType(focusedContainer) ===
    constants.COMP_TYPES.MENU_CONTAINER
  );
}

export const isCandidatesChildrenOfCurrentPopupContainer = (
  editorAPI: EditorAPI,
  compRef: CompRef[],
): boolean => {
  const currentPopupContainer = editorAPI.pages.popupPages.getCurrentPopup();
  return (
    currentPopupContainer &&
    compRef.some((ref) =>
      editorAPI.components.isDescendantOfComp(ref, currentPopupContainer),
    )
  );
};

export {
  attachUtils,
  isDraggingFromSiteStructureToPage,
  isDraggingFromPageToSiteStructure,
  isDraggingFromPageToSiteFooter,
  isCompletelyContained,
  isMostlyContained,
  canAttachToSiteStructureByDrag,
  getCompLayout,
  removeFooterComponents,
  isDirectChildOfMasterPage,
  isMenuContainerFocused,
  FORBIDDEN_CONTAINERS_IDS_TO_ATTACH_TO,
};
