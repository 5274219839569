// @ts-nocheck
import _ from 'lodash';
import * as componentsSelectors from '../components/componentsSelectors';

function getSessionStateTitle(dsRead, statePointer) {
  return dsRead && dsRead.platform.sessionState.get(statePointer);
}

function getPrimaryRole(dsRead, statePointer) {
  return dsRead.platform.controllers.connections.getPrimaryConnection(
    statePointer,
  )?.role;
}

function getTitle(dsRead, statePointer) {
  return (
    getSessionStateTitle(dsRead, statePointer) ||
    getPrimaryRole(dsRead, statePointer)
  );
}

function hasTitle(dsRead, statePointer) {
  return !!getTitle(dsRead, statePointer);
}

function getTitleFromManifest(dsRead, statePointer) {
  const connection = _.head(
    dsRead.platform.controllers.connections.get(statePointer),
  );
  if (connection) {
    const { controllerRef, role } = connection;
    const stageDataByRole = dsRead.platform.controllers.getStageDataByRole(
      controllerRef,
      role,
    );
    return stageDataByRole?.displayName;
  }
  return undefined;
}

function getStateTitle(dsRead, stateBoxPointer, statePointer) {
  return (
    getTitleFromManifest(dsRead, statePointer) ||
    getTitle(dsRead, statePointer) ||
    componentsSelectors.getNickname(statePointer, dsRead)
  );
}

const isRepeaterStateAllowed = (state) => state.stateBox.isRepeaterStateAllowed;

export { isRepeaterStateAllowed, hasTitle, getStateTitle };
