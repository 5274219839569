// @ts-nocheck
import layersActionTypes from './layersActionTypes';

const { SET_SPOTLIGHT } = layersActionTypes;

const setSpotlight = (component) => ({
  type: SET_SPOTLIGHT,
  component,
});

export { setSpotlight };
