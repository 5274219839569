import type { EditorState } from '@/stateManagement';
import type {
  CurrentTpaInnerRoutesInfo,
  IgnoreSubPagesFeature,
  TpaInnerRouteData,
} from './tpaDynamicPagesReducer';
import type { HideSubPagesSettings } from '@wix/platform-editor-sdk';

const getSelectedRouteInfo = (state: EditorState): TpaInnerRouteData =>
  state.tpaDynamicPages.selectedInnerRoute;

const getCurrentRouteInfo = (state: EditorState): CurrentTpaInnerRoutesInfo =>
  state.tpaDynamicPages.currentTpaInfo;

const getInnerRoutesCounts = (
  state: EditorState,
  appDefinitionId: string,
  pageId: string,
) => state.tpaDynamicPages.innerRoutesCounts?.[appDefinitionId]?.[pageId];

const getAllSubPagesInnerRoutes = (
  state: Partial<EditorState>,
  appDefinitionId: string,
  pageId: string,
): TpaInnerRouteData[] =>
  Object.values(
    state.tpaDynamicPages.cachedInnerRoutes[appDefinitionId]?.pages?.[pageId] ||
      {},
  )
    .filter((subPageInnerRoutes) => !!subPageInnerRoutes)
    .flat(1);

const getFocusedSubPages = (
  state: EditorState,
  appDefinitionId: string,
  pageId: string,
  feature: IgnoreSubPagesFeature,
): string[] => {
  const allSubPages = Object.keys(
    state.tpaDynamicPages.cachedInnerRoutes?.[appDefinitionId]?.pages?.[
      pageId
    ] ?? {},
  );
  const disabledSubPagesInFeature =
    state.tpaDynamicPages.cachedInnerRoutes?.[appDefinitionId]
      ?.subPagesToIgnore?.[feature] ?? [];
  return allSubPages.filter(
    (subPage) => !disabledSubPagesInFeature.includes(subPage),
  );
};

const getFocusedInnerRoutes = (
  state: EditorState,
  appDefinitionId: string,
  pageId: string,
  feature: IgnoreSubPagesFeature,
): TpaInnerRouteData[] => {
  const featureDefinition =
    state.tpaDynamicPages.cachedInnerRoutes[appDefinitionId]
      ?.subPagesToIgnore?.[feature];
  if (featureDefinition && featureDefinition.length > 0) {
    return Object.entries(
      state.tpaDynamicPages.cachedInnerRoutes?.[appDefinitionId]?.pages?.[
        pageId
      ] ?? [],
    ).reduce(
      (
        acc: TpaInnerRouteData[],
        [subPage, innerRoutes]: [string, TpaInnerRouteData[]],
      ) => {
        if (!featureDefinition.includes(subPage) && innerRoutes?.length > 0) {
          acc = acc.concat(innerRoutes);
        }
        return acc;
      },
      [],
    );
  }
  return getAllSubPagesInnerRoutes(state, appDefinitionId, pageId);
};

const getSubPageInnerRoute = (
  state: EditorState,
  appDefinitionId: string,
  pageId: string,
  subPage: string,
) =>
  state.tpaDynamicPages.cachedInnerRoutes?.[appDefinitionId]?.pages?.[pageId]?.[
    subPage
  ];

const getSubPagesFailedToFetch = (
  state: EditorState,
  appDefinitionId: string,
  pageId: string,
): string[] =>
  state.tpaDynamicPages.cachedInnerRoutes[appDefinitionId]?.pages?.[pageId]
    ? Object.entries(
        state.tpaDynamicPages.cachedInnerRoutes[appDefinitionId]?.pages?.[
          pageId
        ],
      ).reduce((acc, [key, value]) => {
        if (!value) {
          acc.push(key);
        }
        return acc;
      }, [])
    : null;

const getSubPagesToIgnore = (
  state: EditorState,
  appDefinitionId: string,
): HideSubPagesSettings =>
  state.tpaDynamicPages.cachedInnerRoutes[appDefinitionId]?.subPagesToIgnore;
const isInFetchProcess = (state: EditorState) =>
  state.tpaDynamicPages.inInnerRoutesFetchProcess;

const getCurrentPageSessionId = (state: EditorState) =>
  state.tpaDynamicPages.currentPageSessionId;

export {
  getSelectedRouteInfo,
  getCurrentRouteInfo,
  getAllSubPagesInnerRoutes,
  getSubPagesFailedToFetch,
  getInnerRoutesCounts,
  getFocusedSubPages,
  getFocusedInnerRoutes,
  getSubPageInnerRoute,
  isInFetchProcess,
  getCurrentPageSessionId,
  getSubPagesToIgnore,
};
