import { isFixedItemLayout } from './responsiveLayoutUtils';
import type {
  ComponentLayout,
  FixedItemLayout,
  SingleLayoutData,
} from 'types/documentServices';

function getFullHeightFromLayoutsRaw(layouts: SingleLayoutData) {
  if (!layouts?.componentLayout || !layouts?.itemLayout) {
    return undefined;
  }

  if (
    layouts.componentLayout.height !== undefined ||
    layouts.componentLayout.minHeight !== undefined ||
    !isFixedItemLayout(layouts.itemLayout)
  ) {
    return {
      isFullHeight: false,
    };
  }

  const { top, bottom } = layouts.itemLayout.margins;

  if (top !== undefined && bottom !== undefined) {
    return {
      isFullHeight: true,
      top,
      bottom,
    };
  }
}

export function isFullHeightByLayouts(
  layouts: SingleLayoutData,
): layouts is SingleLayoutData & {
  componentLayout: ComponentLayout & {
    height: undefined;
    minHeight: undefined;
  };
  itemLayout: FixedItemLayout;
} {
  const fullHeight = getFullHeightFromLayoutsRaw(layouts);
  return fullHeight?.isFullHeight;
}
