import { RelatedAppsActionTypes } from './relatedAppsActionTypes';
import { fedopsLogger } from '@/util';

const setRelatedAppsAction = (relatedApps: AnyFixMe) => ({
  type: RelatedAppsActionTypes.SetRelatedApps,
  payload: relatedApps,
});

const startRelatedAppsLoadingAction = () => ({
  type: RelatedAppsActionTypes.StartRelatedAppsLoading,
});

export const fetchRelatedApps =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { dsRead }: AnyFixMe) => {
    dispatch(startRelatedAppsLoadingAction());

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.ADD_PANEL.RELATED_APPS_LOAD,
    );

    dsRead.tpa.appMarket.getRelatedApps((relatedApps: AnyFixMe) => {
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.ADD_PANEL.RELATED_APPS_LOAD,
      );

      return dispatch(setRelatedAppsAction(relatedApps));
    });
  };
