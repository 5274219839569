import _ from 'lodash';
import constants from '@/constants';
import * as panelsActions from '../panels/panelsActions';
import * as actionTypes from './pinModeActionTypes';
import * as pinModeSelectors from './pinModeSelectors';
import * as selection from '../selection/selection';
import type React from 'react';
import type { ThunkAction } from 'types/redux';
import type { CompRef } from 'types/documentServices';

const selectionSelectors = selection.selectors;

const panelNameByType = {
  default: 'compPanels.dynamicPanels.pinnedDockPanel',
  PopupContainer:
    constants.COMP_PANELS.POPUP.CONTAINER.POPUP_GRID_LAYOUT_PANEL.NAME,
} as const;

const openPinMode = () => ({
  type: actionTypes.OPEN_PIN_MODE,
});

const closePinMode = () => ({
  type: actionTypes.CLOSE_PIN_MODE,
});

const showPinPreviewRect = (style: React.CSSProperties) => ({
  type: actionTypes.SHOW_PIN_PREVIEW_RECT,
  style,
});

const hidePinPreviewRect = () => ({
  type: actionTypes.HIDE_COMPONENT_PREVIEW_RECT,
});

const blockingLayerStyle = constants.EDITOR_API.BLOCKING_LAYER_STYLE;

const getPanelNameByType = (compType: string) =>
  panelNameByType[compType as keyof typeof panelNameByType] ||
  panelNameByType.default;

const showBlockingLayer =
  (): ThunkAction =>
  (dispatch, getState, { dsActions }) => {
    const state = getState();
    dsActions.renderPlugins.setCompsToShowOnTop(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(selectionSelectors.getSelectedCompsRefs(state), 'id'),
    );
    dsActions.renderPlugins.setBlockingLayer(blockingLayerStyle);
  };

const hideBlockingLayer =
  (): ThunkAction =>
  (dispatch, getState, { dsActions }) => {
    dsActions.renderPlugins.setCompsToShowOnTop(null);
    dsActions.renderPlugins.setBlockingLayer(null);
  };

const open =
  (
    origin: string,
    props?: {
      selectedComponents?: CompRef[];
    },
  ): ThunkAction =>
  (dispatch, getState, { dsRead, tooltipManager }) => {
    const editorState = getState();
    const compType = selectionSelectors.getSelectedComponentType(
      editorState,
      dsRead,
    );
    const disabledValues =
      compType === 'WRichText' ? ['LEFT', 'MIDDLE', 'RIGHT'] : ['MIDDLE'];

    tooltipManager.hide(
      constants.ROOT_COMPS.TOOLTIPS.FIRST_TIME_EXIT_POPUP_MODE,
    );
    const selectedComponents =
      props?.selectedComponents ||
      selectionSelectors.getSelectedCompsRefs(editorState);
    const updatedProps = Object.assign(
      {
        origin,
        selectedComponents,
        disabledValues,
      },
      props,
    );

    dispatch(
      panelsActions.openComponentPanel(
        getPanelNameByType(compType),
        updatedProps,
      ),
    );

    dispatch(showBlockingLayer());
    dispatch(openPinMode());
  };

const close = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (pinModeSelectors.isOpen(state)) {
    const compType = pinModeSelectors.getPinnedCompType(state);
    dispatch(panelsActions.closePanelByName(getPanelNameByType(compType)));

    dispatch(hidePinPreviewRect());
    dispatch(hideBlockingLayer());
    dispatch(closePinMode());
  }
};

export { open, close, showPinPreviewRect, hidePinPreviewRect };
