export default {
  SELECT_COMPONENTS: 'selectComponents',
  ADD_TO_SELECTION: 'addToSelection',
  REMOVE_FROM_SELECTION: 'removeFromSelection',
  CLEAR_SELECTED_COMPONENTS: 'clearSelectedComponents',
  SET_SELECTED_COMPS_RESTRICTIONS: 'setSelectedCompsRestrictions',
  SET_LAST_SELECTION_CLICK_POS: 'setLastSelectionClickPos',
  SET_FOCUSED_CONTAINER: 'setFocusedContainer',
  SET_SELECTED_COMPS_DISPLAY_NAME: 'setSelectedCompsDisplayName',
  SET_APP_CONTAINER: 'setAppContainer',
  SET_FOCUSED_TABS_DEF: 'setFocusedTabsDefs',
  SET_ANCESTOR_OF_SELECTED_WITH_INTERACTION:
    'SET_ANCESTOR_OF_SELECTED_WITH_INTERACTION',
  SET_LAST_CLICK_TYPE: 'setLastClickType',
  SET_PREV_SELECTED_COMPS: 'SET_PREV_SELECTED_COMPS',
};
