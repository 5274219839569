import _ from 'lodash';
import { mobileInitialState } from '../initialState';
import * as actionTypes from './mobileWizardActionTypes';

const mobileWizardTinyMenuReducer = (
  tinyMenu = mobileInitialState.mobileWizard.tinyMenu,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_TINY_MENU_AVAILABLE_SKINS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, tinyMenu, { availableSkins: action.availableSkins });
    default:
      return tinyMenu;
  }
};

export default mobileWizardTinyMenuReducer;
