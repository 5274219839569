import constants from '@/constants';
import type { HiddenItemData } from '@/mobileEditor';
import { mobileKeyboardInitialState } from './mobileKeyboard/mobileKeyboardReducer';

export const mobileInitialState = {
  introsEnabled: true,
  didVisitMobileEditorInCurrentSession: false,
  qab: {
    enabled: true,
  },
  isHiddenItemsCounterVisible: true,
  reAddedHiddenElement: {
    compRef: null as AnyFixMe,
    addingPhase: constants.LEFT_BAR.MOBILE_HIDDEN_ELEMENTS.PROGRESS.IDLE,
  },
  hiddenItems: {
    isAddingHiddenItemInProgress: false,
    hoveredComponent: {} as any,
    masterPageHiddenComponents: [] as AnyFixMe,
    currentPageHiddenComponents: [] as AnyFixMe,
    isAddingInteractionsHiddenItemInProgress: false,
    interactionsHiddenComponents: [] as HiddenItemData[],
  },
  mobileWizard: {
    enabled: false,
    openingState: constants.MOBILE.MOBILE_WIZARD.OPENING_STATE.BEFORE,
    currentStep: '',
    stepsToShow: [] as AnyFixMe,
    userSelections: {
      tinyMenu: undefined as AnyFixMe,
      header: undefined as AnyFixMe,
      qab: undefined as AnyFixMe,
      backToTop: undefined as AnyFixMe,
    },
    lastSaved: {
      tinyMenu: undefined as AnyFixMe,
      header: undefined as AnyFixMe,
      qab: undefined as AnyFixMe,
      backToTop: undefined as AnyFixMe,
    },
    tinyMenu: {
      availableSkins: [] as AnyFixMe,
    },
    header: {
      animationInProgress: false,
    },
    qab: {
      availablePresets: [] as AnyFixMe,
    },
  },
  mobileKeyboard: mobileKeyboardInitialState,
};
