import _ from 'lodash';
import * as actionTypes from './unifiedChatActionTypes';

const { SET_CHAT_IS_LOADED, SET_CHAT_VISIBILITY } = actionTypes;

export const unifiedChatInitialState = {
  isChatLoaded: false,
  isVisible: false,
};

const unifiedChatReducer = (
  unifiedChatState = unifiedChatInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case SET_CHAT_IS_LOADED:
      return _.defaults(
        {
          isChatLoaded: true,
        },
        unifiedChatState,
      );
    case SET_CHAT_VISIBILITY:
      return _.defaults(
        {
          isVisible: action.isVisible,
        },
        unifiedChatState,
      );
    default:
      return unifiedChatState;
  }
};

export default unifiedChatReducer;
