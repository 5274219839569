import _ from 'lodash';
import * as appsStoreActionTypes from './appsStoreActionTypes';

const { UPDATE_APP_STORE } = appsStoreActionTypes;

export const appsStoreInitialState = {};

const getMutatedStore = (
  appsStore: AnyFixMe,
  { appDefinitionId, partialState }: AnyFixMe,
) =>
  _.defaults(
    {
      [appDefinitionId]: _.defaults(
        partialState,
        _.get(appsStore, appDefinitionId),
      ),
    },
    appsStore,
  );

const reducer = (appsStore = appsStoreInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case UPDATE_APP_STORE:
      return getMutatedStore(appsStore, action);

    default:
      return appsStore;
  }
};

export default reducer;
