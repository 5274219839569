// @ts-nocheck
import * as actionTypes from './componentsStoreActionTypes';

const updateComponentStore = (comp, key, value) => ({
  type: actionTypes.UPDATE_COMPONENT_STORE,
  comp,
  key,
  value,
});
const removeComponentsStore = (comps) => ({
  type: actionTypes.REMOVE_COMPONENTS_STORE,
  comps,
});

export { updateComponentStore, removeComponentsStore };
