// @ts-nocheck
import * as tpa from '../tpa/tpa';
import * as platform from '../platform/platform';

const onFocus =
  () =>
  (dispatch, getState, { dsRead }) => {
    // during editor load dsRead is undefined, but focus event can occur
    if (dsRead) {
      dispatch(tpa.actions.updateUpgradedApplications());
      dispatch(platform.actions.fetchFreshAppsData());
    }
  };

export { onFocus };
