import type {
  CompRef,
  InteractionType,
  CompVariantPointer,
} from 'types/documentServices';
import { interactionsActionTypes } from './interactionsActionTypes';

export interface InteractionsState {
  isInteractionMode?: boolean;
  interactionName?: InteractionType;
  triggerRef?: CompRef;
  compVariantPointer?: CompVariantPointer;
  addPanel?: null | any;
  leftBar?: null | any;
  isPlaying?: boolean;
  interactionDuration?: number;
  showInteractionModeControls?: boolean;
}

export const interactionsInitialState: InteractionsState = {
  isInteractionMode: false,
  interactionName: null,
  triggerRef: null,
  compVariantPointer: null,
  isPlaying: false,
  interactionDuration: 0,
  showInteractionModeControls: false,
};

export const interactionsReducer = (
  interactions: InteractionsState = interactionsInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case interactionsActionTypes.PLAY_INTERACTION:
      return {
        ...interactions,
        isPlaying: true,
      };
    case interactionsActionTypes.STOP_PLAYING_INTERACTION:
      return {
        ...interactions,
        isPlaying: false,
      };
    case interactionsActionTypes.UPDATE_INTERACTION_TRANSITIONS:
      return {
        ...interactions,
        interactionDuration: action.payload.interactionDuration,
      };
    case interactionsActionTypes.ENTER_INTERACTION:
      return {
        ...interactions,
        isInteractionMode: true,
        interactionName: action.payload.interactionName,
        triggerRef: action.payload.triggerRef,
        compVariantPointer: action.payload.compVariantPointer,
        interactionDuration: action.payload.interactionDuration,
        showInteractionModeControls: action.payload.showInteractionModeControls,
      };
    case interactionsActionTypes.CREATE_INTERACTION:
      return {
        ...interactions,
        compVariantPointer: action.payload.compVariantPointer,
      };
    case interactionsActionTypes.UPDATE_INTERACTION_SELECTED_COMPONENT:
      return {
        ...interactions,
        compVariantPointer: action.payload.compVariantPointer,
      };
    case interactionsActionTypes.EXIT_INTERACTION:
      return interactionsInitialState;
    default:
      return interactions;
  }
};
