// @ts-nocheck
import * as util from '@/util';

const TITLE_LENGTH_LIMIT = 100;

export default function (data) {
  if (data.title) {
    data.title = util.media.fixMediaTitleLength(data.title, TITLE_LENGTH_LIMIT);
  }
  return data;
}
