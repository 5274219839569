export default {
  ADD_APP_IDS_TO_REFRESH_ON_DASHBOARD_CLOSE:
    'ADD_APP_IDS_TO_REFRESH_ON_DASHBOARD_CLOSE',
  SET_APP_IDS_TO_REFRESH_ON_DASHBOARD_CLOSE:
    'SET_APP_IDS_TO_REFRESH_ON_DASHBOARD_CLOSE',
  ADD_APP_TO_TRACK_FOR_UPGRADE: 'ADD_APP_TO_TRACK_FOR_UPGRADE',
  SET_TPA_SETTINGS_PANEL_EXTRA_PROPS: 'SET_TPA_SETTINGS_PANEL_EXTRA_PROPS',
  REMOVE_APP_FROM_TRACK_FOR_UPGRADE: 'REMOVE_APP_FROM_TRACK_FOR_UPGRADE',
  SHOULD_REFRESH_GENERAL_INFO: 'SHOULD_REFRESH_GENERAL_INFO',
};
