import { arrayUtils } from '@/util';
import {
  dsOneDockAdapter,
  isMeshLayoutEnabled,
} from '@/layoutOneDockMigration';
import {
  shouldShowNotificationFreezeWarning,
  showNotificationFreezeWarning,
} from './showNotificationFreezeWarning';
import { setFixedPosition } from './fixedPositionPlugins/fixedPositionPlugins';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { LayoutGetApi } from '../layoutGetApi';

export function createLayoutFixedPositionApi({
  editorAPI,
  layoutGetApi,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
}) {
  function isShowOnFixedPosition(compRefOrRefs: CompRef | CompRef[]) {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    if (isMeshLayoutEnabled()) {
      const adapter = dsOneDockAdapter(editorAPI.dsRead);
      return compRefs.every((compRef) =>
        adapter.layout.isShowOnFixedPosition(compRef),
      );
    }

    return compRefs.every((compRef) =>
      editorAPI.dsRead.components.layout.isShowOnFixedPosition(compRef),
    );
  }

  function canBeFixedPosition(compRefOrRefs: CompRef | CompRef[]): boolean {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    // NOTE: checks from the metadata
    // https://github.com/wix-private/document-management/blob/b30cc2ea84908fdde8bbb2ceecbafc08992c9dfb/document-services-implementation/src/componentsMetaData/componentsMetaData.ts#L1054-L1057
    return compRefs.every(
      editorAPI.dsRead.components.layout.canBeFixedPosition,
    );
  }

  function setFixed(
    compRefOrRefs: CompRef | CompRef[],
    newValue: boolean,
  ): boolean | boolean[] {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    const resArr = compRefs.map((compRef) =>
      setFixedPosition(editorAPI, compRef, newValue),
    );

    return compRefs.length > 1 ? resArr : resArr[0];
  }

  function toggleFixedPosition(compRefOrRefs: CompRef | CompRef[]) {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    if (compRefs.length === 0 || !canBeFixedPosition(compRefs)) {
      return;
    }

    const layout = layoutGetApi.get(compRefs);
    const newValue = !layout.fixedPosition;

    setFixed(compRefs, newValue);

    if (shouldShowNotificationFreezeWarning(compRefs[0].id, layout)) {
      showNotificationFreezeWarning(editorAPI);
    }
  }

  return {
    isShowOnFixedPosition,
    canBeFixedPosition,
    setFixed,
    toggleFixedPosition,
  };
}

export type LayoutFixedPositionApi = ReturnType<
  typeof createLayoutFixedPositionApi
>;
