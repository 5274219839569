import _ from 'lodash';
import * as actionTypes from './rightClickMenuActionTypes';

export const rightClickMenuInitialState = {
  overridden: {
    addToSavedComponents: { isHidden: false },
    copyDesign: { isHidden: false },
  },
};

const reducer = (
  rightClickMenu = rightClickMenuInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.OVERRIDE_RIGHT_CLICK_MENU:
      return _.defaultsDeep({ overridden: action.overrides }, rightClickMenu);
    default:
      return rightClickMenu;
  }
};

export default reducer;
