// @ts-nocheck
import imageDataPlugin from './plugins/imageData';
import vectorImageDataPlugin from './plugins/vectorImageData';
import videoDataPlugin from './plugins/wixVideoData';

export {
  imageDataPlugin as Image,
  vectorImageDataPlugin as VectorImage,
  videoDataPlugin as WixVideo,
};
