import {
  layoutGetRotationInDegrees,
  layoutIsRotated,
  layoutGetSize,
  layoutGetSizeRelativeToScreen,
  layoutGetFixedPosition,
  layoutGetFontScale,
  layoutGetDocked,
  layoutIsDocked,
  layoutIsDockedToScreen,
  layoutIsHorizontallyStretchedToScreen,
  layoutGetPositionRelativeToScreen,
  layoutGetPositionRelativeToStructure,
  layoutSetDock,
  layoutUpdateDock,
  type GetLayoutFromDOMContext,
  layoutIsShowOnFixedPosition,
} from './adapter/layout';
import { propertiesGet } from './adapter/properties';

import type {
  CompRef,
  DSAction,
  DSRead,
  Docking,
} from 'types/documentServices';

export function dsOneDockAdapter(dsRead: DSRead) {
  return {
    layout: {
      get_rotationInDegrees: (compRef: CompRef) =>
        layoutGetRotationInDegrees(dsRead, compRef),
      get_size: (compRef: CompRef) => layoutGetSize(dsRead, compRef),
      get_fixedPosition: (compRef: CompRef) =>
        layoutGetFixedPosition(dsRead, compRef),
      isFixedPosition: (compRef: CompRef) =>
        layoutGetFixedPosition(dsRead, compRef),
      get_scale: (compRef: CompRef) => layoutGetFontScale(dsRead, compRef),
      getRelativeToStructure_rect: (
        compRef: CompRef,
        context: GetLayoutFromDOMContext,
      ) => ({
        ...layoutGetPositionRelativeToStructure(dsRead, compRef, context),
        ...layoutGetSize(dsRead, compRef),
      }),
      getRelativeToScreen_rect: (
        compRef: CompRef,
        context: GetLayoutFromDOMContext,
      ) => ({
        ...layoutGetPositionRelativeToScreen(dsRead, compRef, context),
        ...layoutGetSizeRelativeToScreen(dsRead, compRef, context),
      }),
      isRotated: (compRef: CompRef) => layoutIsRotated(dsRead, compRef),
      getDock: (compRef: CompRef) => layoutGetDocked(dsRead, compRef),
      isDocked: (compRef: CompRef) => layoutIsDocked(dsRead, compRef),
      isDockedToScreen: (compRef: CompRef) =>
        layoutIsDockedToScreen(dsRead, compRef),
      isHorizontallyStretchedToScreen: (compRef: CompRef) =>
        layoutIsHorizontallyStretchedToScreen(dsRead, compRef),
      isShowOnFixedPosition: (compRef: CompRef) =>
        layoutIsShowOnFixedPosition(dsRead, compRef),
    },
    properties: {
      get: (compRef: CompRef) => propertiesGet(dsRead, compRef),
    },
  };
}

interface DSContext {
  dsRead: DSRead;
  dsActions: DSAction;
}

export function dsActionsOneDockAdapter(context: DSContext) {
  return {
    layout: {
      setDock: (compRef: CompRef, docking: Docking) =>
        layoutSetDock(context, compRef, docking),
      updateDock: (compRef: CompRef, docking: Docking) =>
        layoutUpdateDock(context, compRef, docking),
    },
  };
}
