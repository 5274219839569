import * as actions from './panelsActions';
import reducer from './panelsReducer';
import * as selectors from './panelsSelectors';
import panelFrame from './panelFrame';
import panelDragLimits from './panelDragLimits';
import { registerNewAddPanelPropsTransformers } from './panelPropsTransformers';
import panelsSectionsDefinitionsDefaults from './panelsSectionsDefinitionsDefaults';
export type { PanelSectionDefinition } from './panelsSectionsDefinitionsDefaults';

const hoc = {
  panelFrame,
  panelDragLimits,
};

const panelsPropsTransformers = { registerNewAddPanelPropsTransformers };

export {
  actions,
  reducer,
  selectors,
  hoc,
  panelsPropsTransformers,
  panelsSectionsDefinitionsDefaults,
};
