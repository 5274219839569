import {
  EditorLayoutConstraintsUtil,
  type LayoutConstraintArea,
} from '@/layoutUtils';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export default class DragConstraintsHandler {
  private editorAPI: EditorAPI;
  private constraintArea: LayoutConstraintArea[];

  constructor(editorAPI: EditorAPI, compRef: CompRef) {
    if (!editorAPI) {
      return;
    }

    this.editorAPI = editorAPI;

    const editorLayoutConstraintsUtil = new EditorLayoutConstraintsUtil(
      editorAPI,
      compRef,
    );

    this.constraintArea = editorLayoutConstraintsUtil.getConstraintArea(
      editorAPI,
      compRef,
    );
  }

  highlightDragConstraints() {
    this.editorAPI.setConstraintArea(this.constraintArea);
  }

  removeHighlightDragConstraints() {
    this.editorAPI.setConstraintArea(null);
  }
}
