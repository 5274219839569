import _ from 'lodash';
import type React from 'react';
import * as actionTypes from './pinModeActionTypes';

export interface PinModeState {
  pinnedCompType?: AnyFixMe; // not sure if still exist
  pinPreviewRectStyle: React.CSSProperties;
  isOpen: boolean;
}

export const pinModeInitialState: PinModeState = {
  isOpen: false,
  pinPreviewRectStyle: { opacity: 0 },
};

const pinModeReducer = (
  pinModeState: PinModeState = pinModeInitialState,
  action: AnyFixMe,
): PinModeState => {
  switch (action.type) {
    case actionTypes.OPEN_PIN_MODE:
      return _.defaults(
        {
          isOpen: true,
        },
        pinModeState,
      );

    case actionTypes.CLOSE_PIN_MODE:
      return _.defaults(
        {
          isOpen: false,
        },
        pinModeState,
      );

    case actionTypes.SHOW_PIN_PREVIEW_RECT:
      return _.defaults(
        {
          pinPreviewRectStyle: action.style,
        },
        pinModeState,
      );

    case actionTypes.HIDE_COMPONENT_PREVIEW_RECT:
      const updatedStyle = _.defaults(
        { opacity: 0 },
        pinModeState.pinPreviewRectStyle,
      );
      return _.defaults({ pinPreviewRectStyle: updatedStyle }, pinModeState);

    default:
      return pinModeState;
  }
};

export default pinModeReducer;
