import _ from 'lodash';
import * as actionTypes from './userPreferencesActionTypes';

const {
  SET_SITE_ID,
  SET_PREFERENCES,
  SET_PREFERENCE,
  MARK_SITE_PREFERENCES_NOT_DIRTY,
  DONT_SAVE_USER_PREFERENCES,
  SET_GLOBAL_PREF_FETCH_STATUS,
  SET_SITE_PREF_FETCH_STATUS,
  REGISTER_SITE_PREF_CB,
  RESET_REGISTER_SITE_PREF_CB_ARRAY,
  SITE_PREF_CALL_DONE,
} = actionTypes;

export interface UserPreferencesValueWrapper<TValue = unknown> {
  isDirty: boolean;
  value: TValue;
}

export type UserPreferencesType = 'global' | 'session' | 'site';

export type UserPreferencesGlobal = Record<string, unknown>;
export type UserPreferencesSite = Record<string, UserPreferencesValueWrapper>;
export type UserPreferencesSession = Record<string, unknown>;
export type UserPreferences =
  | UserPreferencesGlobal
  | UserPreferencesSite
  | UserPreferencesSession;

export interface UserPreferencesState {
  siteId: string;
  globalPrefFetchStatus: boolean;
  sitePrefFetchStatus: boolean;
  sitePreferencesFetchDone: boolean;
  sitePreferencesFetchCallbacks: ((status: boolean) => void)[];
  dontSaveUserPrefs: boolean;
  global: UserPreferencesGlobal;
  session: UserPreferencesSession;
  site: UserPreferencesSite;
}

export const userPreferencesInitialState: UserPreferencesState = {
  siteId: null,
  dontSaveUserPrefs: false,
  session: {},
  site: {},
  global: null,
  globalPrefFetchStatus: false,
  sitePrefFetchStatus: false,
  sitePreferencesFetchCallbacks: [],
  sitePreferencesFetchDone: false,
};

const reducer = (
  userPreferences: UserPreferencesState = userPreferencesInitialState,
  action: AnyFixMe,
): UserPreferencesState => {
  switch (action.type) {
    case SET_SITE_ID:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, { siteId: action.siteId });

    case SET_GLOBAL_PREF_FETCH_STATUS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, {
        globalPrefFetchStatus: action.status,
      });

    case SET_SITE_PREF_FETCH_STATUS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, {
        sitePrefFetchStatus: action.status,
      });

    case SET_PREFERENCES:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, {
        [action.typeId]: action.preferences,
      });

    case SET_PREFERENCE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        [action.typeId]: _.assign(
          {},
          userPreferences[action.typeId as keyof UserPreferencesState],
          {
            [action.key]: action.value,
          },
        ),
      });

    case MARK_SITE_PREFERENCES_NOT_DIRTY:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, {
        site: _.mapValues(userPreferences.site, (valueWrapper) =>
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign({}, valueWrapper, { isDirty: false }),
        ),
      });

    case DONT_SAVE_USER_PREFERENCES:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, { dontSaveUserPrefs: true });

    case REGISTER_SITE_PREF_CB:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/concat
        sitePreferencesFetchCallbacks: _.concat(
          userPreferences.sitePreferencesFetchCallbacks,
          action.cb,
        ),
      });

    case RESET_REGISTER_SITE_PREF_CB_ARRAY:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, {
        sitePreferencesFetchCallbacks: [],
      });

    case SITE_PREF_CALL_DONE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, userPreferences, { sitePreferencesFetchDone: true });

    default:
      return userPreferences;
  }
};

export default reducer;
