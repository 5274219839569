// @ts-nocheck
import _ from 'lodash';
import * as utils from './multilingualUtils';

export default {
  supportedLanguages: ({ languages }) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    _.map(languages, utils.addLanguageCode),
};
