import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import { biEvents } from './events';
import { getTabs } from './tabsSelectors';

function changeState(editorAPI: EditorAPI, tabsRef: CompRef, index: number) {
  const tabs = getTabs(tabsRef, editorAPI.dsRead);
  const newCurrentTabId = tabs[index].tabId;
  editorAPI.selectedComponent.sendSelectedComponentBI(
    biEvents.tabs_change_tab,
    {
      component_id: tabsRef.id,
      component_type: tabsRef.type,
      origin: 'tab_selector',
      page_id: editorAPI.pages.getCurrentPageId(),
    },
  );
  editorAPI.components.behaviors.execute(tabsRef, 'setCurrentTabId', {
    tabId: newCurrentTabId,
  });
}

export { changeState };
