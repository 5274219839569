export default {
  TPA_SETTINGS_PANEL_ID: 'tpa.compPanels.tpaSettings',
  TPA_SETTING_MODAL: 'tpa.compModals.tpaSettingsModal',
  TPA_DEFAULT_HELP_ID: '47f00d3e-78ae-4bd6-9c2f-4ddf0e9c42f4',
  MODALS: {
    MARKET: 'appMarketModal',
    APP: 'appMarketAppModal',
    PERMISSIONS: 'appMarketPermissionsModal',
  },
  BI: {
    initiator: {
      APP_MARKET: 'APPMARKET_EDITOR',
      EDITOR: 'EDITOR',
      SDK: 'SDK',
      PLATFORM: 'SDK_PLATFORM',
      TOP_BAR: 'TOP_BAR',
      DEV_MODE_ON: 'DEV_MODE_ON',
    },
    type: {
      ADD_APP: 'ADD_APP',
      ADD_APP_APP_BUTTON: 'ADD_APP_APP_BUTTON',
      ADD_APP_ADD_PANEL: 'ADD_APP_ADD_PANEL',
      ADD_APP_ADD_PAGE_PANEL: 'ADD_APP_ADD_PAGE_PANEL',
      ADD_APP_NEW_ADD_PANEL: 'ADD_APP_NEW_ADD_PANEL',
      ADD_APP_PREINSTALLATION: 'ADD_APP_PREINSTALLATION',
      ADD_WIDGET: 'ADD_WIDGET',
      APP_MANAGE_BUTTON: 'APP_BUTTON_MANAGE',
      APP_UPGRADE_BUTTON: 'APP_BUTTON_UPGRADE',
      TEMPLATE_LOAD: 'TEMPLATE_LOAD',
      COPY_PASTE: 'COPY_PASTE',
      DOUBLECLICK_SETTINGS: 'DOUBLECLICK_SETTINGS',
      GFPP_MANAGE: 'GFPP_MANAGE',
      GFPP_SETTINGS: 'GFPP_SETTINGS',
      GFPP_UPGRADE: 'GFPP_UPGRADE',
      OPENAPP_SETTINGS: 'OPENAPP_SETTINGS',
      TPA_DASHBOARD: 'TPA_DASHBOARD',
    },
  },
  APP: {
    TYPE: {
      SECTION: 'tpaSection',
      WIDGET: 'tpaWidget',
      APP_PART: 'wixappsPart',
      PLATFORM_ONLY: 'platformOnly',
    },
  },
  HISTORY: {
    BEFORE_ADDING_APP: 'before adding app',
    COMPONENT: 'adding a tpa to stage',
    WIDGET: 'adding a tpa widget to stage',
    SECTION: 'adding a tpa section to stage',
    MULTI_SECTION: 'adding multi section',
    SUB_SECTION: 'adding sub section',
    PLATFORM: 'adding a platform app to stage',
  },
  BUSINESS_MANAGER: {
    GENERAL_INFO: 'business-manager-general-info',
  },
  TPA_ON_SETTINGS_CLOSE_EVENT: 'TPA_ON_SETTINGS_CLOSE_EVENT',
};
