import {
  DEFAULT_DOCKING_OFFSET,
  DEFAULT_DOCKING_OFFSET_POPUP,
} from './constants';
import type { PinDockingDirection } from './types';

export function calcPinDockingOffsetsDefaults(
  dockingDirection: PinDockingDirection,
  offset: number = DEFAULT_DOCKING_OFFSET,
) {
  if (offset === 0) {
    return {
      horizontalOffset: 0,
      verticalOffset: 0,
    };
  }

  /* eslint-disable prettier/prettier */
  const PinDickingOffsetsByDirection = {
    TOP_LEFT: [offset, offset],     TOP: [0, offset],     TOP_RIGHT: [offset, offset],
    LEFT: [offset, 0],              MIDDLE: [0, 0],       RIGHT: [offset, 0],
    BOTTOM_LEFT: [offset, offset],  BOTTOM: [0, offset],  BOTTOM_RIGHT: [offset, offset],
  }
  /* eslint-enable prettier/prettier */
  const [horizontalOffset, verticalOffset] =
    PinDickingOffsetsByDirection[dockingDirection];

  return {
    horizontalOffset,
    verticalOffset,
  };
}

export function getPinDockingOffsetsPopupDefaults(
  dockingDirection: PinDockingDirection,
) {
  return calcPinDockingOffsetsDefaults(
    dockingDirection,
    DEFAULT_DOCKING_OFFSET_POPUP,
  );
}
