import type { CompLayout } from 'types/documentServices';

/**
 * Get bounding layout from layouts array
 *
 * source: https://github.com/wix-private/document-management/blob/43ab8e3734dfcb22a190063f1e87d1a54a61782f/document-services-implementation/src/smartBoxes/multiComponentsUtils.ts#L9
 */
export function getSnugLayoutFromLayoutsArray(
  layouts: CompLayout[],
): CompLayout {
  //copypasted from dm
  let mostLeft: number,
    mostRight: number,
    mostTop: number,
    mostBottom: number,
    mostTopBounding: number,
    mostRightBounding: number,
    mostLeftBounding: number,
    mostBottomBounding: number;
  mostLeft = mostTop = mostTopBounding = mostLeftBounding = Number.MAX_VALUE;
  mostRight =
    mostBottom =
    mostBottomBounding =
    mostRightBounding =
      -Number.MAX_VALUE;

  layouts.forEach((compLayout) => {
    mostLeft = Math.min(mostLeft, compLayout.x);
    mostRight = Math.max(mostRight, compLayout.x + compLayout.width);
    mostTop = Math.min(mostTop, compLayout.y);
    mostBottom = Math.max(mostBottom, compLayout.y + compLayout.height);

    mostLeftBounding = Math.min(mostLeftBounding, compLayout.bounding.x);
    mostRightBounding = Math.max(
      mostRightBounding,
      compLayout.bounding.x + compLayout.bounding.width,
    );
    mostTopBounding = Math.min(mostTopBounding, compLayout.bounding.y);
    mostBottomBounding = Math.max(
      mostBottomBounding,
      compLayout.bounding.y + compLayout.bounding.height,
    );
  });

  return {
    bounding: {
      x: mostLeftBounding,
      y: mostTopBounding,
      width: mostRightBounding - mostLeftBounding,
      height: mostBottomBounding - mostTopBounding,
    },
    x: mostLeftBounding,
    y: mostTopBounding,
    width: mostRightBounding - mostLeftBounding,
    height: mostBottomBounding - mostTopBounding,
    rotationInDegrees: 0,
  };
}
