export default {
  SECTION_KEY_FOR_TESTS: 'HELP_ID_FOR_TESTS',
  add_section_label_theme_text_responsive_tooltip_title:
    '43049a39-6444-4c63-9f02-6d7ce5fb9e72',
  add_section_label_titles: '37d38ee0-c72b-4278-ab7e-240c8b259256',
  add_section_label_progallery_v2: '99576567-3a38-4ece-bcdf-c75ef7f18f2e',
  add_section_label_gridgalleries: '8dbda4f4-4d21-4bb7-9c94-482a1e024b3d',
  add_section_label_3dgalleries: '223e4033-1ac1-4ccf-85bc-7bfad99ad6ad',
  add_section_label_textbutton: '25402aec-ce28-4040-b589-7286d2eed6ee',
  add_section_label_basicbutton: '7a66e972-96fa-4b21-bf2a-0a324c0d6023',
  add_section_label_iconbutton: '55b17b5c-27bd-4177-9776-aabdf85e0b31',
  add_section_label_hover_box: '93f0f3fc-b0e9-4129-ba6c-d97fd745b3d2',
  add_section_label_strip_container_classic:
    'e36f50fe-e1e4-4320-b7e4-f298a67aff4a',
  add_section_label_columns_parallax: '3eac7de8-6535-425d-ac33-e4bd5b3a23f3',
  ADD_SECTION_LABEL_WIXVIDEO: 'f9e79f5b-2e84-4c43-8501-562c03a7feec',
  add_section_label_proplayer: '256cf68f-1184-4d33-995d-47023e9d0a18',
  add_section_label_soundcloud: 'cb4f085d-869e-4cf5-9fb3-2d48787ee25a',
  add_section_label_spotifyplayer: '042092b7-7a8e-411f-a3a8-69e3d7c5ee1f',
  add_section_label_singletrackplayer: '3cca5625-4f77-4125-88b8-a58fabbb3030',
  add_section_label_itunes: '6ce08bdf-cea2-4e60-a8e5-63c0fa8d78df',
  add_section_label_socialbar: '87538995-bdc2-4c77-9214-519f76222e51',
  add_section_label_facebook: 'd62254ca-8c6b-4180-bce9-1a1412c7ce4e',
  add_section_label_twitter: '07949434-c1f6-44fb-9cdb-d92fbd9226a9',
  add_section_label_pinterest: '878889ab-a901-4a21-bfc4-d1610ff6b366',
  add_section_label_youtube: '9b28947f-9161-4f97-8c85-215328a1c12b',
  add_section_label_getsubscribers: 'bcb69cdc-c329-45eb-98aa-e9823bfc2ce5',
  add_section_label_contactform: '3bf1c6fd-b3e5-47f3-9ab4-22beb2a1ce4c',
  add_section_label_googlemap: '85be7222-3707-47b5-bb35-e61a760ab04e',
  add_preset_list_events: '2f8a99f2-0e47-4c5f-bc17-4a768db57c8f',
  add_section_label_html: '8ec212e3-114d-4b8c-9e1e-ec5634b20cb8',
  add_section_label_sitelogin: 'b0885bdd-72a1-4d62-ad9f-9647f9794af4',
  add_section_label_paypal: 'b3840371-7413-4f9f-b6fd-b62e99c548a0',
  add_section_label_vertical_anchors_menu:
    '6beabda7-0fcb-4c56-b771-f56d3c2b7fd1',
  add_section_label_document: 'f3d7cd63-eb32-4277-a0d1-bea688fd11d4',
  add_section_label_loginbar: '5a4eb6a1-9688-4184-a776-569d3cb04e98',
  add_section_label_memberprofile: 'bad6f01b-c794-42b0-8683-58809b188dbd',
  add_section_label_memberswidget: 'bd89b459-1f5a-4508-9e6b-bf9d9188f81a',
  add_section_label_membermenu: 'b24475b2-ec6e-497f-a142-18edb5521161',
  add_section_label_staffwidget: '044b79f5-b0ef-4dbd-85fc-eb05e1c9a01c',
  add_section_label_anchor: '0a1de6d7-3f69-4d30-8ddc-7aa99bd12edc',
  add_section_label_embeds: 'cf7f887a-5489-46fd-9299-dab0a5e4df80',
  add_section_heading_custom_embeds: 'cf7f887a-5489-46fd-9299-dab0a5e4df80',
  add_section_heading_marketing_tools: '9a193d54-2c79-4561-a5be-403944715f5d',
  add_section_label_MyDesigns: 'a7fcc82b-b766-4a66-ac2d-b1648bbbdb46',
  add_section_label_wixevents: '2f8a99f2-0e47-4c5f-bc17-4a768db57c8f',
  add_section_heading_interactions: '7a27bd0b-0052-46e4-bc94-bc5313b300cc',
};
