import { editorSearchActionTypes } from './editorSearchActionTypes';

export const initialEditorSearchState = {
  isPanelOpen: false,
  enabled: true,
};

export type EditorSearchState = typeof initialEditorSearchState;

export const editorSearchReducer = (
  editorSearch = initialEditorSearchState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case editorSearchActionTypes.OPEN_SEARCH_PANEL:
      return { ...editorSearch, isPanelOpen: true, openOrigin: action.origin };
    case editorSearchActionTypes.CLOSE_SEARCH_PANEL:
      return { ...editorSearch, isPanelOpen: false, openOrigin: action.origin };
    case editorSearchActionTypes.ENABLE:
      return { ...editorSearch, enabled: true };
    case editorSearchActionTypes.DISABLE:
      return { ...editorSearch, enabled: false };
    default:
      return editorSearch;
  }
};
