import _ from 'lodash';
import * as util from '@/util';
import { interactionsActionTypes } from './interactionsActionTypes';
import {
  interactionsSelectors,
  isShownOnlyOnVariant,
} from './interactionsSelectors';
import { interactionsContext } from './interactionsContext';
import * as coreBi from '@/coreBi';
import { selectComponents } from '../selection/selectionActions';

import type { EditorState } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type {
  CompRef,
  InteractionType,
  CompVariantPointer,
} from 'types/documentServices';

const {
  getVariantId,
  isInInteractionMode,
  getVariantPointers,
  getCompVariantPointer,
  getInteractionTriggerRef,
  isShownOnlyOnSpecificVariant,
  getComponentInteractionsVariantsDefIfExist,
  isInteractionModeAvailable,
  interactionDuration,
  ENTER_INTERACTION_HISTORY_LABEL,
  DEFAULT_Z_LAYER_VALUE,
} = interactionsSelectors;
const { keyboardShortcuts } = util;

const DEFAULT_TRANSFORM_TRANSITION = {
  'timing-function': 'ease-in-out',
  duration: 0.4,
  delay: 0,
};
let interactionComponentShowMoveContextTimeoutId: AnyFixMe;

interface ShowCompBiExtraParams {
  origin: string;
}

// TODO: move to utils.
const multiplyWithPrecision = (
  a: number,
  b: number,
  precision: number = 3,
): number => {
  const multiplier = Math.pow(10, precision);

  a *= multiplier;
  b *= multiplier;

  return (Math.floor(a) * Math.floor(b)) / Math.pow(multiplier, 2);
};

// TODO: move to utils.
const divideWithPercision = (
  a: number,
  b: number,
  precision: number = 3,
): number => {
  const multiplier = Math.pow(10, precision);

  a *= multiplier;
  b *= multiplier;

  return Math.floor(a) / Math.floor(b);
};

const setDefaultTransition = (editorAPI: AnyFixMe, compRef: CompRef): void => {
  const { transitions } = editorAPI.documentServices.components;

  if (!transitions.has(compRef)) {
    setTransition(editorAPI, compRef, DEFAULT_TRANSFORM_TRANSITION);
  }
};

const setTransition = async (
  editorAPI: AnyFixMe,
  compRef: CompRef,
  transitionData: AnyFixMe,
): Promise<void> => {
  const { transitions } = editorAPI.documentServices.components;

  transitions.update(compRef, transitionData);
  await editorAPI.waitForChangesAppliedAsync();
  editorAPI.store.dispatch(updateInteractionData(compRef));
};

const setDefaultZLayer = (editorAPI: AnyFixMe): void => {
  const state = editorAPI.store.getState();
  const triggerVariantPointer = editorAPI.components.variants.getPointer(
    getInteractionTriggerRef(state),
    getVariantPointers(state),
  );
  const triggetTransformations = editorAPI.components.transformations.get(
    triggerVariantPointer,
  );
  if (!triggetTransformations?.zLayer) {
    editorAPI.components.transformations.update(triggerVariantPointer, {
      zLayer: DEFAULT_Z_LAYER_VALUE,
    });
  }
};

const createInteraction =
  (interactionName: InteractionType, compRef: CompRef) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const { documentServices } = editorAPI;
    const { variants } = documentServices;

    const variantPointer = variants.create(compRef, interactionName);
    const triggerType = editorAPI.components.getType(compRef);
    const triggerParent =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
    const triggerParentType = editorAPI.components.getType(triggerParent);
    editorAPI.bi.event(coreBi.events.interactions.add_hover_interaction, {
      component_id: compRef.id,
      component_type: triggerType,
      interaction_id: variantPointer.id,
      panel_name: 'interactions.panels.interactionsPanel',
      parent_component_id: triggerParent.id,
      parent_component_type: triggerParentType,
    });
  };

const enterInteraction =
  (
    interactionName: InteractionType,
    compRef: CompRef,
    { leftBar, addPanel }: AnyFixMe,
    shouldShowBlockingLayer = true,
  ) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const { documentServices } = editorAPI;
    const { transformations } = documentServices.components;
    const { variants } = documentServices;

    editorAPI.panelManager.closeAllPanels();

    if (
      !editorAPI.utils.isSameRef(
        editorAPI.selection.getSelectedComponents()[0],
        compRef,
      )
    ) {
      dispatch(
        selectComponents([compRef], editorAPI.getCompRestrictions(compRef)),
      );
    }

    const variantsPointer = documentServices.components.variants.get(
      compRef,
      interactionName,
    );
    const variantPointer = variantsPointer[0];
    if (!variantPointer) {
      throw new Error(`Got ${variantPointer} as variant pointer from DS.`);
    }

    if (shouldShowBlockingLayer) {
      keyboardShortcuts.setContext(keyboardShortcuts.CONTEXTS.INTERACTIONS);
    }

    variants.enable([variantPointer]);

    const compVariantPointer = documentServices.components.variants.getPointer(
      compRef,
      [variantPointer],
    );

    const compTransformations = transformations.get(compVariantPointer);
    interactionsContext.setLayout({
      offsetX: compTransformations?.translate?.x?.value || 0,
      offsetY: compTransformations?.translate?.y?.value || 0,
    });

    editorAPI.waitForChangesApplied(() => {
      editorAPI.history.add(ENTER_INTERACTION_HISTORY_LABEL);
    });

    if (shouldShowBlockingLayer) {
      setInteractionBlockingLayer(editorAPI, compRef);
    }

    dispatch({
      type: interactionsActionTypes.ENTER_INTERACTION,
      payload: {
        interactionName,
        triggerRef: compRef,
        compVariantPointer,
        leftBar,
        addPanel,
        interactionDuration: getInteractionDuration(editorAPI, compRef),
        showInteractionModeControls: shouldShowBlockingLayer,
      },
    });

    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.ENTER_INTERACTIONS,
    );
  };

const getInteractionDuration = (editorAPI: AnyFixMe, triggerRef: CompRef) => {
  return [
    triggerRef,
    ...editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
      triggerRef,
      true,
    ),
  ].reduce((max, comp) => {
    const compTransition = editorAPI.components.transitions.get(comp);
    if (!compTransition) {
      return max;
    }
    const newTiming =
      (compTransition?.delay || 0) + (compTransition?.duration || 0);
    return Math.max(max, newTiming);
  }, 0);
};

const onInteractionSelectedComponentsChanged =
  (compRef: CompRef) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    if (isInteractionModeAvailable(editorAPI.dsRead)) {
      const { transformations } = editorAPI.documentServices.components;
      const state = getState();
      const prevSelected = getCompVariantPointer(state);
      const compVariantPointer = editorAPI.components.variants.getPointer(
        compRef,
        prevSelected.variants,
      );

      const compTransformations = transformations.get(compVariantPointer);
      interactionsContext.setLayout({
        offsetX: compTransformations?.translate?.x?.value || 0,
        offsetY: compTransformations?.translate?.y?.value || 0,
      });

      dispatch({
        type: interactionsActionTypes.UPDATE_INTERACTION_SELECTED_COMPONENT,
        payload: {
          isOnlyOnVariant: isShownOnlyOnVariant(editorAPI, compRef),
          compVariantPointer,
        },
      });
    }
  };

let prevTranslateX = 0;
let prevTranslateY = 0;
let previousRotationMap: AnyFixMe = [];
let accumulatedScale = {
  x: 1,
  y: 1,
};

const setPreviousRotationMap = (
  transformationsAPI: AnyFixMe,
  variants: AnyFixMe,
  compsToReset: AnyFixMe,
) => {
  interactionsContext.setLayout({ rotateDisabled: true, skewDisabled: true });

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  previousRotationMap = _.map(compsToReset, (compUnderTrigger) => {
    const childVariantPointer = {
      ...compUnderTrigger,
      variants,
    };
    const currentTransformations = transformationsAPI.get(childVariantPointer);
    const currentChildRotation = currentTransformations?.rotate;
    const currentChildSkew = currentTransformations?.skew;

    transformationsAPI.update(childVariantPointer, {
      rotate: 0,
      skew: undefined,
    });
    return {
      rotate: currentChildRotation,
      skew: currentChildSkew,
      childVariantPointer,
    };
  });
};

const setAccumulatedScale = (
  transformations: AnyFixMe,
  variants: AnyFixMe,
  triggerPointer: AnyFixMe,
  selectedWithParents: AnyFixMe,
) => {
  for (let i = 0, l = selectedWithParents.length; i < l; i++) {
    const isTrigger = selectedWithParents[i].id === triggerPointer.id;
    const transformationsObject = transformations.get({
      ...selectedWithParents[i],
      variants,
    });

    if (transformationsObject?.scale) {
      if (transformationsObject.scale.x > 1 || isTrigger) {
        accumulatedScale.x = multiplyWithPrecision(
          accumulatedScale.x,
          transformationsObject.scale.x,
          3,
        );
      }
      if (transformationsObject.scale.y > 1 || isTrigger) {
        accumulatedScale.y = multiplyWithPrecision(
          accumulatedScale.y,
          transformationsObject.scale.y,
          3,
        );
      }
    }

    if (isTrigger) break;
  }
};

const getCompsToResetOnMouseAction = (
  editorAPI: AnyFixMe,
  compRef: CompRef,
  triggerRef: CompRef,
): CompRef[] => {
  const { isDescendantOfComp } = editorAPI.documentServices.components;
  return editorAPI.components
    .getAncestors_DEPRECATED_BAD_PERFORMANCE(compRef)
    .filter(
      (ancestor: AnyFixMe) =>
        isDescendantOfComp(ancestor, triggerRef) ||
        ancestor.id === triggerRef.id,
    )
    .concat([compRef]);
};

const beforeInteractionResizeStart = (
  editorAPI: AnyFixMe,
  compRef: CompRef,
) => {
  const { transformations } = editorAPI.documentServices.components;

  const state = editorAPI.store.getState();
  const variants = getVariantPointers(state);

  const triggerPointer = getInteractionTriggerRef(state);
  const compsToReset = getCompsToResetOnMouseAction(
    editorAPI,
    compRef,
    triggerPointer,
  );

  setPreviousRotationMap(transformations, variants, compsToReset);
};

const beforeInteractionDragStart = (editorAPI: AnyFixMe, compRef: CompRef) => {
  if (isInteractionModeAvailable(editorAPI.dsRead)) {
    const { transformations } = editorAPI.documentServices.components;
    const state = editorAPI.store.getState();
    const variants = getVariantPointers(state);

    const triggerPointer = getInteractionTriggerRef(state);

    const compVariantPointer = getCompVariantPointer(state);
    const selectedAncestors =
      editorAPI.components.getAncestors_DEPRECATED_BAD_PERFORMANCE(
        compVariantPointer,
      );

    const compsToReset = getCompsToResetOnMouseAction(
      editorAPI,
      compRef,
      triggerPointer,
    );

    setPreviousRotationMap(transformations, variants, compsToReset);
    setAccumulatedScale(transformations, variants, triggerPointer, [
      compVariantPointer,
      ...selectedAncestors,
    ]);
  }
};

const onInteractionDragStart = (editorAPI: AnyFixMe) => {
  const { transformations } = editorAPI.documentServices.components;
  const state = editorAPI.store.getState();
  const compVariantPointer = getCompVariantPointer(state);

  const prevTransformations = transformations.get(compVariantPointer);

  if (prevTransformations?.translate) {
    prevTranslateX = prevTransformations.translate.x.value;
    prevTranslateY = prevTransformations.translate.y.value;
  }

  interactionsContext.setLayout({
    dragging: true,
    rotateDisabled: true,
    skewDisabled: true,
  });
};

const onInteractionDrag = (
  editorAPI: AnyFixMe,
  offsetX: number,
  offsetY: number,
  isShiftPressed: boolean,
) => {
  const { transformations } = editorAPI.documentServices.components;
  const compVariantPointer = getCompVariantPointer(editorAPI.store.getState());

  offsetX = divideWithPercision(offsetX, accumulatedScale.x);
  offsetY = divideWithPercision(offsetY, accumulatedScale.y);

  offsetX += prevTranslateX;
  offsetY += prevTranslateY;

  if (isShiftPressed) {
    if (Math.abs(offsetX) >= Math.abs(offsetY)) {
      offsetY = 0;
    } else {
      offsetX = 0;
    }
  }

  transformations.update(compVariantPointer, {
    translate: {
      x: {
        type: 'px',
        value: offsetX,
      },
      y: {
        type: 'px',
        value: offsetY,
      },
    },
  });
  interactionsContext.setLayout({
    offsetX,
    offsetY,
    dragging: true,
    rotateDisabled: true,
    skewDisabled: true,
  });
};

const onInteractionDragEnd =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const { transformations, getType } = editorAPI.documentServices.components;
    const state = editorAPI.store.getState();
    const triggerPointer = getInteractionTriggerRef(state);
    const compRef = editorAPI.selection.getSelectedComponents()[0];
    const compVariantPointer = getCompVariantPointer(state);
    const currentTransformations = transformations.get(compVariantPointer);

    editorAPI.bi.event(
      coreBi.events.interactions.interaction_mode_move_component,
      {
        yCoordinate_old: prevTranslateY,
        yCoordinate_new: currentTransformations.translate.y.value,
        xCoordinate_old: prevTranslateX,
        xCoordinate_new: currentTransformations.translate.x.value,
        parent_component_type: getType(triggerPointer),
        parent_component_id: triggerPointer.id,
        interaction_id: getVariantId(state),
        component_type: getType(compRef),
        component_id: compRef.id,
        handle_used: interactionsContext.getLayout().isDraggingWithHandle
          ? 'yes'
          : 'no',
      },
    );

    prevTranslateX = prevTranslateY = 0;
    interactionsContext.setLayout({
      dragging: false,
      rotateDisabled: false,
      skewDisabled: false,
      isDraggingWithHandle: false,
    });
    setDefaultZLayer(editorAPI);
    setDefaultTransition(editorAPI, compRef);
  };

const restorePreviousTransformations = (editorAPI: AnyFixMe) => {
  const { transformations } = editorAPI.documentServices.components;

  previousRotationMap.forEach((previousCompRotation: AnyFixMe) => {
    if (_.isNumber(previousCompRotation.rotate)) {
      transformations.update(previousCompRotation.childVariantPointer, {
        rotate: previousCompRotation.rotate,
      });
    }
    if (
      _.isNumber(previousCompRotation.skew?.x) &&
      _.isNumber(previousCompRotation.skew?.y)
    ) {
      transformations.update(previousCompRotation.childVariantPointer, {
        skew: previousCompRotation.skew,
      });
    }
  });

  previousRotationMap = [];

  editorAPI.waitForChangesApplied(() => {
    requestAnimationFrame(() => {
      interactionsContext.setLayout({
        rotateDisabled: false,
        skewDisabled: false,
      });
    });
  });
};

const afterInteractionDrag = (editorAPI: AnyFixMe) => {
  restorePreviousTransformations(editorAPI);
  accumulatedScale = {
    x: 1,
    y: 1,
  };
};

let playInteractionTimeout: AnyFixMe;

const playInteraction =
  (triggerRef: CompRef) =>
  async (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const state = getState();
    const { variants } = editorAPI.documentServices;

    const variantPointers = getVariantPointers(state);
    variants.disable(variantPointers);
    interactionsContext.resetLayout();
    await editorAPI.waitForChangesAppliedAsync();

    variants.enable(variantPointers);
    editorAPI.dsActions.transitions.enable(true);
    dispatch({
      type: interactionsActionTypes.PLAY_INTERACTION,
    });

    await waitForInteractionPlay(interactionDuration(state) * 1000);
    dispatch(afterInteractionPlay(triggerRef));
  };

const waitForInteractionPlay = (ms: number) =>
  new Promise((resolve) => (playInteractionTimeout = setTimeout(resolve, ms)));

const stopPlayInteraction = (triggerRef: AnyFixMe) => (dispatch: AnyFixMe) => {
  if (playInteractionTimeout) {
    clearTimeout(playInteractionTimeout);
    dispatch(afterInteractionPlay(triggerRef));
  }
};

const afterInteractionPlay =
  (triggerRef: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const { transformations } = editorAPI.dsRead.components;
    const variantPointers = getVariantPointers(getState());
    const compVariantPointer = editorAPI.dsRead.components.variants.getPointer(
      triggerRef,
      variantPointers,
    );
    const compTransformations = transformations.get(compVariantPointer);
    interactionsContext.setLayout({
      offsetX: compTransformations?.translate?.x?.value || 0,
      offsetY: compTransformations?.translate?.y?.value || 0,
    });

    playInteractionTimeout = null;
    editorAPI.dsActions.transitions.enable(false);
    dispatch({
      type: interactionsActionTypes.STOP_PLAYING_INTERACTION,
    });
  };

const exitInteraction =
  (shouldSelectTriggerRef: boolean = true) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const state = getState();
    const triggerRef = getInteractionTriggerRef(state);
    if (editorAPI.imageCrop.isCropMode()) {
      editorAPI.imageCrop.cancelCrop(
        editorAPI.selection.getSelectedComponents()[0],
      );
    }
    editorAPI.panelManager.closeAllPanels();
    keyboardShortcuts.setContext(keyboardShortcuts.CONTEXTS.EDITOR);

    const { variants } = editorAPI.documentServices;

    const variantPointers = getVariantPointers(state);
    variants.disable(variantPointers);

    clearInteractionBlockingLayer(editorAPI);

    dispatch({
      type: interactionsActionTypes.EXIT_INTERACTION,
    });

    interactionsContext.resetLayout();

    editorAPI.waitForChangesApplied(() => {
      if (shouldSelectTriggerRef) {
        selectTrigger(editorAPI, triggerRef);
      }
    });
  };

const selectTrigger = (editorAPI: AnyFixMe, comp: CompRef) => {
  let compRef = comp;
  if (editorAPI.columns.isColumn(compRef)) {
    const stripRef = editorAPI.components.getContainer(compRef);
    if (editorAPI.columns.isSingleColumnStrip(stripRef)) {
      compRef = stripRef;
    }
  }
  editorAPI.selection.selectComponentByCompRef(compRef);
};

const hideComponent =
  (showCompBiExtraParams?: ShowCompBiExtraParams) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const state = getState();
    const compVariantPointer = getCompVariantPointer(state);
    const compRef = editorAPI.selection.getSelectedComponents()[0];
    editorAPI.documentServices.components.transformations.update(
      compVariantPointer,
      { hidden: true },
    );
    setTransition(editorAPI, compRef, {
      'timing-function': 'ease-out',
      duration: 0.2,
      delay: 0,
    });
    const compChildren =
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
        compRef,
        true,
      );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(compChildren, (child) => {
      editorAPI.documentServices.components.transformations.update(
        editorAPI.components.variants.getPointer(
          child,
          compVariantPointer.variants,
        ),
        { hidden: false },
      );
    });

    const triggerRef = getInteractionTriggerRef(state);

    if (!editorAPI.utils.isSameRef(compRef, triggerRef)) {
      editorAPI.selection.selectComponentByCompRef(triggerRef);
    }

    editorAPI.bi.event(
      coreBi.events.interactions.interaction_mode_hide_component,
      {
        interaction_id: getVariantId(state),
        component_id: compRef.id,
        component_type: editorAPI.components.getType(compRef),
        parent_component_id: triggerRef.id,
        parent_component_type: editorAPI.components.getType(triggerRef),
        origin: showCompBiExtraParams.origin,
      },
    );
    editorAPI.history.add('hide comp on interactions');
    editorAPI.panelManager.openPanel(
      'interactions.panels.interactionHiddenComponents',
    );
  };

function getCompVariantPointerByRef(
  editorAPI: AnyFixMe,
  state: EditorState,
  compRef: CompRef,
): CompVariantPointer {
  const variantsPointer = editorAPI.components.variants.get(
    state.interactions.triggerRef,
    state.interactions.interactionName,
  );
  return editorAPI.components.variants.getPointer(compRef, variantsPointer);
}

const DEFAULT_TRANSLATION_VALUES = {
  x: {
    type: 'px',
    value: 0,
  },
  y: {
    type: 'px',
    value: 0,
  },
};

function translateComponentTo(
  editorAPI: AnyFixMe,
  compRef: CompRef,
  axis: string,
  amountToMove: number,
): void {
  const compVariantPointer = getCompVariantPointer(editorAPI.store.getState());
  const currentTranslations =
    editorAPI.components.transformations.get(compVariantPointer)?.translate;

  let translateData = currentTranslations;
  if (!currentTranslations) {
    const translationToApply: AnyFixMe = {};
    translationToApply[axis] = {
      type: 'px',
      value: amountToMove,
    };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    translateData = _.assign(
      {},
      DEFAULT_TRANSLATION_VALUES,
      translationToApply,
    );
  } else {
    translateData[axis].value = amountToMove;
  }

  const currentTransitionData =
    editorAPI.components.transitions.get(compVariantPointer);
  if (!currentTransitionData) {
    setDefaultTransition(editorAPI, compRef);
  }

  setDefaultZLayer(editorAPI);

  editorAPI.components.transformations.update(compVariantPointer, {
    translate: translateData,
  });

  window.clearTimeout(interactionComponentShowMoveContextTimeoutId);
  interactionsContext.setLayout({
    offsetX: translateData.x.value,
    offsetY: translateData.y.value,
    dragging: true,
  });
  interactionComponentShowMoveContextTimeoutId = setTimeout(() => {
    editorAPI.history.add('move with keyboard in interaction');
    interactionsContext.setLayout({
      dragging: false,
    });
  }, 800);
}

function transferStripInteractionToSingleColumn(
  editorAPI: AnyFixMe,
  stripRef: CompRef,
): void {
  const stripInteractionsDef = getComponentInteractionsVariantsDefIfExist(
    editorAPI,
    stripRef,
  );
  const singleColumnRef =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(stripRef)[0];
  const singleColumnInteractions = getComponentInteractionsVariantsDefIfExist(
    editorAPI,
    singleColumnRef,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(
    stripInteractionsDef,
    (stripInteractionVariantPointers, interactionName) => {
      let columnVariantPointer = singleColumnInteractions[interactionName];
      if (!columnVariantPointer) {
        columnVariantPointer = editorAPI.variants.create(
          singleColumnRef,
          interactionName,
        );
      }
      editorAPI.components
        .getChildren_DEPRECATED_BAD_PERFORMANCE(singleColumnRef)
        .filter((columnDescendantRef: AnyFixMe) => {
          const descendantVariantPointer =
            editorAPI.components.variants.getPointer(
              columnDescendantRef,
              stripInteractionVariantPointers,
            );
          const descendantVariantTransformations =
            editorAPI.components.transformations.get(descendantVariantPointer);
          return (
            typeof descendantVariantTransformations.hidden !== 'undefined' &&
            descendantVariantTransformations.hidden === false
          );
        })
        .forEach((columnDescendantRef: AnyFixMe) => {
          const descendantColumnVariantPointer =
            editorAPI.components.variants.getPointer(
              columnDescendantRef,
              columnVariantPointer,
            );
          editorAPI.components.transformations.update(
            descendantColumnVariantPointer,
            {
              hidden: false,
            },
          );
        });

      removeInteraction(editorAPI, stripRef, interactionName, false);
    },
  );
}

const showComponent =
  (compRef?: CompRef, showCompBiExtraParams?: ShowCompBiExtraParams) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const state = getState();
    const compVariantPointer = compRef
      ? getCompVariantPointerByRef(editorAPI, state, compRef)
      : getCompVariantPointer(state);
    editorAPI.documentServices.components.transformations.update(
      compVariantPointer,
      { hidden: false },
    );

    const triggerRef = getInteractionTriggerRef(state);

    editorAPI.bi.event(
      coreBi.events.interactions.interaction_mode_unhide_component,
      {
        interaction_id: getVariantId(state),
        component_id: compVariantPointer.id,
        component_type: editorAPI.components.getType(compVariantPointer),
        parent_component_id: triggerRef.id,
        parent_component_type: editorAPI.components.getType(triggerRef),
        origin: showCompBiExtraParams.origin,
      },
    );
    editorAPI.history.add('show comp on interactions');
  };

const removeInteraction = (
  editorAPI: AnyFixMe,
  compRef: CompRef,
  interactionName: string,
  shouldRemoveChildrenUnderVariant: boolean,
): void => {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.REMOVE_INTERACTION,
  );
  const variantPointer = editorAPI.components.variants.get(
    compRef,
    interactionName,
  );
  if (shouldRemoveChildrenUnderVariant) {
    const triggerChildren =
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
        compRef,
        true,
      );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    _(triggerChildren)
      .filter((comp) =>
        isShownOnlyOnSpecificVariant(editorAPI, comp, variantPointer),
      )
      .forEach(editorAPI.components.remove);
  }
  editorAPI.variants.remove(variantPointer);
  const variantId = util.array.asArray(variantPointer)[0].id;
  editorAPI.bi.event(coreBi.events.interactions.remove_interaction, {
    component_id: compRef.id,
    component_type: editorAPI.components.getType(compRef),
    interaction_id: variantId,
    panel_name: 'interactions.panels.interactionsPanel',
  });

  editorAPI.waitForChangesApplied(() => {
    editorAPI.history.add('removed interaction');
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.REMOVE_INTERACTION,
    );
    editorAPI.store.dispatch(updateInteractionData(compRef));
  });
};

function setInteractionBlockingLayer(
  editorAPI: EditorAPI,
  triggerRef: CompRef,
): void {
  editorAPI.renderPlugins.setCompsToShowOnTop([triggerRef.id]);
  editorAPI.renderPlugins.setBlockingLayer({
    backgroundColor: '#fff',
    opacity: 0.6,
  });
}

function clearInteractionBlockingLayer(editorAPI: EditorAPI): void {
  editorAPI.renderPlugins.setCompsToShowOnTop(null);
  editorAPI.renderPlugins.setBlockingLayer(null);
}

function exitInteractionModeIfNeeded(
  editorAPI: AnyFixMe,
  shouldSelectTriggerRef: boolean = true,
) {
  if (isInInteractionMode(editorAPI.store.getState())) {
    editorAPI.store.dispatch(exitInteraction(shouldSelectTriggerRef));
  }
}

function resetInteractionChanges(editorAPI: EditorAPI, compRef: CompRef): void {
  editorAPI.components.transformations.remove(compRef);
  editorAPI.components.transitions.remove(compRef);
  const pointerWithVariant = getCompVariantPointer(editorAPI.store.getState());
  editorAPI.components.style.scoped.remove(pointerWithVariant);
  editorAPI.history.add('reset comp interaction changes');
  editorAPI.waitForChangesApplied(() => {
    requestAnimationFrame(() => {
      interactionsContext.resetLayout();
      interactionsContext.reRenderConsumers();
    });
    editorAPI.store.dispatch(updateInteractionData(compRef));
  });
}

const updateInteractionData =
  (compRef: CompRef) =>
  async (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    dispatch({
      type: interactionsActionTypes.UPDATE_INTERACTION_TRANSITIONS,
      payload: {
        interactionDuration: getInteractionDuration(editorAPI, compRef),
      },
    });
  };

export const interactionsActions = {
  selectTrigger,
  enterInteraction,
  exitInteraction,
  translateComponentTo,
  beforeInteractionDragStart,
  onInteractionDragStart,
  onInteractionDrag,
  onInteractionDragEnd,
  afterInteractionDrag,
  setDefaultZLayer,
  setDefaultTransition,
  onInteractionSelectedComponentsChanged,
  transferStripInteractionToSingleColumn,
  hideComponent,
  showComponent,
  createInteraction,
  exitInteractionModeIfNeeded,
  setInteractionBlockingLayer,
  clearInteractionBlockingLayer,
  removeInteraction,
  beforeInteractionResizeStart,
  restorePreviousTransformations,
  playInteraction,
  stopPlayInteraction,
  resetInteractionChanges,
  updateInteractionData,
};
