// @ts-nocheck
import devModeActionTypes from './devModeActionTypes';

const { RESET_CONTEXT } = devModeActionTypes;

const resetDevModeContext = () => ({
  type: RESET_CONTEXT,
});

export { resetDevModeContext };
