import { interactionsActionTypes } from '../interactions/interactionsActionTypes';
import * as addPanelActionTypes from './addPanelActionTypes';
import type { Reducer } from 'types/redux';

const { OVERRIDE_ADD_PANEL } = addPanelActionTypes;
const { EXIT_INTERACTION, ENTER_INTERACTION } = interactionsActionTypes;

let overriddenButtonsStateBeforeEnterInteractions: AnyFixMe;

export interface AddPanelState {
  overridden: unknown;
}

export const addPanelInitialState: AddPanelState = {
  overridden: undefined,
};

const reducer: Reducer<AddPanelState> = (
  addPanel = addPanelInitialState,
  action,
) => {
  switch (action.type) {
    case OVERRIDE_ADD_PANEL:
      return { ...addPanel, overridden: action.overridden };
    case ENTER_INTERACTION:
      overriddenButtonsStateBeforeEnterInteractions = addPanel.overridden;
      return {
        ...addPanel,
        overridden: action.payload.addPanel?.overridden,
      };
    case EXIT_INTERACTION:
      return {
        ...addPanel,
        overridden: overriddenButtonsStateBeforeEnterInteractions,
      };
    default:
      return addPanel;
  }
};

export default reducer;
