import * as componentDataResolver from './componentDataResolver';
import * as componentsSelectors from './componentsSelectors';
import * as actions from './componentsActions';
import compPanel from './compPanel';
import { useLayoutFullWidthApi } from './compPanelApiContext';

const selectors = {
  ...componentsSelectors,
  getEditorData: componentDataResolver.getEditorData,
};

const hoc = {
  compPanel,
};

const hooks = {
  useLayoutStreatchApi: useLayoutFullWidthApi,
};

export { selectors, actions, hoc, hooks };
