import { ErrorReporter } from '@wix/editor-error-reporter';
import * as util from '@/util';
import type {
  SendBiThunk,
  SendBIErrorThunk,
  BiEventDefinition,
} from 'types/bi';
import { WorkspaceModesApiKey } from '@/apis';

function isValidBIEvent(biEvent: BiEventDefinition): boolean {
  return Boolean(biEvent && (biEvent.evid || biEvent.eventId));
}

// use editorAPI.bi.event(...) if you need BI before preview ready
const event: SendBiThunk =
  (biEvent, biEventParams) =>
  (dispatch, getState, { dsActions, editorAPI }) => {
    if (!editorAPI || !dsActions) {
      return;
    }

    if (!isValidBIEvent(biEvent)) {
      const error = Error('invalid bi event');
      ErrorReporter.captureException(error, {
        tags: {
          invalidEvent: true,
        },
        extra: {
          biEvent,
        },
      });
    }
    util.bi.biLoggerLegacy.event(biEvent, biEventParams);
  };

const error: SendBIErrorThunk =
  (errorEvent, errorParams) =>
  (dispatch, getState, { editorAPI }) => {
    // TODO this is temp solution until legacy bi is removed
    const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
    errorParams.workspace_mode = workspaceModesApi.getMode();
    util.bi.biLoggerLegacy.error(errorEvent, errorParams);
  };

export { event, error };
