// @ts-nocheck
import _ from 'lodash';
import layersActionTypes from './layersActionTypes';

const { SET_SPOTLIGHT } = layersActionTypes;

const reducer = (layers = {}, action) => {
  switch (action.type) {
    case SET_SPOTLIGHT:
      return _.defaults({ spotlight: action.component }, layers);
    default:
      return layers;
  }
};

export default reducer;
