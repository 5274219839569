// @ts-nocheck
import _ from 'lodash';
import highlightsActionTypes from './highlightsActionTypes';

const {
  ADD_TO_HIGHLIGHTS,
  REMOVE_COMPS_FROM_HIGHLIGHTS,
  SET_HIGHLIGHTS,
  CLEAR_HIGHLIGHTS,
  REMOVE_FROM_HIGHLIGHTS_BY_HIGHLIGHT_TYPE,
} = highlightsActionTypes;

const reducer = (highlights = [], action) => {
  switch (action.type) {
    case ADD_TO_HIGHLIGHTS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (_.isArray(action.highlightsDefs)) {
        return highlights.concat(action.highlightsDefs);
      }
      console.error('highlights must be an array');
      return highlights;
    case REMOVE_COMPS_FROM_HIGHLIGHTS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (_.isArray(action.compRefs)) {
        return _.reject(highlights, function (highlight) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/find
          return _.find(action.compRefs, highlight.compRef);
        });
      }
      console.error('highlights must be an array');
      return highlights;
    case REMOVE_FROM_HIGHLIGHTS_BY_HIGHLIGHT_TYPE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (_.isArray(action.highlightTypes)) {
        return _.reject(highlights, function (highlight) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          return _.includes(action.highlightTypes, highlight.type);
        });
      }
      console.error('highlightTypes must be an array');
      return highlights;
    case SET_HIGHLIGHTS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (_.isArray(action.highlightsDefs)) {
        return action.highlightsDefs;
      }
      console.error('highlights must be an array');
      return highlights;
    case CLEAR_HIGHLIGHTS:
      return [];
    default:
      return highlights;
  }
};

export default reducer;
