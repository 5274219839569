import _ from 'lodash';
import * as actionTypes from './preloadingsActionsTypes';

export const preloadingsInitialState: AnyFixMe[] = [];

const preloadingsReducer = (
  preloadingsState = preloadingsInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_PRELOADING_COMPONENT: {
      return [...preloadingsState, action.component];
    }
    case actionTypes.CLEAR_PRELOADING_COMPONENT: {
      return preloadingsState.filter(
        (comp) => !_.isEqual(comp, action.component),
      );
    }
    default:
      return preloadingsState;
  }
};

export default preloadingsReducer;
