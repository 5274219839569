import _ from 'lodash';
import * as actionTypes from './wixCodeIDEActionTypes';

export const wixCodeIDEInitialState = {
  overridden: {
    showTabs: true,
    shouldShow: true,
  },
};

const reducer = (wixCodeIde = wixCodeIDEInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case actionTypes.OVERRIDE_WIX_CODE_IDE:
      return _.defaultsDeep({}, { overridden: action.overrides }, wixCodeIde);
    default:
      return wixCodeIde;
  }
};

export default reducer;
