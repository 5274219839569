function setWorkerMessagesAPI(workerMessagesAPI: AnyFixMe) {
  return (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { panelsWorkerService }: AnyFixMe,
  ) => {
    panelsWorkerService.workerMessagesAPI = workerMessagesAPI;
  };
}

function getWorkerMessagesAPI() {
  return (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { panelsWorkerService }: AnyFixMe,
  ) => panelsWorkerService.workerMessagesAPI;
}

export { setWorkerMessagesAPI, getWorkerMessagesAPI };
