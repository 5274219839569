import type { fedopsLogger } from '@/util';
import type { PanelType, PanelSection } from '@/constants';
import type { PanelSectionDefinition } from './panelsSectionsDefinitionsDefaults';
import type { ProvidedPanelFrameProps, PanelFrameProps } from './panelFrame';
import type { PanelConfiguration } from '@wix/editor-elements-preview-utils/dist/panels';
import type { CompRef } from 'types/documentServices';
import type React from 'react';

export type {
  PanelType,
  PanelSection,
  ProvidedPanelFrameProps,
  PanelFrameProps,
};

export enum StageBehaviour {
  None = 'none',
  Shrink = 'shrink',
  Stretch = 'stretch',
}

type InteractionsMap = ReturnType<typeof fedopsLogger.mapInteraction>;

export interface PanelProps {
  panelName?: string;
  selectedComponent?: CompRef[] | CompRef;
  title?: string;
  key?: string;
  width?: number;
  panelIndex?: number;
  panelSectionsDefinition?: PanelSectionDefinition;
  panelSections?: PanelSection;
  configuration?: Partial<PanelConfiguration>;
  token?: string;
  frameType?: PanelType;
  origin?: string;
  useLastPanelPosition?: boolean;
  stageBehaviour?: {
    open: StageBehaviour;
    close: StageBehaviour;
  };
  emptyStateSectionReplacement?: boolean;
  url?: string;

  category?: string;
  sectionId?: string;
  section?: string;
  stageEntryIndex?: number;
  zoomModeParams?: {
    originalScrollY?: number;
    origin?: string;
  };
  onClose?: () => void;

  urlParams?: Record<string, string>;

  openPanelBiEvent?: unknown;
  openPanelBiParams?: unknown;
  openPanelInteraction?: InteractionsMap;
  firstPresetSectionLoadInteraction?: InteractionsMap;
  closePanelInteraction?: InteractionsMap;
  showPreloader?: boolean;
  preloaderHiddenBeforeShown?: boolean;
  preloaderReachedTimeout?: boolean;
  scrolling?: 'auto' | 'yes' | 'no';

  section_correlation_id?: string;
  panel_correlation_id?: string;
  style?: React.CSSProperties;
  biParams?: object;
}

export interface PanelDescriptor {
  name: string;
  props: PanelProps;
  frameType?: PanelType;
  singleInstance?: boolean;
  closeWithUserIntent?: boolean;
  openTime?: number;
  // isHidden?: boolean;
  token?: string;
  resolve?: (value?: unknown) => void;
  panelLoader?: () => Promise<React.ComponentType>;
}

export interface PanelOptions {
  leavePanelsOpen?: boolean;
  panelLoader?: () => Promise<React.ComponentType>;
  resolve?: () => void;
}

export interface ComponentPanelOptions {
  leavePanelsOpen?: boolean;
  panelMetaData?: object;
  panelLoader?: () => Promise<React.ComponentType>;
}

export type PanelFilterFunction = (panelDescriptor: PanelDescriptor) => boolean;

export interface SidePanelProps extends PanelProps {
  initialData?: Record<string, unknown>;
  displayAboveModals?: boolean;
}
