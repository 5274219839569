// @ts-nocheck
import * as actionTypes from './topBarActionTypes';

const overrideItems = (overridden) => ({
  type: actionTypes.OVERRIDE_TOP_BAR,
  overridden,
});

const setAdiTooltipVisibility = (visibility) => ({
  type: actionTypes.SET_ADI_TOOLTIP_VISIBILITY,
  visibility,
});

const setBannerTitle = (bannerTitle) => ({
  type: actionTypes.SET_BANNER_TITLE,
  bannerTitle,
});

export { overrideItems, setAdiTooltipVisibility, setBannerTitle };
