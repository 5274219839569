import * as actionTypes from './unifiedChatActionTypes';
import * as util from '@/util';
import type { ThunkAction } from 'types/redux';

const setChatIsLoaded = () => ({
  type: actionTypes.SET_CHAT_IS_LOADED,
});

const setChatVisibility = (isVisible: boolean) => ({
  type: actionTypes.SET_CHAT_VISIBILITY,
  isVisible,
});

const showOrHideChat =
  (show: boolean): ThunkAction =>
  (dispatch) => {
    if (show) {
      util.chatManager.showChat();
    } else {
      util.chatManager.hideChat();
    }
    return dispatch(setChatVisibility(show));
  };

export { setChatIsLoaded, setChatVisibility, showOrHideChat };
