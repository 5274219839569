export default {
  POPUP_CONTAINER: '177d4dd7-8989-4671-b2f3-dc3ddbb8fa10',
  POPUP_OVERLAY: '11aef2b1-64f8-4dbc-96ad-d95b91ce746a',
  CLOSE_BUTTON: '709ef0d6-5568-4019-8e5a-fd3702b4df43',
  FILE_UPLOADER: '1fd85a63-f011-417b-9901-6e011f7c36b9',
  MEDIA_BIG_PLAY_ALPHA: '65edcd2c-4691-435d-8a70-fd1993e2e8f6',
  GOOGLE_MAP: '875471c5-d940-43c2-a38d-b1d392c3c325',
  MOBILE_BIG_PLAY: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
  MOBILE_ONLY_COMP: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
  MOBILE_COMP_FROM_DESKTOP: 'e570708a-4fbc-4ab8-9ded-028fedf752c4',
};
