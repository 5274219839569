// @ts-nocheck
import _ from 'lodash';

const getMultilingualProp = (selector) => (state) =>
  _.get(state.multilingual, selector);

export default {
  supportedLanguages: getMultilingualProp('supportedLanguages'),
  siteTranslatablesProperties: getMultilingualProp(
    'siteTranslatablesProperties',
  ),
  machineTranslationLimit: getMultilingualProp('machineTranslationLimit'),
  loading: getMultilingualProp('loading'),
  isChanging: getMultilingualProp('isChanging'),
  isTranslating: getMultilingualProp('isTranslating'),
  nextLanguage: getMultilingualProp('nextLanguage'),
  isAutoTranslated: getMultilingualProp('isAutoTranslated'),
  isQuickEditAutoOpened: getMultilingualProp('isQuickEditAutoOpened'),
};
