// @ts-nocheck

import _ from 'lodash';
import constants from '@/constants';
import * as panelActions from '../panels/panelsActions';
import actionsTypes from './multilingualActionTypes';
import * as utils from './multilingualUtils';
import selectors from './multilingualSelectors';
import localConstants from './multilingualConstants';
import requests from './multilingualRequestSignatures';
import * as userPreferencesSelectors from '../userPreferences/userPreferencesSelectors';
import * as previewSelectors from '../preview/previewSelectors';
import * as biActions from '../bi/biActions';
import experiment from 'experiment';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import type { ThunkAction } from 'types/redux';
import * as stateManagement from '@/stateManagement';
import { MultilingualPages, type AutoTranslationPayload } from './types';
import type { TpaObject } from '@wix/document-services-types';
import { translate } from '@/i18n';
const {
  LOAD_SUPPORTED_LANGUAGES,
  LOAD_SUPPORTED_LANGUAGES_SUCCESS,
  LOAD_SUPPORTED_LANGUAGES_ERROR,
  GET_SITE_TRANSLATABLES_PROPERTIES,
  GET_SITE_TRANSLATABLES_PROPERTIES_SUCCESS,
  GET_SITE_TRANSLATABLES_PROPERTIES_ERROR,
  GET_MACHINE_TRANSLATION_LIMIT,
  GET_MACHINE_TRANSLATION_LIMIT_SUCCESS,
  GET_MACHINE_TRANSLATION_LIMIT_ERROR,
  MULTILINGUAL_LOAD_START,
  MULTILINGUAL_LOAD_STOP,
  LANGUAGE_CHANGE_START,
  LANGUAGE_CHANGE_SUCCESS,
  SET_IS_TRANSLATING,
  SET_IS_PRELOADING,
  SET_IS_AUTO_TRANSLATED,
  SET_QUICK_EDIT_AUTO_OPENED,
} = actionsTypes;

const {
  MULTILINGUAL: { LANGUAGE_STATUSES, linguistDefId },
} = constants;
const {
  LOAD_LANGUAGES,
  CHECK_LANGUAGE_STATUS,
  INITIALIZE_LANGUAGES,
  ADD_LANGUAGE,
  UPDATE_LANGUAGE_STATUS,
} = localConstants.dataLoadTypes;

const { supportedLanguages } = selectors;

const actions = {
  loadSupportedLanguages: () => ({ type: LOAD_SUPPORTED_LANGUAGES }),
  loadSupportedLanguagesSuccess: (payload) => ({
    type: LOAD_SUPPORTED_LANGUAGES_SUCCESS,
    payload,
  }),
  getSiteTranslatablesProperties: () => ({
    type: GET_SITE_TRANSLATABLES_PROPERTIES,
  }),
  getSiteTranslatablesPropertiesSuccess: (payload) => ({
    type: GET_SITE_TRANSLATABLES_PROPERTIES_SUCCESS,
    payload,
  }),
  getSiteTranslatablesPropertiesError: (error) => ({
    type: GET_SITE_TRANSLATABLES_PROPERTIES_ERROR,
    error,
  }),
  getMachineTranslationLimit: () => ({
    type: GET_MACHINE_TRANSLATION_LIMIT,
  }),
  getMachineTranslationLimitSuccess: (payload) => ({
    type: GET_MACHINE_TRANSLATION_LIMIT_SUCCESS,
    payload,
  }),
  getMachineTranslationLimitError: (error) => ({
    type: GET_MACHINE_TRANSLATION_LIMIT_ERROR,
    error,
  }),
  loadSupportedLanguagesError: (error) => ({
    type: LOAD_SUPPORTED_LANGUAGES_ERROR,
    error,
  }),
  multilingualLoadStart: (payload) => ({
    type: MULTILINGUAL_LOAD_START,
    payload,
  }),
  multilingualLoadStop: (payload) => ({
    type: MULTILINGUAL_LOAD_STOP,
    payload,
  }),
  startLanguageChange: (payload) => ({ type: LANGUAGE_CHANGE_START, payload }),
  completeLanguageChange: (payload) => ({
    type: LANGUAGE_CHANGE_SUCCESS,
    payload,
  }),
  setIsTranslating: (payload) => ({ type: SET_IS_TRANSLATING, payload }),
  setIsPreloading: (isPreloading: boolean) => ({
    type: SET_IS_PRELOADING,
    payload: isPreloading,
  }),
  setIsAutoTranslated: (payload: boolean) => ({
    type: SET_IS_AUTO_TRANSLATED,
    payload,
  }),
  setQuickEditAutoOpened: (payload: boolean) => ({
    type: SET_QUICK_EDIT_AUTO_OPENED,
    payload,
  }),

  autoTranslate:
    ({ languageCode }: AutoTranslationPayload = {}): ThunkAction =>
    async (dispatch, _getState, { editorAPI, dsActions }) => {
      try {
        dispatch(actions.setIsPreloading(true));
        await dsActions.waitForChangesAppliedAsync();
        await utils.autoTranslateFromServer(editorAPI, languageCode);
        dsActions.save();
        editorAPI.history.add('auto translate');
      } catch (error) {
        throw error;
      } finally {
        dispatch(actions.setIsPreloading(false));
      }
    },
  autoTranslateWithUIEffects:
    (options?: AutoTranslationPayload): ThunkAction =>
    async (dispatch, _getState, { dsActions }) => {
      try {
        // Must be sequential
        await dispatch(actions.autoTranslate(options));
        dsActions.save(); // We don't wait for this - it'll run asynchronously
        await dispatch(
          stateManagement.multilingual.actions.getMachineTranslationLimitThunk(),
        );
      } catch (error) {
        dispatch(
          stateManagement.panels.actions.updateOrOpenPanel(
            'panels.focusPanels.googleTranslateFailed',
            {
              intent: 'auto',
              biFields: {
                referrer: options?.referrer || 'mode-bar',
                error_type: error.message,
                process_type: 'auto_translation',
              },
            },
          ),
        );
        return;
      }

      dispatch(stateManagement.multilingual.actions.setIsAutoTranslated(true));
      dispatch(actions.showNumCreditsLeft());
    },

  showNumCreditsLeft:
    (): ThunkAction =>
    (dispatch, _getState, { editorAPI }) => {
      const state = editorAPI.store.getState();
      const machineTranslationLimit =
        stateManagement.multilingual.selectors.machineTranslationLimit(state);

      const wordsLeft =
        machineTranslationLimit.machineTranslateWordsLimit -
        machineTranslationLimit.machineTranslationWordsUsed;

      const messageKey =
        wordsLeft === 1
          ? 'google_translate_enoughCredits_snackbar_creditsLeft'
          : 'google_translate_enoughCredits_snackbar_creditsLeft_plural';

      dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message: translate(messageKey, {
            machine_translation_words_left: wordsLeft,
          }),
          type: 'success',
          shouldTranslate: false,
        }),
      );
    },

  showGoogleTranslateFailedNotification:
    (refreshMethod: () => void): ThunkAction =>
    (dispatch, _getState) => {
      const refershMethodWithDispatch = () => dispatch(refreshMethod());

      dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message: 'google_translate_enoughCredits_snackbar_error_message',
          type: 'error',
          link: {
            caption: 'google_translate_enoughCredits_snackbar_error_refresh',
            onNotificationLinkClick: refershMethodWithDispatch,
          },
        }),
      );
    },
};

const getData = (res) => res.data;
const getInstanceId = (tpa) =>
  tpa.app.getDataByAppDefId(linguistDefId)?.instanceId;
const getInstance = (tpa: TpaObject) => {
  return tpa.app.getDataByAppDefId(linguistDefId)?.instance;
};

const initLanguageData =
  (initLanguages) =>
  ({ originalLanguage, translationLanguages }) =>
    initLanguages({
      originalLanguage: utils.addLanguageCode(originalLanguage[0]),
      translationLanguages: translationLanguages.map(utils.addLanguageCode),
    });

const sync = (fn) => (action) => {
  fn(action.payload);
  return action;
};

const attachProducer = _.curry((action, producer, payload) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  action(_.assign({}, payload, { producer })),
);
const loadStartAttachProducer = attachProducer(actions.multilingualLoadStart);
const loadStopAttachProducer = attachProducer(actions.multilingualLoadStop);

const callAjaxAndProduceEffect =
  (dispatch, producer, effect = _.identity) =>
  (payload, prepData = getData) => {
    dispatch(loadStartAttachProducer(producer, payload));

    return utils
      .callAjax(payload)
      .then(prepData)
      .then(loadStopAttachProducer(producer))
      .then(sync(effect))
      .catch(loadStopAttachProducer(producer))
      .then(dispatch);
  };

const loadLanguagesThunk =
  (): ThunkAction =>
  async (
    dispatch,
    getState,
    {
      dsRead: { tpa },
      _editorAPI,
      dsActions: {
        waitForChangesAppliedAsync,
        language: { init },
      },
    },
  ) => {
    const instance = getInstance(tpa);
    dispatch(actions.setIsTranslating(false));

    await callAjaxAndProduceEffect(
      dispatch,
      LOAD_LANGUAGES,
      initLanguageData(init),
    )(requests.loadLanguagesServerless(instance));

    await waitForChangesAppliedAsync();
  };

const getNotUsedRandomLanguage = (state, selectedLanguage) => {
  const localSupportedLanguages = _.reject(supportedLanguages(state), {
    languageCode: selectedLanguage.languageCode,
  });
  return localSupportedLanguages[_.random(localSupportedLanguages.length - 1)];
};

const isOnboardingAppPanel = ({ props: { appDefinitionId } }) =>
  appDefinitionId === linguistDefId;

const save = (saveManager) =>
  new Promise((resolve, reject) =>
    saveManager.saveInBackground(resolve, reject, 'multilingual'),
  ).then(() => true);

const initializeAppThunk =
  () =>
  (dispatch, getState, { editorAPI, dsRead }) => {
    const prepState = () =>
      history.add('before adding multilingual platform app');
    const {
      saveManager,
      panelManager,
      history,
      tpa: { services },
    } = editorAPI;
    let result;
    if (dsRead.generalInfo.isFirstSave() || dsRead.generalInfo.isDraft()) {
      prepState();
      result = save(saveManager);
    } else {
      result = Promise.resolve(false);
    }
    return result
      .then((isFirstSave) => {
        let res;
        const installedInstanceId = getInstanceId(dsRead.tpa);
        if (!editorAPI.dsRead.platform.isAppActive(linguistDefId)) {
          util.fedopsLogger.interactionStarted(
            util.fedopsLogger.INTERACTIONS.INIT_MULTILINGUAL,
          );
          if (!isFirstSave) {
            prepState();
          }
          if (experiment.isOpen('se_multilingualHideOnboardingPanel')) {
            dispatch(
              panelActions.addPanelFilter(
                'ml_onboarding_platform_app_panel',
                isOnboardingAppPanel,
              ),
            );
          }

          const addAppPromise = new Promise((resolve, reject) => {
            import('@/tpa').then((tpa) => {
              const type = tpa.constants.APP.TYPE.PLATFORM_ONLY;
              const biInitiator = tpa.constants.BI.initiator.TOP_BAR;
              const platformOrigin = {
                type: 'CLASSIC',
                initiator: tpa.constants.BI.initiator.EDITOR,
                info: {
                  type: tpa.constants.BI.initiator.TOP_BAR,
                },
              };
              const appData = dsRead.tpa.app.getDataByAppDefId(linguistDefId);

              const callbackChecker = (data) => {
                if (data.success) {
                  resolve(data);
                } else {
                  reject(data);
                }
              };
              tpa.services.tpaAddAppService.addApp(
                editorAPI,
                linguistDefId,
                'multilingual',
                appData,
                type,
                biInitiator,
                false /* isPermissionsApp */,
                {} /* biData */,
                {} /* options */,
                callbackChecker,
                platformOrigin,
              );
            });
          });

          res = Promise.all([false, addAppPromise]);
        } else {
          res = Promise.resolve([true, installedInstanceId]);
        }
        return res;
      })
      .catch((e) => {
        history.undo();
        services.tpaAlertsService.openProvisionFailedAlert(
          panelManager.openPanel,
          linguistDefId,
          false,
        );
        throw e;
      });
  };

const initializeLanguagesThunk =
  (primaryLanguage) =>
  (
    dispatch,
    getState,
    {
      dsActions: {
        language: { init },
      },
      dsRead: { tpa },
      editorAPI: {
        panelManager,
        tpa: { services },
      },
    },
  ) => {
    const instanceId = getInstanceId(tpa);
    const initialize = {
      primaryLang: utils.pickLanguageCode(primaryLanguage),
      additionalLang: utils.pickLanguageCode(
        getNotUsedRandomLanguage(getState(), primaryLanguage),
      ),
    };
    const request = requests.initLanguages(instanceId, initialize);
    dispatch(loadStartAttachProducer(INITIALIZE_LANGUAGES, request));

    return utils
      .callAjax(request)
      .then(getData)
      .then(loadStopAttachProducer(INITIALIZE_LANGUAGES))
      .then(sync(initLanguageData(init)))
      .catch((e) => {
        services.tpaAlertsService.openProvisionFailedAlert(
          panelManager.openPanel,
          linguistDefId,
          false,
        );
        return loadStopAttachProducer(INITIALIZE_LANGUAGES)(e);
      })
      .then(dispatch);
  };

const addNewLanguage =
  (newLanguage) =>
  (dispatch, getState, { dsRead: { tpa }, dsActions: { language } }) => {
    const instanceId = getInstanceId(tpa);

    return callAjaxAndProduceEffect(
      dispatch,
      ADD_LANGUAGE,
      _.flow(utils.addLanguageCode, language.add),
    )(requests.addNewLanguage(instanceId, newLanguage));
  };

const updateLanguageStatusThunk =
  (languageId, status) =>
  (
    dispatch,
    getState,
    {
      dsRead: { tpa },
      dsActions: {
        language: {
          status: { set },
        },
      },
    },
  ) => {
    const instanceId = getInstanceId(tpa);

    return callAjaxAndProduceEffect(dispatch, UPDATE_LANGUAGE_STATUS, (lang) =>
      set(utils.addLanguageCode(lang), status),
    )(requests.updateLanguageStatus(instanceId, languageId, status));
  };

const addLanguageThunk =
  (newLanguage) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const translationLanguages = dsRead.language.getFull();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const deletedLanguage = _.find(
      translationLanguages,
      _.pick(newLanguage, ['languageCode', 'locale']),
    );

    let nextAction;
    if (deletedLanguage) {
      nextAction = updateLanguageStatusThunk(
        newLanguage.languageCode,
        LANGUAGE_STATUSES.INACTIVE,
      );
    } else {
      nextAction = addNewLanguage(newLanguage);
    }

    return nextAction(dispatch, getState, { dsRead, dsActions });
  };

const checkLanguageStatusThunk =
  () =>
  (
    dispatch,
    getState,
    {
      dsRead: {
        tpa,
        generalInfo: { getMetaSiteId },
      },
    },
  ) => {
    const instanceId = getInstanceId(tpa);

    if (!instanceId) {
      return Promise.resolve(actions.multilingualLoadStop({ status: false }));
    }
    const metaSiteInstance = tpa.app.getData(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance;
    return callAjaxAndProduceEffect(dispatch, CHECK_LANGUAGE_STATUS)(
      requests.checkLanguageAppStatus(getMetaSiteId(), metaSiteInstance),
      (response) => ({ status: !!response.properties.multilingual }),
    );
  };

const getSiteTranslatablesPropertiesThunk =
  (secondaryLanguage?: LanguageDefinition): ThunkAction =>
  async (dispatch, getState, { dsRead: { tpa, language } }) => {
    const instance = getInstance(tpa);

    if (language === undefined) {
      return; // quit in case of degenerate early-init
    }

    const originalLanguage = language.original.get();
    const currentLanguageCode = secondaryLanguage
      ? secondaryLanguage.code
      : language.current.get();
    const currentLanguageCountryCode = secondaryLanguage
      ? secondaryLanguage.countryCode
      : language.get().find((lang) => lang?.code === currentLanguageCode)
          ?.countryCode;

    dispatch(actions.getSiteTranslatablesProperties());
    dispatch(actions.getSiteTranslatablesPropertiesSuccess(null));
    try {
      const { data } = await utils.callAjax(
        requests.getSiteTranslatablesProperties(
          instance,
          originalLanguage.code,
          originalLanguage.countryCode,
          currentLanguageCode,
          currentLanguageCountryCode,
        ),
      );
      dispatch(actions.getSiteTranslatablesPropertiesSuccess(data));
    } catch (e) {
      dispatch(actions.getSiteTranslatablesPropertiesError());
    }
  };

const getMachineTranslationLimitThunk =
  (): ThunkAction =>
  async (dispatch, getState, { dsRead: { tpa } }) => {
    const instance = getInstance(tpa);

    dispatch(actions.getMachineTranslationLimit());
    dispatch(actions.getMachineTranslationLimitSuccess(null));
    try {
      const { properties } = await utils.callAjax(
        requests.getMachineTranslationLimit(instance),
      );
      dispatch(actions.getMachineTranslationLimitSuccess(properties));
    } catch (e) {
      dispatch(actions.getMachineTranslationLimitError());
    }
  };

const loadSupportedLanguagesThunk = () => (dispatch) => {
  dispatch(actions.loadSupportedLanguages());
  return utils
    .callAjax(requests.loadSupportedLanguages())
    .then(getData)
    .then(
      actions.loadSupportedLanguagesSuccess,
      actions.loadSupportedLanguagesError,
    )
    .then(dispatch);
};

const setCurrentLanguageThunk =
  (languageCode): ThunkAction =>
  async (
    dispatch,
    getState,
    {
      editorAPI,
      dsActions: {
        language: writeLang,
        documentMode,
        waitForChangesAppliedAsync,
      },
      dsRead: { language: readLang },
    },
  ) => {
    const shouldStopTranslating = readLang.original.get().code === languageCode;
    const isTranslating = selectors.isTranslating(getState());
    const isPreview = previewSelectors.getPreviewMode(getState());

    // If we're switching to secondary language, disable measure changes BEFORE the swap.
    if (!shouldStopTranslating && !isPreview) {
      documentMode.enableShouldUpdateJsonFromMeasureMap(false);
      await waitForChangesAppliedAsync();
    }

    writeLang.current.set(languageCode);

    // If we're going back to primary language, enable measure changes AFTER the swap.
    if (shouldStopTranslating && !isPreview) {
      await waitForChangesAppliedAsync();
      documentMode.enableShouldUpdateJsonFromMeasureMap(true);
    }

    if (experiment.isOpen('se_openQuickEditWhenSecondaryLanguageSelected')) {
      await waitForChangesAppliedAsync();

      const shouldStartTranslating = !isTranslating && !shouldStopTranslating;
      if (shouldStartTranslating && !utils.isQuickEditOpened(editorAPI)) {
        const openQuickEdit = isPreview
          ? utils.openQuickEditOnExitPreview
          : utils.openQuickEdit;

        openQuickEdit(editorAPI, {
          onOpen: () => dispatch(actions.setQuickEditAutoOpened(true)),
          onClose: () => dispatch(actions.setQuickEditAutoOpened(false)),
        });
      }

      if (shouldStopTranslating) {
        if (isPreview) {
          utils.cancelOpenQuickEditOnExitPreview();
        }
        if (selectors.isQuickEditAutoOpened(getState())) {
          utils.closeQuickEdit(editorAPI);
        }
      }

      const isSwitchingBetweenSecondaryLanguages =
        !shouldStartTranslating && !shouldStopTranslating;
      if (isSwitchingBetweenSecondaryLanguages) {
        utils.updateLanguageInQuickEditTitle(editorAPI, languageCode);
      }
    }

    dispatch(actions.setIsTranslating(!shouldStopTranslating));
    dispatch(actions.setIsAutoTranslated(false));
  };

const addLanguageSelectorToSiteHeaderThunk =
  (languageSelectorPreset): ThunkAction =>
  (
    dispatch,
    getState,
    {
      dsRead: { siteSegments, components: readComponents },
      editorAPI: { selection, components, language, pages },
    },
  ) => {
    const siteHeader = siteSegments.getHeader();
    const masterPage = siteSegments.getSiteStructure();
    const getContainer = () =>
      components.layout.get_fixedPosition(siteHeader) ? siteHeader : masterPage;

    // Only automatically add a language picker component if there's not already one on the stage
    if (
      readComponents.get.byType(languageSelectorPreset.structure.componentType)
        .length === 0
    ) {
      const container = experiment.isOpen('se_multilingualShowUouOnTop')
        ? getContainer()
        : siteHeader;

      components.add(
        container,
        languageSelectorPreset.structure,
        undefined,
        (compRef: CompRef) => {
          components.arrangement.moveToFront(compRef);
          selection.selectComponentByCompRef(compRef);
        },
      );
      dispatch(
        biActions.event(coreBi.events.multilingual.language_menu_added_ob, {
          container_id: container.id,
          main_language: language.original.get()?.code,
          page_id: pages.getCurrentPageId(),
        }),
      );
    }
  };

const sendOpenOnboardingEventThunk =
  (biOrigin: string) =>
  (dispatch, getState, { editorAPI }) => {
    const { applicationId } =
      editorAPI.platform.getAppDataByAppDefId(linguistDefId);
    editorAPI.dsActions.platform.notifyApplication(applicationId, {
      eventType: 'openOnboarding',
      eventPayload: {
        origin: biOrigin,
      },
    });
  };

const sendOpenDashboardEventThunk =
  (
    origin: string,
    pageToOpen: MultilingualPages,
    currentLanguage?: string,
    sidePanelSection?: string,
  ) =>
  (dispatch, getState, { editorAPI }) => {
    const eventPayload = {
      origin,
      pageToOpen,
    };
    if (currentLanguage) {
      eventPayload.currentLanguage = currentLanguage;
    }
    if (sidePanelSection) {
      eventPayload.sidePanelSection = sidePanelSection;
    }

    const { applicationId } =
      editorAPI.platform.getAppDataByAppDefId(linguistDefId);
    editorAPI.dsActions.platform.notifyApplication(applicationId, {
      eventType: 'openDashboard',
      eventPayload,
    });
  };

const sendOpenPackagePickerEventThunk =
  (origin, mainLanguage, secondaryLanguage, wordsToTranslate) =>
  (dispatch, getState, { editorAPI }) => {
    const state = editorAPI.store.getState();
    const { machineTranslateWordsLimit, machineTranslationWordsUsed } =
      stateManagement.multilingual.selectors.machineTranslationLimit(state);
    const wordsAvailable =
      machineTranslateWordsLimit - machineTranslationWordsUsed;
    const hasEnoughWords = wordsAvailable >= wordsToTranslate;
    const modalTitle = translate(
      'Multilingual_Mode_TranslationPackage_PackagePicker_Header',
    );
    dispatch(
      biActions.event(coreBi.events.multilingual.get_more_credit_clicked, {
        words_in_credit:
          machineTranslateWordsLimit - machineTranslationWordsUsed,
        words_translated: wordsToTranslate,
        origin,
        is_credit_enough: hasEnoughWords,
        words_remain: hasEnoughWords ? wordsAvailable - wordsToTranslate : 0,
        words_needed: hasEnoughWords ? 0 : wordsToTranslate - wordsAvailable,
      }),
    );
    const { applicationId } =
      editorAPI.platform.getAppDataByAppDefId(linguistDefId);
    editorAPI.dsActions.platform.notifyApplication(applicationId, {
      eventType: 'openPackagePicker',
      eventPayload: {
        wordsInCredit: machineTranslateWordsLimit - machineTranslationWordsUsed,
        mainLanguage,
        secondaryLanguage,
        origin,
        translatedWords: wordsToTranslate,
        wordsRemain: hasEnoughWords ? wordsAvailable - wordsToTranslate : 0,
        wordsNeeded: hasEnoughWords ? 0 : wordsToTranslate - wordsAvailable,
        hasEnoughWords,
        modalTitle,
      },
    });
  };

interface MLAppOptions {
  biOrigin: string;
  scrollToContent: boolean;
}

const notifyMultilingualAppToOpen =
  (options: Partial<MLAppOptions> = {}) =>
  (dispatch, getState, { dsRead: { language } }) =>
    new Promise((resolve) => {
      dispatch(initializeAppThunk()).then(([alreadyInstalled]) => {
        if (alreadyInstalled) {
          if (!language.getFull().length) {
            dispatch(sendOpenOnboardingEventThunk(options.biOrigin));
            resolve();
          } else {
            if (options.biOrigin) {
              dispatch(
                biActions.event(
                  coreBi.events.multilingual.dashboard_start_open,
                  {
                    origin: options.biOrigin,
                    lang: language.current.get(),
                  },
                ),
              );
            }
            dispatch(
              sendOpenDashboardEventThunk(
                options.biOrigin,
                MultilingualPages.MANAGE_LANGUAGES,
              ),
            );
            resolve();
          }
        }
      });
    });

const openLanguageSelectorThunk =
  () =>
  (dispatch, getState, { editorAPI }) => {
    editorAPI.topBarMenuBar.openDropDown(
      constants.ROOT_COMPS.TOPBAR.DROP_PANELS.LANGUAGE_DROP_PANEL,
    );
  };

const switchToOriginalLanguageThunk =
  (): ThunkAction =>
  (dispatch, getState, { dsRead }) => {
    const originalLanguage = dsRead.language.original.get();
    if (originalLanguage) {
      dispatch(actions.startLanguageChange(originalLanguage.code));
    }
  };

const switchToSecondLanguageThunk =
  (): ThunkAction =>
  (dispatch, getState, { dsRead }) => {
    const translationLanguages = dsRead.language.getFull();
    if (translationLanguages.length > 0 && translationLanguages[0].code) {
      dispatch(actions.startLanguageChange(translationLanguages[0].code));
    }
  };

const syncIsTranslatingStateThunk =
  (): ThunkAction =>
  (dispatch, getState, { dsRead: { language } }) => {
    if (
      language === undefined ||
      language.current === undefined ||
      language.original === undefined ||
      language.original.get().code === undefined
    ) {
      return; // Language services still initializing
    }

    if (language.current.get() === null) {
      // User installed multilingual but eventually deleted all secondary languages
      dispatch(actions.setIsTranslating(false));
      return;
    }

    const dsTranslatingState =
      language.current.get() !== language.original.get().code;
    const isTranslatingState = selectors.isTranslating(getState());

    if (dsTranslatingState !== isTranslatingState) {
      dispatch(actions.setIsTranslating(dsTranslatingState));
    }
  };

const selectBiggestTextThunk =
  () =>
  (
    dispatch,
    getState,
    {
      editorAPI,
      dsRead: {
        components: { layout, get },
      },
    },
  ) => {
    const textComps = get.byType('wysiwyg.viewer.components.WRichText');

    if (textComps.length === 0) {
      return;
    }

    const textsWithLayout = textComps.map((compRef) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(compRef, { layout: layout.getRelativeToScreen(compRef) }),
    );
    const textsAboveFold = textsWithLayout.filter(
      (compRef) => compRef.layout.y <= window.innerHeight,
    );

    const biggestText = _.maxBy(
      textsAboveFold.length > 0 ? textsAboveFold : textsWithLayout,
      (compRef) => {
        const compSize = layout.getEffectiveTextDimensions(compRef);
        if (!compSize) {
          // 'empty' text components
          return 0;
        }
        return compSize.width * compSize.height;
      },
    );

    const scrollTop = textsAboveFold.length > 0 ? 0 : biggestText.layout.y - 50;
    editorAPI.scroll.scrollTo({ scrollLeft: biggestText.layout.x, scrollTop });
    editorAPI.selection.selectComponentByCompRef(biggestText);
  };

const dataUpdated = (languageSelectorPreset) => async (dispatch) => {
  await dispatch(loadLanguagesThunk());
  dispatch(addLanguageSelectorToSiteHeaderThunk(languageSelectorPreset));

  dispatch(switchToSecondLanguageThunk());

  dispatch(selectBiggestTextThunk());

  await util.multilingual.localeDatasetTranslationsFetcher.addTranslations();

  dispatch(openLanguageSelectorThunk());
};

const componentChangedUndoSendBi = (origin, biParams?) => (dispatch) => {
  dispatch(
    biActions.event(coreBi.events.editor.undo_redo_component_change, {
      type: 'undo',
      origin,
      pageId: biParams?.currentPage,
      date_of_action: biParams?.date_of_action,
      actionName: biParams?.label,
      component_id: biParams?.component_id,
      component_type: biParams?.component_type,
    }),
  );
};

const componentChanged =
  () =>
  (dispatch, getState, { editorAPI }) => {
    if (!editorAPI.language.isCurrentLanguageSecondary()) {
      return;
    }
    const { getSiteUserPreferences } = userPreferencesSelectors;
    const { DONT_SHOW_AGAIN: didShowWarning } =
      constants.USER_PREFS.MULTILINGUAL.COMPONENT_CHANGED_WARNING;
    if (!getSiteUserPreferences(didShowWarning)(editorAPI.store.getState())) {
      dispatch(
        panelActions.updateOrOpenPanel(
          'panels.focusPanels.multilingualComponentChangedPanel',
        ),
      );
    }
  };

const openGoogleTranslateWarningAndTranslate =
  (
    contentLength: number,
    wordsRemain: number,
    translate: () => Promise<void>,
    source: string,
    translatedWords: number,
  ): ThunkAction =>
  (dispatch, _getState, { editorAPI }) => {
    const { getSiteUserPreferences } = userPreferencesSelectors;
    const { DONT_SHOW_AGAIN: didShowWarning } =
      constants.USER_PREFS.MULTILINGUAL.GOOGLE_TRANSLATE_WARNING;
    const shouldShowGTWarning = !getSiteUserPreferences(didShowWarning)(
      editorAPI.store.getState(),
    );
    const leavePanelsOpen = false;
    if (shouldShowGTWarning)
      dispatch(
        panelActions.updateOrOpenPanel(
          'panels.focusPanels.googleTranslateWarningPanel',
          { translate, contentLength, wordsRemain, source, translatedWords },
          leavePanelsOpen,
        ),
      );
    else {
      translate();
    }
  };

export interface MultilingualAppStoreData {
  data: {
    translationLanguages: Array<{
      code: string;
      machineTranslationLanguageCode: string;
    }>;
  };
}

export default // TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/assign
_.assign(actions, {
  initializeAppThunk,
  addLanguageSelectorToSiteHeaderThunk,
  openLanguageSelectorThunk,
  addLanguageThunk,
  loadLanguagesThunk,
  loadSupportedLanguagesThunk,
  getSiteTranslatablesPropertiesThunk,
  getMachineTranslationLimitThunk,
  updateLanguageStatusThunk,
  checkLanguageStatusThunk,
  initializeLanguagesThunk,
  setCurrentLanguageThunk,
  switchToOriginalLanguageThunk,
  switchToSecondLanguageThunk,
  syncIsTranslatingStateThunk,
  sendOpenOnboardingEventThunk,
  sendOpenDashboardEventThunk,
  sendOpenPackagePickerEventThunk,
  notifyMultilingualAppToOpen,
  openGoogleTranslateWarningAndTranslate,
  selectBiggestTextThunk,
  dataUpdated,
  componentChanged,
  componentChangedUndoSendBi,
});
