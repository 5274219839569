import * as updatePropertiesPlugins from './updatePropertiesPlugins/updatePropertiesPlugins';
import type {
  DSRead,
  DSAction,
  CompRef,
  ComponentProperties,
} from 'types/documentServices';

/**
 *
 * @param properties Properties to update
 * @param compRef Component Pointer
 * @param ds Document Services
 * @param isMobileEditor Is in Mobile Editor Mode (Since Properties can differ between mobile and desktop)
 */
function fix(
  properties: Partial<ComponentProperties> & { type?: string },
  compRef: CompRef,
  dsRead: DSRead,
  dsActions: DSAction,
  isMobileEditor: boolean,
) {
  const propType = properties.type || 'DefaultProperties';
  if (
    updatePropertiesPlugins[propType as keyof typeof updatePropertiesPlugins]
  ) {
    updatePropertiesPlugins[propType as keyof typeof updatePropertiesPlugins](
      properties,
      compRef,
      dsRead,
      dsActions,
      isMobileEditor,
    );
  }
  return properties;
}

export { fix as fixProperties };
