interface DragMovePosition {
  x: number;
  y: number;
}

interface DragMovePositionDelta {
  deltaX: number;
  deltaY: number;
}

export function getDragMovePositionDelta<TLayout extends DragMovePosition>(
  mousePositionInitial: TLayout,
  mousePositionUpdated: TLayout,
  isShiftPressed?: boolean,
): DragMovePositionDelta {
  const deltaX = mousePositionUpdated.x - mousePositionInitial.x;
  const deltaY = mousePositionUpdated.y - mousePositionInitial.y;

  if (isShiftPressed) {
    return Math.abs(deltaX) >= Math.abs(deltaY)
      ? { deltaX, deltaY: 0 }
      : { deltaX: 0, deltaY };
  }

  return { deltaX, deltaY };
}
