import _ from 'lodash';

import actionTypes from './notificationsActionTypes';

import type { NotificationsState } from './notificationsTypes';

export const notificationsInitialState: NotificationsState = [];

export default (
  state = notificationsInitialState,
  action: any,
): NotificationsState => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION:
      return state.concat([action.notification]);
    case actionTypes.CLOSE_NOTIFICATION:
      return _.reject(state, ['id', action.id]);
    case actionTypes.CLOSE_ALL_NOTIFICATIONS:
      return [];
    case actionTypes.RESET_NOTIFICATION_TIMER:
      action.notificationState[action.index].timerId = action.timerId;
      action.notificationState[action.index].startTime = action.startTime;
      return action.notificationState;
    case actionTypes.CLEAR_NOTIFICATION_TIMER:
      clearTimeout(action.notification.timerId); // TODO: make it as a thunk since its only a side-effect
      return state;
    default:
      return state;
  }
};
