// @ts-nocheck
import * as savePublishSelectors from '../savePublish/savePublishSelectors';
import * as localModeSelectors from './localModeSelectors';

const { getIsLocalPushInProgress, getIsReconnecting } = localModeSelectors;
const { getIsPublishInProgress, getIsSaveInProgress } = savePublishSelectors;

export default function (state) {
  return (
    !getIsReconnecting(state) &&
    !(
      getIsPublishInProgress(state) ||
      getIsSaveInProgress(state) ||
      getIsLocalPushInProgress(state)
    )
  );
}
