// @ts-nocheck
import _ from 'lodash';
import { translate } from '@/i18n';
import * as formPlugin from '../../componentPreviewReadyPlugins/formPlugin';
import { isMeshLayoutEnabled } from '@/layout';
import type { BeforePlugin } from 'types/editorPlugins';

const DATA_THAT_NEEDS_TRANSLATION = {
  successMessage: 'Contact_Form_Settings_Messages_Success_message_default',
  validationErrorMessage:
    'Contact_Form_Settings_Messages_Error_message_mandatory_fields_default',
  errorMessage:
    'Contact_Form_Settings_Messages_Error_message_for_email_fields_default',
};

function translateFields(data) {
  _.forOwn(DATA_THAT_NEEDS_TRANSLATION, function (value, key) {
    data[key] = translate(value);
  });
}

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
) => {
  translateFields(compDef.data);
  formPlugin.updateDataWithDefaults(compDef.data, editorAPI);
  const compRef = originalAddComponentFunc(containerRef, compDef);

  if (isMeshLayoutEnabled()) {
    return {
      compRef,
      onChangesApplied: onCompAddCallback,
    };
  }

  if (onCompAddCallback) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      onCompAddCallback(compRef);
    });
  }

  return { compRef };
};

export default plugin;
