import { arrayUtils } from '@/util';
import {
  ensureItemLayoutIsFixedItemLayout,
  ensureLayoutSizeIsPx,
  hasFixedItemLayout,
  hasMeshItemLayout,
  layoutSize,
} from '@/layoutUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';
import type { LayoutMeshApi } from '../createLayoutMeshApi';
import type { LayoutMeshReparentApi } from '../layoutMeshReparentApi/createLayoutMeshReparentApi';
import type { HistoryApi } from '../../createHistoryApi';
import type { LayoutMoveByParams } from '../../layoutMoveApi';
import type { LayoutMoveByOptions } from '../../type';

export function createLayoutMeshMoveByApi({
  editorAPI,
  layoutMeshCoreApi,
  layoutMeshReparentApi,
  historyApi,
}: {
  editorAPI: EditorAPI;
  layoutMeshCoreApi: LayoutMeshApi['__core'];
  layoutMeshReparentApi: LayoutMeshReparentApi;
  historyApi: HistoryApi;
}) {
  function ensureComponentsAreWithMeshItemLayout(compRefs: CompRef[]) {
    if (
      !compRefs.every((compRef) =>
        hasMeshItemLayout(layoutMeshCoreApi.get(compRef)),
      )
    ) {
      throw new Error(
        'This function supports only components with MeshItemLayout',
      );
    }
  }

  function ensureComponentsAreWithFixedItemLayout(compRefs: CompRef[]) {
    if (
      !compRefs.every((compRef) =>
        hasFixedItemLayout(layoutMeshCoreApi.get(compRef)),
      )
    ) {
      throw new Error(
        'This function supports only components with FixedItemLayout',
      );
    }
  }

  /**
   * Move components by delta x and delta y in pixels (relative to initial position)
   *  - supports only components with FixedItemLayout (justify-self: start and align-self: start)
   *
   * @param compRefs - components with MeshItemLayout
   * @param params
   * @param params.deltaX - move by delta x in pixels
   * @param params.deltaY - move by delta y in pixels
   * @param options
   * @param options.dontAddToUndoRedoStack - don't add to undo/redo stack
   */
  async function moveBy_FixedItemLayout(
    compRefs: CompRef[],
    { deltaX = 0, deltaY = 0 }: LayoutMoveByParams,
    options: LayoutMoveByOptions = {},
  ) {
    ensureComponentsAreWithFixedItemLayout(compRefs);

    await editorAPI.transactions.run(async () => {
      compRefs.forEach((compRef) => {
        const { itemLayout } = layoutMeshCoreApi.get(compRef);

        ensureItemLayoutIsFixedItemLayout(itemLayout);

        if (
          itemLayout.justifySelf !== 'start' ||
          itemLayout.alignSelf !== 'start'
        ) {
          throw new Error(
            'moveBy for FixedItemLayout, supports only justify-self: start and align-self: start',
          );
        }

        ensureLayoutSizeIsPx(itemLayout.margins.left);
        ensureLayoutSizeIsPx(itemLayout.margins.top);

        const left = itemLayout.margins.left.value + deltaX;
        const top = itemLayout.margins.top.value + deltaY;

        layoutMeshCoreApi.update(compRef, {
          itemLayout: {
            ...itemLayout,
            margins: {
              ...itemLayout.margins,
              left: layoutSize.px(left),
              top: layoutSize.px(top),
            },
          },
        });
      });
    });

    historyApi.debouncedAdd('component - update layout position', options);
  }

  /**
   * Move components by delta x and delta y in pixels (relative to initial position)
   *  - supports only components with MeshItemLayout
   *
   * @param compRefs - components with MeshItemLayout
   * @param params
   * @param params.deltaX - move by delta x in pixels
   * @param params.deltaY - move by delta y in pixels
   * @param params.compRectInitialByCompId - initial comp rect by comp id (important when transition is in process, and comp rect from DOM is different from initial)
   * @param params.compContainerToByCompId - target container by comp id (for reparenting)
   * @param options
   * @param options.dontAddToUndoRedoStack - don't add to undo/redo stack
   * @returns `compRefUpdatedMap` - map of component refs after reparenting (that could be different from initial)
   */
  async function moveBy_MeshItemLayout(
    compRefs: CompRef[],
    {
      deltaX = 0,
      deltaY = 0,
      compRectInitialByCompId = new Map(
        compRefs.map((compRef) => [
          compRef.id,
          layoutMeshCoreApi.measureRect(compRef),
        ]),
      ),
      compContainerToByCompId: containerToByCompId,
    }: LayoutMoveByParams & {
      compRectInitialByCompId?: Map<string, Rect>;
      compContainerToByCompId?: Map<string, CompRef>;
    },
    options: LayoutMoveByOptions = {},
  ): Promise<{ compRefUpdatedMap: Map<string, CompRef> }> {
    ensureComponentsAreWithMeshItemLayout(compRefs);

    const compRectsUpdatedMap = new Map<string, Rect>(
      compRefs.map((compRef) => {
        const compRectInitial =
          compRectInitialByCompId?.get(compRef.id) ??
          layoutMeshCoreApi.measureRect(compRef);

        const compRectUpdated = {
          ...compRectInitial,
          x: compRectInitial.x + deltaX,
          y: compRectInitial.y + deltaY,
        };

        return [compRef.id, compRectUpdated];
      }),
    );

    let compRefUpdatedMap = new Map<string, CompRef>();

    if (containerToByCompId && containerToByCompId.size > 0) {
      ({ compRefUpdatedMap } =
        await layoutMeshReparentApi.reparentAndUpdateContainersGrids(
          compRefs,
          containerToByCompId,
          {
            compRectsMap: compRectsUpdatedMap,
          },
        ));
    } else {
      await editorAPI.transactions.run(async () => {
        const updatedContainersByContainerId = new Map<string, CompRef>(
          compRefs.map((compRef) => {
            const containerRef = editorAPI.components.getContainer(compRef);
            return [containerRef.id, containerRef];
          }),
        );

        updatedContainersByContainerId.forEach((containerRef) => {
          layoutMeshCoreApi.updateContainerGrid(containerRef, {
            compRectsMap: compRectsUpdatedMap,
          });
        });
      });
    }

    historyApi.debouncedAdd('component - update layout position', options);

    return {
      compRefUpdatedMap,
    };
  }

  /**
   * Move components by delta x and delta y in pixels (relative to initial position)
   *  - supports only components with same itemLayout.type (FixedItemLayout or MeshItemLayout)
   *
   * @param compRefs - components with MeshItemLayout
   * @param params
   * @param params.deltaX - move by delta x in pixels
   * @param params.deltaY - move by delta y in pixels
   * @param options
   * @param options.dontAddToUndoRedoStack - don't add to undo/redo stack
   */
  async function moveBy(
    compRefOrRefs: CompRef | CompRef[],
    params: LayoutMoveByParams,
    options: LayoutMoveByOptions = {},
  ): Promise<void> {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    if (compRefs.length === 0) {
      return;
    }

    if (
      compRefs.every((compRef) =>
        hasFixedItemLayout(layoutMeshCoreApi.get(compRef)),
      )
    ) {
      await moveBy_FixedItemLayout(compRefs, params, options);
      return;
    }

    if (
      compRefs.every((compRef) =>
        hasMeshItemLayout(layoutMeshCoreApi.get(compRef)),
      )
    ) {
      await moveBy_MeshItemLayout(compRefs, params, options);
      return;
    }

    throw new Error(
      'moveBy supports only components with same itemLayout type: FixedItemLayout or MeshItemLayout',
    );
  }

  return {
    moveBy,
    moveBy_FixedItemLayout,
    moveBy_MeshItemLayout,
  };
}

export type LayoutMeshMoveByApi = ReturnType<typeof createLayoutMeshMoveByApi>;
