// @ts-nocheck
import _ from 'lodash';
import tpaActionTypes from './tpaActionTypes';

const INVALID_APP_IDS_ERR_MSG =
  'appIds found for appDefIds must be an array of numbers';

const getDefaultTPAState = () => ({
  appsIdsToRefreshOnDashboardClose: {},
  trackedAppsForUpgrade: {},
});

const convertArrayToMap = (arrayToConvert) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  _.reduce(
    arrayToConvert,
    (acc, val) => {
      acc[val] = true;
      return acc;
    },
    {},
  );

const isAppIdsInputInvalid = (appIds) => !isAppIdsInputValid(appIds);

const isAppIdsInputValid = (appIds) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  _.isArray(appIds) &&
  appIds.every(
    (appId) =>
      (_.isNumber(appId) && appId > 0) ||
      (_.isNumber(parseInt(appId, 10)) && parseInt(appId, 10) > 0),
  );

export default (tpa, action) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-nil
  if (_.isNil(tpa)) {
    tpa = getDefaultTPAState();
  }
  switch (action.type) {
    case tpaActionTypes.SHOULD_REFRESH_GENERAL_INFO:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, tpa, { shouldRefreshGeneralInfo: action.refresh });
    case tpaActionTypes.ADD_APP_IDS_TO_REFRESH_ON_DASHBOARD_CLOSE:
      if (isAppIdsInputInvalid(action.appIds)) {
        console.error(INVALID_APP_IDS_ERR_MSG);
        return tpa;
      }
      const existingAppIdsToRefresh = tpa.appsIdsToRefreshOnDashboardClose;
      const appIdsToRefreshMap = convertArrayToMap(action.appIds);
      const mergedAppsIdsToRefresh = _.merge(
        {},
        existingAppIdsToRefresh,
        appIdsToRefreshMap,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, tpa, {
        appsIdsToRefreshOnDashboardClose: mergedAppsIdsToRefresh,
      });
    case tpaActionTypes.SET_APP_IDS_TO_REFRESH_ON_DASHBOARD_CLOSE:
      if (isAppIdsInputInvalid(action.appIds)) {
        console.error(INVALID_APP_IDS_ERR_MSG);
        return tpa;
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, tpa, {
        appsIdsToRefreshOnDashboardClose: convertArrayToMap(action.appIds),
      });
    case tpaActionTypes.ADD_APP_TO_TRACK_FOR_UPGRADE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, tpa, {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        trackedAppsForUpgrade: _.assign({}, tpa.trackedAppsForUpgrade, {
          [action.applicationId]: action.applicationData,
        }),
      });
    case tpaActionTypes.REMOVE_APP_FROM_TRACK_FOR_UPGRADE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, tpa, {
        trackedAppsForUpgrade: _.omit(tpa.trackedAppsForUpgrade, [
          action.applicationId,
        ]),
      });
    case tpaActionTypes.SET_TPA_SETTINGS_PANEL_EXTRA_PROPS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, tpa, { settingsPanelExtraProps: action.props });
    default:
      return tpa;
  }
};
