import * as actionTypes from './hoverBoxActionTypes';
import type { CompRef } from 'types/documentServices';

const setHoverBox = (hoveredComp: CompRef, hoverBoxOverlay: boolean) => ({
  type: actionTypes.SET_HOVER_BOX,
  hoveredComp,
  hoverBoxOverlay,
});

const clearHoverBox = () => ({
  type: actionTypes.CLEAR_HOVER_BOX,
});

export { setHoverBox, clearHoverBox };
