import _ from 'lodash';

import constants from '@/constants';
import { sections } from '@/util';
import { createSelector, type Selector } from './selector';
import { editorStateSel, editorAPISel } from './rootSelectors';
import type { StateMapperArgs } from 'types/redux';

import * as mouseActions from '../mouseActions/mouseActions';

const mouseActionsSelectors = mouseActions.selectors;

const resizeActionTypes = [
  constants.MOUSE_ACTION_TYPES.RESIZE,
  constants.MOUSE_ACTION_TYPES.RESIZE_PUSH,
  constants.MOUSE_ACTION_TYPES.RESIZE_CROP,
  constants.MOUSE_ACTION_TYPES.RESIZE_PAGE,
  constants.MOUSE_ACTION_TYPES.CLASSIC_SECTION_RESIZE_PUSH,
];

const getDragActionTypes = () =>
  sections.isSectionsEnabled()
    ? [
        constants.MOUSE_ACTION_TYPES.DRAG,
        constants.MOUSE_ACTION_TYPES.DRAG_PUSH,
        constants.MOUSE_ACTION_TYPES.ADD_PANEL_DRAG_TO_STAGE,
        constants.MOUSE_ACTION_TYPES.ADD_SECTION_PANEL_DRAG_TO_STAGE,
      ]
    : [
        constants.MOUSE_ACTION_TYPES.DRAG,
        constants.MOUSE_ACTION_TYPES.DRAG_PUSH,
      ];

const performingMouseMoveActionSel: Selector<StateMapperArgs, boolean> =
  createSelector(
    [editorStateSel],
    mouseActionsSelectors.isPerformingMouseMoveAction,
  );

const registeredMouseMoveActionSel = createSelector(
  [editorAPISel, performingMouseMoveActionSel],
  (editorAPI, isPerformingMouseMoveAction) =>
    isPerformingMouseMoveAction
      ? editorAPI.mouseActions.getRegisteredMouseMoveAction()
      : null,
);

export const isDraggingSel = createSelector(
  [registeredMouseMoveActionSel, performingMouseMoveActionSel],
  (registeredMouseMoveAction, performingMouseMoveAction) => {
    return (
      registeredMouseMoveAction &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(getDragActionTypes(), registeredMouseMoveAction.type) &&
      performingMouseMoveAction
    );
  },
);

export const isResizingSel = createSelector(
  [registeredMouseMoveActionSel],
  (registeredMouseMoveAction) => {
    return (
      registeredMouseMoveAction &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(resizeActionTypes, registeredMouseMoveAction.type)
    );
  },
);

export const isRotatingSel = createSelector(
  [registeredMouseMoveActionSel],
  (registeredMouseMoveAction) => {
    return (
      registeredMouseMoveAction &&
      registeredMouseMoveAction.type === constants.MOUSE_ACTION_TYPES.ROTATE
    );
  },
);

export const isDuringMouseActionSel = createSelector(
  [isRotatingSel, isResizingSel, isDraggingSel],
  (isRotating, isResizing, isDragging) =>
    isDragging || isResizing || isRotating,
);
