import { isMeshLayoutEnabled } from '@/layout';
import _ from 'lodash';
import experiment from 'experiment';
import type { BeforePlugin } from 'types/editorPlugins';
import type { CompStructure } from 'types/documentServices';

function getWPhotoStructure(compDef: CompStructure) {
  return _.merge({}, compDef, {
    layout: {
      rotationInDegrees: 0,
      scale: 1,
      fixedPosition: false,
      anchors: [],
    },
    props: {
      type: 'WPhotoProperties',
    },
    style: compDef.style || 'wp2',
  });
}

const overrideWPhotoStructure = (initialStructure: CompStructure) => {
  if (experiment.isOpen('se_defaultImageAutoFill')) {
    Object.assign(initialStructure.props, { autoFill: true });
  }
  return initialStructure;
};

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
) => {
  if (!compDef.props) {
    compDef = getWPhotoStructure(compDef);
  }

  overrideWPhotoStructure(compDef);

  const compRef = originalAddComponentFunc(containerRef, compDef);

  if (isMeshLayoutEnabled()) {
    return {
      compRef,
      onChangesApplied: onCompAddCallback,
    };
  }

  if (onCompAddCallback) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      onCompAddCallback(compRef);
    });
  }

  return { compRef };
};

export default plugin;
