import { smartWriterActionTypes } from './smartWriterActionTypes';
import type { SmartWriterActionsType } from './smartWriterActionTypes';

export interface SmartWriterState {
  [key: string]: string;
}

export const smartWriterInitialState: SmartWriterState = {};

export const smartWriterReducer = (
  smartWriter: SmartWriterState = smartWriterInitialState,
  action: SmartWriterActionsType,
): SmartWriterState => {
  switch (action.type) {
    case smartWriterActionTypes.UPDATE_COMPONENT_TOPIC:
      return {
        ...smartWriter,
        ...{ [action.payload.compId]: action.payload.topicId },
      };
    case smartWriterActionTypes.RESET_COMPONENT_STORE:
      return {};
    default:
      return smartWriter;
  }
};
