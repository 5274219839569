import * as actionTypes from './newReleasesActionTypes';

export interface NewReleasesState {
  hasNotification: boolean;
}

export const newReleasesInitialState: NewReleasesState = {
  hasNotification: false,
};

const reducer = (
  state: NewReleasesState = newReleasesInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION:
      return Object.assign({}, state, {
        hasNotification: action.hasNotification,
      });
    default:
      return state;
  }
};

export default reducer;
