import type { BeforePlugin } from 'types/editorPlugins';

const plugin: BeforePlugin = (
  editorAPI: AnyFixMe,
  originalAddComponentFunc: AnyFixMe,
  containerRef: AnyFixMe,
  compDef: AnyFixMe,
) => {
  compDef.data.urlFormat = editorAPI.dsRead.generalInfo.urlFormat.get();
};

export default plugin;
