import introFunnelActionTypes from './introFunnelActionTypes';
import type { IntroFunnelIntents } from './introFunnelTypes';

const { ADD_INTRO_FUNNEL_INTENT } = introFunnelActionTypes;

const addIntroFunnelIntent = (appIntent: IntroFunnelIntents) => ({
  type: ADD_INTRO_FUNNEL_INTENT,
  appIntent,
});

export { addIntroFunnelIntent };
