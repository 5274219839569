import { resizeAndPushHooks } from '@/layoutResize';
import type { Scope } from './scope';
import { getCurrentTabComponent } from './tabsSelectors';

export function init(scope: Scope) {
  const { componentsApi } = scope;

  resizeAndPushHooks.getComponentsToResizeInterceptor.tap(
    ({ compsToResize }, { update }) => {
      const compRef = compsToResize[0];

      if (componentsApi.getType(compRef) === 'wixui.Tabs') {
        const currentTab = getCurrentTabComponent(compRef, componentsApi);
        update({ compsToResize: currentTab ? [currentTab] : [compRef] });
      }
    },
  );
}
