import * as selectors from '../hiddenItems/hiddenItemsSelectors';
import type { CompRef } from 'types/documentServices';

const getIsAddingHiddenItemInProgress = (state: AnyFixMe) =>
  state.mobile.hiddenItems.isAddingHiddenItemInProgress;
const getHoveredComponent = (state: AnyFixMe) =>
  state.mobile.hiddenItems.hoveredComponent;
const getMasterPageHiddenComponents = (state: AnyFixMe) =>
  state.mobile.hiddenItems.masterPageHiddenComponents;
const getCurrentPageHiddenComponents = (state: AnyFixMe) =>
  state.mobile.hiddenItems.currentPageHiddenComponents;

const getInteractionsMobileHiddenItems = (editorAPI: AnyFixMe) => {
  const { variants } = editorAPI;
  if (!variants) {
    return [];
  }
  const pageRef = editorAPI.pages.getFocusedPage();
  let hiddenCompRefs =
    editorAPI.components.transformations.getAllHiddenComponentsByDefault(
      pageRef,
    );

  const siteMobileVariant = editorAPI.variants.mobile.get();
  if (siteMobileVariant) {
    hiddenCompRefs = hiddenCompRefs.filter((compRef: AnyFixMe) => {
      const compVariantPointer = editorAPI.components.variants.getPointer(
        compRef,
        siteMobileVariant,
      );
      const compMobileTransformations =
        editorAPI.components.transformations.get(compVariantPointer);
      // Must check that it's really not "false", not just defined
      return compMobileTransformations?.hidden !== false;
    });
  }
  const hoveredComponent = selectors.getHoveredComponent(
    editorAPI.store.getState(),
  );
  if (
    hoveredComponent.isInteraction &&
    !hiddenCompRefs.find(
      (compRef: CompRef) => compRef.id === hoveredComponent.hiddenComp.compId,
    )
  ) {
    hiddenCompRefs.splice(
      hoveredComponent.index,
      0,
      hoveredComponent.hiddenComp.compRef,
    );
  }
  return hiddenCompRefs.map((compRef: AnyFixMe) => {
    const iconProps = editorAPI.components.getIconInfo(compRef);
    return {
      compRef,
      compId: compRef.id,
      label: editorAPI.components.getDisplayName(compRef),
      iconUrl: iconProps.src,
      svgName: iconProps.svgName,
      css: iconProps.css,
    };
  });
};

export {
  getIsAddingHiddenItemInProgress,
  getHoveredComponent,
  getMasterPageHiddenComponents,
  getCurrentPageHiddenComponents,
  getInteractionsMobileHiddenItems,
};
