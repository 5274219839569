// @ts-nocheck
import _ from 'lodash';

const personalizedDesigns = {};
const themePresetDesigns = {};

function isEmptyDesign(designData) {
  designData = designData || this.props.compDesign;
  const { cssStyle } = designData;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys
  return _(cssStyle)
    .keys()
    .every(function (key) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-null
      return _.isNull(cssStyle[key]);
    });
}

function isThemeDesign(componentType, design) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(themePresetDesigns[componentType])) {
    return false;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  return !_.isUndefined(themePresetDesigns[componentType][design.charas]);
}

function getPersonalizedDesigns(editorApi, componentTypes) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _(editorApi.components.getAllComponents_DEPRECATED_BAD_PERFORMANCE())
    .filter(
      _.flow(
        editorApi.components.getType,
        _.partial(_.includes, componentTypes),
      ),
    )
    .map(function (compRef) {
      const design = editorApi.components.design.get(compRef);
      return {
        compRef,
        design,
      };
    })
    .reject(function (item) {
      const componentType = editorApi.components.getType(item.compRef);
      const { design } = item;
      return isEmptyDesign(design) || isThemeDesign(componentType, design);
    })
    .uniqBy('design.charas')
    .value();
}

function addPersonalizedDesign(componentType, design) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(personalizedDesigns[componentType])) {
    personalizedDesigns[componentType] = {};
  }
  personalizedDesigns[componentType][design.charas] = design;
}

function removePersonalizedDesign(componentType, design) {
  delete personalizedDesigns[componentType][design.charas];
  if (_.isEmpty(personalizedDesigns[componentType])) {
    delete personalizedDesigns[componentType];
  }
}

function addThemePresetDesign(componentType, design) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(themePresetDesigns[componentType])) {
    themePresetDesigns[componentType] = {};
  }
  themePresetDesigns[componentType][design.charas] = design;
}

const designsFromoldAddPanel = [
  {
    charas: 'HoverBoxThemePreset_#0',
    themeMappings: {},
    cssStyle: {
      cssBorderRadius: null,
      cssBorder: null,
      cssBoxShadow: null,
    },
  },
  {
    charas: 'HoverBoxThemePreset_#1',

    themeMappings: {
      'cssBorder.color.bottom': 'color_28',
      'cssBorder.color.left': 'color_28',
      'cssBorder.color.right': 'color_28',
      'cssBorder.color.top': 'color_28',
    },

    cssStyle: {
      cssBorderRadius: {
        topLeft: { unit: 'px', value: 25 },
        topRight: { unit: 'px', value: 25 },
        bottomRight: { unit: 'px', value: 25 },
        bottomLeft: { unit: 'px', value: 25 },
      },

      cssBorder: {
        width: {
          top: { unit: 'px', value: 4 },
          right: { unit: 'px', value: 4 },
          bottom: { unit: 'px', value: 4 },
          left: { unit: 'px', value: 4 },
        },

        style: {
          top: 'solid',
          left: 'solid',
          right: 'solid',
          bottom: 'solid',
        },

        color: {
          top: { red: 127, green: 32, blue: 32, alpha: 0.88 },
          right: { red: 127, green: 32, blue: 32, alpha: 0.88 },
          bottom: { red: 127, green: 32, blue: 32, alpha: 0.88 },
          left: { red: 127, green: 32, blue: 32, alpha: 0.88 },
        },
      },

      cssBoxShadow: null,
    },
  },
  {
    charas: 'HoverBoxThemePreset_#2',

    themeMappings: {
      'cssBoxShadow.0.color': 'color_28',
      'cssBoxShadow.1.color': 'color_28',
    },

    cssStyle: {
      cssBorder: null,
      cssBorderRadius: null,
      cssBoxShadow: [
        {
          inset: false,
          offsetX: { unit: 'em', value: 0.2 },
          offsetY: { unit: 'em', value: 0.2 },
          blurRadius: { unit: 'px', value: 4 },
          spreadRadius: { unit: 'px', value: 5 },
          color: { red: 127, green: 172, blue: 32, alpha: 0.5 },
        },

        {
          inset: true,
          offsetX: { unit: 'em', value: 0.2 },
          offsetY: { unit: 'em', value: 0.23 },
          blurRadius: { unit: 'px', value: 2 },
          spreadRadius: { unit: 'px', value: 5 },
          color: { red: 127, green: 172, blue: 32, alpha: 0.5 },
        },
      ],
    },
  },
];

for (const item of designsFromoldAddPanel) {
  addThemePresetDesign('wysiwyg.viewer.components.HoverBox', item);
}

export {
  getPersonalizedDesigns,
  addPersonalizedDesign,
  removePersonalizedDesign,
  addThemePresetDesign,
  isThemeDesign,
};
