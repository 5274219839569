// @ts-nocheck
import _ from 'lodash';
import * as updateDataPlugins from '../../../updateDataPlugins/updateDataPlugins';

export default function (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
  convertStructureFunc,
  items,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(items, function (item, index) {
    let compDefWithOffset, pastePosition, focusedPage;
    if (index > 0) {
      focusedPage = editorAPI.dsRead.pages.getFocusedPageId();
      pastePosition = editorAPI.pasteLogic.addContext.getPastePosition(
        editorAPI,
        compDef.layout,
        focusedPage,
      );
      compDefWithOffset =
        index > 0 ? _.merge({}, compDef, { layout: pastePosition }) : compDef;
    } else {
      compDefWithOffset = compDef;
    }
    const convertedStructure = convertStructureFunc(compDefWithOffset, item);
    const compDataType = convertedStructure.data?.type;
    if (updateDataPlugins[compDataType]) {
      convertedStructure.data = updateDataPlugins[compDataType](
        convertedStructure.data,
      );
    }
    const compRef = originalAddComponentFunc(containerRef, convertedStructure);
    if (onCompAddCallback) {
      editorAPI.dsActions.waitForChangesApplied(function () {
        onCompAddCallback(compRef);
      });
    }
  });
}
