// @ts-nocheck
import isSavePublishOrPushAllowed from './isSavePublishOrPushAllowed';

function localSave({ origin, options, editorAPI }) {
  return new Promise(function (resolve, reject) {
    options = options || {};
    const state = editorAPI.store.getState();

    if (!isSavePublishOrPushAllowed(state)) {
      // log.error('Site is being saved, published, or pushed!');
      resolve();
    }

    editorAPI.savePublish.setSaveProgress(true);

    function unlockSave() {
      editorAPI.savePublish.setSaveProgress(false);
      // editorAPI.bi.tryFlushingConditionedEvents();
    }

    return editorAPI.savePublish
      .save(false, origin, options)
      .then(function (result) {
        unlockSave();
        return result || {};
      })
      .catch(function (error) {
        unlockSave();
        reject(error);
        // Todo: Handle different errors
        // displayFailurePanelMatchingAnErrorType(error, errorType);
        editorAPI.panelManager.openPanel(
          'localMode.panels.failedLocalSaveGenericPanel',
          {},
          true,
        );
        throw error;
      });
  });
}
export default localSave;
