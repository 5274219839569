const SET_SITE_ID = 'set-site-id';
const SET_PREFERENCES = 'set-preferences';
const SET_PREFERENCE = 'set-preference';
const MARK_SITE_PREFERENCES_NOT_DIRTY = 'mark-site-preferences-not-dirty';
const DONT_SAVE_USER_PREFERENCES = 'dont-save-user-preferences';
const SET_GLOBAL_PREF_FETCH_STATUS = 'set-global-pref-fetch-status';
const SET_SITE_PREF_FETCH_STATUS = 'set-site-pref-fetch-status';
const REGISTER_SITE_PREF_CB = 'register_site_pref_cb';
const RESET_REGISTER_SITE_PREF_CB_ARRAY = 'reset_register_site_pref_cb_array';
const SITE_PREF_CALL_DONE = 'site-pref-call-done';

export {
  SET_SITE_ID,
  SET_PREFERENCES,
  SET_PREFERENCE,
  MARK_SITE_PREFERENCES_NOT_DIRTY,
  DONT_SAVE_USER_PREFERENCES,
  SET_GLOBAL_PREF_FETCH_STATUS,
  SET_SITE_PREF_FETCH_STATUS,
  REGISTER_SITE_PREF_CB,
  RESET_REGISTER_SITE_PREF_CB_ARRAY,
  SITE_PREF_CALL_DONE,
};
