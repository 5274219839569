import type { PinDockingDirection } from '../types';

export const DOCKING_DIRECTIONS_LIST: PinDockingDirection[] = [
  'TOP_LEFT',
  'TOP',
  'TOP_RIGHT',

  'LEFT',
  'MIDDLE',
  'RIGHT',

  'BOTTOM_LEFT',
  'BOTTOM',
  'BOTTOM_RIGHT',
];

export const DOCKING_DIRECTIONS: Set<PinDockingDirection> = new Set(
  DOCKING_DIRECTIONS_LIST,
);

export const DOCKING_DIRECTIONS_FULL_WIDTH: Set<PinDockingDirection> = new Set([
  'TOP',
  'MIDDLE',
  'BOTTOM',
]);

export const DOCKING_DIRECTIONS_FULL_HEIGHT: Set<PinDockingDirection> = new Set(
  ['LEFT', 'MIDDLE', 'RIGHT'],
);
