import _ from 'lodash';
import { interactionsActionTypes } from '../interactions/interactionsActionTypes';

let viewToolsStateBeforeInteraction: AnyFixMe = null;

export const viewToolsInitialState = {
  toolbarEnabled: undefined as AnyFixMe,
  developerToolBarEnabled: undefined as AnyFixMe,
  guidelinesEnabled: undefined as AnyFixMe,
  sectionsHandlesEnabled: undefined as AnyFixMe,
  snapToEnabled: undefined as AnyFixMe,
  rulersEnabled: undefined as AnyFixMe,
};

const reducer = (state = viewToolsInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case interactionsActionTypes.ENTER_INTERACTION:
      viewToolsStateBeforeInteraction = state;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, state, {
        isRulersEnabled: false,
        guidelinesEnabled: false,
        snapToEnabled: false,
        developerToolBarEnabled: false,
      });
    case interactionsActionTypes.EXIT_INTERACTION:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(
        {},
        state,
        _.pick(viewToolsStateBeforeInteraction, [
          'isRulersEnabled',
          'guidelinesEnabled',
          'snapToEnabled',
          'developerToolBarEnabled',
        ]),
      );
    default:
      return state;
  }
};

export default reducer;
