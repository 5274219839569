import _ from 'lodash';
import tpaPages from './tpaPages';
import blogPages from './blogPages';
import blogTypeUtils from '../pageUtils/blogTypeUtils';
import tpaTypeUtils from '../pageUtils/tpaTypeUtils';
import * as dynamicTypeUtils from '../pageUtils/dynamicTypeUtils';

import type { PagesData as PageData } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

interface DynamicPagesMap {
  [dynamicPageId: string]: boolean;
}

function getTpaSubPagesList(
  editorAPI: AnyFixMe,
  tpaApplicationId: AnyFixMe,
  pagesDataList: AnyFixMe,
) {
  return tpaPages.getSpecialPagesDataListByTPAId(
    editorAPI,
    tpaApplicationId,
    pagesDataList,
  );
}

function getBlogSubPagesList(editorAPI: AnyFixMe, pagesDataList: AnyFixMe) {
  return blogPages.getSpecialPagesDataList(
    editorAPI,
    pagesDataList || editorAPI.dsRead.pages.getPagesData(),
  );
}

function isDynamicPage(
  editorAPI: EditorAPI,
  pageData: PageData,
  dynamicPagesIdsMap: DynamicPagesMap,
) {
  return dynamicPagesIdsMap
    ? _.has(dynamicPagesIdsMap, pageData.id)
    : dynamicTypeUtils.isDynamicPage(editorAPI, pageData);
}

export default {
  isSpecialPage(
    editorAPI: EditorAPI,
    pageData: PageData,
    dynamicPagesIdsMap?: DynamicPagesMap,
  ) {
    return (
      blogTypeUtils.isBlogSpecialPage(pageData) ||
      tpaTypeUtils.isTPASpecialPage(editorAPI.dsRead, pageData) ||
      isDynamicPage(editorAPI, pageData, dynamicPagesIdsMap)
    );
  },

  isSubPageMenuItem(editorAPI: AnyFixMe, pageData: AnyFixMe) {
    return (
      this.isSpecialPage(editorAPI, pageData) &&
      !tpaTypeUtils.isPageNotVisibleInPagesMenu(editorAPI.dsRead, pageData)
    );
  },

  getSectionPagesIds(editorAPI: AnyFixMe, pagesData: AnyFixMe) {
    const tpaSectionPagesDataList = tpaPages.getSectionPagesDataList(
      editorAPI,
      pagesData,
    );
    const blogSectionPagesDataList = blogPages.getSectionPagesDataList(
      editorAPI,
      pagesData,
    );

    return _.union(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(tpaSectionPagesDataList, 'id'),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(blogSectionPagesDataList, 'id'),
    );
  },

  getSpecialPagesListBySectionPageOrBySibling(
    editorAPI: AnyFixMe,
    pageData: AnyFixMe,
    pagesDataList: AnyFixMe,
  ) {
    if (pageData) {
      if (blogTypeUtils.isBlog(editorAPI, pageData)) {
        return getBlogSubPagesList(editorAPI, pagesDataList);
      }
      if (tpaTypeUtils.isTPA(pageData)) {
        return getTpaSubPagesList(
          editorAPI,
          pageData.tpaApplicationId,
          pagesDataList,
        );
      }
    }
    return [];
  },
};
