// @ts-nocheck
import $ from 'zepto';
import _ from 'lodash';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import commonConstants from '@/constants';
import type { EditorAPI } from '@/editorAPI';
import type { MultilingualAppStoreData } from './multilingualActions';
import { QuickEditApiKey, WorkspaceRightPanelApiKey } from '@/apis';
import constants from './multilingualConstants';

/**
 * @typedef {string} RequestType
 */

const {
  apiMethods: { POST, PUT },
} = constants;

/*
 *
 * @param {{data: Object, type: RequestType}} props
 * @return {Promise} Promise object representing the response of the HTTP call
 */
const callAjax = (props) =>
  new Promise((resolve, reject) =>
    $.ajax(
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        {
          dataType: 'json',
          contentType: 'application/json',
        },
        props,
        {
          data:
            props.type === POST || props.type === PUT
              ? JSON.stringify(props.data)
              : props.data,
          success: resolve,
          error: ({ response, status }) => {
            reject({ response, status });
          },
        },
      ),
    ),
  );

// Temporary Hack to make life easier until https://jira.wixpress.com/browse/WEED-8743 is resolved
const addLanguageCode = (lang) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign({}, lang, { languageCode: lang.languageCode || lang.code });

/**
 * @param {{languageCode: string}}
 * @return {{languageCode: string}}
 */
const pickLanguageCode = ({ languageCode, code }) => ({
  languageCode: languageCode ? languageCode : code,
});

/**
 * @param {editorAPI: object}
 * @return {{currentLanguageCode: string, originalLanguageCode: string}}
 */
const getCurrentAndOriginal = (editorAPI) => {
  const currentLanguageCode = editorAPI.language.current.get();
  return {
    currentLanguageCode,
    originalLanguageCode:
      editorAPI.language.original.get().code || currentLanguageCode,
  };
};

/**
 * @param {editorAPI: object}
 * @return {{currentIsOriginal: boolean}}
 */
const currentIsOriginal = (editorAPI) => {
  const { currentLanguageCode, originalLanguageCode } =
    getCurrentAndOriginal(editorAPI);
  return currentLanguageCode === originalLanguageCode;
};

const getLanguageToTranslate = (editorAPI: EditorAPI, languageCode: string) => {
  const languageFromAPI = editorAPI.language
    .getFull()
    .find((language) => language.languageCode === languageCode);

  if (languageFromAPI) {
    return languageFromAPI;
  }

  // take from the store because ds not updated after installation
  const multilingualAppState: MultilingualAppStoreData =
    stateManagement.appsStore.selectors.getAppStore(
      editorAPI.store.getState(),
      commonConstants.MULTILINGUAL.linguistDefId,
    );

  if (multilingualAppState) {
    return multilingualAppState.data.translationLanguages.find(
      (language) => language.code === languageCode,
    );
  }
};

const autoTranslateNotSupportedFor = (languageCode: string) => {
  return `"${languageCode}" is not supported by automatic translation`;
};

/**
 * Collect and validate params required to perform auto translation of site
 */
const getAutoTranslateParams = (
  editorAPI: EditorAPI,
  inputLanguageCode?: string,
) => {
  const languageCode = inputLanguageCode || editorAPI.language.current.get();
  const originalLanguage = editorAPI.language.original.get();

  if (originalLanguage.languageCode === languageCode) {
    throw new Error(`Can't auto-translate original language`);
  }

  if (!originalLanguage.machineTranslationLanguageCode) {
    throw new Error(
      autoTranslateNotSupportedFor(originalLanguage.languageCode),
    );
  }

  const targetLanguage = getLanguageToTranslate(editorAPI, languageCode);

  if (!targetLanguage) {
    throw new Error(
      `Multilingual isn't installed or "${languageCode}" is not a secondary language`,
    );
  }

  if (!targetLanguage.machineTranslationLanguageCode) {
    throw new Error(autoTranslateNotSupportedFor(targetLanguage.languageCode));
  }

  return {
    originalLanguageCode: originalLanguage.languageCode,
    targetLanguageCode: targetLanguage.languageCode,
    targetFlag: targetLanguage.countryCode,
    originalLanguageMachineTranslationCode:
      originalLanguage.machineTranslationLanguageCode,
    targetLanguageMachineTranslationCode:
      targetLanguage.machineTranslationLanguageCode,
    appInstance: editorAPI.tpa.app.getDataByAppDefId(
      commonConstants.MULTILINGUAL.linguistDefId,
    ).instance,
  };
};

/**
 * Perform server-site translation of site.
 * Both translation for editor and TPAs content is performed by unified call to API.
 * No document update required (editor will update document automatically), but need to wait for API call to be finished.
 */
const autoTranslateFromServer = async (
  editorAPI: EditorAPI,
  languageCode?: string,
) => {
  const { originalLanguageCode, targetLanguageCode, targetFlag, appInstance } =
    getAutoTranslateParams(editorAPI, languageCode);

  return util.multilingual.googleTranslateApi.autoTranslateSite({
    originalLanguageId: originalLanguageCode,
    currentLanguageId: targetLanguageCode,
    currentLanguageFlag: targetFlag,
    instance: appInstance,
  });
};

interface QuickEditCallbacks {
  onOpen: () => void;
  onClose: () => void;
}

const openQuickEdit = (editorAPI: EditorAPI, cbs: QuickEditCallbacks) => {
  editorAPI.host.getAPI(QuickEditApiKey).openPanel({
    rootControlCompRef:
      editorAPI.sections.getFocusedSection() ||
      editorAPI.sections.getFocusedHeaderFooter(),
    onClose: cbs.onClose,
    onOpen: cbs.onOpen,
    origin: 'language-change',
  });
};

const updateLanguageInQuickEditTitle = (
  editorAPI: EditorAPI,
  languageCode: string,
) => editorAPI.host.getAPI(QuickEditApiKey).updateLanguageInTitle(languageCode);

const isQuickEditOpened = (editorAPI) => {
  return editorAPI.host.getAPI(QuickEditApiKey).isQuickEditPanelOpen();
};

const closeQuickEdit = (editorAPI: EditorAPI) => {
  editorAPI.host.getAPI(WorkspaceRightPanelApiKey).close('language-change');
};

let _registeredExitPreviewCb = false;
let _shouldCallExitPreviewCb = false;

const openQuickEditOnExitPreview = (
  editorAPI: EditorAPI,
  cbs: QuickEditCallbacks,
) => {
  _shouldCallExitPreviewCb = true;

  if (_registeredExitPreviewCb) {
    return;
  }

  editorAPI.preview.registerExitPreview(() => {
    if (_shouldCallExitPreviewCb) {
      editorAPI.waitForChangesApplied(() => {
        openQuickEdit(editorAPI, cbs);
      });
    }
    _shouldCallExitPreviewCb = false;
  });

  _registeredExitPreviewCb = true;
};

const cancelOpenQuickEditOnExitPreview = () => {
  _shouldCallExitPreviewCb = false;
};

export {
  callAjax,
  addLanguageCode,
  pickLanguageCode,
  currentIsOriginal,
  getCurrentAndOriginal,
  autoTranslateFromServer,
  openQuickEdit,
  closeQuickEdit,
  isQuickEditOpened,
  openQuickEditOnExitPreview,
  cancelOpenQuickEditOnExitPreview,
  updateLanguageInQuickEditTitle,
};
