import _ from 'lodash';
import * as actionTypes from './wixAdActionTypes';

export const wixAdInitialState = {
  overridden: {
    alwaysHidden: false,
  },
};

const reducer = (wixAds = wixAdInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case actionTypes.OVERRIDE_WIX_AD:
      return _.defaultsDeep(
        { overridden: { alwaysHidden: action.overrides.alwaysHidden } },
        wixAds,
      );
    default:
      return wixAds;
  }
};

export default reducer;
