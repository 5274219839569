import createEditorStore from './store/createEditorStore';
import throttledStore from './store/throttledStore';
import combineReducers from './store/combineReducers';
import * as leftBarData from './leftBar/leftBarData';

import * as editorPlugins from './editorPlugins';

export { STAGE_BEHAVIOUR_PRESET } from './panels/stageBehaviourPreset';
export { StageBehaviour } from './panels/types';
export * from './panels/panels';

export * from './store/editorState';
export type { EditorStore } from './store/createEditorStore';
export * from './editorPlugins';
export type { InnerRoutesCounts } from './dynamicPages/dynamicPagesReducer';
export type {
  TpaInnerRouteData,
  CurrentTpaInnerRoutesInfo,
} from './tpaDynamicPages/tpaDynamicPagesReducer';
export type { CompPanelProps } from './components/compPanel';
export type { DraggableSlotId } from './draggable/draggableSlots/draggableSlotsReducer';
export type {
  ClientLayout,
  ClientScroll,
  Viewport,
  StageLayout,
  PreviewPosition,
  StageRect,
} from './domMeasurements/domMeasurements.types';
export type {
  PanelDescriptor,
  PanelProps,
  PanelFrameProps,
  ProvidedPanelFrameProps,
  PanelOptions,
  ComponentPanelOptions,
} from './panels/types';
export type { ShowUserActionNotificationParam } from './notifications/notificationsActions';

export * from './platform/install/installTypes';
export type { VersionsMap } from './platform/platformReducer';

export type { InteractionDef } from './interactions/interactionsSelectors';

export type { LeftBarState } from './leftBar/leftBarReducer';
export { actions as leftBarActions } from './leftBar/leftBar';

export {
  editorPlugins, // temporary until we can remove all access to plugins directly under stateManagement package
  createEditorStore,
  throttledStore,
  leftBarData,
  combineReducers,
};

export * from './multilingual/types';

export type { PreviewType } from './preview/types';
