// @ts-nocheck
import userProfileActionTypes from './userProfileActionTypes';

const { SET_USER_PROFILE } = userProfileActionTypes;

const reducer = (userProfile = {}, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return action.userProfile;
    default:
      return userProfile;
  }
};

export default reducer;
