const isEnabled = (state: AnyFixMe) => state.localMode.enabled;

const getPushingStatus = (state: AnyFixMe) => state.localMode.pushingStatus;

const getIsReconnecting = (state: AnyFixMe) => state.localMode.isReconnecting;

const getIsLocalPushInProgress = (state: AnyFixMe) =>
  state.localMode.isLocalPushInProgress;

export {
  isEnabled,
  getPushingStatus,
  getIsLocalPushInProgress,
  getIsReconnecting,
};
