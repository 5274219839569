import experiment from 'experiment';
import { overrideChildrenComponentsDataRecursively } from '../../addUtil';
import type { BeforePlugin } from 'types/editorPlugins';

const classicSectionBefore: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
) => {
  const shouldEnableAutoFillByDefault = experiment.isOpen(
    'se_defaultImageAutoFill',
  );

  if (shouldEnableAutoFillByDefault && compDef.components.length) {
    overrideChildrenComponentsDataRecursively(compDef.components);
  }
};

export default classicSectionBefore;
