export default {
  DEFAULT_DESIGN_HELP_ID: '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
  'wysiwyg.viewer.components.mobile.TinyMenu':
    'fd0b345a-d66a-448e-b574-26df9257df6b',
  'wysiwyg.viewer.components.svgshape.SvgShape':
    'bd08e77b-543c-447f-ab92-7c870481b88f',
  'wysiwyg.viewer.components.VectorImage':
    'e8f1a0cf-b56e-49e8-8990-453a5bb85ca1',
  'wysiwyg.viewer.components.VectorImage.shape':
    'e8f1a0cf-b56e-49e8-8990-453a5bb85ca1',
  'wysiwyg.viewer.components.VectorImage.color':
    'e57b288a-3a0f-4adb-b4e2-ab2d93ce7eaf',
  'wysiwyg.viewer.components.VectorImage.tint':
    'e57b288a-3a0f-4adb-b4e2-ab2d93ce7eaf',
  'wysiwyg.viewer.components.VectorImage.ugc':
    'e57b288a-3a0f-4adb-b4e2-ab2d93ce7eaf',
  'wysiwyg.viewer.components.MatrixGallery':
    '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'wysiwyg.viewer.components.PaginatedGridGallery':
    '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'wysiwyg.viewer.components.SliderGallery':
    '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'wysiwyg.viewer.components.SlideShowGallery':
    '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.Masonry': '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.Accordion': '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.Impress': '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.Freestyle': '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.Collage': '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.Honeycomb': '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.StripShowcase': '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.StripSlideshow':
    '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'tpa.viewer.components.Thumbnails': '22c5d44a-fcb8-4bd8-8e98-8d6650e3b209',
  'wysiwyg.viewer.components.GoogleMap': 'ef773d3f-5be4-4b9d-8880-9e2663eb3d18',
  'wysiwyg.viewer.components.tpapps.TPA3DGallery':
    'ae8e0013-afda-4094-930f-938a85ebf9fa',
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel':
    'ae8e0013-afda-4094-930f-938a85ebf9fa',
  'wysiwyg.viewer.components.AdminLoginButton':
    '3c9a7d1e-f228-46bc-b3dd-7f778d8e68ba',
  'wysiwyg.viewer.components.BackToTopButton':
    '29f8433f-8304-4263-92a9-0ceccfbe7524',
  'wysiwyg.viewer.components.LoginButton':
    '3c9a7d1e-f228-46bc-b3dd-7f778d8e68ba',
  'wysiwyg.viewer.components.LoginSocialBar':
    '0c7e8252-e4ad-40df-bba9-47b561b9ae80',
  'wysiwyg.viewer.components.documentmedia.DocumentMedia':
    '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
  'wysiwyg.viewer.components.HeaderContainer':
    'f977ba75-b92b-45b8-bda8-ddb2bd3a7af3',
  'wysiwyg.viewer.components.FooterContainer':
    'b6a6cbc9-c29e-4ebe-8793-df6784b16583',
  'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu':
    'ebfde475-be52-4c11-96fa-1b5de1a4c200',
  'wysiwyg.viewer.components.StripContainerSlideShow':
    '287e66ea-bfd6-4a92-8b35-beec4cf8e220',
  'wysiwyg.viewer.components.ScreenWidthContainer':
    'dbf8b94a-3026-4f68-8197-a1be2ba8c86c',
  'wysiwyg.viewer.components.BgImageStrip':
    '4da90962-8952-46ac-9e9e-dd7403f2fa61',
  'wysiwyg.common.components.PopupContainer':
    'a1fa63c2-c7da-4d65-af49-a63f112cc741',
  'wysiwyg.viewer.components.PopupCloseIconButton':
    'f7a299ad-2774-4edc-847c-63ee6a453a31',
  'wysiwyg.viewer.components.PopupCloseTextButton':
    '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
  'wysiwyg.viewer.components.BoxSlideShow':
    '5a0501e9-d806-4a1e-ae95-f3415bb6b297',
  'wysiwyg.viewer.components.BoxSlideShowSlide':
    '3311e23c-6c8c-4a41-938a-480a665afd0a',
  'wysiwyg.viewer.components.StateBox': 'b0341602-2d57-4f47-8fb7-5571eed8b16c',
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm':
    'bcb69cdc-c329-45eb-98aa-e9823bfc2ce5',
  'wysiwyg.viewer.components.inputs.RadioButton':
    '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
  'wysiwyg.viewer.components.Grid': '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
  'wysiwyg.viewer.components.MediaControls':
    '3fa861e2-2efd-4fb2-b6de-744082ad7715',
  'wysiwyg.viewer.components.MediaOverlayControls':
    '7048549b-44df-48c5-9660-dc3430f669f8',
  'wysiwyg.viewer.components.MediaContainer':
    '779ed2b1-8105-4afc-a698-24c5f8d9b29d',
  'wysiwyg.viewer.components.MenuToggle':
    '6641f61b-75b2-40e3-98f9-3edf284b9750',
  'wysiwyg.viewer.components.LanguageSelector':
    '30ad7372-4f2f-42d4-b02d-0c78b585b5f7',
  MEDIA_PLAYER: 'fc8beb60-2bc9-4b67-8358-428b73e139fb',
  MEDIA_PLAYER_ALPHA: 'Daa04930-0ba0-48ac-84f8-aab57d9badb8',
  'wixui.Pagination': 'cf516de2-f4e1-487d-8e49-da58b1f811eb',
  'wixui.Tags': 'c65a31bd-8b12-4d88-984d-8e606885bb64',
  'wixui.Slider': 'bffefe1d-bb4a-4296-a803-a6d905e59495',
  'wixui.RichTextBox': '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
  'wixui.RatingsDisplay': '726ca486-7384-41e0-8f15-09da403a86e3',
  'wixui.RatingsInput': 'fe1be3cc-c0ee-435e-9c29-5424fea16d03',
  'wysiwyg.viewer.components.inputs.ComboBoxInput':
    '302c9092-faf3-4a82-bfd0-dd56b7401595',
};
