// @ts-nocheck
import * as util from '@/util';
import { recompose } from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'reactDOM';
import * as domMeasurementsSelectors from '../domMeasurements/domMeasurementsSelectors';
import * as mobileSelectors from '../mobile/mobileSelectors';

const { elementUtils } = util;

const panelDragLimits = () => (Comp) => {
  // eslint-disable-next-line react/prefer-es6-class
  const PanelDragLimits = createReactClass({
    displayName: recompose.wrapDisplayName(Comp, 'panelDragLimits'),
    propTypes: {
      editorContentLayout: PropTypes.object.isRequired,
      stageLayout: PropTypes.object.isRequired,
      isMobileEditor: PropTypes.bool.isRequired,
    },
    getDragLimits() {
      const panelElm = ReactDOM.findDOMNode(this);
      const panelHeight = elementUtils.getOuterHeight(panelElm);
      const panelWidth = elementUtils.getOuterWidth(panelElm);
      return util.panelUtils.getPanelDragLimits(
        panelHeight,
        panelWidth,
        this.props.stageLayout,
        this.props.editorContentLayout,
        this.props.isMobileEditor,
      );
    },
    render() {
      return React.createElement(
        Comp,
        _.defaults(
          {
            getPanelDragLimits: this.getDragLimits,
          },
          this.props,
        ),
      );
    },
  });

  const mapStateToProps = ({ state }) => ({
    editorContentLayout: domMeasurementsSelectors.getEditorContentLayout(state),
    stageLayout: domMeasurementsSelectors.getStageLayout(state),
    isMobileEditor: mobileSelectors.isMobileEditor(state),
  });

  return util.hoc.connect(
    util.hoc.STORES.STATE_ONLY,
    mapStateToProps,
  )(PanelDragLimits);
};

export default panelDragLimits;
