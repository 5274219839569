import {
  getLayoutSizePxOrThrow as getPxOrThrow,
  isFixedItemLayout,
  isFullHeightByLayouts,
  isFullWidthByLayouts,
} from '@/layoutUtils';
import type {
  PopupContainerProperties,
  SingleLayoutData,
} from 'types/documentServices';

export function createPopupPropertiesFromLayouts(
  layouts: SingleLayoutData,
): Partial<PopupContainerProperties> {
  if (!isFixedItemLayout(layouts.itemLayout)) {
    return {};
  }

  const alignmentType = ((): PopupContainerProperties['alignmentType'] => {
    if (isFullWidthByLayouts(layouts)) {
      return 'fullWidth';
    }

    if (isFullHeightByLayouts(layouts)) {
      return 'fullHeight';
    }

    return 'nineGrid';
  })();

  const [horizontalAlignment, horizontalOffset] = ((): [
    PopupContainerProperties['horizontalAlignment'],
    PopupContainerProperties['horizontalOffset'],
  ] => {
    const { justifySelf, margins } = layouts.itemLayout;

    if (justifySelf === 'start') {
      return ['left', getPxOrThrow(margins.left)];
    }

    if (justifySelf === 'center') {
      return ['center', getPxOrThrow(margins.left)];
    }

    if (justifySelf === 'end') {
      return ['right', getPxOrThrow(margins.right)];
    }

    throw new Error('justifySelf must be `start`, `end` or `center`');
  })();

  const [verticalAlignment, verticalOffset] = ((): [
    PopupContainerProperties['verticalAlignment'],
    PopupContainerProperties['verticalOffset'],
  ] => {
    const { alignSelf, margins } = layouts.itemLayout;

    if (alignSelf === 'start') {
      return ['top', getPxOrThrow(margins.top)];
    }

    if (alignSelf === 'center') {
      return ['center', getPxOrThrow(margins.top)];
    }

    if (alignSelf === 'end') {
      return ['bottom', getPxOrThrow(margins.bottom)];
    }

    throw new Error('alignSelf must be `start`, `end` or `center`');
  })();

  return {
    alignmentType,
    horizontalAlignment,
    verticalAlignment,
    horizontalOffset,
    verticalOffset,
  };
}
