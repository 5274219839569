// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import * as util from '@/util';
import localConstants from './multilingualConstants';

const { sitePropertyServiceUrl } = constants.MULTILINGUAL;
const {
  linguistLanguageUrl,
  multilingualServerless,
  siteTranslatablesProperties,
  machineTranslationLimit,
  apiMethods: { GET, POST, PUT },
} = localConstants;

export default {
  loadLanguagesServerless: (instance: string) => ({
    type: GET,
    url: multilingualServerless,
    headers: { Authorization: instance },
  }),
  getSiteTranslatablesProperties: (
    instance: string,
    originalLanguageCode: string,
    originalLanguageCountryCode: string,
    currentLanguageCode: string,
    currentLangaugeCountryCode: string,
  ) => ({
    type: POST,
    url: siteTranslatablesProperties,
    headers: {
      Authorization: instance,
    },
    data: {
      mainLanguage: {
        language: originalLanguageCode,
        flag: originalLanguageCountryCode,
      },
      translatedLanguage: {
        language: currentLanguageCode,
        flag: currentLangaugeCountryCode,
      },
    },
  }),
  getMachineTranslationLimit: (instance: string) => ({
    type: GET,
    url: machineTranslationLimit,
    headers: {
      Authorization: instance,
    },
  }),
  loadLanguages: (instanceId) => ({
    type: GET,
    url: `${linguistLanguageUrl}/?instance-id=${instanceId}`,
  }),
  initLanguages: (instanceId, initialize) => ({
    type: POST,
    url: `${linguistLanguageUrl}/?instance-id=${instanceId}`,
    data: { initialize },
  }),
  addNewLanguage: (instanceId, newLanguage) => ({
    type: PUT,
    url: `${linguistLanguageUrl}/?instance-id=${instanceId}`,
    data: { data: _.pick(newLanguage, ['code', 'locale']) },
  }),
  updateLanguageStatus: (instanceId, languageId, status) => ({
    type: PUT,
    url: `${linguistLanguageUrl}/${languageId}/status?instance-id=${instanceId}`,
    data: { data: { status } },
    headers: { 'X-XSRF-TOKEN': util.cookie.getCookie('XSRF-TOKEN') },
  }),
  loadSupportedLanguages: () => ({
    type: GET,
    url: `${linguistLanguageUrl}/supported`,
  }),
  checkLanguageAppStatus: (metaSiteId, metaSiteInstance) => ({
    url: `${sitePropertyServiceUrl}?fields.paths=multilingual`,
    headers: { Authorization: metaSiteInstance },
  }),
};
