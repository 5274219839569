import type { EditorAPI } from '@/editorAPI';
import _ from 'lodash';
import type { CompStructure } from 'types/documentServices';

const DATA_BINDING_COMPONENT_MARGIN = 10;

export default (
  editorAPI: EditorAPI,
  compDef: CompStructure,
  appDefinitionId: string,
) => {
  if (appDefinitionId !== 'dataBinding') {
    return;
  }

  const [selectedComponent] = editorAPI.selection.getSelectedComponents();

  if (selectedComponent) {
    const selectedCompLayout =
      editorAPI.components.layout.get_rect(selectedComponent);

    if (!compDef.layout) {
      compDef.layout = {};
    }
    // components, added from controller settings panel, should be added in the center 10px below the bound component
    _.merge(compDef.layout, {
      x:
        selectedCompLayout.x +
        selectedCompLayout.width / 2 -
        compDef.layout.width / 2,
      y:
        selectedCompLayout.y +
        selectedCompLayout.height +
        DATA_BINDING_COMPONENT_MARGIN,
    });
  }
};
