import _ from 'lodash';
import experiment from 'experiment';

import type { ComponentPanelType } from '@/componentModel';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import {
  biLogger,
  editorModel,
  editorWixRecorder,
  fedopsLogger,
  isCustomMenusEnabled,
} from '@/util';
import { WizardApiKey, WorkspaceRightPanelApiKey, BlocksApiKey } from '@/apis';
import { panelHeaderClose, panelOpened } from '@wix/bi-logger-editor/v2';

import {
  addPanelFilter,
  closeAllPanels,
  closePanelByNameAction,
  closePanelByPropKeyAction,
  openPanelAction,
  removePanelFilter,
  setPanelType,
  updatePanelAction,
} from './panelsActionsInternal';
import panelsSectionsDefinitionsDefaults from './panelsSectionsDefinitionsDefaults';
import * as panelsSelectors from './panelsSelectors';
import {
  FIRST_TIME_PANEL_COMPONENTS,
  CUSTOM_MENUS_FIRST_TIME_PANEL_COMPONENTS,
  ALLOWED_PANELS_DURING_WIZARD,
} from './panelContants';
import { applyPanelPropsTransformers } from './panelPropsTransformers';
import * as bi from '../bi/bi';
import * as devMode from '../devMode/devMode';
import * as dynamicPages from '../dynamicPages/dynamicPages';
import * as helpPanel from '../helpPanel/helpPanel';
import { StageBehaviour } from './types';
import { getPanelStageBehaviour } from '../leftBar/leftBarSelectors';
import { getComponentPanelPathByType_DEPRECATED } from './getComponentPanelPath';
import { isSiteCreationWizardVisible } from '../siteCreation/siteCreationSelectors';

import type {
  PanelOptions,
  ComponentPanelOptions,
  PanelDescriptor,
  PanelProps,
  PanelType,
} from './types';
import type { CompRef } from 'types/documentServices';
import type { Dispatch, StateMapperArgs, ThunkAction } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import createComponentPanelPropsWithConfiguration from './componentPanelProps';
import type { WizardApi } from '@/wizard';

const ORGANIZE_IMAGES_PANEL_NAME = 'panels.focusPanels.organizeImagesPanel';
const OPEN_LEFT_PANEL_ORIGIN = 'open_left_panel';

const devModeSelectors = devMode.selectors;
const devModeActions = devMode.actions;
const helpPanelActions = helpPanel.actions;
const dynamicPagesActions = dynamicPages.actions;

const { selectDevModeContext } = devModeSelectors;
const { selectOpenPanels, selectPanelFilters } = panelsSelectors;

const panelTypesToCloseOnStageMouseDown: PanelType[] = [
  constants.PANEL_TYPES.LEFT,
  constants.PANEL_TYPES.TPA_SETTINGS,
];

function createPanelProps(
  panelName: string,
  panelProps: PanelProps,
): PanelProps {
  return {
    panelName,
    key: panelName + _.uniqueId(),
    ...panelProps,
    panelSections:
      constants?.PANEL_SECTIONS?.[
        panelName as keyof typeof constants.PANEL_SECTIONS
      ],
    panelSectionsDefinition:
      panelName in panelsSectionsDefinitionsDefaults
        ? {
            ...panelsSectionsDefinitionsDefaults[
              panelName as keyof typeof panelsSectionsDefinitionsDefaults
            ],
            ...panelProps?.panelSectionsDefinition,
          }
        : panelProps?.panelSectionsDefinition,
  };
}

function getUserPermissionsUrlParams(): string {
  const { permissionsInfo } = editorModel;
  if (permissionsInfo) {
    const { ownerId } = permissionsInfo;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const roles = _.map(permissionsInfo.loggedInUserRoles, 'role').join(',');
    return `ownerId=${ownerId}&roles=${roles}`;
  }
  return '';
}

function stringifyPanelProps(panelProps: Partial<PanelProps>): string {
  let panelPropsString: string;
  try {
    panelPropsString = JSON.stringify(panelProps);
  } catch (error: MaybeError) {
    panelPropsString = `JSON.stringify failed: ${error.message}`;
  }
  return panelPropsString;
}

const resetContextIfNeeded =
  (prevOpenPanels: PanelDescriptor[]): ThunkAction =>
  (dispatch, getState, { dsRead }) => {
    const state = getState();
    const currentOpenPanels = selectOpenPanels(state);
    const panelsDiff = _.difference(prevOpenPanels, currentOpenPanels);

    const closedFullStagePanels = panelsDiff.filter(
      (panel) => panel.name === 'platformPanels.fullStagePanel',
    );
    if (
      !_.isEmpty(closedFullStagePanels) &&
      selectDevModeContext(state).type ===
        constants.DEVELOPER_MODE.CONTEXT_TYPES.COLLECTION
    ) {
      dispatch(devModeActions.resetDevModeContext());
      if (dsRead.routers.getCurrentInnerRoute().isDynamic) {
        dispatch(dynamicPagesActions.fetchInnerRoutes());
      }
    }
  };

// TODO: find better way to declare panel props
const updateOrOpenPanel =
  <TPanelProps = any>(
    panelName: string,
    panelProps?: TPanelProps & PanelProps,
    optionsOrLeavePanelsOpen?: PanelOptions | boolean,
    resolve?: () => void,
  ) =>
  (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const options =
      typeof optionsOrLeavePanelsOpen === 'boolean'
        ? { leavePanelsOpen: optionsOrLeavePanelsOpen }
        : optionsOrLeavePanelsOpen ?? {};

    if (!options.leavePanelsOpen) {
      dispatch(closeOpenedPanels());
    }

    if (panelName.includes('help')) {
      console.error(
        'openPanel method is deprecated for help panels. Use openHelpCenter instead',
      );
    }

    const currentOpenPanels = selectOpenPanels(getState());
    const currentInstance = currentOpenPanels.find(
      (panelDescriptor) => panelDescriptor.name === panelName,
    );

    if (currentInstance?.singleInstance) {
      dispatch(updatePanel(panelName, panelProps));

      return;
    }

    dispatch(
      openPanel(panelName, panelProps, {
        ...options,
        resolve: options.resolve ?? resolve,
      }),
    );
  };

// TODO: just use wizardApi when siteCreation is deprecated
const isWizardRunning = (wizardApi: WizardApi, state: EditorState) => {
  return isSiteCreationWizardVisible(state) || wizardApi.isWizardActive();
};

const isPanelBlockedByWizard = (
  state: EditorState,
  wizardApi: WizardApi,
  panelName: string,
) => {
  return (
    isWizardRunning(wizardApi, state) &&
    !ALLOWED_PANELS_DURING_WIZARD.includes(panelName)
  );
};

const openPanel =
  (
    panelName: string,
    panelProps: Partial<PanelProps>,
    options: PanelOptions,
  ): ThunkAction =>
  (dispatch, getState, { editorAPI, host }) => {
    const transformedDescriptor = applyPanelPropsTransformers({
      name: panelName,
      props: panelProps,
    });
    const panelDescriptor: PanelDescriptor = {
      name: transformedDescriptor.name,
      props: createPanelProps(
        transformedDescriptor.name,
        transformedDescriptor.props,
      ),
      panelLoader: options?.panelLoader,
      openTime: Date.now(),
    };

    if (panelProps?.token) {
      Object.assign(panelDescriptor, { token: panelProps?.token });
    }
    if (panelProps?.frameType) {
      Object.assign(panelDescriptor, { frameType: panelProps?.frameType });
    }
    const origin = panelProps?.origin ?? '';
    const state = getState();
    const panelFilters = selectPanelFilters(state);
    const isBlockedByFilter = Object.values(panelFilters).some((filter) =>
      filter(panelDescriptor),
    );
    const wizardApi = host.getAPI(WizardApiKey);

    if (
      isBlockedByFilter ||
      isPanelBlockedByWizard(state, wizardApi, panelName)
    ) {
      options?.resolve?.();
    } else {
      dispatch(openPanelAction(panelDescriptor));

      // NOTE: editorAPI could be undefined in the tests
      const selectedComponentRef =
        editorAPI?.selection.getSelectedComponents()[0];
      const selectedComponentType =
        selectedComponentRef &&
        editorAPI.components.getType(selectedComponentRef);

      editorWixRecorder.addLabel(`${panelName} panel opened`);

      biLogger.report(
        panelOpened({
          origin,
          panel_name: panelName,
          panelProps: stringifyPanelProps(panelProps),
          component_type: selectedComponentType,
          component_id: selectedComponentRef?.id,
          panel_correlation_id: panelProps?.panel_correlation_id,
          section_correlation_id: panelProps?.section_correlation_id,
        }),
      );
    }
  };

const closeOpenedPanels =
  (): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const state = getState();
    const currentOpenPanels = selectOpenPanels(state);

    // NOTE: editorAPI could be undefined in the tests
    const selectedComponentRef =
      editorAPI?.selection.getSelectedComponents()[0];
    const selectedComponentType =
      selectedComponentRef &&
      editorAPI.components.getType(selectedComponentRef);

    currentOpenPanels.forEach((panelDescriptor) => {
      if (!panelDescriptor.closeWithUserIntent) {
        panelDescriptor.resolve?.();
      }

      biLogger.report(
        panelHeaderClose({
          panel_name: panelDescriptor.name,
          component_type: selectedComponentType,
          component_id: selectedComponentRef?.id,
          timeSpent: panelDescriptor.openTime
            ? Date.now() - panelDescriptor.openTime
            : null,
        }),
      );
    });

    dispatch(closeAllPanels());
    dispatch(resetContextIfNeeded(currentOpenPanels));
  };

const closePanelsOnStageMouseDown = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const currentOpenPanels = selectOpenPanels(state);

  const panelsToClose = currentOpenPanels.filter((panel) =>
    panelTypesToCloseOnStageMouseDown.includes(panel.frameType),
  );

  panelsToClose.forEach((panelDescriptor) => {
    dispatch(closePanelByName(panelDescriptor.name, 'outer_click'));
  });
};

const closePanelByPropKey =
  (prop: string, propValue: unknown, closeOrigin: string): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const state = getState();
    const prevOpenPanels = selectOpenPanels(state);
    _(state)
      .thru(selectOpenPanels)
      .filter(
        (panel) => panel.props[prop as keyof typeof panel.props] === propValue,
      )
      .forEach((panel) => {
        panel.resolve?.();
      });
    dispatch(closePanelByPropKeyAction(prop, propValue, closeOrigin));
    const updatedOpenPanels = selectOpenPanels(getState());

    const closedPanels = _.difference(prevOpenPanels, updatedOpenPanels);

    // NOTE: editorAPI could be undefined in the tests
    const selectedComponentRef =
      editorAPI?.selection.getSelectedComponents()[0];
    const selectedComponentType =
      selectedComponentRef &&
      editorAPI.components.getType(selectedComponentRef);
    closedPanels.forEach((closedPanel) => {
      dispatch(
        bi.actions.event(coreBi.events.panels.PANEL_CLOSED, {
          panel_name: closedPanel.props.panelName,
          origin: closeOrigin,
          component_type: selectedComponentType,
          component_id: selectedComponentRef?.id,
          timeSpent: closedPanel.openTime
            ? Date.now() - closedPanel.openTime
            : null,
        }),
      );
    });

    dispatch(resetContextIfNeeded(prevOpenPanels));
  };

const closePanelByName =
  (panelName: string, closeOrigin?: string): ThunkAction =>
  (dispatch) => {
    const transformedPanelDescriptor = applyPanelPropsTransformers({
      name: panelName,
      props: {},
    });

    dispatch(
      closePanelByPropKey(
        'panelName',
        transformedPanelDescriptor.name,
        closeOrigin,
      ),
    );
  };

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

// used only for new workspace util.workspace.isNewWorkspaceEnabled
const openLeftPanel =
  (panelName: string, panelProps?: any, leavePanelsOpen?: boolean) =>
  async (dispatch: AnyFixMe) => {
    if (!leavePanelsOpen) {
      dispatch(closeOpenedPanels());
    }
    const editorAPI = dispatch(getEditorAPI);
    const workspaceRightPanelAPI = editorAPI.host.getAPI(
      WorkspaceRightPanelApiKey,
    );
    if (workspaceRightPanelAPI.isOpen()) {
      const stageBehaviour = getPanelStageBehaviour(
        editorAPI.store.getState(),
        panelName,
        editorAPI.isPopUpMode(),
      );
      const shouldExitZoomMode = stageBehaviour.open !== StageBehaviour.Shrink;
      await workspaceRightPanelAPI.close(
        OPEN_LEFT_PANEL_ORIGIN,
        shouldExitZoomMode,
      );
    }
    const transformedDescriptor = applyPanelPropsTransformers({
      name: panelName,
      props: panelProps,
    });

    const panelDescriptor = {
      name: transformedDescriptor.name,
      props: createPanelProps(
        transformedDescriptor.name,
        transformedDescriptor.props,
      ),
      frameType: constants.PANEL_TYPES.LEFT,
    };

    const origin = panelProps?.origin ?? '';

    dispatch(openPanelAction(panelDescriptor));
    editorWixRecorder.addLabel(`${panelName} panel opened`);
    dispatch(
      bi.actions.event(coreBi.events.panels.PANEL_OPENED, {
        origin,
        panel_name: panelName,
      }),
    );
  };

const openRightPanel =
  (panelName: string, panelProps?: any) => (dispatch: AnyFixMe) => {
    dispatch(closeOpenedPanels());

    const transformedDescriptor = applyPanelPropsTransformers({
      name: panelName,
      props: panelProps,
    });

    const panelDescriptor = {
      name: transformedDescriptor.name,
      props: createPanelProps(
        transformedDescriptor.name,
        transformedDescriptor.props,
      ),
      frameType: constants.PANEL_TYPES.RIGHT,
    };

    const origin = panelProps?.origin ?? '';

    dispatch(openPanelAction(panelDescriptor));
    editorWixRecorder.addLabel(`${panelName} panel opened`);
    dispatch(
      bi.actions.event(coreBi.events.panels.PANEL_OPENED, {
        origin,
        panel_name: panelName,
      }),
    );
  };

const openComponentPanel =
  (
    panelName: string,
    panelProps?: {
      selectedComponents?: CompRef[];
    } & PanelProps,
    options: ComponentPanelOptions = {},
  ): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    // NOTE: editorAPI could be undefined in the tests
    const selectedComponents = editorAPI?.selection.getSelectedComponents();
    const selectedComponent = selectedComponents[0];
    const compType =
      selectedComponent && editorAPI.components.getType(selectedComponent);

    const { INTERACTIONS } = fedopsLogger;
    const interactionsForComponentType =
      INTERACTIONS.PANELS[compType as keyof typeof INTERACTIONS.PANELS] || {};
    const interactionKey =
      interactionsForComponentType[
        panelName as keyof typeof interactionsForComponentType
      ] || INTERACTIONS.PANELS[panelName as keyof typeof INTERACTIONS.PANELS];

    const blocksBiData = editorAPI?.host.getAPI(BlocksApiKey).getBlocksBiData();

    if (interactionKey) {
      fedopsLogger.interactionStarted(interactionKey);
    }

    const overloadedPanelProps = createComponentPanelPropsWithConfiguration(
      panelName,
      panelProps,
      editorAPI.platform.controllers.getConnectedComponentStageData(
        selectedComponent,
      ),
    );

    const panelDescriptor = Object.assign(
      {},
      {
        name: panelName,
        loader: options.panelLoader,
        componentRef: selectedComponent,
        openTime: Date.now(),
        props: Object.assign(
          {},
          createPanelProps(panelName, overloadedPanelProps),
          {
            useLastPanelPosition: !!panelProps?.useLastPanelPosition,
          },
        ),
        frameType: constants.PANEL_TYPES.COMP,
      },
      options?.panelMetaData || {},
    );

    if (!options?.leavePanelsOpen) {
      dispatch(closeOpenedPanels());
    }

    dispatch(openPanelAction(panelDescriptor));
    editorWixRecorder.addLabel(`${panelName} panel opened`);
    const biOptions = panelProps?.biParams || {};

    dispatch(
      bi.actions.event(coreBi.events.panels.PANEL_OPENED, {
        ...blocksBiData,
        panel_name: panelName,
        component_type: compType,
        component_id: selectedComponent?.id,
        origin: panelProps?.origin,
        panelProps: stringifyPanelProps(panelProps),
        ...biOptions,
      }),
    );
  };

const closeAllPanelsOfType =
  (panelType: PanelType, origin?: string): ThunkAction =>
  (dispatch, getState) => {
    const openPanels = selectOpenPanels(getState());

    openPanels.forEach((panelDescriptor) => {
      if (panelDescriptor.frameType === panelType) {
        dispatch(closePanelByName(panelDescriptor.name, origin));
      }
    });
  };

const openHelpCenter =
  (helpId: string, props?: object, biHelpParams?: AnyFixMe): ThunkAction =>
  (dispatch, _getState, { editorAPI }) => {
    // NOTE: editorAPI could be undefined in the tests
    const params = [];
    const permissionsUrlParams = getUserPermissionsUrlParams();
    if (permissionsUrlParams) {
      params.push(permissionsUrlParams);
    }

    if (biHelpParams?.component) {
      params.push(`selectedCompType=${biHelpParams.component}`);
    }

    const helpUrlParams = helpId || '';
    const helpUrl = params.length
      ? `${helpUrlParams}?${params.join('&')}`
      : helpUrlParams;

    const helpProps = Object.assign({}, props, {
      helpUrl,
      biHelpParams,
      panelName: 'panels.helpPanels.helpPanel',
    });

    dispatch(helpPanelActions.openHelpCenter(helpProps));

    const blocksBiData = editorAPI?.host.getAPI(BlocksApiKey).getBlocksBiData();
    const helpParamsForBi = Object.assign(
      { ...blocksBiData, learn_more: false, helpId },
      biHelpParams,
    );
    dispatch(bi.actions.event(coreBi.events.help.HELP_CLICK, helpParamsForBi));
  };

const openHelpCenterHome =
  (type: string, props: object, biHelpParams: AnyFixMe): ThunkAction =>
  (dispatch) => {
    const params = [];
    const permissionsUrlParams = getUserPermissionsUrlParams();
    if (permissionsUrlParams) {
      params.push(permissionsUrlParams);
    }

    if (biHelpParams?.component) {
      params.push(`selectedCompType=${biHelpParams.component}`);
    }

    params.push(`type=${type}`);

    const helpUrl = `?${params.join('&')}`;

    const helpProps = Object.assign({}, props, {
      helpUrl,
      biHelpParams,
      panelName: 'panels.helpPanels.helpPanel',
    });

    dispatch(helpPanelActions.openHelpCenter(helpProps));

    const helpParamsForBi = Object.assign({ learn_more: false }, biHelpParams);
    dispatch(bi.actions.event(coreBi.events.help.HELP_CLICK, helpParamsForBi));
  };

const openPlatformPanel =
  (options: AnyFixMe): ThunkAction =>
  (dispatch) => {
    if (
      !experiment.isOpen('se_platform_modal_native_panel') &&
      options.nativePanelData
    ) {
      throw new Error(
        'Error trying to use new native panel when experiment is closed.',
      );
    }

    const updatedOptions = Object.assign(
      {
        name: options.panelName,
        props: createPanelProps(options.panelName, options.panelProps),
        type: constants.PANEL_TYPES.PLATFORM,
        token: options.panelProps.token,
      },
      options?.panelMetaData ?? {},
    );

    dispatch(
      updateOrOpenPanel(
        updatedOptions.name,
        updatedOptions.props,
        !updatedOptions.closeAllOtherPanels,
        updatedOptions.resolve,
      ),
    );
    dispatch(updatePanelAction(updatedOptions));
  };

const closePlatformPanel =
  (token: string, value: unknown, origin: string): ThunkAction =>
  (dispatch, getState) => {
    const openPanels = selectOpenPanels(getState());
    const panelDescriptor = openPanels.find(
      (panelData) => panelData.token === token,
    );

    if (!panelDescriptor) {
      return;
    }

    panelDescriptor.resolve?.(value);

    dispatch(closePanelByName(panelDescriptor.name, origin));
  };

const openFirstTimePanel =
  (
    compType: string,
    { componentRef = {}, controllerType = '', appDefId = '' },
  ): ThunkAction =>
  (dispatch, _getState, { editorAPI }) => {
    const APP_WIDGET_COMP_TYPE = 'AppWidget';
    const CONTROLLER_COMP_TYPE = 'AppController';
    const isController =
      compType === APP_WIDGET_COMP_TYPE || compType === CONTROLLER_COMP_TYPE;
    const isOOIController = editorAPI.dsRead.tpa.isTpaByCompType(compType);

    if (isController || isOOIController) {
      const firstTimeManifestData =
        editorAPI.platform.controllers.getFirstTimeExperienceFromStageData(
          componentRef,
        ) || {};
      if (!_.isEmpty(firstTimeManifestData) && !firstTimeManifestData.hidden) {
        const panelPath = isOOIController ? APP_WIDGET_COMP_TYPE : compType;
        const panelName = `compPanels.panels.${panelPath}.firstTime`;
        const panelData = Object.assign(
          {},
          {
            controllerType,
            appDefId,
            discardTranslation: true,
            panelName,
            title: 'firstTime',
          },
          firstTimeManifestData,
        );
        dispatch(openComponentPanel(panelName, panelData));
      }
    } else if (
      FIRST_TIME_PANEL_COMPONENTS.includes(compType) ||
      (isCustomMenusEnabled() &&
        CUSTOM_MENUS_FIRST_TIME_PANEL_COMPONENTS.includes(compType))
    ) {
      const firstTimeExperience =
        editorAPI.platform.connectedComponents.getFirstTimeExperienceFromStageData(
          componentRef,
        ) || {};
      if (!firstTimeExperience.hidden) {
        const panelName = `compPanels.panels.${compType}.firstTime`;
        dispatch(
          openComponentPanel(panelName, {
            panelName,
            title: 'firstTime',
          }),
        );
      }
    }
  };

const openDeprecationPanel =
  (panelName: string, props: PanelProps): ThunkAction =>
  (dispatch) => {
    dispatch(
      openComponentPanel(panelName, {
        panelName,
        ...props,
      }),
    );
  };

const updatePanel =
  (name: string, props: PanelProps): ThunkAction =>
  (dispatch) => {
    const descriptor = applyPanelPropsTransformers({
      name,
      props,
    });

    dispatch(updatePanelAction(descriptor));
  };

// TODO: find better place for this type
interface OrganizeImagesPanelProps {
  isPreset?: string;
  imagesChanged?: (data?: unknown) => void;
  createImageDataItem?: () => void;
  galleryType?: string;
}

const openOrganizeImagesPanel =
  (onImagesChanged: (data: unknown) => void): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    dispatch(
      updateOrOpenPanel<OrganizeImagesPanelProps>(
        ORGANIZE_IMAGES_PANEL_NAME,
        editorAPI.panelHelpers.getImagePanelsProps('images', onImagesChanged),
        true,
      ),
    );
  };

const createComponentPanelNameFromType = (
  componentType: string,
  componentPanelType: ComponentPanelType | string,
) => `${componentType}.${componentPanelType}`;

const openComponentPanelFromType =
  (
    componentType: string,
    componentPanelType: ComponentPanelType | string,
    componentPanelProps: PanelProps,
    componentPanelOptions: ComponentPanelOptions = {},
  ): ThunkAction =>
  async (dispatch, getState, { dsRead, editorAPI }) => {
    const componentRef: CompRef = _.head(
      editorAPI.selection.getSelectedComponents(),
    );
    const componentModel = await import('@/componentModel');

    //TODO RM FROM THIS
    const unsafeComponentMetadata =
      componentModel.__getUnsafeComponentMetaData(componentType);
    const unsafeOpenPanel = unsafeComponentMetadata?.getPanelOpener?.({
      componentPanelType,
      editorAPI,
      componentPanelProps,
    });

    if (unsafeOpenPanel) {
      unsafeOpenPanel();
      return;
    }
    //TO THIS

    if (componentModel.hasComponentPanel(componentType, componentPanelType)) {
      return dispatch(
        openComponentPanel(
          createComponentPanelNameFromType(componentType, componentPanelType),
          componentPanelProps,
          {
            ...componentPanelOptions,
            // panelLoader
            panelLoader: () =>
              componentModel.loadComponentPanel(
                componentType,
                componentPanelType,
              ),
          },
        ),
      );
    }

    return getComponentPanelPathByType_DEPRECATED({
      componentType,
      componentPanelType,
      isTpa: dsRead.tpa.isTpaByCompType(componentType),
      isMobileComponent: componentRef.type === 'MOBILE',
      // `mobile?.mobileOnlyComponents?.` because editor X, which uses classic editor panels API, does not have `dsRead.mobile.mobileOnlyComponents`
      // https://github.com/wix-private/editorx-santa-editor-bridge/blob/090fc4089df8655b642fd44bda4ae2b490c201d2/packages/santa-editor-bridge/src/main/classicEditorPackage.tsx#L718-L722
      // TODO: cleanup when editorX ready with real `dsRead.mobile` mock
      isMobileOnlyComponent:
        dsRead.mobile?.mobileOnlyComponents?.isMobileOnlyComponent(
          componentRef.id,
        ),
    }).then((panelPath) =>
      dispatch(
        openComponentPanel(
          panelPath,
          componentPanelProps,
          componentPanelOptions,
        ),
      ),
    );
  };

export {
  openPanelAction,
  updatePanelAction,
  closeAllPanels,
  closePanelByNameAction,
  openDeprecationPanel,
  setPanelType,
  addPanelFilter,
  removePanelFilter,
  updateOrOpenPanel,
  openLeftPanel,
  openRightPanel,
  openComponentPanel,
  openComponentPanelFromType,
  createComponentPanelNameFromType,
  openPlatformPanel,
  closeOpenedPanels,
  closePanelsOnStageMouseDown,
  // TODO: should not be used outside, but now used by `appMarketUtils.ts` */
  closePanelByPropKeyAction as closePanelByPropKeyAction_DEPRECATED,
  closePanelByPropKey,
  closePanelByName,
  openHelpCenter,
  openHelpCenterHome,
  closeAllPanelsOfType,
  closePlatformPanel,
  openFirstTimePanel,
  updatePanel,
  openOrganizeImagesPanel,
};
