// @ts-nocheck
import * as topBar from '../topBar/topBar';
import localModeActionTypes from './localModeActionTypes';
import * as overrideHelpers from './localModeOverrideHelpers';
import setPushingStatus from './setPushingStatus';
import setIsReconnecting from './setIsReconnecting';
import * as wixCodeIDEActions from '../wixCodeIDE/wixCodeIDEActions';
import * as savePublishActions from '../savePublish/savePublishActions';
import * as leftTreeActions from '../leftTree/leftTreeActions';
import * as developerToolBarActions from '../developerToolBar/developerToolBarActions';
import localServerEventsHandlerCreator from './localServerEventsHandlerCreator';

const topBarActions = topBar.actions;

const { ENABLE_LOCAL_MODE } = localModeActionTypes;

const openDevModeIfNeeded = (editorAPI) =>
  new Promise((resolve) => {
    const toggleDevMode = () => {
      if (!editorAPI.developerMode.isEnabled()) {
        editorAPI.developerMode.toggle({
          notifyApps: false,
        });
      }
    };

    const USER_PREFERENCES_LOADED_TIMEOUT = 5000;
    const timer = window.setTimeout(() => {
      toggleDevMode();
      resolve();
    }, USER_PREFERENCES_LOADED_TIMEOUT);

    editorAPI.registerInitUserPrefsCallback(() => {
      toggleDevMode();

      window.clearTimeout(timer);

      resolve();
    });
  });

const enableLocalMode = () => ({
  type: ENABLE_LOCAL_MODE,
});

const openLocalMode =
  () =>
  (dispatch, getState, { editorAPI }) =>
    new Promise((resolve) => {
      const state = getState();
      const localServerMessageHandler =
        localServerEventsHandlerCreator(editorAPI);
      dispatch(topBar.actions.setBannerTitle('Topbar_Local_Title'));
      dispatch(enableLocalMode());
      dispatch(
        topBarActions.overrideItems(
          overrideHelpers.getOverriddenTopBarItems(state),
        ),
      );
      dispatch(
        wixCodeIDEActions.overrideWixCodeIDE(
          overrideHelpers.overriddenWixCodeIdeData,
        ),
      );
      dispatch(
        leftTreeActions.overrideItems(overrideHelpers.overriddenLeftTree),
      );
      dispatch(savePublishActions.overrideShouldCheckForConcurrentUsers(false));
      dispatch(
        developerToolBarActions.override(
          overrideHelpers.overriddenDeveloperToolBar,
        ),
      );

      editorAPI.localMode.registerToMessages(
        localServerMessageHandler.handleEvents,
      );
      openDevModeIfNeeded(editorAPI).then(resolve);
    });

export { enableLocalMode, openLocalMode, setPushingStatus, setIsReconnecting };
