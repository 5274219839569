import type {
  SingleLayoutData,
  MeshItemLayout,
  FixedItemLayout,
  UnitSize,
  KeywordSize,
  LayoutSize,
  ItemLayouts,
  CalculationFormula,
} from 'types/documentServices';

export const layoutSize = {
  px: (value: number): UnitSize => ({ type: 'px', value }),
  percentage: (value: number): UnitSize => ({ type: 'percentage', value }),
  vw: (value: number): UnitSize => ({ type: 'vw', value }),
  auto: (): KeywordSize => ({ type: 'auto' }),
  formula: (
    formula: string,
    values: Record<string, UnitSize>,
  ): CalculationFormula => ({
    type: 'CalcFormula',
    value: { formula, values },
  }),
  siteWidth: (): LayoutSize => ({
    type: 'SystemVariable',
    value: 'siteWidth',
  }),
};

export function ensureLayoutSizeIsPx(
  layoutSize: LayoutSize,
): asserts layoutSize is { type: 'px'; value: number } {
  if (!layoutSize || layoutSize.type !== 'px') {
    throw new Error(
      `Layout size should be in 'px'.` +
        `\nReceived: ${JSON.stringify(layoutSize)}`,
    );
  }
}

export function getLayoutSizePxOrThrow(
  layoutSize: LayoutSize | undefined,
): number {
  ensureLayoutSizeIsPx(layoutSize);

  return layoutSize.value;
}

export function getLayoutSizeIfPx(layoutSize: LayoutSize | undefined): number {
  if (layoutSize?.type === 'px') {
    return layoutSize.value;
  }
}

export function isSiteWidthByLayouts(
  layouts: SingleLayoutData,
): layouts is SingleLayoutData & {
  componentLayout: { width: { type: 'SystemVariable'; value: 'siteWidth' } };
} {
  return (
    layouts?.componentLayout?.width &&
    layouts?.componentLayout?.width.type === 'SystemVariable' &&
    layouts?.componentLayout?.width.value === 'siteWidth'
  );
}

export function isMeshItemLayout(
  itemLayout: SingleLayoutData['itemLayout'],
): itemLayout is MeshItemLayout {
  return (
    itemLayout && 'type' in itemLayout && itemLayout.type === 'MeshItemLayout'
  );
}

export function hasMeshItemLayout(
  layouts: SingleLayoutData,
): layouts is SingleLayoutData & {
  itemLayout: MeshItemLayout;
} {
  return isMeshItemLayout(layouts?.itemLayout);
}

export function isFixedItemLayout(
  itemLayout: SingleLayoutData['itemLayout'],
): itemLayout is FixedItemLayout {
  return (
    itemLayout && 'type' in itemLayout && itemLayout.type === 'FixedItemLayout'
  );
}

export function hasFixedItemLayout(
  layouts: SingleLayoutData,
): layouts is SingleLayoutData & {
  itemLayout: FixedItemLayout;
} {
  return isFixedItemLayout(layouts?.itemLayout);
}

function assert(
  condition: boolean,
  message: string,
): asserts condition is true {
  if (!condition) {
    throw new Error(message);
  }
}

export function ensureItemLayoutIsMeshItemLayout(
  itemLayout: SingleLayoutData['itemLayout'],
): asserts itemLayout is MeshItemLayout {
  assert(isMeshItemLayout(itemLayout), 'itemLayout should be MeshItemLayout');
}

export function ensureItemLayoutIsFixedItemLayout(
  itemLayout: SingleLayoutData['itemLayout'],
): asserts itemLayout is FixedItemLayout {
  assert(isFixedItemLayout(itemLayout), 'itemLayout should be FixedItemLayout');
}

export function ensureItemLayoutTypeIs<T extends ItemLayouts['type']>(
  itemLayout: SingleLayoutData['itemLayout'],
  itemLayoutTypesToSupport: T[],
  assertMessage?: string,
): asserts itemLayout is Extract<ItemLayouts, { type: T }> {
  const itemLayoutType =
    itemLayout && 'type' in itemLayout ? itemLayout.type : undefined;

  assert(
    itemLayoutTypesToSupport.includes(itemLayoutType as T),
    assertMessage ??
      `'itemLayout' type is not supported (type: ${
        itemLayoutType ?? 'undefined'
      }).` +
        `\n Please use one of the next types:` +
        `\n ${itemLayoutTypesToSupport.join(', ')}')}`,
  );
}

export function ensureItemLayoutTypeIsNot<T extends ItemLayouts['type']>(
  itemLayout: SingleLayoutData['itemLayout'],
  itemLayoutTypesToSupport: T[],
  assertMessage?: string,
): asserts itemLayout is Exclude<ItemLayouts, { type: T }> {
  const itemLayoutType =
    itemLayout && 'type' in itemLayout ? itemLayout.type : undefined;

  assert(
    !itemLayoutTypesToSupport.includes(itemLayoutType as T),
    assertMessage ??
      `'itemLayout' type is not supported (type: ${
        itemLayoutType ?? 'undefined'
      }).`,
  );
}

const SUPPORTED_ITEM_LAYOUT_TYPES = [
  'MeshItemLayout' as const,
  'FixedItemLayout' as const,
];
type SupportedItemLayoutType = (typeof SUPPORTED_ITEM_LAYOUT_TYPES)[number];

export function ensureItemLayoutTypeIsSupported(
  itemLayout: SingleLayoutData['itemLayout'],
): asserts itemLayout is Extract<
  ItemLayouts,
  { type: SupportedItemLayoutType }
> {
  ensureItemLayoutTypeIs(itemLayout, SUPPORTED_ITEM_LAYOUT_TYPES);
}
