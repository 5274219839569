import { ensureComponentsAreSiblings } from '../lib/ensureComponentsAreSiblings';
import type { EditorAPI } from '@/editorAPI';
import type { LayoutMeshApi } from './createLayoutMeshApi';
import type { CompRef, Point, Rect } from 'types/documentServices';
import type { LayoutMoveToOptions } from '../type';
import type { HistoryApi } from '../createHistoryApi';

export function createLayoutMeshMoveToApi({
  editorAPI,
  historyApi,
  layoutMeshCoreApi,
}: {
  editorAPI: EditorAPI;
  historyApi: HistoryApi;
  layoutMeshCoreApi: LayoutMeshApi['__core'];
}) {
  async function moveTo(
    compRef: CompRef,
    compPosition: Partial<Point>,
    options: LayoutMoveToOptions = {},
  ): Promise<void> {
    await editorAPI.transactions.run(async () => {
      const compRect = layoutMeshCoreApi.measureRect(compRef);
      const compRectUpdated = {
        ...compRect,
        ...compPosition,
      };

      const containerRef = editorAPI.components.getContainer(compRef);
      layoutMeshCoreApi.updateContainerGrid(containerRef, {
        compRectsMap: new Map([[compRef.id, compRectUpdated]]),
      });
    });

    historyApi.debouncedAdd('component - update layout position', options);
  }

  async function moveToMany(
    compRefs: CompRef[],
    compPositionsMap: Map<string, Partial<Point>>,
    options: LayoutMoveToOptions = {},
  ): Promise<void> {
    ensureComponentsAreSiblings(editorAPI.dsRead, compRefs);

    await editorAPI.transactions.run(async () => {
      const containerRef = editorAPI.components.getContainer(compRefs[0]);

      layoutMeshCoreApi.updateContainerGrid(containerRef, {
        compRectsMap: new Map(
          compRefs.map<[string, Rect]>((compRef) => [
            compRef.id,
            {
              ...layoutMeshCoreApi.measureRect(compRef),
              ...compPositionsMap.get(compRef.id),
            },
          ]),
        ),
      });
    });

    historyApi.debouncedAdd('component - update layout position', options);
  }

  return {
    moveTo,
    moveToMany,
  };
}

export type LayoutMeshMoveToApi = ReturnType<typeof createLayoutMeshMoveToApi>;
