import {
  extensionsSlotsReducer,
  extensionsSlotsInitialState,
  registerSlotPortal,
  unregisterSlotPortal,
  registerSlotPortalNode,
  unregisterSlotPortalNode,
  updateSlotPortalProps,
  getSlots,
} from '@wix/extensions-slots-host';

export type { SlotsState } from '@wix/extensions-slots-host';

export { extensionsSlotsInitialState };

export { extensionsSlotsReducer as reducer };

export const actions = {
  registerSlotPortal,
  unregisterSlotPortal,
  updateSlotPortalProps,
  registerSlotPortalNode,
  unregisterSlotPortalNode,
};

export const selectors = { getSlots };
