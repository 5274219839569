import { queryApp } from '@wix/ambassador-devcenter-v1-app/http';
import { listDraftAppsComponents } from '@wix/ambassador-devcenter-v1-component/http';
import type { Component } from '@wix/ambassador-devcenter-v1-component/types';
import _ from 'lodash';
import * as util from '@/util';
import { HttpClient } from '@wix/http-client';
import {
  type GetDeveloperAppsResponse,
  type GetAppSnapshotResponse,
  type GetAppInfoResponse,
  ComponentType,
  type Component as ComponentOld,
  type AppRequest,
  type QueryAppsResponse,
} from '@wix/ambassador-app-service-webapp/types';
import { AppServiceWebapp } from '@wix/ambassador-app-service-webapp/http';
import { queryMarketListing } from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/http';
import { queryMarketApp } from '@wix/ambassador-devcenter-market-apps-v1-market-app/http';
import type { MarketListing } from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/types';
import { getWebSolutionsBase } from '@wix/ambassador-devcenter-marketplace-v1-web-solution/http';
import {
  queryAppVersion,
  listLatestProductionVersions,
  listNotes,
} from '@wix/ambassador-devcenter-apps-v1-app-version/http';
import type { AppVersion } from '@wix/ambassador-devcenter-apps-v1-app-version/types';
import { SortOrder } from '@wix/ambassador-devcenter-apps-v1-app-version/types';
import type { VersionsResponse } from '@wix/ambassador-site-apps-server/types';
import experiment from 'experiment';
import type { Account } from '@wix/ambassador-identity-account-v2-account/types';
import { accountServerFacade } from './accountServerFacade';

const APPS_SERVICES_BASE_URL = 'https://dev.wix.com/_api/app-service/v1/';
const appsServiceBaseUrl = `${util.serviceTopology.editorRootUrl}_api/app-service/v1`;
const AppMarketService = AppServiceWebapp('/_api/app-service-webapp/').AppsV2();
const httpClient = new HttpClient();
const AppService = AppServiceWebapp('/_api/app-service-webapp/').Apps();

interface DeveloperAppData {
  appDefinitionId: string;
  appDefinitionName: string;
  latestVersion: string;
  dateCreated?: string;
}

const fetchLatestVersionMap = async (appIds: string[]) => {
  let appVersionList: AppVersion[] = [];
  const chunkIds = _.chunk(appIds, 50);

  for (const chunk of chunkIds) {
    const appVersions = await fetchListLatestProductionVersions(chunk);

    appVersionList = appVersionList.concat(appVersions);
  }

  return appVersionList;
};

const getAccountOnlyApps = async (
  userId: string,
  accountId: string,
): Promise<DeveloperAppData[]> => {
  const response = await httpClient.request(
    queryApp({
      query: {
        filter: {
          accountId,
          'createdBy.id': { $ne: userId },
          status: { $ne: 'Archived' },
        },
      },
    }),
  );

  let alAppsData = response.data.apps;
  let nextCursor = response.data.pagingMetadata.cursors.next;

  while (nextCursor) {
    const nextResponse = await httpClient.request(
      queryApp({ query: { cursorPaging: { cursor: nextCursor } } }),
    );

    alAppsData = alAppsData.concat(nextResponse.data.apps);
    nextCursor = nextResponse?.data?.pagingMetadata?.cursors?.next;
  }

  const latestVersionArr = await fetchLatestVersionMap(
    alAppsData.map((appData) => appData.id),
  );

  const appToLatestVersionMap = _.keyBy(latestVersionArr, 'id');

  return alAppsData
    .map((appData) => {
      return {
        appDefinitionId: appData.id,
        appDefinitionName: appData.name,
        latestVersion: appToLatestVersionMap[appData.id]?.fullVersion,
      };
    })
    .filter((appData) => !!appData.latestVersion);
};

const filterDeveloperAndContributionApps = (
  userWorkspaces: Account[],
  developerApps: AnyFixMe[],
) => {
  const userWorkspacesMap = userWorkspaces.reduce<Record<string, boolean>>(
    (map, { accountId }) => ({ ...map, [accountId]: true }),
    {},
  );

  return developerApps.filter((app) => !userWorkspacesMap[app.accountId]);
};

const getAllApps = async (
  userId: string,
  accountId: string,
): Promise<DeveloperAppData[]> => {
  if (experiment.isOpen('se_enableWorkspacesInPrivateApps')) {
    const [accountApps, developerApps, userWorkspaces]: [
      DeveloperAppData[],
      AnyFixMe[],
      Account[],
    ] = await Promise.all([
      getAccountOnlyApps(userId, accountId),
      getDeveloperAppsOld(false),
      accountServerFacade.getUserWorkspaces(userId),
    ]);
    const isInWorkspace = userId !== accountId;

    if (isInWorkspace) {
      const workSpaceApps = developerApps.filter(
        (app) => app.accountId === accountId,
      );
      return [...accountApps, ...workSpaceApps];
    }

    return removeDuplicateApps([
      ...accountApps,
      ...filterDeveloperAndContributionApps(userWorkspaces, developerApps),
    ]);
  }

  const accountApps = await getAccountOnlyApps(userId, accountId);
  const userApps = await getDeveloperAppsOld(false);

  return removeDuplicateApps([...accountApps, ...userApps]);
};

const removeDuplicateApps = (
  allAppsData: DeveloperAppData[],
): DeveloperAppData[] => {
  return _.uniqBy(allAppsData, 'appDefinitionId');
};

const getDeveloperApps = async (userId: string, accountId: string) => {
  const allAppsData = await getAllApps(userId, accountId);

  const componentsRes = await getAppsComponents(allAppsData);

  const developerAppsWithComponents = allAppsData.map((app) => {
    const components = componentsRes[app.appDefinitionId]?.map((compData) =>
      convertComponentDataToOldComponentData(compData),
    );

    return {
      appDefinitionId: app.appDefinitionId,
      appDefinitionName: app.appDefinitionName,
      latestVersion: app.latestVersion,
      dateCreated: app.dateCreated,
      compTypes: _.uniq(
        components?.map((compData) => compData.compType).filter(Boolean),
      ),
      blocksVersion: getBlocksVersion(components),
      components,
    };
  });

  return Promise.resolve(developerAppsWithComponents);
};

const fetchListDraftAppsComponents = async (appIdArr: string[]) => {
  const response = await httpClient.request(
    listDraftAppsComponents({ appIds: appIdArr }),
  );
  return response.data.appToComponents;
};

const convertComponentDataToOldComponentData = (
  componentData: Component,
): ComponentOld => {
  return {
    compData: componentData.data as unknown as ComponentOld['compData'],
    compName: componentData.name,
    compType: componentData.type as ComponentOld['compType'],
    compId: componentData.id,
  };
};

const getAppsComponents = async (
  allApps: DeveloperAppData[],
): Promise<Record<string, Component[]>> => {
  const chuckedAppIds = _.chunk(
    allApps.map((app) => app.appDefinitionId),
    50,
  );

  const queryComponentPromiseArr = chuckedAppIds.map((appIdArr: string[]) => {
    return fetchListDraftAppsComponents(appIdArr);
  });
  const allComponentsRes = await Promise.all(queryComponentPromiseArr);

  const mergedMap = _.merge({}, ...allComponentsRes);

  return _.mapValues(mergedMap, 'components');
};

const getDeveloperAppsOld = (withComponents: boolean) => {
  return util.http
    .fetchJson(`${appsServiceBaseUrl}/apps?withComponents=${withComponents}`, {
      credentials: 'include',
    })
    .then((res: GetDeveloperAppsResponse) => {
      if (!res || !res.apps) {
        throw Error('Fetching apps from dev center failed');
      }
      return res.apps.map((app: GetAppInfoResponse) => ({
        appDefinitionId: app.appId,
        appDefinitionName: app.name,
        latestVersion: app.latestVersion,
        accountId: app.accountId,
        dateCreated: String(app.dateCreated),
        compTypes: _.uniq(
          app.components?.map((c: ComponentOld) => c.compType).filter(Boolean),
        ),

        blocksVersion: getBlocksVersion(app.components),
        components: app.components,
      }));
    });
};
const getBlocksVersion = (components: ComponentOld[]) => {
  const studioWidgetComponent = components?.find(
    ({ compType }) => compType === ComponentType.STUDIO_WIDGET,
  );

  if (!studioWidgetComponent) {
    // only Responsive Blocks (2.0.0) supports apps with CODE_PACKAGE or BACK_OFFICE_PAGE component
    const isResponsiveBlocks = components?.some(({ compType }) =>
      [ComponentType.CODE_PACKAGE, ComponentType.BACK_OFFICE_PAGE].includes(
        compType,
      ),
    );

    if (isResponsiveBlocks) return '2.0.0';
  }

  return studioWidgetComponent?.compData?.studioWidgetComponentData
    ?.blocksVersion;
};

const getAppInfoOld = (appDefIds: string[]) => {
  return util.http.fetchJson(
    `https://dev.wix.com/_api/app-service/v1/apps-info?apps-id=${appDefIds.join(
      '&apps-id=',
    )}`,
    { credentials: 'include' },
  );
};

const getAppSnapshotOld = (appDefinitionId: string) => {
  return util.http
    .fetchJson(`${APPS_SERVICES_BASE_URL}apps/${appDefinitionId}`)
    .then((res: GetAppSnapshotResponse) => {
      if (!res) {
        throw Error('Fetching app studio data from dev center failed');
      }
      return res;
    });
};

const appV2Query = async (
  appRequests: AppRequest[],
  languageCode: string = 'en',
) => {
  const appsIds: string[] = appRequests.map((item) => item.id);

  const [queryMarketListingRes, getWebSolutionsBaseRes] = await Promise.all([
    httpClient.request(
      queryMarketListing({
        query: {
          filter: {
            appId: appsIds,
            languageCode,
            status: 'PUBLISHED',
          },
        },
      }),
    ),
    await httpClient.request(
      getWebSolutionsBase({ idsOrSlugs: appsIds.join() }),
    ),
  ]);

  const webSolutionByAppId = _.keyBy(
    getWebSolutionsBaseRes.data.webSolutionsBase,
    'id',
  );

  return Promise.resolve({
    apps: queryMarketListingRes.data.marketListing.map(
      (marketListing: MarketListing) => ({
        id: marketListing.appId,
        name: marketListing.basicInfo.name,
        version: '1.0.0', // todo: version api - https://jira.wixpress.com/browse/WBL-4563
        market: {
          marketListing: {
            assetsMap: marketListing.assetsMap,
            basicInfo: marketListing.basicInfo,
            slug: webSolutionByAppId[marketListing.appId].slug,
          },
        },
      }),
    ),
  } as QueryAppsResponse);
};

const appV2QueryOld = (
  appRequests: AppRequest[],
  languageCode: string = 'en',
) => {
  return AppMarketService().query({
    languageCode,
    appRequests,
  });
};

const getVersionsByAppId = async (appId: string) => {
  const appsVersionsResponse = await httpClient.request(
    queryAppVersion({
      query: {
        filter: { appId, state: 'PRODUCTION' },
        sort: [{ fieldName: 'createdDate', order: SortOrder.DESC }],
      },
    }),
  );

  const oldVersionDataToNewVersionData = (appVersion: AppVersion) => {
    return {
      version: appVersion.fullVersion,
      dateUpdated: appVersion.updatedDate,
      dateCreated: appVersion.createdDate,
      status: 'RELEASED',
    };
  };

  return (
    (appsVersionsResponse?.data?.appVersions?.map(
      oldVersionDataToNewVersionData,
    ) as VersionsResponse[]) || []
  );
};
const appsV2Experiment = experiment.isOpen('se_newDevCenterApiAppsV2');
// todo: create experiments
const componentExperiment = experiment.isOpen('se_newDevCenterApiComponents');
const listingExperiment = experiment.isOpen('se_newDevCenterApiListing');
const namespaceExperiment = experiment.isOpen('se_newDevCenterApiNamespace');
const shareUrl = experiment.isOpen('se_newDevCenterApiShareUrl');

const fetchAppMarketData = async (apps: string[]) => {
  try {
    const { data } = await httpClient.request(
      queryMarketApp({
        query: {
          filter: {
            appId: apps,
          },
        },
      }),
    );

    return data?.marketApps;
  } catch (e) {
    return [];
  }
};

const fetchListLatestProductionVersions = async (appIds: string[]) => {
  const response = await httpClient.request(
    listLatestProductionVersions({ appIds }),
  );

  return response?.data?.appVersions;
};

export const getAppByVersion = async (
  appId: string,
  version: string,
  latestVersion: string,
) => {
  const dataByVersionResponse = await AppService().getAppByVersion({
    appId,
    version,
  });

  const getAgregatedReleaseNotes = async () => {
    const listNotesResponse = await httpClient.request(
      listNotes({
        appId,
        version: util.appStudioUtils.getVersion(
          version === 'latest' ? latestVersion : version,
        ).major,
      }),
    );

    const notesArray = listNotesResponse?.data?.notes || [];
    return notesArray
      .filter((noteData) => !_.isEmpty(noteData.note))
      .map(
        (noteData) =>
          `${util.appStudioUtils.formatVersion(noteData.fullVersion)} ${
            noteData.note
          }`,
      );
  };

  const listReleaseNotes = await getAgregatedReleaseNotes();

  return {
    data: dataByVersionResponse.data,
    listNotes: listReleaseNotes,
  };
};

export const devCenterFacade = {
  getDeveloperApps,
  getDeveloperAppsOld,
  // todo: blocked by versions https://jira.wixpress.com/browse/WBL-4228
  getAppSnapshot: getAppSnapshotOld,
  getAppInfo: getAppInfoOld,
  // todo: appsV2 https://jira.wixpress.com/browse/WBL-4235 - appMarket.ts, fetchAppMarketData.ts, platformActions.ts
  appV2Query: appsV2Experiment ? appV2Query : appV2QueryOld,
  // todo: namespace https://jira.wixpress.com/browse/WBL-4229 - createNamespacePanel.tsx
  namespaceSuggestion: namespaceExperiment,
  setNamespace: namespaceExperiment,
  // todo: listing https://jira.wixpress.com/browse/WBL-4231 - applicationStudioActions.ts
  updateName: listingExperiment,
  // todo: components https://jira.wixpress.com/browse/WBL-4233 - devCenterSettingsActions.ts
  createWidgetComponent: componentExperiment,
  updateWidgetComponent: componentExperiment,
  updatePlatformComponent: componentExperiment,
  // todo: versions https://jira.wixpress.com/browse/WBL-4228 - fetchAppVersion.ts
  getAppByVersion,
  getVersionsByAppId,
  // todo: shareUrl https://jira.wixpress.com/browse/WBL-4230 - appLinkActions.ts
  fetchAppLinkFromDevCenter: shareUrl,
  createAppLinkFromDevCenter: shareUrl,
  revokeAppLinkFromDevCenter: shareUrl,
  fetchAppMarketData,
  fetchListLatestProductionVersions,
};
