import _ from 'lodash';
import experiment from 'experiment';

import { components, interactions, inlinePopup } from '@/stateManagement';

import constants from '@/constants';
import * as util from '@/util';
import { EditorLayoutConstraintsUtil, layoutUtils } from '@/layoutUtils';

import {
  attachUtils,
  canAttachToSiteStructureByDrag,
  FORBIDDEN_CONTAINERS_IDS_TO_ATTACH_TO,
  getCompLayout,
  isCompletelyContained,
  isMostlyContained,
  isDirectChildOfMasterPage,
  isDraggingFromPageToSiteFooter,
  isDraggingFromPageToSiteStructure,
  isDraggingFromSiteStructureToPage,
  isMenuContainerFocused,
  pickSOAPCandidates,
  removeFooterComponents,
  isCandidatesChildrenOfCurrentPopupContainer,
} from './AttachHandler.utils';
import mobileUtil from '../mobileUtil';

import type { EditorState } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, CompLayout, Rect, Point } from 'types/documentServices';
import type { AttachCandidateResponse, AttachCandidateStatus } from '@/layout';

const ATTACH_TYPES = {
  COMP: 'comp',
  STRUCTURE: 'structure',
} as const;

const ATTACH_CANDIDATE_STATUSES = {
  SAME: 'same',
  VALID: 'valid',
  INVALID: 'invalid',
  // NOTE: current version of prettier and swc doesn't support suttisfies keyword
  // eslint-disable-next-line prettier/prettier
} as const; // satisfies Record<string, AttachCandidateStatus>;

type AttachType = ValueOf<typeof ATTACH_TYPES>;

type CompRefOrCompRefArray = CompRef | CompRef[];

interface Options {
  scopeRef?: CompRef;
  strictPageBoundary?: boolean;
  isDragHandler?: boolean;
}

interface InitialCompWithLayout {
  comp: CompRefOrCompRefArray;
  layout: {
    width: number;
    height: number;
  };
}

interface CompWithLayout {
  comp: CompRef;
  layout: CompLayout;
}

/**
 * function returns true for components which not allowed to be attached to in the interaction state.
 * @param editorAPI
 * @param state - editorState
 * @param compRef - single compRef from attachCandidates array
 */
function interactionsNotAllowedAttachComps(
  editorAPI: EditorAPI,
  state: EditorState,
  compRef: CompRef,
): boolean {
  const compType = editorAPI.components.getType(compRef);
  const triggerRef = interactions.selectors.getInteractionTriggerRef(state);
  if (editorAPI.columns.isStrip(triggerRef)) {
    return compType !== constants.COMP_TYPES.COLUMN;
  }

  return false;
}

function replaceToPopupContainer(
  editorAPI: EditorAPI,
  attachCandidates: CompWithLayout[],
  compRef: CompRef,
): CompRef | null {
  const currentPopupId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();

  if (currentPopupId) {
    if (!compRef) {
      const popupContainer = editorAPI.pages.popupPages.getPopupContainer();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find

      const isPopupContainer = attachCandidates.some(
        (candidate) => candidate?.comp?.id === popupContainer.id,
      );

      if (isPopupContainer) {
        return popupContainer;
      }
    } else if (compRef.id === currentPopupId) {
      return null;
    }
  }

  return compRef;
}

class AttachHandler {
  private editorAPI: EditorAPI;
  private type: AttachType;
  private initialCompWithLayout: InitialCompWithLayout;
  private comp: CompRefOrCompRefArray;
  private attachCandidates: CompWithLayout[];
  private scopeRef?: CompRef;
  private isDragHandler: boolean;
  private verticalBounds: [number, number];
  private fallbackSectionLikeRef?: CompRef;
  private fallbackSectionLikeStatus?: AttachCandidateStatus;

  static TYPES = ATTACH_TYPES;
  static ATTACH_CANDIDATE_STATUSES = ATTACH_CANDIDATE_STATUSES;

  constructor(
    editorAPI: EditorAPI,
    initialCompWithLayout: InitialCompWithLayout,
    type: AttachType,
    { scopeRef, strictPageBoundary, isDragHandler }: Options = {},
  ) {
    if (!editorAPI) {
      return;
    }

    this.editorAPI = editorAPI;
    this.type = type;
    this.initialCompWithLayout = initialCompWithLayout;
    this.comp = initialCompWithLayout.comp;
    this.scopeRef = scopeRef;
    this.isDragHandler = isDragHandler;

    const { y, height } = editorAPI.siteSegments.getPagesContainerAbsLayout();
    this.verticalBounds = strictPageBoundary
      ? [0, y + height]
      : [-Infinity, Infinity];
    if (
      !this.isDragHandler &&
      // eslint-disable-next-line @wix/santa/no-falsy-experiment
      !experiment.isOpen('getCurrentAttachCandidate')
    ) {
      this.attachCandidates = this.getAttachCandidates(
        initialCompWithLayout,
        type,
      );
    }
  }

  private getAttachCandidates = (
    compWithLayout: InitialCompWithLayout,
    type: AttachType,
    attachPosition?: Point,
  ) => {
    const { editorAPI, scopeRef, isDragHandler } = this;

    const headerComp = editorAPI.dsRead.siteSegments.getHeader();
    const footerComp = editorAPI.dsRead.siteSegments.getFooter();
    const focusedRootId = editorAPI.dsRead.pages.getFocusedPageId();
    let attachCandidates;

    if (isDragHandler && attachPosition) {
      const { scrollLeft, scrollTop } = editorAPI.scroll.get();
      const attachCandidatesUnderXY = editorAPI.components.get.byXYFromDom(
        Math.max(0, attachPosition.x - scrollLeft),
        Math.max(0, attachPosition.y - scrollTop),
      );

      if (attachCandidatesUnderXY.length) {
        attachCandidates = attachCandidatesUnderXY;
      } else if (!_.has(this, 'attachCandidates')) {
        attachCandidates = editorAPI.components.getAllComponents(focusedRootId);
      } else {
        return this.attachCandidates;
      }
    } else {
      attachCandidates = editorAPI.components.getAllComponents(focusedRootId);
    }

    if (canAttachToSiteStructureByDrag(editorAPI, compWithLayout.comp)) {
      attachCandidates = attachCandidates.concat(
        editorAPI.dsRead.siteSegments.getSiteStructure(),
      );
    }

    if (
      isCandidatesChildrenOfCurrentPopupContainer(
        editorAPI,
        util.array.asArray(compWithLayout.comp),
      )
    ) {
      const popupContainer = editorAPI.pages.popupPages.getCurrentPopup();
      const popupComponentIds = new Set([
        popupContainer.id,
        ...editorAPI.components
          .getChildren(popupContainer, true)
          .map(({ id }) => id),
      ]);
      attachCandidates = attachCandidates.filter(({ id }) =>
        popupComponentIds.has(id),
      );
    }

    const isExistingComponent = type === ATTACH_TYPES.COMP;
    if (util.sections.isSectionsEnabled()) {
      attachCandidates = attachCandidates.filter(
        (candidate) =>
          !FORBIDDEN_CONTAINERS_IDS_TO_ATTACH_TO.includes(candidate.id),
      );
    }

    if (isExistingComponent) {
      const isShownOnAllPages = editorAPI.components.isShowOnAllPages(
        compWithLayout.comp,
      );
      const isShownOnSomePages = editorAPI.components.isShowOnSomePages(
        compWithLayout.comp,
      );

      if (
        util.sections.isSectionsEnabled() &&
        (isShownOnAllPages || isShownOnSomePages)
      ) {
        attachCandidates = pickSOAPCandidates(
          editorAPI,
          attachCandidates,
          [compWithLayout.comp].flat(),
        );
      } else if (
        !isShownOnAllPages ||
        (editorAPI.siteSegments.isUsingSectionsLayout() &&
          isDirectChildOfMasterPage(editorAPI, compWithLayout.comp))
      ) {
        attachCandidates = removeFooterComponents(editorAPI, attachCandidates);
      }
    }

    const state = editorAPI.store.getState();
    const interactionMode = interactions.selectors.isInInteractionMode(state);

    if (editorAPI.isMobileEditor()) {
      if (Array.isArray(compWithLayout.comp)) {
        const isMobileOnlyComponent =
          mobileUtil.isSelectionContainsMobileOnlyComponent(
            editorAPI,
            compWithLayout.comp,
          );
        const isPageComponent = !mobileUtil.isShownOnAllPagesComponent(
          editorAPI,
          compWithLayout.comp,
        );

        if (!isMobileOnlyComponent) {
          if (isPageComponent) {
            attachCandidates = attachCandidates
              .map((attachCandidate) => {
                if (isDragHandler && util.sections.isSectionsEnabled()) {
                  const pageSections = editorAPI.sections.getPageSections(
                    editorAPI.dsRead.pages.getFocusedPage(),
                  );
                  const firstSection = pageSections[0];
                  const lastSection = pageSections[pageSections.length - 1];

                  if (attachCandidate.id === headerComp.id) {
                    return firstSection;
                  }

                  if (attachCandidate.id === footerComp.id) {
                    return lastSection;
                  }
                }

                // cannot attach page components into header or footer on mobile
                if (
                  attachCandidate.id === headerComp.id ||
                  attachCandidate.id === footerComp.id
                ) {
                  return undefined;
                }

                return attachCandidate;
              })
              .filter((attachCandidate) => attachCandidate);
          }

          if (!isPageComponent) {
            attachCandidates = attachCandidates.filter(
              (attachCandidate) =>
                mobileUtil.isContainedByHeaderOrFooter(
                  editorAPI,
                  attachCandidate,
                  true,
                ) ||
                mobileUtil.isContainedByHeaderOrFooter(
                  editorAPI,
                  attachCandidate,
                  false,
                ) ||
                attachCandidate.id === headerComp.id ||
                attachCandidate.id === footerComp.id,
            );
          }
        }
      }
      if (!inlinePopup.selectors.isInlinePopupOpened(state)) {
        attachCandidates = attachCandidates.filter((attachCandidate) => {
          const type = editorAPI.components.getType(attachCandidate);

          return !(constants.INLINE_POPUP.TYPES as [string, string]).includes(
            type,
          );
        });
      }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _(attachCandidates)
      .filter(function (attachCandidateComp) {
        return (
          !editorAPI.utils.isSameRef(
            attachCandidateComp,
            compWithLayout.comp,
          ) &&
          attachUtils[type].isContainable(
            editorAPI,
            compWithLayout.comp,
            attachCandidateComp,
          )
        );
      })
      .reject(
        (comp: CompRef) =>
          interactionMode &&
          interactionsNotAllowedAttachComps(editorAPI, state, comp),
      )
      .filter((comp: CompRef) =>
        components.selectors.getIsComponentVisibleInCurrentMode(
          editorAPI.dsRead,
          comp,
          state,
        ),
      )
      .filter(function isRendered(attachCandidateComp) {
        return editorAPI.components.isRenderedOnSite(attachCandidateComp);
      })
      .filter(function isDescendantOfScope(attachCandidateComp) {
        return scopeRef
          ? editorAPI.components.isDescendantOfComp(
              attachCandidateComp,
              scopeRef,
            ) || editorAPI.utils.isSameRef(attachCandidateComp, scopeRef)
          : true;
      })
      .filter(
        function isNotHeaderOrFooterWhenMenuContainerOpen(attachCandidateComp) {
          return (
            !isMenuContainerFocused(editorAPI) ||
            !editorAPI.utils.isSiteSegment(attachCandidateComp)
          );
        },
      )
      .map((attachCandidateComp) => {
        return {
          layout: getCompLayout(editorAPI, attachCandidateComp),
          comp: attachCandidateComp,
        };
      })
      .filter((attachCandidateWithLayout) => {
        const candidateIsHeader = editorAPI.utils.isSameRef(
          attachCandidateWithLayout.comp,
          headerComp,
        );
        const candidateIsFooter = editorAPI.utils.isSameRef(
          attachCandidateWithLayout.comp,
          footerComp,
        );
        const candidateIsPrimaryContainer = editorAPI.utils.isSameRef(
          attachCandidateWithLayout.comp,
          editorAPI.pages.getPrimaryContainer(),
        );

        if (scopeRef || candidateIsPrimaryContainer) {
          return true;
        }

        if (
          util.sections.isSectionsEnabled() &&
          isExistingComponent &&
          editorAPI.isDesktopEditor() &&
          candidateIsHeader
        ) {
          const currentContainer = attachUtils[this.type].getContainer(
            this.editorAPI,
            this.comp,
          );

          const isAttachedToHeader = currentContainer.id === headerComp.id;

          if (!isAttachedToHeader) {
            // allow attachment to a header only when an initial component box is not completely containable by header box
            return !isCompletelyContained(
              this.editorAPI,
              attachCandidateWithLayout.comp,
              compWithLayout.layout as Rect,
              attachCandidateWithLayout.layout,
            );
          }
        }

        if (
          util.sections.isSectionsEnabled() &&
          this.editorAPI.sections.isSectionLike(attachCandidateWithLayout.comp)
        ) {
          return true;
        }

        if (isDragHandler) {
          const isComponentFullWidth = attachUtils[type].isFullWidth(
            editorAPI,
            compWithLayout.comp,
          );
          const isAttachCandidateFullWidth = editorAPI.components.is.fullWidth(
            attachCandidateWithLayout.comp,
          );

          if (isComponentFullWidth) {
            return (
              isAttachCandidateFullWidth &&
              attachCandidateWithLayout.layout.width >=
                compWithLayout.layout.width
            );
          }

          if (editorAPI.columns.isStrip(compWithLayout.comp)) {
            return (
              attachCandidateWithLayout.layout.width >=
              compWithLayout.layout.width
            );
          }

          return true;
        }

        return (
          attachCandidateWithLayout.layout.width >=
            compWithLayout.layout.width &&
          (candidateIsHeader ||
            (util.sections.isSectionsEnabled() && candidateIsFooter) ||
            attachCandidateWithLayout.layout.height >=
              compWithLayout.layout.height)
        );
      })
      .thru((componentsWithLayout) => {
        // We don't need a case when there are no attach candidates at all.(When it's not possible to attach to sections, for example)
        // This logic is applied after all filters and is relevant for components like Anchor, AnchorsMenu and etc.
        // But in general, a user should have at least 2 attach candidates in this callback(a page and a section), so we will remove a page since it's not allowed to attach to it in most cases
        if (
          util.sections.isSectionsEnabled() &&
          componentsWithLayout.length > 1
        ) {
          return componentsWithLayout.filter(
            (compWithLayout) => compWithLayout.comp.id !== focusedRootId,
          );
        }
        return componentsWithLayout;
      })
      .value();
  };

  private isDetachedFromParent = (compLayout: Rect) => {
    const currentContainer = attachUtils[this.type].getContainer(
      this.editorAPI,
      this.comp,
    );

    if (_.isEmpty(currentContainer) || this.type === ATTACH_TYPES.STRUCTURE) {
      return true;
    }

    const containerLayout = attachUtils[this.type].getCompLayout(
      this.editorAPI,
      currentContainer,
    );

    return !layoutUtils.doBoxesOverlap(compLayout, containerLayout);
  };

  private getAttachBox = (point: Point): Rect => {
    if (!point) return undefined;
    const defaultBox = {
      x: Math.max(0, point.x),
      y: Math.max(0, point.y),
      width: 1,
      height: 1,
    };

    if (
      this.type === ATTACH_TYPES.STRUCTURE ||
      !util.sections.isSectionsEnabled()
    ) {
      return defaultBox;
    }
    // Since the attachment can happen by the mouse position, we need to apply layout constraints on it

    const [compRef] = util.array.asArray(this.comp);
    const oldLayout =
      this.editorAPI.components.layout.getRelativeToScreen(compRef);

    const editorLayoutConstraintsUtil = new EditorLayoutConstraintsUtil(
      this.editorAPI,
      compRef,
    );
    if (this.editorAPI.components.is.fullWidth(compRef)) {
      defaultBox.x = oldLayout.x;
    }

    editorLayoutConstraintsUtil.applyConstraints(
      this.editorAPI,
      compRef,
      defaultBox,
      oldLayout.bounding,
    );

    defaultBox.height = 1;

    return defaultBox;
  };

  private getFirstValidAttachCandidate = (
    currentCompLayout: Rect,
    isCompDetachedFromParent: boolean,
    attachPosition?: Point,
  ) => {
    const currentContainer = attachUtils[this.type].getContainer(
      this.editorAPI,
      this.comp,
    );

    if (!attachUtils[this.type].canReparent(this.editorAPI, this.comp)) {
      return currentContainer;
    }

    if (!_.has(this, 'attachCandidates') || this.isDragHandler) {
      this.attachCandidates = this.getAttachCandidates(
        this.initialCompWithLayout,
        this.type,
        attachPosition,
      );
    }

    const attachBox = this.getAttachBox(attachPosition);

    const isFocusModeEnabled = this.editorAPI.componentFocusMode.isEnabled();
    const compInFocusMode =
      this.editorAPI.componentFocusMode.getCompRefToAttachChildren();
    if (isFocusModeEnabled) {
      this.attachCandidates = this.attachCandidates.filter(({ comp }) =>
        this.editorAPI.components.isDescendantOfComp(comp, compInFocusMode),
      );
    }

    const attachCandidateWithLayout = this.attachCandidates.find(
      (candidate) => {
        if (
          util.sections.isSectionsEnabled() &&
          this.editorAPI.sections.isSectionLike(candidate.comp) &&
          attachPosition
        ) {
          return layoutUtils.doBoxesOverlap(attachBox, candidate.layout);
        }

        if (this.isDragHandler) {
          if (
            this.editorAPI.utils.isSameRef(candidate.comp, currentContainer)
          ) {
            return layoutUtils.doBoxesOverlap(
              currentCompLayout,
              candidate.layout,
            );
          }

          if (
            util.sections.isSectionsEnabled() &&
            !this.editorAPI.sections.isSectionLike(candidate.comp) &&
            isCompDetachedFromParent &&
            this.type !== ATTACH_TYPES.STRUCTURE &&
            this.editorAPI.components.is.fullWidth(this.comp)
          ) {
            return isCompletelyContained(
              this.editorAPI,
              candidate.comp,
              currentCompLayout,
              candidate.layout,
            );
          }

          return isMostlyContained(currentCompLayout, candidate.layout);
        }

        if (isCompDetachedFromParent) {
          return isCompletelyContained(
            this.editorAPI,
            candidate.comp,
            currentCompLayout,
            candidate.layout,
          );
        }

        return layoutUtils.doBoxesOverlap(currentCompLayout, candidate.layout);
      },
    );

    if (
      attachCandidateWithLayout &&
      this.canDrop(
        { layout: currentCompLayout },
        attachCandidateWithLayout,
        attachPosition,
      )
    ) {
      return attachCandidateWithLayout.comp;
    }

    if (!attachCandidateWithLayout && isFocusModeEnabled) {
      return compInFocusMode;
    }

    if (
      this.scopeRef &&
      !attachCandidateWithLayout &&
      !_.isEmpty(this.attachCandidates)
    ) {
      const candidates = this.attachCandidates.map(
        (candidate) => candidate.comp,
      );
      const currComps = [this.scopeRef];

      while (!_.isEmpty(currComps)) {
        const comp = currComps.shift();
        // TODO: Fix this the next time the file is edited.
        if (
          // eslint-disable-next-line you-dont-need-lodash-underscore/find
          _.find(candidates, (c) => this.editorAPI.utils.isSameRef(c, comp))
        ) {
          return comp;
        }
        currComps.push(
          ...this.editorAPI.components
            .getChildren(comp)
            .filter((c) => this.editorAPI.components.is.container(c)),
        );
      }
    }

    return null;
  };

  private isWithinBounds(attachPosition: Point) {
    if (!attachPosition) return true;
    const [top, bottom] = this.verticalBounds;
    const mouseY = attachPosition.y;
    return mouseY >= top && mouseY <= bottom;
  }

  private canDrop = (
    compWithLayout: { layout: CompLayout },
    possibleContainerRefWithLayout: CompWithLayout,
    attachPosition?: Point,
  ) => {
    const { editorAPI, comp, type } = this;

    const containerLayout = possibleContainerRefWithLayout.layout;
    const potentialContainer = possibleContainerRefWithLayout.comp;

    const attachStrategy = attachUtils[type];

    const compLayout = compWithLayout.layout;
    const currentContainer = attachStrategy.getContainer(editorAPI, comp);

    const isShownOnAllPages = attachStrategy.isShowOnAllPages(editorAPI, comp);
    const isShownOnSomePages = attachStrategy.isShowOnSomePages(
      editorAPI,
      comp,
    );

    if (!this.isWithinBounds(attachPosition)) {
      return false;
    }

    return (
      !(
        _.isEmpty(currentContainer) &&
        this.editorAPI.utils.isSiteStructure(potentialContainer)
      ) &&
      !isDraggingFromSiteStructureToPage(
        this.editorAPI,
        currentContainer,
        potentialContainer,
      ) &&
      (util.sections.isSectionsEnabled() &&
      (isShownOnAllPages || isShownOnSomePages)
        ? true
        : !isDraggingFromPageToSiteStructure(
            this.editorAPI,
            currentContainer,
            potentialContainer,
          )) &&
      (util.sections.isSectionsEnabled() ||
        !isDraggingFromPageToSiteFooter(
          this.editorAPI,
          currentContainer,
          potentialContainer,
        )) &&
      !this.editorAPI.utils.isSameRef(currentContainer, potentialContainer) &&
      ((util.sections.isSectionsEnabled() &&
        this.editorAPI.sections.isSectionLike(potentialContainer)) ||
        this.isDragHandler ||
        isCompletelyContained(
          this.editorAPI,
          possibleContainerRefWithLayout.comp,
          compLayout,
          containerLayout,
        ))
    );
  };

  private isAllowedToUseSectionLikeContainerAsAFallback = ({
    attachPosition,
    currentContainer,
  }: {
    attachPosition?: Point;
    currentContainer: CompRef;
  }) => {
    const { type: attachType, editorAPI, comp } = this;

    if (!util.sections.isSectionsEnabled() || !attachPosition) {
      return false;
    }

    if (
      editorAPI.isMobileEditor() &&
      attachUtils[attachType].isShowOnAllPages(editorAPI, comp)
    ) {
      return false;
    }

    const isExistingComponent = attachType === ATTACH_TYPES.COMP;

    if (!isExistingComponent) return true;

    const currentContainerLayout =
      editorAPI.components.layout.getRelativeToScreen(currentContainer);

    const isDraggingOutsideOfCurrentContainer = !layoutUtils.doBoxesOverlap(
      {
        ...attachPosition,
        height: 1,
        width: 1,
      },
      currentContainerLayout,
    );

    return isDraggingOutsideOfCurrentContainer;
  };

  private isValidAttachFallback = (compRef: CompRef) => {
    return Boolean(
      this.attachCandidates.find(
        (compWithLayout) => compWithLayout.comp.id === compRef.id,
      ),
    );
  };

  private shouldUsePageAsAttachFallback(
    isCompDetachedFromParent: boolean,
    attachPosition: Point,
  ) {
    const { editorAPI, type, comp: currComp } = this;

    if (
      util.sections.isSectionsEnabled() &&
      !editorAPI.dsRead.pages.popupPages.isPopupOpened()
    ) {
      const focusedPage = editorAPI.dsRead.pages.getFocusedPage();

      if (!this.isValidAttachFallback(focusedPage)) return false;
      if (!this.isWithinBounds(attachPosition)) return false;
    }

    const currentContainer = attachUtils[type].getContainer(
      editorAPI,
      currComp,
    );

    if (editorAPI.utils.isPage(currentContainer)) {
      return false;
    }

    if (editorAPI.isMobileEditor()) {
      return (
        isCompDetachedFromParent &&
        !attachUtils[type].isShowOnAllPages(editorAPI, currComp)
      );
    }

    return isCompDetachedFromParent;
  }

  private canUseSectionLikeContainerAsAttachFallback = ({
    attachPosition,
    currentContainer,
  }: {
    attachPosition?: Point;
    currentContainer: CompRef;
  }) => {
    this.fallbackSectionLikeRef = undefined;
    this.fallbackSectionLikeStatus = undefined;

    const isAllowed = this.isAllowedToUseSectionLikeContainerAsAFallback({
      attachPosition,
      currentContainer,
    });

    if (!isAllowed) {
      return false;
    }

    const sectionLike = this.editorAPI.sections.getSectionLikeAtY(
      attachPosition.y,
    );

    if (!sectionLike || !this.isValidAttachFallback(sectionLike)) return false;

    const isSameContainer = this.editorAPI.utils.isSameRef(
      currentContainer,
      sectionLike,
    );

    this.fallbackSectionLikeRef = sectionLike;
    this.fallbackSectionLikeStatus = isSameContainer
      ? ATTACH_CANDIDATE_STATUSES.SAME
      : ATTACH_CANDIDATE_STATUSES.VALID;

    return true;
  };

  public getCurrentAttachCandidate = (
    compLayout?: CompLayout | null,
    excludedCandidates?: CompRef[] | null,
    attachPosition?: Point,
  ): AttachCandidateResponse => {
    const { scrollLeft, scrollTop } = this.editorAPI.scroll.get();
    if (attachPosition) {
      attachPosition = {
        x: attachPosition.x + scrollLeft,
        // because attachPosition is relative to screen, and all logic below works with relative to page
        y: attachPosition.y + scrollTop,
      };
    }
    if (compLayout) {
      compLayout = {
        ...compLayout,
        y: compLayout.y + scrollTop,
        x: compLayout.x + scrollLeft,
      };
    }
    const currentCompLayout =
      this.type === ATTACH_TYPES.COMP && !compLayout
        ? attachUtils[this.type].getCompLayout(this.editorAPI, this.comp)
        : compLayout;

    const isCompDetachedFromParent =
      this.isDetachedFromParent(currentCompLayout);

    const attachCandidate = this.getFirstValidAttachCandidate(
      currentCompLayout,
      isCompDetachedFromParent,
      attachPosition,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const isAttachCandidateExcluded = _.find(
      excludedCandidates,
      _.isEqual.bind(_, attachCandidate),
    );

    const retVal: AttachCandidateResponse = {
      candidateRef: null,
      candidateStatus: ATTACH_CANDIDATE_STATUSES.SAME,
    };

    const currentContainer = attachUtils[this.type].getContainer(
      this.editorAPI,
      this.comp,
    );

    if (this.editorAPI.utils.isSameRef(currentContainer, attachCandidate)) {
      retVal.candidateRef = attachCandidate;
      retVal.candidateStatus = ATTACH_CANDIDATE_STATUSES.SAME;
    } else if (attachCandidate && !isAttachCandidateExcluded) {
      retVal.candidateRef = attachCandidate;
      retVal.candidateStatus = ATTACH_CANDIDATE_STATUSES.VALID;
    } else if (
      this.canUseSectionLikeContainerAsAttachFallback({
        attachPosition,
        currentContainer,
      })
    ) {
      retVal.candidateRef = this.fallbackSectionLikeRef;
      retVal.candidateStatus = this.fallbackSectionLikeStatus;
    } else if (
      this.shouldUsePageAsAttachFallback(
        isCompDetachedFromParent,
        attachPosition,
      )
    ) {
      retVal.candidateRef = this.editorAPI.pages.getFocusedPage();
      retVal.candidateStatus = ATTACH_CANDIDATE_STATUSES.VALID;
    }

    if (
      retVal.candidateRef &&
      !this.editorAPI.components.is.allowedToContainMoreChildren(
        retVal.candidateRef,
      )
    ) {
      retVal.candidateStatus = ATTACH_CANDIDATE_STATUSES.INVALID;
    }

    return retVal;
  };

  public highlightAttachCandidate = (compRef: CompRef) => {
    compRef = replaceToPopupContainer(
      this.editorAPI,
      this.attachCandidates,
      compRef,
    );

    if (
      !util.sections.isSectionsEnabled() &&
      compRef &&
      this.editorAPI.utils.isSiteStructure(compRef)
    ) {
      return;
    }

    this.editorAPI.setAttachCandidate(
      { comp: compRef },
      this.type === ATTACH_TYPES.STRUCTURE,
    );
  };
  public clearHighlightAttachCandidate(dontWaitForViewer: boolean) {
    this.editorAPI.setAttachCandidate({ comp: null }, !!dontWaitForViewer);
  }
}

export { AttachHandler };
