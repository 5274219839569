import { isPopupContainer } from '@/documentServices';
import { isMeshLayoutEnabled } from '@/layoutOneDockMigration';

import { getFixedLayoutItemDockingDirection } from './private/fixedItemLayoutByDirection';
import { getDockingDockingDirection } from './private/dockingByDirection';
import { getPopupContainerPropertiesDockingDirection } from './private/popupContainerPropertiesByDirection';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef, PopupContainerProperties } from 'types/documentServices';
import type { PinDockingDirection } from './types';

export function getPinDockingDirection(
  editorAPI: EditorAPI,
  compRef: CompRef,
): PinDockingDirection | null {
  if (isMeshLayoutEnabled()) {
    const layouts = editorAPI.components.layout.__mesh.get(compRef);

    return getFixedLayoutItemDockingDirection(layouts);
  }

  if (isPopupContainer(editorAPI.dsRead, compRef)) {
    const properties = editorAPI.components.properties.get(
      compRef,
    ) as PopupContainerProperties;

    return getPopupContainerPropertiesDockingDirection(properties);
  }

  // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
  const docking = editorAPI.components.layout.getDock(compRef);

  return docking ? getDockingDockingDirection(docking) : null;
}
