import _ from 'lodash';
import * as widgetActions from './widgetActions';
import * as notificationsActions from '../notifications/notificationsActions';
import * as panels from '../panels/panels';
import * as selectors from './applicationStudioSelectors';
import * as componentsSelectors from '../components/componentsSelectors';
import coreUtilsLib from 'coreUtilsLib';
import type { EditorAPI } from '@/editorAPI';
import type { WidgetPointer } from '../../../types/documentServices';

const panelsActions = panels.actions;
const { isAppWidget } = componentsSelectors;

const PRESET_MAP_NAMES = ['style', 'layout'];

const onImportSuccess = (dispatch: AnyFixMe) => {
  dispatch(
    notificationsActions.showUserActionNotification({
      title: 'import widget',
      message: 'Widget imported!',
      type: 'info',
    }),
  );
};

const exportWidget =
  () =>
  async (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI, dsRead }: AnyFixMe,
  ) => {
    const exportedWidgetsData: AnyFixMe = [];
    const containingWidgetsMap =
      dsRead.appStudio.widgets.getContainingWidgetsMap();
    const rootCompId = dsRead.pages.getCurrentPageId();
    const widgetPointer = dsRead.appStudio.widgets.getByRootCompId(rootCompId);
    await getAllWidgetsData(
      editorAPI,
      widgetPointer,
      containingWidgetsMap,
      exportedWidgetsData,
    );
    const definitions = dsRead.appStudio.definitions.getAllSerialized();

    exportToLocalStorage(dispatch, {
      exportedWidgetsData,
      appData: { definitions },
    });
  };

const getAllWidgetsData = async (
  editorAPI: AnyFixMe,
  widgetPointer: AnyFixMe,
  containingWidgetsMap: AnyFixMe,
  widgetsData: AnyFixMe,
) => {
  const containedWidgets = containingWidgetsMap[widgetPointer.id];
  for (const containedWidgetPointerId of containedWidgets) {
    const containedWidgetPointer = editorAPI.dsRead.appStudio.widgets.get.byId(
      containedWidgetPointerId,
    );
    if (
      !isWidgetSerialized(editorAPI.dsRead, widgetsData, containedWidgetPointer)
    ) {
      await getAllWidgetsData(
        editorAPI,
        containedWidgetPointer,
        containingWidgetsMap,
        widgetsData,
      );
    }
  }

  const widgetData = await getWidgetData(editorAPI, widgetPointer);
  widgetsData.push(widgetData);
};

const isWidgetSerialized = (
  dsRead: AnyFixMe,
  widgetsData: AnyFixMe,
  widgetPointer: AnyFixMe,
) => {
  const appWidget = selectors.getAppWidgetByWidgetPointer(
    dsRead,
    widgetPointer,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(widgetsData, ['serializedRootContainer.id', appWidget.id]);
};

const getAppWidgetFromStructure = (structure: AnyFixMe): AnyFixMe => {
  if (structure.componentType === 'platform.components.AppWidget') {
    return structure;
  }

  return getAppWidgetFromStructure(structure.components[0]);
};

const getWidgetData = async (editorAPI: AnyFixMe, widgetPointer: AnyFixMe) => {
  const { dsRead } = editorAPI;
  const page = getPageFromWidgetPointer(dsRead, widgetPointer);
  // Serialize probably not needed, need to refactor / remove when removing old AppBuilder.
  // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
  const serializedWidgetPage = dsRead.components.serialize(
    page,
    null,
    null,
    true,
  );
  const serializedRootContainer =
    getAppWidgetFromStructure(serializedWidgetPage);
  addDataToOverrides(dsRead, serializedRootContainer);
  const widgetVariations =
    dsRead.appStudio.widgets.variations.getWidgetVariations(widgetPointer);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const variations = _.map(widgetVariations, (variation) => {
    const pageId = dsRead.appStudio.widgets.getRootCompId(variation.pointer);
    const pageRef = dsRead.components.get.byId(pageId);
    // Serialize probably not needed, need to refactor / remove when removing old AppBuilder.
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const serializedVariationPage = editorAPI.components.serialize(
      pageRef,
      null,
      null,
      true,
    );
    addDataToOverrides(dsRead, serializedVariationPage);
    return {
      name: variation.name,
      serializedRootContainer: serializedVariationPage.components[0],
    };
  });

  const presetDescriptors = dsRead.appStudio.widgets.presets
    .getWidgetPresets(widgetPointer)
    .map((preset: AnyFixMe) => ({
      variantId: editorAPI.appStudio.widgets.presets.getPresetVariantId(
        preset.pointer,
      ),

      name: preset.name,
    }));

  const properties =
    dsRead.appStudio.widgets.propertiesSchemas.get(widgetPointer);
  const events = dsRead.appStudio.widgets.events.get(widgetPointer);

  const widgetPageId = dsRead.appStudio.widgets.getRootCompId(widgetPointer);
  const code = await readCode(editorAPI, widgetPageId);
  return {
    serializedRootContainer,
    variations,
    presetDescriptors,
    properties,
    events,
    code,
  };
};
const getPageFromWidgetPointer = (
  dsRead: AnyFixMe,
  widgetPointer: AnyFixMe,
) => {
  const rootCompId = dsRead.appStudio.widgets.getRootCompId(widgetPointer);
  return dsRead.components.get.byId(rootCompId);
};

const importWidget =
  ({ onSuccess }: AnyFixMe = { onSuccess: undefined }) =>
  async (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const { appData, exportedWidgetsData } =
      JSON.parse(window.localStorage.getItem('exportedWidget')) || {};
    if (
      _.isEmpty(exportedWidgetsData) ||
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      _.some(exportedWidgetsData, (widget) => !widget.serializedRootContainer)
    ) {
      return;
    }
    const oldToNewIdsMap = {};
    updateCustomTypeDefinitions(editorAPI, appData.definitions);

    let widgetForDisplay;
    for (const widgetData of exportedWidgetsData) {
      const widgetPointer = await createWidgetAndVariations(
        editorAPI,
        dispatch,
        {
          widgetData,
          oldToNewIdsMap,
          definitions: appData.definitions,
        },
      );
      if (!widgetPointer) {
        return;
      }

      widgetForDisplay = widgetPointer;
    }
    dispatch(widgetActions.displayWidget(widgetForDisplay));
    window.localStorage.removeItem('exportedWidget');

    if (onSuccess) {
      onSuccess();
    } else {
      onImportSuccess(dispatch);
    }
  };

const addDataToOverrides = (dsRead: AnyFixMe, structure: AnyFixMe) => {
  const addData = (comp: AnyFixMe) => {
    const { overriddenData } = comp.custom;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    comp.custom.overriddenData = _.map(overriddenData, (dataItem) => {
      const compId = getID(dataItem.compId);
      const compRef = dsRead.components.get.byId(compId);
      if (compRef && !isAppWidget(compRef, dsRead)) {
        const { controllerRef, role } =
          dsRead.platform.controllers.connections.getPrimaryConnection(compRef);

        dataItem = _.defaults(
          {
            controllerId: controllerRef.id,
            role,
          },
          dataItem,
        );
      }
      return dataItem;
    });
  };

  applyToFirstLayerWidgets(structure, addData);
};

const updateCustomTypeDefinitions = (
  { dsRead, dsActions }: AnyFixMe,
  definitions: AnyFixMe,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(definitions, (def) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const definitionName = _.head(_.keys(def));
    const allDefinitions = dsRead.appStudio.definitions.getAll();
    const defPointer = _.get(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      _.find(allDefinitions, { name: definitionName }),
      'pointer',
    );
    dsActions.appStudio.definitions.set(defPointer, _.cloneDeep(def)); // update if exists, create otherwise
  });
};

const createWidgetAndVariations = async (
  editorAPI: EditorAPI,
  dispatch: AnyFixMe,
  data: AnyFixMe,
) => {
  const { widgetData, definitions, oldToNewIdsMap } = data;
  const {
    properties,
    events,
    code,
    serializedRootContainer,
    variations,
    presetDescriptors,
  } = widgetData;

  let refRoleMap = {};
  const widgetStructure = createWidgetStructure(
    editorAPI.dsRead,
    serializedRootContainer,
    oldToNewIdsMap,
    refRoleMap,
  );
  if (widgetStructure) {
    const widgetPointer = await new Promise<WidgetPointer>((res) => {
      editorAPI.dsActions.appStudio.widgets.create(
        {
          initialWidgetRootStructure: widgetStructure,
          presetDescriptors,
        },
        (widgetPointer: WidgetPointer) => res(widgetPointer),
      );
    });
    const newPresetsVariantIds = editorAPI.appStudio.widgets.presets
      .getWidgetPresets(widgetPointer)
      .map(({ pointer }) =>
        editorAPI.appStudio.widgets.presets.getPresetVariantId(pointer),
      );
    for (let index = 0; index < newPresetsVariantIds.length; index++) {
      oldToNewIdsMap[presetDescriptors[index].variantId] =
        newPresetsVariantIds[index];
    }
    populateOldToNewIdsMap(
      editorAPI.dsRead,
      widgetPointer,
      serializedRootContainer,
      { oldToNewIdsMap, refRoleMap },
    );
    for (const { serializedRootContainer, name } of variations) {
      refRoleMap = {};
      const variationInitialStructure = createWidgetStructure(
        editorAPI.dsRead,
        serializedRootContainer,
        oldToNewIdsMap,
        refRoleMap,
      );
      const variationPointer = await new Promise((res) => {
        dispatch(
          widgetActions.createVariation(
            widgetPointer,
            variationInitialStructure,
            false,
            name,
            res,
          ),
        );
      });
      populateOldToNewIdsMap(
        editorAPI.dsRead,
        variationPointer,
        serializedRootContainer,
        { oldToNewIdsMap, refRoleMap },
      );
    }
    await writeCode(editorAPI, widgetPointer, code);
    editorAPI.dsActions.appStudio.widgets.events.set(widgetPointer, events);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const fixedProperties = _.map(properties, (prop) =>
      fixPropertyDefinitions(editorAPI.dsRead, definitions, prop),
    );
    editorAPI.dsActions.appStudio.widgets.propertiesSchemas.set(
      widgetPointer,
      fixedProperties,
    );
    return widgetPointer;
  }
  dispatch(
    notificationsActions.showUserActionNotification({
      title: 'Widget structure not allowed',
      message: 'Widget structure not allowed',
      type: 'error',
    }),
  );
  return;
};

const convertConnectionItems = (
  componentDefinition: AnyFixMe,
  controllerDataId: AnyFixMe,
  findRoleFn: AnyFixMe,
) => {
  const doConversion = (compDef: AnyFixMe) => {
    if (compDef.connections) {
      const role = findRoleFn(compDef.connections);
      compDef.connections.items = [
        {
          type: 'ConnectionItem',
          role,
          controllerId: controllerDataId,
          isPrimary: true,
        },
      ];
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(compDef.components, (chidCompDef) => {
      doConversion(chidCompDef);
    });
  };

  doConversion(componentDefinition);
};

const populateOldToNewIdsMap = (
  dsRead: AnyFixMe,
  widgetPointer: AnyFixMe,
  serializedComp: AnyFixMe,
  data: AnyFixMe,
) => {
  const { oldToNewIdsMap, refRoleMap } = data;
  oldToNewIdsMap[serializedComp.id] = selectors.getAppWidgetByWidgetPointer(
    dsRead,
    widgetPointer,
  ).id;
  const innerWidgets =
    dsRead.appStudio.widgets.getFirstLevelInnerWidgets(widgetPointer);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(innerWidgets, (refCompPointer) => {
    // Serialize probably not needed, need to refactor / remove when removing old AppBuilder code.
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const serializedWidget = dsRead.components.serialize(refCompPointer);
    const role = getRefRole(serializedWidget);
    const oldId = refRoleMap[role];
    oldToNewIdsMap[oldId] = refCompPointer.id;
  });
};

const readCode = (editorAPI: AnyFixMe, widgetPageId: AnyFixMe) => {
  const pagesFolder = editorAPI.wixCode.fileSystem.getRoots().pages;
  return editorAPI.wixCode.fileSystem
    .getChildren(pagesFolder)
    .then((_pages: AnyFixMe) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const currentFile = _.find(_pages, ['name', `${widgetPageId}.js`]);
      return editorAPI.wixCode.fileSystem.readFile(currentFile);
    });
};

const writeCode = (
  editorAPI: AnyFixMe,
  widgetPointer: AnyFixMe,
  code: AnyFixMe,
) => {
  const widgetPage = getPageFromWidgetPointer(editorAPI.dsRead, widgetPointer);
  const fileId = editorAPI.wixCode.fileSystem.getFileIdFromPageId(
    widgetPage.id,
  );
  const fileDescriptor = editorAPI.wixCode.fileSystem.getVirtualDescriptor(
    fileId,
    false,
  );

  return editorAPI.wixCode.fileSystem.writeFile(fileDescriptor, code);
};

const fixPropertyDefinitions = (
  dsRead: AnyFixMe,
  defs: AnyFixMe,
  property: AnyFixMe,
) => {
  const map = createIdMap(dsRead, defs);
  let propertyStr = JSON.stringify(property);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(map, (value, key) => {
    propertyStr = propertyStr.replace(`"$ref":"${key}"`, `"$ref":"${value}"`);
  });
  return JSON.parse(propertyStr);
};

const createIdMap = (dsRead: AnyFixMe, defs: AnyFixMe) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const pairs = _.map(defs, (d) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const definitionName = _.head(_.keys(d));
    const oldId = d[definitionName].$id;
    const newDefinitionPointer = _.get(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      _.find(dsRead.appStudio.definitions.getAll(), { name: definitionName }),
      'pointer',
    );
    const newId = newDefinitionPointer
      ? dsRead.appStudio.definitions.get(newDefinitionPointer)[definitionName]
          .$id
      : null;
    return newId ? [oldId, newId] : null;
  });

  return _.fromPairs(_.compact(pairs));
};

const findRole = (connections: AnyFixMe) => connections.items[0].role;

const createWidgetStructure = (
  dsRead: AnyFixMe,
  initialStructure: AnyFixMe,
  oldToNewIdsMap: AnyFixMe,
  refRoleMap: AnyFixMe,
) => {
  initialStructure = initialStructure.components[0];
  const fakeDataId = 'dataItem-placeholder';
  convertConnectionItems(initialStructure, fakeDataId, findRole);
  fixInnerWidgets(
    dsRead,
    initialStructure,
    oldToNewIdsMap,
    refRoleMap,
    fakeDataId,
  );
  return initialStructure;
};

const fixPresetIds = (comp: AnyFixMe, oldToNewIdsMap: AnyFixMe) => {
  PRESET_MAP_NAMES.forEach((presetMap) => {
    const oldPresetMapId = comp.presets[presetMap];
    comp.presets[presetMap] = oldToNewIdsMap[oldPresetMapId];
  });
};

const fixInnerWidgets = (
  dsRead: AnyFixMe,
  structure: AnyFixMe,
  oldToNewIdsMap: AnyFixMe,
  refRoleMap: AnyFixMe,
  fakeDataId: AnyFixMe,
) => {
  const fixInnerWidget = (comp: AnyFixMe) => {
    fixData(dsRead, comp, oldToNewIdsMap);
    fixOverrides(dsRead, comp, oldToNewIdsMap, fakeDataId);
    fixPresetIds(comp, oldToNewIdsMap);
    const role = getRefRole(comp);
    refRoleMap[role] = comp.id;
  };
  applyToFirstLayerWidgets(structure, fixInnerWidget);
};

const fixData = (
  dsRead: AnyFixMe,
  comp: AnyFixMe,
  oldToNewIdsMap: AnyFixMe,
) => {
  const { rootCompId } = comp.data;
  const newRootID = oldToNewIdsMap[rootCompId];
  const newRootRef = dsRead.components.get.byId(newRootID);
  const page = dsRead.components.getPage(newRootRef);
  comp.data = _.defaults({ rootCompId: newRootID, pageId: page.id }, comp.data);
};

const fixOverrides = (
  dsRead: AnyFixMe,
  comp: AnyFixMe,
  oldToNewIdsMap: AnyFixMe,
  controllerDataId: AnyFixMe,
) => {
  const { overriddenData } = comp.custom;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  comp.custom.overriddenData = _.map(overriddenData, (override) => {
    const { itemType, compId, dataItem } = override;
    const oldOverrideCompId = getID(compId);

    let newCompRef = dsRead.components.get.byId(
      oldToNewIdsMap[oldOverrideCompId],
    );
    if (!newCompRef) {
      newCompRef = getNewIdByRole(dsRead, oldToNewIdsMap, override);
    }
    if (!newCompRef) {
      return override;
    }

    if (itemType === 'connections') {
      fixConnectionOverride(override, controllerDataId);
    }

    if (dataItem.type === 'AppController' && dataItem.controllerType) {
      fixAppControllerOverride(dsRead, override, newCompRef);
    }

    const newCompId = getRepeatedIdIfNeeded(compId, newCompRef.id);
    oldToNewIdsMap[oldOverrideCompId] = newCompId;
    const newInflatedCompId = calcNewOverrideCompId(compId, oldToNewIdsMap);

    return _(override)
      .omit(override, ['controllerId', 'role'])
      .assign({ compId: newInflatedCompId })
      .value();
  });
};

const getNewIdByRole = (
  dsRead: AnyFixMe,
  oldToNewIdsMap: AnyFixMe,
  { controllerId, role }: AnyFixMe,
) => {
  const newAppWidgetId = oldToNewIdsMap[controllerId];
  const newAppWidgetRef = dsRead.components.get.byId(newAppWidgetId);
  const connections =
    dsRead.platform.controllers.connections.getControllerConnections(
      newAppWidgetRef,
    );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(connections, ['connection.role', role])?.componentRef;
};

const fixAppControllerOverride = (
  dsRead: AnyFixMe,
  override: AnyFixMe,
  newCompRef: AnyFixMe,
) => {
  const { type } = dsRead.platform.controllers.settings.get(newCompRef);
  override.dataItem.controllerType = type;
  if (override.dataItem.settings) {
    const settings = JSON.parse(override.dataItem.settings);
    settings.type = type;
    override.dataItem.settings = JSON.stringify(settings);
  }
};

const fixConnectionOverride = (
  override: AnyFixMe,
  controllerDataId: AnyFixMe,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const { role } = _.find(override.dataItem.items, { isPrimary: true });
  override.dataItem.items = [
    {
      type: 'ConnectionItem',
      role,
      controllerId: controllerDataId,
      isPrimary: true,
    },
  ];
};

const getRepeatedIdIfNeeded = (oldCompId: AnyFixMe, newCompId: AnyFixMe) => {
  const isRepeated =
    coreUtilsLib.displayedOnlyStructureUtil.isRepeatedComponent(oldCompId);

  const itemId =
    isRepeated &&
    coreUtilsLib.displayedOnlyStructureUtil.getRepeaterItemId(oldCompId);
  return isRepeated
    ? coreUtilsLib.displayedOnlyStructureUtil.getUniqueDisplayedId(
        newCompId,
        itemId,
      )
    : newCompId;
};

const calcNewOverrideCompId = (compId: AnyFixMe, oldToNewIdsMap: AnyFixMe) => {
  const refSeparator = '_r_';
  return compId
    .split(refSeparator)
    .map((oldId: AnyFixMe) => oldToNewIdsMap[oldId])
    .join(refSeparator);
};

const getRefRole = (comp: AnyFixMe) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const primaryConnectionOverride = _.find(
    comp.custom.overriddenData,
    (override) => {
      if (override.itemType !== 'connections') {
        return false;
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      return _.some(override.dataItem.items, { isPrimary: true });
    },
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(primaryConnectionOverride.dataItem.items, { isPrimary: true })
    .role;
};

const getID = (curCompId: AnyFixMe) => {
  let prevCompId = curCompId;
  let cur = curCompId;
  while (cur) {
    prevCompId = cur;
    cur = coreUtilsLib.displayedOnlyStructureUtil.getReferredCompId(cur);
  }
  return prevCompId;
};
const applyToFirstLayerWidgets = (structure: AnyFixMe, method: AnyFixMe) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  structure.components = _.map(structure.components, (comp) => {
    if (isRefComp(comp)) {
      method(comp);
      return comp;
    }
    applyToFirstLayerWidgets(comp, method);
    return comp;
  });
};

const isRefComp = (comp: AnyFixMe) =>
  comp.componentType === 'wysiwyg.viewer.components.RefComponent';
const exportToLocalStorage = (
  dispatch: AnyFixMe,
  exportedWidgetData: AnyFixMe,
) => {
  try {
    window.localStorage.setItem(
      'exportedWidget',
      JSON.stringify(exportedWidgetData),
    );
  } catch (e) {
    dispatch(
      panelsActions.updateOrOpenPanel(
        'savePublish.panels.common.failPanel',
        {
          onConfirm: () => {
            window.localStorage.clear();
            exportToLocalStorage(dispatch, exportedWidgetData);
          },
          titleKey: 'Fail to setItem',
          description:
            'Local storage is out of space. If the problem persists this widget might be too large. Clear the storage and export the widget?',
          confirmLabel: 'Clear and Export',
          cancelLabel: 'Cancel',
        },
        true,
      ),
    );
  }
};

export { exportWidget, importWidget };
