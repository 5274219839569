// @ts-nocheck
import _ from 'lodash';
import * as components from '../components/components';

const createNewScrubBehavior = (
  behaviorDefinition,
  compRef,
  animationName,
  viewMode,
) => {
  const targetId = compRef.id;
  const action = 'scrollScrub';

  const behavior = _(behaviorDefinition)
    .pick(['name', 'type', 'params'])
    .assign({ targetId, action, viewMode })
    .value();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  if (animationName && _.isArray(behavior?.params?.animations)) {
    behavior.params.animations.push({ name: animationName });
  }

  return behavior;
};

const setHeaderScrollBehavior =
  (option, compRef, compBehaviors = [], viewMode) =>
  (dispatch, getState, { editorAPI }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const currentScrubBehavior = _.find(compBehaviors, {
      type: 'scrub',
      viewMode,
    });

    if (option.behaviorName) {
      const behaviorDefinition = editorAPI.behaviors.getDefinition(
        option.behaviorName,
      );
      const newScrubBehavior = createNewScrubBehavior(
        behaviorDefinition,
        compRef,
        option.animationName,
        viewMode,
      );
      editorAPI.components.behaviors.update(
        compRef,
        newScrubBehavior,
        newScrubBehavior.action,
        newScrubBehavior.viewMode,
      );
    } else if (currentScrubBehavior) {
      editorAPI.components.behaviors.remove(compRef, currentScrubBehavior);
    }

    dispatch(
      components.actions.updateFixedPosition(compRef, !!option.fixedPosition),
    );
  };

export { setHeaderScrollBehavior };
