import { RelatedAppsActionTypes } from './relatedAppsActionTypes';
import type { Reducer } from 'types/redux';

type RelatedAppsState = AnyFixMe;

export const relatedAppsInitialState: RelatedAppsState = {
  isLoading: false,
  value: [],
};

const reducer: Reducer<RelatedAppsState> = (
  relatedApps = relatedAppsInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case RelatedAppsActionTypes.StartRelatedAppsLoading:
      return { value: relatedApps.value, isLoading: true };

    case RelatedAppsActionTypes.SetRelatedApps:
      return { value: action.payload, isLoading: false };

    default:
      return relatedApps;
  }
};

export default reducer;
