const UPDATE_COMPONENT_TOPIC = 'UPDATE_COMPONENT_TOPIC';
const RESET_COMPONENT_STORE = 'RESET_COMPONENT_STORE';

export interface UpdateComponentTopicType {
  type: typeof UPDATE_COMPONENT_TOPIC;
  payload: { compId: string; topicId: string };
}

export interface ResetComponentStoreType {
  type: typeof RESET_COMPONENT_STORE;
}

export type SmartWriterActionsType =
  | UpdateComponentTopicType
  | ResetComponentStoreType;

export const smartWriterActionTypes = {
  UPDATE_COMPONENT_TOPIC,
  RESET_COMPONENT_STORE,
} as const;
