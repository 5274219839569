import _ from 'lodash';
import type { CompRef } from 'types/documentServices';

export function createLayoutGetApiCache() {
  const memoizedFns = new Set<_.MemoizedFunction>();

  const memoize = <
    T extends
      | ((compRef: CompRef) => any)
      | ((compRefOrRefs: CompRef | CompRef[]) => any),
  >(
    fn: T,
  ): T & _.MemoizedFunction => {
    const fnMemoized = _.memoize(fn, (compRefOrRefs) =>
      (Array.isArray(compRefOrRefs) ? compRefOrRefs : [compRefOrRefs])
        .map(({ id, type }) => `${id}|${type}`)
        .join(';'),
    );

    memoizedFns.add(fnMemoized);

    return fnMemoized;
  };

  function clearCache() {
    memoizedFns.forEach((fn) => {
      fn.cache.clear();
    });
  }

  return {
    memoize,
    clearCache,
  };
}
