import * as domainSuggestionsActionTypes from './domainSuggestionsActionTypes';
import * as previewActionTypes from '../preview/previewActionTypes';

const {
  SET_DOMAIN_SUGGESTIONS,
  TURN_OFF_DOMAIN_SUGGESTIONS,
  TURN_ON_DOMAIN_SUGGESTIONS,
} = domainSuggestionsActionTypes;

export interface DomainSuggestionsState {
  isDomainSuggestionsTurnedOff: boolean;
  suggestions: string[];
}

export const domainSuggestionsInitialState: DomainSuggestionsState = {
  suggestions: [],
  isDomainSuggestionsTurnedOff: false,
};

const reducer = (
  domainSuggestionsState: DomainSuggestionsState = domainSuggestionsInitialState,
  action: AnyFixMe,
): DomainSuggestionsState => {
  switch (action.type) {
    case SET_DOMAIN_SUGGESTIONS:
      return {
        ...domainSuggestionsState,
        suggestions: action.domainSuggestions,
      };
    case previewActionTypes.SET_PREVIEW_MODE:
    case TURN_ON_DOMAIN_SUGGESTIONS:
      return {
        ...domainSuggestionsState,
        isDomainSuggestionsTurnedOff: false,
      };
    case TURN_OFF_DOMAIN_SUGGESTIONS:
      return {
        ...domainSuggestionsState,
        isDomainSuggestionsTurnedOff: true,
      };
    default:
      return domainSuggestionsState;
  }
};

export default reducer;
