import constants from '@/constants';
import { FixedStageApiKey } from '@/apis';
import { EditorLayoutConstraintsUtil } from '@/layoutUtils';
import { componentPartiallyOutOfStage } from '@wix/bi-logger-editor/v2';
import { biLogger, sections } from '@/util';
import * as stateManagement from '@/stateManagement';
import { isPopupContainer } from '@/documentServices';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

import type { LayoutGetApi } from '../layoutGetApi';
import type { LayoutPinApi } from '../layoutFixedPositionApi/createLayoutPinApi';
import type { LayoutRotationApi } from '../layoutRotationApi/createLayoutRotationApi';
import type { LayoutContentAreaApi } from './createLayoutContentAreaApi';

const DESKTOP_PAGE_WIDTH = 980;
const MOBILE_PAGE_WIDTH = 320;

export function createLayoutContentAreaOutOfGridApi({
  editorAPI,
  layoutContentAreaApi,
  layoutGetApi,
  layoutRotationApi,
  layoutPinApi,
}: {
  editorAPI: EditorAPI;
  layoutContentAreaApi: LayoutContentAreaApi;
  layoutGetApi: LayoutGetApi;
  layoutRotationApi: LayoutRotationApi;
  layoutPinApi: LayoutPinApi;
}) {
  function shouldShowOutOfContainerGridlinesWarning(
    compRef: CompRef,
    containerRef: CompRef,
    percentOfCompAllowedOut: number,
  ): boolean {
    if (!containerRef) {
      return false;
    }

    return editorAPI.components.someAncestor(
      compRef,
      (ancestor: CompRef) => {
        // order matters for performance. It is lazy evaluated, cheaper and most frequent are on the top
        if (
          editorAPI.components.is.page(ancestor) ||
          editorAPI.columns.isStrip(ancestor) ||
          !editorAPI.components.is.horizontallyResizable(compRef) || //most frequent to exit
          layoutPinApi.isPinned(compRef) ||
          editorAPI.components.is.fullWidth(compRef) ||
          editorAPI.columns.isColumn(compRef) ||
          layoutRotationApi.isRotated(compRef) ||
          editorAPI.components.getType(ancestor) ===
            constants.COMP_TYPES.CONTAINER || // isAncestorContainerComp
          (!editorAPI.sections.isHeaderOrFooter(containerRef) &&
            editorAPI.sections.isHeaderOrFooter(ancestor)) || // isIndirectChildOfHeaderOrFooter
          isPopupContainer(editorAPI.dsRead, ancestor)
        ) {
          return false;
        }

        const containerWithGridlines =
          editorAPI.components.is.showMarginsIndicator(containerRef)
            ? containerRef
            : ancestor;
        const isIndirectChildOfMultiColumnStrip =
          !editorAPI.columns.isMultiColumnsStrip(containerRef) &&
          editorAPI.columns.isMultiColumnsStrip(containerWithGridlines);

        if (isIndirectChildOfMultiColumnStrip) {
          return false;
        }

        return layoutContentAreaApi.isCompExceedingContainerMargin(
          compRef,
          containerWithGridlines,
          percentOfCompAllowedOut,
        );
      },
      { includeScopeOwner: true },
    );
  }

  function isCompOutOfPageBounds(
    compRef: CompRef | CompRef[],
    percentOfCompAllowedOut: number,
  ): boolean {
    const isMobileEditor = editorAPI.isMobileEditor();

    const pageWidth = isMobileEditor ? MOBILE_PAGE_WIDTH : DESKTOP_PAGE_WIDTH;
    const compRelativeToPage = layoutGetApi.getRelativeToStructure(compRef);
    const pixelsAllowedOut =
      (compRelativeToPage.width * percentOfCompAllowedOut) / 100;
    const isExceedingPageOnTheLeft =
      compRelativeToPage.x + pixelsAllowedOut < 0;
    const isExceedingPageOnTheRight =
      compRelativeToPage.x + compRelativeToPage.width - pixelsAllowedOut >
      pageWidth;

    return isExceedingPageOnTheLeft || isExceedingPageOnTheRight;
  }

  function shouldShowOutOfPageGridlinesWarning(
    compRefOrRefs: CompRef | CompRef[],
    containerRef: CompRef,
    percentOfCompAllowedOut: number,
  ): boolean {
    const doesContainerPreventWarningFuncs = [
      layoutPinApi.isPinned,
      editorAPI.components.is.repeaterItem,
      (container: CompRef) =>
        !editorAPI.components.is.page(container) &&
        !editorAPI.components.is.horizontallyResizable(container),
    ];
    // order matters for performance reasons
    return (
      editorAPI.components.is.horizontallyResizable(compRefOrRefs) &&
      !layoutPinApi.isPinned(compRefOrRefs) &&
      isCompOutOfPageBounds(compRefOrRefs, percentOfCompAllowedOut) &&
      !doesContainerPreventWarningFuncs.some(
        (func) =>
          func(containerRef) ||
          editorAPI.components.someAncestor(containerRef, func, {
            includeScopeOwner: true,
          }),
      )
    );
  }

  function shouldShowOutOfGridlinesIndication(compPointer: CompRef): boolean {
    const container = editorAPI.components.getContainer(compPointer);
    const percentOfCompAllowedOut = 10;

    return (
      shouldShowOutOfContainerGridlinesWarning(
        compPointer,
        container,
        percentOfCompAllowedOut,
      ) ||
      shouldShowOutOfPageGridlinesWarning(
        compPointer,
        container,
        percentOfCompAllowedOut,
      )
    );
  }

  function reportIfComponentOutOfStage(compRef: CompRef): void {
    const stageIsFixedInDesktop = editorAPI.host
      .getAPI(FixedStageApiKey)
      .isStageFixedInDesktop();

    if (!stageIsFixedInDesktop) {
      return;
    }
    const { width, x } = layoutGetApi.getRelativeToScreen(compRef);
    const componentIsPartiallyOutOfStage =
      x < 0 || x + width > editorAPI.site.getWidth();

    if (!componentIsPartiallyOutOfStage) {
      return;
    }

    const compBoundaries = new EditorLayoutConstraintsUtil(
      editorAPI,
      compRef,
    ).getDesktopBoundaries(editorAPI, compRef);

    const componentReachedItsLeftBoundary = x <= compBoundaries.left.compLeftX;
    const componentReachedItsRightBoundary =
      x + width >= compBoundaries.right.compRightX;

    const defaultParams = editorAPI.bi.getComponentsBIParams([compRef])[0];

    const biParams: { [key: string]: any } = {
      ...defaultParams,
      type:
        componentReachedItsLeftBoundary || componentReachedItsRightBoundary
          ? 'minimum_left'
          : 'partially_placed',
    };
    biLogger.report(componentPartiallyOutOfStage(biParams));
  }

  function openCompOutOfGridPanelIfNeeded(
    compRefOrRefs: CompRef | CompRef[],
  ): void {
    const ancestorWithMarginIndicator = editorAPI.components.findAncestor(
      compRefOrRefs,
      editorAPI.components.is.showMarginsIndicator,
      { includeScopeOwner: true },
    );

    const container =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
        compRefOrRefs,
      );
    const isSameContainer =
      stateManagement.attachCandidate.selectors.isDraggingToSameParentComponent(
        editorAPI.store.getState(),
      );

    const shouldShowIndicationByContainer = () => {
      const isExceedingContainerMargin =
        ancestorWithMarginIndicator &&
        layoutContentAreaApi.isCompExceedingContainerMargin(
          compRefOrRefs,
          ancestorWithMarginIndicator,
          10,
        );

      if (!isExceedingContainerMargin) return false;

      if (
        sections.isSectionsEnabled() &&
        editorAPI.components.is.fullWidth(compRefOrRefs)
      ) {
        if (editorAPI.sections.isSectionLike(container)) {
          return false;
        }
      }

      return true;
    };

    if (shouldShowIndicationByContainer()) {
      const shouldOpenOutOfContainerGrid = true;
      editorAPI.panelHelpers.handleOutOfGrid({}, shouldOpenOutOfContainerGrid);
      editorAPI.bi.event(
        coreBi.events.columns.COLUMNS_ELEMENTS_PLACED_IN_DEAD_SPOT,
        {
          is_same_parent_component: isSameContainer,
        },
      );
    } else if (
      !ancestorWithMarginIndicator &&
      shouldShowOutOfPageGridlinesWarning(compRefOrRefs, container, 50)
    ) {
      editorAPI.bi.event(
        coreBi.events.columns.COLUMNS_ELEMENTS_PLACED_IN_DEAD_SPOT,
        {
          is_same_parent_component: isSameContainer,
        },
      );

      editorAPI.panelHelpers.handleOutOfGrid();
    }
    reportIfComponentOutOfStage(
      Array.isArray(compRefOrRefs) ? compRefOrRefs[0] : compRefOrRefs,
    );
  }

  return {
    shouldShowOutOfPageGridlinesWarning,
    shouldShowOutOfGridlinesIndication,
    openCompOutOfGridPanelIfNeeded,
  };
}
