import { sectionsOnStageActionTypes } from './sectionsOnStage.actionTypes';

import type { Section } from '@/sectionsOnStage';

const setSections = (sections: Section[]) => ({
  type: sectionsOnStageActionTypes.SET_SECTIONS,
  sections,
});

const setHoveredSectionIndex = (hoveredSectionIndex: number) => ({
  type: sectionsOnStageActionTypes.SET_HOVERED_SECTION_INDEX,
  hoveredSectionIndex,
});

export const sectionsOnStageActions = {
  setSections,
  setHoveredSectionIndex,
};
