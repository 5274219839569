import * as mobileKeyboardTypes from './mobileKeyboardTypes';

export const mobileKeyboardInitialState = {
  lastKeyboardContext: null as AnyFixMe,
  isCollapsingMenuFromKeyboard: false as boolean,
};

export interface MobileKeyboardState {
  lastKeyboardContext: AnyFixMe;
  isCollapsingMenuFromKeyboard: boolean;
}

const { SET_LAST_KEYBOARD_CONTEXT, SET_IS_COLLAPSING_MENU_FROM_KEYBOARD } =
  mobileKeyboardTypes;

export default (
  mobileKeyboardState = mobileKeyboardInitialState,
  action: any,
) => {
  switch (action.type) {
    case SET_LAST_KEYBOARD_CONTEXT:
      return Object.assign({}, mobileKeyboardState, {
        lastKeyboardContext: action.lastKeyboardContext,
      });
    case SET_IS_COLLAPSING_MENU_FROM_KEYBOARD:
      return Object.assign({}, mobileKeyboardState, {
        isCollapsingMenuFromKeyboard: action.isCollapsingMenuFromKeyboard,
      });
    default:
      return mobileKeyboardState;
  }
};
