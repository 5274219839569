import _ from 'lodash';
import { ADD_METADATA_OVERRIDE } from './componentMetaDataActionTypes';

export const componentMetaDataInitialState = {};

const addMetaDataOverride = (
  store: AnyFixMe,
  { compType, key, value }: AnyFixMe,
) =>
  _.defaultsDeep(
    {
      overrides: {
        [compType]: {
          [key]: value,
        },
      },
    },
    store,
  );

export default (store = componentMetaDataInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case ADD_METADATA_OVERRIDE:
      return addMetaDataOverride(store, action);
    default:
      return store;
  }
};
