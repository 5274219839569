export default {
  LIST: '8b0944a1-dbfb-4a02-a46a-33676c076fbb',
  APP_MARKET: 'b41d7063-d3d2-4e57-bd3c-4339f1124e4e',
  ORGANIZE_IMAGES: 'afc9efce-faab-42ec-87aa-e5dafe3b29fe',
  SEARCH_ITUNES: '47fafa52-4dc7-4b22-8d81-73b3ca493e30',
  SEARCH_VIDEO: 'ed1260de-cce4-4815-8162-ae3ae8548001',
  SEARCH_SPOTIFY: '74ce7388-15d7-468e-984f-4d15a2257005',
  BOX_SLIDE_SHOW_MANAGE_SLIDES: '2274f7eb-6fc8-4e1d-b2c2-ea511861b0a9',
  BOX_SLIDE_SHOW_BG_APPLY: '936a1e51-ece7-478b-b522-ec24fe6aac72',
  STRIP_CONTAINER_SLIDE_SHOW_BG_APPLY: '936a1e51-ece7-478b-b522-ec24fe6aac72',
  PAGES_PANEL: '280e7ca3-cf44-452f-a2a6-6709aa42dcb6',
  POPUPS_PANEL: 'd608908c-d714-436c-b7bd-56e0b0accd30',
  MOBILE_POPUPS_PANEL: '0ccc8adb-d36f-4730-b68a-77d3d06afcb5',
  POPUP_SETTINGS_LEARN_HOW_LINK: 'd7502fa9-3c10-44e8-bea7-239db92f1037',
  QUICK_ACTION_BAR_MANAGE: 'bb0cc522-839e-4049-a139-283803a8d9df',
  ACCESSIBILITY_PANEL: '674d7454-b62d-4d3a-8a53-ff75b308ad3a',
  MOBILE_FRIENDLY: 'e43e779e-6121-46f1-aabd-63cf5c00f5de',
  VIDEOBOX_BEHAVIORS_PANEL: '52efedf4-c50f-43f0-92d7-7d6d802c2f8b',
  VIDEOBOX_ALPHA_BEHAVIORS_PANEL: '93645f7b-5b9e-493b-b6ed-cdce39f2802d',
  VIDEOBOX_MASK_PANEL: 'Bfc5d5cd-da41-42d4-a273-a29d3a8ea9e3',
  IMAGEX_MASK_PANEL: '624663c5-4bc1-48d3-ac93-b317178474e7',
  IMAGE_FILTERS_PANEL: '59e18f35-fb67-4ad1-8603-6fbff068b58b',
  SHAPE_DIVIDERS_PANEL: '11b37451-3aec-4955-bffb-113100af9002',
  SHAPE_DIVIDERS_PANEL_RESPONSIVE: 'a8628d8a-c22e-426d-aed9-dbf82d0e39bb',
  SHAPE_DIVIDERS_CUSTOM_PANEL: '1b0cad8b-582c-4f23-97ea-cd7984ae0ead',
  SHAPE_DIVIDERS_CUSTOM_PANEL_RESPONSIVE:
    'b6cc5ecb-a3f9-4238-bbb1-36848d54ddef',
  SHAPE_DIVIDERS_MOBILE_PANEL: '6029287f-8360-4868-ab9f-2f38a33978ff',
  REPEATER_MANAGE_ITEMS: '9cadcbce-4fb3-4798-ab43-fc2e08206d06',
  COPYING_AND_PASTING_AN_ELEMENT_TO_ANOTHER_WEBSITE:
    '0a152e88-9f1f-4b45-829e-770dcec276f3',
  STATE_BOX_MANAGE_STATES: '8ac04474-f897-4424-9438-f2564b2b2e50',
  STATE_BOX_BG_APPLY: 'e3fa34ed-4a10-4b1d-8879-83c50046bf4b',
  CUSTOM_ERROR_PAGE: '88346de9-0258-4cf8-badb-c22d92fdf4a1',
};
