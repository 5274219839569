import _ from 'lodash';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import * as leftBar from '../leftBar/leftBar';
import * as bi from '../bi/bi';
import * as preview from '../preview/preview';
import * as selectors from './rulersSelectors';
import * as actionTypes from './rulersActionTypes';
import * as userPreferences from '../userPreferences/userPreferences';
import { rulersInitialState } from './rulersReducer';

const { getPreviewMode } = preview.selectors;
const userPreferencesActions = userPreferences.actions;
const { getDeviceType, isMobileEditor } = leftBar.selectors;

const {
  RESET_HISTORY,
  UNDO,
  REDO,
  UPDATE_HISTORY,
  UPDATE_STATE,
  SET_RULERS_STATE,
} = actionTypes;

const resetHistory = () => ({ type: RESET_HISTORY });

const undoAction = () => ({ type: UNDO });

const redoAction = () => ({ type: REDO });

const updateHistory = () => ({ type: UPDATE_HISTORY });

const updateState = (state: AnyFixMe) => ({
  type: UPDATE_STATE,
  state,
});

const toggleRulers =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const isEnabled = !selectors.isRulersEnabled(getState());
    editorAPI.updateEditorViewTools('rulersEnabled', isEnabled);
    const promise = dispatch(
      userPreferencesActions.setSiteUserPreferences(
        constants.USER_PREFS.VIEW_TOOLS.RULERS_ENABLED,
        isEnabled,
      ),
    );
    dispatch(resetHistory());
    return promise;
  };

const sendBIEvent =
  (BIEventName: AnyFixMe, fields: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const BIEvent =
      coreBi.events.rulers[BIEventName as keyof typeof coreBi.events.rulers];
    const BIData = {
      editor_view_mode: getDeviceType(getState()),
    };
    _.merge(BIData, fields);
    dispatch(bi.actions.event(BIEvent, BIData));
  };

const undo = () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
  const canUndo = selectors.canUndo(getState());
  if (canUndo) {
    dispatch(deselectGuide());
    dispatch(undoAction());

    const historyState = selectors.getHistoryState(getState());
    dispatch(updateGuides(historyState));
  }
};

const redo = () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
  const canRedo = selectors.canRedo(getState());
  if (canRedo) {
    dispatch(deselectGuide());
    dispatch(redoAction());

    const historyState = selectors.getHistoryState(getState());
    dispatch(updateGuides(historyState));
  }
};

const updateRulersAndHistory =
  (newRulersState: AnyFixMe) => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const { guidesState } = newRulersState;
    dispatch(
      userPreferencesActions.setSiteUserPreferences(
        constants.USER_PREFS.VIEW_TOOLS.GUIDES_STATE,
        guidesState,
      ),
    );
    dispatch(updateState(newRulersState));

    const historyState = selectors.getHistoryState(getState());

    if (!_.isEqual(historyState, newRulersState.guidesState)) {
      dispatch(updateHistory());
    }
  };

const getUpdatedRulersState = (
  key: AnyFixMe,
  value: AnyFixMe,
  rulers: AnyFixMe,
) => _.defaults({ [key]: value }, rulers);

const createNewGuideObj = (position: AnyFixMe) => ({
  guideId: `GUIDE_${_.uniqueId(Date.now().toString(36))}`,
  guidePosition: position,
});

const setGuide =
  (path: AnyFixMe, position: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const guidesState = _.cloneDeep(selectors.getGuidesState(getState()));
    const newGuideObj = createNewGuideObj(position);
    _.get(guidesState, path).push(newGuideObj);
    const updatedRulersState = getUpdatedRulersState(
      'guidesState',
      guidesState,
      getState().rulers,
    );
    editorAPI.selection.deselectComponents();
    updatedRulersState.selectedGuide = newGuideObj.guideId;
    util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.RULERS);
    dispatch(updateRulersAndHistory(updatedRulersState));
    return newGuideObj.guideId;
  };

const getPath = (rulerDirection: AnyFixMe, mobileEditor: AnyFixMe) =>
  `${mobileEditor ? 'mobile.' : 'desktop.'}${rulerDirection}Guides`;

const setVerticalGuide =
  (xPosition: AnyFixMe) => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const path = getPath('vertical', isMobileEditor(getState()));
    return dispatch(setGuide(path, xPosition));
  };

const setHorizontalGuide =
  (yPosition: AnyFixMe) => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const path = getPath('horizontal', isMobileEditor(getState()));
    return dispatch(setGuide(path, yPosition));
  };

const removeGuideFromPath = (
  guideState: AnyFixMe,
  location: AnyFixMe,
  guideId: AnyFixMe,
) => {
  const guideStateWithoutGuide = _.cloneDeep(guideState);
  const newGuidesInLocation = _.reject(
    _.get(guideStateWithoutGuide, location),
    { guideId },
  );
  _.set(guideStateWithoutGuide, location, newGuidesInLocation);
  return guideStateWithoutGuide;
};

const changeGuidePosition =
  (rulersDirection: AnyFixMe, guideId: AnyFixMe, newPosition: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const state = getState();
    const guidePath = getPath(rulersDirection, isMobileEditor(state));
    const newGuidesState = removeGuideFromPath(
      selectors.getGuidesState(state),
      guidePath,
      guideId,
    );

    const newGuideObj = {
      guidePosition: newPosition,
      guideId,
    };
    _.get(newGuidesState, guidePath).push(newGuideObj);
    const updatedRulersState = getUpdatedRulersState(
      'guidesState',
      newGuidesState,
      state.rulers,
    );
    dispatch(updateRulersAndHistory(updatedRulersState));
  };

const changeGuidePositionByPixels =
  (direction: AnyFixMe, pixels: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const selectedGuideData = selectors.getSelectedGuideData(getState());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(selectedGuideData.guidePath, direction)) {
      dispatch(
        changeGuidePosition(
          direction,
          selectedGuideData.guideId,
          selectedGuideData.guidePosition + pixels,
        ),
      );
    }
  };

// todo - move to common utils and use here and in selectors
const getSelectedGuideDataById = (
  guidesState: AnyFixMe,
  selectedGuideId: AnyFixMe,
) => {
  if (!selectedGuideId) {
    return null;
  }
  let guidePath;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  if (_.some(guidesState.mobile.verticalGuides, ['guideId', selectedGuideId])) {
    guidePath = 'mobile.verticalGuides';
  } else if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(guidesState.mobile.horizontalGuides, ['guideId', selectedGuideId])
  ) {
    guidePath = 'mobile.horizontalGuides';
  } else if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(guidesState.desktop.verticalGuides, ['guideId', selectedGuideId])
  ) {
    guidePath = 'desktop.verticalGuides';
  } else if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(guidesState.desktop.horizontalGuides, ['guideId', selectedGuideId])
  ) {
    guidePath = 'desktop.horizontalGuides';
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const { guidePosition } = _.find(_.get(guidesState, guidePath), {
    guideId: selectedGuideId,
  });

  return {
    guideId: selectedGuideId,
    guidePosition,
    guidePath,
  };
};

const removeGuideById =
  (guideId: AnyFixMe) => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const state = getState();
    const guidesState = _.cloneDeep(selectors.getGuidesState(state));
    const selectedGuideData = getSelectedGuideDataById(guidesState, guideId);
    const newGuidesState = removeGuideFromPath(
      selectors.getGuidesState(state),
      selectedGuideData.guidePath,
      selectedGuideData.guideId,
    );
    const updatedRulersState = getUpdatedRulersState(
      'guidesState',
      newGuidesState,
      state.rulers,
    );
    updatedRulersState.selectedGuide = null;
    util.keyboardShortcuts.setContext(
      getPreviewMode(state)
        ? util.keyboardShortcuts.CONTEXTS.PREVIEW
        : util.keyboardShortcuts.CONTEXTS.EDITOR,
    );
    dispatch(updateRulersAndHistory(updatedRulersState));
  };

const removeAllGuidesInRuler =
  (isMobileMode: AnyFixMe, isVertical: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const state = getState();
    const newGuidesState = _.cloneDeep(selectors.getGuidesState(state));
    const mode = isMobileMode ? 'mobile' : 'desktop';
    const direction = isVertical ? 'verticalGuides' : 'horizontalGuides';
    if (!_.isEmpty(newGuidesState[mode][direction])) {
      newGuidesState[mode][direction] = [];
      const updatedRulersState = getUpdatedRulersState(
        'guidesState',
        newGuidesState,
        state.rulers,
      );
      updatedRulersState.selectedGuide = null;
      util.keyboardShortcuts.setContext(
        getPreviewMode(state)
          ? util.keyboardShortcuts.CONTEXTS.PREVIEW
          : util.keyboardShortcuts.CONTEXTS.EDITOR,
      );
      dispatch(updateRulersAndHistory(updatedRulersState));
    }
  };

const removeSelectedGuide = () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
  dispatch(removeGuideById(getState().rulers.selectedGuide));
};

const removeGuideViaDelBtn = () => (dispatch: AnyFixMe) => {
  dispatch(sendBIEvent('GUIDE_DELETED', { origin: 'delete_button' }));
  dispatch(removeSelectedGuide());
};

const updateEditorRulersState =
  (key: AnyFixMe, value: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe) => {
    dispatch(updateState(_.defaults({ [key]: value }, getState().rulers)));
  };

const updateGuides = (newGuidesState: AnyFixMe) => (dispatch: AnyFixMe) => {
  dispatch(
    userPreferencesActions.setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.GUIDES_STATE,
      newGuidesState,
    ),
  );
  dispatch(updateEditorRulersState('guidesState', newGuidesState));
};

const updateStateOnDragEnd =
  (isMouseOverRulers: AnyFixMe) => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const editorRulersState = _.clone(getState().rulers);
    editorRulersState.isDragging = false;
    if (!isMouseOverRulers) {
      editorRulersState.isHovered = false;
    }
    dispatch(updateState(editorRulersState));
  };

const selectGuide =
  (newSelectedGuide: AnyFixMe, origin?: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    if (origin) {
      dispatch(sendBIEvent('GUIDE_TOOLTIP_IN_EDIT_MODE', { origin }));
    }
    if (newSelectedGuide) {
      editorAPI.selection.deselectComponents();
      util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.RULERS);
    }

    dispatch(updateEditorRulersState('selectedGuide', newSelectedGuide));
  };

const deselectGuide = () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
  dispatch(selectGuide(null));
  util.keyboardShortcuts.setContext(
    getPreviewMode(getState())
      ? util.keyboardShortcuts.CONTEXTS.PREVIEW
      : util.keyboardShortcuts.CONTEXTS.EDITOR,
  );
};

const setRulersState = (rulersState: AnyFixMe) => ({
  type: SET_RULERS_STATE,
  rulersState,
});

const initRulersFromUserPreferences =
  () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const rulersState = {
      guidesState: userPreferences.selectors.getSiteUserPreferences(
        constants.USER_PREFS.VIEW_TOOLS.GUIDES_STATE,
      )(getState()),
    };

    dispatch(setRulersState(_.defaults(rulersState, rulersInitialState)));
  };

export {
  undo,
  redo,
  changeGuidePosition,
  changeGuidePositionByPixels,
  updateEditorRulersState,
  sendBIEvent,
  selectGuide,
  deselectGuide,
  setVerticalGuide,
  setHorizontalGuide,
  updateStateOnDragEnd,
  toggleRulers,
  resetHistory,
  updateRulersAndHistory,
  setGuide,
  removeGuideViaDelBtn,
  removeGuideById,
  removeSelectedGuide,
  updateGuides,
  initRulersFromUserPreferences,
  removeAllGuidesInRuler,
};
