import { mobileInitialState } from '../initialState';
import * as introsActionTypes from './introsActionTypes';

const { ENABLE_MOBILE_INTROS, DISABLE_MOBILE_INTROS } = introsActionTypes;

export default (
  introsEnabled = mobileInitialState.introsEnabled,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case ENABLE_MOBILE_INTROS:
      return true;

    case DISABLE_MOBILE_INTROS:
      return false;

    default:
      return introsEnabled;
  }
};
