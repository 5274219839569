import panelsActionTypes from './panelsActionTypes';
import type { PanelDescriptor, PanelFilterFunction } from './types';

export const openPanelAction = (panelDescriptor: PanelDescriptor) => ({
  type: panelsActionTypes.OPEN_PANEL,
  panelDescriptor,
});

export const updatePanelAction = (panelDescriptor: PanelDescriptor) => ({
  type: panelsActionTypes.UPDATE_PANEL,
  panelDescriptor,
});

export const setPanelType = (
  panelName: string,
  frameType: string,
  singleInstance: boolean,
  closeWithUserIntent?: boolean,
  isHidden?: boolean,
) => ({
  type: panelsActionTypes.UPDATE_PANEL,

  panelDescriptor: {
    name: panelName,
    frameType,
    singleInstance,
    closeWithUserIntent,
    isHidden,
  },
});

export const closeAllPanels = () => ({
  type: panelsActionTypes.CLOSE_ALL_PANELS,
});

export const closePanelByNameAction = (panelName: string) => ({
  type: panelsActionTypes.CLOSE_PANEL_BY_NAME,
  panelName,
});

export const closePanelByPropKeyAction = (
  prop: string,
  propValue: unknown,
  origin?: string,
) => ({
  type: panelsActionTypes.CLOSE_PANEL_BY_PROP,
  prop,
  propValue,
  origin,
});

export const addPanelFilter = (
  name: string,
  predicate: PanelFilterFunction,
) => ({
  type: panelsActionTypes.ADD_PANEL_FILTER,
  name,
  predicate,
});

export const removePanelFilter = (name: string) => ({
  type: panelsActionTypes.REMOVE_PANEL_FILTER,
  name,
});
