export default {
  TOOLBAR: '2d410806-cc01-4840-9a77-1531d33f565b',
  FROZEN_HEADER: 'f927a8d0-a67a-4d09-b2d6-7eaff0dbc6c8',
  FROZEN_FOOTER: 'cf253240-4f7d-4824-a0b5-6706b164ecf7',
  ATTACH_TO_FOOTER: 'db639aa7-f012-4526-9390-ef466c95f19d',
  ATTACH_TO_HEADER: 'db639aa7-f012-4526-9390-ef466c95f19d',
  OUT_OF_GRIDLINES: 'b712fa83-f939-4ec4-ab68-b7f968d90fab',
  ATTACH_TO_SOSP: '95f36e0d-651e-480b-8d35-1db2016843a5',
  DETACH_FROM_SOSP: 'e3bb822e-e92e-4194-9b46-509f2c680158',
  TOO_MANY_PAGES: '244dfae4-bd5c-4672-85ba-28938f4cb07f',
  PINNED_TOO_BIG: '7827a21e-04e0-4bdd-9ee0-b68677fa7a5a',
  PIN_TO_SCREEN: 'f133c899-6694-4830-bdae-7d1d1d5f219d',
  MOBILE_ONLY_CODE: '92164dda-0315-4948-b8c6-69b4cc05589b',
  UNDO_REDO: '231b5ab9-ee41-4d23-a161-44219a1b61e2',
  CANNOT_DELETE_REF_COMPONENT_INNER_COMP:
    '9523df6b-3edd-4348-bda4-4e46db99e17d',
  TRANSLATED_BY_GOOGLE: '7c5a06b9-867d-4146-8dab-1c671c0f5eac',
  AUTO_TRANSLATE_PARTIAL_SUCCESS: '09f61ec4-a7f6-4361-bcfa-445527cbc414',
  RESIZE_BLOCKED_STATE_BOX: 'fdcca113-4c63-49ef-bd3b-d6da9dbe4555',
} as const;
