import { editorAPIMouseSel } from './rootSelectors';
import _ from 'lodash';
import { layoutUtils } from '@/layoutUtils';
import { createSelector, createStructuredSelector } from './selector';
import { selectedCompsSel } from './selectedCompsSelectors';

export const relativeToStructureOrigLayoutSel = createSelector(
  [editorAPIMouseSel, selectedCompsSel],
  (editorAPI, comps) =>
    _.isEmpty(comps)
      ? null
      : layoutUtils.getLayoutRelativeToPrimaryContainer(editorAPI, comps),
);

const mbParseInt = parseInt as (val: number | string, base?: number) => number;

export const _relativeToStructureLayoutSel = {
  x: createSelector(
    [relativeToStructureOrigLayoutSel],
    (layout) => layout && mbParseInt(layout.bounding.x, 10),
  ),
  y: createSelector(
    [relativeToStructureOrigLayoutSel],
    (layout) => layout && mbParseInt(layout.bounding.y, 10),
  ),
  width: createSelector(
    [relativeToStructureOrigLayoutSel],
    (layout) => layout && mbParseInt(layout.bounding.width, 10),
  ),
  height: createSelector(
    [relativeToStructureOrigLayoutSel],
    (layout) => layout && mbParseInt(layout.bounding.height, 10),
  ),
  rotationInDegrees: createSelector(
    [relativeToStructureOrigLayoutSel],
    (layout) => layout && mbParseInt(layout.rotationInDegrees, 10),
  ),
};

export const relativeToStructureLayoutSel = createStructuredSelector(
  _relativeToStructureLayoutSel,
);

export type RelativeToStructureLayout = ReturnType<
  typeof relativeToStructureLayoutSel
>;
