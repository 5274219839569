const SET_IS_ADDING_COMPONENT_IN_PROGRESS =
  'hidden-items-set-is-adding-component-in-progress';
const SET_HIDDEN_ITEMS_HOVERED_COMPONENT =
  'hidden-items-set-hidden-items-hovered-component';
const SET_HIDDEN_ITEMS_MASTER_PAGE_COMPS =
  'hidden-items-set-master-page-hidden-comps';
const SET_HIDDEN_ITEMS_CURRENT_PAGE_COMPS =
  'hidden-items-set-current-page-hidden-comps';
const REMOVE_HIDDEN_ITEM_FROM_MASTER_PAGE_LIST =
  'hidden-items-remove-component-from-master-page-list';
const REMOVE_HIDDEN_ITEM_FROM_CURRENT_PAGE_LIST =
  'hidden-items-remove-component-from-current-page-list';

export {
  SET_IS_ADDING_COMPONENT_IN_PROGRESS,
  SET_HIDDEN_ITEMS_HOVERED_COMPONENT,
  SET_HIDDEN_ITEMS_MASTER_PAGE_COMPS,
  SET_HIDDEN_ITEMS_CURRENT_PAGE_COMPS,
  REMOVE_HIDDEN_ITEM_FROM_MASTER_PAGE_LIST,
  REMOVE_HIDDEN_ITEM_FROM_CURRENT_PAGE_LIST,
};
