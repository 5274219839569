import _ from 'lodash';
import * as externalLinksActionTypes from './externalLinksActionTypes';

const { OVERRIDE_EXTERNAL_LINK } = externalLinksActionTypes;

export const externalLinksInitialState = {
  overridden: {},
};

const reducer = (
  externalLinks = externalLinksInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case OVERRIDE_EXTERNAL_LINK:
      const overridden = _.defaultsDeep(
        {},
        action.override,
        externalLinks.overridden,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, externalLinks, { overridden });
    default:
      return externalLinks;
  }
};

export default reducer;
