// @ts-nocheck
import actionTypes from './secondaryLeftAreaActionTypes';

const setWidth = (newWidth) => ({ type: actionTypes.SET_WIDTH, newWidth });
const setContentModule = (moduleName) => ({
  type: actionTypes.SET_CONTENT_MODULE,
  moduleName,
});

export { setWidth, setContentModule };
