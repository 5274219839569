import type { Reducer } from 'types/redux';
import * as actionTypes from './previewActionTypes';
import type { PreviewType } from './types';

export interface PreviewState {
  pointerEventsOn: boolean;
  userToggledPreviewInCurrentSession: boolean;
  previewWidth: number;
  preveiwType: PreviewType;
  isAnimatedPreviewResize: boolean;
}

export const previewInitialState: PreviewState = {
  pointerEventsOn: false,
  userToggledPreviewInCurrentSession: false,
  previewWidth: 0,
  preveiwType: 'desktop',
  isAnimatedPreviewResize: false,
};

const reducer: Reducer<PreviewState> = (previewState, action) => {
  switch (action.type) {
    case actionTypes.SET_PREVIEW_POINTER_EVENTS_ON:
      return {
        ...previewState,
        pointerEventsOn: action.pointerEventsOn,
      };
    case actionTypes.SET_IS_USER_TOGGLED_PREVIEW_IN_CURRENT_SESSION:
      return {
        ...previewState,
        userToggledPreviewInCurrentSession: true,
      };
    case actionTypes.SET_PREVIEW_WIDTH:
      return {
        ...previewState,
        previewWidth: action.previewWidth,
      };
    case actionTypes.SET_PREVIEW_TYPE:
      return {
        ...previewState,
        preveiwType: action.preveiwType,
      };
    case actionTypes.ENABLE_PREVIEW_RESIZE_ANIMATION:
      return {
        ...previewState,
        isAnimatedPreviewResize: true,
      };
    case actionTypes.DISABLE_PREVIEW_RESIZE_ANIMATION:
      return {
        ...previewState,
        isAnimatedPreviewResize: false,
      };
    default:
      return previewState;
  }
};

export default reducer;
