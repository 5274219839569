// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import * as selectionActions from '../selection/selectionActions';
import * as inlinePopupActionTypes from './inlinePopupActionTypes';
import constants from '@/constants';

const setOpenInlinePopup = (inlinePopupRef) => ({
  type: inlinePopupActionTypes.SET_OPEN_INLINE_POPUP,
  inlinePopupRef,
});

const open =
  (inlinePopupRef, immediate = false) =>
  (dispatch, getState, { dsRead, dsActions }) => {
    toggleContainerVisibility({ dsRead, dsActions }, inlinePopupRef, true);
    return new Promise((resolve) =>
      dsActions.components.behaviors.execute(inlinePopupRef, 'open', {
        immediate,
        callbacks: {
          onComplete: () => {
            dispatch(setOpenInlinePopup(inlinePopupRef));
            resolve();
          },
        },
      }),
    );
  };

const close =
  (inlinePopupRef, immediate = false) =>
  (dispatch, getState, { dsRead, dsActions }) =>
    new Promise((resolve) =>
      dsActions.components.behaviors.execute(inlinePopupRef, 'close', {
        immediate,
        callbacks: {
          onComplete: () => {
            toggleContainerVisibility(
              { dsRead, dsActions },
              inlinePopupRef,
              false,
            );
            dispatch(selectionActions.setFocusedContainer(null));
            dispatch(selectionActions.removeFromSelection([inlinePopupRef]));
            dispatch(setOpenInlinePopup(null));
            resolve();
          },
        },
      }),
    );

const toggle =
  (inlinePopupRef) =>
  (dispatch, getState, { editorAPI }) => {
    const action = util.inlinePopupUtils.isOpen(editorAPI, inlinePopupRef)
      ? close
      : open;
    return dispatch(action(inlinePopupRef));
  };

function toggleContainerVisibility({ dsRead, dsActions }, target, shouldShow) {
  const func = shouldShow
    ? dsActions.renderPlugins.showComp
    : dsActions.renderPlugins.hideComp;
  func(target);
  dsRead.deprecatedOldBadPerformanceApis.components
    .getChildren(target, true)
    .forEach((comp) => func(comp));
}

const closeAll =
  (immediate = false) =>
  (dispatch, getState, { dsRead }) =>
    Promise.all(
      _(constants.INLINE_POPUP.TYPES)
        .flatMap((type) =>
          dsRead.deprecatedOldBadPerformanceApis.components.get.byType(type),
        )
        .map((comp) => dispatch(close(comp, immediate)))
        .value(),
    );

export { open, close, toggle, closeAll };
