import { mobileSessionActionTypes } from './mobileSessionActionTypes';
import type { Action } from 'redux';

export interface SetDidVisitMobileEditorInCurrentSessionTrueAction
  extends Action<
    typeof mobileSessionActionTypes.SET_DID_VISIT_MOBILE_EDITOR_IN_CURRENT_SESSION_TRUE
  > {}

const setDidVisitMobileEditorInCurrentSessionTrue = () => ({
  type: mobileSessionActionTypes.SET_DID_VISIT_MOBILE_EDITOR_IN_CURRENT_SESSION_TRUE,
});
export const mobileSessionActions = {
  setDidVisitMobileEditorInCurrentSessionTrue,
};
