import type {
  HideSectionizedPanelAction,
  SetHoveredSectionLikeAction,
  ShowSectionizedPanelAction,
  SetIsSectionActionsBarHoveredAction,
} from './sections.actions';
import { sectionsActionTypes } from './sections.actionTypes';

import type { CompRef } from 'types/documentServices';

export const sectionsInitialState: SectionsState = {
  hoveredSectionLikeRef: undefined,
  isSectionizedPanelOpened: false,
  sectionActionsBarHovered: false,
};

export interface SectionsState {
  hoveredSectionLikeRef: CompRef | undefined;
  isSectionizedPanelOpened: boolean;
  sectionActionsBarHovered: boolean;
}

type SectionsAction =
  | SetHoveredSectionLikeAction
  | HideSectionizedPanelAction
  | ShowSectionizedPanelAction
  | SetIsSectionActionsBarHoveredAction;

export const sectionsReducer = (
  state = sectionsInitialState,
  action: SectionsAction,
) => {
  switch (action.type) {
    case sectionsActionTypes.SET_HOVERED_SECTION_LIKE_REF:
      return {
        ...state,
        hoveredSectionLikeRef: action.payload,
      };
    case sectionsActionTypes.SHOW_SECTIONIZED_PANEL:
      return {
        ...state,
        isSectionizedPanelOpened: true,
      };
    case sectionsActionTypes.HIDE_SECTIONIZED_PANEL:
      return {
        ...state,
        isSectionizedPanelOpened: false,
      };
    case sectionsActionTypes.SET_SECTION_ACTION_BAR_HOVERED:
      return {
        ...state,
        sectionActionsBarHovered: action.payload,
      };
    default:
      return state;
  }
};
