import _ from 'lodash';
import * as actionTypes from './permanentBorderActionTypes';

const { SET_COMPONENT } = actionTypes;

export const permanentBorderInitialState: AnyFixMe = {
  component: undefined,
};

const reducer = (
  permanentBorder = permanentBorderInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case SET_COMPONENT:
      return _.defaults({}, { component: action.compRef }, permanentBorder);
    default:
      return permanentBorder;
  }
};

export default reducer;
