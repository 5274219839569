import _ from 'lodash';
import constants from '@/constants';
import { animation } from '@/util';
import type { ClientLayout, ClientScroll } from './domMeasurements.types';

export const getClientEditorStageScroll = (): ClientScroll => {
  const scrollableEditorElement = window.document.getElementById(
    constants.ROOT_COMPS.SELECTOR_ID.SCROLLABLE_EDITOR_STAGE,
  );

  return _.pick(scrollableEditorElement, [
    'scrollLeft',
    'scrollTop',
    'scrollHeight',
    'scrollWidth',
  ]);
};

export const getClientStageLayout = (): ClientLayout => {
  let stageLayout: ClientLayout = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  const stageContainer = window.document.getElementById(
    constants.ROOT_COMPS.SELECTOR_ID.STAGE_CONTAINER,
  );

  const scrollableStageContent = window.document.getElementById(
    constants.ROOT_COMPS.SELECTOR_ID.SCROLLABLE_EDITOR_STAGE_CONTENT,
  );

  if (stageContainer) {
    const stageBoundingRect = stageContainer.getBoundingClientRect();
    stageLayout = _.pick(stageBoundingRect, Object.keys(stageLayout));
  }

  if (scrollableStageContent) {
    const { left } = scrollableStageContent.getBoundingClientRect();
    stageLayout.left = left;
  }

  return stageLayout;
};

export const getClientEditorContentLayout = (): ClientLayout => {
  let editorContent: ClientLayout = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  const editorContainer = window.document.querySelector(
    '.editor-content-wrapper',
  );

  if (editorContainer) {
    const editorBoundingRect = editorContainer.getBoundingClientRect();

    editorContent = _.pick(editorBoundingRect, Object.keys(editorContent));
  }

  return editorContent;
};

export const createWaitForStageLayoutChangeEndPromise = () =>
  animation.waitForAnimationEnd(() => {
    return {
      stageLayout: getClientStageLayout(),
      editorContentLayout: getClientEditorContentLayout(),
    };
  });
