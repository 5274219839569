export default {
  IMAGE: '73121707-4039-4374-b069-1507276ce4e0',
  BOX: '9369198c-90a1-4f21-b75d-2089c9759171',
  MEMBERS: '2b172a7a-04e4-4fc5-bc1b-75d734fb61ab',
  MEMBERS_WELCOME: 'aa5b2ac1-ae45-404f-9b45-33098096c39e',
  NEW_BLOG: '4a365632-9712-4630-95ee-8b8f9074519f',
  NEW_BLOG_WELCOME: '53c03480-93c0-4c1c-a21b-4787ee1d6d05',
  STRIP: '4da90962-8952-46ac-9e9e-dd7403f2fa61',
  POPUP: '24efd6f0-a76c-4a9d-bfb3-5f78284a2d08',
  ONLINE_PROGRAMS_WELCOME: '9e1cda75-0254-4dac-ae80-75b94399b358',
  WIX_BOOKINGS_WELCOME: 'a617fccf-3a79-4c01-acae-2335d755ec81',
  WIX_BOOKINGS_ADD_ELEMENT: 'ec2615c5-4f08-4c7e-8ee5-2c9fde4ab6cb',
  WIX_BOOKINGS: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
  EVENTS: '2f8a99f2-0e47-4c5f-bc17-4a768db57c8f',
};
