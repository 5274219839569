import type { EditorConfig } from 'types/core';
import actionTypes from '../config/configActionTypes';

const { SET_TOP_BAR_HEIGHT } = actionTypes;

export default (config: EditorConfig, action: AnyFixMe) => {
  switch (action.type) {
    case SET_TOP_BAR_HEIGHT:
      return {
        ...config,
        topBarHeight: action.payload.value,
      };
    default:
      return config;
  }
};
