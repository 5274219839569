import _ from 'lodash';
import * as linkPanelActionTypes from './linkPanelActionTypes';
import type { Reducer } from 'types/redux';

const { OVERRIDE_LINK_PANEL_SECTIONS } = linkPanelActionTypes;

export interface LinkPanelState {
  overridden: unknown;
}

export const linkPanelInitialState: LinkPanelState = {
  overridden: undefined,
};

const reducer: Reducer<LinkPanelState> = (linkPanel, action) => {
  switch (action.type) {
    case OVERRIDE_LINK_PANEL_SECTIONS:
      const overridden = action?.overridden ?? linkPanel.overridden;
      return _.merge({}, linkPanel, { overridden });
    default:
      return linkPanel;
  }
};

export default reducer;
