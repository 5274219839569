import * as util from '@/util';
import constants from '@/constants';
import { components, multilingual, domMeasurements } from '@/stateManagement';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import type { CompLayout, CompRef, DSRead } from 'types/documentServices';
import type { EditorState } from '@/stateManagement';
import type { GetLayoutFnMulti } from '../type';

function createCache<A extends any[], R>(
  origFn: (...args: A) => R,
  getCacheKey: (...args: A) => string,
) {
  const cache = new Map<string, R>();

  function cachedFn(...args: A) {
    const cacheKey = getCacheKey(...args);
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    }
    const value = origFn(...args);
    cache.set(cacheKey, value);
    return value;
  }

  const clearCache = () => {
    cache.clear();
  };

  return {
    cachedFn,
    clearCache,
  };
}

const isSingleColumnStripColumn = (dsRead: DSRead, compRef: CompRef) => {
  return (
    dsRead.components.getType(compRef) === constants.COMP_TYPES.COLUMN &&
    !dsRead.components.getSiblings(compRef).length
  );
};

const getResponsiveLayoutDiff = (
  dsRead: DSRead,
  compRef: CompRef,
  siteScale: number,
): CompLayout => {
  // getRelativeToScreen returns site width for a single-column strip column layout width, so we need to apply the layout of the strip instead
  const handledCompRef = isSingleColumnStripColumn(dsRead, compRef)
    ? dsRead.components.getContainer(compRef)
    : compRef;

  const clientRect =
    dsRead.components.layout.measure.getBoundingClientRect(handledCompRef);

  const layout = dsRead.components.layout.getRelativeToScreen(handledCompRef);
  if (compRef.id === 'PAGES_CONTAINER') {
    clientRect.absoluteLeft = layout.x; //somewhy it's 0 for page container, and width is += layout.x
    clientRect.width = layout.width;
  }

  return {
    width: clientRect.width / siteScale - layout.width,
    height: clientRect.height / siteScale - layout.height,
    x: clientRect.absoluteLeft / siteScale - layout.x,
    y: clientRect.absoluteTop / siteScale - layout.y,
  };
};

/**
 * @deprecated
 */
export function createLayoutPatcher(editorAPI: AnyFixMe) {
  let readLayoutFromDom = false;

  const {
    cachedFn: cachedGetResponsiveLayoutDiff,
    clearCache: clearLayoutCache,
  } = createCache(
    getResponsiveLayoutDiff,
    (dsRead, compRef) => `${compRef.id}|${compRef.type}`,
  );

  function getDiff(dsRead: DSRead, state: EditorState, compRef: CompRef) {
    const siteScale = domMeasurements.selectors.getSiteScale(state);

    if (readLayoutFromDom) {
      return cachedGetResponsiveLayoutDiff(dsRead, compRef, siteScale);
    }

    if (components.selectors.hasResponsiveLayout(compRef, dsRead)) {
      return cachedGetResponsiveLayoutDiff(dsRead, compRef, siteScale);
    }

    if (multilingual.selectors.isTranslating(state)) {
      return cachedGetResponsiveLayoutDiff(dsRead, compRef, siteScale);
    }
  }

  const patchLayoutFn = (origGetLayout: GetLayoutFnMulti): GetLayoutFnMulti => {
    if (isResponsiveEditor()) {
      return origGetLayout;
    }

    return (compRefs): CompLayout => {
      const origLayout = origGetLayout(compRefs); //other args

      const compsArr = util.array.asArray(compRefs);

      if (compsArr.length > 1) {
        return origLayout;
      }

      const diff = getDiff(
        editorAPI.dsRead,
        editorAPI.store.getState(),
        compsArr[0],
      );
      if (!diff) {
        return origLayout;
      }
      return {
        ...origLayout,
        width: origLayout.width + diff.width,
        height: origLayout.height + diff.height,
        x: origLayout.x + diff.x,
        y: origLayout.y + diff.y,
        bounding: {
          width: origLayout.bounding.width + diff.width,
          height: origLayout.bounding.height + diff.height,
          x: origLayout.bounding.x + diff.x,
          y: origLayout.bounding.y + diff.y,
        },
      };
    };
  };

  function setLayoutReadFromDOM(_readLayoutFromDom: boolean) {
    readLayoutFromDom = _readLayoutFromDom;
  }

  return { patchLayoutFn, setLayoutReadFromDOM, clearLayoutCache };
}
