export default {
  'StylableButton.root': 'de96244a-c9d3-4aac-a046-5b83adfa91af',
  'StylableButton.root::label': '72024bc1-d0aa-4cb0-b565-b31505ec070e',
  'StylableButton.root::icon': '89ebf2be-4da5-4d08-a457-ab6eed4d9cf0',
  'StylableHorizontalMenu.root': '28b1daff-3649-401f-baf3-6d649ca8f9af',
  'StylableHorizontalMenu.root::menuItem':
    '28b1daff-3649-401f-baf3-6d649ca8f9af',
  'StylableHorizontalMenu.root::menuItem::label':
    '28b1daff-3649-401f-baf3-6d649ca8f9af',
  'StylableHorizontalMenu.root::columnsLayout':
    '28b1daff-3649-401f-baf3-6d649ca8f9af',
  'StylableHorizontalMenu.root::columnsLayout::menuItem':
    '28b1daff-3649-401f-baf3-6d649ca8f9af',
  'StylableHorizontalMenu.root::columnsLayout::menuItem::label':
    '28b1daff-3649-401f-baf3-6d649ca8f9af',
  'CollapsibleText.root': '143944a3-2d61-4a48-9ea7-5526cf541089',
  'CollapsibleText.root::text': '143944a3-2d61-4a48-9ea7-5526cf541089',
  'CollapsibleText.root::expand-control':
    '143944a3-2d61-4a48-9ea7-5526cf541089',
  'RangeSlider.root::label': 'fc626224-171e-4c9a-a502-b5a3c48cd3e2',
  'RangeSlider.root::sliderThumb': 'fc626224-171e-4c9a-a502-b5a3c48cd3e2',
  'RangeSlider.root::markLine': 'fc626224-171e-4c9a-a502-b5a3c48cd3e2',
  'RangeSlider.root::slider-rail': 'fc626224-171e-4c9a-a502-b5a3c48cd3e2',
  'RangeSlider.root::slider-track': 'fc626224-171e-4c9a-a502-b5a3c48cd3e2',
  'Tabs.root': '8b5c6db2-b67e-4a3c-a332-f23376dc827c',
  'Tabs.root::tabsList': '8b5c6db2-b67e-4a3c-a332-f23376dc827c',
  'Tabs.root::tabsList::tab': '8b5c6db2-b67e-4a3c-a332-f23376dc827c',
  'Tabs.root::tabsList::scrollButton': '8b5c6db2-b67e-4a3c-a332-f23376dc827c',
  'Tabs.root::multiStateBoxWrapper': '8b5c6db2-b67e-4a3c-a332-f23376dc827c',
  'Breadcrumbs.root': '2a62f7cc-d5f7-4307-bb1c-c8ae7c861575',
};
