import { schoolModeActionTypes } from './schoolModeActionTypes';

const { ENABLE_SCHOOL_MODE } = schoolModeActionTypes;

export interface SchoolModeState {
  enabled: boolean;
}

export const schoolModeInitialState: SchoolModeState = {
  enabled: false,
};

const reducer = (schoolMode = schoolModeInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case ENABLE_SCHOOL_MODE:
      return schoolMode.enabled === true
        ? schoolMode
        : { ...schoolMode, enabled: true };
    default:
      return schoolMode;
  }
};

export default reducer;
