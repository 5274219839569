import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import { getSnugLayoutFromLayoutsArray } from '@/layoutUtils';
import { ensureCompRefOrRefsHasValue } from './validation';
import type { CompLayout, CompRef, Rect, DSRead } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { GetLayoutFn, GetLayoutFnMulti } from './type';

const { asArray } = util.array;

type LayoutPlugin = (
  data: LayoutContext,
  layout: CompLayout,
) => Partial<CompLayout>;

const anchorPlugin: LayoutPlugin = (
  { isMobile, siteWidth, stageWidth },
  layout,
) => {
  const ANCHOR_WIDTH = 141;
  const MARGINS_FROM_RIGHT = 68;

  let { width } = layout;
  let left = stageWidth - (ANCHOR_WIDTH + MARGINS_FROM_RIGHT);

  if (isMobile) {
    left = left - (stageWidth - siteWidth) / 2 - 13; // eslint-disable-line no-mixed-operators
    width = 140;
  }

  return {
    x: left,
    y: layout.y,
    height: 0,
    width,
  };
};

const pagePlugin: LayoutPlugin = ({ isMobile, siteX, siteWidth }) => {
  if (isMobile) {
    return {};
  }
  return {
    width: siteWidth,
    x: siteX,
  };
};

const layoutPlugins: Record<string, LayoutPlugin> = {
  'wysiwyg.common.components.anchor.viewer.Anchor': anchorPlugin,
  'wysiwyg.viewer.components.PageGroup': pagePlugin,
  'wysiwyg.viewer.components.PagesContainer': pagePlugin,
};

function getLayoutForMulti(
  getSingleLayout: GetLayoutFn,
  compRefs: CompRef | CompRef[],
) {
  ensureCompRefOrRefsHasValue(
    getSingleLayout.name || 'layout.get[...]',
    compRefs,
  );

  const compsArr = asArray(compRefs);

  const compLayouts = compsArr.map((compRef) => {
    const layout: CompLayout = getSingleLayout(compRef);
    return layout;
  });

  let resLayout: CompLayout;
  if (compsArr.length === 1) {
    resLayout = compLayouts[0];
  } else {
    resLayout = getSnugLayoutFromLayoutsArray(compLayouts);
  }
  return resLayout;
}

interface LayoutContext {
  dsRead: DSRead;
  stageWidth: number;
  scrollX: number;
  scrollY: number;
  siteScale: number;
  isMobile: boolean;
  siteX: number;
  siteWidth: number;
}

export function getRelativeToScreenInner(
  context: LayoutContext,
  compRef: CompRef,
) {
  const { dsRead } = context;
  const isFixed = dsRead.components.layout.isShowOnFixedPosition(compRef);

  let clientRect;

  if (isFixed) {
    clientRect =
      dsRead.components.layout.measure.getRelativeToViewportBoundingBox(
        compRef,
      );
  } else {
    clientRect =
      dsRead.components.layout.measure.getBoundingClientRect(compRef);
  }

  const compLayoutRelativeToScreen: Rect = {
    width: clientRect.width,
    height: clientRect.height,
    x: clientRect.absoluteLeft,
    y: clientRect.absoluteTop,
  };

  if (context.siteScale && context.siteScale !== 1) {
    const { stageWidth, siteScale } = context;

    // does not work for fixed components
    const pageLeft = stageWidth / 4;
    compLayoutRelativeToScreen.x =
      (compLayoutRelativeToScreen.x - pageLeft) / siteScale;
    compLayoutRelativeToScreen.y /= siteScale;
    compLayoutRelativeToScreen.height /= siteScale;
    compLayoutRelativeToScreen.width /= siteScale;
  }
  const compType = dsRead.components.getType(compRef);
  const plugin = layoutPlugins[compType];
  if (plugin) {
    Object.assign(
      compLayoutRelativeToScreen,
      plugin(context, compLayoutRelativeToScreen),
    );
  }

  return compLayoutRelativeToScreen;
}

export function getRelativeToScreen(context: LayoutContext, compRef: CompRef) {
  const { dsRead } = context;

  if (process.env.NODE_ENV === 'test') {
    return dsRead.components.layout.getRelativeToScreen(compRef);
  }

  if (!dsRead.components.is.rendered(compRef)) {
    //eslint-disable-next-line no-console
    console.warn('getRelativeToScreen: not rendered');
    return dsRead.components.layout.getRelativeToScreen(compRef);
  }

  const compLayout_nonRect = dsRead.components.layout.get(compRef);
  const compLayoutRelativeToScreen_rect = getRelativeToScreenInner(
    context,
    compRef,
  );
  const compLayoutRelativeToScreen = {
    ...compLayout_nonRect,
    ...compLayoutRelativeToScreen_rect,
  };

  // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
  const docked = dsRead.components.layout.getDock(compRef);
  if (docked) {
    compLayoutRelativeToScreen.docked = docked;
  }

  compLayoutRelativeToScreen.bounding = dsRead.utils.getBoundingLayout(
    compLayoutRelativeToScreen,
  );

  return compLayoutRelativeToScreen;
}

export function getRelativeToScreenConsideringScroll(
  data: LayoutContext,
  compRef: CompRef,
) {
  const layout = getRelativeToScreen(data, compRef);

  if (!data.dsRead.components.layout.isShowOnFixedPosition(compRef)) {
    layout.x -= data.scrollX;
    layout.y -= data.scrollY;
    layout.bounding.x -= data.scrollX;
    layout.bounding.y -= data.scrollY;
  }

  return layout;
}

const makeLayoutFn = (
  editorAPI: EditorAPI,
  fn: (context: LayoutContext, compRef: CompRef) => CompLayout,
): GetLayoutFnMulti => {
  return (compRefs) => {
    const context = getLayoutContext(editorAPI);
    return getLayoutForMulti((compRef) => {
      return fn(context, compRef);
    }, compRefs);
  };
};

export function getLayoutContext(editorAPI: EditorAPI): LayoutContext {
  const dsRead = editorAPI.dsRead;
  const scroll = editorAPI.dsRead.site.getScroll();
  const siteScale = editorAPI.getSiteScale();

  const stageWidth =
    stateManagement.domMeasurements.selectors.getPreviewPosition(
      editorAPI.store.getState(),
    ).width;

  const isMobile = dsRead.viewMode?.get() === dsRead.viewMode.VIEW_MODES.MOBILE;

  const siteWidth = dsRead.site.getWidth();
  const siteX = dsRead.site.getSiteX();
  return {
    dsRead,
    scrollX: scroll.x,
    scrollY: scroll.y,
    siteScale,
    stageWidth,
    isMobile,
    siteWidth,
    siteX,
  };
}

export function getDomLayoutFn(
  editorAPI: EditorAPI,
  domLayoutFnFactory: (data: LayoutContext, compRef: CompRef) => CompLayout,
): GetLayoutFnMulti {
  const domLayoutFn = makeLayoutFn(editorAPI, domLayoutFnFactory);

  return (compRef) => {
    return domLayoutFn(compRef);
  };
}
