import { getComponentsContainerOrScopeOwner } from '@/documentServices';
import { getLayoutSizePxOrThrow, isMeshItemLayout } from '@/layoutUtils';
import { responsiveLayoutGet } from '../responsiveLayout';
import type { CompLayout, CompRef, DSRead } from 'types/documentServices';

export interface GetLayoutFromDOMContext {
  siteScale: number;
}

function isRendered(dsRead: DSRead, compRef: CompRef): boolean {
  return dsRead.components.isRenderedOnSite(compRef);
}

function withScale(value: number = 1, scale: number): number {
  return scale === 1 ? value : value / scale;
}

function layoutGetX_fromStructure(dsRead: DSRead, compRef: CompRef): number {
  const layouts = responsiveLayoutGet(dsRead, compRef);

  return layouts && isMeshItemLayout(layouts.itemLayout)
    ? // TODO: left & right were removed from "MeshItemLayout" - https://github.com/wix-private/document-management/pull/24507
      // @ts-expect-error
      getLayoutSizePxOrThrow(layouts.itemLayout.left)
    : undefined;
}

function layoutGetXRelativeToStructure_fromStructure(
  dsRead: DSRead,
  compRef: CompRef,
): number {
  const x = layoutGetX_fromStructure(dsRead, compRef);
  if (typeof x !== 'number') {
    return undefined;
  }

  const containerRef = getComponentsContainerOrScopeOwner(dsRead, [compRef]);
  const containerX =
    containerRef &&
    layoutGetXRelativeToStructure_fromStructure(dsRead, containerRef);

  return typeof containerX === 'number' ? x + containerX : x;
}

function layoutGetXRelativeToScreen_fromDOM(
  dsRead: DSRead,
  compRef: CompRef,
  context?: GetLayoutFromDOMContext,
): number {
  if (!isRendered(dsRead, compRef)) {
    return undefined;
  }

  // TODO:
  // - siteScale
  // - fixed

  return withScale(
    dsRead.components.layout.measure.getBoundingClientRect(compRef)
      .absoluteLeft,
    context.siteScale,
  );
}

function layoutGetYRelativeToScreen_fromDOM(
  dsRead: DSRead,
  compRef: CompRef,
  context?: GetLayoutFromDOMContext,
): number {
  if (!isRendered(dsRead, compRef)) {
    return undefined;
  }

  // TODO:
  // - siteScale
  // - fixed

  return withScale(
    dsRead.components.layout.measure.getBoundingClientRect(compRef).absoluteTop,
    context?.siteScale,
  );
}

function layoutGetY_fromDOM(
  dsRead: DSRead,
  compRef: CompRef,
  context?: GetLayoutFromDOMContext,
): number {
  if (!isRendered(dsRead, compRef)) {
    return undefined;
  }

  const containerRef = dsRead.components.getContainer(compRef);
  const yRelativeToScreen = layoutGetYRelativeToScreen_fromDOM(
    dsRead,
    compRef,
    context,
  );
  const yRelativeToScreenForContainer = containerRef
    ? layoutGetYRelativeToScreen_fromDOM(dsRead, containerRef, context)
    : 0;

  return yRelativeToScreen - yRelativeToScreenForContainer;
}

export function layoutGetPosition(
  dsRead: DSRead,
  compRef: CompRef,
  context: GetLayoutFromDOMContext,
): Pick<CompLayout, 'x' | 'y'> {
  if (!isRendered(dsRead, compRef)) {
    return undefined;
  }

  if (dsRead.utils.isSameRef(compRef, dsRead.siteSegments.getSiteStructure())) {
    return {
      x: 0,
      y: 0,
    };
  }

  return {
    x: layoutGetX_fromStructure(dsRead, compRef),
    y: layoutGetY_fromDOM(dsRead, compRef, context),
  };
}

export function layoutGetPositionRelativeToStructure(
  dsRead: DSRead,
  compRef: CompRef,
  context?: GetLayoutFromDOMContext,
): Pick<CompLayout, 'x' | 'y'> {
  if (!isRendered(dsRead, compRef)) {
    return undefined;
  }

  if (dsRead.utils.isSameRef(compRef, dsRead.siteSegments.getSiteStructure())) {
    return {
      x: 0,
      y: 0,
    };
  }

  return {
    x: layoutGetXRelativeToStructure_fromStructure(dsRead, compRef),
    y: layoutGetYRelativeToScreen_fromDOM(dsRead, compRef, context),
  };
}

export function layoutGetPositionRelativeToScreen(
  dsRead: DSRead,
  compRef: CompRef,
  context?: GetLayoutFromDOMContext,
): Pick<CompLayout, 'x' | 'y'> {
  if (!isRendered(dsRead, compRef)) {
    return undefined;
  }

  return {
    x: layoutGetXRelativeToScreen_fromDOM(dsRead, compRef, context),
    y: layoutGetYRelativeToScreen_fromDOM(dsRead, compRef, context),
  };
}
