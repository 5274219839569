import _ from 'lodash';
import * as actionTypes from './welcomeScreenActionTypes';
import type { Reducer } from 'types/redux';

export const welcomeScreenInitialState = {
  overrides: {
    panels: [] as string[],
  },
};

const reducer: Reducer<typeof welcomeScreenInitialState> = (
  state = welcomeScreenInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_CONFIG:
      return _.defaultsDeep({}, action.welcomeScreen, state);
    case actionTypes.SET_OVERRIDE:
      const panels = action.overrides.panels.concat(state.overrides.panels);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, state, { overrides: { panels } });
    default:
      return state;
  }
};

export default reducer;
