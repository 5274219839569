import setStateReducer from './setStateReducer';
import type { Reducer } from 'types/redux';

const reducer =
  <TState>(combinedReducer: Reducer<TState>): Reducer<TState> =>
  (state, action) => {
    const newState: TState = setStateReducer(state, action);
    return combinedReducer(newState, action);
  };

export default reducer;
