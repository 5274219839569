import _ from 'lodash';
import constants from '@/constants';
import * as util from '@/util';
import * as boxSlideShowSelectors from '../boxSlideShow/boxSlideShowSelectors';

import coreUtilsLib from 'coreUtilsLib';
import type {
  DSRead,
  CompRef,
  BehaviorObject,
  LegacyBehaviorObject,
  ComponentProperties,
} from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

import type { EditorState } from '@/stateManagement';

import { interactionsSelectors } from '../interactions/interactionsSelectors';
import {
  isComponentVisibleInMobileMode,
  isMobileEditor,
} from '../mobile/mobileSelectors';
import {
  getComponentsContainer,
  getComponentsContainerOrScopeOwner,
  getComponentCodeNickname,
  findComponentAncestorMatchesCondition,
  someComponentAncestorMatchesCondition,
  isRefComponent as isRefComponent_inner,
  isGhostRefComponent as isGhostRefComponent_inner,
  isAppWidget as isAppWidget_inner,
  getOneComponentIfSiblings,
} from '@/documentServices';

const {
  isComponentVisibleInInteractionMode,
  isInInteractionMode,
  isOneOfAncestorsShownOnlyOnVariant,
} = interactionsSelectors;

const { asArray, applyForFirstAndThrowIfMulti } = util.array;
const { isResponsiveBlocksWidget } = util.appStudioUtils;
const { hasDividersDesign } = util.designData;

const getCompTypeSuffix = (
  compRefs: CompRef | CompRef[],
  dsRead: DSRead,
): string => {
  const fullType = getCompType(compRefs, dsRead);
  return fullType && util.path.suffix(fullType);
};

const getCompType = (
  compRefs: CompRef | CompRef[],
  dsRead: DSRead,
): string | null => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  if (compRefs && (_.isArray(compRefs) ? compRefs.length : compRefs.id)) {
    const compsArray = asArray(compRefs);

    if (compsArray.length > 1) {
      return constants.MULTISELECT.TYPE;
    }

    const firstCompRef = _.head(compsArray);
    return dsRead.components.is.exist(firstCompRef)
      ? dsRead.components.getType(firstCompRef)
      : null;
  }

  return null;
};

/**
 * Checks whether a specified component is a Stylable component
 * @param compRef - Component reference object
 * @param dsRead - Document Services object
 * @returns Is the component a Stylable component?
 */
const isStylable = (compRef: CompRef | CompRef[], dsRead: DSRead): boolean => {
  const compType = getCompType(compRef, dsRead);

  if (compType) {
    const compDef = dsRead.components.getDefinition(compType);
    const selectedCompRef = Array.isArray(compRef) ? compRef[0] : compRef;
    // @ts-expect-error TODO: fix type in document-services-types
    const compSkin = dsRead.components.skin.get(selectedCompRef);
    const isSkinless = compSkin === 'wixui.skins.Skinless';
    return !!compDef && !!compDef.isStylableComp && isSkinless;
  }

  return false;
};

const isChildOfCompType = (
  compRef: CompRef | CompRef[],
  compTypes: string[],
  dsRead: DSRead,
): boolean => {
  if (!compRef) {
    return false;
  }
  const container = getContainer(compRef, dsRead);
  const containerType = getCompType(container, dsRead);

  return compTypes.includes(containerType);
};

const isRepeaterItem = (
  compRef: CompRef | CompRef[],
  dsRead: DSRead,
): boolean =>
  isChildOfCompType(compRef, ['wysiwyg.viewer.components.Repeater'], dsRead);

const CONTROLLING_PARENTS = [
  'wysiwyg.viewer.components.RefComponent',
  'wysiwyg.viewer.components.Repeater',
];

const isChildOfControllingParent = (
  compRef: CompRef | CompRef[],
  dsRead: DSRead,
): boolean => isChildOfCompType(compRef, CONTROLLING_PARENTS, dsRead);

const getContainer = (
  compRefOrRefs: CompRef | CompRef[],
  dsRead: DSRead,
): CompRef => getComponentsContainer(dsRead, asArray(compRefOrRefs));

const hasResponsiveLayout = (
  compRefOrRefs: CompRef | CompRef[],
  dsRead: DSRead,
): Boolean => {
  const [compRef] = asArray(compRefOrRefs);
  return Boolean(compRef && dsRead.components.responsiveLayout?.get(compRef));
};

const getCompsData = (compRefs: CompRef | CompRef[], dsRead: DSRead) => {
  const compsArr = asArray(compRefs);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(compsArr, (compRef) => dsRead.components.data.get(compRef));
};

/**
 * Returns visible to visible according to modes. Modes which are turned off return by default true.
 * @param dsRead: DocumentServicesObject
 * @param compRef: CompRef
 * @param currentEditorState: state
 */
const getIsComponentVisibleInCurrentMode = (
  dsRead: DSRead,
  compRef: CompRef,
  currentEditorState: EditorState,
): boolean => {
  if (isInInteractionMode(currentEditorState)) {
    return isComponentVisibleInInteractionMode(
      dsRead,
      compRef,
      currentEditorState,
    );
  }

  if (isMobileEditor(currentEditorState)) {
    return isComponentVisibleInMobileMode(dsRead, compRef);
  }

  const isHidden =
    dsRead.components.transformations?.get(compRef)?.hidden === true;
  return !isHidden && !isOneOfAncestorsShownOnlyOnVariant(dsRead, compRef);
};

const getBehaviors = (
  compRefs: CompRef | CompRef[],
  dsRead: DSRead,
): LegacyBehaviorObject[] | BehaviorObject[] => {
  const compRefsArr = asArray(compRefs);
  // TODO: get rid of lodash chain and fix type
  const behaviorsArr = _(compRefsArr)
    .flatMap(dsRead.components.behaviors.get)
    .compact()
    .value() as AnyFixMe;
  return behaviorsArr;
};

const getProperties = (
  compRefs: CompRef | CompRef[],
  dsRead: DSRead,
): Partial<ComponentProperties> =>
  getSingleDataItems(compRefs, dsRead.components.properties.get);

const getData = (
  compRefs: CompRef | CompRef[],
  dsRead: DSRead,
  useOriginalLanguage: boolean = false,
) =>
  getSingleDataItems(compRefs, dsRead.components.data.get, useOriginalLanguage);

const getDesign = (compRefs: CompRef | CompRef[], dsRead: DSRead) =>
  getSingleDataItems(compRefs, dsRead.components.design.get);

const getStyle = (compRefs: CompRef | CompRef[], dsRead: DSRead) =>
  getSingleDataItems(compRefs, dsRead.components.style.get);

const getSingleDataItems = (
  compRefs: CompRef | CompRef[],
  method: (
    compRef: CompRef,
    fixMeProp: AnyFixMe,
    useOriginalLanguage: boolean,
  ) => AnyFixMe,
  useOriginalLanguage: boolean = false,
): AnyFixMe => {
  const compsArr = asArray(compRefs);
  const dataItemsArr = compsArr.map((compRef) =>
    method(compRef, null, useOriginalLanguage),
  );
  return compsArr.length > 1 ? dataItemsArr : _.head(dataItemsArr);
};

const getSkin = (compRefs: CompRef | CompRef[], dsRead: DSRead): string =>
  applyForFirstAndThrowIfMulti('getSkin', dsRead.components.skin.get, compRefs);

const getStyleId = (compRefs: CompRef[], dsRead: DSRead): string =>
  applyForFirstAndThrowIfMulti(
    'getStyleId',
    dsRead.components.style.getId,
    compRefs,
  );

const getComponentSkinExports = (
  compRefs: CompRef | CompRef[],
  dsRead: DSRead,
): AnyFixMe =>
  applyForFirstAndThrowIfMulti(
    'getComponentSkinExports',
    dsRead.components.skin.getComponentSkinExports,
    compRefs,
  );

const getPlatformDisplayName = (
  componentRef: CompRef,
  dsRead: DSRead,
): string => {
  const stageData =
    dsRead.platform.controllers.getConnectedComponentStageData(componentRef);
  return stageData?.displayName;
};

const isAppWidget = (compRef: CompRef, dsRead: DSRead): boolean =>
  isAppWidget_inner(dsRead, compRef);

const isWidgetRef = (compRef: CompRef, dsRead: DSRead): boolean =>
  getData(compRef, dsRead)?.type === 'WidgetRef';

const isWidgetInWidget = (comp: CompRef, dsRead: DSRead): boolean => {
  return isWidgetRef(comp, dsRead) && isDescendantOfAppWidget(comp, dsRead);
};

const shouldStartDesignPanelOpened = (
  compRef: CompRef,
  dsRead: DSRead,
): boolean => dsRead.components.getType(compRef) === 'wixui.VideoPlayer';

const isStateBox = (compRef: CompRef, dsRead: DSRead): boolean =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  _.includes(
    [
      'wysiwyg.viewer.components.StateBox',
      'wysiwyg.viewer.components.StateStrip',
    ],
    dsRead.components.getType(compRef),
  );

const isDirectDescendantOfAppWidget = (
  compRef: CompRef,
  dsRead: DSRead,
): boolean => {
  const currentAncestor =
    dsRead.deprecatedOldBadPerformanceApis.components.getContainer(compRef);
  if (currentAncestor) {
    const nextAncestor =
      dsRead.deprecatedOldBadPerformanceApis.components.getContainer(
        currentAncestor,
      );
    return (
      isAppWidget(currentAncestor, dsRead) || isAppWidget(nextAncestor, dsRead)
    );
  }
  return false;
};

const isDescendantOfAppWidget = (
  compRefOrRefs: CompRef | CompRef[],
  dsRead: DSRead,
): boolean => {
  const compRef = getOneComponentIfSiblings(dsRead, compRefOrRefs);
  return someComponentAncestorMatchesCondition(
    dsRead,
    compRef,
    (ancestorRef) => isAppWidget(ancestorRef, dsRead),
    { includeScopeOwner: true },
  );
};

const isDescendantOfBlocksWidget = (
  compRefOrRefs: CompRef | CompRef[],
  dsRead: DSRead,
): boolean => {
  const compRef = getOneComponentIfSiblings(dsRead, compRefOrRefs);
  return someComponentAncestorMatchesCondition(
    dsRead,
    compRef,
    (ancestorRef) => isResponsiveBlocksWidget({ dsRead }, ancestorRef),
    { includeScopeOwner: true },
  );
};

const getPrimaryConnectionToAppWidget = (
  compRef: CompRef,
  dsRead: DSRead,
): CompRef | undefined => {
  const connection =
    dsRead.platform.controllers.connections.getPrimaryConnection(compRef);

  if (connection && isAppWidget(connection.controllerRef, dsRead)) {
    return connection.controllerRef;
  }
};

const isPrimaryConnectedToAppWidget = (
  compRef: CompRef,
  dsRead: DSRead,
): boolean => {
  const connection =
    dsRead.platform.controllers.connections.getPrimaryConnection(compRef);

  if (!connection) {
    return false;
  }

  return isAppWidget(connection.controllerRef, dsRead);
};
const someDescendantIsPrimaryConnectedToAppWidget = (
  compRefs: CompRef | CompRef[],
  dsRead: DSRead,
): boolean => {
  const compsArray = asArray(compRefs);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/concat
  const comps = _.concat(
    compsArray,
    _.flatMap(compsArray, (compRef) =>
      dsRead.components.getChildrenFromFull(compRef, true),
    ),
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(comps, (compRef) =>
    isPrimaryConnectedToAppWidget(compRef, dsRead),
  );
};

const getAppContainerIfExists = (
  compRef: CompRef,
  focusedContainer: CompRef,
  dsRead: DSRead,
): CompRef | null => {
  const ancestors =
    dsRead.deprecatedOldBadPerformanceApis.components.getAncestors(compRef);
  const outermostAppWidget = _.findLast(ancestors, (ancestor) =>
    isAppWidget(ancestor, dsRead),
  );
  const outermostAppWidgetParent =
    outermostAppWidget &&
    getComponentsContainerOrScopeOwner(dsRead, [outermostAppWidget]);

  if (!outermostAppWidget) {
    return null;
  }

  const appContainer = isRefComponent(outermostAppWidgetParent, dsRead)
    ? outermostAppWidgetParent
    : outermostAppWidget;
  return dsRead.utils.isSameRef(appContainer, focusedContainer)
    ? null
    : appContainer;
};

const getFirstAppWidget = (
  compRef: CompRef,
  dsRead: DSRead,
): CompRef | null => {
  return findComponentAncestorMatchesCondition(
    dsRead,
    compRef,
    (ancestorRef) => isAppWidget(ancestorRef, dsRead),
    { includeScopeOwner: true },
  );
};

const resolveSelectedComponentForBackgroundSettings = (
  compRef: CompRef[],
  editorAPI: EditorAPI,
): CompRef => {
  if (
    editorAPI.components.is.nestedBackgroundDesign(compRef) &&
    !hasDividersDesign(editorAPI, compRef)
  ) {
    if (editorAPI.components.is.slidesContainer(compRef)) {
      return boxSlideShowSelectors.findCurrentSlide(editorAPI, compRef);
    }
    return _.head(
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compRef),
    );
  }
  return _.head(compRef);
};

const getNickname = (compRef: CompRef, dsRead: DSRead): string | null =>
  getComponentCodeNickname(dsRead, compRef) || null;

const getNonAppWidgetComp = (compRef: CompRef, dsRead: DSRead): CompRef => {
  if (!isAppWidget(compRef, dsRead)) {
    return compRef;
  }

  const children = dsRead.components.getChildren(compRef);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/size
  if (_.size(children) === 1) {
    return children[0];
  }

  return compRef;
};

const getInnerNickname = (compRef: CompRef, editorAPI: EditorAPI): string => {
  const { dsRead } = editorAPI;
  const mainComp = getNonAppWidgetComp(compRef, dsRead);
  return isStateBox(mainComp, dsRead)
    ? getNickname(
        boxSlideShowSelectors.findCurrentSlide(editorAPI, mainComp),
        dsRead,
      )
    : null;
};

const getSecondaryNickname = (
  compRef: CompRef,
  { dsRead }: EditorAPI,
): string => {
  const mainComp = getNonAppWidgetComp(compRef, dsRead);
  return mainComp === compRef ? null : getNickname(mainComp, dsRead);
};

const filterVisibleComponents = (
  compRefs: CompRef[],
  dsRead: DSRead,
): CompRef[] =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  _.filter(compRefs, dsRead.components.is.visible);

const getComponentsByType = (
  compType: string,
  rootComp: CompRef,
  dsRead: DSRead,
): CompRef[] =>
  filterVisibleComponents(
    dsRead.components.get.byType(compType, rootComp),
    dsRead,
  );

const getComponentsByType_DEPRECATED_BAD_PERFORMANCE = (
  compType: string,
  rootComp: CompRef,
  dsRead: DSRead,
): CompRef[] =>
  filterVisibleComponents(
    dsRead.deprecatedOldBadPerformanceApis.components.get.byType(
      compType,
      rootComp,
    ),
    dsRead,
  );

const isReferredComponent = (compRef: CompRef): boolean =>
  coreUtilsLib.displayedOnlyStructureUtil.isRefPointer(compRef);

const isReferredId = (id: string): boolean =>
  coreUtilsLib.displayedOnlyStructureUtil.isReferredId(id);

const isRefComponent = (compRef: CompRef, dsRead: DSRead): boolean =>
  isRefComponent_inner(dsRead, compRef);

const isInternalRef = (refCompPtr: CompRef, dsRead: DSRead) =>
  dsRead.components.data.get(refCompPtr)?.type === 'InternalRef';

const isGhostRefComponent = (compRef: CompRef, dsRead: DSRead): boolean =>
  isGhostRefComponent_inner(dsRead, compRef);

export {
  getCompType,
  getCompTypeSuffix,
  getContainer,
  isStylable,
  isRepeaterItem,
  isChildOfControllingParent,
  getCompsData,
  getBehaviors,
  getProperties,
  getData,
  getDesign,
  getStyle,
  getSkin,
  getComponentSkinExports,
  getStyleId,
  getIsComponentVisibleInCurrentMode,
  getPlatformDisplayName,
  isDescendantOfAppWidget,
  isDescendantOfBlocksWidget,
  someDescendantIsPrimaryConnectedToAppWidget,
  isAppWidget,
  isWidgetInWidget,
  isStateBox,
  getNonAppWidgetComp,
  isDirectDescendantOfAppWidget,
  getAppContainerIfExists,
  getFirstAppWidget,
  resolveSelectedComponentForBackgroundSettings,
  getNickname,
  getSecondaryNickname,
  getInnerNickname,
  filterVisibleComponents,
  getComponentsByType,
  getComponentsByType_DEPRECATED_BAD_PERFORMANCE,
  isReferredComponent,
  isReferredId,
  isRefComponent,
  isInternalRef,
  isGhostRefComponent,
  hasResponsiveLayout,
  getPrimaryConnectionToAppWidget,
  shouldStartDesignPanelOpened,
};
