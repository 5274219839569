// @ts-nocheck
import constants from '@/constants';
import { isMeshLayoutEnabled } from '@/layout';
import { panels } from '@/stateManagement';
import type { BeforePlugin } from 'types/editorPlugins';

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
) => {
  const compLayout = compDef.layout;

  if (compLayout.fixedPosition) {
    compLayout.docked.right.px += constants.SECTIONS_REORGANIZE.STRIP_WIDTH;
    compLayout.docked.right.px += editorAPI.isVerticalScrollbarHidden()
      ? 0.01 * window.innerWidth
      : constants.SECTIONS_REORGANIZE.STRIP_WIDTH;
    delete compLayout.x;
    delete compLayout.y;
  }

  const compRef = originalAddComponentFunc(containerRef, compDef);

  editorAPI.store.dispatch(
    panels.actions.openComponentPanel(
      constants.PANELS.ANCHOR.ANCHORS_MENU_MANAGE_PANEL,
    ),
  );

  if (isMeshLayoutEnabled()) {
    return {
      compRef,
      onCompAddCallback,
    };
  }

  if (onCompAddCallback) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      onCompAddCallback(compRef);
    });
  }

  return { compRef };
};

export default plugin;
