import {
  ensureDockingDirectionIsValid,
  isDockingDirectionAllowed,
} from './ensureDockingDirectionIsValid';
import { DOCKING_DIRECTIONS_LIST } from './constants';
import type { PopupContainerProperties } from 'types/documentServices';
import type { PinDockingDirection, PinDockingOptions } from '../types';

const alignmentsByDirection = new Map<
  PinDockingDirection,
  Pick<PopupContainerProperties, 'verticalAlignment' | 'horizontalAlignment'>
>([
  ['TOP_LEFT', { verticalAlignment: 'top', horizontalAlignment: 'left' }],
  ['TOP', { verticalAlignment: 'top', horizontalAlignment: 'center' }],
  ['TOP_RIGHT', { verticalAlignment: 'top', horizontalAlignment: 'right' }],

  ['LEFT', { verticalAlignment: 'center', horizontalAlignment: 'left' }],
  ['MIDDLE', { verticalAlignment: 'center', horizontalAlignment: 'center' }],
  ['RIGHT', { verticalAlignment: 'center', horizontalAlignment: 'right' }],

  ['BOTTOM_LEFT', { verticalAlignment: 'bottom', horizontalAlignment: 'left' }],
  ['BOTTOM', { verticalAlignment: 'bottom', horizontalAlignment: 'center' }],
  [
    'BOTTOM_RIGHT',
    { verticalAlignment: 'bottom', horizontalAlignment: 'right' },
  ],
]);

export function createPopupContainerPropertiesByPinDockingDirection(
  alignmentType: PopupContainerProperties['alignmentType'],
  {
    dockingDirection,
    dockingOffsets: { horizontalOffset, verticalOffset },
  }: PinDockingOptions,
): Partial<PopupContainerProperties> {
  ensureDockingDirectionIsValid(dockingDirection, {
    isFullWidth: alignmentType === 'fullWidth',
    isFullHeight: alignmentType === 'fullHeight',
  });

  const { verticalAlignment, horizontalAlignment } =
    alignmentsByDirection.get(dockingDirection);

  return {
    alignmentType,
    horizontalAlignment,
    horizontalOffset,
    verticalAlignment,
    verticalOffset,
  };
}

export function getPopupContainerPropertiesDockingDirection(
  properties: PopupContainerProperties,
) {
  const isFullWidth = properties.alignmentType === 'fullWidth';
  const isFullHeight = properties.alignmentType === 'fullHeight';

  return DOCKING_DIRECTIONS_LIST.find((dockingDirection) => {
    const directionAllignment = alignmentsByDirection.get(dockingDirection);

    if (
      !isDockingDirectionAllowed(dockingDirection, {
        isFullWidth,
        isFullHeight,
      })
    ) {
      return false;
    }

    if (isFullWidth) {
      return (
        directionAllignment.verticalAlignment === properties.verticalAlignment
      );
    }

    if (isFullHeight) {
      return (
        directionAllignment.horizontalAlignment ===
        properties.horizontalAlignment
      );
    }

    return (
      directionAllignment.verticalAlignment === properties.verticalAlignment &&
      directionAllignment.horizontalAlignment === properties.horizontalAlignment
    );
  });
}

export function getPopupContainerPropertiesDockingOffsets(
  properties: PopupContainerProperties,
) {
  return {
    horizontalOffset: properties.horizontalOffset,
    verticalOffset: properties.verticalOffset,
  };
}
