// @ts-nocheck
import _ from 'lodash';
import experiment from 'experiment';
import constants from '@/constants';
import { editorDataResolver } from '@wix/editor-component-data-resolver';
import { selectOpenPanels } from '../panels/panelsSelectors';
import * as componentsSelectors from './componentsSelectors';
import { EditorParamsApiKey } from '@/apis';

import type { CompRef } from 'types/documentServices';
import type {
  EditorDataFunction,
  ComponentParamResolver,
  ComponentInstanceDataKey,
  ComponentEnvironmentDataKey,
} from '@wix/editor-component-data-resolver';

const {
  ENVIRONMENT,
  EXPERIMENT,
  COMPONENT_INSTANCE_DATA,
  IS_COMPONENT_PANEL_OPENED,
} = editorDataResolver;
const {
  getSkin,
  getData,
  getStyle,
  getProperties,
  isDescendantOfBlocksWidget,
  isReferredComponent,
} = componentsSelectors;

// check arg index usage
const getInstanceDataByKey = (
  key: ComponentInstanceDataKey,
  param,
  editorAPI,
  compPointer: CompRef | CompRef[],
) => {
  if (Array.isArray(compPointer)) {
    compPointer = compPointer[0];
  }
  const { dsRead } = editorAPI;
  switch (key) {
    case 'componentProperties': // move consts to wix-ui-santa
      return getProperties(compPointer, dsRead);
    case 'componentData':
      return getData(compPointer, dsRead);
    case 'componentStyle':
      return getStyle(compPointer, dsRead);
    case 'skin':
      return getSkin(compPointer, dsRead);
    case 'isWidgetPage':
      return !!compPointer && !dsRead.pages.isWidgetPage(compPointer.id);
    case 'isMultiColumnsStrip':
      return editorAPI.columns.isMultiColumnsStrip(compPointer);
    case 'isColumn':
      return editorAPI.columns.isColumn(compPointer);
    case 'tpaAppData':
      const { applicationId } =
        editorAPI.components.data.get(compPointer) || {};
      return dsRead.tpa.app.getData(applicationId) || {};
    case 'isComponentSelected':
      return editorAPI.selection.isComponentSelected(compPointer);
    case 'isRepeaterItem':
      return editorAPI.components.is.repeaterItem(compPointer);
    case 'activeModeIds':
      return editorAPI.components.modes.getComponentActiveModeIds(compPointer);
    case 'isPopup':
      return dsRead.pages.popupPages.isPopup(compPointer.id);
    case 'isDescendantOfBlocksWidget':
      return isDescendantOfBlocksWidget(compPointer, dsRead);
    case 'isReferredComponent':
      return isReferredComponent(compPointer);
    default:
      throw new Error(`Unknown metadata key: ${key}`);
  }
};

const getEnvironmentDataByKey = (
  key: ComponentEnvironmentDataKey,
  editorAPI,
) => {
  switch (key) {
    case 'isMobileEditor':
      return editorAPI.isMobileEditor();
    case 'supportedFeatures':
      return editorAPI.media.mediaState.getSupportedFeatures();
    case 'qabActions':
      return editorAPI.mobile.qab.getActions();
    case 'premiumFeatures':
      return editorAPI.premiumFeatures.get();
    case 'isWixCodeLoaded':
      return editorAPI.wixCode.isLoaded();
    case 'isInsideBlocks':
      return editorAPI.host.getAPI(EditorParamsApiKey).isInsideAppStudio;
    case 'isCurrentLanguageSecondary':
      return editorAPI.language.isCurrentLanguageSecondary();
    case 'isInsideEditorX':
      return editorAPI.host.getAPI(EditorParamsApiKey).isInsideEditorX;
    default:
      throw new Error(`Unknown environment key: ${key}`);
  }
};

const getPanelState = (panelName, editorAPI) => {
  const openedPanels = selectOpenPanels(editorAPI.store.getState());

  return openedPanels.find((panel) => {
    const shortName = [].concat(panel.name.split('.')).pop();

    return (
      panelName === panel.name ||
      constants.componentPanels[panelName] === shortName
    );
  });
};

const getEditorData = (
  f: EditorDataFunction,
  editorAPI,
  ...args: [CompRef | CompRef[]]
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const metadataArgs = _.map(
    f.parameters,
    (parameter: ComponentParamResolver) => {
      switch (parameter.type) {
        case ENVIRONMENT:
          return getEnvironmentDataByKey(parameter.key, editorAPI);
        case EXPERIMENT:
          return experiment.isOpen(parameter.name);
        case COMPONENT_INSTANCE_DATA:
          return getInstanceDataByKey(
            parameter.key,
            (parameter as AnyFixMe).param,
            editorAPI,
            ...args,
          );
        case IS_COMPONENT_PANEL_OPENED:
          return getPanelState(parameter.key, editorAPI);
        default:
          throw new Error(
            `Unknown metadata parameter type ${(parameter as AnyFixMe).type}`,
          );
      }
    },
  );

  return f(...metadataArgs);
};

export { getEditorData };
