import _ from 'lodash';
import { domMeasurements } from '@/stateManagement';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';
import type { LayoutConstraint } from '../types';

function getLayoutDiff(newLayout: Partial<Rect>, oldLayout: Partial<Rect>) {
  return _(newLayout)
    .pick(['x', 'y', 'width', 'height'])
    .mapValues(function (value, key) {
      return value - oldLayout[key as keyof typeof oldLayout];
    })
    .pickBy(function (value) {
      return value !== 0;
    })

    .value();
}

const MAX_MARGIN_AS_DEFINED_BY_PRODUCT = 80;

const isMeaningfulChange = (value: number) => Math.abs(value) >= 1;

function applyHorizontallyStretchedToScreenConstraints(
  editorAPI: EditorAPI,
  compPointer: CompRef,
  newLayout: Partial<Rect>,
  oldLayout: Partial<Rect>,
): void {
  const state = editorAPI.store.getState();
  const previewPosition = domMeasurements.selectors.getPreviewPosition(state);
  const previewWidth = previewPosition.width;
  const changedLayoutProperties = getLayoutDiff(newLayout, oldLayout);

  let distanceFromRestriction: number;

  if (isMeaningfulChange(changedLayoutProperties.x)) {
    const left = newLayout.x;
    const leftMaxValue = MAX_MARGIN_AS_DEFINED_BY_PRODUCT;
    distanceFromRestriction = _.min([leftMaxValue - left, 0]);

    if (distanceFromRestriction) {
      newLayout.x += distanceFromRestriction;
    }
  }

  if (changedLayoutProperties.width) {
    const right = newLayout.x + newLayout.width;
    const rightMinValue = previewWidth - MAX_MARGIN_AS_DEFINED_BY_PRODUCT;
    distanceFromRestriction = _.max([rightMinValue - right, 0]);

    if (distanceFromRestriction) {
      newLayout.width += distanceFromRestriction;
    }
  }
}

const constraint: LayoutConstraint = {
  shouldConstrain(editorAPI, compPointer) {
    return editorAPI.components.layout.isHorizontallyStretchedToScreen(
      compPointer,
    );
  },
  applyConstraint: applyHorizontallyStretchedToScreenConstraints,
};

export default constraint;
