// @ts-nocheck
import _ from 'lodash';

function isWixEmail(email) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/ends-with
  return _.endsWith(email, '@wix.com');
}

function updateDataWithDefaults(data, editorAPI) {
  const fieldName = 'toEmailAddress';
  const emailFieldValue = data[fieldName];
  const userEmail = editorAPI.dsRead.generalInfo.getUserInfo().email;

  if (emailFieldValue || isWixEmail(userEmail)) {
    return;
  }
  data[fieldName] = userEmail;
}

export { updateDataWithDefaults };
