import _ from 'lodash';
import { pinModeUtils } from '@/util';
import { ensureDockingDirectionIsValid } from './ensureDockingDirectionIsValid';

import type { CompLayout, Docking } from 'types/documentServices';
import type { PinDockingOptions, PinDockingOuterOffsets } from '../types';

export const getDockingDockingDirection = pinModeUtils.getDockedDirection;

export function getDockingWithRotationOffsets({
  compLayout,
  compDocking,
}: {
  compLayout: CompLayout;
  compDocking: Docking;
}): Docking {
  return pinModeUtils.getAdditionOfDiffBoundingAndOriginalLayout(
    compDocking,
    pinModeUtils.getDiffBetweenBoundingAndOriginalLayout(compLayout),
  );
}

export function createLayoutDockingByPinDockingDirection(
  compLayout: CompLayout,
  { dockingDirection, dockingOffsets }: PinDockingOptions,
) {
  ensureDockingDirectionIsValid(dockingDirection);

  let compDocking = _.cloneDeep(pinModeUtils.dockMap[dockingDirection]);

  if (typeof dockingOffsets?.horizontalOffset === 'number') {
    compDocking = pinModeUtils.updateHorizontalOffset(
      compDocking,
      dockingOffsets.horizontalOffset,
    );
  }

  if (typeof dockingOffsets?.verticalOffset === 'number') {
    compDocking = pinModeUtils.updateVerticalOffset(
      compDocking,
      dockingOffsets.verticalOffset,
    );
  }

  return getDockingWithRotationOffsets({
    compLayout,
    compDocking,
  });
}

export function getDockingDockingOuterOffsets({
  compLayout,
  compDocking,
}: {
  compLayout: CompLayout;
  compDocking: Docking;
}): PinDockingOuterOffsets | null {
  if (!compLayout || !compDocking) {
    return null;
  }

  const decreaseHeight = (compLayout.bounding.height - compLayout.height) / -2;
  const decreaseWidth = (compLayout.bounding.width - compLayout.width) / -2;

  compDocking = pinModeUtils.updateVerticalOffset(
    compDocking,
    pinModeUtils.getVerticalOffset(compDocking),
    decreaseHeight,
  );

  compDocking = pinModeUtils.updateHorizontalOffset(
    compDocking,
    pinModeUtils.getHorizontalOffset(compDocking),
    decreaseWidth,
  );

  return {
    horizontalOffset: pinModeUtils.getHorizontalOffset(compDocking),
    verticalOffset: pinModeUtils.getVerticalOffset(compDocking),
  };
}
