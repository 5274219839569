import type { PathsState } from '@wix/add-panel-component';

export enum ActionType {
  SetPathsState = '@newAddPanel/setPathsState',
}

interface SetPathsStateAction {
  type: ActionType.SetPathsState;
  payload: {
    pathsState: PathsState;
  };
}

export type NewAddPanelAction = SetPathsStateAction;
