import * as util from '@/util';
import { translate } from '@/i18n';
import { events } from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';

const warnBeforeLeave = (event: AnyFixMe, editorAPI: EditorAPI) => {
  editorAPI.bi.event(events.desktopBrowserActions.WARN_BEFORE_LEAVE_POPS_UP);

  const confirmationMessage = translate('GENERAL_Close_Warning_Message');
  (event || window.event).returnValue = confirmationMessage; // Gecko and Trident
  return confirmationMessage; // Gecko and WebKit
};

const setLeavePopup =
  (shouldShow: boolean) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const isDisabled =
      util.url.getParameterByName('leavePagePopUp') === 'false';

    if (!isDisabled && shouldShow) {
      window.onbeforeunload = (event: AnyFixMe) =>
        warnBeforeLeave(event, editorAPI);
    } else {
      window.onbeforeunload = null;
    }
  };

export { setLeavePopup };
