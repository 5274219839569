// @ts-nocheck
import panelsActionTypes from './helpPanelActionTypes';

const { OPEN_HELP_CENTER, CLOSE_HELP_CENTER } = panelsActionTypes;

const closeHelpCenter = () => ({
  type: CLOSE_HELP_CENTER,
});

const openHelpCenter = (helpPanelProps) => ({
  type: OPEN_HELP_CENTER,
  helpPanelProps,
});

export { openHelpCenter, closeHelpCenter };
