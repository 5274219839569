export default {
  OPEN_PANEL: 'openPanel',
  UPDATE_PANEL: 'updatePanel',
  OPEN_DOCKED_PANEL: 'openDockedPanel',
  CLOSE_ALL_PANELS: 'closeAllPanels',
  CLOSE_PANEL_BY_NAME: 'closePanelByName',
  CLOSE_PANEL_BY_PROP: 'closePanelByProp',
  ADD_PANEL_FILTER: 'ADD_PANEL_FILTER',
  REMOVE_PANEL_FILTER: 'REMOVE_PANEL_FILTER',
} as const;
