import type { ComponentProperties } from 'types/documentServices';
import type { LayoutFullWidthValue } from '@/layout';

export function getStripPropertiesFromFullWidthValue({
  isFullWidth,
  siteMargin,
}: LayoutFullWidthValue): Partial<ComponentProperties> {
  if (!isFullWidth) {
    return {
      fullWidth: false,
    };
  }

  if (siteMargin.type !== 'px') {
    throw new Error('`siteMargin.type` for strip must be "px"');
  }

  return {
    fullWidth: true,
    siteMargin: siteMargin.value,
  };
}

export function getFullWidthValueFromStripProperties(
  compProperties: Partial<ComponentProperties>,
): LayoutFullWidthValue {
  return compProperties.fullWidth
    ? {
        isFullWidth: true,
        siteMargin: {
          type: 'px',
          value: compProperties.siteMargin,
        },
      }
    : {
        isFullWidth: false,
      };
}
