// @ts-nocheck
import _ from 'lodash';
import type { BeforePlugin } from 'types/editorPlugins';

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
) => {
  const { svgId } = compDef.data;
  editorAPI.media.vectorImage.fetchSvg(svgId, _.noop);
};

export default plugin;
