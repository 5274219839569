import { fedopsLogger } from '@/util';

import * as pinModeActions from '../pinMode/pinModeActions';
import { interactionsActions } from '../interactions/interactionsActions';
import { editorSearchActionTypes } from './editorSearchActionTypes';

import type { EditorAPI } from '@/editorAPI';

const openSearchPanel = (origin: AnyFixMe) => ({
  type: editorSearchActionTypes.OPEN_SEARCH_PANEL,
  origin,
});

const closeEditorSearchPanel = () => ({
  type: editorSearchActionTypes.CLOSE_SEARCH_PANEL,
});

interface OpenEditorSearchPanelConfig {
  origin?: string;
  shouldLeavePanelsOpen?: boolean;
}

const openEditorSearchPanel =
  ({ origin, shouldLeavePanelsOpen }: OpenEditorSearchPanelConfig = {}) =>
  (
    dispatch: AnyFixMe,
    _getState: AnyFixMe,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    if (!shouldLeavePanelsOpen) {
      editorAPI.panelManager.closeAllPanels();
    }

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.EDITOR_SEARCH.OPEN_PANEL,
    );
    // Exits LightBox mode
    if (editorAPI.dsRead.pages.popupPages.isPopupOpened()) {
      editorAPI.pages.popupPages.close();
    }

    dispatch(pinModeActions.close());
    interactionsActions.exitInteractionModeIfNeeded(editorAPI);

    dispatch(openSearchPanel(origin));
  };

export const editorSearchActions = {
  openEditorSearchPanel,
  closeEditorSearchPanel,
  enable: () => ({ type: editorSearchActionTypes.ENABLE }),
  disable: () => ({ type: editorSearchActionTypes.DISABLE }),
};
