import type { Docking } from 'types/documentServices';

// source https://github.com/wix-private/santa-core/blob/f1a5a0d8e67d5d606cd1983a47a56794e4f12238/santa-core-utils/src/coreUtils/core/layoutUtils.js#L77

function isVerticallyStretchedToScreen(docked: Docking | undefined) {
  // @ts-expect-error
  return Boolean(docked?.bottom?.vh && docked?.top?.vh);
}

export function isHorizontallyStretchedToScreen(docked: Docking | undefined) {
  return Boolean(docked?.left?.vw && docked?.right?.vw);
}

export function isDockToScreen(docked: Docking | undefined) {
  return (
    isVerticallyStretchedToScreen(docked) ||
    isHorizontallyStretchedToScreen(docked)
  );
}
