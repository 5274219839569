import _ from 'lodash';
import servicesActionTypes from './servicesActionTypes';

const { SET_STATE, UPDATE_VIEWER_RENDERS_COUNTER } = servicesActionTypes;

const setStateReducer = (state: AnyFixMe = {}, action: AnyFixMe) => {
  switch (action.type) {
    case SET_STATE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, state, action.state);
    case UPDATE_VIEWER_RENDERS_COUNTER:
      return _.defaults(
        { viewerRendersCounter: (state.viewerRendersCounter + 1) % 16 },
        state,
      );
    default:
      return state;
  }
};

export default setStateReducer;
