import constants from '@/constants';
import { browserUtil, mouseCoordinatesHandler, workspace } from '@/util';

import { isMobileEditor } from '../mobile/mobileSelectors';

import type { MouseEvent as ReactMouseEvent } from 'react';
import type { EditorState } from '../store/editorState';
import type { EditorAPI } from '@/editorAPI';
import type {
  PreviewPosition,
  StageLayout,
  Viewport,
} from './domMeasurements.types';

const getDomMeasurements = (state: EditorState) => state.domMeasurements;
const getStageLayout = (state: EditorState): StageLayout =>
  state.domMeasurements.stageLayout;
const getEditorContentLayout = (state: EditorState) =>
  state.domMeasurements.editorContentLayout;
const getPreviewPosition = (state: EditorState): PreviewPosition =>
  state.domMeasurements.previewPosition;
const getScrollMeasurements = (state: EditorState) =>
  state.domMeasurements.scroll;
const getStageRect = (state: EditorState) => state.domMeasurements.stageRect;
const getSiteScale = (state: EditorState) => state.siteScale;

const translateToViewerCoordinates = <E extends MouseEvent | ReactMouseEvent>(
  editorAPI: EditorAPI,
  e: E,
) => {
  const state = editorAPI.store.getState();
  const scroll = editorAPI.scroll.get();
  return mouseCoordinatesHandler.fitMouseCoordinatesToViewer(
    getSiteScale(state),
    getStageLayout(state),
    scroll,
    getPreviewPosition(state),
    e,
  );
};

const translatePositionToViewerCoordinates = (
  editorAPI: EditorAPI,
  position: AnyFixMe,
) => {
  const state = editorAPI.store.getState();
  const scroll = editorAPI.scroll.get();
  return mouseCoordinatesHandler.translatePositionToViewerCoordinates(
    getSiteScale(state),
    getStageLayout(state),
    scroll,
    getPreviewPosition(state),
    position,
  );
};

const getMousePosition = (editorAPI: EditorAPI, e: AnyFixMe) => {
  const translatedCoordinates = translateToViewerCoordinates(editorAPI, e);

  return {
    x: translatedCoordinates.pageX,
    y: translatedCoordinates.pageY,
    isShiftPressed: e.shiftKey,
    isAltKeyPressed: e.altKey,
    isSpecialKeyPressed: browserUtil.isSpecialKeyPressed(e),
  };
};

const getViewPort = (
  state: EditorState,
  relativeToScroll?: boolean,
  leftButtonsBoundingRect?: AnyFixMe,
): Viewport | null => {
  const isNewWorkspace = workspace.isNewWorkspaceEnabled();
  const isMobile = isMobileEditor(state);
  const verticalMargins = isMobile ? 0 : constants.UI.VIEWPORT_VERTICAL_MARGINS;

  const margins = {
    top: verticalMargins,
    bottom: verticalMargins,
    left: isNewWorkspace ? constants.UI.VIEWPORT_HORIZONTAL_MARGINS : 72,
    right: isNewWorkspace ? constants.UI.VIEWPORT_HORIZONTAL_MARGINS : 60,
  };

  const stageLayout = getStageLayout(state);
  if (!stageLayout) {
    return null;
  }
  const editorScroll = getScrollMeasurements(state);

  const viewPort = {
    y: margins.top,
    minY: margins.top,
    bottomY: stageLayout.height - margins.bottom,
    maxBottomY: editorScroll.scrollHeight - margins.bottom,
    marginTop: margins.top,
    marginBottom: margins.bottom,
    x: margins.left,
    rightX: stageLayout.width - margins.right,
    height: stageLayout.height - (margins.top + margins.bottom),
    width: stageLayout.width - (margins.left + margins.right),
    scrollTop: editorScroll.scrollTop,
    scrollLeft: editorScroll.scrollLeft,
    stageLayout,
  };

  if (relativeToScroll) {
    viewPort.y += editorScroll.scrollTop;
    viewPort.bottomY += editorScroll.scrollTop;
    viewPort.x += editorScroll.scrollLeft;
    viewPort.rightX += editorScroll.scrollLeft;
  }

  const leftBarButtonsRight =
    leftButtonsBoundingRect && leftButtonsBoundingRect.right + 10;
  if (leftBarButtonsRight && leftBarButtonsRight > viewPort.x) {
    const diffBetweenLeftBarAndViewPortLeft = leftBarButtonsRight - viewPort.x;
    viewPort.x = leftBarButtonsRight;
    viewPort.width -= diffBetweenLeftBarAndViewPortLeft;
  }

  return viewPort;
};

export {
  getDomMeasurements,
  getPreviewPosition,
  getSiteScale,
  getStageLayout,
  getScrollMeasurements,
  getStageRect,
  getViewPort,
  getMousePosition,
  translateToViewerCoordinates,
  translatePositionToViewerCoordinates,
  getEditorContentLayout,
};
