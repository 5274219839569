const SET_COLLECTION_ACTION = '@saved-components/set-collection-action';
const ADD_ITEM_ACTION = '@saved-components/add-item-action';
const ADD_OPTIMISTIC_ITEM_ACTION =
  '@saved-components/add-optimistic-item-action';
const CLEAN_OPTIMISTIC_ITEM_ACTION =
  '@saved-components/clean-optimistic-item-action';
const UPDATE_ITEM_ACTION = '@saved-components/update-item-action';
const DELETE_ITEM_ACTION = '@saved-components/delete-item-action';

export {
  SET_COLLECTION_ACTION,
  ADD_ITEM_ACTION,
  ADD_OPTIMISTIC_ITEM_ACTION,
  CLEAN_OPTIMISTIC_ITEM_ACTION,
  UPDATE_ITEM_ACTION,
  DELETE_ITEM_ACTION,
};
