// @ts-nocheck
import _ from 'lodash';
import * as compsSectionsAnalyzer from './compsSectionsAnalyzer';

function getSectionTop(section) {
  return section.position.top;
}

function getSectionHeight(section) {
  return section.position.bottom - section.position.top;
}

function shiftComps(editorAPI, comps, offset) {
  comps.forEach((comp) => {
    const compLayout = editorAPI.components.layout.get_position(comp);
    editorAPI.components.layout.update(
      comp,
      { y: compLayout.y + offset },
      true,
    );
  });
}

function getSectionSizeForBI(height) {
  if (height > 500) {
    return 'large';
  }
  if (height > 61) {
    return 'medium';
  }
  return 'small';
}

function isAtSectionsSwitchPoint(
  draggedSectionLayout,
  possibleSwitchedSection,
  dragDirection,
) {
  const switchedSectionHeight = getSectionHeight(possibleSwitchedSection);
  const switchedSectionMiddle =
    possibleSwitchedSection.position.top + switchedSectionHeight / 2;
  if (dragDirection === 1) {
    return (
      draggedSectionLayout.y + draggedSectionLayout.height >=
      switchedSectionMiddle
    );
  }

  return draggedSectionLayout.y <= switchedSectionMiddle;
}

function switchSectionsPositions(topSection, bottomSection) {
  const origTopSectionPos = _.clone(topSection.position);
  const origBottomSectionPos = _.clone(bottomSection.position);

  const topH = getSectionHeight(topSection);
  const bottomH = getSectionHeight(bottomSection);
  const deltaH = topH - bottomH;

  topSection.position.top = origBottomSectionPos.top - deltaH;
  topSection.position.bottom = topSection.position.top + topH;

  bottomSection.position.top = origTopSectionPos.top;
  bottomSection.position.bottom = bottomSection.position.top + bottomH;
}

function switchSectionsIndices(sections, sectionA, sectionB) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const topSectionIndex = _.findIndex(sections, sectionA);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const bottomSectionIndex = _.findIndex(sections, sectionB);

  sections[topSectionIndex] = sectionB;
  sections[bottomSectionIndex] = sectionA;
}

function switchSectionsByDrag(
  editorAPI,
  sections,
  draggedSection,
  switchedSection,
) {
  let topSection, bottomSection;
  if (draggedSection.position.top < switchedSection.position.top) {
    topSection = draggedSection;
    bottomSection = switchedSection;
  } else {
    topSection = switchedSection;
    bottomSection = draggedSection;
  }

  const origTopSectionTop = getSectionTop(topSection);
  const origBottomSectionTop = getSectionTop(bottomSection);

  switchSectionsPositions(topSection, bottomSection);
  switchSectionsIndices(sections, topSection, bottomSection);

  if (switchedSection === bottomSection) {
    // dragging downwards
    shiftComps(
      editorAPI,
      switchedSection.comps,
      getSectionTop(bottomSection) - origBottomSectionTop,
    );
  } else {
    // dragging upwards
    shiftComps(
      editorAPI,
      switchedSection.comps,
      getSectionTop(topSection) - origTopSectionTop,
    );
  }
}

function switchSections(editorAPI, sections, topSection, bottomSection) {
  const origTopSectionTop = getSectionTop(topSection);
  const origBottomSectionTop = getSectionTop(bottomSection);

  switchSectionsPositions(topSection, bottomSection);
  switchSectionsIndices(sections, topSection, bottomSection);

  shiftComps(
    editorAPI,
    bottomSection.comps,
    getSectionTop(bottomSection) - origBottomSectionTop,
  );
  shiftComps(
    editorAPI,
    topSection.comps,
    getSectionTop(topSection) - origTopSectionTop,
  );
}

function shiftSection(editorAPI, section, offset) {
  section.position.top += offset;
  section.position.bottom += offset;

  if (section.comps) {
    shiftComps(editorAPI, section.comps, offset);
  }
}

function getPageSections(editorAPI, page) {
  page = page || editorAPI.pages.getFocusedPage();

  if (editorAPI.dsRead.pages.isWidgetPage(page.id)) {
    return [];
  }

  let sections = compsSectionsAnalyzer.analyzeContainer(editorAPI, page);
  const pageLayout = editorAPI.components.layout.getRelativeToScreen(page);

  sections.forEach((section) => {
    section.position.top += pageLayout.y;
    section.position.bottom += pageLayout.y;
  });

  if (editorAPI.isMobileEditor()) {
    // siteRegionContainers are on master page, their layout position is already up-to-date
    sections = _.union(
      getSiteRegionContainerSections(editorAPI, 'MOBILE'),
      sections,
    );
    return _.sortBy(sections, 'position.top');
  }

  return sections;
}

function getHeaderSection(editorAPI) {
  const header = editorAPI.components.layout.getRelativeToScreen(
    editorAPI.dsRead.siteSegments.getHeader(),
  );
  return {
    position: {
      top: header.y,
      bottom: header.height,
    },
  };
}

function getSiteRegionContainerSections(editorAPI, viewMode) {
  const masterPagePointer =
    editorAPI.dsRead.siteSegments.getSiteStructure(viewMode);

  const siteRegionContainers =
    editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.get
      .byType(
        'wysiwyg.viewer.components.SiteRegionContainer',
        masterPagePointer,
      )
      ?.filter(editorAPI.dsRead.components.is.exist) || [];
  if (!siteRegionContainers.length) {
    return [];
  }

  const siteRegionSections =
    compsSectionsAnalyzer.getSortedComponentsWithLayout(
      editorAPI,
      siteRegionContainers,
    );

  return siteRegionSections.map((siteRegionSection) => ({
    position: {
      top: siteRegionSection.layout.y,
      bottom: siteRegionSection.layout.bottomY,
    },
    comps: [siteRegionSection.comp],
    isStaticSection: true,
  }));
}

async function duplicateSection(editorAPI, sections, origSectionIdx) {
  const sectionToDuplicate = sections[origSectionIdx];
  const compsLayout = editorAPI.components.layout.getRelativeToStructure(
    sectionToDuplicate.comps,
  );
  const origSectionHeight = getSectionHeight(sectionToDuplicate);

  for (let i = origSectionIdx + 1; i < sections.length; i++) {
    shiftSection(editorAPI, sections[i], origSectionHeight);
  }

  const duplicatedComps = await editorAPI.copyPaste.duplicate(
    sectionToDuplicate.comps,
  );
  editorAPI.dsActions.waitForChangesApplied(function () {
    editorAPI.components.layout.updateRelativeToStructure(duplicatedComps, {
      x: compsLayout.x,
      y: sectionToDuplicate.position.bottom,
    });
  });
}

function deleteSection(editorAPI, sections, deletedSectionIndex) {
  const deletedSection = sections[deletedSectionIndex];
  const deletedSectionHeight = getSectionHeight(deletedSection);

  for (let i = deletedSectionIndex + 1; i < sections.length; i++) {
    shiftSection(editorAPI, sections[i], -1 * deletedSectionHeight);
  }

  return editorAPI.components.remove(deletedSection.comps);
}

export default {
  addSectionByPosition(sections, sectionToAdd) {
    const insertAt = sections.findIndex(
      (section) => section.position.top > sectionToAdd.position.top,
    );

    if (insertAt) {
      sections.splice(insertAt, 0, sectionToAdd);
    } else {
      sections.push(sectionToAdd);
    }

    return sections;
  },
  getSectionSizeForBI,
  getPageSections,
  getHeaderSection,
  duplicateSection,
  deleteSection,
  shiftSection,
  switchSectionsByDrag,
  switchSections,
  isAtSectionsSwitchPoint,
};
