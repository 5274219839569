// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import { translate } from '@/i18n';
import * as localModeSelectors from './localModeSelectors';
import localPushWrapper from './localPushWrapper';
import localSave from './localSave';
import isSavePublishOrPushAllowed from './isSavePublishOrPushAllowed';
import * as coreBi from '@/coreBi';

const { MENU_BAR_ITEMS, SETTINGS_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;
const { MODES } = constants.ROOT_COMPS.TOPBAR;
const { getPushingStatus } = localModeSelectors;

const progressButtonClassNames = {
  [constants.PROGRESS_STATUS.IDLE_SAVED]: 'top-bar-btn-idle-saved',
  [constants.PROGRESS_STATUS.IN_PROGRESS]: 'top-bar-btn-in-progress-indicator',
  [constants.PROGRESS_STATUS.DONE_SUCCESS]: 'top-bar-btn-done-successfully',
};

const EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES = {
  content: {
    right: '6px',
  },
};

const isDoneSuccess = (status) =>
  status === constants.PROGRESS_STATUS.DONE_SUCCESS;

const overriddenCodeMenu = {
  key: MENU_BAR_ITEMS.CODE,
  devToolsButtonEnabled: false,
};

const saveButtonOverride = ({ state, editorAPI }) => ({
  key: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
  label: translate('Topbar_Local_Save'),
  disabled: !isSavePublishOrPushAllowed(state),
  dropPanelData: {
    title: 'Topbar_Local_Save_Tooltip_Title',
    body: 'Topbar_Local_Save_Tooltip_Text',
    linkText: null,
    linkAction: null,
  },
  onClick() {
    localSave({ origin: 'topbar', editorAPI });
    editorAPI.bi.event(coreBi.events.localSave.localSaveClick, {
      initiatorName: 'manual',
    });
    editorAPI.topBarMenuBar.closeDropDown(
      constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SAVE,
    );
  },
});

const getLocalPushButton = ({ state, editorAPI }) => {
  const _localPushWrapper = localPushWrapper({ editorAPI });
  return {
    key: constants.ROOT_COMPS.TOPBAR.BUTTONS.LOCAL_PUSH,
    label: translate('Topbar_Local_Push_Version'),
    customSymbol: isDoneSuccess(getPushingStatus(state)) ? 'vi' : null,
    automationId: 'top-bar-button-local-push',
    ref: 'localPushButton',
    className: [
      'top-bar-btn-push',
      'top-bar-edit-mode-last-btn',
      progressButtonClassNames[getPushingStatus(state)] || '',
    ].join(' '),
    MODES: [MODES.EDITOR],
    onClick() {
      _localPushWrapper.localPush({ origin: 'top_bar_local_push' });
      editorAPI.bi.event(coreBi.events.push.pushClick, {});
      editorAPI.topBarMenuBar.closeDropDown(
        constants.ROOT_COMPS.TOPBAR.DROP_PANELS.LOCAL_PUSH,
      );
    },
    __isExtraItem: true,
    bubbleStyleOverrides: EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
    disabled: !isSavePublishOrPushAllowed(state),
    dropPanelData: {
      title: 'Topbar_Local_Push_Version_Tooltip_Title',
      body: 'Topbar_Local_Push_Version_Tooltip_Text',
      linkText: null,
      linkAction: null,
    },
    onMouseEnter() {
      editorAPI.topBarMenuBar.openDropDown(
        constants.ROOT_COMPS.TOPBAR.DROP_PANELS.LOCAL_PUSH,
      );
    },
    onMouseLeave() {
      editorAPI.topBarMenuBar.closeDropDown(
        constants.ROOT_COMPS.TOPBAR.DROP_PANELS.LOCAL_PUSH,
      );
    },
  };
};

const getSettingsMenuOverrides = () => [
  { key: SETTINGS_MENU_ITEMS.ACCESSIBILITY },
  { key: SETTINGS_MENU_ITEMS.MOBILE_FRIENDLY },
  { key: SETTINGS_MENU_ITEMS.IMAGE_OPTIMIZATION },
  { key: SETTINGS_MENU_ITEMS.MULTILINGUAL },
  { key: 'separator', isSeparator: true, __isExtraItem: true },
  { key: SETTINGS_MENU_ITEMS.SITE_DASHBOARD },
];

const getRightButtonsOverrides = (state, editorAPI) => [
  { key: constants.ROOT_COMPS.TOPBAR.BUTTONS.ZOOM },
  { key: constants.ROOT_COMPS.TOPBAR.BUTTONS.UNDO },
  { key: constants.ROOT_COMPS.TOPBAR.BUTTONS.REDO },
  { key: constants.ROOT_COMPS.TOPBAR.BUTTONS.WIXSTORES_MIGRATION_UPGRADE_SITE },
  saveButtonOverride({ state, editorAPI }),
  { key: constants.ROOT_COMPS.TOPBAR.BUTTONS.PREVIEW },
  getLocalPushButton({ state, editorAPI }),
  { key: constants.ROOT_COMPS.TOPBAR.BUTTONS.BACK_TO_EDITOR },
];

const getOverriddenTopBarItems = (state) => {
  const overridenBaseState = state.topBar.overridden;

  const menus = [
    { key: MENU_BAR_ITEMS.SETTINGS },
    { key: MENU_BAR_ITEMS.TOOLS },
    overriddenCodeMenu,
    { key: MENU_BAR_ITEMS.HELP },
    { key: MENU_BAR_ITEMS.UPGRADE },
  ];

  const overrideGetters = {
    getMenuOverrides: () => menus,
    getSettingsMenuOverrides,
    getRightButtonsOverrides,
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign({}, overridenBaseState, { overrideGetters });
};

const overriddenWixCodeIdeData = { shouldShow: false };

const overriddenLeftTree = { shouldShow: false };

const overriddenDeveloperToolBar = {
  showOnlySavedEvents: true,
  isReadOnlyEvents: true,
  disableLinkToCode: true,
};

export {
  getOverriddenTopBarItems,
  overriddenWixCodeIdeData,
  overriddenLeftTree,
  overriddenDeveloperToolBar,
};
