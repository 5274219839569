export default {
  NEW_HELP_WIDGET_ID: '30fd5f57-eee2-4b02-a1f5-644e628d3e22',
  HELP_WIDGET_ID: 'cb6480f3-768b-4737-ab8f-10fe2e4fa2ab',
  WIXCODE_HELP_WIDGET_ID: '94bf341b-89bd-49dd-8090-53229756d111',
  DESKTOP_HELP_ITEM: '58406e7a-1ad0-4105-8e37-c053b529f9d8',
  MOBILE_HELP_ITEM: 'f8b24a99-cefd-4c55-adda-aa66ec643259',
  WIXCODE_DESKTOP_HELP_ITEM: 'b06a142e-7796-4fa3-9425-17c84894d10a',
  NEW_DESKTOP_HELP_PREFIX: '',
  NEW_MOBILE: 'dfe5a53a-a179-4013-9c85-af062b9874e1',
  NEW_WIXCODE_DESKTOP: 'a511b1d2-2b97-4b78-a67a-4ce6bc89e942',
  NEW_WIXCODE_MOBILE: 'eb630c4f-b3ce-4ad5-87f2-5666fa798d53',
  WIXCODE_MOBILE_HELP_ITEM: 'b31c9491-7c23-45c7-b3b2-5cb7aed2455b',
  KEYBOARD_SHORTCUTS: '5a3238e6-ebe9-4aca-a7a2-1c717598e334',
  PAGE_TYPES: {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    CORVID: 'corvid',
    CORVID_MOBILE: 'corvid-mobile',
    REGULAR: 'regular',
  },
  GOOGLE_MAP: {
    MANAGE_LOCATIONS: 'fa37d624-0629-4adc-8e3c-5f32857c93de',
  },
  SAVE_TO_MY_ELEMENTS_HELP_ITEM: '4e6bef36-e56f-4c51-94b4-780a93f3ff4c',
  CANNOT_PASTE_COMPONENTS: '18cd8731-8764-404b-b1b7-d86d0685a069',
} as const;
