import _ from 'lodash';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef, StyleRef } from '@wix/document-services-types';

export interface SystemStyleDef {
  id: string;
  compId: string;
  componentClassName: string;
  pageId: string;
  styleType: 'system' | 'custom';
  type: 'TopLevelStyle';
  // TODO: try to fix it
  // @ts-expect-error
  skin: defaultSkin;
  style: {
    groups: {};
    properties: {};
    propertiesSource: {};
  };
}

interface ComponentStyleMap {
  compPtr: CompRef;
  styleItem: StyleRef;
}

function areStylePropertiesEqual(firstProps: AnyFixMe, secondProps: AnyFixMe) {
  return _.isEqual(firstProps, secondProps);
}

/**
 * Returns custom styles by component type
 */
function getStylesAndCompPtrByType(
  _themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  compType: string,
  compsWithSameType: string[],
  styleType: string,
): ComponentStyleMap[] {
  const targetCompTypes = _.union(compsWithSameType, [compType]);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    targetCompTypes,
    (acc, currType) => {
      const customStylesForCompType = _(currType)
        .thru(componentModule.get.byType)
        .map((compPtr) => ({
          compPtr,
          styleItem: componentModule.style.get(compPtr),
        }))
        .filter({ styleItem: { styleType } })
        .value();
      acc = _.union(acc, customStylesForCompType);
      return acc;
    },
    [],
  );
}

function getCompTypeStyles(
  _themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  compType: string,
  compsWithSameType: string[],
  styleType: string,
): StyleRef[] {
  //TODO: Replace usages with getStylesAndCompPtrByType
  const targetCompTypes = _.union(compsWithSameType, [compType]);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    targetCompTypes,
    (acc, currType) => {
      const customStylesForCompType = _(currType)
        .thru(componentModule.get.byType)
        .map((compPtr) => componentModule.style.get(compPtr))
        .filter({ styleType })
        .value();
      acc = _.union(acc, customStylesForCompType);
      return acc;
    },
    [],
  );
}

function getDefaultSystemStyleSkin(
  componentModule: EditorAPI['components'],
  compType: string,
  styleId: string,
) {
  if (compType) {
    const compDef = componentModule.getDefinition(compType);
    if (compDef?.styles && !Array.isArray(compDef.styles)) {
      return compDef.styles[styleId];
    }
  } else {
    //TODO: Remove once santaPreviewService changes will be deployed
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const componentDefaults = _.find(
      // TODO: try to fix it
      // @ts-expect-error
      componentModule.COMPONENT_DEFINITION_MAP,
      function (compDef) {
        return compDef.styles?.[styleId];
      },
    );
    return componentDefaults?.styles[styleId];
  }
}

function createSystemStyleDef(
  componentModule: EditorAPI['components'],
  themeModule: EditorAPI['theme'],
  compType: string,
  styleId: string,
): SystemStyleDef {
  const defaultSkin = getDefaultSystemStyleSkin(
    componentModule,
    compType,
    styleId,
  );
  if (!defaultSkin) {
    return null;
  }
  if (compType) {
    const styleItem = themeModule.styles.getDefaultThemeStyle(
      compType,
      styleId,
    );

    // TODO: try to fix it
    // @ts-expect-error
    return {
      ...styleItem,
      id: styleId,
    };
  }

  return {
    id: styleId,
    compId: '',
    componentClassName: '',
    pageId: '',
    styleType: 'system',
    type: 'TopLevelStyle',
    skin: defaultSkin,
    style: {
      groups: {},
      properties: {},
      propertiesSource: {},
    },
  };
}

function getSortedStyleProperties(styleProperties: AnyFixMe) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const propertiesArray = _.map(styleProperties, function (propVal, propKey) {
    return [propKey, propVal];
  });
  return _.sortBy(propertiesArray, _.head);
}

/**
 * Returns the style value of the given style id, or null if no such style exists.<br>
 * @returns {object} style value
 */
function getSystemStyleById(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  styleId: string,
  compType: string,
): StyleRef {
  let style = themeModule.styles.get(styleId);
  if (!style) {
    style = createSystemStyleDef(
      componentModule,
      themeModule,
      compType,
      styleId,
    );
  }
  return style;
}

function getStyleById(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  styleId: string,
  compType: string,
) {
  return getSystemStyleById(themeModule, componentModule, styleId, compType);
}

/**
 * Returns a unique sorted custom style array of the passed component type.<br>
 * 2 styles are considered identical if their properties are equal.<br>
 * The sorting is lexicographic on the skin name
 */
function getUniqueCustomStylesAndCompPtr(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  compType: string,
): ComponentStyleMap[] {
  //TODO: Consider to return only compPtrs
  const fullCustomStyleList = getStylesAndCompPtrByType(
    themeModule,
    componentModule,
    compType,
    [],
    'custom',
  );
  const themeStyleList = getUniqueThemeStyleList(
    themeModule,
    componentModule,
    compType,
  );
  const uniqueCustomStyleList = _.uniqBy(
    fullCustomStyleList,
    function (customStyle) {
      return JSON.stringify(
        customStyle.styleItem.style
          ? getSortedStyleProperties(customStyle.styleItem.style.properties)
          : {},
      );
    },
  );
  /*remove custom styles that are equal to themed style*/
  _.remove(uniqueCustomStyleList, (uniqueCustomStyle) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(themeStyleList, (themeStyle) =>
      isEqualWithoutTypeConsideration(uniqueCustomStyle.styleItem, themeStyle),
    );
  });
  return _.sortBy(uniqueCustomStyleList, 'styleItem.id');
}

/**
 * Returns a unique sorted custom style array of the passed component type.<br>
 * 2 styles are considered identical if their properties are equal.<br>
 * The sorting is lexicographic on the skin name
 */
function getUniqueCustomStyleList(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  compType: string,
): StyleRef[] {
  //TODO: Replace usages with getUniqueCustomStylesAndCompPtr
  const fullCustomStyleList = getCompTypeStyles(
    themeModule,
    componentModule,
    compType,
    [],
    'custom',
  );
  const themeStyleList = getUniqueThemeStyleList(
    themeModule,
    componentModule,
    compType,
  );
  const uniqueCustomStyleList = _.uniqBy(
    fullCustomStyleList,
    function (customStyle) {
      return JSON.stringify(
        customStyle.style
          ? getSortedStyleProperties(customStyle.style.properties)
          : {},
      );
    },
  );
  /*remove custom styles that are equal to themed style*/
  _.remove(uniqueCustomStyleList, (uniqueCustomStyle) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(themeStyleList, (themeStyle) =>
      isEqualWithoutTypeConsideration(uniqueCustomStyle, themeStyle),
    );
  });
  return _.sortBy(uniqueCustomStyleList, 'skin');
}

/**
 * Returns a unique sorted theme style array of the passed component type.<br>
 * 2 styles are considered identical if their properties are equal.<br>
 * The sorting is lexicographic on the skin name
 */
function getUniqueThemeStyleList(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  compType: string,
): StyleRef[] {
  //TODO: Replace usages with getUniqueThemeStyleAndCompPtrList
  const themedStyles = getThemedStyleForComp(
    themeModule,
    componentModule,
    compType,
  );
  const uniqueCustomStyleList = _.uniqBy(themedStyles, function (customStyle) {
    return (
      JSON.stringify(
        customStyle.style
          ? getSortedStyleProperties(customStyle.style.properties)
          : {},
      ) + customStyle.skin
    );
  });
  return _.sortBy(uniqueCustomStyleList, 'skin');
}

/**
 * Returns a unique sorted theme style array of the passed component type.<br>
 * 2 styles are considered identical if their properties are equal.<br>
 * The sorting is lexicographic on the skin name
 */
function getUniqueThemeStyleAndCompPtrList(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  compType: string,
): ComponentStyleMap[] {
  const themedStyles = getStylesAndCompPtrByType(
    themeModule,
    componentModule,
    compType,
    [],
    'system',
  );
  const uniqueStyleList = _.uniqBy(themedStyles, function (customStyle) {
    return (
      JSON.stringify(
        customStyle.styleItem.style
          ? getSortedStyleProperties(customStyle.styleItem.style.properties)
          : {},
        // TODO: try to fix it
        // @ts-expect-error
      ) + customStyle.skin
    );
  });
  return _.sortBy(uniqueStyleList, 'styleItem.skin');
}

/**
 * Returns a theme style array of the passed component type.<br>
 */
function getThemedStyleForComp(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  compType: string,
): StyleRef[] {
  //TODO: Replace usage with getThemedStyleAndCompPtrForComp
  const getThemeCompIds = componentModule.getDefinition(compType)
    ? // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      _.keys(componentModule.getDefinition(compType).styles)
    : [];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(getThemeCompIds, (themeStyleId) =>
    getSystemStyleById(themeModule, componentModule, themeStyleId, compType),
  );
}

/**
 * Returns a theme style array of the passed component type.<br>
 */
function getThemedStyleAndCompPtrForComp(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  compType: string,
): ComponentStyleMap[] {
  return getStylesAndCompPtrByType(
    themeModule,
    componentModule,
    compType,
    [],
    'system',
  );
}

/**
 * Return array with styles identical to the style sent by param.<br>
 * 2 styles are considered identical if their properties are equal.<br>
 */
function getDuplicateToStyleList(
  themeModule: EditorAPI['theme'],
  componentModule: EditorAPI['components'],
  style: StyleRef,
  compType: string,
  compsWithSameType: string[],
): ComponentStyleMap[] {
  const themedStyles = getUniqueThemeStyleAndCompPtrList(
    themeModule,
    componentModule,
    compType,
  );
  const customStyles = getStylesAndCompPtrByType(
    themeModule,
    componentModule,
    compType,
    compsWithSameType,
    'custom',
  );
  const allStyleForComp = themedStyles.concat(customStyles);
  return (
    _(allStyleForComp)
      // TODO: try to fix it
      // @ts-expect-error
      .reject({ id: style.id })
      .filter((item) => isEqualWithoutTypeConsideration(item.styleItem, style))
      .value()
  );
}

/**
 * Returns true iff the passed styles are equal in terms of skin, type and properties
 */
function isEqual(styleA: StyleRef, styleB: StyleRef) {
  if (!styleA) {
    throw new Error('StyleA is not defined');
  }
  if (!styleB) {
    throw new Error('StyleB is not defined');
  }
  if (styleA.skin !== styleB.skin || styleA.styleType !== styleB.styleType) {
    return false;
  }
  if (styleA.styleType === 'system') {
    return styleA.id === styleB.id;
  }
  if (styleA.style && styleB.style) {
    return areStylePropertiesEqual(
      styleA.style.properties,
      styleB.style.properties,
    );
  }
  return true;
}

/**
 * Returns true if the passed styles are equal in terms of skin, type and properties - compare also themed and customize
 */
function isEqualWithoutTypeConsideration(styleA: StyleRef, styleB: StyleRef) {
  if (!styleA || !styleB) {
    throw new Error('Style is not defined');
  }
  if (styleA.skin !== styleB.skin) {
    return false;
  }
  if (styleA.style && styleB.style) {
    return areStylePropertiesEqual(
      styleA.style.properties,
      styleB.style.properties,
    );
  }
  return true;
}

function getCompDefStyle(compDef: AnyFixMe, editorAPI: EditorAPI) {
  const style = compDef.style || compDef.styleId;
  if (_.isString(style)) {
    return getStyleById(
      editorAPI.theme,
      editorAPI.components,
      style,
      compDef.compType || compDef.componentType,
    );
  }
  return style;
}

export default {
  getSystemStyleById,
  getStyleById,
  getUniqueCustomStylesAndCompPtr,
  getUniqueCustomStyleList,
  getUniqueThemeStyleList,
  getUniqueThemeStyleAndCompPtrList,
  getThemedStyleForComp,
  getThemedStyleAndCompPtrForComp,
  getDuplicateToStyleList,
  isEqual,
  isEqualWithoutTypeConsideration,
  getCompDefStyle,
};
