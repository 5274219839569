import type { EditorState } from '../../store/editorState';
const getDraggableSlotPosition = (state: EditorState, slotId: string) =>
  state.draggableSlots[slotId];
const getAllDraggableSlots = (state: EditorState) => state.draggableSlots;
const getAllDraggableSlotsPositions = (state: EditorState) =>
  Object.values(state.draggableSlots).filter((position) => position);

export {
  getDraggableSlotPosition,
  getAllDraggableSlots,
  getAllDraggableSlotsPositions,
};
