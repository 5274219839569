import { arrayUtils } from '@/util';
import {
  isMeshLayoutEnabled,
  dsOneDockAdapter,
  dsActionsOneDockAdapter,
} from '@/layoutOneDockMigration';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, Docking } from 'types/documentServices';
/**
 * @deprecated use fullWidth and pin API instead
 */
export function createLayoutDockApi({ editorAPI }: { editorAPI: EditorAPI }) {
  /**
   * @deprecated
   */
  function isDocked(compRefOrRefs: CompRef | CompRef[]) {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    return compRefs.every((compRef) => {
      if (isMeshLayoutEnabled()) {
        return dsOneDockAdapter(editorAPI.dsRead).layout.isDocked(compRef);
      }

      // source https://github.com/wix-private/document-management/blob/b30cc2ea84908fdde8bbb2ceecbafc08992c9dfb/document-services-implementation/src/structure/structure.ts#L825
      return editorAPI.dsRead.components.layout.isDocked(compRef);
    });
  }

  /**
   * @deprecated
   */
  function isDockedToScreen(compRefOrRefs: CompRef | CompRef[]) {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    return compRefs.every((compRef) => {
      if (isMeshLayoutEnabled()) {
        return dsOneDockAdapter(editorAPI.dsRead).layout.isDockedToScreen(
          compRef,
        );
      }

      return editorAPI.dsRead.components.layout.isDockedToScreen(compRef);
    });
  }

  const DOCKING_UPDATE_IS_DEPRECATED_ERROR =
    'unDock is not supported in mesh layout.\nUse steatch/unstreach and pin/unpin instead';

  interface DockingUpdateDepticationOptions {
    allowOneDockAdapter_DONT_USE?: boolean;
  }

  function throwDockingUpdateIsDeprecatedIfNotAllowed({
    allowOneDockAdapter_DONT_USE: allowOneDockAdapter,
  }: DockingUpdateDepticationOptions = {}) {
    if (isMeshLayoutEnabled() && !allowOneDockAdapter) {
      throw new Error(DOCKING_UPDATE_IS_DEPRECATED_ERROR);
    }
  }

  /**
   * @deprecated use steatch/unstreach and pin/unpin instead
   */
  function unDock(compRefOrRefs: CompRef | CompRef[]) {
    const compRef = arrayUtils.asSingleThrowIfMultiple(compRefOrRefs);

    if (isMeshLayoutEnabled()) {
      throwDockingUpdateIsDeprecatedIfNotAllowed();
      return;
    }

    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    editorAPI.dsActions.components.layout.unDock(compRef);
  }

  /**
   * @deprecated use steatch/unstreach and pin/unpin instead
   */
  function setDock(
    compRefOrRefs: CompRef | CompRef[],
    newValue: Docking,
    options: DockingUpdateDepticationOptions = {},
  ): void {
    const compRef = arrayUtils.asSingleThrowIfMultiple(compRefOrRefs);

    if (isMeshLayoutEnabled()) {
      throwDockingUpdateIsDeprecatedIfNotAllowed(options);

      const dsAdapter = dsActionsOneDockAdapter({
        dsRead: editorAPI.dsRead,
        dsActions: editorAPI.dsActions,
      });

      dsAdapter.layout.setDock(compRef, newValue);
      return;
    }

    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    editorAPI.dsActions.components.layout.setDock(compRef, newValue);
  }

  /**
   * @deprecated
   */
  function getDock(compRefOrRefs: CompRef | CompRef[]): Docking {
    const compRef = arrayUtils.asSingleThrowIfMultiple(compRefOrRefs);

    if (isMeshLayoutEnabled()) {
      return dsOneDockAdapter(editorAPI.dsRead).layout.getDock(compRef);
    }

    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    return editorAPI.dsRead.components.layout.getDock(compRef);
  }

  /**
   * @deprecated use steatch/unstreach and pin/unpin instead
   */
  function updateDock(
    compRefOrRefs: CompRef | CompRef[],
    newValue: Docking,
    options: DockingUpdateDepticationOptions = {},
  ): void {
    const compRef = arrayUtils.asSingleThrowIfMultiple(compRefOrRefs);

    if (isMeshLayoutEnabled()) {
      throwDockingUpdateIsDeprecatedIfNotAllowed(options);

      const dsAdapter = dsActionsOneDockAdapter({
        dsRead: editorAPI.dsRead,
        dsActions: editorAPI.dsActions,
      });

      dsAdapter.layout.updateDock(compRef, newValue);
      return;
    }

    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    editorAPI.dsActions.components.layout.updateDock(compRef, newValue);
  }

  return {
    isDocked,
    isDockedToScreen,
    setDock,
    updateDock,
    unDock,
    getDock,
  };
}

export type LayoutDockApi = ReturnType<typeof createLayoutDockApi>;
