import { attachCandidateActionTypes } from './attachCandidateActionTypes';

import type { CompRef } from 'types/documentServices';

import type { AnyAction } from 'redux';

export interface AttachCandidateState {
  comp: CompRef | null;
  useAnimation: boolean;
}

export const attachCandidateInitialState: AttachCandidateState = {
  comp: null,
  useAnimation: false,
};

export const attachCandidateReducer = (
  state = attachCandidateInitialState,
  action: AnyAction,
): AttachCandidateState => {
  if (action.type === attachCandidateActionTypes.UPDATE) {
    return action.payload;
  }

  return state;
};
