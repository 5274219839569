import _ from 'lodash';
import panelsActionTypes from './panelsActionTypes';
import type { Reducer } from 'types/redux';
import type {
  openPanelAction,
  closePanelByNameAction,
  closePanelByPropKeyAction,
  closeAllPanels,
  updatePanelAction,
  addPanelFilter,
  removePanelFilter,
} from './panelsActionsInternal';
import type { PanelDescriptor, PanelFilterFunction } from './types';

const {
  OPEN_PANEL,
  UPDATE_PANEL,
  CLOSE_ALL_PANELS,
  CLOSE_PANEL_BY_NAME,
  CLOSE_PANEL_BY_PROP,
  ADD_PANEL_FILTER,
  REMOVE_PANEL_FILTER,
} = panelsActionTypes;

const userCloseOrigins = [
  'header_close_button',
  'click_outside',
  'out_of_focus',
  'escape_press',
  'done_button',
  'confirm_button',
  'apiMethodCall',
];

const isClosedByUserIntent = (panel: PanelDescriptor, origin: string) =>
  userCloseOrigins.includes(origin);

export interface PanelsState {
  openPanels: PanelDescriptor[];
  panelFilters: Record<string, PanelFilterFunction>;
}

export type { PanelDescriptor };

type PanelsAction =
  | ReturnType<typeof openPanelAction>
  | ReturnType<typeof updatePanelAction>
  | ReturnType<typeof closePanelByNameAction>
  | ReturnType<typeof closePanelByPropKeyAction>
  | ReturnType<typeof closeAllPanels>
  | ReturnType<typeof addPanelFilter>
  | ReturnType<typeof removePanelFilter>;

export const panelsInitialState: PanelsState = {
  openPanels: [],
  panelFilters: {},
};

const reducer: Reducer<PanelsState, PanelsAction> = (
  panelsState = panelsInitialState,
  action,
) => {
  const { openPanels, panelFilters } = panelsState;
  switch (action.type) {
    case OPEN_PANEL:
      return {
        openPanels: openPanels.concat(action.panelDescriptor),
        panelFilters,
      };
    case UPDATE_PANEL:
      return {
        openPanels: openPanels.map((panelDescriptor) => {
          if (panelDescriptor.name === action.panelDescriptor.name) {
            return {
              ...panelDescriptor,
              ...action.panelDescriptor,
              props: {
                ...panelDescriptor.props,
                ...action.panelDescriptor.props,
              },
            };
          }
          return panelDescriptor;
        }),
        panelFilters,
      };
    case CLOSE_PANEL_BY_NAME:
      return {
        openPanels: _.reject(openPanels, { name: action.panelName }),
        panelFilters,
      };
    case CLOSE_PANEL_BY_PROP: {
      const updatedOpenPanels = _.reject(
        openPanels,
        (panel) =>
          panel.props[action.prop as keyof typeof panel.props] ===
            action.propValue &&
          (!panel.closeWithUserIntent ||
            isClosedByUserIntent(panel, action.origin)),
      );

      return { openPanels: updatedOpenPanels, panelFilters };
    }
    case CLOSE_ALL_PANELS:
      return {
        openPanels: openPanels.length
          ? openPanels.filter(
              (panelDescriptor) => panelDescriptor.closeWithUserIntent === true,
            )
          : openPanels,
        panelFilters,
      };

    case ADD_PANEL_FILTER:
      return {
        openPanels,
        panelFilters: {
          ...panelFilters,
          [action.name]: action.predicate,
        },
      };
    case REMOVE_PANEL_FILTER:
      return { openPanels, panelFilters: _.omit(panelFilters, action.name) };
    default:
      return panelsState;
  }
};

export default reducer;
