import type { EditorState } from '../store/editorState';

const getHoveredSection = (state: EditorState) =>
  state.sectionsOnStage.sections[state.sectionsOnStage.hoveredSectionIndex];

const getHoveredSectionIndex = (state: EditorState) =>
  state.sectionsOnStage.hoveredSectionIndex;

const getSectionsOnStage = (state: EditorState) =>
  state.sectionsOnStage.sections;

export const sectionsOnStageSelectors = {
  getHoveredSection,
  getHoveredSectionIndex,
  getSectionsOnStage,
};
