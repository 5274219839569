import { startUpdatePositionInTransition } from './startUpdatePositionInTransition';
import {
  moveByTransitionEndInterceptor,
  moveByTransitionEndAfterHook,
} from './interceptors';

import type { EditorAPI } from '@/editorAPI';
import type { HistoryAddOptions } from '@/history';
import type { CompRef } from 'types/documentServices';
import type { HistoryApi } from '../../../createHistoryApi';
import type { LayoutGetApi } from '../../../layoutGetApi';
import type { LayoutMeshApi } from '../../createLayoutMeshApi';
import type { LayoutMeshMoveByApi } from '../../layoutMeshMoveByApi/createLayoutMeshMoveByApi';

export function moveByTransition(
  {
    editorAPI,
    historyApi,
    layoutGetApi,
    layoutMeshCoreApi,
    layoutMeshMoveByApi,
  }: {
    editorAPI: EditorAPI;
    historyApi: HistoryApi;
    layoutGetApi: LayoutGetApi;
    layoutMeshCoreApi: LayoutMeshApi['__core'];
    layoutMeshMoveByApi: LayoutMeshMoveByApi;
  },
  compRefsRaw: CompRef[],
) {
  const compRefs = compRefsRaw.map((compRef) =>
    layoutMeshCoreApi.resolveCompRefWithVariant(compRef),
  );

  const itemLayoutTypes = new Set(
    compRefs
      .map((compRef) => layoutMeshCoreApi.get(compRef))
      .map((layouts) =>
        'type' in layouts.itemLayout ? layouts.itemLayout.type : undefined,
      ),
  );

  if (itemLayoutTypes.size > 1) {
    throw new Error(
      `moveByTransition supports only components with the same 'layouts.itemLayout.type'.\n` +
        `Received the following itemLayout types: ${[...itemLayoutTypes].join(
          ', ',
        )}`,
    );
  }

  const itemLayoutType = Array.from(itemLayoutTypes)[0];

  const compRectInitialByCompId = new Map(
    compRefs.map((compRef) => [
      compRef.id,
      layoutMeshCoreApi.measureRect(compRef),
    ]),
  );
  const compRectRelativeToScreenInitialByCompId = new Map(
    compRefs.map((compRef) => [
      compRef.id,
      layoutGetApi.getRelativeToScreen_rect(compRef),
    ]),
  );

  const updateInTransition = startUpdatePositionInTransition(
    { editorAPI },
    compRefs,
    compRectRelativeToScreenInitialByCompId,
  );

  function update({ deltaX, deltaY }: { deltaX: number; deltaY: number }) {
    return updateInTransition.update({
      deltaX,
      deltaY,
    });
  }

  async function end(options: HistoryAddOptions & {} = {}) {
    const { deltaX, deltaY } = updateInTransition.get();
    const {
      data: { compContainerToByCompId },
    } = moveByTransitionEndInterceptor.intercept({});

    let compRefUpdatedMap = new Map<string, CompRef>();

    switch (itemLayoutType) {
      case 'MeshItemLayout': {
        ({ compRefUpdatedMap } =
          await layoutMeshMoveByApi.moveBy_MeshItemLayout(
            compRefs,
            {
              deltaX,
              deltaY,
              compRectInitialByCompId,
              compContainerToByCompId,
            },
            {
              dontAddToUndoRedoStack: true,
            },
          ));
        break;
      }

      case 'FixedItemLayout': {
        await layoutMeshMoveByApi.moveBy_FixedItemLayout(
          compRefs,
          { deltaX, deltaY },
          {
            dontAddToUndoRedoStack: true,
          },
        );
        break;
      }

      default:
        throw new Error(`unsuported itemLayoutType: ${itemLayoutType}`);
    }

    updateInTransition.end();
    moveByTransitionEndAfterHook.fire({
      compRefUpdatedMap,
    });

    historyApi.add('component - move transition', options);
  }

  return {
    update,
    end,
  };
}
