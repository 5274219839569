import type { EditorAPI } from '@/editorAPI';
import type { Rect, CompRef } from 'types/documentServices';
import type { GridLine } from 'types/core';

function getScaledPagesContainerLayout(editorAPI: EditorAPI): Rect {
  const pagesContainerLayout =
    editorAPI.siteSegments.getPagesContainerAbsLayout();
  const siteScale = editorAPI.getSiteScale();

  if (!siteScale || siteScale === 1) {
    return pagesContainerLayout;
  }

  const scaledLayout = {
    x: undefined as number,
    y: pagesContainerLayout.y * siteScale,
    width: pagesContainerLayout.width * siteScale,
    height: pagesContainerLayout.height * siteScale,
  };

  const widthDiff = pagesContainerLayout.width - scaledLayout.width;

  scaledLayout.x = pagesContainerLayout.x + widthDiff / 2;

  return scaledLayout;
}

function getGridLinesByPageLayout(
  scaledPagesContainerLayout: Rect,
  ignoreHorizontals: boolean,
): [GridLine, GridLine] | [GridLine, GridLine, GridLine, GridLine] {
  const layoutBottom =
    scaledPagesContainerLayout.y + scaledPagesContainerLayout.height;
  const layoutRight =
    scaledPagesContainerLayout.x + scaledPagesContainerLayout.width;

  const leftGrid = {
    x1: scaledPagesContainerLayout.x,
    y1: 0,
    x2: scaledPagesContainerLayout.x,
    y2: '100%',
  };
  const rightGrid = {
    x1: layoutRight,
    y1: 0,
    x2: layoutRight,
    y2: '100%',
  };
  const topGrid = {
    x1: 0,
    y1: scaledPagesContainerLayout.y,
    x2: '100%',
    y2: scaledPagesContainerLayout.y,
  };
  const bottomGrid = {
    x1: 0,
    y1: layoutBottom,
    x2: '100%',
    y2: layoutBottom,
  };

  return ignoreHorizontals
    ? [leftGrid, rightGrid]
    : [leftGrid, rightGrid, topGrid, bottomGrid];
}

function getPageGridLines(editorAPI: EditorAPI, ignoreHorizontals: boolean) {
  const scaledPagesContainerLayout = getScaledPagesContainerLayout(editorAPI);
  return getGridLinesByPageLayout(
    scaledPagesContainerLayout,
    ignoreHorizontals,
  );
}

function getContainerGridLines(
  editorAPI: EditorAPI,
  compPointer: CompRef,
): [GridLine, GridLine] {
  const COLUMNS_CONTAINER = 'wysiwyg.viewer.components.StripColumnsContainer';
  if (editorAPI.components.getType(compPointer) === COLUMNS_CONTAINER) {
    compPointer = editorAPI.components.getChildren(compPointer)[0];
  }

  const containerMargin =
    editorAPI.components.layout.getCompMargin(compPointer);
  const layoutRelativeToScreen =
    editorAPI.components.layout.getRelativeToScreen(compPointer);

  const compBottom = layoutRelativeToScreen.y + layoutRelativeToScreen.height;
  const leftMarginXRelativeToScreen =
    layoutRelativeToScreen.x + containerMargin.left;
  const rightMarginXRelativeToScreen =
    layoutRelativeToScreen.x +
    layoutRelativeToScreen.width -
    containerMargin.right;

  const leftGrid = {
    x1: leftMarginXRelativeToScreen,
    y1: layoutRelativeToScreen.y,
    x2: leftMarginXRelativeToScreen,
    y2: compBottom,
  };
  const rightGrid = {
    x1: rightMarginXRelativeToScreen,
    y1: layoutRelativeToScreen.y,
    x2: rightMarginXRelativeToScreen,
    y2: compBottom,
  };

  return [leftGrid, rightGrid];
}

export { getPageGridLines, getContainerGridLines };
