const START_STAGE_LOADING = 'startStageLoading';
const END_STAGE_LOADING = 'endStageLoading';

export interface StartStageLoadingType {
  type: typeof START_STAGE_LOADING;
}

export interface EndStageLoadingType {
  type: typeof END_STAGE_LOADING;
}

export type StageLoaderActionTypes =
  | StartStageLoadingType
  | EndStageLoadingType;

export const stageLoaderActionTypes = {
  START_STAGE_LOADING,
  END_STAGE_LOADING,
} as const;
