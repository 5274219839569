import { resolveCompRefWithVariant } from './resolveCompRefWithVariant';
import type {
  CompRef,
  SingleLayoutData,
  RecursivePartial,
} from 'types/documentServices';
import type { DSContext } from '../types';

export function responsiveLayoutUpdate(
  { dsRead, dsActions }: DSContext,
  compRef: CompRef,
  layouts: RecursivePartial<SingleLayoutData>,
) {
  const compRefWithVariant = resolveCompRefWithVariant(dsRead, compRef);
  return dsActions.components.responsiveLayout.update(
    compRefWithVariant,
    layouts,
  );
}
