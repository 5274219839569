const SET_MOBILE_WIZARD_IS_ENABLED = 'mobile-wizard-set-is-enabled';
const SET_MOBILE_WIZARD_IS_EXPANDED = 'mobile-wizard-set-is-expanded';
const SET_MOBILE_WIZARD_OPENING_STATE = 'mobile-wizard-set-opening-state';

const NEXT_STEP = 'mobile-wizard-next-step';
const PREVIOUS_STEP = 'mobile-wizard-previous-step';
const SET_TINY_MENU_AVAILABLE_SKINS =
  'mobile-wizard-set-tiny-menu-available-skins';
const SET_HEADER_ANIMATION_PROGRESS =
  'mobile-wizard-set-header-animation-progress';
const SET_BACK_TO_TOP = 'mobile-wizard-set-back-to-top';
const SET_CURRENT_STEP = 'mobile-wizard-set-current-step';
const SET_STEPS_TO_SHOW = 'mobile-wizard-set-steps-to-show';
const SET_USER_SELECTIONS = 'mobile-wizard-set-user-selections';
const SET_LAST_SAVED = 'mobile-wizard-set-last-saved';
const SET_QAB_AVAILABLE_PRESETS = 'mobile-wizard-set-qab-available-presets';

export {
  SET_MOBILE_WIZARD_IS_ENABLED,
  SET_MOBILE_WIZARD_IS_EXPANDED,
  SET_MOBILE_WIZARD_OPENING_STATE,
  NEXT_STEP,
  PREVIOUS_STEP,
  SET_TINY_MENU_AVAILABLE_SKINS,
  SET_HEADER_ANIMATION_PROGRESS,
  SET_BACK_TO_TOP,
  SET_CURRENT_STEP,
  SET_STEPS_TO_SHOW,
  SET_USER_SELECTIONS,
  SET_LAST_SAVED,
  SET_QAB_AVAILABLE_PRESETS,
};
