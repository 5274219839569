export default {
  SET_CURR_PACKAGE_TASK: 'SET_CURR_PACKAGE_TASK',
  SET_WIDGET_API_TEST_PROPERTIES: 'SET_WIDGET_API_TEST_PROPERTIES',
  SET_APP_DEF_ID: 'SET_APP_DEF_ID',
  SET_APP_NAME: 'SET_APP_NAME',
  SET_APP_VERSION: 'SET_APP_VERSION',
  SET_APP_NAMESPACE: 'SET_APP_NAMESPACE',
  SET_CURR_BUILD_VERSION: 'SET_CURR_BUILD_VERSION',
  SET_IS_FIRST_APP_BUILD: 'SET_IS_FIRST_APP_BUILD',
  SET_IS_PACKAGING: 'SET_IS_PACKAGING',
  SET_NUM_OF_TASKS_DONE: 'SET_NUM_OF_TASKS_DONE',
  SET_WIDGET_DESIGN_PANEL_SPLITTER_HEIGHT:
    'SET_WIDGET_DESIGN_PANEL_SPLITTER_HEIGHT',
  SET_PACKAGE_ERROR: 'SET_IS_PACKAGE_ERROR',
  SET_PACKAGE_STEP: 'SET_PACKAGE_STEP',
  SET_TOTAL_NUM_OF_PACKAGE_TASKS: 'SET_TOTAL_NUM_OF_PACKAGE_TASKS',
  SET_APP_LINK: 'SET_APP_LINK',
  SET_IS_CREATING_APP_LINK: 'SET_IS_CREATING_APP_LINK',
  SET_IS_CREATE_LINK_FAIL: 'SET_IS_CREATE_LINK_FAIL',
  SET_IS_REVOKING_APP_LINK: 'SET_IS_REVOKING_APP_LINK',
  SET_IS_REVOKE_LINK_FAIL: 'SET_IS_REVOKE_LINK_FAIL',
  UPDATE_WIDGETS_MAP: 'UPDATE_WIDGETS_MAP',
  SET_WIDGETS_DATA: 'SET_WIDGETS_DATA',
  SET_SITE_HEADER_URL: 'SET_SITE_HEADER_URL',
  SET_BLOCKS_VERSION: 'SET_BLOCKS_VERSION',
  SET_STRICT_COLOR_PICKER_MODE: 'SET_STRICT_COLOR_PICKER_MODE',
};
