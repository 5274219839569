import { smartWriterActionTypes } from './smartWriterActionTypes';
import type {
  UpdateComponentTopicType,
  ResetComponentStoreType,
} from './smartWriterActionTypes';

const updateComponentTopic = (
  compId: string,
  topicId: string,
): UpdateComponentTopicType => ({
  type: smartWriterActionTypes.UPDATE_COMPONENT_TOPIC,
  payload: { compId, topicId },
});

const resetComponentStore = (): ResetComponentStoreType => ({
  type: smartWriterActionTypes.RESET_COMPONENT_STORE,
});

export const smartWriterActions = {
  updateComponentTopic,
  resetComponentStore,
};
