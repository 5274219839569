import _ from 'lodash';
import { applicationStudioInitialState } from '../applicationStudioReducer';
import actionTypes from '../applicationStudioActionTypes';

const appLinkReducer = (
  appLink = applicationStudioInitialState.appLink,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_APP_LINK:
      return _.defaults({ link: action.link }, appLink);
    case actionTypes.SET_IS_CREATE_LINK_FAIL:
      return _.defaults({ fail: action.isCreateFail }, appLink);
    case actionTypes.SET_IS_CREATING_APP_LINK:
      return _.defaults({ creating: action.isCreating }, appLink);
    case actionTypes.SET_IS_REVOKE_LINK_FAIL:
      return _.defaults({ failRevoke: action.isRevokeFail }, appLink);
    case actionTypes.SET_IS_REVOKING_APP_LINK:
      return _.defaults({ revoking: action.isRevoking }, appLink);
    default:
      return appLink;
  }
};

export default appLinkReducer;
