import _ from 'lodash';
import constants from '@/constants';
import * as highlightsActions from '../../highlights/highlightsActions';
import * as actionTypes from './interactionsHiddenItemsActionTypes';
import * as selectors from './interactionsHiddenItemsSelectors';
import * as hiddenItemsSelectors from '../hiddenItems/hiddenItemsSelectors';
import * as componentLocatorActions from '../../componentLocator/componentLocatorActions';
import type { CompRef } from '@wix/document-services-types';
import {
  highlightComp,
  isMobileHiddenItemsPanelOpen,
  callbacksAndScrollToComp,
  hideHiddenItemsCounter,
  clearHoveredComponent,
} from '../hiddenItems/hiddenItemsActions';
import type { EditorState } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type {
  Dispatch,
  StateMapperArgs,
  DispatchMapperArgs,
} from 'types/redux';
import type { HiddenItemData } from '@/mobileEditor';
import * as selectionSelectors from '../../selection/selectionSelectors';
import * as inlinePopupActions from '../../inlinePopup/inlinePopupActions';

const markHoveredComponent =
  (compId: string, pageId: string) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: DispatchMapperArgs,
  ) => {
    const interactionsHiddenComponents =
      hiddenItemsSelectors.getInteractionsMobileHiddenItems(editorAPI);

    const hiddenCompIndex = interactionsHiddenComponents.findIndex(
      (hiddenItem: HiddenItemData) => hiddenItem.compId === compId,
    );

    dispatch({
      type: actionTypes.SET_HIDDEN_ITEMS_HOVERED_COMPONENT,
      hoveredComponent: {
        hiddenComp: interactionsHiddenComponents[hiddenCompIndex],
        pageId,
        isInteraction: true,
        index: hiddenCompIndex,
      },
    });
  };

const addInteractionComponentToStageAndScrollToIt =
  (
    compRef: CompRef,
    compId: string,
    pageId: string,
    scrollCallback = _.noop,
    showCallback = _.noop,
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { dsActions, editorAPI }: DispatchMapperArgs,
  ) => {
    if (!isMobileHiddenItemsPanelOpen(getState())) {
      return;
    }

    const canStillPreviewComp = (compRef: CompRef) =>
      !!compRef && editorAPI.components.is.exist(compRef);

    toggleHideInteractionsItem(compRef, false, editorAPI);
    dsActions.waitForChangesApplied(() => {
      callbacksAndScrollToComp(
        compId,
        canStillPreviewComp,
        editorAPI,
        scrollCallback,
        showCallback,
      );
    });
  };

const toggleHideInteractionsItem = (
  compRef: CompRef,
  shouldHide: boolean,
  editorAPI: EditorAPI,
) => {
  const mobileVariant = editorAPI.mobile.getMobileVariant();
  const compMobileVariantPointer = editorAPI.components.variants.getPointer(
    compRef,
    [mobileVariant],
  );
  editorAPI.components.transformations.update(compMobileVariantPointer, {
    hidden: shouldHide,
  });
};

const setIsAddingInteractionsComponentInProgress =
  (inProgress: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.SET_IS_ADDING_COMPONENT_IN_PROGRESS,
      isAddingHiddenItemInProgress: inProgress,
    });
  };

const stopPreviewReAddComponent =
  (compRef: CompRef) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { dsActions, editorAPI }: DispatchMapperArgs,
  ) => {
    const isAddingHiddenItemInProgress =
      selectors.getIsAddingInteractionsHiddenItemInProgress(getState());
    if (isAddingHiddenItemInProgress) {
      return;
    }
    dispatch(highlightsActions.clearHighlights());
    dispatch(clearHoveredComponent());
    toggleHideInteractionsItem(compRef, true, editorAPI);
    // @ts-expect-error
    dsActions.history.applyLatestSnapshot();
  };

const previewReAddComponent =
  (compRef: CompRef, pageId: string) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: StateMapperArgs,
  ) => {
    const isAddingHiddenItemInProgress =
      selectors.getIsAddingInteractionsHiddenItemInProgress(getState());
    if (isAddingHiddenItemInProgress) {
      return;
    }
    const compId = compRef.id;
    dispatch(markHoveredComponent(compId, pageId));

    editorAPI.selection.deselectComponents();
    dispatch(highlightsActions.clearHighlights());

    dispatch(
      addInteractionComponentToStageAndScrollToIt(
        compRef,
        compId,
        pageId,
        () => {},
        (compRef: CompRef) => {
          editorAPI.store.dispatch(
            componentLocatorActions.locateComponentAndHighlight(
              compRef,
              true,
              constants.UI.HIGHLIGHTS.TYPES.NORMAL_WITH_OVERLAY,
            ),
          );
        },
      ),
    );
  };

const reAddInteractionsComponent =
  (compId: string, pageId: string) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: StateMapperArgs,
  ) => {
    dispatch(setIsAddingInteractionsComponentInProgress(true));
    const actionType = actionTypes.REMOVE_HIDDEN_ITEM_FROM_INTERACTIONS_LIST;

    window.setTimeout(() => {
      dispatch(highlightsActions.clearHighlights());
      dispatch(clearHoveredComponent());
      dispatch({ type: actionType, compId });
      const compRef = editorAPI.components.get.byId(compId, pageId);

      dispatch(
        addInteractionComponentToStageAndScrollToIt(
          compRef,
          compId,
          pageId,
          () => {
            if (editorAPI.components.is.exist(compRef)) {
              editorAPI.selection.selectComponentByCompRef(compRef);
            }

            const focusedContainer =
              selectionSelectors.getFocusedContainer(getState());
            if (editorAPI.components.is.inlinePopup(focusedContainer)) {
              dispatch(inlinePopupActions.open(focusedContainer));
            }

            dispatch(setIsAddingInteractionsComponentInProgress(false));
          },
        ),
      );
    }, 500);
  };

export {
  stopPreviewReAddComponent,
  previewReAddComponent,
  hideHiddenItemsCounter,
  highlightComp,
  reAddInteractionsComponent,
};
