import type { EditorState } from '../store/editorState';

const getSectionsState = (state: EditorState) => state.sections;

const getHoveredSectionLikeRef = (state: EditorState) =>
  getSectionsState(state).hoveredSectionLikeRef;

const getIsSectionizedPanelOpened = (state: EditorState): boolean =>
  getSectionsState(state).isSectionizedPanelOpened;

const getIsSectionActionsBarHovered = (state: EditorState): boolean =>
  getSectionsState(state).sectionActionsBarHovered;

export const sectionsSelectors = {
  getHoveredSectionLikeRef,
  getIsSectionizedPanelOpened,
  getIsSectionActionsBarHovered,
};
