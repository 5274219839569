// @ts-nocheck
import { getInnerRoutesCacheKey } from './dynamicPagesUtils';
import type { SitemapEntry } from 'types/documentServices';

const getRouterId = (state) => state.dynamicPages.routerData?.routerId;

const getRouterData = (state) => state.dynamicPages.routerData;

const getInnerRoutes = (state) => state.dynamicPages.innerRoutes;

const shouldPreventFetch = (state) => state.dynamicPages.preventFetch;

const getSelectedInnerRoute = (state) => state.dynamicPages.selectedInnerRoute;

const getCurrentRouteInfo = (state) => state.dynamicPages.currentRouteInfo;

const isInInnerRoutesFetchProcess = (state) =>
  state.dynamicPages.inInnerRoutesFetchProcess;

const getInnerRoutesCount = (state, pageId) =>
  state.dynamicPages.innerRoutesCounts[pageId];

const getCachedInnerRoutes = (state, routerId, pageId): SitemapEntry[] =>
  state.dynamicPages.cachedInnerRoutes[
    getInnerRoutesCacheKey(routerId, pageId)
  ];

export {
  getRouterData,
  getCurrentRouteInfo,
  getRouterId,
  getInnerRoutes,
  shouldPreventFetch,
  getSelectedInnerRoute,
  isInInnerRoutesFetchProcess,
  getInnerRoutesCount,
  getCachedInnerRoutes,
};
