import _ from 'lodash';
import * as util from '@/util';
import { editorStateSel } from './rootSelectors';
import { createSelector } from './selector';

import * as selection from '../selection/selection';
import * as attachCandidate from '../attachCandidate/attachCandidate';

const { getSelectedCompsRefs } = selection.selectors;
const { selectAttachCandidateComponent } = attachCandidate.selectors;

export const selectedCompsSel = createSelector(
  [editorStateSel],
  getSelectedCompsRefs,
);

export const singleSelectedCompSel = createSelector([selectedCompsSel], _.head);

export const attachCandidateSel = createSelector(
  [editorStateSel],
  selectAttachCandidateComponent,
);

export const isMultiselectSel = createSelector(
  [selectedCompsSel],
  util.array.isMultiselect,
);
