import * as draggableSlotsActionTypes from './draggableSlotsActionTypes';
import type { DraggableSlotId } from './draggableSlotsReducer';
import type { Box } from 'types/core';
import { translatePositionToViewerCoordinates } from '../../domMeasurements/domMeasurementsSelectors';

export const registerDragComponent = (
  slotId: string,
  initialPosition: Box,
) => ({
  type: draggableSlotsActionTypes.REGISTER_DRAG_COMPONENT,
  slotId,
  initialPosition,
});

export const updateDragComponentPosition = (
  slotId: string,
  newPosition: Box,
) => ({
  type: draggableSlotsActionTypes.UPDATE_DRAG_COMPONENT_POSITION,
  slotId,
  newPosition,
});

export const unregisterDragComponent = (slotId: string) => ({
  type: draggableSlotsActionTypes.UNREGISTER_DRAG_COMPONENT,
  slotId,
});

export const getDraggableSlotsActions = (
  editorAPI: AnyFixMe,
  slotId: DraggableSlotId,
) => {
  return {
    registerDragComponent: (initialPosition: Box) => {
      const translatedPosition = translatePositionToViewerCoordinates(
        editorAPI,
        initialPosition,
      );
      editorAPI.store.dispatch(
        registerDragComponent(slotId, translatedPosition),
      );
    },
    updateDragComponentPosition: (newPosition: Box) => {
      const translatedPosition = translatePositionToViewerCoordinates(
        editorAPI,
        newPosition,
      );
      editorAPI.store.dispatch(
        updateDragComponentPosition(slotId, translatedPosition),
      );
    },
    unregisterDragComponent: () => {
      editorAPI.store.dispatch(unregisterDragComponent(slotId));
    },
  };
};
