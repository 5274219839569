import _ from 'lodash';
import * as actionTypes from './leftTreeActionTypes';

const { OVERRIDE_LEFT_TREE } = actionTypes;

export const leftTreeInitialState = {
  overridden: {
    overriddenItems: undefined as AnyFixMe,
    shouldShow: true,
  },
};

const reducer = (leftTree = leftTreeInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case OVERRIDE_LEFT_TREE:
      const overridden = action?.overridden ?? leftTree.overridden;
      return _.defaultsDeep({}, { overridden }, leftTree);
    default:
      return leftTree;
  }
};

export default reducer;
