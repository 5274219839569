import { getUserAccounts } from '@wix/ambassador-identity-account-v2-account/http';
import { HttpClient } from '@wix/http-client';

const httpClient = new HttpClient();

const queryUserAccounts = async (userId: string) => {
  const { data } = await httpClient.request(getUserAccounts({ userId }));
  return data.accounts;
};

const getUserWorkspaces = async (userId: string) => {
  const userAccounts = await queryUserAccounts(userId);
  return userAccounts.filter(
    ({ accountId, accountOwner }: AnyFixMe) => accountId !== accountOwner,
  );
};

export const accountServerFacade = {
  getUserWorkspaces,
};
