import * as actionTypes from './hoverBoxActionTypes';
import type { CompRef } from 'types/documentServices';

export interface HoverBoxState {
  hoveredComp: CompRef;
  hoverBoxOverlay: boolean;
}

export const hoverBoxInitialState: HoverBoxState = {
  hoveredComp: null,
  hoverBoxOverlay: false,
};

const hoverBoxReducer = (hoverBox = hoverBoxInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case actionTypes.SET_HOVER_BOX:
      return {
        ...hoverBox,
        hoveredComp: action.hoveredComp,
        hoverBoxOverlay: action.hoverBoxOverlay,
      };
    case actionTypes.CLEAR_HOVER_BOX:
      return {
        ...hoverBox,
        hoveredComp: null,
        hoverBoxOverlay: false,
      };
    default:
      return hoverBox;
  }
};

export default hoverBoxReducer;
