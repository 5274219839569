import _ from 'lodash';
import constants from '@/constants';
import actionTypes from './testSiteStatusActionTypes';

const { PROGRESS_STATUS } = constants;

const isValidStatus = (status: AnyFixMe) =>
  // eslint-disable-next-line you-dont-need-lodash-underscore/values, you-dont-need-lodash-underscore/includes
  _.includes(_.values(PROGRESS_STATUS), status);

export const testSiteStatusInitialState = constants.PROGRESS_STATUS.IDLE;

const reducer = (
  testSiteStatus = testSiteStatusInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_TEST_SITE_STATUS:
      return isValidStatus(action.testSiteStatus)
        ? action.testSiteStatus
        : testSiteStatus;
    default:
      return testSiteStatus;
  }
};

export default reducer;
