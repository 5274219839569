// @ts-nocheck
import * as mobileQabActionTypes from './mobileQabActionTypes';

export default {
  disableMobileQab: () => ({
    type: mobileQabActionTypes.DISABLE_MOBILE_QAB,
  }),
  setQabUploadedSvg: (svgData, compId) => ({
    type: mobileQabActionTypes.SET_QAB_UPLOADED_BUTTON,
    icon: {
      svgData,
      compId,
    },
  }),
};
