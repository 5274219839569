import {
  ensureItemLayoutTypeIs,
  ensureItemLayoutTypeIsNot,
  hasFixedItemLayout,
  hasMeshItemLayout,
  layoutSize,
} from './responsiveLayoutUtils';
import constants from '@/constants';

import type {
  CalculationFormula,
  ComponentLayout,
  FixedItemLayout,
  MeshItemLayout,
  SingleLayoutData,
  UnitSize,
} from 'types/documentServices';
import type { LayoutFullWidthValue, LayoutFullWidthSiteMargin } from '@/layout';

const FULL_WIDTH_FORMULA = '${width} - ${marginLeft} - ${marginRight}';

export function fullWidthWidthCalcFormula({
  siteMargin,
}: {
  siteMargin: UnitSize;
}): CalculationFormula {
  return {
    type: 'CalcFormula',
    value: {
      formula: FULL_WIDTH_FORMULA,
      values: {
        width: layoutSize.percentage(100),
        marginLeft: siteMargin,
        marginRight: siteMargin,
      },
    },
  };
}

export function isFullWidthByWidthFormula(
  layouts: SingleLayoutData,
): layouts is SingleLayoutData & {
  componentLayout: ComponentLayout & {
    width: CalculationFormula;
  };
  itemLayout: MeshItemLayout & {
    justifySelf: 'center';
  };
} {
  return (
    hasMeshItemLayout(layouts) &&
    layouts.componentLayout.width.type === 'CalcFormula' &&
    layouts.componentLayout.width.value.formula === FULL_WIDTH_FORMULA &&
    layouts.itemLayout.justifySelf === 'center'
  );
}

export function isFullWidthByJustifySelfStretch(
  layouts: SingleLayoutData,
): layouts is SingleLayoutData & {
  itemLayout: (FixedItemLayout | MeshItemLayout) & {
    justifySelf: 'stretch';
  };
} {
  return (
    (hasFixedItemLayout(layouts) || hasMeshItemLayout(layouts)) &&
    layouts.itemLayout.justifySelf === 'stretch'
  );
}

function getFullWidthFromLayoutsRaw(layouts: SingleLayoutData) {
  if (!layouts?.componentLayout || !layouts?.itemLayout) {
    return undefined;
  }

  if (isFullWidthByWidthFormula(layouts)) {
    return {
      isFullWidth: true,
      left: layouts.componentLayout.width.value.values.marginLeft,
      right: layouts.componentLayout.width.value.values.marginRight,
    };
  }

  if (isFullWidthByJustifySelfStretch(layouts)) {
    return {
      isFullWidth: true,
      left: layouts.itemLayout.margins.left,
      right: layouts.itemLayout.margins.right,
    };
  }

  return {
    isFullWidth: false,
  };
}

export function isFullWidthByLayouts(
  layouts: SingleLayoutData,
): layouts is SingleLayoutData & {
  componentLayout: ComponentLayout & { width: undefined };
  itemLayout: MeshItemLayout | FixedItemLayout;
} {
  const fullWidth = getFullWidthFromLayoutsRaw(layouts);
  return fullWidth?.isFullWidth;
}

export function getFullWidthFromLayouts(
  layouts: SingleLayoutData,
): LayoutFullWidthValue {
  const fullWidth = getFullWidthFromLayoutsRaw(layouts);
  if (!fullWidth?.isFullWidth) {
    return {
      isFullWidth: false,
    };
  }

  if (fullWidth.left.type !== 'px' && fullWidth.left.type !== 'vw') {
    throw new Error('`left` should be px or vw');
  }

  if (
    fullWidth.left.type !== fullWidth.right.type ||
    fullWidth.left.value !== fullWidth.right.value
  ) {
    // eslint-disable-next-line no-console
    console.error(
      '`left` and `right` for full width layout should be equal.',
      fullWidth,
    );
  }

  return {
    isFullWidth: true,
    siteMargin: {
      value: fullWidth.left.value,
      type: fullWidth.left.type,
    },
  };
}

function recalculateFullWidthByJustifySelfStretch(
  layouts: SingleLayoutData,
  siteMargin: LayoutFullWidthSiteMargin,
): {
  componentLayout?: ComponentLayout;
  itemLayout?: MeshItemLayout | FixedItemLayout;
} {
  ensureItemLayoutTypeIs(layouts.itemLayout, [
    'MeshItemLayout',
    'FixedItemLayout',
  ]);

  const layoutsUpdate = {
    componentLayout: {
      ...layouts.componentLayout,
      width: layoutSize.auto(),
    },
    itemLayout: {
      ...layouts.itemLayout,
      justifySelf: 'stretch' as const,
      isRelativeToContentArea: false,
      left: layoutSize.px(0),
      margins: {
        ...layouts.itemLayout.margins,
        left: siteMargin,
        right: siteMargin,
      },
    },
  };

  const isAlreadyFullWidth = isFullWidthByJustifySelfStretch(layouts);

  // if component is not full width, mark it as full width
  // else update only site margin in itemLayout.margins
  return !isAlreadyFullWidth
    ? {
        componentLayout: layoutsUpdate.componentLayout,
        itemLayout: layoutsUpdate.itemLayout,
      }
    : { itemLayout: layoutsUpdate.itemLayout };
}

function recalculateFullWidthByWidthFormula(
  layouts: SingleLayoutData,
  siteMargin: LayoutFullWidthSiteMargin,
): {
  componentLayout?: ComponentLayout;
  itemLayout?: MeshItemLayout;
} {
  ensureItemLayoutTypeIs(layouts.itemLayout, ['MeshItemLayout']);

  const layoutsUpdate = {
    componentLayout: {
      ...layouts.componentLayout,
      width: fullWidthWidthCalcFormula({
        siteMargin,
      }),
    },
    itemLayout: {
      ...layouts.itemLayout,
      justifySelf: 'center' as const,
      isRelativeToContentArea: false,
      left: layoutSize.px(0),
    },
  };

  const isAlreadyFullWidth = isFullWidthByWidthFormula(layouts);

  // if component is not full width, mark it as full width
  // else update only site margin in componentLayout.width formula
  return !isAlreadyFullWidth
    ? {
        componentLayout: layoutsUpdate.componentLayout,
        itemLayout: layoutsUpdate.itemLayout,
      }
    : { componentLayout: layoutsUpdate.componentLayout };
}

const fullWidthWithWidthFormulaByType = new Set([
  constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
]);
const notFullWidthWithSiteWidthByType = new Set([
  constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
]);

export function recalculateFullWidthLayouts(
  layouts: SingleLayoutData,
  siteMargin: LayoutFullWidthSiteMargin,
  { componentType }: { componentType: string },
) {
  if (fullWidthWithWidthFormulaByType.has(componentType)) {
    return recalculateFullWidthByWidthFormula(layouts, siteMargin);
  }

  return recalculateFullWidthByJustifySelfStretch(layouts, siteMargin);
}

export function recalculateNotFullWidthLayoutsAfterFullWidth(
  layouts: SingleLayoutData,
  xAndWidth: { width: number; x?: number },
  { componentType }: { componentType: string },
): Partial<SingleLayoutData> {
  if (!isFullWidthByLayouts(layouts)) {
    throw new Error('layouts is not full width');
  }

  ensureItemLayoutTypeIsNot(
    layouts.itemLayout,
    ['FixedItemLayout'],
    '`FixedItemLayout` component cannot be unstretched' +
      `\nIf it's not Header or Footer, please contact the developer`,
  );
  ensureItemLayoutTypeIs(layouts.itemLayout, ['MeshItemLayout']);

  const isSiteWidthComponent =
    notFullWidthWithSiteWidthByType.has(componentType);

  const [left, width] = isSiteWidthComponent
    ? [layoutSize.px(0), layoutSize.siteWidth()]
    : [
        typeof xAndWidth.x === 'number'
          ? layoutSize.px(xAndWidth.x)
          : // TODO: left & right were removed from "MeshItemLayout" - https://github.com/wix-private/document-management/pull/24507
            // @ts-expect-error
            layouts.itemLayout.left,
        layoutSize.px(xAndWidth.width),
      ];

  return {
    componentLayout: {
      ...layouts.componentLayout,
      width,
    },
    itemLayout: {
      ...layouts.itemLayout,
      left,
      isRelativeToContentArea: true,
      justifySelf: undefined,
      margins: {
        ...layouts.itemLayout.margins,
        left: undefined,
        right: undefined,
      },
    },
  };
}
