import * as userPreferences from '../userPreferences/userPreferences';
import { USER_HAS_SEEN_THE_OPENAI_TERMS } from './constants';

import type { ThunkAction } from 'types/redux';

const setIsAiTermsAndConditionsShown =
  <T>(value: T): ThunkAction =>
  (dispatch) => {
    dispatch(
      userPreferences.actions.setSessionUserPreferences(
        USER_HAS_SEEN_THE_OPENAI_TERMS,
        value,
      ),
    );

    return value;
  };

export { setIsAiTermsAndConditionsShown };
