import _ from 'lodash';
import * as actionTypes from './dynamicPagesActionTypes';
import type { RouterDynamicPages } from 'types/documentServices';
import type { SitemapEntry, CurrentRouteInfo } from 'types/documentServices';
import { WIX_CODE } from '@wix/app-definition-ids';
import { getInnerRoutesCacheKey } from './dynamicPagesUtils';

export interface InnerRoutesCounts {
  [pageId: string]: number | undefined;
}

export type CachedInnerRoutes = Record<string, SitemapEntry[]>;

export interface DynamicPagesState {
  innerRoutes: SitemapEntry[];
  selectedInnerRoute: string | null;
  preventFetch: boolean;
  routerData: RouterDynamicPages | null;
  inInnerRoutesFetchProcess: boolean;
  currentRouteInfo: Partial<CurrentRouteInfo>;
  innerRoutesCounts: InnerRoutesCounts;
  cachedInnerRoutes: CachedInnerRoutes;
}

const {
  SET_INNER_ROUTES,
  RESET_INNER_ROUTES,
  SET_ROUTER_DATA,
  RESET_ROUTER_DATA,
  SET_SELECTED_INNER_ROUTE,
  RESET_SELECTED_INNER_ROUTE,
  SET_INNER_ROUTES_FETCH_PROCESS,
  SET_PREVENT_FETCH,
  RESET_INNER_ROUTES_FETCH_PROCESS,
  SET_CURRENT_ROUTE_INFO,
  SET_INNER_ROUTES_COUNT,
  CLEAR_INNER_ROUTES_COUNT,
  SET_CACHED_INNER_ROUTES,
} = actionTypes;

export const dynamicPagesInitialState: DynamicPagesState = {
  innerRoutes: [],
  selectedInnerRoute: null,
  preventFetch: false,
  routerData: null,
  inInnerRoutesFetchProcess: false,
  currentRouteInfo: {},
  innerRoutesCounts: {},
  cachedInnerRoutes: {},
};

const reducer = (
  dynamicPages = dynamicPagesInitialState,
  action: AnyFixMe,
): DynamicPagesState => {
  switch (action.type) {
    case SET_INNER_ROUTES:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, { innerRoutes: action.innerRoutes });

    case RESET_INNER_ROUTES:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, { innerRoutes: [] });
    case SET_ROUTER_DATA:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, { routerData: action.routerData });
    case RESET_ROUTER_DATA:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, {
        routerData: null,
        innerRoutes: [],
        selectedInnerRoute: null,
        currentRouteInfo: null,
      });
    case SET_SELECTED_INNER_ROUTE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, {
        selectedInnerRoute: action.selectedInnerRoute,
      });
    case SET_CURRENT_ROUTE_INFO:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, {
        currentRouteInfo: action.currentRouteInfo,
      });
    case RESET_SELECTED_INNER_ROUTE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, { selectedInnerRoute: null });

    case SET_INNER_ROUTES_FETCH_PROCESS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, { inInnerRoutesFetchProcess: true });

    case SET_PREVENT_FETCH:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, { preventFetch: action.shouldPrevent });

    case RESET_INNER_ROUTES_FETCH_PROCESS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, dynamicPages, { inInnerRoutesFetchProcess: false });
    case SET_INNER_ROUTES_COUNT:
      return {
        ...dynamicPages,
        innerRoutesCounts: {
          ...dynamicPages.innerRoutesCounts,
          ...action.innerRoutesCounts,
        },
      };
    case CLEAR_INNER_ROUTES_COUNT:
      return {
        ...dynamicPages,
        innerRoutesCounts: {},
      };
    case SET_CACHED_INNER_ROUTES:
      return action.routerData?.appDefinitionId === WIX_CODE
        ? dynamicPages
        : {
            ...dynamicPages,
            cachedInnerRoutes: {
              ...dynamicPages.cachedInnerRoutes,
              [getInnerRoutesCacheKey(
                action.routerData?.routerId,
                action.pageId,
              )]: action.innerRoutes,
            },
          };

    default:
      return dynamicPages;
  }
};

export default reducer;
