import { documentStyles } from '@/util';
import actionTypes from './configActionTypes';

const setTopBarHeightStyleProperty = (value: number) => {
  documentStyles.setProperty('--g-top-bar-height', `${value}px`);
};

const setTopBarHeight = (value: number) => (dispatch: AnyFixMe) => {
  setTopBarHeightStyleProperty(value);

  dispatch({
    type: actionTypes.SET_TOP_BAR_HEIGHT,
    payload: {
      value,
    },
  });
};

export default {
  setTopBarHeight,
};
