import type {
  CompRef,
  DocumentServicesObject,
  Features,
} from 'types/documentServices';

const NAME_KEY = 'naming' as const;

function getStateName(pointer: CompRef, getComponentFeature: Features['get']) {
  return getComponentFeature(pointer, NAME_KEY)?.name;
}

function getSessionStateTitle(
  statePointer: CompRef,
  dsRead: DocumentServicesObject,
): string {
  // @ts-expect-error undocumented attribute sessionState
  return dsRead.platform.sessionState.get(statePointer);
}

function getPrimaryRole(statePointer: CompRef, dsRead: DocumentServicesObject) {
  return dsRead.platform.controllers.connections.getPrimaryConnection(
    statePointer,
  )?.role;
}

function getTitle(statePointer: CompRef, dsRead: DocumentServicesObject) {
  return (
    getSessionStateTitle(statePointer, dsRead) ||
    getPrimaryRole(statePointer, dsRead)
  );
}

function getTitleFromManifest(
  statePointer: CompRef,
  dsRead: DocumentServicesObject,
) {
  const data =
    dsRead.platform.controllers.getConnectedComponentStageData(statePointer);
  return data?.displayName;
}

function getStateTitle(
  dsRead: DocumentServicesObject,
  statePointer: CompRef,
  getComponentFeature: Features['get'],
) {
  return (
    getStateName(statePointer, getComponentFeature) ||
    getTitleFromManifest(statePointer, dsRead) ||
    getTitle(statePointer, dsRead)
  );
}

function getSelectedStateId(
  multiStateBoxRef: CompRef,
  dsRead: DocumentServicesObject,
) {
  return dsRead.components.data.get(multiStateBoxRef)?.selectedStateId;
}

function getStates(multiStateBoxRef: CompRef, dsRead: DocumentServicesObject) {
  return dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
    multiStateBoxRef,
  );
}

export { getSelectedStateId, getStateTitle, getStates };
