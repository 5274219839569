import _ from 'lodash';
import ascendActionTypes from './ascendActionTypes';
import type ascendConstants from './constants';

const {
  SET_CURRENT_ASCEND_DATA,
  SET_ASCEND_FETCH_STATUS,
  SET_REMOVE_ASCEND_FEATURES,
} = ascendActionTypes;

export interface AscendState {
  ascendPlan: string;
  isTopPremium: boolean;
  premiumPlan: string;
  ascendProductId: string;
  fetchStatus: ValueOf<typeof ascendConstants.ASCEND_FETCH_STATUS>;
  removeAscendFeatures: boolean;
}

export const ascendInitialState: AscendState = {
  ascendPlan: null,
  premiumPlan: null,
  isTopPremium: false,
  fetchStatus: null,
  ascendProductId: null,
  removeAscendFeatures: false,
};

const reducer = (
  state: AscendState = ascendInitialState,
  action: AnyFixMe,
): AscendState => {
  switch (action.type) {
    case SET_CURRENT_ASCEND_DATA:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, state, {
        ascendPlan: action.ascendPlan,
        isTopPremium: action.isTopPremium,
        premiumPlan: action.premiumPlan,
        ascendProductId: action.ascendProductId,
      });
    case SET_ASCEND_FETCH_STATUS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, state, {
        fetchStatus: action.fetchStatus,
      });
    case SET_REMOVE_ASCEND_FEATURES:
      return { ...state, removeAscendFeatures: action.removeAscendFeatures };
    default:
      return state;
  }
};

export default reducer;
