import { stageLoaderActionTypes as actionTypes } from './stageLoaderActionTypes';
import type { StageLoaderActionTypes } from './stageLoaderActionTypes';

export interface StageLoaderState {
  isLoading: boolean;
}

export const stageLoaderInitialState: StageLoaderState = {
  isLoading: false,
};

const reducer = (
  stageLoaderState: StageLoaderState = stageLoaderInitialState,
  action: StageLoaderActionTypes,
) => {
  switch (action.type) {
    case actionTypes.START_STAGE_LOADING:
      return {
        isLoading: true,
      };
    case actionTypes.END_STAGE_LOADING:
      return {
        isLoading: false,
      };
    default:
      return stageLoaderState;
  }
};

export default reducer;
