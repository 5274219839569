import type { CompRef, DSRead } from 'types/documentServices';

export function layoutGetFontScale(dsRead: DSRead, compRef: CompRef): number {
  if (!dsRead.components.is.exist(compRef)) {
    return undefined;
  }

  // NOTE: `fontScale` in component properties could be undefined
  // while in the old layout it was always defined (with default value as 1).
  return dsRead.components.properties.get(compRef)?.fontScale ?? 1;
}
