export default {
  YOUTUBE: 'e19084b7-a28f-4fbe-8d67-bf8087516aef',
  VIMEO: '80a85af5-5c8e-4301-98d1-5d2689c58180',
  DAILYMOTION: 'f2258f3e-24e6-4da7-9428-904cc5d8f506',
  FACEBOOK: 'fe26ec14-7929-4cc0-8321-260af67b1359',
  FACEBOOK_LIKE: 'f299b468-97e9-468a-9dbf-4360cc1edd00',
  FACEBOOK_LIKE_BOX: '12e9605f-34c0-4e1f-8218-2981934d3783',
  FACEBOOK_COMMENTS: '9be6f749-5cc7-4057-aac4-2cabdd212ccc',
  DISQUS_COMMENTS: '10f0e420-c0c0-49c0-a165-73be4c62543b',
  TWITTER_TWEET: '3b489b6b-cac4-403f-99cf-d935cdec7105',
  TWITTER_FOLLOW: '47114922-48b4-42d8-ac5f-4f7b9c7d783e',
  PINTEREST_PIN_IT: 'd2d6465a-9c4f-407e-8224-d0a2f5d9c3fb',
  PINTEREST_FOLLOW: '1318a93b-f092-4b24-8115-1fc10c7a784c',
  PINTEREST_SHARE: '83fcadfc-923a-4b8e-b9f5-a5061f5f8843',
  SINGLE_AUDIO_PLAYER: 'e1dfbad5-0796-4ddd-aac6-c6f79f62b42d',
  YOUTUBE_SUBSCRIBE: '92ef39f8-988c-4717-b3ef-f472d126875f',
  LOGIN_SOCIAL_BAR: '7d044565-2386-4c2c-978e-02cc215691d0',
  LOGIN_SOCIAL_BAR_MOBILE: 'd2dc201e-c5da-4498-a676-3d2490bf353d',
  SITE_REGION_CONTAINER: '7e8175ac-5024-4e45-b7ef-2063be942235',
  SITE_REGION_CONTAINER_MOBILE: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
  HEADER: 'f997c671-a3e7-4d7c-b73b-2c043b65d77f',
  HEADER_SCROLL: '90e994a1-5c83-47d0-b8db-5cf9f86d40d0',
  HEADER_SCROLL_MOBILE: '90e994a1-5c83-47d0-b8db-5cf9f86d40d0',
  FOOTER: '3b70dfa1-5e34-4eaa-811f-b83cf76b1a6a',
  PAGE: 'cae5851c-672d-40a2-a29e-a44946f8a131',
  FLICKR: 'dd003f08-0c74-498a-9ffb-5f5fac508642',
  VERTICAL_ANCHORS_MENU: '40b174e0-3283-4ecf-92a5-f0f65a20365e',
  VERTICAL_ANCHORS_MENU_SECTIONS: 'ee569a77-c328-4a20-98a9-02e403ab4211',
  EXPANDABLE_MENU: 'd0a94df9-5576-473e-af7f-b2e41d33596c', // change this if you want to support expandableMenu in desktop
  EXPANDABLE_MENU_MOBILE: 'd0a94df9-5576-473e-af7f-b2e41d33596c',
  STRIP_CONTAINER_SLIDE_SHOW: '55c20512-5604-4388-b6fd-d25454dfd826',
  COLUMN: 'b67e9bb6-d4b9-4814-b2eb-d94975e76d8e',
  STRIP_COLUMNS_CONTAINER: '5fc405d1-8ff4-4c3f-8329-3e47c77e4343',
  STRIP_COLUMNS_CONTAINER_MOBILE: 'c251975c-eeae-4e9c-9b0c-245842fa4468',
  STRIP_COLUMNS_CONTAINER_SINGLE: 'e36f50fe-e1e4-4320-b7e4-f298a67aff4a',
  STRIP_COLUMNS_CONTAINER_MOBILE_SINGLE: 'aef38ead-ce3e-41dc-9cde-8827ceabea84',
  COLUMN_MOBILE: '0e8d0168-cd07-4855-9924-fa0c44d8fd4d',
  POPUP_CONTAINER: '04442ce1-d558-47ec-86fc-9e1017a077fb',
  POPUP_OVERLAY: 'bb1a4e54-a6fc-4d77-9371-3d76f96b3c29',
  CLOSE_BUTTON: '24562858-3963-4f5f-aca2-ce4ca89e93da',
  TINY_MENU: '752a7bbb-7d2c-45e3-985d-00f892408259',
  TEXT_INPUT_HELP: '68dfc0f5-8625-4861-bc16-c35675755bd0',
  TEXT_INPUT_MOBILE_HELP: 'cbed441f-7e63-4dcb-a5eb-77d009aed980',
  TEXT_AREA_HELP: '15b12a4b-f24f-41d5-b4d1-ea6f76d3e399',
  TEXT_AREA_MOBILE_HELP: '15b12a4b-f24f-41d5-b4d1-ea6f76d3e399',
  RICH_TEXT_BOX_HELP: 'ca3c42af-4775-42e4-92d5-8828539c6179',
  RICH_TEXT_BOX_MOBILE_HELP: 'ca3c42af-4775-42e4-92d5-8828539c6179',
  COMBOBOX_INPUT_HELP: 'af64b788-2b91-49c1-9032-fef95c3ea57e',
  COMBOBOX_INPUT_MOBILE_HELP: '12ba2f07-3e06-432b-8520-acbde0a07a11',
  RADIO_BUTTON_HELP: 'e12528d1-d1ab-42c7-9251-5424dd0a4ede',
  RADIO_BUTTON_MOBILE_HELP: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
  CHECKBOX: '955d237c-934a-4d76-9508-eeb9f3904394',
  GRID: '18f735ee-b53e-462e-b206-4adce65aed13',
  GRID_MOBILE: 'b337a3f8-1a4e-4f31-afd5-185498884404',
  MEDIA_PLAYER: '9a197b52-49bb-46fd-b649-b4fee11cbe72',
  MEDIA_PLAYER_ALPHA: '932f2fa2-f0db-4f71-83a6-4f53a33cc373',
  MEDIA_PLAYER_MOBILE: 'bd794a00-4f6c-4b43-842a-09f5a5854356',
  MEDIA_BIG_PLAY: 'd5d8f46d-b53b-4e14-8580-c7832132a4d5',
  MEDIA_BIG_PLAY_ALPHA: '74dfea55-5733-43d0-b042-94bf9cd32a59',
  MEDIA_CONTROLS: 'aeb4e50c-d88e-4200-81b0-f119bab98cf9',
  FILE_UPLOADER: 'd4107177-08e7-4d84-a23a-ca0489f991dc',
  QUICK_ACTION_BAR: '38866312-318a-4ba3-8c3b-4b37b0b4440c',
  MEDIA_CONTAINER: '9cc6c484-46ea-4aef-8067-0fe3be5ca72a',
  MEDIA_CONTAINER_MOBILE: '9d1e153f-b7f4-475f-bc3e-a17cc1d4b484',
  MEDIA_BOX: 'cbcc3945-f2e0-4347-ad75-fe2c32c6819e',
  MEDIA_BOX_MOBILE: 'cbcc3945-f2e0-4347-ad75-fe2c32c6819e',
  REPEATER: '9f5b63bb-c4c8-4818-9ecb-49002ed99190',
  REPEATER_MOBILE: '284b9abb-0337-40a8-9392-809f954dede0',
  LANGUAGE_SELECTOR: 'cff97ce5-b33e-47e9-82ba-7394de27f516',
  LANGUAGE_SELECTOR_MOBILE: '50f4c4ea-95ef-4922-9c20-846f5b2a9ad5',
  FORM_CONTAINER: '9369198c-90a1-4f21-b75d-2089c9759171',
  STATE_BOX: '340e3cee-05f5-4b1d-958a-700bef679365',
};
