export const biEvents = {
  tabs_change_tab: {
    evid: 534,
    src: 38,
    fields: {
      origin: 'string',
      component_id: 'string',
      component_type: 'string',
      page_id: 'string',
    },
  },
};
