const SET_IS_ADDING_COMPONENT_IN_PROGRESS =
  'hidden-items-set-is-adding-component-in-progress';
const SET_HIDDEN_ITEMS_HOVERED_COMPONENT =
  'hidden-items-set-hidden-items-hovered-component';
const SET_HIDDEN_ITEMS_INTERACTIONS_COMPS =
  'hidden-items-set-interactions-hidden-comps';
const REMOVE_HIDDEN_ITEM_FROM_INTERACTIONS_LIST =
  'hidden-items-remove-component-from-interactions-list';
const SET_INTERACTIONS_LIST_AND_COUNTER =
  'set-interactions-hidden-items-and-counter';

export {
  SET_IS_ADDING_COMPONENT_IN_PROGRESS,
  SET_HIDDEN_ITEMS_HOVERED_COMPONENT,
  SET_HIDDEN_ITEMS_INTERACTIONS_COMPS,
  REMOVE_HIDDEN_ITEM_FROM_INTERACTIONS_LIST,
  SET_INTERACTIONS_LIST_AND_COUNTER,
};
