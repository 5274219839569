// @ts-nocheck
import * as notificationsActions from '../notifications/notificationsActions';
import setIsReconnecting from './setIsReconnecting';
import * as localModeSelectors from './localModeSelectors';
const { getIsReconnecting } = localModeSelectors;
const localServerEventsHandlerCreator = (editorAPI) => {
  const closePanel = (panelName) =>
    editorAPI.panelManager.closePanelByName(panelName);

  const handleLocalServerSocketReconnecting = () => {
    if (!getIsReconnecting(editorAPI.store.getState())) {
      editorAPI.store.dispatch(setIsReconnecting(true));
      editorAPI.store.dispatch(notificationsActions.closeAllNotifications());
      editorAPI.panelManager.openPanel(
        'localMode.panels.reconnectPanel',
        {},
        true,
      );
    }
  };
  const handleLocalServerSocketReconnected = () => {
    editorAPI.store.dispatch(setIsReconnecting(false));
    editorAPI.panelManager.closePanelByName('localMode.panels.reconnectPanel');
    editorAPI.store.dispatch(
      notificationsActions.showUserActionNotification({
        message: 'Notifications_LocalMode_Reconnected',
        title: 'Reconnected successfully',
        type: 'info',
      }),
    );
  };
  const handleLocalServerDocumentUpdated = () => {
    editorAPI.panelManager.openPanel(
      'localMode.panels.localEditorUnsyncedPanel',
      { closePanel },
      true,
    );
  };

  const handleEvents = (eventObject) => {
    const { event } = eventObject;
    switch (event) {
      case 'SOCKET_RECONNECTING':
        handleLocalServerSocketReconnecting();
        break;
      case 'SOCKET_RECONNECTED':
        handleLocalServerSocketReconnected();
        break;
      case 'LOCAL_DOCUMENT_UPDATED':
        handleLocalServerDocumentUpdated();
        break;
    }
  };
  return {
    handleEvents,
  };
};

export default localServerEventsHandlerCreator;
