import * as platformEvents from 'platformEvents';

import type { AfterPlugin } from 'types/editorPlugins';

const appControllerAddPlugin: AfterPlugin = (
  editorAPI,
  { compRef: controllerRef, origin },
) => {
  const controllerData = editorAPI.components.data.get(controllerRef);
  const controllerAppData = editorAPI.dsRead.platform.getAppDataByAppDefId(
    controllerData.applicationId,
  );
  if (controllerAppData) {
    editorAPI.dsActions.platform.notifyApplication(
      controllerAppData.applicationId,
      platformEvents.factory.controllerAdded({ controllerRef, origin }),
    );
  }
};

export default appControllerAddPlugin;
