import constants from '@/constants';
import type { CompRef } from '@wix/document-services-types';
import type {
  ConnectedComponentConfig,
  NativePanelKeys,
} from '@wix/platform-editor-sdk';
import _ from 'lodash';
import type { PanelProps } from './types';

const createComponentPanelPropsWithConfiguration = (
  panelName: string,
  props: {
    selectedComponents?: CompRef[];
  } & PanelProps,
  manifest: ConnectedComponentConfig,
): PanelProps => {
  return _.merge(
    props?.selectedComponents
      ? { selectedComponent: props?.selectedComponents }
      : {},
    getNativePanelConfigurationProp(panelName, manifest) ?? {},
    props,
  );
};

const getNativePanelConfigurationProp = (
  panelName: string,
  manifest: ConnectedComponentConfig,
) => {
  const panelNameSufix = panelName.replace(/.*\./, '');
  const nativePanelName: NativePanelKeys = Object.keys(
    constants.PANELS.NATIVE_PANELS,
  ).find(
    (key) => (constants.PANELS.NATIVE_PANELS as any)[key] === panelNameSufix,
  ) as NativePanelKeys;

  if (!nativePanelName || !manifest?.panel?.[nativePanelName]) {
    return undefined;
  }

  return {
    configuration: manifest.panel?.[nativePanelName],
  };
};

export default createComponentPanelPropsWithConfiguration;
