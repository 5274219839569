import type { CompLayout } from 'types/documentServices';
import type { PinDockingDirection } from './types';

function isDockedVerticalCenter(dockVal: PinDockingDirection) {
  return ['LEFT', 'RIGHT'].includes(dockVal);
}

function isDockedHorizontalCenter(dockVal: PinDockingDirection) {
  return ['TOP', 'BOTTOM'].includes(dockVal);
}

function isDockedCorner(dockVal: PinDockingDirection) {
  return ['TOP_LEFT', 'TOP_RIGHT', 'BOTTOM_LEFT', 'BOTTOM_RIGHT'].includes(
    dockVal,
  );
}

function isDockedMiddle(dockValue: PinDockingDirection) {
  return dockValue === 'MIDDLE';
}

/**
 * source: https://github.com/wix-private/santa-editor/pull/3429/files
 */
export function getPinDockingMinMaxHorizontalOffset(
  dockVal: PinDockingDirection,
) {
  let minVal: number;
  let maxVal: number;

  if (isDockedHorizontalCenter(dockVal) || isDockedMiddle(dockVal)) {
    minVal = -500;
    maxVal = 500;
  }
  if (isDockedVerticalCenter(dockVal)) {
    minVal = 0;
    maxVal = 300;
  }
  if (isDockedCorner(dockVal)) {
    minVal = 0;
    maxVal = 300;
  }

  return {
    min: minVal,
    max: maxVal,
  };
}

/**
 * source: https://github.com/wix-private/santa-editor/pull/3429/files
 */
export function getPinDockingMinMaxVerticalOffset(
  dockVal: PinDockingDirection,
) {
  let minVal: number;
  let maxVal: number;

  if (isDockedVerticalCenter(dockVal) || isDockedMiddle(dockVal)) {
    minVal = -150;
    maxVal = 150;
  }
  if (isDockedHorizontalCenter(dockVal)) {
    minVal = 0;
    maxVal = 300;
  }
  if (isDockedCorner(dockVal)) {
    minVal = 0;
    maxVal = 300;
  }

  return {
    min: minVal,
    max: maxVal,
  };
}

/**
 * source: https://github.com/wix-private/santa-editor/blob/5c01acf4358660ead7e77bb21fd666334ae0e74f/packages/util/src/main/layout/pinModeUtils.js#L18-L42
 */
export function hasLayoutDockingSizeRestrictionsIssues(
  layout: Pick<CompLayout, 'width' | 'height'>,
  dockingDirection: PinDockingDirection,
) {
  switch (dockingDirection) {
    case 'TOP_LEFT':
    case 'TOP_RIGHT':
    case 'BOTTOM_LEFT':
    case 'BOTTOM_RIGHT':
      if (layout.width >= 500 && layout.height >= 500) {
        return true;
      }
      break;
    case 'TOP':
    case 'BOTTOM':
      if (layout.height >= 300) {
        return true;
      }
      break;
    case 'LEFT':
    case 'RIGHT':
      if (layout.width >= 500) {
        return true;
      }
      break;
  }
  return false;
}
