import { layoutSize } from '@/layoutUtils';

import type { EditorAPI } from '@/editorAPI';
import type {
  CompRef,
  MeshGridItemLayout,
  Rect,
  RecalculateMeshGridResults,
  ResponsiveLayoutObject,
  AbsoluteLayout,
} from 'types/documentServices';

export function recalculateContainerMeshGrid(
  editorAPI: EditorAPI,
  containerRef: CompRef,
  {
    containerRect,
    containerChildrenRefs,
    containerChildrenRectsMap,
  }: {
    containerRect: Rect;
    containerChildrenRefs: CompRef[];
    containerChildrenRectsMap: Map<string, Rect>;
  },
) {
  if (
    containerChildrenRefs.every((compRef) =>
      editorAPI.sections.isSection(compRef),
    )
  ) {
    return recalculateSectionsContainerMeshGrid(editorAPI, {
      containerChildrenRefs,
    });
  }

  const { gridMargins, meshGridItemsLayouts }: RecalculateMeshGridResults =
    // TODO: ⚠️ DS should return correct type
    (editorAPI.components.responsiveLayout as any as ResponsiveLayoutObject)
      // TODO: "recalculateMeshGrid" was removed from DM public API - https://github.com/wix-private/document-management/pull/24507
      // @ts-expect-error
      .recalculateMeshGrid(
        {
          containerPointer: containerRef,
          containerClientRect: containerRect,
          isMobileView: containerRef.type === 'MOBILE',
        },
        Object.fromEntries(
          containerChildrenRefs.map((compRef) => [
            compRef.id,
            // TODO: ⚠️ DS should accept Rect instead of AbsoluteLayout
            [
              compRef,
              containerChildrenRectsMap.get(compRef.id) as AbsoluteLayout,
            ],
          ]),
        ),
      );

  return {
    containerLayout: {
      gridMargins,
    },
    itemLayoutByCompId: new Map(
      containerChildrenRefs.map((compRef) => [
        compRef.id,
        meshGridItemsLayouts[compRef.id][1],
      ]),
    ),
  };
}

function recalculateSectionsContainerMeshGrid(
  editorAPI: EditorAPI,
  {
    containerChildrenRefs,
  }: {
    containerChildrenRefs: CompRef[];
  },
) {
  return {
    containerLayout: {
      gridMargins: {
        top: layoutSize.px(0),
        bottom: layoutSize.px(0),
      },
    },
    itemLayoutByCompId: new Map<string, MeshGridItemLayout>(
      containerChildrenRefs
        .sort(
          (a, b) =>
            editorAPI.components.arrangement.getCompIndex(a) -
            editorAPI.components.arrangement.getCompIndex(b),
        )
        .map((compRef, index) => [
          compRef.id,
          {
            gridArea: {
              rowStart: index + 1,
              rowEnd: index + 2,
            },
            margins: {
              top: layoutSize.px(0),
              bottom: layoutSize.px(0),
            },
          },
        ]),
    ),
  };
}
