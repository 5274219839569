import type { PathsState } from '@wix/add-panel-component';
import { ActionType, type NewAddPanelAction } from './newAddPanelActionTypes';

export interface NewAddPanelState {
  pathsState: PathsState | null;
}

export const newAddPanelInitialState: NewAddPanelState = {
  pathsState: null,
};

export const newAddPanelReducer = (
  state: NewAddPanelState = newAddPanelInitialState,
  action: NewAddPanelAction,
) => {
  switch (action.type) {
    case ActionType.SetPathsState:
      return { ...state, pathsState: action.payload.pathsState };
    default:
      return state;
  }
};
