import * as util from '@/util';
import actionTypes from './ascendActionTypes';
import ascendConstants from './constants';
import constants from '@/constants';
import type { ThunkAction } from 'types/redux';

const ASCEND_API_BASE_URL = '/_api/premium-assets/v1';

const fetchCurrentAscendData =
  (): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const msid = editorAPI.site.getMetaSiteId();
    const endpoint = `assets?filter.assetType.assetTypes=PLAN&filter.assetType.assetTypes=CATALOG_PRODUCT&filter.serviceStatus.serviceStatuses=ACTIVE&filter.metaSite.metaSiteIds=${msid}`;
    const instance = editorAPI.dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance;
    dispatch({
      type: actionTypes.SET_ASCEND_FETCH_STATUS,
      fetchStatus: ascendConstants.ASCEND_FETCH_STATUS.LOADING,
    });
    return util.http
      .fetch(`${ASCEND_API_BASE_URL}/${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: instance,
        },
      })
      .then((res: AnyFixMe) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then(({ assets }: AnyFixMe) => {
        const currentAscendData = (assets || []).find(
          (asset: AnyFixMe) =>
            asset?.catalogProductData?.productData?.productTypeId ===
            ascendConstants.ASCEND_PRODUCT_ID,
        );

        const ascendProductData =
          currentAscendData?.catalogProductData?.productData;
        const currentAscendPlanName = ascendProductData?.productName;
        const currentAscendProductId = ascendProductData?.productId;

        const canUpgradeAscend = ascendProductData
          ? ascendProductData?.canUpgrade
          : true;

        const premiumPlanData = (assets || []).find(
          (asset: AnyFixMe) => asset?.premiumAssetType === 'PLAN',
        );
        const premiumPlanName = premiumPlanData?.plansSpecificData?.productName;

        const canUpgradePremium = premiumPlanData
          ? premiumPlanData?.plansSpecificData?.canUpgrade
          : true;

        dispatch({
          type: actionTypes.SET_ASCEND_FETCH_STATUS,
          fetchStatus: ascendConstants.ASCEND_FETCH_STATUS.SUCCESS,
        });
        dispatch({
          type: actionTypes.SET_CURRENT_ASCEND_DATA,
          ascendPlan: currentAscendPlanName,
          premiumPlan: premiumPlanName,
          isTopPremium: !canUpgradeAscend && !canUpgradePremium,
          ascendProductId: currentAscendProductId,
        });
        return assets;
      })
      .catch((e: AnyFixMe) => {
        console.error(e);
        dispatch({
          type: actionTypes.SET_ASCEND_FETCH_STATUS,
          fetchStatus: ascendConstants.ASCEND_FETCH_STATUS.ERROR,
        });
        dispatch({
          type: actionTypes.SET_CURRENT_ASCEND_DATA,
          ascendPlan: null,
          premiumPlan: null,
          isTopPremium: false,
          ascendProductId: null,
        });
      });
  };

const updateKillingAscend =
  (killingAscend: boolean): ThunkAction =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_REMOVE_ASCEND_FEATURES,
      removeAscendFeatures: killingAscend,
    });
  };

export default { fetchCurrentAscendData, updateKillingAscend };
