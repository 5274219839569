import _ from 'lodash';
import type { PanelDescriptor } from '../types';

type PanelDescriptorTransformer = (
  descriptor: PanelDescriptor,
) => PanelDescriptor;

const panelPropsTransformers: PanelDescriptorTransformer[] = [];

export const registerPanelPropsTransformer = (
  transformer: PanelDescriptorTransformer,
) => {
  panelPropsTransformers.push(transformer);
};

export const applyPanelPropsTransformers = (
  descriptor: PanelDescriptor,
): PanelDescriptor => _.flow(panelPropsTransformers)(descriptor);
