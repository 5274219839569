// @ts-nocheck
import _ from 'lodash';
import fontsActionTypes from './uploadedFontsActionTypes';

const {
  SET_LIST,
  SET_QUEUE,
  SET_FLAG_TO_QUEUE_ITEM,
  SET_UPLOADED_ITEMS,
  ADD_TIME_OUT_TOKEN,
  CLEAR_TIME_OUT_TOKENS,
} = fontsActionTypes;

const reducer = (
  uploadedFonts = { list: [], queue: [], uploadedItems: {}, timeOutTokens: [] },
  action,
) => {
  switch (action.type) {
    case SET_LIST:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, uploadedFonts, { list: action.list });
    case SET_QUEUE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, uploadedFonts, { queue: action.queue });
    case SET_FLAG_TO_QUEUE_ITEM:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      const flaggedQueueItem = _.assign(
        {},
        uploadedFonts.queue[action.queueItemIndex],
        action.flag,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      const queue = _.map(uploadedFonts.queue, (item, idx) => {
        const queueItem =
          idx === action.queueItemIndex ? flaggedQueueItem : item;
        return queueItem;
      });
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, uploadedFonts, { queue });
    case SET_UPLOADED_ITEMS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, uploadedFonts, {
        uploadedItems: action.uploadedItems,
      });
    case ADD_TIME_OUT_TOKEN:
      const timeOutTokens = [
        ...uploadedFonts.timeOutTokens,
        action.timeOutToken,
      ];
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, uploadedFonts, { timeOutTokens });
    case CLEAR_TIME_OUT_TOKENS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, uploadedFonts, { timeOutTokens: [] });
    default:
      return uploadedFonts;
  }
};

export default reducer;
