const SET_PREVIEW_MODE = 'setPreviewMode';
const SET_PREVIEW_POINTER_EVENTS_ON = 'setPreviewPointerEventsOn';
const SET_IS_USER_TOGGLED_PREVIEW_IN_CURRENT_SESSION =
  'SET_IS_USER_TOGGLED_PREVIEW_IN_CURRENT_SESSION';
const SET_PREVIEW_WIDTH = 'SET_PREVIEW_WIDTH';
const SET_PREVIEW_TYPE = 'SET_PREVIEW_TYPE';
const ENABLE_PREVIEW_RESIZE_ANIMATION = 'ENABLE_PREVIEW_RESIZE_ANIMATION';
const DISABLE_PREVIEW_RESIZE_ANIMATION = 'DISABLE_PREVIEW_RESIZE_ANIMATION';

export {
  SET_PREVIEW_MODE,
  SET_PREVIEW_POINTER_EVENTS_ON,
  SET_IS_USER_TOGGLED_PREVIEW_IN_CURRENT_SESSION,
  SET_PREVIEW_WIDTH,
  SET_PREVIEW_TYPE,
  ENABLE_PREVIEW_RESIZE_ANIMATION,
  DISABLE_PREVIEW_RESIZE_ANIMATION,
};
