import _ from 'lodash';
import Immutable from 'wixImmutable';
import type { Reducer } from 'types/redux';

const isImmutable = (obj: AnyFixMe) => Immutable.Iterable.isIterable(obj);

const createNormalCombination =
  <TState extends Record<string, any>>(reducers: {
    [K in keyof TState]: Reducer<TState[K]>;
  }) =>
  (state: TState, action: unknown): TState => {
    let hasChanged = false;

    const res = _.mapValues(state, (val, key: keyof TState) => {
      const reducer = reducers[key] || _.identity;
      const newValue = reducer(val, action);
      if (newValue !== val) {
        hasChanged = true;
      }
      return newValue;
    }) as TState;

    return hasChanged ? res : state;
  };

const createImmutableCombination = (reducers: AnyFixMe) => {
  const finalReducers = isImmutable(reducers)
    ? reducers
    : Immutable.Map(reducers); //eslint-disable-line new-cap
  const defaultState = finalReducers.map(() => undefined as AnyFixMe);

  return (state = defaultState, action: unknown) => {
    let hasChanged = false;

    const res = state.map((val: AnyFixMe, key: AnyFixMe) => {
      const reducer = finalReducers.get(key) || _.identity;
      const newValue = reducer(val, action);
      if (newValue !== val) {
        hasChanged = true;
      }
      return newValue;
    });

    return hasChanged ? res : state;
  };
};

export default <TState>(
  reducers: { [K in keyof TState]: Reducer<TState[K]> },
  isStateImmutable?: boolean,
) =>
  isStateImmutable
    ? createImmutableCombination(reducers)
    : createNormalCombination(reducers);
