import _ from 'lodash';
import { combineReducers } from 'redux';
import constants from '@/constants';
import actionTypes from './sectionedPanelActionTypes';

const { TOGGLE_SECTION, OPEN_SECTION, DISPLAY_PANEL, CLOSE_SECTION } =
  actionTypes;

const sectionsList = [
  constants.DOCKED_PANEL_SECTIONS.WIDGET_PUBLIC_API,
  constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
];

export const sectionedPanelInitialState = {
  openDockedSections: [] as string[],
  shouldShow: true,
};

const toggleSection = (openSections: AnyFixMe, sectionToToggle: AnyFixMe) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  _.filter(
    sectionsList,
    (section) =>
      // @ts-expect-error
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      (section === sectionToToggle) ^ _.includes(openSections, section),
  );

const openSection = (openSections: AnyFixMe, sectionToOpen: AnyFixMe) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  _.filter(
    sectionsList,
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    (section) => section === sectionToOpen || _.includes(openSections, section),
  );

const closeSection = (openSections: AnyFixMe, sectionToClose: AnyFixMe) =>
  _.reject(openSections, (section) => section === sectionToClose);

const openDockedSectionsReducer = (
  openDockedSections = sectionedPanelInitialState.openDockedSections,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case TOGGLE_SECTION:
      return toggleSection(openDockedSections, action.sectionName);
    case OPEN_SECTION:
      return openSection(openDockedSections, action.sectionName);
    case CLOSE_SECTION:
      return closeSection(openDockedSections, action.sectionName);
    default:
      return openDockedSections;
  }
};

const displayReducer = (
  shouldShow = sectionedPanelInitialState.shouldShow,
  action: AnyFixMe,
) => (action.type === DISPLAY_PANEL ? action.shouldShow : shouldShow);

export default combineReducers({
  openDockedSections: openDockedSectionsReducer,
  shouldShow: displayReducer,
});
