// @ts-nocheck
import { overridable } from '@wix/santa-editor-utils';
import * as actionTypes from './appsStoreActionTypes';

const updateAppsStore = overridable((appDefinitionId, partialState) => ({
  type: actionTypes.UPDATE_APP_STORE,
  appDefinitionId,
  partialState,
}));

export { updateAppsStore };
