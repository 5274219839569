import _ from 'lodash';
import actions from './multilingualActionTypes';
import dataConverters from './multilingualDataConverters';

const setLoading =
  (isLoading: AnyFixMe) =>
  ({ producer }: AnyFixMe, state: AnyFixMe) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign({}, state, { loading: { isLoading, producer } });
const setLoadingT = setLoading(true);
const setLoadingF = setLoading(false);

const {
  LOAD_SUPPORTED_LANGUAGES,
  LOAD_SUPPORTED_LANGUAGES_SUCCESS,
  LOAD_SUPPORTED_LANGUAGES_ERROR,
  GET_SITE_TRANSLATABLES_PROPERTIES,
  GET_SITE_TRANSLATABLES_PROPERTIES_SUCCESS,
  GET_SITE_TRANSLATABLES_PROPERTIES_ERROR,
  GET_MACHINE_TRANSLATION_LIMIT,
  GET_MACHINE_TRANSLATION_LIMIT_SUCCESS,
  GET_MACHINE_TRANSLATION_LIMIT_ERROR,
  MULTILINGUAL_LOAD_START,
  MULTILINGUAL_LOAD_STOP,
  LANGUAGE_CHANGE_START,
  LANGUAGE_CHANGE_SUCCESS,
  SET_IS_TRANSLATING,
  SET_IS_PRELOADING,
  SET_IS_AUTO_TRANSLATED,
  SET_QUICK_EDIT_AUTO_OPENED,
} = actions;

export const multilingualInitialState: MultilingualState = {
  translatablesProperties: {},
  machineTranslationLimit: {},
  supportedLanguages: [],
  loading: {
    isLoading: false,
    producer: null,
  },
  isChanging: false,
  isTranslating: false,
  nextLanguage: null,
  error: null,
  isAutoTranslated: false,
  isQuickEditAutoOpened: false,
};

export type MultilingualState = AnyFixMe;

export default (
  multilingual = multilingualInitialState,
  { type, payload, error }: AnyFixMe = {},
) => {
  switch (type) {
    case LOAD_SUPPORTED_LANGUAGES:
      return setLoadingT({ producer: type }, multilingual);
    case MULTILINGUAL_LOAD_START:
      return setLoadingT(payload, multilingual);

    case MULTILINGUAL_LOAD_STOP:
      return setLoadingF(payload, multilingual);

    case LOAD_SUPPORTED_LANGUAGES_SUCCESS:
      return setLoadingF(
        { producer: type },
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, multilingual, {
          supportedLanguages: dataConverters.supportedLanguages(payload),
        }),
      );
    case GET_SITE_TRANSLATABLES_PROPERTIES:
      return setLoadingT({ producer: type }, multilingual);
    case GET_SITE_TRANSLATABLES_PROPERTIES_SUCCESS:
      return setLoadingF(
        { producer: type },
        Object.assign({}, multilingual, {
          siteTranslatablesProperties: payload,
        }),
      );
    case GET_SITE_TRANSLATABLES_PROPERTIES_ERROR:
      return setLoadingF(
        { producer: type },
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, multilingual, { siteTranslatablesProperties: 'failed' }),
      );
    case GET_MACHINE_TRANSLATION_LIMIT:
      return setLoadingT({ producer: type }, multilingual);
    case GET_MACHINE_TRANSLATION_LIMIT_SUCCESS:
      return setLoadingF(
        { producer: type },
        Object.assign({}, multilingual, {
          machineTranslationLimit: payload,
        }),
      );
    case GET_MACHINE_TRANSLATION_LIMIT_ERROR:
      return setLoadingF(
        { producer: type },
        Object.assign({}, multilingual, { machineTranslationLimit: 'failed' }),
      );
    case LOAD_SUPPORTED_LANGUAGES_ERROR:
      return setLoadingF(
        { producer: type },
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, multilingual, { error }),
      );
    case SET_IS_PRELOADING: {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, multilingual, {
        isChanging: payload,
      });
    }
    case LANGUAGE_CHANGE_START:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, multilingual, {
        isChanging: true,
        nextLanguage: payload,
      });
    case LANGUAGE_CHANGE_SUCCESS:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, multilingual, {
        isChanging: false,
        nextLanguage: null,
      });
    case SET_IS_TRANSLATING:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, multilingual, { isTranslating: payload });
    case SET_IS_AUTO_TRANSLATED:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, multilingual, { isAutoTranslated: payload });
    case SET_QUICK_EDIT_AUTO_OPENED:
      return Object.assign({}, multilingual, {
        isQuickEditAutoOpened: payload,
      });
    default:
      return multilingual;
  }
};
