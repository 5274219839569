export default {
  INDICATION: '44886124-b4dd-4ea0-9aac-4eabbd9e1aec',
  FIRST_MOBILE_ONLY_COMP: '44886124-b4dd-4ea0-9aac-4eabbd9e1aec',
  FIRST_PASTE_MOBILE_ONLY_COMP: 'eb07f2af-4e4a-48cd-8fa1-3d105aef46bb',
  CANT_PERFORM_COPY_PASTE_CUT: '4075046c-4b98-4f52-821e-10f46ad90765',
  CANNOT_ADD_COMP_AS_MOBILE_ONLY: '1048feb9-ff5a-4225-af3f-1a89f1824caf',
  LINK_PANEL: {
    POPUP_LINK: '413fc010-57fa-4ea0-bae9-17f5325e3912',
    ANCHOR_LINK: '283a9097-baf8-4fab-89f1-78d00888215d',
  },
};
