import LayoutConstraintsUtil from './LayoutConstraintsUtil';
import editorConstraints from './editorConstraintsRegistry';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
export default class EditorLayoutConstraintsUtil extends LayoutConstraintsUtil {
  constructor(editorAPI: EditorAPI, compPointer: CompRef) {
    super(editorAPI, compPointer, editorConstraints);
  }
}
