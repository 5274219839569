import _ from 'lodash';
import { platformInitialState } from '../platformReducer';
import actionTypes from '../platformActionTypes';

const addElementsReducer = (
  addElementsPanel = platformInitialState.addElementsPanel,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case actionTypes.SET_MASTER_STRUCTURE:
      return _.defaults(
        { masterStructure: action.masterStructure },
        addElementsPanel,
      );
    default:
      return addElementsPanel;
  }
};

export default addElementsReducer;
