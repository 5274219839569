import _ from 'lodash';

import { animation, promiseUtils } from '@/util';

import * as actionTypes from './domMeasurementsActionTypes';
import * as selectors from './domMeasurementsSelectors';
import * as helpers from './domMeasurementsHelpers';
import type { StageRect } from './domMeasurements.types';

const { makeCancelablePromise, isCancelablePromiseCancelError } = promiseUtils;

const {
  SET_MEASURED_DOM,
  CLEAR_MEASURED_DOM,
  SET_STAGE_LAYOUT,
  SET_MEASURED_SCROLL,
  SET_STAGE_RECT,
  SET_PREVIEW_POS,
  SET_EDITOR_CONTENT_LAYOUT,
} = actionTypes;

const setMeasuredDOM = (measureKey: AnyFixMe, measuredRes: AnyFixMe) => ({
  type: SET_MEASURED_DOM,
  measureKey,
  measuredRes,
});

const clearMeasuredDOM = (measureKey: AnyFixMe) => ({
  type: CLEAR_MEASURED_DOM,
  measureKey,
});

const setStageLayout = (stageLayout: AnyFixMe) => ({
  type: SET_STAGE_LAYOUT,
  stageLayout,
});

const setEditorContentLayout = (editorContentLayout: AnyFixMe) => ({
  type: SET_EDITOR_CONTENT_LAYOUT,
  editorContentLayout,
});

const setMeasuredScroll = (scroll: AnyFixMe) => ({
  type: SET_MEASURED_SCROLL,
  scroll,
});

const setStageRect = (stageRect: StageRect) => ({
  type: SET_STAGE_RECT,
  stageRect,
});

const setPreviewPos = (previewPos: AnyFixMe) => ({
  type: SET_PREVIEW_POS,
  previewPos,
});

const updateStageLayout = () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
  const stageLayout = helpers.getClientStageLayout();
  const curStageLayout = selectors.getStageLayout(getState());

  if (!_.isEqual(curStageLayout, stageLayout)) {
    dispatch(setStageLayout(stageLayout));
  }
};

const updateEditorContentLayout =
  () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const editorContent = helpers.getClientEditorContentLayout();
    const curEditorContentLayout = selectors.getEditorContentLayout(getState());

    if (!_.isEqual(curEditorContentLayout, editorContent)) {
      dispatch(setEditorContentLayout(editorContent));
    }
  };

const updateScroll = () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
  const scroll = helpers.getClientEditorStageScroll();

  if (!_.isEqual(scroll, selectors.getScrollMeasurements(getState()))) {
    dispatch(setMeasuredScroll(scroll));
  }
};
//todo test logic
const updateStageRect = () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
  const state = getState();
  const stageLayout = selectors.getStageLayout(state);
  const editorScroll = selectors.getScrollMeasurements(state);
  const stageRect = {
    top: editorScroll.scrollTop,
    bottom: editorScroll.scrollTop + stageLayout.height,
    left: editorScroll.scrollLeft,
    right: editorScroll.scrollLeft + stageLayout.width,
  };

  if (!_.isEqual(stageRect, selectors.getStageRect(state))) {
    dispatch(setStageRect(stageRect));
  }
};

const updatePreviewPos =
  (previewPos: AnyFixMe) => (dispatch: AnyFixMe, getState: AnyFixMe) => {
    const state = getState();
    const lastPreviewPos = selectors.getPreviewPosition(state);
    if (!_.isEqual(previewPos, lastPreviewPos)) {
      dispatch(setPreviewPos(previewPos));
    }
  };

const initDomMeasurements = () => (dispatch: AnyFixMe) => {
  dispatch(updateStageLayout());
  dispatch(updateEditorContentLayout());
  dispatch(updateScroll());
  dispatch(updateStageRect());
};

let pendingDomMeasurements: AnyFixMe = null;

const initDomMeasurementsAfterAnimationEnd =
  () => async (dispatch: AnyFixMe) => {
    pendingDomMeasurements?.cancel();
    pendingDomMeasurements = makeCancelablePromise(
      animation.waitForAnimationEnd(() => ({
        stageLayout: helpers.getClientStageLayout(),
        editorContentLayout: helpers.getClientEditorContentLayout(),
        editorStageScroll: helpers.getClientEditorStageScroll(),
      })),
    );

    try {
      await pendingDomMeasurements.promise;

      dispatch(initDomMeasurements());
    } catch (error) {
      if (!isCancelablePromiseCancelError(error)) {
        throw error;
      }
    }
  };

export {
  clearMeasuredDOM,
  initDomMeasurements,
  initDomMeasurementsAfterAnimationEnd,
  setMeasuredDOM,
  setMeasuredScroll,
  setStageLayout,
  setStageRect,
  updatePreviewPos,
  updateStageLayout,
  setEditorContentLayout,
};
