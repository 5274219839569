const COMPONENT_INSTANCE_DATA = 'component-instance-data';
const EXPERIMENT = 'experiment';
const ENVIRONMENT = 'environment';
const IS_COMPONENT_PANEL_OPENED = 'is-component-panel-opened';

type ComponentInstanceDataKey =
  | 'componentProperties'
  | 'componentData'
  | 'componentStyle'
  | 'skin'
  | 'isWidgetPage'
  | 'isMultiColumnsStrip'
  | 'isColumn'
  | 'tpaAppData'
  | 'isComponentSelected'
  | 'isRepeaterItem'
  | 'activeModeIds'
  | 'isPopup'
  | 'isDescendantOfBlocksWidget'
  | 'isReferredComponent';

type ComponentEnvironmentDataKey =
  | 'isMobileEditor'
  | 'supportedFeatures'
  | 'qabActions'
  | 'premiumFeatures'
  | 'isWixCodeLoaded'
  | 'isInsideBlocks'
  | 'isCurrentLanguageSecondary'
  | 'isInsideEditorX';

function componentInstanceData<TKey extends ComponentInstanceDataKey>(
  key: TKey,
) {
  return {
    type: COMPONENT_INSTANCE_DATA,
    key,
  } as const;
}

function experiment<TName extends string>(name: TName) {
  return {
    type: EXPERIMENT,
    name,
  } as const;
}

function environment<TKey extends ComponentEnvironmentDataKey>(key: TKey) {
  return {
    type: ENVIRONMENT,
    key,
  } as const;
}

function isComponentPanelOpened<TKey extends string>(key: TKey) {
  return {
    type: IS_COMPONENT_PANEL_OPENED,
    key,
  } as const;
}

type ComponentParamResolverInstanceData = ReturnType<
  typeof componentInstanceData
>;
type ComponentParamResolverExperiment = ReturnType<typeof experiment>;
type ComponentParamResolverEnvironment = ReturnType<typeof environment>;
type ComponentParamResolverPanelOpened = ReturnType<
  typeof isComponentPanelOpened
>;

type ComponentParamResolver =
  | ComponentParamResolverInstanceData
  | ComponentParamResolverExperiment
  | ComponentParamResolverEnvironment
  | ComponentParamResolverPanelOpened;

function editorDataFunction<TResolverValue>(
  ...args:
    | [() => TResolverValue]
    | [ComponentParamResolver, (param1) => TResolverValue]
    | [
        ComponentParamResolver,
        ComponentParamResolver,
        (param1, param2) => TResolverValue,
      ]
    | [
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        (param1, param2, param3) => TResolverValue,
      ]
    | [
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        (param1, param2, param3, param4) => TResolverValue,
      ]
    | [
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        (param1, param2, param3, param4, param5) => TResolverValue,
      ]
    | [
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        (param1, param2, param3, param4, param5, param6) => TResolverValue,
      ]
    | [
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        (
          param1,
          param2,
          param3,
          param4,
          param5,
          param6,
          param7,
        ) => TResolverValue,
      ]
    | [
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        ComponentParamResolver,
        (
          param1,
          param2,
          param3,
          param4,
          param5,
          param6,
          param7,
          param8,
        ) => TResolverValue,
      ]
) {
  // NOTE: ...rest could be used only for last args
  // const [...paramResolvers, dataResolver] = args;
  const parameters = args.slice(0, args.length - 1) as ComponentParamResolver[];
  const dataResolver = args[args.length - 1] as (
    ...params: any[]
  ) => TResolverValue;

  const f = (...paramValues: unknown[]) => dataResolver(...paramValues);
  f.parameters = parameters;
  return f;
}

type EditorDataFunction = ReturnType<typeof editorDataFunction>;

function isEditorDataFunction(
  dataOrResolver: any,
): dataOrResolver is EditorDataFunction {
  return (
    typeof dataOrResolver === 'function' &&
    dataOrResolver.hasOwnProperty('parameters')
  );
}

export {
  ComponentParamResolver,
  ComponentInstanceDataKey,
  ComponentEnvironmentDataKey,
  EditorDataFunction,
  componentInstanceData,
  experiment,
  environment,
  isComponentPanelOpened,
  editorDataFunction,
  isEditorDataFunction,
  COMPONENT_INSTANCE_DATA,
  ENVIRONMENT,
  EXPERIMENT,
  IS_COMPONENT_PANEL_OPENED,
};

// resolving: https://github.com/wix-private/santa-editor/blob/81c9633e70470232393a402723ae6be50f243e8b/santa-editor/packages/stateManagement/components/componentDataResolver.ts
export const editorDataResolver = {
  componentInstanceData,
  experiment,
  environment,
  isComponentPanelOpened,
  editorDataFunction,
  isEditorDataFunction,
  COMPONENT_INSTANCE_DATA,
  ENVIRONMENT,
  EXPERIMENT,
  IS_COMPONENT_PANEL_OPENED,
} as const;

export type EditorDataResolver = typeof editorDataResolver;
