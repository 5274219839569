import { attachCandidateActionTypes } from './attachCandidateActionTypes';

import type { CompRef } from 'types/documentServices';

const updateAttachCandidate = (payload: {
  comp: CompRef | null;
  useAnimation?: boolean;
}) => ({
  type: attachCandidateActionTypes.UPDATE,
  payload,
});

export const attachCandidateActions = {
  updateAttachCandidate,
};
