import _ from 'lodash';
import actionTypes from './stateBoxActionTypes';

export const stateBoxInitialState = {
  isRepeaterStateAllowed: false,
};

const stateBoxReducer = (stateBox = stateBoxInitialState, action: AnyFixMe) => {
  switch (action.type) {
    case actionTypes.SET_IS_REPEATER_STATE_ALLOWED:
      return _.defaults(
        { isRepeaterStateAllowed: action.isRepeaterStateAllowed },
        stateBox,
      );
    default:
      return stateBox;
  }
};

export default stateBoxReducer;
