import _ from 'lodash';
import * as interactionsActionTypes from '../interactions/interactionsActionTypes';
import * as actionTypes from './leftBarActionTypes';
import type { Reducer } from 'types/redux';

import type {
  LeftBarDesktopItemKey,
  LeftBarItem,
  LeftBarMobileItemKey,
} from './leftBarData';

const {
  COLLAPSE_DESKTOP_MENU, // only for old se_newWorkspace
  COLLAPSE_MOBILE_MENU, // only for old se_newWorkspace
  HIGHLIGHT_MENU,
  UNHIGHLIGHT_MENU,
  EXPAND_BUTTON,
  COLLAPSE_BUTTON,
  UPDATE_BUTTONS_BOUNDING_RECT,
  SET_NOTIFICATION_COUNT,
  INCREASE_NOTIFICATION_COUNT,
  DECREASE_NOTIFICATION_COUNT,
  REMOVE_PANEL_NOTIFICATIONS,
  UPDATE_DESKTOP_SUPERAPP_BUTTON,
  UPDATE_MOBILE_SUPERAPP_BUTTON,
  REMOVE_SUPERAPP_BUTTON,
  OVERRIDE_LEFT_BAR,
  PATCH_DESKTOP_LEFT_BAR_ITEMS,
  PATCH_MOBILE_LEFT_BAR_ITEMS,
  UPDATE_DID_DISMISS_MOBILE_HIDDEN_ITEMS_TOOLTIP,
  UPDATE_IS_MOBILE_HIDDEN_ITEMS_TOOLTIP_ENABLED,
} = actionTypes;

const { EXIT_INTERACTION, ENTER_INTERACTION } =
  interactionsActionTypes.interactionsActionTypes;

let overriddenButtonsStateBeforeEnterInteractions: AnyFixMe;

const setSuperApps =
  (superAppsKey: AnyFixMe) =>
  (leftBarState: AnyFixMe, superAppsValues: AnyFixMe) =>
    _.defaults(
      {
        superApps: _.defaults(
          {
            [superAppsKey]: superAppsValues,
          },
          leftBarState.superApps,
        ),
      },
      leftBarState,
    );

const updateSuperAppButton =
  (superAppsKey: AnyFixMe) =>
  (leftBarState: AnyFixMe, buttonParams: AnyFixMe) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const existingButton = _.find(leftBarState.superApps[superAppsKey], {
      panelName: buttonParams.panelName,
    });
    if (existingButton) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      const updatedSuperApps = _.map(
        leftBarState.superApps[superAppsKey],
        (button) =>
          button === existingButton ? _.defaults(buttonParams, button) : button,
      );
      return setSuperApps(superAppsKey)(leftBarState, updatedSuperApps);
    }
    return setSuperApps(superAppsKey)(
      leftBarState,
      leftBarState.superApps[superAppsKey].concat(buttonParams),
    );
  };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LeftBarPatches<T extends LeftBarDesktopItemKey | LeftBarMobileItemKey> =
  Partial<Record<LeftBarDesktopItemKey, Partial<LeftBarItem>>>;
type LeftBarDesktopPatches = LeftBarPatches<LeftBarDesktopItemKey>;
type LeftBarMobilePatches = LeftBarPatches<LeftBarDesktopItemKey>;

export interface LeftBarState {
  isCollapsed: boolean;
  isMobileCollapsed: boolean;
  isHighlighted: boolean;
  buttonsBoundingRect: any;
  collapseOnClick?: string[];
  notifications: Record<string, number>;
  superApps: {
    desktop: unknown[];
    mobile: unknown[];
  };
  overriddenButtons: {
    desktop: unknown;
    mobile: unknown;
  };
  openedPanel: AnyFixMe;

  patchedItems: {
    desktop: LeftBarDesktopPatches;
    mobile: LeftBarMobilePatches;
  };
  didDismissMobileHiddenItemsTooltip: boolean;
  isMobileHiddenItemsTooltipEnabled: boolean;
}

export const leftBarInitialState: LeftBarState = {
  isCollapsed: false, // only for se_newWorkspace old
  isMobileCollapsed: false,
  isHighlighted: false,
  notifications: {},
  superApps: {
    desktop: [],
    mobile: [],
  },
  buttonsBoundingRect: {},
  overriddenButtons: {
    desktop: undefined,
    mobile: undefined,
  },
  openedPanel: null,
  patchedItems: {
    desktop: {},
    mobile: {},
  },
  didDismissMobileHiddenItemsTooltip: false,
  isMobileHiddenItemsTooltipEnabled: false,
};

const reducer: Reducer<LeftBarState> = (leftBar, action) => {
  switch (action.type) {
    case COLLAPSE_DESKTOP_MENU:
      return Object.assign({}, leftBar, { isCollapsed: true });

    case COLLAPSE_MOBILE_MENU:
      return Object.assign({}, leftBar, { isMobileCollapsed: true });

    case EXPAND_BUTTON:
      return _.defaults(
        {
          collapseOnClick: _.union(leftBar.collapseOnClick, [action.panelName]),
        },
        leftBar,
      );

    case COLLAPSE_BUTTON:
      return Object.assign({}, leftBar, {
        collapseOnClick: _.without(leftBar.collapseOnClick, action.panelName),
      });

    case UPDATE_BUTTONS_BOUNDING_RECT:
      const fieldsToCompare = ['left', 'right', 'top', 'bottom'];
      const lastBoundingRect = _.pick(
        leftBar.buttonsBoundingRect,
        fieldsToCompare,
      );
      const newBoundingRect = _.pick(action.boundingRect, fieldsToCompare);

      if (!_.isEqual(lastBoundingRect, newBoundingRect)) {
        return Object.assign({}, leftBar, {
          buttonsBoundingRect: newBoundingRect,
        });
      }

      return leftBar;

    case SET_NOTIFICATION_COUNT:
      return Object.assign({}, leftBar, {
        notifications: Object.assign({}, leftBar.notifications, {
          [action.panelName]: action.count,
        }),
      });

    case INCREASE_NOTIFICATION_COUNT:
      return Object.assign({}, leftBar, {
        notifications: Object.assign({}, leftBar.notifications, {
          [action.panelName]:
            leftBar.notifications[action.panelName] + (action.count || 1),
        }),
      });

    case DECREASE_NOTIFICATION_COUNT:
      return Object.assign({}, leftBar, {
        notifications: Object.assign({}, leftBar.notifications, {
          [action.panelName]:
            leftBar.notifications[action.panelName] - (action.count || 1),
        }),
      });

    case REMOVE_SUPERAPP_BUTTON:
      const superApps = {
        desktop: _.reject(leftBar.superApps.desktop, {
          panelName: action.panelName,
        }),
        mobile: _.reject(leftBar.superApps.mobile, {
          panelName: action.panelName,
        }),
      };
      return _.defaults({ superApps }, leftBar);

    case REMOVE_PANEL_NOTIFICATIONS:
      return _.defaults(
        { notifications: _.omit(leftBar.notifications, action.panelName) },
        leftBar,
      );

    case UPDATE_DESKTOP_SUPERAPP_BUTTON:
      return updateSuperAppButton('desktop')(leftBar, action.buttonParams);

    case UPDATE_MOBILE_SUPERAPP_BUTTON:
      return updateSuperAppButton('mobile')(leftBar, action.buttonParams);

    case OVERRIDE_LEFT_BAR:
      const overriddenButtons = action?.overriddenButtons;
      return Object.assign({}, leftBar, { overriddenButtons });

    case PATCH_DESKTOP_LEFT_BAR_ITEMS: {
      const patches = _.merge({}, leftBar.patchedItems, {
        desktop: action.items as LeftBarDesktopPatches,
      });

      return {
        ...leftBar,
        patchedItems: patches,
      };
    }

    case PATCH_MOBILE_LEFT_BAR_ITEMS: {
      const patches = _.merge({}, leftBar.patchedItems, {
        mobile: action.items as LeftBarMobilePatches,
      });

      return {
        ...leftBar,
        patchedItems: patches,
      };
    }

    case ENTER_INTERACTION:
      overriddenButtonsStateBeforeEnterInteractions = leftBar.overriddenButtons;
      const newOverriddenButtons = action.payload.leftBar?.overriddenButtons
        ? {
            overriddenButtons: {
              desktop: action.payload.leftBar.overriddenButtons,
            },
          }
        : {};
      const shouldHighlight = action.payload.leftBar?.shouldHighlight;
      return Object.assign({}, leftBar, {
        isCollapsed: false,
        isHighlighted: shouldHighlight ?? true,
        ...newOverriddenButtons,
      });

    case EXIT_INTERACTION:
      return Object.assign({}, leftBar, {
        isCollapsed: true,
        isHighlighted: false,
        overriddenButtons: overriddenButtonsStateBeforeEnterInteractions,
      });

    case HIGHLIGHT_MENU:
      return Object.assign({}, leftBar, {
        isHighlighted: true,
      });

    case UNHIGHLIGHT_MENU:
      return Object.assign({}, leftBar, {
        isHighlighted: false,
      });

    case UPDATE_DID_DISMISS_MOBILE_HIDDEN_ITEMS_TOOLTIP:
      return Object.assign({}, leftBar, {
        didDismissMobileHiddenItemsTooltip: action.payload.didDismiss,
      });

    case UPDATE_IS_MOBILE_HIDDEN_ITEMS_TOOLTIP_ENABLED:
      return Object.assign({}, leftBar, {
        isMobileHiddenItemsTooltipEnabled: action.payload.isEnabled,
      });

    default:
      return leftBar;
  }
};

export default reducer;
