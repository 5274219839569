import _ from 'lodash';
import * as util from '@/util';
import { Uploader } from '@wix/media-manager-sdk';

function upload(mediaManagerSdkSource: AnyFixMe, fileList: AnyFixMe) {
  if (mediaManagerSdkSource) {
    const filesToUpload = fileList.map(function (file: AnyFixMe) {
      return {
        uploadFn: mediaManagerSdkSource.item.upload,
        source: {
          file,
          name: file.name,
          size: file.size,
          folderId: null as string,
          folderName: 'All Media',
          mediaType: util.fontsManagerUtils.constants.FONTS_MEDIA_TYPE,
        },
      };
    });
    Uploader.upload(filesToUpload);
  }
}

function clearUploadQueue() {
  if (!Uploader.uploadIsInProgress) {
    Uploader.clearQueue();
  }
}

function getUploadQueue() {
  return Uploader.uploadQueue;
}

function cancelUpload(item: AnyFixMe) {
  let isSuccessCancel = false;
  if (item.canAbort()) {
    try {
      item.abort();
      isSuccessCancel = true;
    } catch (e) {
      isSuccessCancel = false;
    }
  }
  return isSuccessCancel;
}

function retryUpload() {
  if (!_.isEmpty(Uploader.uploadQueue) && Uploader.uploadIsInProgress) {
    Uploader.upload();
  }
}

export { upload, clearUploadQueue, getUploadQueue, cancelUpload, retryUpload };
