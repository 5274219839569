// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';

import type { DSRead } from 'types/documentServices';

const getSiteProtocol = (state, dsRead) => {
  // TODO: replace with dsRead.generalInfo.getPublicUrl();
  if (dsRead.generalInfo.isHttpsEnabled()) {
    return 'https';
  }
  return 'http';
};

/**
 * See comment below for isDomainConnected,
 * premiumFeatures.includes('HasDomain') is unreliable because of the ongoing issue.
 */
const isDomainConnectedTempBroken = () => {
  const premiumFeatures = util.editorModel.metaSiteData
    ? util.editorModel.metaSiteData.premiumFeatures
    : [];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return _.includes(premiumFeatures, 'HasDomain');
};

/**
 * This function is a temporary replacement function for "isDomainConnected",
 * since current implementation is wrong, and there are some cases that we get wrong value.
 * Relevant slack thread:
 * https://wix.slack.com/archives/C022YD75A0J/p1621941716001400
 *
 * Next implementation would go directly to user-domains API and have reliable answer for isDomainConnected.
 * @param dsRead
 */
const isDomainConnected = (dsRead: DSRead): boolean => {
  if (!dsRead?.generalInfo) {
    return false;
  }

  const publicUrl = dsRead.generalInfo.getPublicUrl();

  return util.domain.isDomainConnected(dsRead, publicUrl);
};

// TODO: get rid of state since it's unused
const getFreeDomainPrefix = (state, dsRead) => {
  const publicUrl = dsRead.generalInfo.getPublicUrl();
  const { origin } = new URL(publicUrl);
  return `${origin}/`;
};

export {
  getSiteProtocol,
  isDomainConnected,
  isDomainConnectedTempBroken,
  getFreeDomainPrefix,
};
