import _ from 'lodash';
import * as actionTypes from './pagesActionTypes';
import type { PageRef } from 'types/documentServices';

const {
  SET_FOCUSED_PAGE,
  SET_FOCUSED_PAGE_TITLE,
  EDITOR_LOADED_WITH_MORE_THAN_MAX_STATIC_PAGES,
  SET_EDITING_MENU_ITEM,
} = actionTypes;

export interface PagesState {
  focusedPage: PageRef;
  focusedPageTitle: string;
  isEditorLoadedWithMoreThanMaxStaticPages: boolean;
  editingId: string;
}

export const pagesInitialState: PagesState = {
  focusedPage: null,
  focusedPageTitle: '',
  isEditorLoadedWithMoreThanMaxStaticPages: null,
  editingId: null,
};

const reducer = (
  pages: PagesState = pagesInitialState,
  action: AnyFixMe,
): PagesState => {
  switch (action.type) {
    case SET_FOCUSED_PAGE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, pages, { focusedPage: action.focusedPage });
    case SET_FOCUSED_PAGE_TITLE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, pages, { focusedPageTitle: action.focusedPageTitle });
    case EDITOR_LOADED_WITH_MORE_THAN_MAX_STATIC_PAGES:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, pages, {
        isEditorLoadedWithMoreThanMaxStaticPages: action.value,
      });
    case SET_EDITING_MENU_ITEM:
      return {
        ...pages,
        editingId: action.value,
      };
    default:
      return pages;
  }
};

export default reducer;
