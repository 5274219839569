import type { CompRef } from 'types/documentServices';
import type { LayoutApiByStructure } from '../createLayoutApi_structure';
import type { LayoutGetApi } from '../layoutGetApi';
import type { LayoutMoveByOptions } from '../type';
import type { LayoutMoveByParams } from './types';

export function createLayoutMoveApi_structure({
  layoutGetApi,
  layoutApi_structure,
}: {
  layoutGetApi: LayoutGetApi;
  layoutApi_structure: LayoutApiByStructure;
}) {
  function moveBy(
    compRefOrRefs: CompRef | CompRef[],
    { deltaX, deltaY }: LayoutMoveByParams,
    options: LayoutMoveByOptions = {},
  ) {
    const currentLayout = layoutGetApi.getRelativeToStructure(compRefOrRefs);

    const x =
      typeof deltaX === 'number' ? currentLayout.x + deltaX : currentLayout.x;
    const y =
      typeof deltaY === 'number' ? currentLayout.y + deltaY : currentLayout.y;

    layoutApi_structure.updateRelativeToStructure(
      compRefOrRefs,
      { x, y },
      options.dontAddToUndoRedoStack,
    );
  }

  return {
    moveBy,
  };
}
