import type { CompRef } from 'types/documentServices';

export function ensureCompRefOrRefsHasValue(
  fnName: string,
  compRefOrRefs: CompRef | CompRef[],
) {
  if (
    !compRefOrRefs ||
    (Array.isArray(compRefOrRefs) && compRefOrRefs.length === 0)
  ) {
    throw new Error(
      `${fnName}: compRef(s) argument should be compRef or array of compRefs. ${JSON.stringify(
        compRefOrRefs,
      )}`,
    );
  }
}
