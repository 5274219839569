import * as actionTypes from './layersPanelActionTypes';

export interface LayersPanelState {
  isLayersPanelDisplayed: boolean;
  wasLayersPanelOpen?: boolean;
  origin?: string;
}

export const layersPanelInitialState: LayersPanelState = {
  isLayersPanelDisplayed: false,
  wasLayersPanelOpen: null,
  origin: null,
};

export default (
  layersPanel: LayersPanelState = layersPanelInitialState,
  action: AnyFixMe,
): LayersPanelState => {
  switch (action.type) {
    case actionTypes.SHOW_LAYERS_PANEL:
      return {
        isLayersPanelDisplayed: true,
        origin: action.origin,
      };
    case actionTypes.HIDE_LAYERS_PANEL:
      return {
        isLayersPanelDisplayed: false,
        origin: '',
      };
    case actionTypes.SET_PREVIEW_MODE:
      if (action.isPreview) {
        return {
          isLayersPanelDisplayed: false,
          wasLayersPanelOpen: layersPanel.isLayersPanelDisplayed,
        };
      }
      return {
        isLayersPanelDisplayed: !!layersPanel.wasLayersPanelOpen,
        wasLayersPanelOpen: null,
      };

    default:
      return layersPanel;
  }
};
