const tpaTypeUtils = {
  isTPA,
  isTPASpecialPage,
  isPageNotVisibleInPagesMenu,
};

function isTPA(pageData: AnyFixMe) {
  return !!pageData.tpaApplicationId; // not (undefined or null or 0)
}

function isTPASpecialPage(
  dsRead: AnyFixMe,
  pageData: AnyFixMe,
  onlyStaticPages?: AnyFixMe,
) {
  if (!tpaTypeUtils.isTPA(pageData)) {
    return false;
  }

  const isMultiSection = dsRead.pages.isPageContainsTPAMultiSectionComp(
    pageData.id,
  );

  const onlyStaticFiltered =
    !onlyStaticPages ||
    !dsRead.routers.getRouterDataForPageIfExist(pageData.id);

  return isMultiSection && onlyStaticFiltered;
}

function isPageNotVisibleInPagesMenu(dsRead: AnyFixMe, pageData: AnyFixMe) {
  return dsRead.tpa.isPageMarkedAsHideFromMenu(
    pageData.tpaApplicationId,
    pageData.tpaPageId,
  );
}

export default tpaTypeUtils;
