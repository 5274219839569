import type ReactType from 'react';
import type { DSAction, DSRead } from 'types/documentServices';
import type { ThunkAction } from 'types/redux';
import ascendActions from '../ascend/ascendActions';
import * as dataFixers from '../dataFixers/dataFixers';
import * as leftBar from '../leftBar/leftBar';
import * as mobile from '../mobile/mobile';
import * as mouseActions from '../mouseActions/mouseActions';
import * as popups from '../popups/popups';
import * as selectionActions from '../selection/selectionActions';
import * as siteDisplayName from '../siteDisplayName/siteDisplayName';
import servicesActionTypes from './servicesActionTypes';

const dataFixersActions = dataFixers.actions;

const { SET_STATE, UPDATE_VIEWER_RENDERS_COUNTER } = servicesActionTypes;

const setState = (state: AnyFixMe) => ({
  type: SET_STATE,
  state,
});

const { isPopUpMode } = popups.selectors;
const { isPerformingMouseMoveAction } = mouseActions.selectors;

const extendThunkActionWithDocumentServices =
  ({
    dsRead,
    dsActions,
  }: {
    dsRead: DSRead;
    dsActions: DSAction;
  }): ThunkAction =>
  (dispatch, getState, services) => {
    Object.assign(services, {
      dsRead,
      dsActions,
    });
  };

const createSantaPreview =
  (React: typeof ReactType): ThunkAction =>
  (dispatch, getState, services) =>
    services.createSantaPreview(React);

const onPreviewReady =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { dsRead }: AnyFixMe) => {
    const state = getState();
    dispatch(leftBar.actions.sendBIButtonsCount(isPopUpMode(state, dsRead)));
    dispatch(siteDisplayName.actions.fetchSiteDisplayName());
    dispatch(dataFixersActions.runDataFixers());
    dispatch(ascendActions.fetchCurrentAscendData());
  };

const onViewerChanged = () => (dispatch: AnyFixMe, getState: AnyFixMe) => {
  const state = getState();

  if (!isPerformingMouseMoveAction(state)) {
    dispatch(
      mobile.actions.hiddenItems.refreshMobileHiddenComponentsIfPanelIsOpen(),
    );
    dispatch(selectionActions.setFocusedTabDefinition());
  }

  dispatch(updateViewerRendersCounter());
};

const updateViewerRendersCounter = () => ({
  type: UPDATE_VIEWER_RENDERS_COUNTER,
});

const loadService =
  (serviceKey: AnyFixMe, service: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, services: AnyFixMe) =>
    Object.assign(services, { [serviceKey]: service });

export {
  setState,
  extendThunkActionWithDocumentServices,
  createSantaPreview,
  onPreviewReady,
  onViewerChanged,
  loadService,
};
