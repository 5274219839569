import { isMeshLayoutEnabled } from '../layoutMeshApi';
import type { CompLayout, Rect } from 'types/documentServices';

function ensureArgumentsAreValid(
  layoutInitial: Rect,
  layoutUpdated: Partial<Rect>,
) {
  if (!layoutUpdated || !layoutInitial) {
    const layoutUpdatedType = layoutUpdated
      ? typeof layoutUpdated
      : String(layoutUpdated);
    const layoutInitialType = layoutInitial
      ? typeof layoutInitial
      : String(layoutInitial);
    throw new Error(
      'layoutUpdated & layoutInitial are required arguments.\n' +
        ` layoutUpdated: '${layoutUpdatedType}';\n` +
        ` layoutInitial: '${layoutInitialType}';`,
    );
  }
}

function isLayoutKeyAllowed(key: string): key is keyof CompLayout {
  return isMeshLayoutEnabled()
    ? [
        'x',
        'y',
        'width',
        'height',
        'rotationInDegrees',
        'fixedPosition',
        'scale',
      ].includes(key)
    : true;
}

export function getLayoutChanges(
  layoutInitial: CompLayout,
  layoutUpdated: Partial<CompLayout>,
): {
  isPositionChanged?: boolean;
  isSizeChanged?: boolean;
  isRotationChanged?: boolean;
  changes?: (keyof CompLayout)[];
} {
  ensureArgumentsAreValid(layoutInitial, layoutUpdated);

  const changes = Object.keys(layoutUpdated).filter(
    (key) =>
      isLayoutKeyAllowed(key) &&
      typeof layoutUpdated[key] !== 'undefined' &&
      layoutUpdated[key] !== layoutInitial[key],
  ) as (keyof CompLayout)[];

  if (changes.length === 0) {
    return {
      changes,
    };
  }

  return {
    changes,
    isPositionChanged: changes.includes('x') || changes.includes('y'),
    isSizeChanged: changes.includes('width') || changes.includes('height'),
    isRotationChanged: changes.includes('rotationInDegrees'),
  };
}
