import type { Store } from 'redux';
import a11yReducer from '../a11y/a11yReducer';
import addPanelReducer from '../addPanel/addPanelReducer';
import applicationStudioReducer from '../applicationStudio/applicationStudioReducer';
import appsStoreReducer from '../appsStore/appsStoreReducer';
import ascendReducer from '../ascend/ascendReducer';
import { reducer as attachCandidateReducer } from '../attachCandidate/attachCandidate';
import blogReducer from '../blog/blogReducer';
import componentMetaDataReducer from '../componentMetaData/componentMetaDataReducer';
import componentsStoreReducer from '../componentsStore/componentsStoreReducer';
import concurrentUsersReducers from '../concurrentUsers/concurrentUsersReducers';
import configReducer from '../config/configReducer';
import contextMenuReducer from '../contextMenu/contextMenuReducer';
import designPanelReducer from '../designPanel/designPanelReducer';
import developerToolBarReducer from '../developerToolBar/developerToolBarReducer';
import devModeReducer from '../devMode/devModeReducer';
import domMeasurementsReducer from '../domMeasurements/domMeasurementsReducer';
import domainSuggestionsReducer from '../domainSuggestions/domainSuggestionsReducer';
import draggableSlotsReducer from '../draggable/draggableSlots/draggableSlotsReducer';
import dynamicPagesReducer from '../dynamicPages/dynamicPagesReducer';
import tpaDynamicPagesReducer from '../tpaDynamicPages/tpaDynamicPagesReducer';
import * as editorSearchReducer from '../editorSearch/editorSearchReducer';
import { reducer as extensionsSlotsReducer } from '../extensionsSlots/extensionsSlots';
import schoolModeReducer from '../schoolMode/schoolModeReducer';
import externalLinksReducer from '../externalLinks/externalLinksReducer';
import featuresOverriddenReducer from '../featuresOverridden/featuresOverriddenReducer';
import googleMapReducer from '../googleMap/googleMapReducer';
import gridLinesReducer from '../gridLines/gridLinesReducer';
import helpHomeReducer from '../helpHome/helpHomeReducer';
import helpPanelReducer from '../helpPanel/helpPanelReducer';
import highlightsReducer from '../highlights/highlightsReducer';
import hoverBoxReducer from '../hoverBox/hoverBoxReducer';
import inlinePopupReducer from '../inlinePopup/inlinePopupReducer';
import { interactionsReducer } from '../interactions/interactionsReducer';
import introFunnelReducer from '../introFunnel/introFunnelReducer';
import layersReducer from '../layers/layersReducer';
import layersPanelReducer from '../layersPanel/layersPanelReducer';
import leftBarReducer from '../leftBar/leftBarReducer';
import leftTreeReducer from '../leftTree/leftTreeReducer';
import linkPanelReducer from '../linkPanel/linkPanelReducer';
import localModeReducer from '../localMode/localModeReducer';
import mobileReducer from '../mobile/mobileReducer';
import mouseActionsReducer from '../mouseActions/mouseActionsReducer';
import multilingualReducer from '../multilingual/multilingualReducer';
import { newAddPanelReducer } from '../newAddPanel/newAddPanelReducer';
import newReleasesReducer from '../newReleases/newReleasesReducer';
import notificationsReducer from '../notifications/notificationsReducer';
import pagesReducer from '../pages/pagesReducer';
import panelsReducer from '../panels/panelsReducer';
import permanentBorderReducer from '../permanentBorder/permanentBorderReducer';
import pinModeReducer from '../pinMode/pinModeReducer';
import platformReducer from '../platform/platformReducer';
import preloadingsReducer from '../preloadings/preloadingsReducer';
import previewModeReducer from '../preview/previewModeReducer';
import previewReducer from '../preview/previewReducer';
import publishingStatusReducer from '../publishingStatus/publishingStatusReducer';
import testSiteStatusReducer from '../testSiteStatus/testSiteStatusReducer';
import relatedAppsReducer from '../relatedApps/relatedAppsReducer';
import rightClickMenuReducer from '../rightClickMenu/rightClickMenuReducer';
import rulersReducer from '../rulers/rulersReducer';
import savedComponentsReducer from '../savedComponents/savedComponentsReducer';
import savePublishReducer from '../savePublish/savePublishReducer';
import secondaryLeftAreaReducer from '../secondaryLeftArea/secondaryLeftAreaReducer';
import sectionedPanelReducer from '../sectionedPanel/sectionedPanelReducer';
import { sectionsReducer } from '../sections/sections.reducer';
import { sectionsOnStageReducer } from '../sectionsOnStage/sectionsOnStage.reducer';
import selectionReducer from '../selection/selectionReducer';
import servicesReducer from '../services/servicesReducer';
import siteDisplayNameReducer from '../siteDisplayName/siteDisplayNameReducer';
import siteCreationReducer from '../siteCreation/siteCreationReducer';
import { smartWriterReducer } from '../smartWriter/smartWriterReducer';
import stageLoaderReducer from '../stageLoader/stageLoaderReducer';
import stateBoxReducer from '../stateBox/stateBoxReducer';
import { textReducer } from '../text/textReducer';
import topBarReducer from '../topBar/topBarReducer';
import branchesReducer from '../branches/branchesReducer';
import tpaReducer from '../tpa/tpaReducer';
import * as unifiedChat from '../unifiedChat/unifiedChat';
import uploadedFontsReducer from '../uploadedFonts/uploadedFontsReducer';
import userPreferencesReducer from '../userPreferences/userPreferencesReducer';
import userProfileReducer from '../userProfile/userProfileReducer';
import viewToolsReducer from '../viewTools/viewToolsReducer';
import virtualNumbersReducer from '../virtualNumbers/virtualNumbersReducer';
import welcomeScreenReducer from '../welcomeScreen/welcomeScreenReducer';
import wixAdReducer from '../wixAd/wixAdReducer';
import wixCodeIDEReducer from '../wixCodeIDE/wixCodeIDEReducer';
import combineReducers from './combineReducers';
import type { EditorState } from './editorState';
import initialEditorState from './initialEditorState';
import stateFreezer from './stateFreezer';
import { DragInProgressPath } from '@/apilib';

export type EditorStore = Store<EditorState> & {
  dispatch: (action: any) => any;
  flush: () => void;
};

export default function createEditorStoreBase(shouldFreezeState: AnyFixMe) {
  const combinedReducer = servicesReducer(
    combineReducers({
      a11y: a11yReducer,
      addPanel: addPanelReducer,
      applicationStudio: applicationStudioReducer,
      appsStore: appsStoreReducer,
      ascend: ascendReducer,
      attachCandidate: attachCandidateReducer,
      blog: blogReducer,
      branches: branchesReducer,
      componentMetaData: componentMetaDataReducer,
      componentsStore: componentsStoreReducer,
      concurrentUsers: concurrentUsersReducers,
      config: configReducer,
      contextMenu: contextMenuReducer,
      designPanel: designPanelReducer,
      devModeContext: devModeReducer,
      developerToolBar: developerToolBarReducer,
      domMeasurements: domMeasurementsReducer,
      domainSuggestions: domainSuggestionsReducer,
      draggableSlots: draggableSlotsReducer,
      dynamicPages: dynamicPagesReducer,
      editorSearch: editorSearchReducer.editorSearchReducer,
      extensionsSlots: extensionsSlotsReducer,
      externalLinks: externalLinksReducer,
      featuresOverridden: featuresOverriddenReducer,
      googleMap: googleMapReducer,
      gridLines: gridLinesReducer,
      helpHome: helpHomeReducer,
      helpProps: helpPanelReducer,
      hoverBox: hoverBoxReducer,
      inlinePopup: inlinePopupReducer,
      interactions: interactionsReducer,
      introFunnel: introFunnelReducer,
      layers: layersReducer,
      layersPanel: layersPanelReducer,
      leftBar: leftBarReducer,
      leftTree: leftTreeReducer,
      linkPanel: linkPanelReducer,
      localMode: localModeReducer,
      mobile: mobileReducer,
      [DragInProgressPath.subreducer]: mouseActionsReducer,
      multilingual: multilingualReducer,
      newAddPanel: newAddPanelReducer,
      newReleases: newReleasesReducer,
      notifications: notificationsReducer,
      pages: pagesReducer,
      panelData: panelsReducer,
      permanentBorder: permanentBorderReducer,
      pinMode: pinModeReducer,
      platform: platformReducer,
      preloadings: preloadingsReducer,
      preview: previewReducer,
      previewMode: previewModeReducer,
      publishingStatus: publishingStatusReducer,
      testSiteStatus: testSiteStatusReducer,
      relatedApps: relatedAppsReducer,
      rightClickMenu: rightClickMenuReducer,
      rulers: rulersReducer,
      savePublish: savePublishReducer,
      savedComponents: savedComponentsReducer,
      secondaryLeftArea: secondaryLeftAreaReducer,
      sectionedPanel: sectionedPanelReducer,
      sections: sectionsReducer,
      sectionsOnStage: sectionsOnStageReducer,
      selection: selectionReducer,
      siteDisplayName: siteDisplayNameReducer,
      schoolMode: schoolModeReducer,
      siteCreation: siteCreationReducer,
      smartWriter: smartWriterReducer,
      stage: combineReducers({
        highlights: highlightsReducer,
      }),
      stageLoader: stageLoaderReducer,
      stateBox: stateBoxReducer,
      text: textReducer,
      topBar: topBarReducer,
      tpa: tpaReducer,
      tpaDynamicPages: tpaDynamicPagesReducer,
      unifiedChat: unifiedChat.reducer,
      uploadedFonts: uploadedFontsReducer,
      userPreferences: userPreferencesReducer,
      userProfile: userProfileReducer,
      viewTools: viewToolsReducer,
      virtualNumbers: virtualNumbersReducer,
      welcomeScreen: welcomeScreenReducer,
      wixAd: wixAdReducer,
      wixCodeIDE: wixCodeIDEReducer,
    }),
  );

  const reducer = shouldFreezeState
    ? stateFreezer(combinedReducer)
    : combinedReducer;

  return {
    reducer: (
      state: EditorState = initialEditorState,
      action: unknown,
    ): EditorState => reducer(state as any, action),
    initialState: initialEditorState,
  };
}
