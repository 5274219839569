import _ from 'lodash';
import * as util from '@/util';
import { overridable } from '@wix/santa-editor-utils';
import * as actionTypes from './userPreferencesActionTypes';
import * as selectors from './userPreferencesSelectors';
import constants from './constants';
import getKeyFromAppId from './getKeyFromAppId';
import type { EditorState } from '@/stateManagement';
import type { ThunkAction } from 'types/redux';
import type {
  UserPreferences,
  UserPreferencesGlobal,
  UserPreferencesSite,
  UserPreferencesType,
  UserPreferencesValueWrapper,
} from './userPreferencesReducer';

const { EDITOR_APP_ID } = constants;
const USER_PREFERENCES_VERSION = 3;

const { premiumStateApiUrl } = util.serviceTopology;
const BASE_URL = premiumStateApiUrl
  ? premiumStateApiUrl.replace(
      /\/getTpaPremiumState/,
      '-user-preferences-webapp/',
    )
  : 'https://editor.wix.com/_api/wix-user-preferences-webapp/';

const SITE = 'site';
const GLOBAL = 'global';
const SESSION = 'session';

const URLS = {
  [SITE]: {
    load: (siteId: AnyFixMe) =>
      `${BASE_URL}getVolatilePrefsForSite/htmlEditor/${siteId}`,
    SAVE: `${BASE_URL}bulkSet`,
    DELETE: `${BASE_URL}delete`,
  },
  [GLOBAL]: {
    load: () =>
      `${BASE_URL}getVolatilePrefForKey/htmlEditor/global_preferences`,
    SAVE: `${BASE_URL}set`,
  },
};

const setSiteId = (siteId: string) => ({
  type: actionTypes.SET_SITE_ID,
  siteId,
});
const setPreferences = (
  typeId: UserPreferencesType,
  preferences: UserPreferences,
) => ({
  type: actionTypes.SET_PREFERENCES,
  typeId,
  preferences,
});
const setPreference = (
  typeId: UserPreferencesType,
  key: string,
  value: AnyFixMe,
) => ({
  type: actionTypes.SET_PREFERENCE,
  typeId,
  key,
  value,
});
const markSitePreferencesNotDirty = () => ({
  type: actionTypes.MARK_SITE_PREFERENCES_NOT_DIRTY,
});
const dontSaveUserPreferences = () => ({
  type: actionTypes.DONT_SAVE_USER_PREFERENCES,
});

const isFakeUserPrefs = (): boolean => util.url.isTrue('fakeUserPrefs');

const getSiteId = (getState: () => EditorState) =>
  selectors.getSiteId(getState());

const createDeletePayload = (getState: () => EditorState, key: string) => ({
  nameSpace: 'htmlEditor',
  key,
  siteId: getSiteId(getState),
});

const createRequestPayloadForGlobal = (data: UserPreferences) => ({
  nameSpace: 'htmlEditor',
  key: 'global_preferences',
  blob: data,
});

const createRequestPayloadForSite = (
  getState: () => EditorState,
  data: UserPreferencesSite,
) => {
  const postData = {
    nameSpace: 'htmlEditor',
    siteId: getSiteId(getState),
    data: {
      version: undefined,
    } as AnyFixMe,
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(data, (valueWrapper, key) => {
    if (valueWrapper.isDirty) {
      postData.data[key] = valueWrapper.value;
    }
  });

  postData.data.version = USER_PREFERENCES_VERSION;

  return postData;
};

const createRequestPayload = (
  getState: () => EditorState,
  typeId: UserPreferencesType,
) => {
  switch (typeId) {
    case GLOBAL:
      return createRequestPayloadForGlobal(
        selectors.getPreferences(GLOBAL)(getState()),
      );
    case SITE:
      return createRequestPayloadForSite(
        getState,
        selectors.getPreferences(SITE)(getState()),
      );
  }
};

const deleteKey = (getState: () => EditorState, key: string) => {
  if (isFakeUserPrefs()) {
    return;
  }
  util.http
    .fetch(URLS[SITE].DELETE, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        'content-type': 'application/json; charset=utf-8',
      }),
      body: JSON.stringify(createDeletePayload(getState, key)),
    })
    .catch(_.noop);
};

const bulkDelete = (getState: () => EditorState, data: UserPreferences) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(data, (value, key) => deleteKey(getState, key));

const markData = (data: AnyFixMe, isDirty: boolean, value?: AnyFixMe) =>
  _.mapValues(data, (originalValue) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    const newValue = _.isUndefined(value) ? originalValue : value;
    return {
      value: newValue,
      isDirty,
    };
  });

const cleanData = (rawData: Record<string, unknown>) => {
  const keyIsNumber = (key: string) => {
    const keyAsNumber = _.toNumber(key);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-nan
    return !_.isNaN(keyAsNumber);
  };

  const keyIsTooShort = (key: string) => key.length < 6;

  return _.transform(
    rawData,
    function (result: AnyFixMe, value, key) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/replace
      const cleanKey = _.replace(key, /editorAppId_/g, '');
      const shouldFilterValue =
        keyIsNumber(cleanKey) || keyIsTooShort(cleanKey);
      if (!shouldFilterValue) {
        result[key] = value;
      }
    },
    {},
  );
};

const cleanAndMigrateDataFromServer = (
  getState: () => EditorState,
  data: AnyFixMe,
) => {
  data = cleanData(data);

  if (data.version === USER_PREFERENCES_VERSION) {
    return markData(data, false);
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  let migratedData = _.assign(
    {},
    _.omit(data, EDITOR_APP_ID, 'version'),
    data[EDITOR_APP_ID],
  );
  bulkDelete(getState, data);

  migratedData = _.transform(
    migratedData,
    (result: AnyFixMe, value, key: string) => {
      result[getKeyFromAppId(EDITOR_APP_ID, key)] = value;
    },
    {},
  );

  migratedData.version = USER_PREFERENCES_VERSION;
  return markData(migratedData, true);
};

const extractDataFromServerResponse = (
  getState: () => EditorState,
  typeId: UserPreferencesType,
  response: { global_preferences?: UserPreferencesGlobal },
) => {
  switch (typeId) {
    case GLOBAL:
      return response.global_preferences;
    case SITE:
      return cleanAndMigrateDataFromServer(getState, response);
    default:
      console.error('unknown type ID');
      return {};
  }
};

const shouldResetUserPrefs = (typeId: UserPreferencesType) => {
  const urlParam = util.url.getParameterByName('resetUserPrefs').toLowerCase();
  return urlParam === 'all' || urlParam === typeId;
};

const wrapDataForFakeStorage = (
  getState: () => EditorState,
  typeId: UserPreferencesType,
) => {
  switch (typeId) {
    case GLOBAL:
      return {
        global_preferences: selectors.getPreferences(GLOBAL)(getState()),
      };
    case SITE:
      const wrappedData: Record<string, any> = {
        version: undefined,
      }; // todo: isn't this just a clone?
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        selectors.getPreferences(SITE)(getState()),
        (valueWrapper, key) => {
          wrappedData[key] = valueWrapper.value;
        },
      );
      wrappedData.version = USER_PREFERENCES_VERSION;
      return wrappedData;
    default:
      console.error('unknown type ID');
      return {};
  }
};

const postSaveSuccessActions = (
  dispatch: AnyFixMe,
  typeId: UserPreferencesType,
) => {
  if (typeId === SITE) {
    dispatch(setSitePrefFetchStatus(true));
    dispatch(setSitePrefFetchDoneStatus());
    dispatch(markSitePreferencesNotDirty());
    dispatch(invokeSitePrefCallbacks(true));
  }
};

const savePreferences = (
  dispatch: AnyFixMe,
  getState: () => EditorState,
  typeId: UserPreferencesType,
  onSuccess?: AnyFixMe,
  onError?: AnyFixMe,
) => {
  if (selectors.getPreferences(typeId)(getState()) === null) {
    if (onError) {
      onError(`saving ${typeId} preferences failed since they were not loaded`);
    }
    return;
  }

  if (isFakeUserPrefs()) {
    const wrappedData = wrapDataForFakeStorage(getState, typeId);
    window.sessionStorage.setItem(
      `user_prefs_${typeId}`,
      JSON.stringify(wrappedData),
    );
    postSaveSuccessActions(dispatch, typeId);
    if (onSuccess) {
      onSuccess();
    }
    return;
  }

  if (selectors.dontSaveUserPrefs(getState())) {
    return;
  }

  const dataToSend = createRequestPayload(getState, typeId);

  if (!dataToSend) {
    if (onSuccess) {
      onSuccess();
    }
    return;
  }

  util.http
    .fetch(URLS[typeId as keyof typeof URLS].SAVE, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        'content-type': 'application/json; charset=utf-8',
      }),
      body: JSON.stringify(dataToSend),
    })
    .then(() => {
      postSaveSuccessActions(dispatch, typeId);
      if (onSuccess) {
        onSuccess();
      }
    })
    .catch((responseOrErr: AnyFixMe) => {
      if (responseOrErr.status === 413) {
        dispatch(dontSaveUserPreferences());
      }
      if (onError) {
        onError(responseOrErr);
      }
    })
    .catch(_.noop);
};

const resetGlobalPreferences = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  onSuccess: AnyFixMe,
  onError: AnyFixMe,
) => {
  dispatch(setPreferences(GLOBAL, {}));
  savePreferences(dispatch, getState, GLOBAL, onSuccess, onError);
};

const resetSitePreferences = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  onSuccess: AnyFixMe,
  onError: AnyFixMe,
) => {
  dispatch(
    setPreferences(
      SITE,
      markData(selectors.getPreferences(SITE)(getState()), true, null),
    ),
  );
  savePreferences(dispatch, getState, SITE, onSuccess, onError);
};

const resetPreferences = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  typeId: AnyFixMe,
  onSuccess: AnyFixMe,
  onError: AnyFixMe,
) => {
  switch (typeId) {
    case GLOBAL:
      return resetGlobalPreferences(dispatch, getState, onSuccess, onError);
    case SITE:
      return resetSitePreferences(dispatch, getState, onSuccess, onError);
  }
};

const postLoadSuccessActions = (
  dispatch: AnyFixMe,
  getState: () => EditorState,
  typeId: UserPreferencesType,
  data: { global_preferences?: UserPreferences },
  onSuccess: AnyFixMe,
  onError: AnyFixMe,
) => {
  dispatch(
    setPreferences(
      typeId,
      data ? extractDataFromServerResponse(getState, typeId, data) : {},
    ),
  );
  if (shouldResetUserPrefs(typeId)) {
    resetPreferences(dispatch, getState, typeId, onSuccess, onError);
  } else if (onSuccess) {
    onSuccess();
  }
};

const loadPreferences = (
  dispatch: AnyFixMe,
  getState: () => EditorState,
  typeId: UserPreferencesType,
  onSuccess: AnyFixMe,
  onError = _.noop,
) => {
  if (isFakeUserPrefs()) {
    const localServerData = window.sessionStorage.getItem(
      `user_prefs_${typeId}`,
    );
    postLoadSuccessActions(
      dispatch,
      getState,
      typeId,
      JSON.parse(localServerData),
      onSuccess,
      onError,
    );
    return;
  }

  const fetchInit = {
    contentType: 'application/json',
    credentials: 'same-origin',
    method: 'GET',
  };
  const url = URLS[typeId as keyof typeof URLS].load(getSiteId(getState));
  util.http
    .fetchJson(url, fetchInit)
    // .then( data => { //uncomment to test races with being settings loaded later
    //   return new Promise(resolve => {
    //     setTimeout(() => resolve(data), 5000)
    //   })
    // })
    .then((data: AnyFixMe) => {
      if (!_.isObject(data)) {
        return onError();
      }

      postLoadSuccessActions(
        dispatch,
        getState,
        typeId,
        data,
        onSuccess,
        onError,
      );
    })
    .catch(onError);
};

const loadGlobalPreferences = (): ThunkAction => (dispatch, getState) =>
  new Promise<void>((resolve, reject) => {
    const onLoadError = (response: AnyFixMe) => {
      const rejectThis = () => {
        dispatch(setPreferences(GLOBAL, null));
        console.error('Global preferences failed to load!');
        reject('Global preferences failed to load!');
      };
      Promise.resolve()
        .then(() => response.json())
        .then((errResponseJson) => {
          if (errResponseJson.errorCode === 3001) {
            dispatch(setPreferences(GLOBAL, {}));
            resolve();
          } else {
            rejectThis();
          }
        })
        .catch(() => rejectThis());
    };
    loadPreferences(dispatch, getState, GLOBAL, resolve, onLoadError);
  });

const loadSitePreferences =
  (updatedSiteId: string, isFirstSave: boolean): ThunkAction =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const areSitePrefsEmpty = selectors.arePreferencesEmpty(SITE)(getState());

      dispatch(setSiteId(updatedSiteId));
      const callSavePreferences = (_ as any).partial(
        savePreferences,
        dispatch,
        getState,
        SITE,
        resolve,
        reject,
      );
      if (isFirstSave) {
        if (!areSitePrefsEmpty) {
          callSavePreferences();
        }
      } else if (areSitePrefsEmpty) {
        loadPreferences(dispatch, getState, SITE, callSavePreferences, () => {
          console.error('Site preferences failed to load!');
          dispatch(invokeSitePrefCallbacks(false));
          dispatch(setSitePrefFetchStatus(false));
          dispatch(setSitePrefFetchDoneStatus());
          reject('Site preferences failed to load!');
        });
      } else {
        const alreadySetSitePreferences =
          selectors.getPreferences(SITE)(getState());
        const mergeFromAlreadySetPrefsToLoadedAndSave = () => {
          dispatch(
            setPreferences(
              SITE,
              _.merge(
                selectors.getPreferences(SITE)(getState()),
                alreadySetSitePreferences,
              ),
            ),
          );
          callSavePreferences();
        };

        loadPreferences(
          dispatch,
          getState,
          SITE,
          mergeFromAlreadySetPrefsToLoadedAndSave,
          () => {
            console.error('Site preferences failed to load!');
            dispatch(setSitePrefFetchStatus(false));
            dispatch(setSitePrefFetchDoneStatus());
            dispatch(invokeSitePrefCallbacks(false));
            callSavePreferences();
          },
        );
      }
    });

const setGlobalUserPreferences = overridable(
  (key: string, value: AnyFixMe): ThunkAction =>
    (dispatch, getState) => {
      if (!_.isString(key)) {
        throw new Error(
          `Setting a global user preference expects a key of type string but received: ${key}`,
        );
      }

      return new Promise((resolve, reject) => {
        const globalPreferences = selectors.getPreferences(GLOBAL)(getState());

        if (globalPreferences === null) {
          console.error('Global user preferences failed to load!');
          dispatch(setGlobalPrefFetchStatus(false));
          reject('Global user preferences failed to load!');
        } else {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
          if (_.isUndefined(value)) {
            value = null;
          }

          dispatch(setPreference(GLOBAL, key, value));
          savePreferences(dispatch, getState, GLOBAL, resolve, reject);
        }
      });
    },
);

const setSiteUserPreferences = overridable(
  <TValue = unknown>(key: string, value: TValue, appId?: string): ThunkAction =>
    (dispatch, getState) => {
      if (!_.isString(key)) {
        throw new Error(
          `Setting a user preference for the site expects a key of type string but received: ${key}`,
        );
      }

      return new Promise<void>((resolve, reject) => {
        appId = appId || EDITOR_APP_ID;

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
        if (_.isUndefined(value)) {
          value = null;
        }

        const valueWrapper: UserPreferencesValueWrapper = {
          value,
          isDirty: true,
        };

        key = getKeyFromAppId(appId, key);

        dispatch(setPreference(SITE, key, valueWrapper));
        if (getSiteId(getState)) {
          savePreferences(dispatch, getState, SITE, resolve, reject);
        } else {
          resolve();
        }
      });
    },
);

const setSessionUserPreferences = overridable(
  <T>(key: string, value: T): ThunkAction =>
    (dispatch) => {
      if (!_.isString(key)) {
        throw new Error(
          `Setting a session user preference expects a key of type string but received: ${key}`,
        );
      }

      dispatch(setPreference(SESSION, key, value));

      return value;
    },
);

const updateSessionUserPreferences =
  (key: string, partialValue: AnyFixMe): ThunkAction =>
  (dispatch, getState) => {
    const currentSessionObj =
      selectors.getSessionUserPreferences(key)(getState());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const newSessionObj = _.assign({}, currentSessionObj, partialValue);
    dispatch(setSessionUserPreferences(key, newSessionObj));
  };

const setSitePreferences = (typeId: UserPreferencesType) => {
  switch (typeId) {
    case 'site':
      return setSiteUserPreferences;
    case 'session':
      return setSessionUserPreferences;
    case 'global':
      return setGlobalUserPreferences;
  }
};

const registerSitePreferencesCallbacks =
  (callBack: (status: boolean) => void): ThunkAction =>
  (dispatch, getState) => {
    const sitePreferencesFetchDone =
      selectors.getSitePreferencesFetchDone(getState());
    if (sitePreferencesFetchDone) {
      callBack(selectors.getSitePrefFetchStatus(getState()));
    } else {
      dispatch(pushToRegisterSitePreferencesCallbacks(callBack));
    }
  };

const setSitePrefFetchStatus = (status: boolean) => ({
  type: actionTypes.SET_SITE_PREF_FETCH_STATUS,
  status,
});

const setGlobalPrefFetchStatus = (status: boolean) => ({
  type: actionTypes.SET_GLOBAL_PREF_FETCH_STATUS,
  status,
});

const setSitePrefFetchDoneStatus = () => ({
  type: actionTypes.SITE_PREF_CALL_DONE,
});

const pushToRegisterSitePreferencesCallbacks = (cb: AnyFixMe) => ({
  type: actionTypes.REGISTER_SITE_PREF_CB,
  cb,
});

const resetSitePreferencesCallbacksArray = () => ({
  type: actionTypes.RESET_REGISTER_SITE_PREF_CB_ARRAY,
});

const invokeSitePrefCallbacks =
  (success: boolean): ThunkAction =>
  (dispatch, getState) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      selectors.getRegisteredSitePrefCallbacks(getState()),
      (callback) => {
        callback(success);
      },
    );
    dispatch(resetSitePreferencesCallbacksArray());
  };

export {
  loadGlobalPreferences,
  loadSitePreferences,
  setGlobalUserPreferences,
  setSiteUserPreferences,
  setSessionUserPreferences,
  updateSessionUserPreferences,
  setSitePreferences,
  setPreferences,
  setSitePrefFetchStatus,
  setGlobalPrefFetchStatus,
  registerSitePreferencesCallbacks,
  invokeSitePrefCallbacks,
  setSitePrefFetchDoneStatus,
};
