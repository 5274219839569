import addMultiple from './addMultiple';
import { translate } from '@/i18n';

import type { EditorAPI } from '@/editorAPI';

const openSource = 'music_player';

export default {
  handleAudioAdd(
    editorAPI: EditorAPI,
    originalAddComponentFunc: AnyFixMe,
    containerRef: AnyFixMe,
    compDef: AnyFixMe,
    optionalId: AnyFixMe,
    onCompAddCallback: AnyFixMe,
    convertStructureFunc: AnyFixMe,
  ): null {
    if (compDef.data.uri) {
      return null;
    }

    const { mediaServices } = editorAPI;
    mediaServices.mediaManager.open(
      mediaServices.mediaManager.categories.MUSIC,
      openSource,
      {
        callback(items: AnyFixMe) {
          addMultiple(
            editorAPI,
            originalAddComponentFunc,
            containerRef,
            compDef,
            optionalId,
            onCompAddCallback,
            convertStructureFunc,
            items,
          );
          mediaServices.setFocusToEditor();
        },
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_mytracks_add'),
        },
      },
    );

    return null;
  },
};
