// @ts-nocheck
import _ from 'lodash';
import { getDragMovePositionDelta } from './dragMove';
import * as layoutSymmetryUtil from '../layoutSymmetryUtil';

import type { CompLayout, Point, Rect } from 'types/documentServices';

const degreesPerRadians = 180 / Math.PI;
const radiansPerDegrees = 1 / degreesPerRadians;

const VALID_PROPS_FOR_LAYOUT_UPDATE = [
  'x',
  'y',
  'width',
  'height',
  'rotationInDegrees',
  'scale',
  'fixedPosition',
  'aspectRatio',
];

const KNOB_POSITION = {
  TOP: -1,
  BOTTOM: 1,
  LEFT: -1,
  RIGHT: 1,
  NONE: 0,
};

function angleBetween(p: Point, q: Point) {
  return Math.atan2(p.y - q.y, p.x - q.x);
}

function rotateVectorByRadAround(
  vector: Point,
  rad: number,
  center: Point,
): Point {
  if (rad === 0) {
    return vector;
  }
  return {
    x:
      (vector.x - center.x) * Math.cos(rad) -
      (vector.y - center.y) * Math.sin(rad) +
      center.x,
    y:
      (vector.x - center.x) * Math.sin(rad) +
      (vector.y - center.y) * Math.cos(rad) +
      center.y,
  };
}

function rotateVectorByRad(vector, rad) {
  return rotateVectorByRadAround(vector, rad, { x: 0, y: 0 });
}

function getLayoutCenter(layout: CompLayout): Point {
  return {
    x: layout.x + layout.width / 2,
    y: layout.y + layout.height / 2,
  };
}

function projectMouseProportionally(mouseDiff, layout, direction) {
  // The points that make up the line for projection:
  // ('from' is the static handle, 'to' is the used handle)
  const fromPoint = {
    x: direction.x > 0 ? layout.x : layout.x + layout.width,
    y: direction.y > 0 ? layout.y : layout.y + layout.height,
  };

  const toPoint = {
    x: direction.x < 0 ? layout.x : layout.x + layout.width,
    y: direction.y < 0 ? layout.y : layout.y + layout.height,
  };

  // get slope:
  const m = (toPoint.y - fromPoint.y) / (toPoint.x - fromPoint.x);

  // get standard form: ax + by + c = 0
  const a = m;
  const b = -1;
  const c = fromPoint.y - m * fromPoint.x;

  //absolute mouse location:
  const mouse = {
    x: toPoint.x + mouseDiff.x,
    y: toPoint.y + mouseDiff.y,
  };

  return {
    x: (b * (b * mouse.x - a * mouse.y) - a * c) / (a * a + b * b) - toPoint.x,
    y: (a * (a * mouse.y - b * mouse.x) - b * c) / (a * a + b * b) - toPoint.y,
  };
}

function isHorizontalResize(direction) {
  return (
    direction.y === KNOB_POSITION.NONE && direction.x !== KNOB_POSITION.NONE
  );
}

function isVerticalResize(direction) {
  return (
    direction.x === KNOB_POSITION.NONE && direction.y !== KNOB_POSITION.NONE
  );
}

/////////////////
class Drag {
  private layoutInitial: Rect;
  private layoutByCompInitial: Rect[];
  private mousePositionInitial: Point;

  constructor(
    layoutInitial: Rect,
    mousePositionInitial: Point,
    layoutByCompInitial: Rect[],
  ) {
    if (!layoutInitial) {
      return;
    }

    this.layoutInitial = layoutInitial;
    this.layoutByCompInitial = layoutByCompInitial;
    this.mousePositionInitial = mousePositionInitial;
  }

  /**
   * @deprecated use layoutInitial (or better don't use it at all. it's private)
   */
  private get initLayout() {
    return this.layoutInitial;
  }

  getLayout(mousePositionUpdated: Point & { isShiftPressed: boolean }) {
    if (!this.layoutInitial) {
      return null;
    }

    const mousePositionInitial = this.mousePositionInitial;
    const { deltaX, deltaY } = getDragMovePositionDelta(
      mousePositionInitial,
      mousePositionUpdated,
      mousePositionUpdated.isShiftPressed,
    );

    return {
      ...this.layoutInitial,
      x: this.layoutInitial.x + deltaX,
      y: this.layoutInitial.y + deltaY,
    };
  }

  getLayoutDelta(layoutUpdated: Rect) {
    return {
      deltaX: layoutUpdated.x - this.layoutInitial.x,
      deltaY: layoutUpdated.y - this.layoutInitial.y,
    };
  }

  getBoundComponentLayouts(layoutUpdated: Rect): Rect[] {
    const { deltaX, deltaY } = this.getLayoutDelta(layoutUpdated);

    return this.layoutByCompInitial.map((layoutInnerInitial) => ({
      x: layoutInnerInitial.x + deltaX,
      y: layoutInnerInitial.y + deltaY,
    }));
  }
}

class Rotate {
  private center: Point;
  private initMouseAngle: number;
  private initLayout: Rect;

  constructor(
    layoutRelativeToScreen: Rect,
    initMouseLocation: Point,
    absoluteLayout: Rect,
  ) {
    if (!layoutRelativeToScreen) {
      return;
    }
    this.initLayout = layoutRelativeToScreen;
    this.initMouseLocation = initMouseLocation;

    this.center = getLayoutCenter(absoluteLayout);
    this.initMouseAngle = angleBetween(this.initMouseLocation, this.center);
  }

  getLayout(mouseLocation: Point) {
    const layout = _.clone(this.initLayout);
    const currentMouseAngle = angleBetween(mouseLocation, this.center);
    layout.rotationInDegrees +=
      (currentMouseAngle - this.initMouseAngle) * degreesPerRadians;
    return layout;
  }
}

function resizeLayout(
  initLayout,
  initMouseLocation,
  direction,
  proportional,
  mouseLocation,
) {
  if (!initLayout) {
    return null;
  }

  const resize = new Resize(
    initLayout,
    initMouseLocation,
    direction,
    proportional,
  );
  return resize.getLayout(mouseLocation);
}

function updateLayoutForVerticalResize(componentLayout, mouseDiff, yDirection) {
  const validMouseYDiff = getValidMouseDiffToPreventSquash(
    yDirection,
    componentLayout.height,
    false,
    mouseDiff.y,
  );
  if (yDirection === 1) {
    componentLayout.height += validMouseYDiff;
  } else if (yDirection === -1) {
    componentLayout.y += validMouseYDiff;
    componentLayout.height -= validMouseYDiff;
  }
}

function updateLayoutForHorizontalResize(
  componentLayout,
  mouseDiff,
  xDirection,
) {
  const validMouseXDiff = getValidMouseDiffToPreventSquash(
    xDirection,
    componentLayout.width,
    false,
    mouseDiff.x,
  );
  if (xDirection === 1) {
    componentLayout.width += validMouseXDiff;
  } else if (xDirection === -1) {
    componentLayout.x += validMouseXDiff;
    componentLayout.width -= validMouseXDiff;
  }
}

function getValidMouseDiffToPreventSquash(
  direction,
  compSize,
  isResizeSymmetric,
  desiredDiff,
) {
  let validMouseDiff = 0;
  const maxValidDistanceToSquash = isResizeSymmetric
    ? Math.floor(compSize / 2)
    : compSize;

  if (direction === 1) {
    validMouseDiff = Math.max(-maxValidDistanceToSquash, desiredDiff);
  } else if (direction === -1) {
    validMouseDiff = Math.min(maxValidDistanceToSquash, desiredDiff);
  }

  return validMouseDiff;
}

function symmetricallyUpdateLayoutForVerticalResize(
  componentLayout,
  mouseDiff,
  yDirection,
) {
  const validMouseDiff = getValidMouseDiffToPreventSquash(
    yDirection,
    componentLayout.height,
    true,
    mouseDiff.y,
  );
  if (yDirection === 1) {
    componentLayout.y -= validMouseDiff;
    componentLayout.height += validMouseDiff * 2;
  } else if (yDirection === -1) {
    componentLayout.y += validMouseDiff;
    componentLayout.height -= validMouseDiff * 2;
  }
}

function symmetricallyUpdateLayoutForHorizontalResize(
  componentLayout,
  mouseDiff,
  xDirection,
) {
  const validMouseDiff = getValidMouseDiffToPreventSquash(
    xDirection,
    componentLayout.width,
    true,
    mouseDiff.x,
  );
  if (xDirection === 1) {
    componentLayout.x -= validMouseDiff;
    componentLayout.width += validMouseDiff * 2;
  } else if (xDirection === -1) {
    componentLayout.x += validMouseDiff;
    componentLayout.width -= validMouseDiff * 2;
  }
}

class Resize {
  private initLayout: CompLayout;
  private initMouseLocation: Point;
  private direction: Point;
  private proportional: boolean;
  private rotationInRad: number;
  private ratio: number;
  private initLayoutAspectRatio: number;

  constructor(
    initLayout: CompLayout,
    initMouseLocation: Point,
    direction: Point,
    proportional: boolean,
    ratio: number = 1,
  ) {
    if (!initLayout) {
      return null;
    }

    this.initLayout = initLayout;
    this.initMouseLocation = initMouseLocation;
    this.direction = direction;
    this.proportional = proportional;
    this.rotationInRad = radiansPerDegrees * initLayout.rotationInDegrees;
    this.ratio = ratio;
    this.initLayoutAspectRatio = initLayout.width / initLayout.height;
  }

  getLayoutAfterProportionalHorizontalResize(
    layout: CompLayout,
    mouseDiff: Point,
  ) {
    const layoutAfterResize = _.clone(layout);
    let horizontalChange;

    if (this.direction.x === KNOB_POSITION.LEFT) {
      horizontalChange = -Math.min(mouseDiff.x, layout.width);
      layoutAfterResize.x -= horizontalChange;
    } else if (this.direction.x === KNOB_POSITION.RIGHT) {
      horizontalChange = Math.max(mouseDiff.x, -layout.width);
    }

    layoutAfterResize.width += horizontalChange;
    layoutAfterResize.height =
      layoutAfterResize.width / this.initLayoutAspectRatio;

    // TODO: naora 7/21/15 4:29 PM prevent y modification after min-width reached

    const changeInHeight = layoutAfterResize.height - this.initLayout.height;
    layoutAfterResize.y -= changeInHeight / 2;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      layoutAfterResize,
      this.getCorrectXYAfterTransformOriginChange(layoutAfterResize),
    );

    return layoutAfterResize;
  }

  getLayoutAfterProportionalVerticalResize(
    layout: CompLayout,
    mouseDiff: Point,
  ) {
    const layoutAfterResize = _.clone(layout);
    let verticalChange = 0;

    if (this.direction.y === KNOB_POSITION.TOP) {
      verticalChange = -Math.min(mouseDiff.y, layout.height);
      layoutAfterResize.y -= verticalChange;
    } else if (this.direction.y === KNOB_POSITION.BOTTOM) {
      verticalChange = Math.max(mouseDiff.y, -layout.height);
    }

    layoutAfterResize.height += verticalChange;
    layoutAfterResize.width =
      layoutAfterResize.height * this.initLayoutAspectRatio;

    const changeInWidth = layoutAfterResize.width - this.initLayout.width;
    layoutAfterResize.x -= changeInWidth / 2;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      layoutAfterResize,
      this.getCorrectXYAfterTransformOriginChange(layoutAfterResize),
    );

    return layoutAfterResize;
  }

  getLayoutAfterStandardResize(layout: CompLayout, mouseDiff: Point) {
    const symmetry = layoutSymmetryUtil.getLayoutSymmetry(layout);

    if (this.direction.x) {
      if (symmetry.horizontal) {
        symmetricallyUpdateLayoutForHorizontalResize(
          layout,
          mouseDiff,
          this.direction.x,
        );
      } else {
        mouseDiff.x /= this.ratio;
        updateLayoutForHorizontalResize(layout, mouseDiff, this.direction.x);
      }
    }

    if (this.direction.y) {
      if (symmetry.vertical) {
        symmetricallyUpdateLayoutForVerticalResize(
          layout,
          mouseDiff,
          this.direction.y,
        );
      } else {
        mouseDiff.y /= this.ratio;
        updateLayoutForVerticalResize(layout, mouseDiff, this.direction.y);
      }
    }

    //Move layout to correct location
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(layout, this.getCorrectXYAfterTransformOriginChange(layout));

    if (!this.direction.x) {
      delete layout.width;
    }
    if (!this.direction.y) {
      delete layout.height;
    }

    return layout;
  }

  getLayout(mouseLocation: Point) {
    const layout = _.cloneDeep(this.initLayout); //create a new layout object to manipulate
    let layoutAfterResize: CompLayout;

    let mouseDiff = {
      x: mouseLocation.x - this.initMouseLocation.x,
      y: mouseLocation.y - this.initMouseLocation.y,
    }; //relative mouse movement, since we never care about absolute mouse positioning

    mouseDiff = rotateVectorByRad(mouseDiff, -this.rotationInRad); //rotate mouseDiff so it can be applied to the non-rotated layout

    if (this.proportional) {
      if (isHorizontalResize(this.direction)) {
        layoutAfterResize = this.getLayoutAfterProportionalHorizontalResize(
          layout,
          mouseDiff,
        );
      } else if (isVerticalResize(this.direction)) {
        layoutAfterResize = this.getLayoutAfterProportionalVerticalResize(
          layout,
          mouseDiff,
        );
      } else {
        mouseDiff = projectMouseProportionally(
          mouseDiff,
          layout,
          this.direction,
        );
        layoutAfterResize = this.getLayoutAfterStandardResize(
          layout,
          mouseDiff,
        );
      }
      layoutAfterResize = _.mapValues(layoutAfterResize, Math.round);
    } else {
      layoutAfterResize = this.getLayoutAfterStandardResize(layout, mouseDiff);
    }

    layoutAfterResize = _.pick(
      layoutAfterResize,
      VALID_PROPS_FOR_LAYOUT_UPDATE,
    );

    return layoutAfterResize;
  }

  // Fix related to transform-origin css attribute issue - see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin}
  getCorrectXYAfterTransformOriginChange(layout: CompLayout) {
    let layoutOrigin = _.pick(layout, ['x', 'y']);
    layoutOrigin = rotateVectorByRadAround(
      layoutOrigin,
      -this.rotationInRad,
      getLayoutCenter(layout),
    );
    return rotateVectorByRadAround(
      layoutOrigin,
      this.rotationInRad,
      getLayoutCenter(this.initLayout),
    );
  }
}

export { Drag, Rotate, Resize, resizeLayout };
