import constants from '@/constants';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { getPreviewMode } from '../preview/previewSelectors';
import type { EditorState } from '../store/editorState';
import type { PanelDescriptor, SidePanelProps } from './types';

const selectOpenPanels = (state: EditorState) => state.panelData.openPanels;
const selectPanelFilters = (state: EditorState) => state.panelData.panelFilters;
const selectHelpProps = (state: EditorState) => state.helpProps;
const selectDockedPlatformPanels = (state: EditorState) =>
  state.panelData.openPanels.filter(
    (item) => item.frameType === constants.PANEL_TYPES.DOCKED_PANEL,
  );
const selectAllDockedPanels = (state: EditorState) =>
  state.sectionedPanel.openDockedSections.concat(
    selectDockedPlatformPanels(state),
  );
const selectOpenLeftPanels = (state: EditorState) =>
  state.panelData.openPanels.filter(
    (item) => item.frameType === constants.PANEL_TYPES.LEFT,
  );
const selectOpenRightPanels = (state: EditorState) =>
  state.panelData.openPanels.filter(
    (item) => item.frameType === constants.PANEL_TYPES.RIGHT,
  );

const hasOpenedLeftPanels = (state: EditorState) =>
  selectOpenLeftPanels(state).length > 0;

const isPanelOpenedByName = (state: EditorState, panelName: string) =>
  Boolean(state.panelData.openPanels.find((item) => item.name === panelName));

const COLOR_PICKER_PANELS = [
  'panels.toolPanels.colorPicker.colorPickerPanel',
  'panels.toolPanels.colorPicker.colorPickerWithGradientsPanel',
];
const isColorPickerClosed = createSelector(
  selectOpenPanels,
  (openedPanels: PanelDescriptor[]) =>
    !openedPanels.some((opened) => COLOR_PICKER_PANELS.includes(opened.name)),
);
const isLinkDialogClosed = createSelector(
  selectOpenPanels,
  (openedPanels) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    !_.some(openedPanels, { name: 'panels.toolPanels.linkPanel' }),
);
const isMobileHiddenItemsPanelOpen = createSelector(
  selectOpenPanels,
  (openedPanels) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    !!_.find(openedPanels, { name: 'mobileEditor.hiddenItemsPanel' }),
);
const selectOpenDockedPlatformPanels = createSelector(
  selectDockedPlatformPanels,
  (dockedPlatformPanels) => dockedPlatformPanels,
);
const isDockedSectionOpen = createSelector(
  selectAllDockedPanels,
  (dockedPanels) => dockedPanels.length > 0,
);

const isSidePanelOpened = (state: EditorState) =>
  isPanelOpenedByName(state, 'platformPanels.sidePanel');

const isSidePanelOpenedWithAppDefId = (
  state: EditorState,
  appDefinitionId: string,
) =>
  isSidePanelOpened(state) &&
  selectOpenPanels(state).some(
    ({ props }) =>
      props.url &&
      new URL(props.url).searchParams.get('appDefinitionId') ===
        appDefinitionId,
  );

const selectPlatformSidePanel = createSelector(
  [selectAllDockedPanels, getPreviewMode],
  (dockedPanels, previewMode) => {
    const sidePanel =
      !previewMode &&
      dockedPanels.length > 0 &&
      dockedPanels[0].name === 'platformPanels.sidePanel'
        ? dockedPanels[0]
        : null;

    return sidePanel;
  },
);

const selectPlatformSidePanelWidth = (state: EditorState) => {
  const sidePanel = selectPlatformSidePanel(state);
  if (!sidePanel) {
    return 0;
  }

  const panelWidth =
    sidePanel?.props?.width ?? constants.RIGHT_AREA.SIDE_DEFAULT_WIDTH;

  return panelWidth + constants.PANELS.SIDE_PANEL_DIVIDER_WIDTH;
};

const isPlatformSidePanelAboveCurrentModal = (state: EditorState) => {
  const sidePanel = selectPlatformSidePanel(state);
  if (!sidePanel) {
    return false;
  }

  const sidePanelProps = sidePanel.props as SidePanelProps;

  return (
    sidePanelProps.displayAboveModals &&
    selectOpenPanels(state).some(({ name }) =>
      constants.PANELS.PANELS_TO_SHRINK_WHEN_SIDE_PANEL_OPEN.includes(name),
    )
  );
};

export {
  selectPlatformSidePanelWidth,
  selectAllDockedPanels,
  selectOpenPanels,
  selectOpenDockedPlatformPanels,
  selectOpenLeftPanels,
  selectOpenRightPanels,
  hasOpenedLeftPanels,
  isDockedSectionOpen,
  selectPanelFilters,
  selectHelpProps,
  isColorPickerClosed,
  isLinkDialogClosed,
  isMobileHiddenItemsPanelOpen,
  isPanelOpenedByName,
  isSidePanelOpened,
  isSidePanelOpenedWithAppDefId,
  isPlatformSidePanelAboveCurrentModal,
};
