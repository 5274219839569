export default {
  STRIP_CONTAINER: 'ad6b47d3-3a27-403e-9d41-7509d8acf3dd',
  STRIP_CONTAINER_COLOR: '761f7434-d98e-47fa-bf38-62105ce45856',
  STRIP_CONTAINER_IMAGE: '992f4c74-324b-4b4c-896c-2e4ef9c4f066',
  STRIP_CONTAINER_WIXVIDEO: '1aba72a2-5cd9-4961-ba00-48287f10b2ab',

  BOX_SLIDESHOW: '3311e23c-6c8c-4a41-938a-480a665afd0a',
  BOX_SLIDESHOW_COLOR: '761f7434-d98e-47fa-bf38-62105ce45856',
  BOX_SLIDESHOW_IMAGE: 'b4229365-db2f-4c38-8c5b-a5f54fd74cca',
  BOX_SLIDESHOW_WIXVIDEO: '2f8b0909-ca10-4897-af90-4b3ff2bd6912',
  MOBILE_BOX_SLIDESHOW: 'ec7b8429-1510-41d8-b5d0-183e840a87fc',

  STRIP_SLIDESHOW: '12d2d586-805b-4f6d-bffd-5723409b2d52',
  STRIP_SLIDESHOW_COLOR: '761f7434-d98e-47fa-bf38-62105ce45856',
  STRIP_SLIDESHOW_IMAGE: 'b4229365-db2f-4c38-8c5b-a5f54fd74cca',
  STRIP_SLIDESHOW_WIXVIDEO: '2f8b0909-ca10-4897-af90-4b3ff2bd6912',
  MOBILE_STRIP_SLIDESHOW: 'ec7b8429-1510-41d8-b5d0-183e840a87fc',

  COLUMNS_CONTAINER: 'ad6b47d3-3a27-403e-9d41-7509d8acf3dd',
  COLUMNS_CONTAINER_COLOR: '761f7434-d98e-47fa-bf38-62105ce45856',
  COLUMNS_CONTAINER_IMAGE: '992f4c74-324b-4b4c-896c-2e4ef9c4f066',
  COLUMNS_CONTAINER_WIXVIDEO: '1aba72a2-5cd9-4961-ba00-48287f10b2ab',

  COLUMNS: 'b4c2eaf1-b054-4f37-b58c-aec08e98c75c',
  COLUMNS_COLOR: '761f7434-d98e-47fa-bf38-62105ce45856',
  COLUMNS_IMAGE: '992f4c74-324b-4b4c-896c-2e4ef9c4f066',
  COLUMNS_WIXVIDEO: '1aba72a2-5cd9-4961-ba00-48287f10b2ab',

  POPUPS: '9e374b79-fd9f-44a3-ac61-40e7e9db206d',
  POPUPS_MOBILE: '56f27a3a-19bf-4c70-8841-7701269228a0',
  POPUPS_COLOR: 'd746aafb-b66f-452f-8a8d-0b8931b201ed',
  POPUPS_IMAGE: '3e372d38-0790-456e-8b71-e03ebcd14c52',
  POPUPS_WIXVIDEO: '6466917b-e74d-41e9-8e25-64f340096fc3',

  POPUPS_OVERLAY: '3c926888-4e12-4d38-8104-45dab3d53859',
  POPUPS_OVERLAY_MOBILE: 'bb08f427-4d9b-4884-9d77-786d35ecfdaa',
  POPUPS_OVERLAY_MOBILE_COLOR: '39d81f1b-f91c-41d1-b2bb-a538da8c60e5',
  POPUPS_OVERLAY_MOBILE_IMAGE: '155b556f-02ca-423f-9743-be91867036b4',
  POPUPS_OVERLAY_COLOR: 'a5f8d1b9-c9fe-403a-bfca-cbaf1e27c6d5',
  POPUPS_OVERLAY_IMAGE: 'ab8404ab-31f9-4669-b88b-bd19db3c8b87',
  POPUPS_OVERLAY_WIXVIDEO: 'a2d0d257-61a5-4d75-91b8-40d43194cd97',

  HOVER_BOX: '0255c079-65c5-4183-84f1-0acd6d03cda7',
  HOVER_BOX_COLOR: 'e2bd1d59-52f1-4c95-947a-30af2fb54f67',
  HOVER_BOX_IMAGE: 'dc35eb1d-1215-4c88-88c8-7ae518cf22ea',
  HOVER_BOX_WIXVIDEO: '9e4e92de-0852-4c05-8412-0b0bd1f4d5da',

  STATE_BOX: '6723c69a-a457-4dc5-aa43-7e3c593f3077',
  STATE_BOX_COLOR: '0101719e-0290-4998-9c29-6db54143c256',
  STATE_BOX_IMAGE: '258b6956-5a1c-4de7-8fc0-a1ae6dc0adb7',
  STATE_BOX_WIXVIDEO: '457b4fc1-0d8a-4a43-995c-7f4ffb28fdc9',

  MENU_CONTAINER: 'c3b1e57c-1bae-46fa-a93a-6a502282fe48',
  MENU_CONTAINER_COLOR: 'f6b79d08-97e4-47af-aef0-8574530b975c',
  MENU_CONTAINER_IMAGE: '97cc6070-c62d-4908-b029-153854692d56',

  BACKGROUND_PANEL: 'f11a3bb9-cff0-46d4-92ff-cc8cd9d30468',
};
