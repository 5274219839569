// @ts-nocheck
import actionTypes from './designPanelActionTypes';

const overrideSections = (overridden) => ({
  type: actionTypes.OVERRIDE_DESIGN_PANEL,
  overridden,
});
const showCustomizeDesignOnly = () => ({
  type: actionTypes.CUSTOMIZE_DESIGN_ONLY,
});
const showCompletePanel = () => ({ type: actionTypes.SHOW_COMPLETE_PANEL });

export { overrideSections, showCustomizeDesignOnly, showCompletePanel };
