import _ from 'lodash';
import * as inlinePopupActionTypes from './inlinePopupActionTypes';

const { SET_OPEN_INLINE_POPUP } = inlinePopupActionTypes;

export const inlinePopupInitialState = {
  openedInlinePopup: null as AnyFixMe,
};

const reducer = (
  inlinePopupState = inlinePopupInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case SET_OPEN_INLINE_POPUP:
      return _.defaultsDeep(
        {
          openedInlinePopup: action.inlinePopupRef,
        },
        inlinePopupState,
      );

    default:
      return inlinePopupState;
  }
};

export default reducer;
