// @ts-nocheck
import _ from 'lodash';
import actionTypes from './tpaActionTypes';
import * as selectors from './tpaSelectors';
import * as selectionActions from '../selection/selectionActions';
import * as panelsActions from '../panels/panelsActions';
import * as platformEvents from 'platformEvents';
import * as util from '@/util';
//eslint-disable-next-line @wix/santa-editor/scoped-imports
import constants from '@/tpa/constants/constants'; // is circular import

import type { AppData } from 'types/documentServices';

const ASCEND_APP_DEF_ID = 'ee21fe60-48c5-45e9-95f4-6ca8f9b1c9d9';

const createAddAppDefIdsToRefreshOnDashboardClose = (appIds) => ({
  type: actionTypes.ADD_APP_IDS_TO_REFRESH_ON_DASHBOARD_CLOSE,
  appIds,
});

const createSetAppDefIdsToRefreshOnDashboardClose = (appIds) => ({
  type: actionTypes.SET_APP_IDS_TO_REFRESH_ON_DASHBOARD_CLOSE,
  appIds,
});

const createAddAppToTrackForUpgrade = (applicationId, applicationData) => ({
  type: actionTypes.ADD_APP_TO_TRACK_FOR_UPGRADE,
  applicationId,
  applicationData,
});

const createRemoveAppFromTrackForUpgrade = (applicationId) => ({
  type: actionTypes.REMOVE_APP_FROM_TRACK_FOR_UPGRADE,
  applicationId,
});

const shouldRefreshGeneralInfo = (refresh) => ({
  type: actionTypes.SHOULD_REFRESH_GENERAL_INFO,
  refresh,
});

const normalizeAppIds = (appIdsToNormalize) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(appIdsToNormalize, normalizeAppId);
const normalizeAppId = (appIdToNormalize) => parseInt(appIdToNormalize, 10);
const getAppIdsToRefresh = (appDefIds, dsRead) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _(appDefIds)
    .map(dsRead.tpa.app.getDataByAppDefId)
    .map('applicationId')
    .compact()
    .value();

const addAppDefIdsToRefreshOnDashboardClose =
  (appDefIds) =>
  (dispatch, getState, { dsRead }) => {
    const appIdsToRefresh = getAppIdsToRefresh(appDefIds, dsRead);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(appDefIds, constants.BUSINESS_MANAGER.GENERAL_INFO)) {
      dispatch(shouldRefreshGeneralInfo(true));
    }
    dispatch(
      createAddAppDefIdsToRefreshOnDashboardClose(
        normalizeAppIds(appIdsToRefresh),
      ),
    );
  };

const setAppDefIdsToRefreshOnDashboardClose =
  (appDefIds) =>
  (dispatch, getState, { dsRead }) => {
    const appIdsToRefresh = getAppIdsToRefresh(appDefIds, dsRead);
    dispatch(
      createSetAppDefIdsToRefreshOnDashboardClose(
        normalizeAppIds(appIdsToRefresh),
      ),
    );
  };

const addAppToTrackForUpgrade =
  (applicationId) =>
  (dispatch, getState, { dsRead }) => {
    const appData = dsRead.tpa.app.getData(applicationId);
    const applicationData = {
      appDefinitionId: appData.appDefinitionId,
      vendorProductId: appData.vendorProductId,
      upgradedToYearly: appData.upgradedToYearly === true,
      instance: appData.instance,
    };

    dispatch(createAddAppToTrackForUpgrade(applicationId, applicationData));
  };

const removeAppFromTrackForUpgrade = (applicationId) => (dispatch) => {
  dispatch(createRemoveAppFromTrackForUpgrade(applicationId));
};

const isUpgradedToYearly = (tpaPremiumState) =>
  tpaPremiumState && tpaPremiumState.cycle === 6;

const openRefreshConfirmOnUpgradeAscend =
  (appDefIdToPremiumState, applicationId) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const appData = dsRead.tpa.app.getData(applicationId);
    const parentAppDefId = appData?.appFields?.premiumBundle?.parentAppId;
    if (
      parentAppDefId === ASCEND_APP_DEF_ID &&
      _.isObject(appDefIdToPremiumState[parentAppDefId])
    ) {
      editorAPI.panelManager.openPanel(
        'tpaPanels.confirmation.refreshForUpgrade',
        {},
        false,
      );
      dispatch(removeAppFromTrackForUpgrade(applicationId));
    }
  };

const updateUpgradedApplications =
  () =>
  (dispatch, getState, { dsRead, dsActions }) => {
    const appsToTrack = selectors.getAppsToTrackForUpgrade(getState());

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/size
    if (_.size(appsToTrack) === 0) {
      return;
    }

    const metaSiteId = dsRead.generalInfo.getMetaSiteId();
    dsRead.tpa.billing.getPremiumApps(metaSiteId, function (premiumApps) {
      const appDefIdToPremiumState = _.keyBy(premiumApps, 'appDefinitionId');
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(appsToTrack, (appToTrack, applicationId) => {
        const tpaPremiumState =
          appDefIdToPremiumState[appToTrack.appDefinitionId];
        if (_.isObject(tpaPremiumState)) {
          if (
            appToTrack.vendorProductId !== tpaPremiumState.vendorProductId ||
            appToTrack.upgradedToYearly !== isUpgradedToYearly(tpaPremiumState)
          ) {
            dispatch(removeAppFromTrackForUpgrade(applicationId));

            dsActions.tpa.provision.refreshAppSpecMap(
              applicationId,
              metaSiteId,
              function () {
                dispatch(selectionActions.clearSelectedComponents());
                dispatch(panelsActions.closeOpenedPanels());
                dsActions.tpa.app.refreshApp(
                  dsRead.tpa.app.getRenderedReactCompsByApplicationId(
                    applicationId,
                  ),
                );

                dsActions.platform.notifyApplication(
                  applicationId,
                  platformEvents.factory.appUpgradeCompleted({
                    vendorProductId: tpaPremiumState.vendorProductId,
                    cycle: tpaPremiumState.cycle,
                    instance: appToTrack.instance,
                  }),
                );
              },
            );
          }
        } else {
          dispatch(
            openRefreshConfirmOnUpgradeAscend(
              appDefIdToPremiumState,
              applicationId,
            ),
          );
        }
      });
    });
  };

const deleteApp =
  (appDefinitionId, options = {}, callback) =>
  (dispatch, getState, { editorAPI }) => {
    const appData: AppData =
      editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
    const applicationId = appData?.applicationId;
    const currentPageId = editorAPI.pages.getCurrentPage()?.id;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const appPageIds = _(editorAPI.pages.getPagesData())
      .filter(
        (pageData) =>
          pageData.tpaApplicationId === applicationId ||
          pageData.managingAppDefId === appDefinitionId,
      )
      .map('id')
      .value();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(appPageIds, currentPageId)) {
      editorAPI.pages.beforePagesRemoveHook(appPageIds, function () {
        editorAPI.dsActions.tpa.app.delete(applicationId, callback, callback);
      });
    } else {
      editorAPI.dsActions.tpa.app.delete(applicationId, callback, callback);
    }
    util.editorWixRecorder.addLabel(
      `${appData.appDefinitionName} component removed`,
    );
    editorAPI.bi.reportBI(util.bi.events.COMPONENT_REMOVED, [
      {
        app_id: appDefinitionId,
        app_site_id: appData.instanceId,
        is_last_tpa: 1,
        removal_method: options.removal_method,
      },
    ]);
  };

const setTpaSettingsPanelExtraProps = (props) => ({
  type: actionTypes.SET_TPA_SETTINGS_PANEL_EXTRA_PROPS,
  props,
});

export {
  addAppDefIdsToRefreshOnDashboardClose,
  setAppDefIdsToRefreshOnDashboardClose,
  setTpaSettingsPanelExtraProps,
  addAppToTrackForUpgrade,
  removeAppFromTrackForUpgrade,
  updateUpgradedApplications,
  deleteApp,
  shouldRefreshGeneralInfo,
};
