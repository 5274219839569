import * as util from '@/util';
import actionTypes from './virtualNumbersActionTypes';
import virtualNumbersConstants from './constants';
import constants from '@/constants';

const VIRTUAL_NUMBERS_API_BASE_URL =
  '/_api/virtual-numbers-manager/v1/virtual-numbers';

const fetchVirtualNumbersData =
  () =>
  async (dispatch: AnyFixMe, _: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const Authorization = editorAPI.dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    ).instance;

    dispatch({
      type: actionTypes.SET_VIRTUAL_NUMBERS_FETCH_STATUS,
      fetchStatus: virtualNumbersConstants.VIRTUAL_NUMBERS_FETCH_STATUS.LOADING,
    });

    try {
      const listResponse = await util.http.fetch(
        `${VIRTUAL_NUMBERS_API_BASE_URL}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization,
          },
        },
      );

      if (!listResponse.ok) {
        throw listResponse;
      }

      const {
        virtualNumberAccounts: [vnAccountInfo],
      } = await listResponse.json();

      if (!vnAccountInfo) {
        throw virtualNumbersConstants.VIRTUAL_NUMBERS_ERROR;
      }

      const virtualNumbersAccountId = vnAccountInfo.id;

      const accountResponse = await util.http.fetch(
        `${VIRTUAL_NUMBERS_API_BASE_URL}/${virtualNumbersAccountId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization,
          },
        },
      );

      if (!accountResponse.ok) {
        throw accountResponse;
      }

      const {
        virtualNumberAccount: { businessNumber, info },
      } = await accountResponse.json();

      dispatch({
        type: actionTypes.SET_VIRTUAL_NUMBERS_DATA,
        virtualNumbersAccountId,
        businessNumber: businessNumber?.canonicalNumber || null,
        status: info?.status || 'UNKNOWN',
      });

      dispatch({
        type: actionTypes.SET_VIRTUAL_NUMBERS_FETCH_STATUS,
        fetchStatus:
          virtualNumbersConstants.VIRTUAL_NUMBERS_FETCH_STATUS.SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: actionTypes.SET_VIRTUAL_NUMBERS_DATA,
        virtualNumbersAccountId: null,
        businessNumber: null,
        status: 'UNKNOWN',
      });

      if ((e as any) in virtualNumbersConstants.VIRTUAL_NUMBERS_ERROR) {
        console.error(e);

        dispatch({
          type: actionTypes.SET_VIRTUAL_NUMBERS_FETCH_STATUS,
          fetchStatus:
            virtualNumbersConstants.VIRTUAL_NUMBERS_FETCH_STATUS.ERROR,
        });
      } else {
        dispatch({
          type: actionTypes.SET_VIRTUAL_NUMBERS_FETCH_STATUS,
          fetchStatus:
            virtualNumbersConstants.VIRTUAL_NUMBERS_FETCH_STATUS.SUCCESS,
        });
      }
    }
  };

export default { fetchVirtualNumbersData };
