import siteCreationActionTypes from './siteCreationActionTypes';

export interface SiteCreationState {
  isFullyDone: boolean;
  shouldShowWizard: boolean;
  sessionInitializedWithWizard: boolean;
  siteCreationUiVisible: boolean;
}

export const siteCreationInitialState: SiteCreationState = {
  isFullyDone: true,
  shouldShowWizard: false,
  sessionInitializedWithWizard: false,
  siteCreationUiVisible: false,
};

const siteCreationReducer = (
  siteCreation: SiteCreationState = siteCreationInitialState,
  action: any,
) => {
  switch (action.type) {
    case siteCreationActionTypes.HIDE_WIZARD:
      return {
        ...siteCreation,
        shouldShowWizard: false,
      };
    case siteCreationActionTypes.SET_SESSION_INITIALIZED_WITH_WIZARD:
      return {
        ...siteCreation,
        isFullyDone: false,
        shouldShowWizard: true,
        sessionInitializedWithWizard: true,
        siteCreationUiVisible: true,
      };
    case siteCreationActionTypes.SET_SITE_CREATION_FINISHED:
      return {
        ...siteCreation,
        siteCreationUiVisible: false,
      };
    case siteCreationActionTypes.SET_SITE_CREATION_FULLY_DONE:
      return {
        ...siteCreation,
        isFullyDone: true,
      };
    default:
      return siteCreation;
  }
};

export default siteCreationReducer;
