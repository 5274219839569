import _ from 'lodash';
import experiment from 'experiment';
import { arrayUtils, mathUtils } from '@/util';
import { layoutUtils, updateLayoutUtil } from '@/layoutUtils';
import * as stateManagement from '@/stateManagement';
import { areComponentsSiblings } from '@/documentServices';
import { isMeshLayoutEnabled, type LayoutMeshApi } from './layoutMeshApi';
import { getLayoutChanges } from './lib/getLayoutChanges';

import type {
  CompRef,
  Docking,
  Rect,
  ResizeDirection,
  ProportionStructure,
  CompLayoutUpdate,
  Point,
} from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { LayoutGetApi } from './layoutGetApi';
import type { LayoutDockApi } from './layoutFixedPositionApi/createLayoutDockApi';

type CompLayoutToUpdate = CompLayoutUpdate & {
  rotationInDegrees?: number;
  // fixedPosition: boolean
  scale?: number;
};

type CompRefs = CompRef[] | CompRef;
/**
 * @deprecated this file is too big and should be decomposed
 */
export function createLayoutApi_structure({
  editorAPI,
  layoutGetApi,
  layoutDockApi,
  layoutMeshApi,
}: {
  editorAPI: EditorAPI;
  layoutGetApi: LayoutGetApi;
  layoutDockApi: LayoutDockApi;
  layoutMeshApi: LayoutMeshApi;
}) {
  const { store } = editorAPI;

  const historyAdd = (
    label: string,
    { dontAddToUndoRedoStack }: { dontAddToUndoRedoStack?: boolean },
  ) => {
    if (!dontAddToUndoRedoStack) {
      editorAPI.history.debouncedAdd(label);
    }
  };

  function updateRelativeToScreenSingle(
    compRef: CompRef,
    layoutUpdatedRelativeToScreen: CompLayoutToUpdate,
    dontAddToUndoRedoStack?: boolean,
    layoutUpdateCtx?: layoutUtils.LayoutUpdateCtx,
  ) {
    layoutUpdatedRelativeToScreen = getConstrainedRelativeToScreenLayout(
      compRef,
      layoutUpdatedRelativeToScreen,
    );
    const layoutCurrent =
      layoutUpdateCtx?.initialAbsLayout ?? layoutGetApi.get(compRef);
    const layoutUpdated =
      layoutUtils.getLayoutRelativeToParentFromRelativeToScreen(
        editorAPI,
        compRef,
        layoutUpdatedRelativeToScreen,
        layoutUpdateCtx,
      );

    updateRelativeToParentInner(compRef, layoutCurrent, layoutUpdated, {
      dontAddToUndoRedoStack,
    });
  }

  function getConstrainedLayout(
    components: CompRefs,
    layoutUpdated: Partial<Rect>,
    forceApply: boolean = false,
  ): Rect {
    const layoutCurrent = editorAPI.components.layout.get(components);
    return updateLayoutUtil.getLayoutAfterConstraints(
      editorAPI,
      components,
      layoutUpdated as Rect,
      layoutCurrent,
      forceApply,
    );
  }

  function getConstrainedRelativeToScreenLayout(
    compRef: CompRef,
    newLayout: Partial<Rect>,
  ) {
    const currentLayout = layoutGetApi.getRelativeToScreen(compRef);
    return updateLayoutUtil.getLayoutRelativeToScreenAfterConstraints(
      editorAPI,
      compRef,
      newLayout as Rect,
      currentLayout,
    );
  }

  function excludeDockedSides(
    components: CompRefs,
    resizableSides: AnyFixMe,
    dockData: Docking,
  ) {
    const isScreenWidthEditBoxExperimentOpen = experiment.isOpen(
      'se_screenWidthEditBox',
    );

    if (
      isScreenWidthEditBoxExperimentOpen &&
      editorAPI.components.layout.isHorizontallyStretchedToScreen(components)
    ) {
      return resizableSides;
    }

    const dockSideToResizeSideMap = {
      left: editorAPI.dsRead.components.layout.RESIZE_SIDES.LEFT,
      right: editorAPI.dsRead.components.layout.RESIZE_SIDES.RIGHT,
      top: editorAPI.dsRead.components.layout.RESIZE_SIDES.TOP,
      bottom: editorAPI.dsRead.components.layout.RESIZE_SIDES.BOTTOM,
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const resizeSidesToExclude = _.map(
      dockData,
      (val, dockDirection) =>
        dockSideToResizeSideMap[
          dockDirection as keyof typeof dockSideToResizeSideMap
        ],
    );

    return _.difference(resizableSides, resizeSidesToExclude);
  }

  function containsSiblingsOnly(compRefs: CompRef[]) {
    return areComponentsSiblings(editorAPI.dsRead, compRefs);
  }

  function isRenderedInFixedPosition(compRefs: CompRefs) {
    const components = arrayUtils.asArray(compRefs);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    const areAllCompsRenderedInFixedPosition = _.every(components, (compRef) =>
      editorAPI.dsRead.components.layout.isRenderedInFixedPosition(compRef),
    );

    return areAllCompsRenderedInFixedPosition;
  }

  function isAncestorRenderedInFixedPosition(compRefs: CompRefs) {
    const components = arrayUtils.asArray(compRefs);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    const areAllCompsRenderedInFixedPosition = _.every(components, (compRef) =>
      editorAPI.dsRead.components.layout.isAncestorRenderedInFixedPosition(
        compRef,
      ),
    );

    return areAllCompsRenderedInFixedPosition;
  }

  function getClosestAncestorRenderedInFixedPosition(compRefs: CompRefs) {
    const component = _.head(arrayUtils.asArray(compRefs));

    return editorAPI.dsRead.components.layout.getClosestAncestorRenderedInFixedPosition(
      component,
    );
  }

  function getResizableSides(compRefs: CompRefs) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      if (editorAPI.components.is.proportionallyResizable(compsArr)) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/values
        return _.values(editorAPI.dsRead.components.layout.RESIZE_SIDES);
      }
      return [];
    }
    const component = _.head(compsArr);
    if (stateManagement.pinMode.selectors.isOpen(store.getState())) {
      return [];
    }

    if (editorAPI.components.is.group(component)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/values
      return _.values(editorAPI.dsRead.components.layout.RESIZE_SIDES);
    }

    let resizableSides =
      editorAPI.dsRead.components.layout.getResizableSides(component);
    const dockData = layoutDockApi.getDock(component);

    if (dockData) {
      resizableSides = excludeDockedSides(compRefs, resizableSides, dockData);
    }

    return resizableSides;
  }

  function checkPropertyForEveryComponentInSelection(
    method: (compRef: CompRef) => boolean,
    compRefs: CompRefs,
  ) {
    const components = arrayUtils.asArray(compRefs);

    if (!_.isEmpty(components)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      return _.every(components, (compRef) => method(compRef));
    }

    return false;
  }

  function getResizableWithAnchors(compRefs: CompRefs) {
    return checkPropertyForEveryComponentInSelection(
      editorAPI.components.is.resizableWithAnchors,
      compRefs,
    );
  }

  function getResizableDiagonally(compRefs: CompRefs) {
    return checkPropertyForEveryComponentInSelection(
      editorAPI.components.is.resizableDiagonally,
      compRefs,
    );
  }

  function getProportionStructure(
    compRefs: CompRef[],
    resizeDirection?: ResizeDirection,
  ) {
    const compsArr = arrayUtils.asArray(compRefs);
    return compsArr.length > 1
      ? getMultiComponentProportionStructure(compsArr, resizeDirection)
      : editorAPI.dsRead.components.layout.getProportionStructure(
          compsArr[0],
          resizeDirection,
        );
  }

  function getMultiComponentProportionStructure(
    compsArr: CompRef[],
    resizeDirection?: ResizeDirection,
  ) {
    const snugLayoutRelativeToScreen =
      layoutGetApi.getRelativeToScreen(compsArr);

    return {
      component: null as null,
      layout: snugLayoutRelativeToScreen,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      children: _.map(compsArr, function getCompProportionStructure(component) {
        const compLayoutRelativeToScreen =
          layoutGetApi.getRelativeToScreen(component);
        const compXYRelativeToSnug = layoutUtils.getLayoutsXYDiff(
          compLayoutRelativeToScreen,
          snugLayoutRelativeToScreen,
        );
        const compLayoutRelativeToSnug = _.defaults(
          compXYRelativeToSnug,
          compLayoutRelativeToScreen,
        );
        const compSnugProportions = layoutUtils.getChildContainerLayoutRatio(
          compLayoutRelativeToSnug,
          snugLayoutRelativeToScreen,
        );

        const compProportionStructure =
          editorAPI.dsRead.components.layout.getProportionStructure(
            component,
            resizeDirection,
          );
        compProportionStructure.proportions = compSnugProportions;
        compProportionStructure.minLayout = _.omit(
          compProportionStructure.minLayout,
          ['x', 'y'],
        );

        return compProportionStructure;
      }),
    };
  }

  function updateRelativeToParentInner(
    compRef: CompRef,
    layoutCurrent: Rect,
    layoutUpdated: CompLayoutToUpdate,
    {
      dontAddToUndoRedoStack,
    }: {
      dontAddToUndoRedoStack?: boolean;
    } = {},
  ) {
    const layoutUpdatedConstrained = updateLayoutUtil.getLayoutAfterConstraints(
      editorAPI,
      compRef,
      layoutUpdated,
      layoutCurrent,
    );
    const { changes } = getLayoutChanges(
      layoutCurrent,
      layoutUpdatedConstrained,
    );

    if (changes.length === 0) {
      return;
    }

    if (!isMeshLayoutEnabled()) {
      editorAPI.dsActions.components.layout.update(
        compRef,
        layoutUpdatedConstrained,
      );

      historyAdd(`component - update layout (${changes.join(', ')})`, {
        dontAddToUndoRedoStack,
      });

      return;
    }

    const layoutWithChanges = _.pick(layoutUpdatedConstrained, changes);

    if (changes.length === 1 && changes[0] === 'rotationInDegrees') {
      return layoutMeshApi.rotateTo(
        compRef,
        layoutWithChanges.rotationInDegrees,
        {
          dontAddToUndoRedoStack,
        },
      );
    }

    if (changes.every((key) => key === 'x' || key === 'y')) {
      return layoutMeshApi.moveTo(compRef, layoutWithChanges, {
        dontAddToUndoRedoStack,
      });
    }

    if (changes.every((key) => key === 'width' || key === 'height')) {
      return layoutMeshApi.resizeTo(compRef, layoutWithChanges, {
        dontApplyConstrains: true,
        dontAddToUndoRedoStack,
      });
    }

    if (changes.includes('fixedPosition') || changes.includes('scale')) {
      throw new Error('not implemented');
    }

    return layoutMeshApi.batchUpdate(compRef, layoutWithChanges, {
      dontApplyConstrains: true,
      dontAddToUndoRedoStack,
    });
  }

  function update(
    compRefOrRefs: CompRef | CompRef[],
    layoutUpdated: CompLayoutToUpdate,
    dontAddToUndoRedoStack?: boolean,
  ) {
    const compRefs = arrayUtils.asArray(compRefOrRefs);

    if (compRefs.length === 0 || !compRefs[0] || !layoutUpdated) {
      return;
    }

    if (compRefs.length === 1) {
      const compRef = compRefs[0];
      const layoutCurrent = editorAPI.components.layout.get(compRef);

      updateRelativeToParentInner(compRef, layoutCurrent, layoutUpdated, {
        dontAddToUndoRedoStack,
      });
      return;
    }

    if (!editorAPI.components.layout.containsSiblingsOnly(compRefs)) {
      throw new Error(
        'Selected components do not share the same parent and therefore cannot be positioned relative to their container',
      );
    }

    const layoutCurrent =
      editorAPI.dsRead.components.layout.getSnugLayout(compRefs);
    const layoutUpdatedConstrained = updateLayoutUtil.getLayoutAfterConstraints(
      editorAPI,
      compRefOrRefs,
      layoutUpdated as Rect,
      layoutCurrent,
    );

    updateComponentsLayoutsWithLayoutXYDiffs(
      compRefs,
      layoutCurrent,
      layoutUpdatedConstrained,
    );

    historyAdd('component - update layout', {
      dontAddToUndoRedoStack,
    });
  }

  function updateComponentsLayoutsWithLayoutXYDiffs(
    compRefs: CompRef[],
    oldLayout: CompLayoutToUpdate,
    newLayout: CompLayoutToUpdate,
  ) {
    const positionOffsets = layoutUtils.getLayoutsXYDiff(newLayout, oldLayout);

    const componentToNewLayoutMap = mapComponentsToNewLayout(
      compRefs,
      positionOffsets,
    );

    componentToNewLayoutMap.forEach((componentLayoutPair) => {
      editorAPI.components.layout.update(
        componentLayoutPair.compRef,
        componentLayoutPair.compPositionUpdated,
      );
    });
  }

  function mapComponentsToNewLayout(
    compRefs: CompRef[],
    compPositionOffsets: { x: number; y: number },
  ) {
    return compRefs.map((compRef) => {
      const { x, y } = layoutGetApi.get(compRef) ?? {};
      const compPosition: Point = { x, y };

      const compPositionUpdated = layoutUtils.addXYOffsetsToLayout(
        compPosition,
        compPositionOffsets,
      );

      return { compRef, compPositionUpdated };
    });
  }

  function updateRelativeToStructure(
    compRefs: CompRefs,
    newLayoutRelativeToStructure: CompLayoutToUpdate,
    dontAddToUndoRedoStack?: boolean,
  ) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      const { x, y } = layoutGetApi.getRelativeToStructure(compsArr) ?? {};
      const currentLayoutRelativeToStructure = { x, y };

      updateComponentsLayoutsWithLayoutXYDiffs(
        compsArr,
        currentLayoutRelativeToStructure,
        newLayoutRelativeToStructure,
      );
    } else {
      const compRef = _.head(compsArr);
      const newLayoutRelativeToParent =
        layoutUtils.getLayoutRelativeToParentFromRelativeToStructure(
          editorAPI,
          compRef,
          newLayoutRelativeToStructure,
        );
      editorAPI.components.layout.update(
        compRef,
        newLayoutRelativeToParent,
        dontAddToUndoRedoStack,
      );
    }
  }

  function updateRelativeToScreen(
    compRefOrRefs: CompRef | CompRef[],
    relativeToScreenUpdated: CompLayoutToUpdate,
    dontAddToUndoRedoStack?: boolean,
    layoutUpdateCtx?: layoutUtils.LayoutUpdateCtx,
  ) {
    const compRefs = arrayUtils.asArray(compRefOrRefs);
    if (compRefs.length > 1) {
      const { x, y } = layoutGetApi.getRelativeToScreen(compRefs) ?? {};
      const relativeToScreenInitial: Point = { x, y };

      updateComponentsLayoutsWithLayoutXYDiffs(
        compRefs,
        relativeToScreenInitial,
        relativeToScreenUpdated,
      );
    } else {
      updateRelativeToScreenSingle(
        compRefs[0],
        relativeToScreenUpdated,
        dontAddToUndoRedoStack,
        layoutUpdateCtx,
      );
    }
  }

  function updateRelativeToStructureAndPreserveProportions(
    compRefs: CompRef[],
    newLayoutRelativeToStructure: CompLayoutToUpdate,
    proportionStructure: ProportionStructure,
  ) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      const newLayoutRelativeToScreen =
        layoutUtils.getLayoutRelativeToScreenFromRelativeToStructure(
          editorAPI,
          compsArr,
          newLayoutRelativeToStructure,
        );
      updateMultiCompsRelativeToScreenAndPreserveProportions(
        compsArr,
        newLayoutRelativeToScreen,
        proportionStructure,
      );
    } else {
      const compRef = _.head(compsArr);
      const newLayoutRelativeToParent =
        layoutUtils.getLayoutRelativeToParentFromRelativeToStructure(
          editorAPI,
          compRef,
          newLayoutRelativeToStructure,
        );
      updateAndPreserveProportions(
        compRef,
        newLayoutRelativeToParent,
        proportionStructure,
      );
    }
  }

  function updateMultiCompsRelativeToScreenAndPreserveProportions(
    compsArr: CompRef[],
    newLayoutRelativeToScreen: CompLayoutToUpdate,
    proportionStructure: ProportionStructure,
  ) {
    _.defaults(newLayoutRelativeToScreen, proportionStructure.layout);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      proportionStructure.children,
      function updateChildLayoutRelativeToScreen(childProportionStructure) {
        const newCompLayoutRelativeToScreen = getNewProportionalLayoutFromSnug(
          childProportionStructure,
          newLayoutRelativeToScreen,
        );
        editorAPI.components.layout.updateRelativeToScreenAndPreserveProportions(
          childProportionStructure.component,
          newCompLayoutRelativeToScreen,
          childProportionStructure,
        );
      },
    );
  }

  function getNewProportionalLayoutFromSnug(
    compProportionStructure: ProportionStructure,
    newSnugLayout: CompLayoutToUpdate,
  ) {
    const newCompLayoutRelativeToSnug =
      layoutUtils.calcLayoutFromLayoutAndRatio(
        newSnugLayout,
        compProportionStructure.proportions,
      );
    const newCompLayoutRelativeToScreen = layoutUtils.addXYOffsetsToLayout(
      newCompLayoutRelativeToSnug,
      newSnugLayout,
    );
    return newCompLayoutRelativeToScreen;
  }

  function updateRelativeToScreenAndPreserveProportions(
    compRefs: CompRefs,
    newLayoutRelativeToScreen: CompLayoutToUpdate,
    proportionStructure: ProportionStructure,
  ) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      updateMultiCompsRelativeToScreenAndPreserveProportions(
        compsArr,
        newLayoutRelativeToScreen,
        proportionStructure,
      );
    } else {
      const compRef = _.head(compsArr);
      const newLayoutRelativeToParent =
        layoutUtils.getLayoutRelativeToParentFromRelativeToScreen(
          editorAPI,
          compRef,
          newLayoutRelativeToScreen,
        );
      updateAndPreserveProportions(
        compRef,
        newLayoutRelativeToParent,
        proportionStructure,
      );
    }
  }

  function updateAndPreserveProportions(
    compRefs: CompRefs,
    newLayout: CompLayoutToUpdate,
    proportionStructure: ProportionStructure,
  ) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      if (!editorAPI.components.layout.containsSiblingsOnly(compsArr)) {
        throw new Error(
          'Selected components do not share the same parent and therefore cannot be positioned relative to their container',
        );
      }

      const newLayoutRelativeToScreen =
        layoutUtils.getLayoutRelativeToScreenFromRelativeToParent(
          editorAPI,
          compsArr,
          newLayout,
        );
      updateMultiCompsRelativeToScreenAndPreserveProportions(
        compsArr,
        newLayoutRelativeToScreen,
        proportionStructure,
      );
    } else {
      if (isMeshLayoutEnabled()) {
        throw new Error('not implemented');
      }

      editorAPI.dsActions.components.layout.updateAndPreserveProportions(
        newLayout,
        proportionStructure,
        true,
      );
    }
  }

  function dragAndPush(
    compRef: CompRef,
    layoutRelativeToScreen: CompLayoutToUpdate,
  ) {
    const layoutToUpdate =
      layoutUtils.getLayoutRelativeToParentFromRelativeToScreen(
        editorAPI,
        compRef,
        layoutRelativeToScreen,
      );
    const constrainedLayout = getConstrainedLayout(compRef, layoutToUpdate);

    editorAPI.dsActions.components.layout.updateAndPushUpdate(compRef, {
      y: constrainedLayout.y,
    });

    return { layout: constrainedLayout };
  }

  /**
   * @deprecated use `components.layout.resizeToAndPush` instead
   */
  function resizeAndPush(
    compRef: CompRef,
    layoutRelativeToScreen: CompLayoutToUpdate,
  ) {
    const layoutToUpdate =
      layoutUtils.getLayoutRelativeToParentFromRelativeToScreen(
        editorAPI,
        compRef,
        layoutRelativeToScreen,
      );
    const constrainedLayout = getConstrainedLayout(compRef, layoutToUpdate);

    editorAPI.dsActions.components.layout.updateAndPushUpdate(compRef, {
      height: constrainedLayout.height,
    });

    return { layout: constrainedLayout };
  }

  function updateAndAdjustLayout(
    compRefs: CompRefs,
    newLayout: CompLayoutToUpdate,
    dontAddToUndoRedoStack?: boolean,
  ) {
    const keys = Object.keys(newLayout);
    if (keys.length === 1 && keys[0] === 'scale') {
      editorAPI.components.layout.update(
        compRefs,
        {
          scale: newLayout.scale,
        },
        dontAddToUndoRedoStack,
      );
      return;
    }

    const compsArr = arrayUtils.asArray(compRefs);

    newLayout = getConstrainedLayout(compRefs, newLayout);

    if (compsArr.length > 1) {
      updateAndAdjustLayoutForMultiComps(compsArr, newLayout);
    } else {
      _updateAndAdjustLayoutForSingle(compsArr[0], newLayout);
    }

    if (!dontAddToUndoRedoStack) {
      editorAPI.history.debouncedAdd('component - update layout');
    }
  }

  function _updateAndAdjustLayoutForSingle(
    compRef: CompRef,
    newLayout: CompLayoutToUpdate,
  ) {
    editorAPI.dsActions.components.layout.updateAndPushStart(compRef);
    editorAPI.dsActions.components.layout.updateAndPushUpdate(
      compRef,
      newLayout,
    );
    editorAPI.dsActions.components.layout.updateAndPushEnd(compRef);
  }

  function updateAndAdjustLayoutForMultiComps(
    compRefs: CompRef[],
    newLayout: CompLayoutToUpdate,
  ) {
    if (!editorAPI.components.layout.containsSiblingsOnly(compRefs)) {
      throw new Error(
        'Cannot update and adjust layout of non-siblings components',
      );
    }
    let currentSnugLayout: CompLayoutToUpdate =
      editorAPI.dsRead.components.layout.getSnugLayout(compRefs);
    currentSnugLayout = _.pick(currentSnugLayout, 'y');

    const compPositionOffsets = layoutUtils.getLayoutsXYDiff(
      newLayout,
      currentSnugLayout,
    );
    const componentToLayoutMap = mapComponentsToNewLayout(
      compRefs,
      compPositionOffsets,
    );

    componentToLayoutMap.forEach((componentToLayoutPair) => {
      if (isMeshLayoutEnabled()) {
        throw new Error('not implemented');
      }

      editorAPI.dsActions.components.layout.updateAndAdjustLayout(
        componentToLayoutPair.compRef,
        componentToLayoutPair.compPositionUpdated,
      );
    });
  }

  function updateRelativeToScreenAndAdjustLayout(
    component: CompRef,
    layoutRelativeToScreen: CompLayoutToUpdate,
    dontAddToUndoRedoStack?: boolean,
  ) {
    const layoutToUpdate =
      layoutUtils.getLayoutRelativeToParentFromRelativeToScreen(
        editorAPI,
        component,
        layoutRelativeToScreen,
      );
    return editorAPI.components.layout.updateAndAdjustLayout(
      component,
      layoutToUpdate,
      dontAddToUndoRedoStack,
    );
  }

  function updateAspectRatio(compRefs: CompRefs) {
    return arrayUtils.applyForAll(
      editorAPI.dsActions.components.layout.updateAspectRatio,
      compRefs,
    );
  }

  function removeAspectRatio(compRefs: CompRefs) {
    return arrayUtils.applyForAll(
      editorAPI.dsActions.components.layout.removeAspectRatio,
      compRefs,
    );
  }

  function isAspectRatioOn(compRefs: CompRefs) {
    const components = arrayUtils.asArray(compRefs);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    return _.every(components, (compRef) =>
      editorAPI.dsRead.components.layout.isAspectRatioOn(compRef),
    );
  }

  /**
   * Reset comp aspect ratio
   * NOTE: Does not add an undo step
   * @param {pointer} compRef
   * @param {number} newShapeAspectRatio
   * @param {boolean} [center]
   */
  function resetCompLayoutToAspectRatio(
    compRef: CompRef,
    newShapeAspectRatio: number,
    center: boolean,
  ) {
    const layout = layoutGetApi.getRelativeToStructure(compRef);

    const alignments: AnyFixMe = _.mapValues(layout.docked, () => true);
    alignments.center =
      alignments.vCenter ||
      alignments.hCenter ||
      (_.isEmpty(alignments) && center);

    const updatedLayout = mathUtils.rect.getRectBoundWithAspectRatio(
      layout,
      newShapeAspectRatio,
      null,
      alignments,
    );
    editorAPI.components.layout.updateRelativeToStructure(
      compRef,
      updatedLayout,
      true,
    );
  }

  return {
    //layout fns
    getConstrainedLayout,
    //layout fns
    isRenderedInFixedPosition,
    isAncestorRenderedInFixedPosition,
    getClosestAncestorRenderedInFixedPosition,
    getResizableSides,
    getResizableWithAnchors,
    getResizableDiagonally,
    getProportionStructure,
    update,
    updateRelativeToStructure,
    updateRelativeToScreen,
    updateRelativeToStructureAndPreserveProportions,
    updateRelativeToScreenAndPreserveProportions,
    updateAndPreserveProportions,
    updateAndAdjustLayout,
    _updateAndAdjustLayoutForSingle,
    updateRelativeToScreenAndAdjustLayout,
    dragAndPush,
    resizeAndPush,
    resetCompLayoutToAspectRatio,
    updateAspectRatio,
    removeAspectRatio,
    isAspectRatioOn,
    containsSiblingsOnly,
  };
}

export type LayoutApiByStructure = ReturnType<typeof createLayoutApi_structure>;
