import _ from 'lodash';
import developerToolBarActionTypes from './developerToolBarActionTypes';

export const developerToolBarInitialState = {
  overrides: {
    showOnlySavedEvents: false,
    isReadOnlyEvents: false,
    disableLinkToCode: false,
    shouldShowEvents: true,
  },
};

const reducer = (
  developerToolBar = developerToolBarInitialState,
  action: AnyFixMe,
) => {
  switch (action.type) {
    case developerToolBarActionTypes.OVERRIDE:
      const overrides = action?.overrides ?? developerToolBar.overrides;
      return _.defaultsDeep({}, { overrides }, developerToolBar);
    default:
      return developerToolBar;
  }
};

export default reducer;
