import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export interface ComponentSourceData {
  type: 'Source';
  original: string;
  current: string;
  changed: boolean;
}

const createDefaultSourceData = (
  sourceValue: string,
  changed: boolean = false,
): ComponentSourceData => ({
  type: 'Source',
  original: sourceValue,
  current: sourceValue,
  changed,
});

export const getComponentSource = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): ComponentSourceData | null =>
  editorAPI.components.features.get(compRef, 'source') as ComponentSourceData;

export const updateComponentSource = (
  editorAPI: EditorAPI,
  {
    compRef,
    source,
    changedOverride,
  }: {
    compRef: CompRef;
    source: string;
    changedOverride?: boolean;
  },
): void => {
  const currentValue = getComponentSource(editorAPI, compRef);
  const updatedChangedValue =
    typeof changedOverride === 'boolean' ? changedOverride : true;
  const updatedValue = currentValue
    ? { ...currentValue, current: source, changed: updatedChangedValue }
    : createDefaultSourceData(source, changedOverride);

  editorAPI.components.features.update(compRef, 'source', updatedValue);
};

export const updateAllChildrenSourceData = (
  editorAPI: EditorAPI,
  {
    source,
    parentRef,
    changedOverride,
  }: {
    source: string;
    parentRef?: CompRef;
    changedOverride?: boolean;
  },
): void => {
  // for now we support only text elements
  const children =
    editorAPI.deprecatedOldBadPerformanceApis.components.get.byType(
      'wysiwyg.viewer.components.WRichText',
      parentRef,
    );

  children.forEach((compRef) =>
    updateComponentSource(editorAPI, { compRef, source, changedOverride }),
  );
};
