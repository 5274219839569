import _ from 'lodash';
import * as componentsStoreActionTypes from './componentsStoreActionTypes';
import type { CompRef } from 'types/documentServices';
import type { Reducer } from 'types/redux';

const { UPDATE_COMPONENT_STORE, REMOVE_COMPONENTS_STORE } =
  componentsStoreActionTypes;

export interface ComponentsStoreState {
  [compId: string]: {
    [key: string]: unknown;
    compRef: CompRef;
  };
}

export const componentsStoreInitialState: ComponentsStoreState = {};

const getMutatedStore = (
  store: ComponentsStoreState,
  { comp, key, value }: AnyFixMe,
): ComponentsStoreState =>
  _.defaultsDeep(
    {
      [comp.id]: {
        [key]: value,
        compRef: comp,
      },
    },
    store,
  );

const reducer: Reducer<ComponentsStoreState> = (
  componentsStore = componentsStoreInitialState,
  action,
) => {
  switch (action.type) {
    case UPDATE_COMPONENT_STORE:
      return getMutatedStore(componentsStore, action);

    case REMOVE_COMPONENTS_STORE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      return _.omit(componentsStore, _.map(action.comps, 'id'));

    default:
      return componentsStore;
  }
};

export default reducer;
