import { interactionsActions } from './interactionsActions';
import { interactionsReducer } from './interactionsReducer';
import { interactionsSelectors } from './interactionsSelectors';
import { interactionsContext } from './interactionsContext';

export const interactions = {
  actions: interactionsActions,
  reducer: interactionsReducer,
  selectors: interactionsSelectors,
  context: interactionsContext,
};
