import { createReduxStore } from '@/apilib';

export const EditorRestrictionsVersionStore = createReduxStore({
  getInitialState: () => ({
    restrictionsVersion: 0,
  }),
  actions: {
    restrictionsVersionChanged: (state) => ({
      restrictionsVersion: state.restrictionsVersion + 1,
    }),
  },
  selectors: {
    getRestrictionsVersion: (state) => state.restrictionsVersion,
  },
});
