import React from 'react';

export interface DragLayoutContext {
  offsetX: number;
  offsetY: number;
  dragging: boolean;
  rotateDisabled: boolean;
  skewDisabled: boolean;
  isDraggingWithHandle: boolean;
}

const initialState: DragLayoutContext = {
  offsetX: 0,
  offsetY: 0,
  dragging: false,
  rotateDisabled: false,
  skewDisabled: false,
  isDraggingWithHandle: false,
};

const InteractionsContext = React.createContext(initialState);

let layoutSetter: AnyFixMe;
let layoutGetter: AnyFixMe;
let layoutReseter: AnyFixMe;

const setLayout = (newLayout: AnyFixMe): void => {
  if (typeof layoutSetter === 'function') {
    layoutSetter(newLayout);
  }
};

const getLayout = (): DragLayoutContext => {
  if (typeof layoutGetter === 'function') {
    return layoutGetter();
  }
  return initialState;
};

const resetLayout = (): void => {
  if (typeof layoutReseter === 'function') {
    layoutReseter();
  }
};

class DragLayoutProvider extends React.Component<{}, DragLayoutContext> {
  constructor(props: AnyFixMe) {
    super(props);

    this.state = initialState;

    layoutSetter = (newLayout: DragLayoutContext) => {
      this.setState({ ...this.state, ...newLayout });
    };

    layoutGetter = () => this.state;

    layoutReseter = () => this.setState({ ...initialState });
  }
  componentWillUnmount() {
    layoutSetter = layoutGetter = layoutReseter = undefined;
  }
  render() {
    return React.createElement(
      InteractionsContext.Provider,
      { value: this.state },
      this.props.children,
    );
  }
}

const DragLayoutConsumer = InteractionsContext.Consumer;

export const interactionsContext = {
  setLayout,
  getLayout,
  resetLayout,
  InteractionsContext,
  DragLayoutProvider,
  DragLayoutConsumer,
  reRenderConsumers: () => {
    setLayout({});
  },
};
