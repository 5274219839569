import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { PinDockingDirection } from './types';

export function calcPinDockingDirectionForNotPined(
  editorAPI: EditorAPI,
  compRef: CompRef,
): PinDockingDirection {
  const compLayout = editorAPI.components.layout.getRelativeToScreen(compRef);
  const editingAreaLayout = editorAPI.ui.stage.getEditingAreaLayout();

  const centerCompX = compLayout.x + compLayout.width / 2;
  const centerCompY = compLayout.y + compLayout.height / 2;
  const thirdPageWidth = editingAreaLayout.width / 3;

  if (centerCompY <= editingAreaLayout.height / 2) {
    if (centerCompX <= thirdPageWidth) {
      return 'TOP_LEFT';
    }

    if (centerCompX <= thirdPageWidth * 2) {
      return 'TOP';
    }

    return 'TOP_RIGHT';
  }

  if (centerCompX <= thirdPageWidth) {
    return 'BOTTOM_LEFT';
  }

  if (centerCompX <= thirdPageWidth * 2) {
    return 'BOTTOM';
  }

  return 'BOTTOM_RIGHT';
}
