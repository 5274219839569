export default {
  DEFAULT: '38d87f84-c844-440c-9ba0-089d7507f6c2',
  CUSTOMIZE_POPUP_CONTAINER: 'a66428d2-ce4d-4a1d-80cd-3df2334c48ac',
  POPUP_CONTAINER: 'efeccf74-7b6d-40fb-9e09-dc1110dfd1ef',
  CLOSE_BUTTON: '38d87f84-c844-440c-9ba0-089d7507f6c2',
  CUSTOMIZE_CLOSE_ICON_BUTTON: 'a66428d2-ce4d-4a1d-80cd-3df2334c48ac',
  TRANSITION: 'd448918d-3633-4b98-b28c-b8d4c32d4fce',
  MOBILE_ANIMATIONS: 'dbcdb401-a000-4378-a45d-924b7382d45b',
  LEGACY_MESSAGE: '91be65db-1fac-4dd2-a81a-01c6427032ca',
};
