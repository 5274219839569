import { sectionsActionTypes } from './sections.actionTypes';

import type { CompRef } from 'types/documentServices';
import type { Action } from 'redux';

export interface SetHoveredSectionLikeAction
  extends Action<typeof sectionsActionTypes.SET_HOVERED_SECTION_LIKE_REF> {
  payload: CompRef;
}

const setHoveredSectionLike = (ref: CompRef): SetHoveredSectionLikeAction => ({
  type: sectionsActionTypes.SET_HOVERED_SECTION_LIKE_REF,
  payload: ref,
});

export interface ShowSectionizedPanelAction
  extends Action<typeof sectionsActionTypes.SHOW_SECTIONIZED_PANEL> {}

const showSectionizedPanel = () => ({
  type: sectionsActionTypes.SHOW_SECTIONIZED_PANEL,
});

export interface HideSectionizedPanelAction
  extends Action<typeof sectionsActionTypes.HIDE_SECTIONIZED_PANEL> {}

const hideSectionizedPanel = () => ({
  type: sectionsActionTypes.HIDE_SECTIONIZED_PANEL,
});

export interface SetIsSectionActionsBarHoveredAction
  extends Action<typeof sectionsActionTypes.SET_SECTION_ACTION_BAR_HOVERED> {
  payload: boolean;
}

export const setIsSectionActionsBarHovered = (hovered: Boolean) => ({
  type: sectionsActionTypes.SET_SECTION_ACTION_BAR_HOVERED,
  payload: hovered,
});

export const sectionsActions = {
  setHoveredSectionLike,
  showSectionizedPanel,
  hideSectionizedPanel,
  setIsSectionActionsBarHovered,
};
