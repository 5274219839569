import { arrayUtils } from '@/util';
import { calulateRectInContainer_byRectInAnotherContainer } from './calculatePositionInContainer';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, Rect } from 'types/documentServices';
import type { LayoutMeshApi } from '../createLayoutMeshApi';

export function createLayoutMeshReparentApi({
  editorAPI,
  layoutMeshCoreApi,
}: {
  editorAPI: EditorAPI;
  layoutMeshCoreApi: LayoutMeshApi['__core'];
}) {
  /**
   * Reparent components and update containers grids (before 'onedock', `components.setContainer` updated layouts automatically under the hood)
   *  - supports only components with MeshItemLayout
   *
   * @param compRefs - components with MeshItemLayout
   * @param containerToByCompId - target container by comp id (for reparenting)
   * @param params
   * @param params.compRectsMap - initial comp rect by comp id (important when comp rects from DOM is currently different from reqired: 1. you wont to update rects; 2. some transition is in process)
   * @returns `compRefUpdatedMap` - map of component refs after reparenting (that could be different from initial)
   */
  async function reparentAndUpdateContainersGrids(
    compRefs: CompRef[],
    containerToByCompId: Map<string, CompRef>,
    {
      compRectsMap,
    }: {
      compRectsMap?: Map<string, Rect>;
    } = {},
  ) {
    const updatedContainersByContainerId = new Map<
      string,
      {
        containerRef: CompRef;
        compRefsToAttach: CompRef[];
        compRectsMap: Map<string, Rect>;
      }
    >();

    function scheduleUpdateContainer(containerRef: CompRef) {
      if (!updatedContainersByContainerId.has(containerRef.id)) {
        /**
         * ⚠️ it's important to gather all container children rects before any reparenting
         */
        const compRectsMapInitial = new Map<string, Rect>(
          editorAPI.components
            .getChildren(containerRef)
            .map((compRef) => [
              compRef.id,
              compRectsMap?.get(compRef.id) ??
                layoutMeshCoreApi.measureRect(compRef),
            ]),
        );

        updatedContainersByContainerId.set(containerRef.id, {
          containerRef,
          compRefsToAttach: [],
          compRectsMap: compRectsMapInitial,
        });
      }

      return updatedContainersByContainerId.get(containerRef.id)!;
    }

    // Phase 1: schedule components layout updates and reparenting
    compRefs.forEach((compRef) => {
      const containerFromRef = editorAPI.components.getContainer(compRef);
      const containerToRef = containerToByCompId.get(compRef.id);

      if (
        !containerToRef ||
        editorAPI.utils.isSameRef(containerFromRef, containerToRef)
      ) {
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const scheduledUpdate = scheduleUpdateContainer(containerFromRef);
      } else {
        const scheduledUpdateFrom = scheduleUpdateContainer(containerFromRef);
        const scheduledUpdateTo = scheduleUpdateContainer(containerToRef);

        const compRectInitial = scheduledUpdateFrom.compRectsMap.get(
          compRef.id,
        );

        scheduledUpdateFrom.compRectsMap.delete(compRef.id);
        scheduledUpdateTo.compRectsMap.set(
          compRef.id,
          calulateRectInContainer_byRectInAnotherContainer(editorAPI, {
            compRectInContainerFrom: compRectInitial,
            containerFromRef,
            containerToRef,
          }),
        );

        scheduledUpdateTo.compRefsToAttach.push(compRef);
      }
    });

    // after reparenting component refs could be changed
    const compRefUpdatedMap = new Map<string, CompRef>();

    await editorAPI.transactions.run(async () => {
      // Phase 2: apply scheduled containers updates - reparent components
      updatedContainersByContainerId.forEach((containerUpdate) => {
        if (containerUpdate.compRefsToAttach.length === 0) {
          return;
        }

        const compRefsReparented = arrayUtils.asArray(
          editorAPI.components.setContainer(
            containerUpdate.compRefsToAttach,
            containerUpdate.containerRef,
          ),
        );

        compRefsReparented.forEach((compRefUpdated, index) => {
          const compRefBeforeReparent = containerUpdate.compRefsToAttach[index];

          compRefUpdatedMap.set(compRefBeforeReparent.id, compRefUpdated);

          if (compRefUpdated.id !== compRefBeforeReparent.id) {
            const compRectUpdated = containerUpdate.compRectsMap.get(
              compRefBeforeReparent.id,
            );

            containerUpdate.compRectsMap.set(
              compRefUpdated.id,
              compRectUpdated,
            );
            containerUpdate.compRectsMap.delete(compRefBeforeReparent.id);
          }
        });
      });

      // Phase 3: apply scheduled containers updates - update container grid
      // ⚠️ important to update grid after reparenting
      updatedContainersByContainerId.forEach((containerUpdate) => {
        layoutMeshCoreApi.updateContainerGrid(containerUpdate.containerRef, {
          compRectsMap: containerUpdate.compRectsMap,
        });
      });
    });

    return { compRefUpdatedMap };
  }

  return {
    reparentAndUpdateContainersGrids,
  };
}

export type LayoutMeshReparentApi = ReturnType<
  typeof createLayoutMeshReparentApi
>;
