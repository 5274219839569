const VIRTUAL_NUMBERS_FETCH_STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

const VIRTUAL_NUMBERS_ERROR = {
  NO_ACCOUNTS: 'NO_ACCOUNTS',
};

const VIRTUAL_NUMBERS_ACCOUNT_STATUS = {
  UNKNOWN: 'UNKNOWN',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  SUSPENDED: 'SUSPENDED',
};

export default {
  VIRTUAL_NUMBERS_FETCH_STATUS,
  VIRTUAL_NUMBERS_ACCOUNT_STATUS,
  VIRTUAL_NUMBERS_ERROR,
};
