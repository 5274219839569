import * as mobileKeyboardActionTypes from './mobileKeyboardTypes';

const setLastKeyboardContext = (context: any) => ({
  type: mobileKeyboardActionTypes.SET_LAST_KEYBOARD_CONTEXT,
  lastKeyboardContext: context,
});

const setIsCollapsingMenuFromKeyboard = (value: any) => ({
  type: mobileKeyboardActionTypes.SET_IS_COLLAPSING_MENU_FROM_KEYBOARD,
  isCollapsingMenuFromKeyboard: value,
});

export { setLastKeyboardContext, setIsCollapsingMenuFromKeyboard };
