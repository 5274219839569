import type { BeforePlugin, AfterPlugin } from 'types/editorPlugins';

import page from './plugins/page';
import anchor from './plugins/anchor';
import documentMedia from './plugins/documentMedia';
import audioPlayer from './plugins/audioPlayer';
import singleAudioPlayer from './plugins/singleAudioPlayer';
import contactForm from './plugins/contactForm';
import clipArt from './plugins/clipArt';
import svgShape from './plugins/svgShape';
import wPhoto from './plugins/wPhoto';
import verticalAnchorsMenu from './plugins/verticalAnchorsMenu';
import stripContainer from './plugins/stripContainer';
import appControllerAddPlugin from './plugins/appControllerAddPlugin';
import vectorImageBefore from './plugins/vectorImageBefore';
import vectorImageAfter from './plugins/vectorImageAfter';
import wTwitterTweet from './plugins/wTwitterTweet';
import htmlComponent from './plugins/htmlComponent';
import wRichText from './plugins/wRichText';
import fillTextContentSource from './plugins/fillTextContentSource';
import classicSectionBefore from './plugins/classicSectionBefore';

const before: Record<string, BeforePlugin> = {
  'mobile.core.components.Page': page,
  'wysiwyg.common.components.anchor.viewer.Anchor': anchor,
  'wysiwyg.viewer.components.documentmedia.DocumentMedia': documentMedia,
  'wysiwyg.viewer.components.AudioPlayer': audioPlayer,
  'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer':
    singleAudioPlayer,
  'wysiwyg.viewer.components.ClipArt': clipArt,
  'wysiwyg.viewer.components.ContactForm': contactForm,
  'wysiwyg.viewer.components.DynamicContactForm': contactForm,
  'wysiwyg.viewer.components.svgshape.SvgShape': svgShape,
  'wysiwyg.viewer.components.WPhoto': wPhoto,
  'wysiwyg.viewer.components.StripContainer': stripContainer,
  'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu':
    verticalAnchorsMenu,
  'wysiwyg.viewer.components.WTwitterTweet': wTwitterTweet,
  'wysiwyg.viewer.components.VectorImage': vectorImageBefore,
  'wysiwyg.viewer.components.ClassicSection': classicSectionBefore,
};

const after: Record<string, AfterPlugin> = {
  'platform.components.AppController': appControllerAddPlugin,
  'wysiwyg.viewer.components.VectorImage': vectorImageAfter,
  'wysiwyg.viewer.components.HtmlComponent': htmlComponent,
  'wysiwyg.viewer.components.WRichText': wRichText,
  'wysiwyg.viewer.components.StripColumnsContainer': fillTextContentSource,
  'wysiwyg.viewer.components.ClassicSection': fillTextContentSource,
};

export default {
  before,
  after,
};
