// @ts-nocheck
import * as utils from '@wix/santa-editor-utils';
import { recompose } from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'reactDOM';
import $ from 'zepto';

const { measuring } = utils.hoc;

/**
 * measures the panel position and adds it to the context
 * @returns {Function}
 */
const panelPositionContext = () => (Component) => {
  // eslint-disable-next-line react/prefer-es6-class
  const CompWithContext = createReactClass({
    displayName: recompose.wrapDisplayName(Component, 'panelPosition'),
    propTypes: {
      style: PropTypes.object,
      measurements: PropTypes.object.isRequired,
      reportMeasurements: PropTypes.func.isRequired,
    },
    childContextTypes: {
      panelPosition: PropTypes.object,
    },
    getChildContext() {
      return {
        panelPosition: this.props.measurements,
      };
    },
    componentDidMount() {
      this.measurePanel();
    },
    componentDidUpdate() {
      this.measurePanel();
    },
    measurePanel() {
      const $panelNode = $(ReactDOM.findDOMNode(this));
      this.props.reportMeasurements($panelNode.position());
    },
    render() {
      return React.createElement(Component, this.props);
    },
  });

  return measuring(CompWithContext);
};

export default panelPositionContext;
