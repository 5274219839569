import type { Reducer } from 'types/redux';
import { UPDATE_CONCURRENT_USERS } from './concurrentUsersActionsTypes';

export interface ConcurrentUsersState {
  isFirstUser: boolean;
  concurrentUsers: string[];
  concurrentUserToDisplay: string;
}

export const initialConcurrentUsersState: ConcurrentUsersState = {
  isFirstUser: false,
  concurrentUsers: [],
  concurrentUserToDisplay: '',
};

const reducer: Reducer<ConcurrentUsersState> = (
  concurrentUsers = initialConcurrentUsersState,
  action,
) => {
  switch (action.type) {
    case UPDATE_CONCURRENT_USERS:
      return Object.assign({}, concurrentUsers, action.concurrentUsers);
    default:
      return concurrentUsers;
  }
};

export default reducer;
