export const FIRST_TIME_PANEL_COMPONENTS = [
  'Anchor',
  'VerticalAnchorsMenu',
  'BoxSlideShow',
  'StripContainerSlideShow',
  'PopupContainer',
  'PopupCloseTextButton',
  'HoverBox',
  'Column',
  'Pagination',
  'MenuToggle',
  'StateBox',
];

export const CUSTOM_MENUS_FIRST_TIME_PANEL_COMPONENTS = [
  'StylableHorizontalMenu',
  'DropDownMenu',
  'VerticalMenu',
];

export const ALLOWED_PANELS_DURING_WIZARD = [
  'panels.focusPanels.progressBarPanel',
];
