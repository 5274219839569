export default {
  SET_LATEST_VERSIONS: 'SET_LATEST_VERSIONS',
  SET_AVAILABLE_APPS: 'SET_AVAILABLE_APPS',
  SET_FRESH_APPS_DATA: 'SET_FRESH_APPS_DATA',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_APP_INSTALLATION_IN_PROGRESS: 'SET_IS_APP_INSTALLATION_IN_PROGRESS',
  SET_IS_IMPORTING_APP: 'SET_IS_IMPORTING_APP',
  SET_IS_LOADING_APPS_ERROR: 'SET_IS_LOADING_APPS_ERROR',
  TOGGLE_PACKAGES_VIEW_ROOT: 'TOGGLE_PACKAGES_VIEW_ROOT',
  SET_APPS_TO_MEASURE_LOADING_TIME: 'SET_APPS_TO_MEASURE_LOADING_TIME',
  SET_APP_MANIFEST_HAS_BEEN_LOADED: 'SET_APP_MANIFEST_HAS_BEEN_LOADED',
  SET_ALL_APP_MANIFESTS_LOADED_REPORTED:
    'SET_ALL_APP_MANIFESTS_LOADED_REPORTED',
  SET_EDITOR_SCRIPTS_LOADED_REPORTED: 'SET_EDITOR_SCRIPTS_LOADED_REPORTED',
  SET_START_LOADING_PLATFORM_REPORTED: 'SET_START_LOADING_PLATFORM_REPORTED',
  SET_START_LOADING_EDITOR_SCRIPTS_REPORTED:
    'SET_START_LOADING_EDITOR_SCRIPTS_REPORTED',
  SET_MASTER_STRUCTURE: 'SET_MASTER_STRUCTURE',
  SET_SILENT_INSTALL_FLAG: 'SET_SILENT_INSTALL_FLAG',
  SET_USED_SILENT_INSTALL_ON_SITE: 'SET_USED_SILENT_INSTALL_ON_SITE',
} as const;

export interface SilentInstallAction {
  type: 'SET_SILENT_INSTALL_FLAG';
  isRunning: boolean;
}

export interface SilentInstallUsedOnSiteAction {
  type: 'SET_USED_SILENT_INSTALL_ON_SITE';
  usedOnSite: boolean;
}
