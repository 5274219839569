// @ts-nocheck
import _ from 'lodash';
import addAudioPluginUtil from './commonUtils/addAudioPluginUtil';
import type { BeforePlugin } from 'types/editorPlugins';

function getSingleAudioPlayerStructure(compDef, item) {
  return _.merge({}, compDef, {
    data: {
      uri: item.fileName,
      originalFileName: item.title,
      artist: 'Unknown Artist',
      track: 'Unknown Track',
    },
  });
}

const plugin: BeforePlugin = (
  editorAPI,
  originalAddComponentFunc,
  containerRef,
  compDef,
  optionalId,
  onCompAddCallback,
) =>
  addAudioPluginUtil.handleAudioAdd(
    editorAPI,
    originalAddComponentFunc,
    containerRef,
    compDef,
    optionalId,
    onCompAddCallback,
    getSingleAudioPlayerStructure,
  );

export default plugin;
