import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';

export const safeTopBarOverrides = (editorAPI: EditorAPI) => ({
  overrideGetters: {
    getMenuOverrides: () => [
      { key: constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.SITE },
      { key: constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.SETTINGS },
      { key: constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.TOOLS },
      { key: constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.CODE },
      { key: constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.HELP },
    ],
    getSiteMenuOverrides: () => [
      {
        key: constants.ROOT_COMPS.TOPBAR.SITE_MENU_ITEMS.SAVE,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.SITE_MENU_ITEMS.PREVIEW,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.SITE_MENU_ITEMS.REVISIONS,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.SITE_MENU_ITEMS.FEEDBACK,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.SITE_MENU_ITEMS.EXIT,
        onClick() {
          editorAPI.exitEditor('https://www.wix.com/education');
        },
      },
    ],
    getSettingsMenuOverrides: () => [
      {
        key: constants.ROOT_COMPS.TOPBAR.SETTINGS_MENU_ITEMS.MOBILE_FRIENDLY,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.SETTINGS_MENU_ITEMS.IMAGE_OPTIMIZATION,
      },
    ],
    getHelpMenuOverrides: () => [
      {
        key: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_KB_SHORTCUTS,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_VIDEOS_SEPARATOR,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_WIX_LEARN,
      },
    ],
    getCodeMenuOverrides: () => [
      {
        key: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.ENABLE_DEVELOPER_MODE,
      },
      {
        isSeparator: true,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.API_REFERENCES,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.VIDEO_TUTORIALS,
      },
    ],
    getRightButtonsOverrides: () => [
      {
        key: constants.ROOT_COMPS.TOPBAR.BUTTONS.ZOOM,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.BUTTONS.UNDO,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.BUTTONS.REDO,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.BUTTONS.PREVIEW,
      },
      {
        key: constants.ROOT_COMPS.TOPBAR.BUTTONS.PUBLISH,
      },
    ],
  },
});
