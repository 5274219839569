// @ts-nocheck
import _ from 'lodash';

export default function (compProps) {
  if (compProps && compProps.type === 'MusicPlayerProperties') {
    return {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      properties: _(compProps)
        .keys()
        .filter(
          (elem) =>
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/includes
            _.includes(elem, 'show') &&
            elem !== 'showPlaylist' &&
            compProps[elem],
        )
        .join(';'),
    };
  }
  return {};
}
